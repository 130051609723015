import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDropzone } from 'react-dropzone'
import { Link } from "react-router-dom";
import Select from 'react-select';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import API from './apis';
import InviteUser from "../../common/InviteUser";
import styles from './bulk_upload.module.scss';
import styled from "styled-components";
import TableRow from "@material-ui/core/TableRow";
import MDTableCell from "@material-ui/core/TableCell";
import CustomTable from "./Table";
import _ from 'lodash';
import Input from "@material-ui/core/Input";
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import LargeScreenLoader from "../../common/Loaders/PulseLoader/pulse-loader";
import APIs from '../../common/APIs';
import LocalAPIs from './apis';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

const mockData = [
  {
    id: 1,
    name: 'John Walky',
    email: 'john@gmail.com',
    countryCode: { id: 1, dailing_code: '9276' },
    contact: '9175399800',
    license_type: { id: 1, display_name: 'Manager' },
    gender: 'male',
    status: true, reason: ''
  },
  {
    id: 2, name: 'John Walky', email: 'john@gmail.com',
    countryCode: { id: 2, dailing_code: '9276' }, contact: '9175399800',
    license_type: { id: 2, display_name: 'Manager' },
    gender: 'female', status: false, reason: 'CSV is in wrong format'
  }
]
const TableCell = styled(MDTableCell)`
  padding: 10px;
`;

const Span = styled.span`
  font-size: 0.875em !important;
`;

const UnderLinedText = styled(Span)`
  text-decoration: underline;
`;

const HeaderLink = styled.span`
  font-weight: 620 !important;
  font-size: 1em !important;
`;

const SearchInputContainer = styled.div`
  border: 0.0625em solid #e3e3e3;
  border-radius: 0.125em;
  padding: 0.3125em;
  padding-left: 0.625em;
  text-align: right;
  width: 21.875em;
  float: right;
`;

const headers = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    sort: true,
  },
  {
    id: "gender",
    numeric: false,
    disablePadding: true,
    label: "Gender",
    sort: true,
  },
  {
    id: "phone_number",
    numeric: false,
    disablePadding: true,
    label: "Phone No.",
    sort: true,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
    sort: true,
  },
  {
    id: "role_assigned",
    numeric: false,
    disablePadding: true,
    label: "Role",
    sort: true
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
  }
];

function applySearch(users, searchKey) {
  if (!searchKey) return users;

  return users.filter(
    user => user.name.search(searchKey) !== -1 || user.email.search(searchKey) !== -1
  );
}

const SearchInput = (props) => {
  const [searchText, setSearchText] = useState(props.initialValue);
  const { onSearch, ...rest } = props;
  const searchRef = useRef(null);

  const handleOnIconClick = () => {
    props.onSearch(searchRef.current.value);
  };

  const handleOnChange = (e) => {
    setSearchText(e.target.value);
    props.onSearch(e.target.value);
  }

  return (
    <SearchInputContainer>
      <div style={{ display: "flex", fontStyle: 'italic' }}>
        <Input
          value={searchText}
          {...rest}
          ref={searchRef}
          onKeyUp={handleOnChange}
          onChange={handleOnChange}
          type="search"
          fullWidth={true}
          disableUnderline={true}
        />
        {!searchText && <span style={{ display: 'flex' }}>
          <img
            style={{ width: 20 }}
            src={require("../../../assets/images/administration/Rounded.svg")}
          />
        </span>}
      </div>
    </SearchInputContainer>
  );
};

const MyDropZone = ({onFileSelect}) => {
  const onDrop = useCallback((acceptedFiles) => {
    onFileSelect(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps,isDragActive } = useDropzone({ onDrop, accept: '.csv' })

  return (
    <div className={"row my-2"}>
      <div className="col-md-12">
        <div {...getRootProps()} className={`py-5 text-center ${isDragActive ? styles.draggableStyle : ''}`} style={{ borderStyle: "dotted", borderColor: "#E3E3E6", backgroundColor: '#FFFFFF' }}>
          <input {...getInputProps()} />
          <div className="pb-2">
            <CloudUploadOutlinedIcon style={{ color: '#4EB782', fontSize: '60px' }} />
          </div>
          <h4 className="font-weight-bold">Drag and drop .CSV files here</h4>
          <p style={{ color: '#878787', fontSize: '15px' }}>OR</p>
          <button className="btn btn-rounded px-5 p-2" style={{ 'backgroundColor': '#232f44', 'color': 'white' }}>
            Browse files
              </button>
        </div>
      </div>
    </div>
  )
}

export default function BulkUpload() {
  const [isLoading, setIsLoading] = useState(true);
  const [organizationNameOptions, setOrganizationNameOptions] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [userListResult, setUserListResult] = useState(null);
  const [organizationName, setOrganizationName] = useState('');
  const [selectedOrg, setSelectedOrg] = useState(null);
  const inputFileRef = useRef(null);
  
  

  const fetchOptions = async () => {
    setIsLoading(true);
    const result = await Promise.all([
      API.getOrganizations(),
      API.getMyAccount()
    ]);
    const orgOptions = result[0].map(r => ({ value: r.id, label: r.name, my_account: r.my_account }));
    setOrganizationNameOptions(orgOptions);
    setSelectedOrg(orgOptions.find(o => o.my_account));
    setOrganizationName(result[1]);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  const bulkUpladAPICall = async (file, selectedOrg) => {
    setIsLoading(true);
    const org = selectedOrg || organizationNameOptions.find(o => o.my_account) || organizationNameOptions[0];
    const result = await LocalAPIs.bulkUpload(org.value, file);
    setUserListResult(result);
    setIsLoading(false);
  }

  const handleOnFileChange = async (file) => {
    bulkUpladAPICall(file, selectedOrg);
  };

  const renderUserRecord = (row, i) => {
    return (
      <TableRow key={row.id}>
        <TableCell style={{ textAlign: "center" }}>
          <Span style={{ display: "flex", cursor: 'pointer' }} >
            <UnderLinedText className="ml-5">{(row.name)}</UnderLinedText>
          </Span>
        </TableCell>
        <TableCell>
          <Span className="ml-5" style={{ 'textTransform': 'capitalize' }}>{row.gender}</Span>
        </TableCell>
        <TableCell style={{ textAlign: "center", minWidth: 110 }}>
          <Span>{row.contact}</Span>
        </TableCell>
        <TableCell style={{ textAlign: "center" }}>
          <Span>{row.email}</Span>
        </TableCell>
        <TableCell style={{ textAlign: "center" }}>
          <Span className="mr-4">{row.role}</Span>
        </TableCell>
        <TableCell style={{ textAlign: "center" }}>
          <Span className="ml-4">{row.success ? 'Success' : 'Failure'}</Span>
          {!row.success && <Tooltip  title={row.reason} aria-label="add">
                  <InfoIcon style={{marginLeft: 4}} />
              </Tooltip>}
        </TableCell>
      </TableRow>
    )
  };

  const renderUserTable = () => {
    return (
      <>
        <div className="row mt-3">
          <div className="col-md-6 pt-2">
            <HeaderLink>
              User List ({userListResult.length})
            </HeaderLink>
          </div>
          <div className={"col-md-6 " + styles.search_box}>
            <SearchInput
              onSearch={(key) => setSearchText(key)}
              placeholder="Search By Email or Name"
              initialValue={searchText}
            />
          </div>
        </div>
        <hr />
        <div id="pendingusers" style={{ overflow: "overlay" }}>
          <CustomTable
            count={userListResult && userListResult.length}
            headers={headers}
            data={applySearch(userListResult, searchText)}
            initialSortField="name"
            renderRow={renderUserRecord}
          />
        </div>
      </>
    )
  }

  const renderDownloadLink = () => (
    <div className="row mb-2">
      <div className="col-md-12" onClick={() => LocalAPIs.downloadSampleCSV()}>
        <GetAppOutlinedIcon style={{ color: '#4EB782' }} />
        <span className="pointer font-weight-bold">Download sample format</span>
      </div>
    </div>
  )

  return (
    <>
      {isLoading ? (
        <LargeScreenLoader />
      ) : (
          <div className="main-wrapper">
            {/* Header part- make it in function */}
            <div className="breadcrumbs">
              <div className="row justify-content-between">
                <div className="col-sm-12 col-md-5">
                  <h3 className={styles.title_Administration}>
                    Bulk Upload
                  </h3>
                  <div className="breadcrumbs_container">
                    <Breadcrumbs separator="›" arial-label="Breadcrumb">
                      <Link to="/sales-news">Home</Link>
                      <Link to="/administration">Administration</Link>
                      <Link to="/administration/user_management">User Management</Link>
                      <span>Bulk Upload</span>
                    </Breadcrumbs>
                  </div>
                </div>
                <div className="col-md text-right pt-2">
                  <span className="font-weight-bold pointer">
                    {organizationName}
                  </span>
                  <span className="mx-2"></span>
            |
            <span className="mx-2"></span>
                  <InviteUser />
                  <span className="mx-2"></span>
                </div>
              </div>
            </div>
            <hr />
            {/* End of Header Part */}
            <div className="row">
              <div className="col-sm-12 col-xs-12 col-md-4 col-lg-4">
                <label>Select Organization</label>
                <br />
                <Select
                  options={organizationNameOptions}
                  placeholder="Select Organization/Child Orgnization"
                  onChange={(org) => setSelectedOrg(org)}
                  value={selectedOrg}
                />
              </div>
            </div>
            <MyDropZone onFileSelect={handleOnFileChange} />
            {renderDownloadLink()}
            {userListResult && renderUserTable()}
          </div>
        )}
    </>
  )
};