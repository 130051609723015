import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import util from "../util";
import API from "../API";
import styles from "../graphContainer.module.scss";
import revenue_circle from "../../../../assets/images/bi/revenue_circle.png";
import handshakeIconpic2 from "../../../../assets/images/bi/handshake_circle2.png";
import hitratecircle_icon from "../../../../assets/images/bi/hitrate_circle.png";
import PieChartContainer from "../pieChart/PieChartContainer";
import {
  Card,
  CardContent,
  CardHeader,
  Select,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import LeadsCompareGraph from "../leadCompChart";
import AgingGraph from "../agingChart";
import LargeScreenLoader from "../../../common/Loaders/PulseLoader/pulse-loader";

function toMillion(num) {
  const value = (num / 1000000).toFixed(2);

  return value === "0.00" ? Math.round(num) : `${value} M`;
}

const Pulse = ({ fromDate, toDate, organizationArray, ...rest }) => {
  const [leadStages, setLeadStages] = useState(null);
  const [leadStageDataSelection, setLeadStageDataSelection] = useState("1");
  const [leadAgingSelection, setLeadAgingSelection] = useState("new");
  const [hitRate, setHitRate] = useState(null);
  const [avgVelocityMonth, setAvgVelocityMonth] = useState(null);
  const [compareLeads, setCompareLeads] = useState(false);
  const compareDivRef = useRef(null);
  const agingGraphDivRef = useRef(null);
  const [leadStagesAvgTime, setLeadStagesAvgTime] = useState(null);
  const [leadAging, setLeadAging] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (organizationArray) {
      const params = {
        ...util.getParams(organizationArray, fromDate, toDate),
        leadStage: leadStageDataSelection,
      };
      fetchLeadStageAvgTime(params);
      fetchLeadStage();
    }
    //alert("hiiiiiiiiiiiii11")
  }, [organizationArray, fromDate, toDate, leadStageDataSelection]);

  useEffect(() => {
    if (organizationArray) {
      const params = {
        ...util.getParams(organizationArray, fromDate, toDate),
        leadStage: leadAgingSelection,
      };
      fetchLeadAging(params);
    }
    // alert("hiiiiiiiiiiiii222")
  }, [organizationArray, fromDate, toDate, leadAgingSelection]);

  useEffect(() => {
    if (organizationArray) {
      const params = util.getParams(organizationArray, fromDate, toDate);
      rest.getRevenue(params);
      rest.getLeadsByMonth(params);
      rest.getAvgVelocity(params);
      rest.getRevenueByMonth(params);
      fetchChartData(params);
    }
  }, [organizationArray, fromDate, toDate]);

  const fetchLeadAging = async (params) => {
    const result = await API.getLeadAging(params);
    setLeadAging(result);
  };

  const fetchChartData = async (params) => {
    setShowLoader(true);
    const [hitRate, avgVelocityMonth] = await Promise.all([
      API.getHitRate(params),
      API.getAvgVelocityMonthWise(params),
    ]);
    setHitRate(hitRate);
    setAvgVelocityMonth(avgVelocityMonth);
    setShowLoader(false);
  };

  const fetchLeadStageAvgTime = async (params) => {
    const result = await API.getLeadStagesAvgTime(params);
    setLeadStagesAvgTime(result);
  };

  const fetchLeadStage = async () => {
    const params = util.getParams(organizationArray, fromDate, toDate);
    const req = { ...params, leadStage: leadStageDataSelection };
    const result = await API.getLeadStage(req);
    setLeadStages(result);
  };

  const renderHitRatePieChart = () => {
    const hitRateChartData =
      hitRate &&
      hitRate[0] &&
      hitRate.map((lead) => {
        const date = moment(lead.YearMonth, "MM-DD-YYYY").toDate();
        const month = date.toLocaleString("default", { month: "short" });
        const year = date.getFullYear();
        return { xVal: month, yVal: lead.HitRate, year: year };
      });

    console.log("Hit Rate Per Month", hitRateChartData);

    return (
      <div className={`col-md-6 border-right`}>
        <PieChartContainer
          leftHeaderValue={
            rest.revenueData ? rest.revenueData.hitRate + "%" : 0 + "%"
          }
          leftSubHeaderValue={"Hit Rate"}
          leftHeaderColor="#FFC458"
          noData={hitRateChartData && hitRateChartData.every(a => a.yVal === null)}
          pieData={
            hitRateChartData
              ? hitRateChartData.map((a) => ({ ...a, yValue: a.yVal === null ? '-' : ` ${a.yVal}%` }))
              : []
          }
          leftFooterValue={
            hitRateChartData ? "Y:" + hitRateChartData[0].year : ""
          }
          rightHeaderTitle={"Hit Rate Per Month"}
        ></PieChartContainer>
      </div>
    );
  };

  const renderLeadsPieChart = () => {
    let TotalleadTileChartData = 0;
    const leadTileChartData =
      rest.LeadsByMonthData &&
      rest.LeadsByMonthData[0] &&
      rest.LeadsByMonthData.map((lead) => {
        TotalleadTileChartData += lead.Leads;
        const date = moment(lead.YearMonth, "MM-DD-YYYY").toDate();
        const month = date.toLocaleString("default", { month: "short" });
        const year = date.getFullYear();
        return { xVal: month, yVal: lead.Leads, year: year };
      });

    return (
      <div className={`col-md-6 border-right`}>
        <PieChartContainer
          leftHeaderValue={TotalleadTileChartData ? TotalleadTileChartData : 0}
          leftSubHeaderValue={"Leads"}
          leftHeaderColor={"#6CAA76"}
          noData={leadTileChartData && leadTileChartData.every(a => !a.yVal)}
          pieData={leadTileChartData ? leadTileChartData : []}
          leftFooterValue={
            leadTileChartData ? "Y:" + leadTileChartData[0].year : ""
          }
          rightHeaderTitle={"No of Leads Per Month"}
        ></PieChartContainer>
      </div>
    );
  };

  const renderAvgVelocityPieChart = () => {
    let AvgVelocity = 0;
    const averageVelocityChartData =
      avgVelocityMonth &&
      avgVelocityMonth[0] &&
      avgVelocityMonth.map((lead) => {
        const date = moment(lead.YearMonth, "MM-DD-YYYY").toDate();
        const month = date.toLocaleString("default", { month: "short" });
        const year = date.getFullYear();
        AvgVelocity += parseInt(lead.AvgVelocity);
        return { xVal: month, yVal: lead.AvgVelocity, year: year };
      });

    AvgVelocity = Math.round(AvgVelocity / (avgVelocityMonth && avgVelocityMonth.length) || 1);  

    return (
      <div className={`col-md-6 border-right`}>
        <PieChartContainer
          leftHeaderValue={rest.revenueData ? AvgVelocity + "D" : 0 + " D"}
          leftSubHeaderValue={"Avg.Velocity"}
          leftHeaderColor="#725DA8"
          noData={averageVelocityChartData && averageVelocityChartData.every(a => a.yVal === "0.0000")}
          pieData={
            averageVelocityChartData
              ? averageVelocityChartData.map((a) => {
                  const num = parseFloat(a.yVal);
                  const value =
                    num - Math.floor(num) === 0
                      ? parseFloat(num).toFixed(1)
                      : parseFloat(num).toFixed(1);
                  return { ...a, yValue: `${Math.round(value)}D`};
                })
              : []
          }
          leftFooterValue={
            averageVelocityChartData
              ? "Y:" + averageVelocityChartData[0].year
              : ""
          }
          rightHeaderTitle={"Avg.Velocity Per Month"}
        ></PieChartContainer>
      </div>
    );
  };

  const renderRevenuePieChart = () => {
    //Get Revenue chart json
    const revenueChartData =
      rest.revenueByMonthData &&
      rest.revenueByMonthData[0] &&
      rest.revenueByMonthData.map((lead) => {
        const date = moment(lead.YearMonth, "MM-DD-YYYY").toDate();
        const month = date.toLocaleString("default", { month: "short" });
        const year = date.getFullYear();
        return { xVal: month, yVal: lead.Revenue, year: year };
      });

    const revenueValue = rest.revenueData
      ? parseInt(rest.revenueData.revenue)
      : 0;

    return (
      <div className={`col-md-6 border-right`}>
        <PieChartContainer
          leftHeaderValue={`${toMillion(revenueValue)}`}
          leftSubHeaderValue={"Revenue"}
          leftHeaderColor="#F68E77"
          noData={revenueChartData && revenueChartData.every(a => !a.yVal)}
          pieData={
            revenueChartData
              ? revenueChartData.map((a) => ({
                  ...a,
                  yValue: `${toMillion(a.yVal)}`,
                }))
              : []
          }
          leftFooterValue={
            revenueChartData ? "Y:" + revenueChartData[0].year : ""
          }
          rightHeaderTitle={"Revenue Per Month"}
        ></PieChartContainer>
      </div>
    );
  };

  const onCompareLeadsClick = () => {
    setCompareLeads(!compareLeads);
  };

  const handleleadStageDataDropdown = (e) => {
    setLeadStageDataSelection(e.target.value);
  };

  const handleLeadAgingDropdown = (e) => {
    setLeadAgingSelection(e.target.value);
  };

  const renderLeadsCompareChart = () => {
    const leadsChartData =
      leadStages &&
      leadStages[0] &&
      leadStages.map((lead) => {
        return {
          xVal: lead.name,
          current: parseInt(lead.current_data),
          previous: parseInt(lead.previous_data),
        };
      });

    const finalLeadChartData = [];
    if (leadStages && leadStages[0]) {
      leadsChartData.forEach((element) => {
        if (element.xVal === "New") {
          finalLeadChartData[0] = element;
        } else if (element.xVal === "Lead") {
          finalLeadChartData[1] = element;
        } else if (element.xVal === "Opportunity") {
          finalLeadChartData[2] = element;
        } else if (element.xVal === "Proposal") {
          finalLeadChartData[3] = element;
        } else if (element.xVal === "Negotiation") {
          finalLeadChartData[4] = element;
        } else if (element.xVal === "Closed") {
          finalLeadChartData[5] = element;
        }
      });
    }

    const leadsChartAvgTimeData =
      leadStagesAvgTime &&
      leadStagesAvgTime[0] &&
      leadStagesAvgTime.map((lead) => {
        return {
          xVal: lead.name,
          avgTime: lead.avgTime,
        };
      });

    const finalLeadChartAvgTimeData = [];
    if (leadStagesAvgTime && leadStagesAvgTime[0]) {
      leadsChartAvgTimeData.forEach((element) => {
        if (element.xVal === "New") {
          finalLeadChartAvgTimeData[0] = element;
        } else if (element.xVal === "Lead") {
          finalLeadChartAvgTimeData[1] = element;
        } else if (element.xVal === "Opportunity") {
          finalLeadChartAvgTimeData[2] = element;
        } else if (element.xVal === "Proposal") {
          finalLeadChartAvgTimeData[3] = element;
        } else if (element.xVal === "Negotiation") {
          finalLeadChartAvgTimeData[4] = element;
        } else if (element.xVal === "Closed") {
          finalLeadChartAvgTimeData[5] = element;
        }
      });
    }

    const leadsAvgChartData =
      finalLeadChartAvgTimeData &&
      finalLeadChartAvgTimeData.map((lead) => {
        return { xVal: lead.xVal, avg: parseInt(lead.avgTime) };
      });

    const leadsBarChartOptions = {
      id: "leadsChart",
      chartStyle: "vertical", // horizontal/vertical
      innerBar: false,
      height: 0,
      width: 0,
      margin: {
        top: 20,
        right: 65,
        bottom: 50,
        left: 45,
      },
      hideXaxis: false,
      hideYaxis: false,
      hideZaxis: false,
      hideCenterLine: false,
      parseTime: "%b-%y",
      hideLegend: false,
      barWidth: 10,
      insideBarWidth: 10,
      multiColorBar: true,
      colors: ["#725DA8"],
      inSideColors: ["#FEC55A"],
      hideTooltip: false,
      responsive: true,
      ticksSizeYaxis: 5,
      yAxisNumebrFormat: "s",
      ticksSizeZaxis: 10,
      zAxisNumebrFormat: "s",
      xAxisRotate: true,
      yAxisText: true,
      yAxisTextString: "No of Leads",
      zAxisText: true,
      zAxisTextString: "Avg Time",
      yAxisDivided: true,
      valueOnTop: true,
      valueOnTopDecimal: false,
    };

    const width =
      compareDivRef &&
      compareDivRef.current &&
      compareDivRef.current.offsetWidth;

    return (
      <div className={"col-md-6 " + styles.chartPadding}>
        <Card className={styles.card}>
          <CardHeader
            className={styles.activities_container_header}
            action={
              <div>
                <span className="mr-3">
                  <Checkbox
                    checked={compareLeads}
                    onChange={onCompareLeadsClick}
                    color="primary"
                    label={<span>Compare</span>}
                    inputProps={{ "aria-label": "primary checkbox" }}
                    style={{
                      width: "24px",
                      height: "24px",
                      marginRight: "5px",
                    }}
                  />
                  Compare
                </span>

                <Select
                  id="select"
                  style={{
                    border: "solid 1px #ccc",
                    paddingLeft: "10px",
                    marginRight: "20px",
                    borderRadius: "5px",
                  }}
                  value={leadStageDataSelection}
                  onChange={handleleadStageDataDropdown}
                >
                  <MenuItem value="7">7 Days </MenuItem>
                  <MenuItem value="30">30 Days</MenuItem>
                  <MenuItem value="90">90 Days</MenuItem>
                  <MenuItem value="1">1 Year</MenuItem>
                </Select>
              </div>
            }
            avatar={
              <h1 className={styles.activities_container_heading}>Leads</h1>
            }
          />
          <CardContent>
            <div className="row">
              <div className="col" ref={compareDivRef}>
                <LeadsCompareGraph
                  height={250}
                  width={width}
                  // data={leadsChartData ? leadsChartData : []}
                  data={finalLeadChartData ? finalLeadChartData : []}
                  options={leadsBarChartOptions}
                  lineData={leadsAvgChartData ? leadsAvgChartData : []}
                  compare={{ Checked: compareLeads ? 1 : 0 }}
                  dropDownValue={leadStageDataSelection}
                />
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  };

  const renderAgingGraph = () => {
    const keys = leadAging && leadAging[0] && Object.keys(leadAging[0]);
    const agingChartData =
      keys &&
      keys
        .map((lead) => {
          if (lead !== "NAME") {
            return { xVal: lead, yVal: leadAging[0][lead] };
          } else {
            return false;
          }
        })
        .filter((lead) => {
          return lead !== false;
        });
    const FinalagingChartData = [];
    if (agingChartData != null && agingChartData && agingChartData[0]) {
      agingChartData.forEach((element) => {
        if (element.xVal === "_0_7_Days") {
          element.xVal = "0-7";
          FinalagingChartData[0] = element;
        } else if (element.xVal === "_8_30_Days") {
          element.xVal = "8-30";
          FinalagingChartData[1] = element;
        } else if (element.xVal === "_31_90_Days") {
          element.xVal = "31-90";
          FinalagingChartData[2] = element;
        } else if (element.xVal === "_90_Plus_Days") {
          element.xVal = "90+";
          FinalagingChartData[3] = element;
        }
      });
    }

    const agingBarChartOptions = {
      id: "agingChart",
      chartStyle: "horizontal", // horizontal/vertical
      height: 0,
      width: 0,
      margin: {
        top: 20,
        right: 40,
        bottom: 40,
        left: 80,
      },
      hideXaxis: false,
      hideYaxis: false,
      hideCenterLine: false,
      parseTime: "%b-%y",
      hideLegend: false,
      barWidth: 30,
      insideBarWidth: 60,
      multiColorBar: true,
      // colors: ["#5D3299", "#CC5869", "#f6b93b", "#e55039"],
      colors: ["#F15E4D", "#FEC55A", "#725DA8", "#A3D6B1"],
      hideTooltip: false,
      responsive: true,
      ticksSize: 5,
      xAxisRotate: false,
      yAxisText: true,
      xAxisText: true,
      yAxisTextString: "Days",
      xAxisTextString: "Leads",
      yAxisDivided: true,
      valueOnTop: true,
      valueOnTopDecimal: false,
    };

    const width =
      agingGraphDivRef &&
      agingGraphDivRef.current &&
      agingGraphDivRef.current.offsetWidth;

    return (
      <div className={"col-md-6 " + styles.chartPadding}>
        <Card className={styles.card}>
          <CardHeader
            className={styles.activities_container_header}
            action={
              <Select
                style={{
                  border: "solid 1px #ccc",
                  paddingLeft: "15px",
                  marginRight: "20px",
                  borderRadius: "5px",
                }}
                className={styles.grid_title_text}
                id="select"
                value={leadAgingSelection}
                onChange={handleLeadAgingDropdown}
              >
                <MenuItem value="new">New</MenuItem>
                <MenuItem value="lead">Lead</MenuItem>
                <MenuItem value="opportunity">Opportunity</MenuItem>
                <MenuItem value="proposal">Proposal</MenuItem>
                <MenuItem value="nigotiation">Negotiation</MenuItem>
                <MenuItem value="closed">Closed</MenuItem>
              </Select>
            }
            avatar={
              <h1 className={styles.activities_container_heading}>Aging</h1>
            }
          />
          {console.log(agingChartData, "agingChartData")}
          <CardContent>
            <div className="row">
              <div style={{ minHeight : 250 }} className="col" ref={agingGraphDivRef}>
                <AgingGraph
                  height={250}
                  width={width}
                  data={agingChartData ? agingChartData : []}
                  options={agingBarChartOptions}
                />
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  };

  const renderPage = () => {
    return (
      <>
        <div className="container-body">
          <div className="row">
            <div className="col-md-4 border-right">
              <div className="row">
                <div className={"col-sm-6 " + styles.topSectionLeft}>
                  <img
                    className={styles.icon_pic}
                    src={revenue_circle}
                    alt="RevenueIcon"
                  />
                </div>
                <div className={"col-sm-6 " + styles.topSection}>
                  <div className={styles.content_values1}>
                    {rest.revenueData
                      ? `${toMillion(rest.revenueData.revenue.toFixed(2))}`
                      : "0 M"}{" "}
                  </div>
                  <div className="sales-content-text">
                    <p className={styles.selectDropdownTitle}>
                      Revenue,{" "}
                      {rest.revenueData && rest.revenueData.account
                        ? rest.revenueData.account.currency.short_name
                        : ""}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 border-right">
              <div className="row">
                <div className={"col-sm-6 " + styles.topSectionLeft}>
                  <img
                    className={styles.icon_pic}
                    src={handshakeIconpic2}
                    alt="HandShake"
                  />
                </div>
                <div className={"col-sm-6 " + styles.topSection}>
                  <div className={styles.first_content_style}>
                    <div className={styles.content_values1}>
                      {rest.revenueData ? rest.revenueData.handShakeTotal : 0}
                    </div>
                    <p className={styles.selectDropdownTitle}>Handshake</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className={"col-sm-6 " + styles.topSectionLeft}>
                  <img
                    className={styles.icon_pic}
                    src={hitratecircle_icon}
                    alt="hitrate"
                  />
                </div>
                <div className={"col-sm-6 " + styles.topSection}>
                  <div className={styles.first_content_style}>
                    <div className={styles.content_values1}>
                      {rest.revenueData ? rest.revenueData.hitRate : 0}
                    </div>
                    <p className={styles.selectDropdownTitle}>Hit rate, %</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showLoader ? (
          <LargeScreenLoader />
        ) : (
          <>
            <div className="container-body">
              <div className={styles.pieChartContainer}>
                <div className={`row justify-content-center mb-4`}>
                  {renderLeadsPieChart()}
                  {renderHitRatePieChart()}
                </div>
              </div>
            </div>

            <div className="container-body">
              <div className={styles.pieChartContainer}>
                <div className={`row justify-content-center mb-4`}>
                  {renderAvgVelocityPieChart()}
                  {renderRevenuePieChart()}
                </div>
              </div>
            </div>
            <div className="container-body">
              <div className="row justify-content-center mb-4">
                {renderLeadsCompareChart()}
                {renderAgingGraph()}
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  return renderPage();
};

export default Pulse;
