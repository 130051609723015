import React from "react";
import sound from "../../../../assets/sounds/ring_bell.mp3";
import { withStyles } from "@material-ui/core/styles";
import { 
      Avatar, 
    } from '@material-ui/core';
import Stepper from "../../../common/Stepper/stepper";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import handshakeIconGrey from "../../../../assets/images/content-images/beforeHandshake.png";
import handshakeIconOne from "../../../../assets/images/content-images/afterHandshake.png";
import handshakeIconTwo from "../../../../assets/images/content-images/handshakeIconTwo.png";
import deleteIcon from "../../../../assets/images/bi/delete.png";
import IconButton from "@material-ui/core/IconButton";
import * as commonFunctions from "../../../common/common-functions";
import * as actions from "../redux/actions";
import { connect } from "react-redux";
import { openSnackbar } from "../../../common/Snackbar/snackbar";
import styles from "./leadDetail.module.scss";
import addIcon from "../../../../assets/add_icon.png";
import Dialog from "@material-ui/core/Dialog";
import ActivityTaskNote from "../../../components/ActivityTaskNote/component/ActivityTaskNote";
import SalesActivities from "../../../components/SalesActivities/SalesActivities";
import NotesListingContainer from "../../../components/NotesListing/NotesListingContainer";
import axiosInstance from "../../../common/api-config";
import * as actionsNoteListing from "../../../components/NotesListing/redux/actions";
import bell from "../../../../assets/images/content-images/bell.png";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteLeadConfirmation from '../../../components/LeadDialog/LeadDeleteConfirmation';
import {DialogTitle, DialogContent} from '../../../components/LeadDialog/Components';

import { getStorageItem, showSnackbarMessage } from "../../../common/common-functions";
import API from '../../../common/APIs';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    maxWidth: "120px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}))(Tooltip);

const getOrganizationId = () => {
  return JSON.parse(getStorageItem("user")).organization_id;
};
class LeadDetailPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addSalesActivities: false,
      value: 0,
      item: null,
      isRingedBell: false,
      documentList: [],
      open: false,
      deleting: false
    };
  }

  componentDidMount() {
    this.getNotes();
    if (this.props.currentSalesItem) {
      let params = {
        lead_id: this.props.currentSalesItem.id,
      };
      this.props.getStatusLogs(params);

      const requestBodySalesActivitiesTasks = {
        arrayFilters: [
          {
            dateFrom: moment().format("YYYY-MM-DD"),
            dateTo: null,
            lead_id: this.props.currentSalesItem.id,
          },
        ],
        selectFilters: [],
        sort: {
          field: "activity_time",
          sortOrder: "ASC",
        },
        paginate: {
          page: 0,
          limit: 1000,
        },
        type: "BOTH",
        //user_id: JSON.parse(localStorage.getItem("user")).user_id
      };

      this.props.myPipelineGetPlannedActivities(
        requestBodySalesActivitiesTasks
      );

      const paramsHistoryActivities = {
        arrayFilters: [
          {
            dateFrom: null,
            dateTo: moment().format("YYYY-MM-DD"),
            lead_id: this.props.currentSalesItem.id,
          },
        ],
        selectFilters: [],
        sort: {
          field: "activity_time",
          sortOrder: "ASC",
        },
        paginate: {
          page: 0,
          limit: 1000,
        },
        type: "BOTH",
        //user_id: JSON.parse(localStorage.getItem("user")).user_id
      };

      this.props.myPipelineGetHistoryActivities(paramsHistoryActivities);
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    // if (nextProps && nextProps.documents !== state.documents) {
    //   return {
    //     documentListing: nextProps.documents
    //   };
    // }
    if (nextProps && nextProps.ringedBell) {
      nextProps.resetRingedBell();
      openSnackbar("Lead updated successfully.", "snack-success");

      let fromValue =
        window.location.pathname === "/my-pipeline" ? "my_pipeline" : "";
      const filterVals = {};

      if (fromValue === "my_pipeline" && nextProps.parkedSelected) {
        filterVals.is_won = 2;
      }
      if (nextProps.filterList.length !== 0) {
        filterVals.lead_current_status_id = nextProps.filterList;
      }

      let organizationArray = null;
      let current_user_selection = null;
      let licenseType = null;

      if (nextProps.orgSelected === null) {
        organizationArray = [nextProps.currentUser.organization_id];
      } else {
        organizationArray = [nextProps.orgSelected.id];
      }

      if (nextProps.userSelected === null) {
        current_user_selection = nextProps.currentUser.user_id;
      } else {
        current_user_selection = nextProps.userSelected.id;
      }

      if (nextProps.userSelected === null) {
        licenseType = nextProps.currentUser.license_type.actual_name;
      } else {
        licenseType = nextProps.userSelected.license_type.actual_name;
      }

      let params = {
        // user_id:
        //   nextProps.currentUser && nextProps.currentUser.user_id
        //     ? nextProps.currentUser.user_id
        //     : "",
        from: "my_pipeline",
        arrayFilters: [filterVals],
        selectFilters: [
          "id",
          "lead_title",
          "expected_closing_date",
          "lead_value",
          "is_confidential",
          "notes",
          "is_hand_over",
          "is_won",
          "is_bell_ringed",
          "project_location",
          "created_at",
          "linkedin_profile",
        ],
        sort: {
          field: "id",
          sortOrder: "DESC",
        },
        paginate: {
          page: fromValue === "sales-community" ? "0" : "",
          limit: fromValue === "sales-community" ? "10" : "",
        },
        organizationArray: organizationArray,
        user_id: current_user_selection,
        license_type: licenseType,
      };
      nextProps.getPipelineLeadList(params);
    }
    if (nextProps && nextProps.errorMessage) {
      openSnackbar(nextProps.errorMessage, "snack-error");
      nextProps.resetRingedBell();
    }

    return nextProps;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.documents !== prevProps.documents) {
      this.setState({
        documentList: this.props.documents,
      });
    }

    if (
      this.props.unparkLeadFlag &&
      prevProps.unparkLeadFlag != this.props.unparkLeadFlag
    ) {
      openSnackbar("Lead unparked successfully.", "snack-success");
      this.props.resetUnparkLeadFlag();
      this.props.openUpdateLeadPopup();
    }
  }

  ringTheBell = async () => {
    var myAudio = document.getElementById("myTune");
    await myAudio.play();
    await this.setState({
      isRingedBell: true,
    });

    //notify everyone about win lead by ringing the bell
    let item = this.props.currentSalesItem;
    let updateLeadParams = {
      is_bell_ringed: 1,
      id: item.id,
      // user_id: this.props.currentUser.user_id,
      company_id: item.contact_company ? item.contact_company.id : null,
    };
    this.props.updateRingBellLead(updateLeadParams);
    setTimeout(() => {
      this.props.onClose();
    }, 1000);
  };

  handleOpenAddActivity = (val) => {
    this.setState({ addSalesActivities: true, value: val });
  };

  handleUpdateActivity = (item) => {
    this.setState({ item: item, addSalesActivities: true, value: 0 });
  };

  handleUpdateTask = (item) => {
    this.setState({ item: item, addSalesActivities: true, value: 1 });
  };

  handleDeleteTask = (id) => {
    const req = {
      id: id,
      is_deleted: 1,
      //user_id: JSON.parse(localStorage.getItem("user")).user_id
    };
    axiosInstance
      .put("/task/update", req)
      .then((response) => {
        if (response && response.data && response.data.statusCode === 200) {
          openSnackbar("Task deleted successfully.", "snack-success");
          this.getPlannedActivities();
        } else {
          openSnackbar("Task deleting failed.", "snack-error");
        }
      })
      .catch((error) => {
        openSnackbar("Task deleting failed.", "snack-error");
      });
  };

  handleDeleteActivity = (id) => {
    const req = {
      id: id,
      is_deleted: 1,
      //user_id: JSON.parse(localStorage.getItem("user")).user_id
    };
    axiosInstance
      .put("/salesActivity/update", req)
      .then((response) => {
        if (response && response.data && response.data.statusCode === 200) {
          openSnackbar("Activity deleted successfully.", "snack-success");
          this.getPlannedActivities();
        } 
      })
      .catch((error) => {
        openSnackbar("Activity deleting failed.", "snack-error");
      });
  };

  getPlannedActivities = () => {
    const requestBodySalesActivitiesTasks = {
      arrayFilters: [
        {
          dateFrom: moment().format("YYYY-MM-DD"),
          dateTo: null,
          lead_id: this.props.currentSalesItem.id,
        },
      ],
      selectFilters: [],
      sort: {
        field: "activity_time",
        sortOrder: "ASC",
      },
      paginate: {
        page: 0,
        limit: 1000,
      },
      type: "BOTH",
      //user_id: JSON.parse(localStorage.getItem("user")).user_id
    };
    this.props.myPipelineGetPlannedActivities(requestBodySalesActivitiesTasks);
  };

  getNotes = () => {
    const req = {
      arrayFilters: [
        {
          note_parent_table: "leads",
          note_id:
            this.props.currentSalesItem && this.props.currentSalesItem.id,
        },
      ],
      selectFilters: [],
      sort: {
        field: "updated_at",
        sortOrder: "DESC",
      },
      paginate: {
        page: "0",
        limit: "",
      },
    };
    this.props.getNotesListing(req);
  };

  handleClose = (value) => {
    if (value === "save_activity") {
      this.getPlannedActivities();
    }
    if (value.value === "note_added") {
      let currentSalesItem = this.props.currentSalesItem;
      this.setState({
        recentNotes: value.updatedLead[0].notes,
      });
      this.getNotes();
    }
    this.setState({ addSalesActivities: false, item: null });
  };

  openUpdateLeadPopup = (isParked) => {
    if (isParked) {
      //unpark the lead
      let params = {
        is_won: null,
        id: this.props.currentSalesItem.id,
      };
      this.props.unparkLead(params);
    } else {
      this.props.openUpdateLeadPopup();
    }
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  }

  handleCloseDialog = () => {
    this.setState({ open: false });
  }

  renderThumbnail = (doc) => {
    const title = commonFunctions.fileNameFromUrl(doc.document_path);
    switch (doc.document_type) {
      case "JPG":
      case "PNG":
        return (
          <a href={doc.document_path} target="_blank" download>
            {/* "../../../../assets/userProfile_default.png" */}
            <LightTooltip title={title} placement="top">
              <Avatar
                alt="IMG"
                src={doc.document_path}
                className={`${styles.avataar} `}
              />
            </LightTooltip>
          </a>
        );
      case "PDF":
        return (
          <a href={doc.document_path} target="_blank" download>
            <LightTooltip title={title} placement="top">
              {/* "../../../../assets/userProfile_default.png" */}
              <Avatar
                alt="PDF"
                src={require("../../../../assets/images/spl/icon_pdf.png")}
                className={`${styles.avataar} ${styles.icon}`}
              />
            </LightTooltip>
          </a>
        );
      case "PPT":
        return (
          <a href={doc.document_path} download>
            <LightTooltip title={title} placement="top">
              {/* "../../../../assets/userProfile_default.png" */}
              <Avatar
                alt="PPT"
                src={require("../../../../assets/images/spl/icon_ppt.png")}
                className={`${styles.avataar} ${styles.icon}`}
              />
            </LightTooltip>
          </a>
        );
      case "DOC":
        return (
          <a href={doc.document_path} download>
            <LightTooltip title={title} placement="top">
              {/* "../../../../assets/userProfile_default.png" */}
              <Avatar
                alt="DOC"
                src={require("../../../../assets/images/spl/icon_doc.png")}
                className={`${styles.avataar} ${styles.icon}`}
              />
            </LightTooltip>
          </a>
        );
    }
  };

  componentWillUnmount() {
    //this.props.resetDocuments();
  }

  handleOnDelete = async () => {
    this.setState({
      deleting: true
    });

    await API.markAsDelete(this.props.currentSalesItem.id);

    this.setState({
      deleting: false
    });

    showSnackbarMessage('Lead deleted successfully', 'snack-success');

    this.props.onClose();
    this.props.getPipelineLeadList && this.props.getPipelineLeadList();
    this.props.onRefresh && this.props.onRefresh();
  }

  canSeeDeleteButton = () => {
    let { currentSalesItem } = this.props;

    return currentSalesItem.can_update;
  }

  canSeeUpdateButton = () => {
    let { currentSalesItem } = this.props;
    return currentSalesItem.can_update;
  } 

  render() {
    let { currentSalesItem } = this.props;
    return (
      <div className="leadDeatails_popup_wrapper overflow_x_hidden">
        <div className="overflow_x_hidden">
          <DialogTitle
            id="customized-dialog-title"
            onClose={this.props.onClose}
          >
            <div className={styles.lead_dialog + " title_top_padding"}>
              <div className="dialog-title">
                {currentSalesItem ? currentSalesItem.lead_title : ""}
              </div>
              <div className="dialog-company-name title_bottom_padding">
                {currentSalesItem && currentSalesItem.contact_company
                  ? currentSalesItem.contact_company.company_name
                  : ""}
                {currentSalesItem && this.canSeeDeleteButton() && <div className={styles.deleteLead} onClick={this.handleClickOpen}>
                  <img src={deleteIcon}></img> 
                  <span>Delete Lead</span>
                </div>}
                <DeleteLeadConfirmation open={this.state.open} onClose={this.handleCloseDialog} deleting={this.state.deleting} onDeleteClick={this.handleOnDelete} />
              </div>
            </div>
            <div className={styles.handshakeIconSetion}>
              {currentSalesItem ? (
                currentSalesItem.is_hand_over ? (
                  <div className="handoverIconOnPopup">
                    {currentSalesItem.createdBy &&
                    currentSalesItem.createdBy.license_type &&
                    (currentSalesItem.createdBy.license_type.actual_name ==
                      "BASIC" ||
                      currentSalesItem.createdBy.license_type.actual_name ==
                        "LITE") ? (
                      currentSalesItem.is_hand_over == 0 ? (
                        <img src={handshakeIconGrey} width="40" />
                      ) : currentSalesItem.is_hand_over &&
                        currentSalesItem.is_hand_over == 1 ? (
                        <img src={handshakeIconOne} width="40" />
                      ) : currentSalesItem.is_hand_over &&
                        currentSalesItem.is_hand_over == 2 ? (
                        <img src={handshakeIconTwo} width="40" />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                ) : null
              ) : null}
            </div>
          </DialogTitle>
          <DialogContent className={styles.dialogContent}>
            {currentSalesItem &&
            currentSalesItem.lead_status &&
            currentSalesItem.lead_status.id > 1 ? (
              <div className="popover-breadcrumb pipeline-popover-breadcrumb">
                <div>
                  <span className={styles.stage_label}>Pipeline stage : </span>
                  <span className={styles.stage_name}>
                    {currentSalesItem
                      ? currentSalesItem.lead_status.id == 6
                        ? currentSalesItem.lead_status.name +
                          (currentSalesItem.is_won == 1
                            ? " (Won)"
                            : currentSalesItem.is_won == 0
                            ? " (Lost)"
                            : currentSalesItem.is_won == 2
                            ? " (Parked)"
                            : "")
                        : currentSalesItem.lead_status.name
                        ? currentSalesItem.lead_status.name +
                          (currentSalesItem.is_won == 2 ? " (Parked)" : "")
                        : ""
                      : ""}
                  </span>
                </div>
                <Stepper
                  currentSalesItem={currentSalesItem}
                  statusLogs={this.props.statusLogs}
                />
                {
                  //ring the bell icon
                  this.props.currentUser &&
                  this.props.currentUser.license_type &&
                  (this.props.currentUser.license_type.actual_name ===
                    "PROFESSIONAL" ||
                    this.props.currentUser.license_type.actual_name ===
                      "MANAGER") &&
                  currentSalesItem &&
                  currentSalesItem.lead_status &&
                  currentSalesItem.lead_status.id == 6 &&
                  currentSalesItem.is_won == 1 &&
                  currentSalesItem &&
                  currentSalesItem.is_bell_ringed != 1 &&
                  (currentSalesItem.account && currentSalesItem.account.id) ===
                    this.props.currentUser.organization_id ? (
                    <div className={"row  " + styles.dialogBtn}>
                      <div className={"col-md-12 " + styles.textCenter}>
                        <audio id="myTune">
                          <source src={sound} />
                        </audio>
                        <button
                          type="button"
                          disabled={this.state.isRingedBell}
                          className={"update-btn " + styles.ringBtn}
                          onClick={() => this.ringTheBell()}
                        >
                          Ring the bell
                          <img
                            src={bell}
                            className={styles.bellIcon}
                            width="40"
                          />
                        </button>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                }
              </div>
            ) : (
              ""
            )}
            <div>
              <div className={"row " + styles.popup_row}>
                <div className="col-md-12 col-lg-4 pipeline-popup-grid padd_top_remove">
                  <span className="dialog-content-title">
                    Lead id & organization:
                  </span>
                  <span className="dialog-content-value">
                    {currentSalesItem && currentSalesItem.id
                      ? `${currentSalesItem.id} (${
                          currentSalesItem.account &&
                          currentSalesItem.account.name
                        })`
                      : " - "}
                  </span>
                </div>
                <div className="col-md-12 col-lg-4 pipeline-popup-grid padd_top_remove">
                  <span className="dialog-content-title">
                    Contact person name:
                  </span>
                  <span className="dialog-content-value">
                    {currentSalesItem && currentSalesItem.contact_person
                      ? currentSalesItem.contact_person.name
                      : " - "}
                  </span>
                </div>
                <div className="col-md-12 col-lg-4 pipeline-popup-grid padd_top_remove">
                  <span className="dialog-content-title">
                    Contact person phone:
                  </span>
                  <span className="dialog-content-value">
                    {currentSalesItem &&
                    currentSalesItem.contact_person &&
                    currentSalesItem.contact_person.phone_number
                      ? currentSalesItem.contact_person.countryCode &&
                        currentSalesItem.contact_person.countryCode.dialing_code
                        ? "+" +
                          currentSalesItem.contact_person.countryCode
                            .dialing_code +
                          " " +
                          currentSalesItem.contact_person.phone_number
                        : currentSalesItem.contact_person.phone_number
                      : " - "}{" "}
                  </span>
                </div>
              </div>

              <div className={"row " + styles.popup_row}>
                <div className="col-md-12 col-lg-12 pipeline-popup-grid padd_top_remove">
                  <span className="dialog-content-title">
                    LinkedIn profile:
                  </span>
                  <span className="dialog-content-value linkedin-hover">
                    {currentSalesItem && currentSalesItem.linkedin_profile ? (
                      <a
                        href={currentSalesItem.linkedin_profile}
                        target="_blank"
                      >
                        <div
                          className="dialog-content-value"
                        >
                          {currentSalesItem.linkedin_profile}
                        </div>
                      </a>
                    ) : (
                      " - "
                    )}
                  </span>
                </div>
              </div>

              {this.props.currentUser &&
              this.props.currentUser.license_type &&
              (this.props.currentUser.license_type.actual_name === "BASIC" ||
                this.props.currentUser.license_type.actual_name === "LITE") ? (
                <div className={"row " + styles.popup_row}>
                  <div className="col-md-12 col-lg-4 pipeline-popup-grid padd_top_remove">
                    <span className="dialog-content-title">
                      Lead creation date:
                    </span>
                    <span className="dialog-content-value">
                      {currentSalesItem && currentSalesItem.created_at
                        ? moment(currentSalesItem.created_at).format(
                            " MMMM D, YYYY"
                          )
                        : " - "}
                    </span>
                  </div>

                  <div className="col-md-12 col-lg-4 pipeline-popup-grid padd_top_remove">
                    <span className="dialog-content-title">Created by:</span>
                    <span className="dialog-content-value">
                      {/* {currentSalesItem && currentSalesItem.createdBy
                        ? currentSalesItem.createdBy.name
                        : " - "} */}

                      {currentSalesItem && currentSalesItem.accountCreatedBy
                        ? getOrganizationId() ===
                          currentSalesItem.accountCreatedBy.id
                          ? currentSalesItem.createdBy.name
                          : currentSalesItem.accountCreatedBy.name
                        : currentSalesItem.createdBy.name}
                    </span>
                  </div>

                  <div className="col-md-12 col-lg-4 pipeline-popup-grid padd_top_remove">
                    <span className="dialog-content-title">Assigned to:</span>
                    <span className="dialog-content-value">
                      {currentSalesItem && currentSalesItem.assignedTo
                        ? currentSalesItem.assignedTo.name
                        : " - "}
                    </span>
                  </div>
                </div>
              ) : (
                <>
                  <div className={"row " + styles.popup_row}>
                    <div className="col-md-12 col-lg-4 pipeline-popup-grid padd_top_remove">
                      <span className="dialog-content-title">
                        Expected closing date:
                      </span>
                      <span className="dialog-content-value">
                        {currentSalesItem &&
                        currentSalesItem.expected_closing_date &&
                        moment(currentSalesItem.expected_closing_date).format(
                          " MMMM D, YYYY"
                        ) != "Invalid date"
                          ? moment(
                              currentSalesItem.expected_closing_date
                            ).format(" MMMM D, YYYY")
                          : " - "}
                      </span>
                    </div>

                    <div className="col-md-12 col-lg-4 pipeline-popup-grid padd_top_remove">
                      <span className="dialog-content-title">Category:</span>
                      <span className="dialog-content-value">
                        {currentSalesItem &&
                        currentSalesItem.category &&
                        currentSalesItem.category.name
                          ? currentSalesItem.category.name
                          : " - "}
                      </span>
                    </div>

                    <div className="col-md-12 col-lg-4 pipeline-popup-grid padd_top_remove">
                      <span className="dialog-content-title">
                        Sales value:{" "}
                      </span>
                      {currentSalesItem && currentSalesItem.is_confidential ? (
                        <span className="dialog-content-value">
                          {currentSalesItem &&
                          currentSalesItem.lead_value !== ""
                            ? commonFunctions.getConvertedCurrency(
                                currentSalesItem.lead_value
                              )
                            : " - "}
                          {currentSalesItem &&
                          currentSalesItem.currency &&
                          currentSalesItem.lead_value !== ""
                            ? " " +
                              currentSalesItem.currency.short_name +
                              "(Confidential)"
                            : ""}{" "}
                        </span>
                      ) : (
                        <span className="dialog-content-value">
                          {currentSalesItem
                            ? currentSalesItem.lead_value
                              ? commonFunctions.getConvertedCurrency(
                                  currentSalesItem.lead_value
                                )
                              : " - "
                            : " - "}
                          {currentSalesItem &&
                          currentSalesItem.currency &&
                          currentSalesItem.lead_value
                            ? " " + currentSalesItem.currency.short_name
                            : ""}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className={"row " + styles.popup_row}>
                    <div className="col-md-12 col-lg-4 pipeline-popup-grid padd_top_remove">
                      <span className="dialog-content-title">
                        Project location:
                      </span>
                      <span className="dialog-content-value">
                        {currentSalesItem && currentSalesItem.project_location
                          ? currentSalesItem.project_location
                          : " - "}
                      </span>
                    </div>
                    <div className="col-md-12 col-lg-4 pipeline-popup-grid padd_top_remove">
                      <span className="dialog-content-title">
                        Lead creation date:
                      </span>
                      <span className="dialog-content-value">
                        {currentSalesItem && currentSalesItem.created_at
                          ? moment(currentSalesItem.created_at).format(
                              " MMMM D, YYYY"
                            )
                          : " - "}
                      </span>
                    </div>
                    <div className="col-md-12 col-lg-4 pipeline-popup-grid padd_top_remove">
                      <span className="dialog-content-title">Lead source:</span>
                      <span className="dialog-content-value">
                        {currentSalesItem && currentSalesItem.lead_source
                          ? currentSalesItem.lead_source.name
                          : " -  "}
                      </span>
                    </div>
                  </div>
                  <div className={"row " + styles.popup_row}>
                    <div className="col-md-12 col-lg-4 pipeline-popup-grid padd_top_remove">
                      <span className="dialog-content-title">Created by:</span>
                      <span className="dialog-content-value">
                        {currentSalesItem && currentSalesItem.accountCreatedBy
                          ? getOrganizationId() ===
                            currentSalesItem.accountCreatedBy.id
                            ? currentSalesItem.createdBy.name
                            : currentSalesItem.accountCreatedBy.name
                          : currentSalesItem && currentSalesItem.createdBy.name}
                      </span>
                    </div>
                    <div className="col-md-12 col-lg-4 pipeline-popup-grid padd_top_remove">
                      <span className="dialog-content-title">Assigned to:</span>
                      <span className="dialog-content-value">
                        {currentSalesItem && currentSalesItem.assignedTo
                          ? currentSalesItem.assignedTo.name
                          : " - "}
                      </span>
                    </div>
                  </div>
                </>
              )}

              {this.props.documents && this.props.documents.length !== 0 ? (
                <div className={"row " + styles.popup_row}>
                  <div className="col-md-12 col-lg-12  pipeline-popup-document padd_top_remove">
                    <label
                      className={`${styles.form_title} dialog-content-title`}
                    >
                      Uploaded photo/document
                    </label>
                    <div className={styles.uploaded_items}>
                      {this.state.documentList.map((doc) => {
                        return (
                          <div
                            className={styles.uploaded_items_box}
                            key={doc.id}
                          >
                            {this.renderThumbnail(doc)}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : null}
              <div className={"row " + styles.popup_row}>
                <div className="col-md-12 col-lg-12 pipeline-popup-grid padd_top_remove">
                  <span className="dialog-content-title">
                    Most recent note:
                  </span>
                  <span className="dialog-content-value">
                    {this.state.recentNotes
                      ? this.state.recentNotes
                      : currentSalesItem && currentSalesItem.notes
                      ? " " + currentSalesItem.notes
                      : " - "}
                  </span>
                </div>
              </div>
            </div>

            {
              this.canSeeUpdateButton() ? (
                <div className="row dialog-btn dialog-btn-bottom">
                  <div className="col-md-12 text-right">
                    <button
                      type="button"
                      className={`update-btn ${
                        this.props.currentUser.license_type.actual_name ===
                          "BASIC" ||
                        this.props.currentUser.license_type.actual_name ===
                          "LITE" ||
                        currentSalesItem.lead_status.id === 1
                          ? styles.update_btn_margin_space
                          : ""
                      }`}
                      onClick={() =>
                        this.openUpdateLeadPopup(currentSalesItem.is_won == 2)
                      }
                    >
                      {currentSalesItem.is_won == 2 ? "Unpark" : "Update"}
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )
            }
            {this.props.currentUser ? (
              this.props.currentUser.license_type ? (
                this.props.currentUser.license_type.actual_name !== "BASIC" &&
                this.props.currentUser.license_type.actual_name !== "LITE" &&
                currentSalesItem.lead_status.id !== 1 &&
                currentSalesItem.assignedTo &&
                currentSalesItem.assignedTo.id ==
                  this.props.currentUser.user_id ? (
                  <div className={`${styles.container_body} row`}>
                    <div className={`col-xl-4 col-md-6 col-12`}>
                      <div className={styles.activities_container}>
                        <div className={styles.activities_container_header}>
                          <h1 className={styles.activities_container_heading}>
                            Planned events
                          </h1>
                          <div className={styles.activities_container_actions}>
                            {/* <IconButton>
                                    <img src={filterIcon} />
                                </IconButton> */}
                            {currentSalesItem ? (
                              currentSalesItem.lead_status.name !== "Closed" ? (
                                <IconButton
                                  style={{
                                    marginTop: "8px",
                                    marginBottom: "8px",
                                    marginLeft: 0,
                                    marginRight: 0,
                                  }}
                                  onClick={() => this.handleOpenAddActivity(0)}
                                >
                                  <img src={addIcon} />
                                </IconButton>
                              ) : null
                            ) : null}
                            {/* <IconButton
                      style={{
                        marginTop: "8px",
                        marginBottom: "8px",
                        marginLeft: 0,
                        marginRight: 0
                      }}
                      onClick={this.handleOpenAddActivity}
                    >
                      <img src={addIcon} />
                    </IconButton> */}
                            <Dialog
                              className="width"
                              aria-labelledby="customized-add-activities"
                              open={this.state.addSalesActivities}
                              onClose={this.handleClose}
                              maxWidth={"lg"}
                            >
                              {currentSalesItem && (
                                <ActivityTaskNote
                                  close={this.handleClose}
                                  value={this.state.value}
                                  lead_id={currentSalesItem.id}
                                  company_id={
                                    currentSalesItem.contact_company
                                      ? currentSalesItem.contact_company.id
                                      : ""
                                  }
                                  company_name={
                                    currentSalesItem.contact_company
                                      ? currentSalesItem.contact_company
                                          .company_name
                                      : ""
                                  }
                                  person_id={
                                    currentSalesItem.contact_person
                                      ? currentSalesItem.contact_person.id
                                      : ""
                                  }
                                  person_name={
                                    currentSalesItem.contact_person
                                      ? currentSalesItem.contact_person.name
                                      : ""
                                  }
                                  item={this.state.item}
                                />
                              )}
                            </Dialog>
                          </div>
                        </div>
                        {currentSalesItem && (
                          <SalesActivities
                            startDate={moment().format("YYYY-MM-DD")}
                            endDate={null}
                            salesActivitesTasks={this.props.plannedActivities}
                            from="my-pipeline"
                            errorLoadingSalesActivitesTasks={
                              this.props.errorLoadingPlannedActivites
                            }
                            loadingSalesActivitiesTasks={
                              this.props.loadingPlannedActivities
                            }
                            editDelete={true}
                            leadTitle={false}
                            handleUpdateActivity={this.handleUpdateActivity}
                            handleDeleteActivity={this.handleDeleteActivity}
                            handleUpdateTask={this.handleUpdateTask}
                            handleDeleteTask={this.handleDeleteTask}
                            lead_id={currentSalesItem.id}
                          />
                        )}
                      </div>
                    </div>
                    <div className={`col-xl-4 col-md-6 col-12`}>
                      <div className={styles.activities_container}>
                        <div className={styles.activities_container_header}>
                          <h1 className={styles.activities_container_heading}>
                            History
                          </h1>
                        </div>
                        {currentSalesItem && (
                          <SalesActivities
                            startDate={null}
                            endDate={moment().format("YYYY-MM-DD")}
                            salesActivitesTasks={this.props.historyActivities}
                            from="my-pipeline"
                            errorLoadingSalesActivitesTasks={
                              this.props.errorLoadingHistoryActivites
                            }
                            loadingSalesActivitiesTasks={
                              this.props.loadingHistoryActivities
                            }
                            editDelete={false}
                            leadTitle={false}
                            lead_id={currentSalesItem.id}
                          />
                        )}
                      </div>
                    </div>
                    <div className={`col-xl-4 col-md-6 col-12`}>
                      <div className={styles.activities_container}>
                        <div className={styles.activities_container_header}>
                          <h1 className={styles.activities_container_heading}>
                            Notes
                          </h1>
                          <div className={styles.activities_container_actions}>
                            {currentSalesItem ? (
                              currentSalesItem.lead_status.name !== "Closed" ? (
                                <IconButton
                                  style={{
                                    marginTop: "8px",
                                    marginBottom: "8px",
                                    marginLeft: 0,
                                    marginRight: 0,
                                  }}
                                  onClick={() => this.handleOpenAddActivity(2)}
                                >
                                  <img src={addIcon} />
                                </IconButton>
                              ) : null
                            ) : null}
                          </div>
                        </div>
                        {currentSalesItem && (
                          <NotesListingContainer
                            lead_id={currentSalesItem.id}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ) : null
              ) : null
            ) : null}
          </DialogContent>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.leadDetailReducer.errorMessage,
    ringedBell: state.leadDetailReducer.ringedBell,
    isFetching: state.leadDetailReducer.isFetching,
    statusLogs: state.leadDetailReducer.statusLogs,
    plannedActivities: state.leadDetailReducer.plannedActivities,
    historyActivities: state.leadDetailReducer.historyActivities,
    errorLoadingPlannedActivites:
      state.leadDetailReducer.errorLoadingPlannedActivites,
    loadingPlannedActivities: state.leadDetailReducer.loadingPlannedActivities,
    errorLoadingHistoryActivites:
      state.leadDetailReducer.errorLoadingHistoryActivites,
    loadingHistoryActivities: state.leadDetailReducer.loadingHistoryActivities,
    leadUpdated: state.newLead.leadUpdated,
    parkedSelected: state.filterData.parkedSelected,
    filterList: state.filterData.filterValue,
    orgSelected: state.mypipeline.mypipelineOrgSelected,
    userSelected: state.mypipeline.mypipelineUserSelected,
    documents: state.leadDetailReducer.documentListing,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRingBellLead: (requestBody) =>
      dispatch(actions.updateRingBellLead(requestBody)),
    resetRingedBell: (requestBody) =>
      dispatch(actions.resetRingedBell(requestBody)),
    getStatusLogs: (requestBody) =>
      dispatch(actions.getStatusLogs(requestBody)),
    myPipelineGetPlannedActivities: (requestBody) =>
      dispatch(actions.myPipelineGetPlannedActivities(requestBody)),
    myPipelineGetHistoryActivities: (requestBody) =>
      dispatch(actions.myPipelineGetHistoryActivities(requestBody)),
    getNotesListing: (requestBody) =>
      dispatch(actionsNoteListing.getNotesListing(requestBody)),
    resetDocuments: () => dispatch(actions.resetDocuments()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadDetailPopup);
