import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "./redux/actions";
import ConfigureBrregComponent from "./component/ConfigureBrregComponent";

class ConfigureBrregContainer extends Component {
    render() {
        const configureBrregComponentProps = { ...this.props };
        return <ConfigureBrregComponent {...configureBrregComponentProps}
        />;
    }
}

const mapStateToProps = state => {
    //console.log("state in mapstatetoprops", state);
    return {
        ...state,
        idcrm_integration: state.CRMConfigureDetails.idcrm_integration,
        idcrm_master: state.CRMIntegrationDetails.idcrm_master,
        crm_name: state.CRMIntegrationDetails.crm_name,
        isActive: state.CRMIntegrationDetails.isActive,
        configured: state.CRMIntegrationDetails.configured
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setIdCRM_Integration: (idcrm_integration) =>
          //dispatch({type: "SETIDCRM_INTEGRATION", payload: {idcrm_integration: idcrm_integration}}),
          dispatch(actions.setIdCRM_Integration(idcrm_integration))/* ,
        setCRM_name: (crm_name) =>
          dispatch(actions.setCRM_Name(crm_name)),
        setIsActive: (isActive) =>
          dispatch(actions.setCRMisActive(isActive)),
        setConfigured: (configured) =>
          dispatch(actions.setCRMConfigured(configured)) */
      };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConfigureBrregContainer);