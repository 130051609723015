import React from "react";
import { Route, Redirect } from "react-router-dom";
import AsideLeftContainer from "../pages/aside-left/asideLeftContainer";
import {getStorageItem, isParentOrgAdmin} from "../common/common-functions";
import { isAdminUser } from "../common/common-functions";
import ErrorBoundry from '../components/ErrorBoundry';

export const PrivateRoute = ({ component: Component, adminOnly, adminAndParentOnly, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      {/* const currentUser = JSON.parse(localStorage.getItem("user")); */}
      const currentUser = JSON.parse(getStorageItem("user"));
      const path = window.location.pathname;
      const pathArray = path.split("/");
      const url = pathArray[1];
      const hasAccess = adminOnly ? isAdminUser() : adminAndParentOnly ? isParentOrgAdmin() : true;
      if (hasAccess && currentUser && currentUser.role && currentUser.role.id) {
        if (
          currentUser.license_type &&
          ( currentUser.license_type.actual_name === "BASIC" ||
            currentUser.license_type.actual_name === "LITE" ||
            currentUser.license_type.id === 1 ||
            currentUser.license_type.display_name === "Basic Role" ||
            currentUser.license_type.display_name === "Lite Role") &&
          url === "activities"
        ) {
          return <Redirect to="/sales-news" />;
        }
        return (
          <div>
            <AsideLeftContainer {...props} />
            <div className="responsive-aside-left">
              <ErrorBoundry key={props.location.pathname}>
                <Component {...props} />
              </ErrorBoundry>
            </div>
          </div>
        );
      } else {
        return <Redirect to="/login" />;
      }
    }}
  />
);
