import axios from "../../common/api-config";
// const FileDownload = require('js-file-download');

const BASE_URL = process.env.REACT_APP_API_BASE_URL.replace('/api/v3', '');

function toTitleCase(str='') {
  return str.replace(
      /\w\S*/g,
      function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
  );
}

export default {
  async bulkUpload(organization_id, file) {
    const formData = new FormData();
    formData.append('organization_id', organization_id);
    formData.append('file', file);
    const url = '/users/bulkUpload';
    const result = await axios.post(url, formData);
    return result.data.data.map(r => ({
      ...r,
      name: `${r.first_name} ${r.last_name}`,
      contact: `+${r.country_code} ${r.contact}`,
      role: toTitleCase(r.role)
    }));
  },

  async getOrganizations() {
    const url = '/account/getOrganizations';
    const result = await axios.post(url, {});
    return result.data.data.rows;
  },
  getMyAccount() {
    const url = '/users/getMyAccount';
    return axios.post(url, {}).then(response => {
      return response.data.data.data.name;
    });
  },
  downloadSampleCSV() {
    const url = '/users/downloadSampeFile';
    return axios.post(url, {}).then(response => {
      window.open(`${BASE_URL}/temp/user.csv`, 'Download');
    });
  },
  getParentAccountDetail() {
    const MOCK_DATA = {
      "language_id": 1,
      "date_format_id": 1,
      "timezone_id": 1,
      "dns": "abc.com",
      "client_id": 'slls',
      'account_group': { id: 1, name: 'LeadX Test'  },
    };
    return Promise.resolve(MOCK_DATA);
  }
}