import axios from "./api-config";
import {getStorageItem, isParentOrgAdmin} from "../common/common-functions";

export default {
  async getOrganizations(currentOrgId) {
    try {
      const currentUser = JSON.parse(getStorageItem("user"));
      const url = '/account/getOrganizationList';
      const response = await axios.post(url, {});
      if (response.data.statusCode === 200) {
          return response.data.data.rows.map(a => ({...a, my_account: a.id === currentUser.organization_id}));
      } else {
          throw new Error('No org found');
      }
    } catch (error) {
        return currentOrgId ? [currentOrgId] : [];
    }
  },
  async inviteUsers(organization_id, users) {
    const url = '/users/inviteUsers';
    const result = await axios.post(url, { organization_id, users: users.map(u => ({
        ...u, 
        initials: u.initials.value, 
        country_code: u.country_code.value, 
        role: u.license_type_id.label 
    })) });
    return result.data.data;
  },
  async getLicenseTypes() {
    const url = '/master/getLicenseType';
    const result = await axios.post(url, {});
    return result.data.data.rows;
  },
  async getCountries() {
    const url = '/master/territory/country';
    const result = await axios.post(url, {});
    return result.data.data.rows;
  },
  markAsDelete(lead_id) {
    const url = `/leads/${lead_id}`;
    return axios.delete(url, {});
  }
}