import { useState, useEffect } from "react";
import Api from "./Api";

const isMatching = (string = "", searchString = "") => {
  return string.toLowerCase().search(searchString.trim().toLowerCase()) !== -1;
};

const toggoleModuleMapper = (row, newChecked) => (record) => {
  if (record.id === row.id) {
    record.active = newChecked;
  }
  return record;
};

const toggleModule = (moduleAndActions, row, newChecked) =>
  moduleAndActions.map(toggoleModuleMapper(row, newChecked));

const toggleFeature = (moduleAndActions, row, action, newChecked) =>
  moduleAndActions.map((record) => {
    if (record.id === row.id) {
      record.features = record.features.map((a) => {
        if (a.id === action.id) {
          a.active = newChecked;
        }
        return a;
      });
    }
    return record;
  });

const openCloseModule = (moduleAndActions, row) =>
  moduleAndActions.map((record) => {
    if (record.id === row.id) {
      record.open = !record.open;
    }
    return record;
  });

const hasSomeFeatureMatch = (module, searchText) =>
  module.features.some((feature) => isMatching(feature.name, searchText));

const filterFeature = (searchText) => (module) => ({
  ...module,
  features: module.features.filter((feature) =>
    isMatching(feature.name, searchText)
  ),
});

const getSearchedModuleAndFeatures = (moduleAndActions, searchText) =>
  moduleAndActions
    .filter(
      (module) =>
        isMatching(module.name, searchText) ||
        hasSomeFeatureMatch(module, searchText)
    )
    .map(filterFeature(searchText));

function useRoleConfiguration() {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [licenseOptions, setLicenseOptions] = useState([]);
  const [selectedLicense, setSelectedLicense] = useState(null);
  const [moduleAndActions, setModuleAndActions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchDependecies();
  }, []);

  useEffect(() => {
    if (selectedLicense) {
      fetchModuleAndActions(selectedLicense);
    }
  }, [selectedLicense]);

  const fetchModuleAndActions = async (selectedLicense) => {
    setIsLoading(true);
    const moduleAndActions = await Api.getModuleAndActionsForLicenseType(
      selectedLicense.value
    );

    setModuleAndActions(moduleAndActions);
    setIsLoading(false);
  };

  const fetchDependecies = async () => {
    setIsLoading(true);
    const licenseTypeOptions = await Api.getLicenseTypes();

    setLicenseOptions(licenseTypeOptions);
    const selectedLicenseType = licenseTypeOptions[0];
    setSelectedLicense(selectedLicenseType);

    setIsLoading(false);
  };

  const handleOnSearch = (searchString) => {
    setSearchText(searchString);
  };

  const handleOnSave = async (callback) => {
    setLoading(true);

    const feature_set_ids = [];
    moduleAndActions.forEach((module) => {
      module.features.forEach((feature) => {
        feature_set_ids.push({ id: feature.id, active: feature.active });
      });
    });

    const response = await Api.saveRoleConfiguration(
      selectedLicense.value,
      feature_set_ids
    );

    callback(response);
    setLoading(false);
  };

  const toggleCheckModule = (row) => (newChecked) => {
    setModuleAndActions(toggleModule(moduleAndActions, row, newChecked));
  };

  const onToggleCheckAction = (row) => (action) => (newChecked) => {
    setModuleAndActions(
      toggleFeature(moduleAndActions, row, action, newChecked)
    );
  };

  const toggleOpenModule = (row) => () => {
    setModuleAndActions(openCloseModule(moduleAndActions, row));
  };

  const handleOnCancel = () => {};

  let searchedModuleAndFeatures = getSearchedModuleAndFeatures(
    moduleAndActions,
    searchText
  );

  return {
    state: {
      loading,
      searchText,
      licenseOptions,
      selectedLicense,
      updating: isLoading,
      searchedModuleAndFeatures,
    },
    actions: {
      handleOnCancel,
      handleOnSave,
      handleOnToggleCheckFeature: onToggleCheckAction,
      handleOnToggleCheckModule: toggleCheckModule,
      handleOnExpandClick: toggleOpenModule,
      handleOnSearch,
      handleOnLicenseSelect: setSelectedLicense,
    },
  };
}

export default useRoleConfiguration;
