export const NEW_SELECTED = "NEW_SELECTED";
export const LEAD_SELECTED = "LEAD_SELECTED";
export const OPPORTUNITY_SELECTED = "OPPORTUNITY_SELECTED";
export const PROPOSAL_SELECTED = "PROPOSAL_SELECTED";
export const NEGOTIATION_SELECTED = "NEGOTIATION_SELECTED";
export const CLOSED_SELECTED = "CLOSED_SELECTED";
export const NEW_REMOVED = "NEW_REMOVED";
export const LEAD_REMOVED = "LEAD_REMOVED";
export const OPPORTUNITY_REMOVED = "OPPORTUNITY_REMOVED";
export const PROPOSAL_REMOVED = "PROPOSAL_REMOVED";
export const NEGOTIATION_REMOVED = "NEGOTIATION_REMOVED";
export const CLOSED_REMOVED = "CLOSED_REMOVED";
export const PARKED_FILTER_SELECTED = "PARKED_FILTER_SELECTED";
export const PARKED_FILTER_DESELECTED = "PARKED_FILTER_DESELECTED";
export const RESET_FILTER = "RESET_FILTER";
