import React, { Component } from 'react';

export default class RedirectComponent extends Component {
    
    componentDidMount () {
        const newRedirection = this.props.location.search.split("url=");
        
        if(newRedirection[1].startsWith("leadxAS360")) {
            window.location.replace(newRedirection[1])
            this.props.history.replace('/login')
        } else {
            this.props.history.replace('/login')
        }
        
    }

    render() {
        return (
            <div></div>
        )
    }
}
