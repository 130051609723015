import * as types from "./types";

const initialState = {
  salesActivitesTasks: null,
  errorLoadingSalesActivitesTasks: false,
  loadingSalesActivitiesTasks: true,
  errorMessage: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SALES_ACTIVITIES_TASKS_SUCCESS:
      return {
        ...state,
        errorLoadingSalesActivitesTasks: false,
        salesActivitesTasks: action.data,
        loadingSalesActivitiesTasks: false
      };

    case types.GET_SALES_ACTIVITIES_TASKS_FAIL:
      return {
        ...state,
        errorLoadingSalesActivitesTasks: true,
        salesActivitesTasks: null,
        loadingSalesActivitiesTasks: false
      };

    case types.LOAD_SALES_ACTIVITIES_TASKS:
      return {
        ...state,
        salesActivitesTasks: null,
        errorLoadingSalesActivitesTasks: false,
        loadingSalesActivitiesTasks: true
      };

    default:
      return state;
  }
};

export default reducer;
