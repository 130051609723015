import React, { Component } from "react";
import styles from "./StatusMappingCRMComponent.module.scss";
import { withStyles } from "@material-ui/core/styles";
import "rc-time-picker/assets/index.css";
import OopsError from "../../../components/ErrorMessages/OopsError/OopsError";
import WizardError from "../../../components/ErrorMessages/WizardError/WizardError";
import NoRecordsFound from "../../../components/ErrorMessages/NoRecordsFound/NoRecordsFound";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import SmallLoader from "../../../common/Loaders/CircularLoader/circular-loader";
import { openSnackbar } from "../../../common/Snackbar/snackbar";
import axios from "../../../common/api-configCRM";
import LargeScreenLoader from "../../../common/Loaders/PulseLoader/pulse-loader";
import infoIcon from "../../../../../src/assets/images/content-images/info_icon.png";
const stylesMaterialUI = () => ({});

class StatusMappingCRMComponent extends Component {
  state = {
    errorLoadingStatusMappingDetails: false,
    loadingStatusMappingDetails: true,
    errorInWizard: false,
    leadstatus: [],
    errorLoadingLeadStatus: false,
    loadingLeadStatusDetails: true,
    showLoader: false,
    hspipelines: []
  }
  constructor(props) {
    super(props);
    console.log("status mo", this.props);
    this.state = {
      objectMapping: this.props.fieldMapping,
      leadstatus: [],
      hspipelines: [],
      //crm_name: this.props.crm_name
    };
  }

  componentDidMount() {
    console.log("props in status mapping ", this.props.leadLeadMappingSelected);
    console.log("props in pipelines", this.props.hspipelines );
    if (this.props.idcrm_integration !== undefined && this.props.idcrm_integration !== null 
      && this.props.fieldMapping !== undefined && this.props.fieldMapping !== null && this.props.fieldMapping.length > 0) {
      
        this.setState({ objectMapping: this.props.fieldMapping }, () => {
        //console.log("object mapping in status", this.state.objectMapping);
      });
      
      this.setState({
        loadingStatusMappingDetails: false,
        errorLoadingStatusMappingDetails: false
      });
      // get all crm_statuses based on lead-lead or lead-opportunity
      console.log("crm master ", this.props.idcrm_master);

      if(this.props.idcrm_master == 3) { // SuperOffice 
        console.log("crm_so_statuses lead", this.props.idcrm_master);
        //axios.get(`/crm_so_statuses/get/${this.props.idcrm_integration}/Lead`).then(response => {
        this.setState({showLoader: true});
        axios.get(`/get_so_statuses/get/${this.props.idcrm_integration}`).then(response => {
          //console.log("status response: ", response.data);
          if (response !== undefined &&
            response.data !== undefined &&
            response.data.leadstatus !== undefined ) {
            if (response.data.leadstatus !== undefined) {
              if (response.data.leadstatus.length > 0) {
                //console.log("fieldmapping", this.props.fieldMapping);
                this.setState({
                  leadstatus: response.data.leadstatus.map(status=> status.crm_status),
                  loadingLeadStatusDetails: false,
                  errorLoadingLeadStatus: false,
                  showLoader: false
                }, () => {
                });
                this.setState({leadstatus:response.data.leadstatus});
              }
             
            } else {
              openSnackbar("Data Not Found.", "snack-error");
              this.setState({
                loadingLeadStatusDetails: false,
                errorLoadingLeadStatus: true,
                showLoader: false
              });
            }
          } else {
            openSnackbar("Unable to Get CRM Statuses Opp", "snack-error");
            this.setState({
              loadingLeadStatusDetails: false,
              errorLoadingLeadStatus: true,
              showLoader: false
            });
          }
        }).catch((error) => {
          openSnackbar("Exception Getting CRM Statuses", "snack-error");
          this.setState({
            loadingLeadStatusDetails: false,
            errorLoadingLeadStatus: true,
            showLoader: false
          });
          console.log(error);
        });
      }
      else if(this.props.idcrm_master == 5) { // HubSpot 

        console.log("crm_hs_statuses lead", this.props.idcrm_master);
        //axios.get(`/crm_so_statuses/get/${this.props.idcrm_integration}/Lead`).then(response => {
        this.setState({showLoader: true});
        axios.get(`/get_hs_statuses/get/${this.props.idcrm_integration}/${this.props.CRMConfigureDetails.integrationDetails.crm_org_id}`).then(response => {
          console.log("status response: ", response.data);
          if (response !== undefined &&
            response.data !== undefined &&
            response.data.leadstatus !== undefined ) {
            if (response.data.leadstatus !== undefined) {
              if (response.data.leadstatus.length > 0) {
                //console.log("fieldmapping", this.props.fieldMapping);
                this.setState({
                  leadstatus: response.data.leadstatus.map(status=> status.crm_status),
                  loadingLeadStatusDetails: false,
                  errorLoadingLeadStatus: false,
                  showLoader: false
                }, () => {
                });
                this.setState({leadstatus:response.data.leadstatus});
              }
             
            } else {
              openSnackbar("Data Not Found.", "snack-error");
              this.setState({
                loadingLeadStatusDetails: false,
                errorLoadingLeadStatus: true,
                showLoader: false
              });
            }
          } else {
            openSnackbar("Unable to Get CRM Statuses", "snack-error");
            this.setState({
              loadingLeadStatusDetails: false,
              errorLoadingLeadStatus: true,
              showLoader: false
            });
          }
        }).catch((error) => {
          openSnackbar("Exception Getting CRM Statuses", "snack-error");
          this.setState({
            loadingLeadStatusDetails: false,
            errorLoadingLeadStatus: true,
            showLoader: false
          });
          console.log(error);
        });
      }
      else if (this.props.leadLeadMappingSelected == true) {
        axios.get(`/crm_statuses/get/${this.props.idcrm_integration}/Lead`).then(response => {
          //console.log("crm_statuses lead", response);
          if (response !== undefined &&
            response.data !== undefined &&
            response.data.status !== undefined &&
            response.data.status === 0) {
            if (response.data.leadstatus !== undefined) {
              if (response.data.leadstatus.length > 0) {
                this.setState({
                  leadstatus: response.data.leadstatus,
                  loadingLeadStatusDetails: false,
                  errorLoadingLeadStatus: false
                });
              }
            } else {
              openSnackbar("CRM Statuses Not Found.", "snack-error");
              this.setState({
                loadingLeadStatusDetails: false,
                errorLoadingLeadStatus: true
              });
            }
          } else {
            openSnackbar("Unable to Get CRM Statuses Lead", "snack-error");
            this.setState({
              loadingLeadStatusDetails: false,
              errorLoadingLeadStatus: true
            });
          }
        }).catch((error) => {
          openSnackbar("Exception Getting CRM Statuses", "snack-error");
          this.setState({
            loadingLeadStatusDetails: false,
            errorLoadingLeadStatus: true
          });
          console.log(error);
        });
      } else {
         
        axios.get(`/crm_statuses/get/${this.props.idcrm_integration}/Opportunity`).then(response => {
          //console.log("crm_statuses lead", response);
          if (response !== undefined &&
            response.data !== undefined &&
            response.data.status !== undefined &&
            response.data.status === 0) {
            if (response.data.leadstatus !== undefined) {
              if (response.data.leadstatus.length > 0) {
                this.setState({
                  leadstatus: response.data.leadstatus,
                  loadingLeadStatusDetails: false,
                  errorLoadingLeadStatus: false
                }, () => {
                  //console.log("leadstatus", this.state.leadstatus);
                });
              }
            } else {
              openSnackbar("Data Not Found.", "snack-error");
              this.setState({
                loadingLeadStatusDetails: false,
                errorLoadingLeadStatus: true
              });
            }
          } else {
            openSnackbar("Unable to Get CRM Statuses Opp", "snack-error");
            this.setState({
              loadingLeadStatusDetails: false,
              errorLoadingLeadStatus: true
            });
          }
        }).catch((error) => {
          openSnackbar("Exception Getting CRM Statuses", "snack-error");
          this.setState({
            loadingLeadStatusDetails: false,
            errorLoadingLeadStatus: true
          });
          console.log(error);
        });
      }
    } else {
      this.setState({ errorInWizard: true });
    }

  }

  handleCloseClick = () => {
    // console.log("Close clicked");
  };

  handleGoBackClicked = () => {
    // console.log("go back clicked");
    this.setState({ goBack: true });
    this.props.history.goBack();
  }

  checkValidity() {
    if (this.state.objectMapping !== undefined &&
      this.state.objectMapping.length > 0) {
      const defaultStatusMappingObj = this.state.objectMapping.find(item => {
        return item.leadx_status.toLowerCase() == "default"
      });
      //console.log("defaultStatusObjMap", defaultStatusMappingObj);
      //console.log("defaultStatusObjMap status", defaultStatusMappingObj.crm_status);
      if (defaultStatusMappingObj !== undefined && defaultStatusMappingObj !== null) {
        if (defaultStatusMappingObj.crm_status == undefined ||
          defaultStatusMappingObj.crm_status == null || 
          defaultStatusMappingObj.crm_status.trim() == "") {
          //console.log("InFALSE");
          return false;
        } else {
          if (defaultStatusMappingObj.crm_status == "-1") {
            //console.log("InFALSE1");
            return false;
          } else {
            //console.log("INTRUE");
            return true;
          }
        }
      }
    }
  }

  handleSaveClicked = () => {
    if (this.checkValidity() === true) {
      let params = null;
      if (this.state.objectMapping !== undefined &&
        this.state.objectMapping.length > 0) {
        params = {
          crm_id: this.props.idcrm_integration,
          data_rows: this.state.objectMapping
        }
        //console.log("params", params);
        axios
          .post('/statusmapping/update/', { data: JSON.stringify(params) })
          .then(response => {
            console.log("response in savenproceed", response);
            if (response !== undefined && response.data.status !== undefined && response.data.status === 0) {
              openSnackbar("Saved!", "snack-success");
            } else {
              openSnackbar("Something Went Wrong!", "snack-error");
            }
          })
          .catch((error) => {
            console.log(error);
            openSnackbar("Unable to Update Status Mapping Information", "snack-error");
          })
      }
    } else {
      openSnackbar("Error Saving Mappings - Make sure Default Mapping is Set", "snack-error");
    }
  }

  handleSaveNProceedClicked = (e) => {
    if (this.checkValidity() === true) {
      let params = null;
      if (this.state.objectMapping !== undefined &&
        this.state.objectMapping.length > 0) {
        params = {
          crm_id: this.props.idcrm_integration,
          data_rows: this.state.objectMapping
        }
        axios
          .post('/statusmapping/update/', { data: JSON.stringify(params) })
          .then(response => {
            //console.log("response in savenproceed", response);
            if (response !== undefined && response.data.status !== undefined &&
              response.data.status === 0) {
              openSnackbar("Saved!", "snack-success");
              this.props.history.push({
                pathname: '/integrations/assignsalesteamCRM',
                state: {}
              });
            } else {
              openSnackbar("Something Went Wrong!", "snack-error");
            }
          })
          .catch((error) => {
            console.log(error);
            openSnackbar("Unable to Update Status Mapping Information", "snack-error");
          })
      }
    } else {
      openSnackbar("Error Saving Mappings - Make sure Default Mapping is Set", "snack-error");
    }
  }

  toggleRingTheBellChange = (event, statusMap) => {
    //console.log("event", event.target.value);
    //console.log("statusmap", statusMap);
    const selected_StatusId = statusMap.id;
    if (this.state.objectMapping !== undefined &&
      this.state.objectMapping !== null) {
      const temp_ObjectMapping = [...this.state.objectMapping];
      for (let index = 0; index < temp_ObjectMapping.length; index++) {
        const element = temp_ObjectMapping[index];
        if (element !== undefined && element !== null) {
          if (selected_StatusId == element.id) {
            //modify the flag 
            if (element.ring_the_bell == null) {
              element.ring_the_bell = 0;
            }
            const selectedElement = {
              ...element,
              ring_the_bell: element.ring_the_bell == 0 ? 1 : 0
            }
            //console.log("selectedElement", selectedElement);
            if (selectedElement !== undefined) {
              temp_ObjectMapping[index] = selectedElement;
            }
            this.setState({ objectMapping: temp_ObjectMapping }, () => {
              //console.log("updated obj mapping", this.state.objectMapping);
            });
          }
        }
      }
    }
  }

  toggleConvertToOpportunityChange = (event, statusMap) => {
    //console.log("event", event.target.value);
    //console.log("statusmap", statusMap);
    const selected_StatusId = statusMap.id;
    if (this.state.objectMapping !== undefined &&
      this.state.objectMapping !== null) {
      const temp_ObjectMapping = [...this.state.objectMapping];
      for (let index = 0; index < temp_ObjectMapping.length; index++) {
        const element = temp_ObjectMapping[index];
        if (element !== undefined && element !== null) {
          if (selected_StatusId == element.id) {
            //modify the flag 
            if (element.convert_to_Opportunity == null) {
              element.convert_to_Opportunity = 0;
            }
            const selectedElement = {
              ...element,
              convert_to_Opportunity: element.convert_to_Opportunity == 0 ? 1 : 0
            }
            //console.log("selectedElement", selectedElement);
            if (selectedElement !== undefined) {
              temp_ObjectMapping[index] = selectedElement;
            }
            this.setState({ objectMapping: temp_ObjectMapping }, () => {
              //console.log("updated obj mapping 1", this.state.objectMapping);
            });
          }
        }
      }
    }
  }

  handleCRMStatusChange = (event, statusMap) => {
    const value = event.target.value;
    //console.log("value", value);
    //console.log("statusMap", statusMap);
    const selected_StatusId = statusMap.id;
    if (this.state.objectMapping !== undefined &&
      this.state.objectMapping !== null) {
      const temp_ObjectMapping = [...this.state.objectMapping];
      for (let index = 0; index < temp_ObjectMapping.length; index++) {
        const element = temp_ObjectMapping[index];
        if (element !== undefined && element !== null) {
          if (selected_StatusId == element.id) {
            //modify the crm_status 
            const selectedElement = {
              ...element,
              crm_status: value
            }
            //console.log("selectedElement", selectedElement);
            if (selectedElement !== undefined) {
              temp_ObjectMapping[index] = selectedElement;
            }
            this.setState({ objectMapping: temp_ObjectMapping }, () => {
              //console.log("updated obj mapping 2", this.state.objectMapping);
            });
          }
        }
      }
    }
  }

  render() {
    return (

      <React.Fragment>
        {
          this.state.showLoader && <LargeScreenLoader />
        }
        <div className={styles.main_wrapper}>
          <div className="row">
            <div className="col-12">

              <div className={"breadcrumbs row " + styles.centerAlign}>
                <div className="col-md-12 col-12">
                  <h2 className="heading">Integrations</h2>
                  <div className="breadcrumbs_container">
                    <Breadcrumbs separator="›" arial-label="Breadcrumb">
                      {/* <Link to="/sales-news">Home</Link>
                      <Link to="/integrations">Integrations</Link>
                      <Link to="/integrations/configureCRM">Configure CRM</Link> */}
                      {/* <Link to="/integrations/authenticateCRM">Authenticate</Link> */}
                      <span>Home</span>
                      <span>Integrations</span>
                      <span>Configure CRM</span>
                      <span>Configure Data Object Mappings</span>
                      <span>Configure Status Mappings</span>
                    </Breadcrumbs>
                  </div>
                </div>
              </div>
              <div className="row container-body">
                <div className={`col-xs-12 col-md-12 col-lg-12 col-12`}>
                  <div className={styles.status_mapping_container}>

                    <div className={styles.status_mapping_container_header}>
                      <h1 className={styles.status_mapping_container_heading}>
                        Step 3 of 5 - Configure Status Mapping
                      </h1>
                    </div>

                    <div className={styles.status_mapping_container_footer} >
                      {/* start */}
                      {this.state.errorInWizard ? (<div><WizardError /></div>) :
                        this.state.errorLoadingStatusMappingDetails ||
                          this.state.errorLoadingLeadStatus ?
                          (<div><OopsError /></div>) :
                          this.state.loadingStatusMappingDetails ||
                            this.state.loadingLeadStatusDetails ? (<SmallLoader />) :
                            this.props.fieldMapping.length === 0 ?
                              (<div><NoRecordsFound /></div>) :
                              (<div>
                                <div className="row">
                                  <div className="col-12">
                                  {this.props.idcrm_master == 5 ? //Hubspot  
                                    <>
                                    <div className='ml-4 mt-3 mb-2'>
                                      <span>
                                        <img src={infoIcon} title='Integration Information' className={ styles.info }/> The HubSpot integration wizard is connected to the <b>"{this.props.CRMConfigureDetails.integrationDetails.crm_org_name}"</b> pipeline to get Stages.  
                                      </span>
                                    </div>
                                    </> 
                                    : null
                                  }
                                   
                                  
                                    <div className="bg-white ml-4 mr-4">
                                      <table className="table table-striped- table-bordered table-hover table-checkable mt-4 mb-4">
                                        <thead>
                                          <tr style={{ textAlign: 'center' }}>
                                            <th style={{ width: '35%', textAlign: 'center' }}>LeadX</th>
                                            {this.props.leadLeadMappingSelected == true ?
                                              <th style={{ width: '30%', textAlign: 'center' }}>Settings</th> : <></>}
                                            {this.props.idcrm_master == 3 ? 
                                                  <th style={{ width: '35%', textAlign: 'center' }}>SuperOffice</th> :
                                              this.props.idcrm_master == 5 ? 
                                                  <th style={{ width: '35%', textAlign: 'center' }}>HubSpot</th> :
                                              this.props.idcrm_master == 1 ?    
                                                  <th style={{ width: '35%', textAlign: 'center' }}>Salesforce</th> :
                                                  <th style={{ width: '35%', textAlign: 'center' }}>CRM</th>
                                            }
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.props.fieldMapping.map((statusMap, index) =>   
                                            <tr key={index} style={{ textAlign: 'center' }}>
                                              <td style={{ width: '20%', textAlign: 'center', verticalAlign: 'center' }}>
                                                <h6><strong>{statusMap.leadx_status}</strong></h6>
                                              </td>
                                              {this.props.leadLeadMappingSelected == true ?
                                                <td style={{ width: '45%', textAlign: 'left' }}>
                                                  {statusMap.leadx_status.toLowerCase() == "closed-won" ?
                                                    <>
                                                     {/*  <label>
                                                        <input type="checkbox" defaultValue={statusMap.convert_to_Opportunity}
                                                          defaultChecked={statusMap.ring_the_bell}
                                                          onChange={(event) => this.toggleRingTheBellChange(event, statusMap)}
                                                        />
                                                        &nbsp;&nbsp;&nbsp;&nbsp;Ring The Bell When Status is Closed-Won
                                                      </label> */}
                                                      <br />
                                                      <label>
                                                        <input type="checkbox" defaultValue={statusMap.convert_to_Opportunity}
                                                          defaultChecked={statusMap.convert_to_Opportunity}
                                                          onChange={(event) => this.toggleConvertToOpportunityChange(event, statusMap)}
                                                        />
                                                        &nbsp;&nbsp;&nbsp;&nbsp;Convert Lead With Status Closed-Won to Opportunity in Sales Force
                                                        </label>
                                                    </>
                                                    : <></>}
                                                </td>
                                                : <></>}
                                              <td style={{ width: '35%', textAlign: 'center' }}>
                                                {/* {statusMap.crm_status} */}
                                                 {/* {this.state.leadstatus.map(status => <label>status is : {status.crm_status}</label>)} */}
                                                {/* <input name="user_id" onChange={(event) => this.handleCRMStatusChange(event, statusMap)} defaultValue={statusMap.crm_status} className={styles.profileContainer_inputStyle__1Dduu} type="text" autoComplete="off" /> */}
                                                <select className={styles.profileContainer_inputStyle__1Dduu} style={{ backgroundColor: '#fff' }} onChange={(event) => this.handleCRMStatusChange(event, statusMap)}>
                                                  <option value={-1} selected={statusMap.crm_status == ''}>--- Choose Status ---</option>
                                                  {console.log('lead status in mapping : ', this.state.leadstatus, statusMap.crm_status)}
                                                  { 
                                                   this.state.leadstatus.map((crm_stat, index) =>
                                                    <option key={index} value={crm_stat} selected={statusMap.crm_status == crm_stat}>{crm_stat}</option>
                                                  )}
                                                </select>
                                              </td>
                                            </tr>
                                          )
                                          }
                                        </tbody>
                                      </table>
                                      <hr className="jss201" />
                                      <div className="mt-3 mb-5">
                                        <div style={{ textAlign: 'right' }}>
                                          <button onClick={this.handleGoBackClicked} className={styles.profileContainer_update_btn__MXNDH} type="button" style={{ backgroundColor: '#e0e0e0', padding: '10px 20px', color: 'rgba(0, 0, 0, 0.87)' }}>Go Back</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <button onClick={() => this.handleSaveClicked("step2")} type="button" className={styles.profileContainer_update_btn__MXNDH} style={{ padding: '10px 20px' }}>Save</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                  <button onClick={() => this.handleSaveNProceedClicked("step2")} type="button" className={styles.profileContainer_update_btn__MXNDH} style={{ padding: '10px 20px' }}>Save &amp; Proceed</button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>)
                      }

                      {/* end */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment >
    );
  }
}
export default withStyles(stylesMaterialUI)(StatusMappingCRMComponent);
