import React, { Component } from "react";
import { connect } from "react-redux";
import StatusMappingCRMComponent from "./component/StatusMappingCRMComponent";

class StatusMappingCRMContainer extends Component {
  render() {
    const statusMappingCRMComponentProps = { ...this.props };
    return <StatusMappingCRMComponent {...statusMappingCRMComponentProps} />;
  }
}

const mapStateToProps = state => {
  return {
    ...state,
    idcrm_integration: state.CRMConfigureDetails.idcrm_integration,
    idcrm_master: state.CRMIntegrationDetails.idcrm_master,
    crm_name: state.CRMIntegrationDetails.crm_name,
    fieldMapping: state.CRMConfigureObjMappingDetails.fieldMapping,
    leadLeadMappingSelected: state.CRMConfigureObjMappingDetails.leadLeadMappingSelected
  };
};


export default connect(
  mapStateToProps
)(StatusMappingCRMContainer);
