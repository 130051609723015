import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "./redux/actions";
import MyPipeline from "./component/my-pipeline";
import * as communityActions from "../SalesCommunity/redux/actions";
import * as leadActions from '../newLead/redux/actions';
import {getStorageItem} from "../../common/common-functions";

class MyPipelineContainer extends Component {
  render() {
    const MyPipelineProps = { ...this.props };
    const currentUser = JSON.parse(getStorageItem("user"));
    return <MyPipeline {...MyPipelineProps} currentUser={currentUser} />;
  }
}

const mapStateToProps = state => {  
  return {
    leads: state.mypipeline.leads,
    leadsCount: state.mypipeline.leadsCount,
    contactList: state.mypipeline.contactList,
    contactCount: state.mypipeline.contactCount,
    revenueData: state.salesCommunity.revenueData,
    errorMessage: state.mypipeline.errorMessage,
    isFetching: state.mypipeline.isFetching,
    unparkLeadFlag: state.mypipeline.unparkLeadFlag,
    lead: state.newLead.lead,
    leadUpdated: state.newLead.leadUpdated,
    errorLoadingMypipelineContacts: state.mypipeline.errorLoadingMypipelineContacts,
    errorLoadingMypipelineLeads: state.mypipeline.errorLoadingMypipelineLeads,
    organizationList: state.asideLeft.organizationListNews,

    contactsLoading: state.mypipeline.contactsLoading,

    orgSelected: state.mypipeline.mypipelineOrgSelected,
    userSelected: state.mypipeline.mypipelineUserSelected,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPipelineLeadList: requestBody =>
      dispatch(actions.getPipelineLeadList(requestBody)),
    getContactList: requestBody =>
      dispatch(actions.getContactList(requestBody)),
    getRevenue: requestBody =>
      dispatch(communityActions.getRevenue(requestBody)),
    unparkLead: requestBody => dispatch(actions.unparkLead(requestBody)),
    resetUnparkLeadFlag: requestBody => dispatch(actions.resetUnparkLeadFlag(requestBody)),
    
    selectMypipelineOrg: requestBody => dispatch(actions.selectMypipelineOrg(requestBody)),
    selectMypipelineUser: requestBody => dispatch(actions.selectMypipelineUser(requestBody)),
    resetError: () => dispatch(actions.resetError())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyPipelineContainer);
