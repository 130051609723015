import {useState, useEffect} from 'react';

function useTableWithAPI(fetchAPI, advanceSearch, key, onCountChange) {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [result, setResult] = useState({count: 0, rows: []});

  const handleOnOrderChange = (property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
      setPage(0);
  };

  useEffect(() => {
      if (advanceSearch) {
        fetchData();
      }
  }, [page, rowsPerPage, order, orderBy, advanceSearch]);

  const fetchData = async () => {
      const result = await fetchAPI(page, rowsPerPage, order, orderBy, advanceSearch);
      setResult(result);
      onCountChange(key, result.count);  
  };

  const onPageChange = (newPage) => setPage(newPage); 

  const onRowsPerPageChange = (newRowsPerPage) => setRowsPerPage(newRowsPerPage);

  return { 
    count: result.count, 
    data: result.rows, 
    page, 
    order, 
    orderBy,
    rowsPerPage, 
    handleOnOrderChange, 
    setRowsPerPage, 
    onPageChange, 
    onRowsPerPageChange 
  };
}

export default useTableWithAPI;