import axios from "../../../common/api-config";

export default {
  doRegistration(data) {
    return axios.post('/login/register', data)
      .then(response => {
        return response.data;
      });
  },
  getCountryCodes() {
    return axios.post('/login/countries', {}).then(response => {
      return response.data;
    });
  },
  async getAllRegisterAccount() {
    const url = 'account/getAllRegisterAccount';
    const result = await axios.post(url, {});
    return result.data.data.data.rows;
  }
}