import axios from "../../../common/api-config";
import * as commonTypes from "../../../common/common-types";
import API from "../API";

export const updateRingBellLead = (requestBody) => {
  return (dispatch) => {
    dispatch({ type: commonTypes.HANDLE_LOADING, data: true });

    axios
      .put(`/leads/${requestBody.id}`, requestBody)
      .then(async (response) => {
        if (response && response.data && response.data.statusCode == 200) {
          await dispatch({
            type: "RING_BELL_SUCCESS",
            data: response.data.data,
          });
          dispatch({ type: commonTypes.HANDLE_LOADING, data: false });
        } else {
          await dispatch({
            type: "RING_BELL_ERROR",
            data: response.data.errorMessage,
          });
          dispatch({ type: commonTypes.HANDLE_LOADING, data: false });
        }
      })
      .catch(async (error) => {
        await dispatch({ type: "RING_BELL_ERROR", data: error.errorMessage });
        dispatch({ type: commonTypes.HANDLE_LOADING, data: false });
      });
  };
};

export const resetRingedBell = (requestBody) => {
  return (dispatch) => {
    dispatch({ type: "RESET_RINGED_BELL", data: null });
  };
};

export const getStatusLogs = (requestBody) => {
  return (dispatch) => {
    dispatch({ type: commonTypes.HANDLE_LOADING, data: true });

    API.getLeadStatusLog(requestBody.lead_id)
      .then(async (response) => {
        if (response.rows) {
          await dispatch({
            type: "GET_STATUS_LOGS_SUCCESS",
            data: response.rows.lead_status_logs,
            docs: response.rows.LeadDocuments,
          });
        }
        dispatch({ type: commonTypes.HANDLE_LOADING, data: false });
      })
      .catch(async (error) => {
        await dispatch({
          type: "GET_STATUS_LOGS_ERROR",
          data: error.errorMessage,
        });
        dispatch({ type: commonTypes.HANDLE_LOADING, data: false });
      });
  };
};

export const myPipelineGetPlannedActivities = (requestBody) => {
  return (dispatch) => {
    dispatch({ type: "LOADING_PLANNED_ACTIVITIES" });
    axios
      .post("/salesActivity/getActivityTaskList", requestBody)
      .then((response) => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: "MY_PIPELINE_PLANNED_ACTIVITIES_SUCCESS",
            data: response.data.data.rows,
          });
        } else {
          dispatch({ type: "MY_PIPELINE_PLANNED_ACTIVITIES_FAIL" });
        }
      })
      .catch((error) => {
        dispatch({ type: "MY_PIPELINE_PLANNED_ACTIVITIES_FAIL" });
      });
  };
};

export const myPipelineGetHistoryActivities = (requestBody) => {
  return (dispatch) => {
    dispatch({ type: "LOADING_HISTORY_ACTIVITIES" });
    axios
      .post("/salesActivity/getActivityTaskList", requestBody)
      .then((response) => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: "MY_PIPELINE_HISTORY_ACTIVITIES_SUCCESS",
            data: response.data.data.rows,
          });
        } else {
          dispatch({ type: "MY_PIPELINE_HISTORY_ACTIVITIES_FAIL" });
        }
      })
      .catch((error) => {
        dispatch({ type: "MY_PIPELINE_HISTORY_ACTIVITIES_FAIL" });
      });
  };
};

export const resetDocuments = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_DOCUMENT_LISTING" });
  };
};

export default {
  updateRingBellLead,
  resetRingedBell,
  getStatusLogs,
  myPipelineGetPlannedActivities,
  myPipelineGetHistoryActivities,
  resetDocuments,
};
