import moment from 'moment';

export function toMillion(num) {
  const value = (num / 1000000).toFixed(2);

  return value === "0.00" ? Math.round(num) : `${value} M`;
}

export function getDatesFromMonth(startingMonth) {
  let date = moment().set("date", 1).set("month", startingMonth).clone();
  date = moment().isBefore(date) ? date.add("year", -1).clone() : date;

  return [date, date.clone().add('years',1).add('days', -1)];
}