import * as types from "./types";

const initialState = {
  communityLeads: [],
  communityLeadsCount: 0,
  revenueData: null,
  errorMessage: '',
  errorLoadingCommunityLeads: false,
  errorLoadingCommunityRevenue: false,
  selectedSalesCommunityOrganization: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LEAD_LIST_SUCCESS_SALES_COMMUNITY:
      return { 
        ...state, 
        communityLeads: action.data.rows,
        communityLeadsCount: action.data.count,
        errorLoadingCommunityLeads: false
      };
    case types.LOAD_MORE_LEAD_LIST_SUCCESS_SALES_COMMUNITY:
      let finalArr = [...state.communityLeads, ...action.data.rows];
      return{
        ...state, 
        communityLeads: finalArr,
        communityLeadsCount: action.data.count,
      }
    case types.LEAD_LIST_FAILED_SALES_COMMUNITY:
      return {
        ...state,
        errorMessage: action.errorMessage,
        errorLoadingCommunityLeads: true
      }
    case types.GET_REVENUE_SUCCESS_SALES_COMMUNITY:
      return {
        ...state,
        revenueData: action.data,
        errorLoadingCommunityRevenue: false
      }
    case types.GET_REVENUE_ERROR_SALES_COMMUNITY:
      return {
        ...state,
        revenueData: null,
        errorMessage : action.errorMessage,
        errorLoadingCommunityRevenue: true
      }

    case types.SET_ORGANIZATION_SALES_COMMUNITY:
      return {
        ...state,
        selectedSalesCommunityOrganization: action.selectedSalesCommunityOrganization
      }
    default:
      return state;
  }
};

export default reducer;
