import React, { useState, useRef, useEffect } from 'react';
import LargeScreenLoader from "../../common/Loaders/PulseLoader/pulse-loader";
import styled from 'styled-components';
import TableRow from "@material-ui/core/TableRow";
import MDTableCell from "@material-ui/core/TableCell";
import APIs from './apis';
import moment from 'moment';
import AdvanceSearch from './Components/AdvanceSearch';
import Tabs from './Components/Tabs';
import Header from './Components/Header';
import DownloadSection from './Components/DownloadSection';
import useTableWithAPI from './TableHook';
import CustomTable from './Table';
import handshakeIconGrey from '../../../assets/images/content-images/beforeHandshake.png';
import handshakeIconOne from '../../../assets/images/content-images/afterHandshake.png';
import handshakeIconTwo from '../../../assets/images/content-images/handshakeIconTwo.png';
import tableBusinessmanPNG from "../../../assets/images/bi/businessman.png";


const TableCell = styled(MDTableCell)`
  padding: 10px;
`;
const Span = styled.span`
  font-size: 0.875em !important;
`;
const leads_headers = [
    {
        id: "leadStatus.name",
        numeric: false,
        disablePadding: true,
        label: "Stage",
        sort: true,
    },
    {
        id: "lead",
        numeric: false,
        disablePadding: true,
        label: "Lead"
    },
    {
        id: "lead_title",
        numeric: false,
        disablePadding: true,
        label: "Lead Title",
        sort: true
    },
    {
        id: "created_by",
        numeric: false,
        disablePadding: true,
        label: "Created By"
    },
    {
        id: "assigned_to",
        numeric: false,
        disablePadding: true,
        label: "Assigned To",
    },
    {
        id: "created_at",
        numeric: false,
        disablePadding: true,
        label: "Created On",
        sort: true
    },
];
const contact_headers = [
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Name",
    },
    {
        id: "company.company_name",
        numeric: false,
        disablePadding: true,
        label: "Company Name",
        sort: true
    },
    {
        id: "email",
        numeric: false,
        disablePadding: true,
        label: "Email",
        sort: true,
    },
    {
        id: "phone_number",
        numeric: false,
        disablePadding: true,
        label: "Phone No.",
        sort: true
    },
    {
        id: "address",
        numeric: false,
        disablePadding: true,
        label: "Address",
    }
];
const company_headers = [
    {
        id: "company_name",
        numeric: false,
        disablePadding: true,
        label: "Company Name",
        sort: true
    },
    {
        id: "company_reg_no",
        numeric: false,
        disablePadding: true,
        label: "Company Reg. No"
    },
    {
        id: "address",
        numeric: false,
        disablePadding: true,
        label: "Address",
    },
    {
        id: "website",
        numeric: false,
        disablePadding: true,
        label: "Website",
        sort: true
    },
    {
        id: "contact_person_phone",
        numeric: false,
        disablePadding: true,
        label: "Phone No.",
        sort: true
    },
    {
        id: "contact_person_email",
        numeric: false,
        disablePadding: true,
        label: "Email",
        sort: true
    }
];
const renderLeadRecord = (row, i) => {
    return (
        <TableRow key={row.id}>
            <TableCell style={{ textAlign: "left" }}>
                <Span style={{ display: "flex", alignItems: "left", cursor: 'pointer' }} >
                    <b>{row.leadStatus && row.leadStatus.name}</b>
                </Span>
            </TableCell>
            <TableCell style={{ textAlign: "left" }}>
                <Span>
                    {
                         row.createdBy && 
                         row.createdBy.license_type && 
                         (
                            row.createdBy.license_type.actual_name == 'BASIC' 
                            || 
                            row.createdBy.license_type.actual_name == 'LITE'
                        ) ?
                            (
                            row.is_hand_over == 0 ?
                            <img src={handshakeIconGrey} width="33" />
                            : (row.is_hand_over && row.is_hand_over == 1 ?
                                <img src={handshakeIconOne} width="33" />
                                : row.is_hand_over && row.is_hand_over == 2 ?
                                    <img src={handshakeIconTwo} width="33" />
                                    : '')
                            )
                        : <img src={tableBusinessmanPNG} width="33" />
                    }
                </Span>
            </TableCell>
            <TableCell style={{ textAlign: "left" }}>
                <Span>{row.lead_title}</Span>
            </TableCell>
            <TableCell style={{ textAlign: "left" }}>
                <Span>{row.createdBy && row.createdBy.name}</Span>
            </TableCell>
            <TableCell style={{ textAlign: "left" }}>
                <Span>{row.assignedTo && row.assignedTo.name}</Span>
            </TableCell>
            <TableCell style={{ textAlign: "left" }}>
                <Span>{moment(new Date(row.created_at)).format('YYYY-MM-DD')}</Span>
            </TableCell>
        </TableRow>
    )
};
const renderCompanyRecord = (row, i) => {
    return (
        <TableRow key={row.id}>
            <TableCell style={{ textAlign: "left" }}>
                <Span style={{ display: "flex", alignItems: "left", cursor: 'pointer' }} >
                    {row.company_name}
                </Span>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
                <Span></Span>
            </TableCell>
            <TableCell style={{ textAlign: "left" }}>
                <Span>{`${row.office_address_line_1 || ''} ${row.office_address_line_2 || ''}`}</Span>
            </TableCell>
            <TableCell style={{ textAlign: "left" }}>
                <Span>{row.website}</Span>
            </TableCell>
            <TableCell style={{ textAlign: "center", minWidth: 110 }}>
                <Span>{row.contact_person_phone}</Span>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
                <Span>{row.contact_person_email}</Span>
            </TableCell>
        </TableRow>
    )
};
const renderContactRecord = (row, i) => {
    return (
        <TableRow key={row.id}>
            <TableCell style={{ textAlign: "left" }}>
                <Span style={{ display: "flex", alignItems: "left", cursor: 'pointer' }} >
                    {row.name}
                </Span>
            </TableCell>
            <TableCell style={{ textAlign: "left" }}>
                <Span>{row.company.company_name}</Span>
            </TableCell>
            <TableCell style={{ textAlign: "left" }}>
                <Span>{row.email}</Span>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
                <Span>{row.phone_number}</Span>
            </TableCell>
            <TableCell style={{ textAlign: "left" }}>
                <Span>{`${row.address_line_1 || ''} ${row.address_line_2 || ''}`}</Span>
            </TableCell>
        </TableRow>
    )
};
export default function DataExport() {
    const [isLoading, setLoading] = useState(false);
    const [currentTab, setCurrentTab] = useState('lead');
    const [advanceSearch, setAdvanceSearch] = useState(null);
    // const [count, setCount] = useState({ lead: 0, contact: 0, company: 0 });
    const [leadCount,setLeadCount]=useState(0);
    const [contactCount,setContactCount]=useState(0);
    const [companyCount,setCompanyCount]=useState(0);

    const handleOnCountChange = (key, newCount) => {
        // setCount({
        //     ...count,
        //     [key]: newCount
        // });
        setLeadCount(newCount)
    }

    const handleOnContactCountChange = (key,newCount) => {
        setContactCount(newCount);
    };

    const handleOnCompanyCountChange = (key,newCount) => {
        setCompanyCount(newCount);
    };

    const leadTableProps = useTableWithAPI(APIs.getLeadList, advanceSearch, 'lead', handleOnCountChange);
    const contactTableProps = useTableWithAPI(APIs.getContactList, advanceSearch, 'contact', handleOnContactCountChange);
    const companyTableProps = useTableWithAPI(APIs.getCompanyList, advanceSearch, 'company', handleOnCompanyCountChange);
    const handleExcelDownload = async () => {
        const result = await APIs.downloadExcel(currentTab, advanceSearch);
        console.log(result);
    };
    const handlePDFDownload = async () => {
        const result = await APIs.downloadPDF(currentTab, advanceSearch);
        console.log(result);
    };


    const renderAfterSearch = () => {
        return (
            <>
                <Tabs currentTab={currentTab} onTabChange={(key) => setCurrentTab(key)} />
                {
                    currentTab == 'lead' &&
                    <DownloadSection
                        onPdfClick={handlePDFDownload}
                        onExcelClick={handleExcelDownload}
                        count={leadCount}
                    />
                }
                {
                    currentTab == 'company' &&
                    <DownloadSection
                        onPdfClick={handlePDFDownload}
                        onExcelClick={handleExcelDownload}
                        count={companyCount}
                    />
                }
                {
                    currentTab == 'contact' &&
                    <DownloadSection
                        onPdfClick={handlePDFDownload}
                        onExcelClick={handleExcelDownload}
                        count={contactCount}
                    />
                }
                <div id="pendingusers" style={{ overflow: "overlay" }} className="mt-3">
                    {
                        currentTab == 'lead' &&
                        <CustomTable
                            headers={leads_headers}
                            {...leadTableProps}
                            renderRow={renderLeadRecord}
                        />
                    }
                    {
                        currentTab == 'contact' &&
                        <CustomTable
                            headers={contact_headers}
                            {...contactTableProps}
                            renderRow={renderContactRecord}
                        />
                    }
                    {
                        currentTab == 'company' &&
                        <CustomTable
                            headers={company_headers}
                            {...companyTableProps}
                            renderRow={renderCompanyRecord}
                        />
                    }
                </div>
            </>
        )
    };

    const renderPage = () => (
        <>
            <div className="main-wrapper">
                <Header />
                <AdvanceSearch
                    onSearch={(obj) => setAdvanceSearch(obj)}
                />
                {advanceSearch && renderAfterSearch()}
            </div>
        </>
    )
    return (<> {isLoading ? <LargeScreenLoader /> : renderPage()} </>)
}