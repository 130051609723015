import React from "react";
import styles from '../NewContact.module.scss';
import AutoComplete from '../../../common/AutoComplete/autocomplete';
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "../../../common/Loaders/CircularLoader/circular-loader";
import { emailPattern, firstName_regex, middleName_regex, lastName_regex, zipCode_regex, commonValidation_regex, commonValidationWithLineBreak_regex } from "../../../common/common-types";
import SmallScaleLoader from "../../../common/Loaders/SyncLoader/sync-loader";
import axios from "../../../common/api-config";


const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing.unit * 2
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit
    }
}))(MuiDialogActions);

class Person extends React.Component {

    constructor(props) {
        super(props);
        this.textInput = null;
        this.state = {
            personFields: {
                initials: "",
                first_name: "",
                middle_name: "",
                last_name: "",
                personCompany: 0,
                country_code: "",
                personPhoneNumber: "",
                personEmail: "",
                personAddress1: "",
                personAddress2: "",
                personCountry: 0,
                personState: 0,
                personCity: 0,
                zipcode: '',
                personNote: "",
                title: "",
                is_city: "",
                asyncCompanyList: [],
            },
            personErrors: {
                middle_name: "",
                last_name: "",
                personEmail: "",
                country_code: "",
                personPhoneNumber: "",
                personAddress1: "",
                personAddress2: "",
                personCountry: "",
                personState: "",
                personCity: "",
                zipcode: '',
                personNote: "",
                title: "",
                personCompany: ""

            },
            disableCompanyField: false,
            resetState: false,
            resetCity: false,
            submitButton: false,
            updatedStateList: [],
            newAddedCompany: "",
            submitButtonBlur: false,
            loadingCompanyList: false
        }

        this.initialsArr = [
            { key: 'Mr.', value: 'Mr.' },
            { key: 'Mrs.', value: 'Mrs.' },
            { key: 'Miss.', value: 'Miss.' },
            { key: 'Ms.', value: 'Ms.' },
        ]
    }

    componentDidMount() {
        this.textInput.focus();
        if (this.props.currentPerson) {

            let currentPerson = this.props.currentPerson;
            this.setState({
                value: this.props.value,
                personFields: {
                    initials: currentPerson.initials ? currentPerson.initials : '',
                    first_name: currentPerson.first_name,
                    middle_name: currentPerson.middle_name ? currentPerson.middle_name : "",
                    last_name: currentPerson.last_name ? currentPerson.last_name : "",
                    personCompany: currentPerson.contact_company ? currentPerson.contact_company.id : 0,
                    country_code: currentPerson.countryCode ? currentPerson.countryCode.id : "",
                    personPhoneNumber: currentPerson.phone_number ? currentPerson.phone_number.toString() : "",
                    personEmail: currentPerson.email ? currentPerson.email : "",
                    personAddress1: currentPerson.address_line_1 ? currentPerson.address_line_1 : "",
                    personAddress2: currentPerson.address_line_2 ? currentPerson.address_line_2 : "",
                    personCountry: currentPerson.country_id ? currentPerson.country_id : "",
                    personState: currentPerson.state_id ? currentPerson.state_id : currentPerson.country && currentPerson.country.is_city == 1 ? "other" : null,

                    //personState: currentPerson.state_id ? currentPerson.state_id : "",
                    personCity: currentPerson.city_id ? currentPerson.city_id : "",
                    zipcode: currentPerson.zipcode ? currentPerson.zipcode : "",
                    personNote: currentPerson.notes ? currentPerson.notes : '',
                    title: currentPerson.title ? currentPerson.title : "",
                    is_city: currentPerson.country ? currentPerson.country["is_city"] : ""

                }
            });

            if (currentPerson.country_id) {
                this.props.getAllStates(currentPerson.country_id);
            }

            let personStat = currentPerson.state_id ? currentPerson.state_id : currentPerson.country && currentPerson.country.is_city == 1 ? "other" : null
            if (currentPerson.country_id && personStat) {

                this.props.getAllCities(currentPerson.country_id, personStat);
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        //if person is getting added from Lead form, pre-populate company field.
        if (this.props.selectedCompany && this.props.selectedCompany != prevState.personFields.personCompany) {
            let personFields = { ...this.state.personFields };
            personFields["personCompany"] = this.props.selectedCompany;
            if (this.props.selectedCompanyObj && this.props.selectedCompanyObj.countryCode && this.props.selectedCompanyObj.countryCode != prevState.personFields.country_code) {
                personFields["country_code"] = this.props.selectedCompanyObj.countryCode.id;
            }
            this.setState({
                personFields,
                disableCompanyField: true
            });
        }


        if (prevProps.stateList !== this.props.stateList) {

            this.setState({
                updatedStateList: this.props.stateList

            }, () => {

                if (this.state.updatedStateList && (this.state.personFields["is_city"] == true || this.state.personFields["is_city"] == 1)) {

                    this.setState({
                        updatedStateList: [...this.state.updatedStateList, { id: "other", name: "Other" }]
                    }, () => {

                        if (this.state.personFields["personState"] == null || this.state.personFields["personState"] == 0) {
                            this.handlePersonFieldsChange({ name: "personState", value: "other" })
                        }

                    })
                }
            })
        }

    }

    componentWillUnmount() {
        //clearing state and city data from redux
        this.props.resetStateCity();
    }

    fetchCompaniesAsnc = (newValue) => {
        let requestBody = {
            arrayFilters: [{}],
            selectFilters: [],
            searchFilter: [
                {
                    company_name: newValue.trim()
                }
            ],
            sort: {
                field: "id",
                sortOrder: "DESC"
            }
        };

        axios
            .post("/contact/company/get", requestBody)
            .then(response => {

                if (response.status === 200) {
                    let personFields = { ...this.state.personFields };
                    personFields["asyncCompanyList"] = response.data.data.rows
                    // setTimeout(() => {
                    //     this.setState({
                    //         personFields: personFields,
                    //         loadingCompanyList: false
                    //     });
                    // }, 2000);

                    this.setState({
                        personFields: personFields,
                        loadingCompanyList: false
                    });
                } else {
                    let personErrors = { ...this.state.personErrors };
                    personErrors["personCompany"] = "Error loading company list, please try again"
                    this.setState({
                        personErrors: personErrors,
                        loadingCompanyList: false
                    })
                }
            })
            .catch(error => {
                let personErrors = { ...this.state.personErrors };
                personErrors["personCompany"] = "Error loading company list, please try again"
                this.setState({
                    personErrors: personErrors,
                    loadingCompanyList: false
                })
            });
    }

    // person fields on change
    handlePersonFieldsChange = async (e) => {
        let { name, value } = '';
        if (e && e.target) {
            name = e.target.name;
            value = e.target.value;
        } else if (e && e.name) {
            name = e.name;
            value = e.value;
        }
        let personFields = { ...this.state.personFields };
        let personErrors = { ...this.state.personErrors };

        if (
            name === "personCompany" ||
            name === "personCountry" ||
            name === "personState" ||
            name === "personCity"
        ) {
            if (value === "") {
                value = 0;
            } else if (value == "other") {
                value = "other"
            } else {
                value = parseInt(value);
            }
        }

        switch (name) {
            case "initials":
                personFields["initials"] = value;
                personErrors["initials"] = "";
                break;

            case "first_name":
                if (value.trim() === "") {
                    personFields["first_name"] = value;
                    personErrors["first_name"] = "This field is required";
                } else if (!value.match(commonValidation_regex)) {
                    personFields["first_name"] = value;
                    personErrors["first_name"] = "Please enter valid first name";
                } else {
                    personFields["first_name"] = value;
                    personErrors["first_name"] = "";
                }
                break;

            case "middle_name":
                if (value.trim() === "") {
                    personFields["middle_name"] = value;
                    personErrors["middle_name"] = "";
                }
                else if (!value.match(commonValidation_regex)) {
                    personFields["middle_name"] = value;
                    personErrors["middle_name"] = "Please enter valid middle name";
                } else {
                    personFields["middle_name"] = value;
                    personErrors["middle_name"] = "";
                }
                break;
            //title -spaces allowed, maxlength 100 no validation
            case "title":
                personFields["title"] = value;
                personErrors["title"] = "";

                break;


            case "last_name":
                if (value.trim() === "") {
                    personFields["last_name"] = value;
                    personErrors["last_name"] = "";
                } else if (!value.match(commonValidation_regex) || value.charAt(value.length - 1) === "'") {
                    personFields["last_name"] = value;
                    personErrors["last_name"] = "Please enter valid last name";
                }
                else {
                    personFields["last_name"] = value;
                    personErrors["last_name"] = "";
                }

                break;

            case "personCompany":
                if (value === 0) {
                    personFields["personCompany"] = value;
                    personErrors["personCompany"] = "This field is required";
                } else if (!Number.isNaN(value)) {
                    let countryCode = '';
                    if (this.state.personFields.asyncCompanyList.length > 0) {
                        const companySelected = this.state.personFields.asyncCompanyList.find(company => {
                            return company.id === value
                        });
                        countryCode = companySelected && companySelected['contact_country_code'] ? companySelected['contact_country_code'] : '';

                        if (countryCode) {
                            personFields["country_code"] = countryCode;
                            personErrors["country_code"] = "";
                            //  this.setState({ countryCode });
                        } else {
                            personFields["country_code"] = ""
                            personErrors["country_code"] = "";
                            // this.setState({  countryCode: '' });
                        }
                    }
                    await this.setState(prevState => ({
                        personFields: {
                            ...prevState.personFields,
                            personCompany: value,
                        },
                        personErrors: {
                            ...prevState.personErrors,
                            personCompany: ''
                        },
                        newAddedCompany: "",
                        submitButtonBlur: false
                    }));
                    return;
                }
                break;

            case "country_code":
                personFields["country_code"] = value;
                personErrors["country_code"] = "";
                break;

            case "personEmail":
                if (value.trim() === "") {
                    personFields["personEmail"] = value;
                    personErrors["personEmail"] = "";
                } else if (
                    !value.match(emailPattern)
                ) {
                    personFields["personEmail"] = value;
                    personErrors["personEmail"] = "Please enter a valid email";
                } else {
                    personFields["personEmail"] = value;
                    personErrors["personEmail"] = "";
                }
                break;

            case "personAddress1":
                if (value.trim() === "") {
                    personFields["personAddress1"] = value;
                    personErrors["personAddress1"] = "";
                } else if (
                    !value.match(commonValidation_regex)
                ) {
                    personFields["personAddress1"] = value;
                    personErrors["personAddress1"] = "Please enter a valid address";
                } else {
                    personFields["personAddress1"] = value;
                    personErrors["personAddress1"] = "";
                }

                break;




            case "personAddress2":
                if (value.trim() === "") {
                    personFields["personAddress2"] = value;
                    personErrors["personAddress2"] = "";
                } else if (
                    !value.match(commonValidation_regex)
                ) {
                    personFields["personAddress2"] = value;
                    personErrors["personAddress2"] = "Please enter a valid address";
                } else {
                    personFields["personAddress2"] = value;
                    personErrors["personAddress2"] = "";
                }

                break;

            case "personCountry":
                personFields["personCountry"] = value;
                personFields["personState"] = 0;
                personFields["personCity"] = 0;
                personErrors["personCountry"] = "";
                this.props.resetStateCity();
                await this.setState({
                    resetState: true,
                    resetCity: true
                })
                this.props.getAllStates(value);

                if (this.props.countryList) {
                    let selectedCountry = this.props.countryList.filter((country, index) => {
                        return country.id == value;
                    });
                    if (selectedCountry && selectedCountry[0] && selectedCountry[0].id) {
                        personFields["is_city"] = selectedCountry[0].is_city;

                    }
                }
                break;

            case "personState":
                personFields["personState"] = value;
                personFields["personCity"] = 0;
                personErrors["personState"] = "";
                this.props.resetCity();
                await this.setState({
                    resetState: false,
                    resetCity: true
                })
                this.props.getAllCities(this.state.personFields.personCountry, value);
                // }
                break;

            case "personCity":
                await this.setState({
                    resetCity: false
                })
                personFields["personCity"] = value;
                personErrors["personCity"] = "";
                // }
                break;

            case "zipcode":
                if (!value.match(zipCode_regex)) {
                    personFields["zipcode"] = value;
                    personErrors["zipcode"] = "Please enter valid zip code";
                } else {
                    personFields["zipcode"] = value;
                    personErrors["zipcode"] = "";
                }
                break;

            case "personNote":

                if (value.trim() === "") {
                    personFields["personNote"] = value;
                    personErrors["personNote"] = "";
                } else if (
                    !value.match(commonValidationWithLineBreak_regex)
                ) {
                    personFields["personNote"] = value;
                    personErrors["personNote"] = "Please enter a valid note";
                } else {
                    personFields["personNote"] = value;
                    personErrors["personNote"] = "";
                }

                break;

            default:
                break;
        }

        this.setState({
            personFields: { ...personFields },
            personErrors: { ...personErrors }
        });
    };

    handlePhoneNumberChange = (e) => {
        let value = e.target.value;
        const personFields = { ...this.state.personFields };
        const personErrors = { ...this.state.personErrors };
        if (value.trim() === "") {
            personFields["personPhoneNumber"] = value.trim();
            personErrors["personPhoneNumber"] = "";
        } else if (!value.match(/^[0-9]+$/)) {
            //personFields["personPhoneNumber"] = "";
            //personErrors["personPhoneNumber"] = "Only numbers are allowed";
            // if (value.length < 9) {
            //     personErrors["personPhoneNumber"] = "Phone number should not be less than 8 digits.";
            // }
        } else if (value.length < 8) {
            personFields["personPhoneNumber"] = value;
            personErrors["personPhoneNumber"] = "Phone number should not be less than 8 digits.";
        } else {
            personFields["personPhoneNumber"] = value;
            personErrors["personPhoneNumber"] = "";
        }

        this.setState({
            personFields: personFields,
            personErrors: personErrors
        });
    }

    handleUpdatePerson = () => {
        const personRequestBody = {
            id: this.props.currentPerson.id,
            initials: this.state.personFields.initials,
            first_name: this.state.personFields.first_name.trim(),
            middle_name: this.state.personFields.middle_name.trim(),
            last_name: this.state.personFields.last_name.trim(),
            title: this.state.personFields.title.trim(),
            company_id: this.state.personFields.personCompany ? (this.state.personFields.personCompany == 0 ? "" : this.state.personFields.personCompany.toString()) : "",
            country_code: this.state.personFields.country_code ? (this.state.personFields.country_code == 0 ? "" : this.state.personFields.country_code.toString()) : "",
            phone_number: this.state.personFields.personPhoneNumber.trim(),
            email: this.state.personFields.personEmail,
            address_line_1: this.state.personFields.personAddress1.trim(),
            address_line_2: this.state.personFields.personAddress2.trim(),
            country_id: this.state.personFields.personCountry ? (this.state.personFields.personCountry == 0 ? "" : this.state.personFields.personCountry.toString()) : "",
            state_id: this.state.personFields.personState ? (this.state.personFields.personState == 0 || this.state.personFields.personState == "other" ? "" : this.state.personFields.personState.toString()) : "",

            //state_id: this.state.personFields.personState ? (this.state.personFields.personState == 0 ? "" : this.state.personFields.personState.toString()) : "",
            city_id: this.state.personFields.personCity ? (this.state.personFields.personCity == 0 ? "" : this.state.personFields.personCity.toString()) : "",
            zipcode: this.state.personFields.zipcode.toString().trim(),
            notes: this.state.personFields.personNote.trim()
        };
        this.props.updatePerson(personRequestBody);
    };

    // submitting person form
    handlePersonFormSubmit = () => {
        let personErrors = { ...this.state.personErrors };
        if (personErrors["middle_name"] !== "" ||
            personErrors["last_name"] !== "" ||
            personErrors["personEmail"] !== "" ||
            personErrors["personAddress1"] !== "" || personErrors["personAddress2"] ||
            personErrors["personCity"] !== "" || personErrors["personCountry"] ||
            personErrors["personState"] !== "" || personErrors["personNote"] ||
            personErrors["zipcode"] || personErrors["personPhoneNumber"]) {
            this.handlePersonOnSubmitValidation();
            return;
        } else {
            if (this.handlePersonOnSubmitValidation()) {
                this.setState({ submitButton: true });
                if (this.props.currentPerson) {
                    this.handleUpdatePerson();
                } else {
                    this.handleSavePerson();
                }
            }
        }
    };

    // person form on submit all validations
    handlePersonOnSubmitValidation = () => {
        let personFields = this.state.personFields;
        let personErrors = { ...this.state.personErrors };
        let personFormIsValid = true;

        // person name
        if (typeof personFields["first_name"]) {
            if (personFields["first_name"].trim() === "") {
                personFormIsValid = false;
                personErrors["first_name"] = "This field is required";
            } else {
                if (!personFields["first_name"].match(commonValidation_regex)) { ///^[a-zA-Z ]+$/
                    personFormIsValid = false;
                    personErrors["first_name"] = "Please enter valid first name";
                }
            }
        }

        // person company
        if (personFields["personCompany"] == 0) {
            personFormIsValid = false;
            personErrors["personCompany"] = "This field is required";
        }

        // Phone number
        if (personFields["personPhoneNumber"].trim() !== "" && personFields["country_code"] === "") {
            personFormIsValid = false;
            personErrors["country_code"] = "This field is required";
        }
// Phone number
        if (personFields["country_code"] !== "" && personFields["personPhoneNumber"].trim() === "") {
            personFormIsValid = false;
            personErrors["personPhoneNumber"] = "This field is required";
        }
        this.setState({ personErrors: personErrors });
        return personFormIsValid;
    };

    // save person contact request body
    handleSavePerson = () => {
        const personRequestBody = {
            initials: this.state.personFields.initials,
            first_name: this.state.personFields.first_name.trim(),
            title: this.state.personFields.title.trim(),
            middle_name: this.state.personFields.middle_name.trim(),
            last_name: this.state.personFields.last_name.trim(),
            company_id: this.state.personFields.personCompany.toString(),
            country_code: this.state.personFields.country_code.toString(),
            phone_number: this.state.personFields.personPhoneNumber.trim(),
            email: this.state.personFields.personEmail,
            address_line_1: this.state.personFields.personAddress1.trim(),
            address_line_2: this.state.personFields.personAddress2.trim(),
            country_id: this.state.personFields.personCountry.toString(),
            //state_id: this.state.personFields.personState.toString(),
            state_id: this.state.personFields.personState ? (this.state.personFields.personState == 0 || this.state.personFields.personState == "other" ? "" : this.state.personFields.personState.toString()) : "",
            city_id: this.state.personFields.personCity.toString(),
            zipcode: this.state.personFields.zipcode.toString().trim(),
            notes: this.state.personFields.personNote.trim()
        };
        this.props.addNewPerson(personRequestBody);
    };

    // on update if fields changed to empty, handle the state
    onChangeAutocomplete = async (event, newValue, key) => {
        let personFields = { ...this.state.personFields };
        let personErrors = { ...this.state.personErrors };
        switch (key) {
            case "personCompany":
                if (!newValue || newValue == "") {
                    personFields["personCompany"] = 0;
                    personFields["country_code"] = "";
                    personFields["asyncCompanyList"] = [];
                    personErrors["personCompany"] = "This field is required";
                    await this.setState({ personFields, personErrors, newAddedCompany: "", submitButtonBlur: false });
                } else {
                    // let selectedCompany = this.props.companyList.filter((company, index) => {
                    //     return company.company_name === newValue;
                    // });
                    // if (!selectedCompany || selectedCompany.length === 0) {
                    personFields["country_code"] = "";
                    personFields["personCompany"] = 0;
                    // personFields["loadingCompanyList"] = newValue.length > 2 ? true : false

                    if (!newValue.match(commonValidation_regex)) {
                        personErrors["personCompany"] = "This field is invalid";
                        await this.setState({ personFields, personErrors, newAddedCompany: newValue });
                    } else {
                        personErrors["personCompany"] = "";
                        await this.setState({ personFields, personErrors, newAddedCompany: newValue },
                            async () => {
                                if (newValue.length > 0 && this.state.personFields["personCompany"] === 0) {
                                    await this.setState({ loadingCompanyList: true })
                                    this.fetchCompaniesAsnc(newValue);
                                }
                            });
                    }
                }
                break;
            case "personCountry":
                if (!newValue || newValue == "") {
                    personFields["personCountry"] = "";
                    personFields["personState"] = "";
                    personFields["personCity"] = "";
                    personFields["is_city"] = "";
                    personErrors["personCountry"] = "";
                    personErrors["personState"] = "";
                    personErrors["personCity"] = "";
                    this.props.resetStateCity();
                    await this.setState({ personFields, personErrors, resetState: true, resetCity: true });
                } else {
                    let selectedCountry = this.props.countryList.filter((country, index) => {
                        return country.name === newValue;
                    });
                    if (!selectedCountry || selectedCountry.length == 0) {
                        personFields["personCountry"] = "";
                        personFields["personState"] = "";
                        personFields["personCity"] = "";
                        personFields["is_city"] = "";
                        personErrors["personState"] = "";
                        personErrors["personCity"] = "";
                        this.props.resetStateCity();
                        personErrors["personCountry"] = "Please select country from suggestions";
                    }
                    await this.setState({ personFields, personErrors, resetState: true, resetCity: true });
                }
                break;
            case "personState":
                if (!newValue || newValue == "") {
                    personFields["personState"] = "";
                    personFields["personCity"] = "";
                    personErrors["personState"] = "";
                    personErrors["personCity"] = "";
                    this.props.resetCity();
                    await this.setState({ personFields, personErrors, resetCity: true });
                } else {
                    let selectedState = this.props.stateList.filter((state, index) => {
                        return state.name === newValue;
                    });
                    if (!selectedState || selectedState.length == 0) {
                        personFields["personState"] = "";
                        personFields["personCity"] = "";
                        personErrors["personState"] = "Please select state from suggestions";
                        personErrors["personCity"] = "";
                        this.props.resetCity();
                        await this.setState({ personFields, personErrors, resetCity: true });
                    }
                }
                break;
            case "personCity":
                if (!newValue || newValue == "") {
                    personFields["personCity"] = "";
                    personErrors["personCity"] = "";
                    await this.setState({ personFields, personErrors });
                } else {
                    let selectedCity = this.props.cityList.filter((city, index) => {
                        return city.name === newValue;
                    });
                    if (!selectedCity || selectedCity.length == 0) {
                        personFields["personCity"] = "";
                        personErrors["personCity"] = "Please select city from suggestions";
                        await this.setState({ personFields, personErrors });
                    }
                }
                break;
            default:
                break;
        }
    }

    //adding company on blur
    addNewCompany = () => {
        let value = this.state.newAddedCompany.trim();
        if (value !== "" && this.state.personErrors["personCompany"] === "") {
            const params = {
                company_name: value
            };
            axios
                .post("/contact/company/add", params)
                .then(response => {
                    if (response.data.data.status === 200) {
                        this.getCompanies();
                        this.setState(prevState => ({
                            ...prevState,
                            personFields: {
                                ...prevState.personFields,
                                personCompany: response.data.data.data.id,
                            },
                            personErrors: {
                                ...prevState.personErrors,
                                personCompany: ''
                            },
                            newAddedCompany: "",
                            submitButtonBlur: false
                        }));
                    } else {

                        this.setState(prevState => ({
                            ...prevState,
                            personFields: {
                                ...prevState.personFields,
                                personCompany: 0,
                            },
                            personErrors: {
                                ...prevState.personErrors,
                                personCompany: response.data.errorMessage
                            },
                            newAddedCompany: "",
                            submitButtonBlur: false
                        }));
                    }
                })
                .catch(error => {

                    this.setState(prevState => ({
                        ...prevState,
                        personFields: {
                            ...prevState.personFields,
                            personCompany: 0,
                        },
                        personErrors: {
                            ...prevState.personErrors,
                            personCompany: "Error adding company, please try again"
                        },
                        newAddedCompany: "",
                        submitButtonBlur: false
                    }));
                });
        }
    };

    getCompanies = () => {
        let params = {
            arrayFilters: [
                {
                    is_deleted: 0
                }
            ],
            selectFilters: [],
            sort: {
                field: "company_name",
                sortOrder: "ASC"
            },
            paginate: {
                page: "",
                limit: ""
            }
        };
        this.props.getCompanies(params);
    };

    //get company name while updation
    getUpdationCompanyName = () => {
        let updationCompany = null;
        if (this.props.currentPerson) {
            updationCompany = this.props.companyList.find(company => {
                return company.id === this.props.currentPerson.contact_company.id
            });
        }
        return updationCompany.company_name;
    }

    render() {

        return (
            <div>
                <DialogContent className={styles.lead_dialog_content}>
                    <div className="row">
                        <div className="form-group col-md-6 col-lg-3">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                            >
                                <label className={styles.label}>
                                    Company<span className={styles.required_field}>*</span>
                                </label>
                                <div
                                    style={{ paddingRight: "10px", }}
                                >
                                    <SmallScaleLoader
                                        loading={this.state.loadingCompanyList}
                                    />
                                </div>
                            </div>
                            {
                                this.props.currentPerson ?
                                    <input
                                        disabled
                                        className={styles.inputStyle}
                                        value={this.getUpdationCompanyName()}
                                    />
                                    :
                                    <AutoComplete
                                        suggestions={this.state.personFields.asyncCompanyList}
                                        autoCompleteName="personCompany"
                                        placeholder="Select company name"
                                        handlePersonFieldsChange={this.handlePersonFieldsChange}
                                        value={this.state.personFields["personCompany"]}
                                        disabled={(this.props.currentPerson && this.props.currentPerson.contact_company && this.props.currentPerson.contact_company.id) || this.state.disableCompanyField}
                                        onChange={
                                            (event, newValue, key) => {
                                                this.setState({ submitButtonBlur: true });
                                                this.onChangeAutocomplete(event, newValue, key);
                                            }
                                        }
                                        onBlur={this.addNewCompany}
                                        loadingCompanyList={this.state.loadingCompanyList}
                                    />
                            }
                            <span className={styles.errorMessage}>
                                {this.state.personErrors["personCompany"]}
                            </span>
                        </div>
                        <div className="form-group col-md-6 col-lg-3">
                            <div className="row">
                                <div className="col-4  padding_right padding_right_remove">
                                    <label className={styles.label}>Prefix</label>
                                    <select className={styles.selectCompany}
                                        name="initials"
                                        value={this.state.personFields["initials"]}
                                        onChange={this.handlePersonFieldsChange}
                                    >
                                        <option value="" disabled>Select</option>
                                        {
                                            this.initialsArr.map((item, index) => {
                                                return (
                                                    <option value={item.key} key={"initial_" + index}>{item.value}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-8 padding_left">
                                    <label className={styles.label}>First name<span className={styles.required_field}>*</span></label>
                                    <input
                                        className={styles.inputStyle}
                                        type="text"
                                        name="first_name"
                                        value={this.state.personFields["first_name"]}
                                        onChange={this.handlePersonFieldsChange}
                                        autoComplete="off"
                                        maxLength="20"
                                        //placeholder="Enter first name"
                                        ref={elem => (this.textInput = elem)}
                                    />
                                    <span className={styles.errorMessage}>
                                        {this.state.personErrors["first_name"]}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-md-6 col-lg-3">
                            <label className={styles.label}>Middle name</label>
                            <input
                                className={styles.inputStyle}
                                type="text"
                                name="middle_name"
                                value={this.state.personFields["middle_name"]}
                                onChange={this.handlePersonFieldsChange}
                                autoComplete="off"
                                maxLength="20"
                            //placeholder="Enter middle name"
                            />
                            <span className={styles.errorMessage}>
                                {this.state.personErrors["middle_name"]}
                            </span>
                        </div>
                        <div className="form-group col-md-6 col-lg-3">
                            <label className={styles.label}>Last name
                                {/* <span className={styles.required_field}>*</span> */}
                            </label>
                            <input
                                className={styles.inputStyle}
                                type="text"
                                name="last_name"
                                value={this.state.personFields["last_name"]}
                                onChange={this.handlePersonFieldsChange}
                                autoComplete="off"
                                maxLength="20"
                            //placeholder="Enter last name"
                            />
                            <span className={styles.errorMessage}>
                                {this.state.personErrors["last_name"]}
                            </span>
                        </div>
                    </div>
                    <div className="row">

                        <div className="form-group col-md-6 col-lg-3">
                            <label className={styles.label}>Title</label>
                            <input
                                className={styles.inputStyle}
                                type="text"
                                name="title"
                                value={this.state.personFields["title"]}
                                onChange={this.handlePersonFieldsChange}
                                autoComplete="off"
                                maxLength="30"
                                placeholder="Enter title"
                            />
                            <span className={styles.errorMessage}>
                                {this.state.personErrors["title"]}
                            </span>
                        </div>

                        <div className="form-group col-md-6 col-lg-3">
                            <label className={styles.label}>Email address
                                {/* <span className={styles.required_field}>*</span> */}
                            </label>
                            <input
                                className={styles.inputStyle}
                                type="text"
                                name="personEmail"
                                value={this.state.personFields["personEmail"]}
                                onChange={this.handlePersonFieldsChange}
                                autoComplete="off"
                                maxLength="100"
                            //placeholder="Enter email address"
                            />
                            <span className={styles.errorMessage}>
                                {this.state.personErrors["personEmail"]}
                            </span>
                        </div>
                        <div className="form-group col-md-6 col-lg-3">

                            <div className="row">
                                <div className="col-4 padding_right padding_right_remove">
                                    <label className={styles.label}>Code
                                        {/* <span className={styles.required_field}>*</span> */}
                                    </label>
                                    <select className={styles.selectCompany}
                                        name="country_code"
                                        value={this.state.personFields["country_code"]}
                                        onChange={this.handlePersonFieldsChange}>
                                        <option value="">Select</option>
                                        {
                                            this.props.countryList ?
                                                this.props.countryList.map((country, index) => {
                                                    return (
                                                        <option value={country.id} key={"code_" + index}>
                                                            {country.iso_code + " +" + country.dialing_code}
                                                        </option>
                                                    )
                                                })
                                                : ''
                                        }
                                    </select>
                                    <span className={styles.errorMessage}>
                                        {`${this.state.personErrors["country_code"] ?
                                            this.state.personErrors["country_code"] : ""}`}
                                    </span>
                                </div>

                                <div className="col-8 padding_left">
                                    <label className={styles.label}>Phone number
                                        {/* <span className={styles.required_field}>*</span> */}
                                    </label>
                                    <input
                                        className={styles.inputStyle}
                                        type="text"
                                        name="personPhoneNumber"
                                        value={this.state.personFields["personPhoneNumber"]}
                                        onChange={this.handlePhoneNumberChange}
                                        autoComplete="off"
                                        maxLength="15"
                                    //placeholder="Enter phone number"
                                    />
                                    <span className={styles.errorMessage}>
                                        {`${this.state.personErrors["personPhoneNumber"] ?
                                            this.state.personErrors["personPhoneNumber"] : ""}`}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="form-group col-md-6 col-lg-3">
                            <label className={styles.label}>Address line 1</label>
                            <input
                                className={styles.inputStyle}
                                type="text"
                                name="personAddress1"
                                value={this.state.personFields["personAddress1"]}
                                onChange={this.handlePersonFieldsChange}
                                autoComplete="off"
                                maxLength="100"
                            // placeholder="Enter address line 1"
                            />
                            <span className={styles.errorMessage}>
                                {this.state.personErrors["personAddress1"]}
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-6 col-lg-3">
                            <label className={styles.label}>Address line 2</label>
                            <input
                                className={styles.inputStyle}
                                type="text"
                                name="personAddress2"
                                value={this.state.personFields["personAddress2"]}
                                onChange={this.handlePersonFieldsChange}
                                autoComplete="off"
                                maxLength="100"
                            //  placeholder="Enter address line 2"
                            />
                            <span className={styles.errorMessage}>
                                {this.state.personErrors["personAddress2"]}
                            </span>
                        </div>



                        <div className="form-group col-md-6 col-lg-3">
                            <label className={styles.label}>Country</label>
                            <AutoComplete
                                suggestions={this.props.countryList}
                                autoCompleteName="personCountry"
                                placeholder="Select country name"
                                handlePersonFieldsChange={this.handlePersonFieldsChange}
                                value={this.state.personFields["personCountry"]}
                                onChange={(event, newValue, key) => this.onChangeAutocomplete(event, newValue, key)}
                            />
                            <span className={styles.errorMessage}>
                                {this.state.personErrors["personCountry"]}
                            </span>
                        </div>
                        <div className="form-group col-md-6 col-lg-3">
                            <label className={styles.label}>State</label>
                            <AutoComplete
                                suggestions={this.state.updatedStateList}
                                autoCompleteName="personState"
                                resetState={this.state.resetState}
                                placeholder="Select state name"
                                handlePersonFieldsChange={this.handlePersonFieldsChange}
                                value={this.state.personFields["personState"]}
                                onChange={(event, newValue, key) => this.onChangeAutocomplete(event, newValue, key)}
                                disabled={this.props.stateList && this.props.stateList.length !== 0 ? false : true}
                            />
                            <span className={styles.errorMessage}>
                                {this.state.personErrors["personState"]}
                            </span>
                        </div>
                        <div className="form-group col-md-6 col-lg-3">
                            <label className={styles.label}>City</label>
                            <AutoComplete
                                suggestions={this.props.cityList}
                                autoCompleteName="personCity"
                                resetCity={this.state.resetCity}
                                placeholder="Select city name"
                                handlePersonFieldsChange={this.handlePersonFieldsChange}
                                value={this.state.personFields["personCity"]}
                                onChange={(event, newValue, key) => this.onChangeAutocomplete(event, newValue, key)}
                                disabled={this.props.cityList && this.props.cityList.length !== 0 ? false : true}
                            />
                            <span className={styles.errorMessage}>
                                {this.state.personErrors["personCity"]}
                            </span>
                        </div>
                    </div>
                    <div className="row">

                        <div className="form-group col-md-6 col-lg-3">
                            <label className={styles.label}>Zip/Postal address</label>
                            <input
                                className={styles.inputStyle}
                                type="text"
                                name="zipcode"
                                value={this.state.personFields["zipcode"]}
                                onChange={this.handlePersonFieldsChange}
                                autoComplete="off"
                                maxLength="10"
                            //placeholder="Enter zip/postal address"
                            />
                            <span className={styles.errorMessage}>
                                {this.state.personErrors["zipcode"]}
                            </span>
                        </div>


                        <div className="form-group col-md-12 col-lg-9">
                            <label className={styles.label}>Note</label>
                            <div className="notesHint">{(1024 - this.state.personFields["personNote"].length) + " characters left"}</div>
                            <textarea
                                className={styles.taxtArea}
                                style={{ height: "46px" }}
                                name="personNote"
                                value={this.state.personFields["personNote"]}
                                onChange={this.handlePersonFieldsChange}
                                autoComplete="off"
                                maxLength="1024"
                            />
                            <span className={styles.errorMessage}>
                                {this.state.personErrors["personNote"]}
                            </span>
                        </div>
                    </div>

                </DialogContent>
                <DialogActions className={styles.removeBorder}>
                    {
                        this.state.submitButton ?
                            <div className={styles.submit_loader}>
                                <CircularProgress
                                    style={{
                                        color: "#19D98C"
                                    }}
                                />
                            </div> :
                            <Button
                                disabled={this.state.submitButtonBlur}
                                className={styles.saveButton}
                                onClick={this.handlePersonFormSubmit}
                                color="primary"
                            >
                                {"Save"}
                            </Button>
                    }
                </DialogActions>
            </div >
        )
    }
}

export default Person;