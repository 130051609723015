import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./AuthenticateCRMComponent.module.scss";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import { Redirect } from 'react-router';
import { string } from "prop-types";

const stylesMaterialUI = theme => ({
  button: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    marginLeft: 0,
    marginRight: 0
  }
});

class AuthenticateCRMComponent extends Component {
  state = {
    CRMClicked: false,
    whichCRM: "",
    goBack: false,
   /*  integrationDetails: { */
      idcrm_master: 1,
      crm_name: "SalesForce",
      idcrm_version_master: 1,
      version_name: "v2.3",
      idcrm_integration: 1,
      user_id: "test",
      password: "test",
      client_id: "test",
      secret_key: "test",
      crm_url: "test/test",
      active_YN: true
/*     } */
  };

  constructor(props) {
    super(props);
    this.getConnectionInfoFromDB();
    //console.log("props in auth", props);
    //console.log("tetstaus", props.history.state.whichCRM);
    this.handleLoginIdChange = this.handleLoginIdChange.bind(this);
  }

  getConnectionInfoFromDB = () => {
    
    //get info from db
   /*  this.setState({
    }); */
  }

  handleSaveNProceedClicked = (e) => {
    //console.log("configuration for CRM ", e);
    this.setState({ CRMClicked: true, whichCRM: e });
    //alert();
  }

  handleGoBackClicked = () => {
    //console.log("go back clicked");
    this.setState({ goBack: true });
  }

  handleLoginIdChange = (e) => {
    //console.log("name", e.target.value);
    this.setState({
        user_id: e.target.value
    });
  }

  handleTestConnectionClicked = () => {
    //console.log("Test connection clicked");
  }

  render() {
    if (this.state.CRMClicked) {
      return <Redirect to='/integrations/configuredataobjsmapCRM' />;
    }
    if (this.state.goBack) {
      return <Redirect to='/integrations/configureCRM' />
    }
    return (
      <React.Fragment>
        <div className={styles.main_wrapper}>
          <div className="row">
            <div className="col-12">

              <div className={"breadcrumbs row " + styles.centerAlign}>
                <div className="col-md-6 col-12">
                  <h2 className="heading">Integrations</h2>
                  <div className="breadcrumbs_container">
                    <Breadcrumbs separator="›" arial-label="Breadcrumb">
                      <Link to="/sales-news">Home</Link>
                      <Link to="/integrations">Integrations</Link>
                      <Link to="/integrations/configureCRM">Configure CRM</Link>
                      <span>Authenticate</span>
                    </Breadcrumbs>
                  </div>
                </div>
              </div>
              <div className="row container-body">
                <div className={`col-xs-12 col-md-12 col-lg-12 col-12`}>
                  <div className={styles.authenticateCRM_container}>

                    <div className={styles.authenticateCRM_container_header}>
                      <h1 className={styles.authenticateCRM_container_heading}>
                        Step 2 - User Authentication
                      </h1>
                    </div>

                    <div className={styles.authenticateCRM_container_footer} >
                      {/* start */}
                      <div>
                        <div className="row">
                          <div className="col-12">
                            <div className="bg-white ml-4 mr-4">
                              <div className="mt-5 mb-4">
                                <div className="row">
                                  <div className="col-sm-3" />
                                  <div className="col-sm-6">
                                    <label className={styles.profileContainer_label__ykzKB}>User Login ID<span style={{ color: '#c00' }}> *</span></label>
                                    <input onChange={this.handleLoginIdChange} value={this.state.user_id} className={styles.profileContainer_inputStyle__1Dduu} type="text" autoComplete="off" />
                                    <label className={styles.profileContainer_label__ykzKB}>Password<span style={{ color: '#c00' }}> *</span></label>
                                    <input value={this.state.password} className={styles.profileContainer_inputStyle__1Dduu} type="password" autoComplete="off"/>
                                    {/*  <button className="btn btn-warning btn-sm mt-3" type="button" style={{ padding: '5px 20px' }}>Test This Connection</button> */}
                                  </div>
                                  <div className="col-sm-3" />
                                  <div className="col-sm-3" />
                                  <div className="col-sm-6">
                                    {/*  <div className="alert alert-success" role="alert" style={{ marginTop: 20 }}>
                                      <div className="alert-text">
                                        <h4 className="alert-heading">Connection Successfull !!</h4>
                                        <p>Your credentials have been verified and the connection has been established successfully.</p>
                                        <hr />
                                        <p className="mb-0">Please proceed for further configuration.</p>
                                      </div>
                                    </div> */}
                                    {/* <div className="alert alert-danger" role="alert" style={{ marginTop: 20 }}>
                                      <div className="alert-text">
                                        <h4 className="alert-heading">Error In Connection...</h4>
                                        <p>Sorry! The connection could not be established. Please verify your connection URL and login credentials.</p>
                                      </div>
                                    </div> */}
                                  </div>
                                  <div className="col-sm-3" />
                                </div>
                                {/* </div>
                              <div> */}
                                <div className="row">
                                  <div className="col-sm-3" />
                                  <div className="col-sm-6">
                                    <label className={styles.profileContainer_label__ykzKB}>Client Account ID<span style={{ color: '#c00' }}> *</span></label>
                                    <input value={this.state.client_id} className={styles.profileContainer_inputStyle__1Dduu} type="text" autoComplete="off" />
                                    <label className={styles.profileContainer_label__ykzKB + ' ' + styles.mt_2}>Secret Key / Security Token</label>
                                    <input value={this.state.secret_key} className={styles.profileContainer_inputStyle__1Dduu} type="text" autoComplete="off" defaultValue />
                                    <button onClick={this.handleTestConnectionClicked} className="btn btn-warning btn-sm mt-3" type="button" style={{ padding: '5px 20px' }}>Test This Connection</button>
                                  </div>
                                  <div className="col-sm-3" />
                                  <div className="col-sm-3" />
                                  <div className="col-sm-6">
                                    <div className="alert alert-success" role="alert" style={{ marginTop: 20 }}>
                                      <div className="alert-text">
                                        <h4 className="alert-heading">Connection Successful !!</h4>
                                        <p>Your credentials have been verified and the connection has been established successfully.</p>
                                        <hr />
                                        <p className="mb-0">Please proceed for further configuration.</p>
                                      </div>
                                    </div>
                                    <div className="alert alert-danger" role="alert" style={{ marginTop: 20 }}>
                                      <div className="alert-text">
                                        <h4 className="alert-heading">Error In Connection...</h4>
                                        <p>Sorry! The connection could not be established. Please verify your connection URL and login credentials.</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-3" />
                                </div>
                              </div>

                              <hr className="jss201" />
                              <div className="mt-3 mb-5">
                                <div style={{ textAlign: 'right' }}>
                                  <button onClick={this.handleGoBackClicked} className={styles.profileContainer_update_btn__MXNDH} type="button" style={{ backgroundColor: '#e0e0e0', padding: '10px 20px', color: 'rgba(0, 0, 0, 0.87)' }}>Go Back</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                  <button onClick={() => this.handleSaveNProceedClicked("step2")} type="button" className={styles.profileContainer_update_btn__MXNDH} style={{ padding: '10px 20px' }}>Save &amp; Proceed</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* end */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(stylesMaterialUI)(AuthenticateCRMComponent);