import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MDTableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import styled from "styled-components";
// import Label from '@material-ui/core/Label';
import _ from "lodash";

const TableCell = styled(MDTableCell)`
  padding-left: 0em;
  border-bottom-width: 0.125em;
  border-bottom-color: #c9c8d0;
  text-align: center;
  font-weight: 650;
  color: #62616e;
  margin-right: 0.9375em !important;
  margin-left: 0.9375em;
`;

function getField(row, orderBy) {
  const [first, second, third] = orderBy.split(".");

  if (third) {
    return (
      row[first] &&
      row[first][second] &&
      row[first][second][third].toLowerCase()
    );
  } else if (second) {
    return row[first] && row[first][second].toLowerCase();
  }
  return row[orderBy] && row[orderBy].toLowerCase();
}

function sortBy(data, orderBy, order) {
  if (
    orderBy === "role_assigned" ||
    orderBy === "organization" ||
    orderBy === "department" ||
    orderBy === "actions"
  ) {
    return data;
  }
  const sorted = _.sortBy(data, [(row) => getField(row, orderBy)]);
  return order === "asc" ? sorted : sorted.reverse();
}

function EnhancedTableHead(props) {
  const { classes, headers, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ borderSpacing: "0.9375em 3.125em", textAlign: "left" }}>
      <TableRow style={{ marginBottom: 8 }}>
        {headers.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={
              orderBy === headCell.id && headCell.sort ? order : false
            }
            style={{ textAlign: "left", paddingLeft: 10 }}
          >
            <TableSortLabel
              active={headCell.sort && headCell.id === orderBy}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={headCell.sort && createSortHandler(headCell.id)}
              style={{ marginBottom: 8 }}
            >
              {headCell.label}
              {headCell.sort && orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "" : ""}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function CustomTable(props) {
  const {
    classes,
    headers,
    data,
    renderRow,
    initialSortField,
    count,
    initialOrder,
    disableSort,
    tablePaginationStyle,
  } = props;
  const [order, setOrder] = useState(initialOrder || "asc");
  const [orderBy, setOrderBy] = useState(initialSortField);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleOnRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const minIndex = page * rowsPerPage;
  const maxIndex = page * rowsPerPage + rowsPerPage - 1;

  const sorted = disableSort ? data : sortBy(data, orderBy, order);

  return (
    <>
      <Table style={{ borderSpacing: 15 }}>
        <EnhancedTableHead
          classes={classes}
          headers={headers}
          onRequestSort={handleOnRequestSort}
          order={order}
          orderBy={orderBy}
        />
        <TableBody>
          {sorted
            .filter((_, i) => i >= minIndex && i <= maxIndex)
            .map((row, i) => renderRow(row, i))}
        </TableBody>
      </Table>
      <TablePagination
        style={ tablePaginationStyle || { paddingBottom: 150 }}
        rowsPerPageOptions={[10, 20]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        classes={{
          selectIcon: classes.selectIcon,
        }}
      />
    </>
  );
}

const styles = (theme) => ({
  selectIcon: {
    paddingLeft: "5px",
  },
});

export default withStyles(styles)(CustomTable);
