import React, { Component } from "react";
import leadXLogo from "../../../../assets/images/login/LeadX_logo.png";
import styles from "./LoginComponent.module.scss";
import { Button, Input, FormGroup } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { openSnackbar } from "../../../common/Snackbar/snackbar";
import LargeScreenLoader from "../../../common/Loaders/PulseLoader/pulse-loader";
import { Link } from "react-router-dom";
import { emailPattern } from '../../../common/common-types';
import { setStorageItem, getStorageItem } from '../../../common/common-functions';
import apis from "./apis";
import moment from "moment";
const stylesMaterialUI = theme => ({
  input: {
    borderBottom: "none",
    color: "black",
    "&:focus": {
      borderBottom: "none"
    }
  }
});

class LoginComponent extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    loginForm: {
      userName: {
        value: "",
        valid: false,
        touched: false,
        errorMessage: ""
      },
      password: {
        value: "",
        valid: false,
        touched: false,
        errorMessage: ""
      }
    },
    isLoading: false,
    isRegister: false,
    currVersion: null,
    key: '',
  };

  async componentDidMount() {

    const user = JSON.stringify(getStorageItem("user"));
    if (user !== 'null') {
      this.props.logout();
      localStorage.clear();
      this.setState({ isLoading: false })
    } else {
      this.setState({ isLoading: false })
    }
    const result = await apis.isRegistered();
    this.setState({ isRegister: result.data && result.data.self_user_reg_config_enabled, key: result.data.key });
    
    //this.getVersion();
    await apis.getVersion();
    //this.setState({ currVersion: version.data });
  }

  handleUsernameChange = e => {
    let name = e.target.name;
    let userName = e.target.value;
    const updatedLoginForm = {
      ...this.state.loginForm
    };
    const updatedFormElement = {
      ...updatedLoginForm[name]
    };
    updatedFormElement.value = userName;
    updatedFormElement.touched = true;

    if (userName.trim() === "") {
      updatedFormElement.errorMessage =
        "Please enter a username/email address";
      updatedFormElement.valid = false;
    } else if (!emailPattern.test(userName)) {
      updatedFormElement.errorMessage =
        "Please enter a valid username/email address";
      updatedFormElement.valid = false;
    } else if (userName.length > 50) {
      updatedFormElement.errorMessage = "Username length too long";
      updatedFormElement.valid = false;
    } else {
      updatedFormElement.errorMessage = "";
      updatedFormElement.valid = true;
    }
    updatedLoginForm[name] = updatedFormElement;
    this.setState({
      loginForm: updatedLoginForm
    });
  };

  handlePasswordChange = e => {
    let name = e.target.name;
    let password = e.target.value;
    const updatedLoginForm = {
      ...this.state.loginForm
    };
    const updatedFormElement = {
      ...updatedLoginForm[name]
    };
    updatedFormElement.value = password;
    updatedFormElement.touched = true;
    // const passwordPattern = /(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{4,}$/;
    if (password.trim() === "") {
      updatedFormElement.errorMessage = "Please enter a password";
      updatedFormElement.valid = false;
    }
    // else if (!passwordPattern.test(password)) {
    //   updatedFormElement.errorMessage = "Please enter a valid password";
    //   updatedFormElement.valid = false;
    // } else if (password.length < 12) {
    //   updatedFormElement.errorMessage = "Password too short";
    //   updatedFormElement.valid = false;
    // } else if (password.length > 12) {
    //   updatedFormElement.errorMessage = "Password too long";
    //   updatedFormElement.valid = false;
    // }
    else {
      updatedFormElement.errorMessage = "";
      updatedFormElement.valid = true;
    }
    updatedLoginForm[name] = updatedFormElement;
    this.setState({
      loginForm: updatedLoginForm
    });
  };

  checkValidity = () => {
    return (
      this.state.loginForm.userName.valid && this.state.loginForm.password.valid
    );
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      isLoading: true
    });
    const requestBody = {
      username: this.state.loginForm.userName.value,
      password: this.state.loginForm.password.value
    };
    this.props.userLogin(requestBody);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.user) {
      this.goTo(nextProps.user);
    } else if (nextProps && nextProps.errorMessage !== "") {
      if (nextProps.errorMessage === undefined) {
        openSnackbar("Technical error occured.", "snack-error");
      } else {
        openSnackbar("Incorrect username and/or password.", "snack-error");
      }
      this.setState({
        loginForm: {
          ...this.state.loginForm,
          password: {
            value: "",
            valid: false,
            touched: false,
            errorMessage: ""
          }
        },
        isLoading: false
      });
      nextProps.resetUserLogin();
    }
    return nextProps;
  }

  goTo = user => {
    if (user.id) {
      let currentUser = {
        user_id: user.id,
        name: user.name,
        email: this.state.loginForm.userName.value,
        user_avatar: user.user_avatar,
        license_type: user.license_type,
        role: user.role,
        dialing_code: user.countryCode && user.countryCode.dialing_code,
        contact: user.contact,
        department: user.department,
        currency: user.currency,
        startDate: moment(user.account.account_user_license.start_date).format(" MMMM D, YYYY"),
        endDate: moment(user.account.account_user_license.end_date).format(" MMMM D, YYYY"),
        activeLicense: user.account.account_user_license.active_license,
        organization_id: user.account_id,
        organizationName: user.account.name,
        is_parent: user.is_parent,
        has_revenue_field_access: user.has_revenue_field_access,
        has_create_access: user.has_create_access,
        enable_publishing_news: user.enable_publishing_news,
        enable_publishing_news_child: user.enable_publishing_news_child
      };
      // localStorage.setItem("user", JSON.stringify(currentUser));
      setStorageItem("user", JSON.stringify(currentUser))
      var promise = new Promise((resolve, reject) => {
        // var savedUser = localStorage.getItem("user");
        var savedUser = getStorageItem("user");
        if (savedUser) {
          resolve(savedUser);
        }
      });
      promise.then(savedUser => {
        this.props.history.push("/sales-news"); // this will redirect to intended page
        //openSnackbar("You've logged in successfully!", "snack-success");
      });
    } else {
      openSnackbar("Invalid username or password!", "snack-error");
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        {this.state.isLoading ? <LargeScreenLoader /> : ""}
        <div className={styles.login}>
          <div className={styles.logo_container}>
            <img className={styles.logo} src={leadXLogo} alt="Lead X logo" />
          </div>
          <div className={styles.welcome}>
            <h2 className={styles.heading}>Welcome to</h2>
            <h2 className={styles.heading_leadx}>LEADX!</h2>
            <h5>Before we begin, please login into your account</h5>
          </div>
          <form className={styles.login_form} autocomplete="off" onSubmit={this.handleSubmit}>
            <FormGroup>
              <div className={styles.input_container}>
                <Input
                  inputProps={{
                    maxLength: 50,
                    autoFocus: true
                  }}
                  className={classes.input + " " + styles.input}
                  type="text"
                  value={this.state.loginForm.userName.value}
                  name="userName"
                  placeholder="Enter username/email address"
                  onChange={this.handleUsernameChange}
                />
                {this.state.loginForm.userName.touched ? (
                  this.state.loginForm.userName.valid ? null : (
                    <span className={styles.errors}>
                      {this.state.loginForm.userName.errorMessage}
                    </span>
                  )
                ) : null}
              </div>
              <div className={styles.input_container}>
                <Input
                  inputProps={{
                    maxLength: 20
                  }}
                  className={classes.input + " " + styles.input}
                  type="password"
                  value={this.state.loginForm.password.value}
                  name="password"
                  placeholder="Enter password"
                  onChange={this.handlePasswordChange}
                  autocomplete="off"
                />
                {this.state.loginForm.password.touched ? (
                  this.state.loginForm.password.valid ? null : (
                    <span className={styles.errors}>
                      {this.state.loginForm.password.errorMessage}
                    </span>
                  )
                ) : null}
              </div>
              <div className={styles.login_button_container}>
                <Button
                  disabled={!this.checkValidity()}
                  className={styles.button}
                  type="submit"
                >
                  Login
                </Button>
              </div>
            </FormGroup>
          </form>
          {!this.state.isRegister ? (<div className={styles.forgot_password}>
            
              <h5>
                Forgot your <Link to="/forgot-password" className={styles.forgot_password_link}>password?</Link>
              </h5>
           
          </div>) :
            (<div className={styles.forgot_password}>
              
                <h5>
                  Forgot your <Link to="/forgot-password" className={styles.forgot_password_link}>password?</Link> | 
                              < Link to={this.state.key ? `/register/${this.state.key}` : "/register"} className={styles.register_link}>Register</Link>
                </h5>
            </div>)}
            <div className={styles.version}>
              <p>Version : 2.1.4</p>
            </div>
        </div>
      </>
    );
  }
}

export default withStyles(stylesMaterialUI)(LoginComponent);
