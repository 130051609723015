import * as types from "./types";
import * as commonTypes from "../../../common/common-types";
import axios from "../../../common/api-config";

export const getPipelineLeadList = (requestBody) => {
  requestBody = requestBody || getPipelineLeadList.prevRequestBody;
  requestBody.license_type = Array.isArray(requestBody.license_type) ? requestBody.license_type[0] : requestBody.license_type;
  getPipelineLeadList.prevRequestBody = requestBody;

  return (dispatch) => {
    dispatch({ type: commonTypes.HANDLE_LOADING, data: true });
    axios
      .get("/leads", { params: requestBody })
      .then(async (response) => {
        if (response && response.data && response.data.statusCode == 200) {
          if (
            requestBody &&
            requestBody.paginate &&
            requestBody.paginate.page == 0
          ) {
            await dispatch(leadListSuccess(response.data.data));
          } else {
            await dispatch(loadMoreLeadListSuccess(response.data.data));
          }
          dispatch({ type: commonTypes.HANDLE_LOADING, data: false });
        } else {
          await dispatch(leadListFailed(response.data.errorMessage));
          dispatch({ type: commonTypes.HANDLE_LOADING, data: false });
        }
      })
      .catch(async (error) => {
        await dispatch(leadListFailed(error.errorMessage));
        dispatch({ type: commonTypes.HANDLE_LOADING, data: false });
      });
  };
};

const leadListFailed = (message) => {
  return {
    type: types.LEAD_LIST_FAILED,
    errorMessage: message,
  };
};

const leadListSuccess = (data) => {
  return {
    type: types.LEAD_LIST_SUCCESS,
    data: data,
  };
};

const loadMoreLeadListSuccess = (data) => {
  return {
    type: "LOAD_MORE_LEAD_LIST_SUCCESS",
    data,
  };
};

const contactListFailed = (message) => {
  return {
    type: types.CONTACT_LIST_FAILED,
    errorMessage: message,
  };
};

const contactListSuccess = (data, count) => {
  return {
    type: types.CONTACT_LIST_SUCCESS,
    data: data,
    count: count,
  };
};

export const getContactList = (requestBody) => {
  return (dispatch) => {
    // dispatch({type:commonTypes.HANDLE_LOADING,data:true});
    dispatch({ type: types.LOAD_CONTACT_LIST, data: true });
    axios
      .post("/contact/person/getList", requestBody)
      .then(async (response) => {
        if (response && response.data && response.data.statusCode === 200) {
          await dispatch(
            contactListSuccess(
              response.data.data.rows,
              response.data.data.count
            )
          );
          // dispatch({type:commonTypes.HANDLE_LOADING,data:false});
          dispatch({ type: types.LOAD_CONTACT_LIST, data: false });
        } else {
          await dispatch(contactListFailed(response.data.errorMessage));
          // dispatch({type:commonTypes.HANDLE_LOADING,data:false});
          dispatch({ type: types.LOAD_CONTACT_LIST, data: false });
        }
      })
      .catch(async (error) => {
        await dispatch(contactListFailed(error.errorMessage));
        // dispatch({type:commonTypes.HANDLE_LOADING,data:false});
        dispatch({ type: types.LOAD_CONTACT_LIST, data: false });
      });
  };
};

export const unparkLead = (requestBody) => {
  return (dispatch) => {
    axios
      .put(`/leads/${requestBody.id}`, requestBody)
      .then((response) => {
        if (response && response.data && response.data.statusCode == 200) {
          dispatch({ type: "UNPARK_LEAD_SUCCESS", data: response.data.data });
        } else {
          dispatch({
            type: "UNPARK_LEAD_FAILED",
            data: response.data.errorMessage,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: "UNPARK_LEAD_FAILED", data: error.errorMessage });
      });
  };
};

export const resetUnparkLeadFlag = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_UNPARK_LEAD", data: false });
  };
};

export const selectMypipelineOrg = (org) => {
  let selectedOrg = null;
  if (org.length && org.length > 0) {
    selectedOrg = org[0];
  } else {
    selectedOrg = org;
  }
  return (dispatch) => {
    dispatch({
      type: types.SET_ORGANIZATION_MY_PIPELINE,
      mypipelineOrgSelected: selectedOrg,
    });
  };
};

export const selectMypipelineUser = (user) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_USER_MY_PIPELINE,
      mypipelineUserSelected: user,
    });
  };
};

export const resetError = () => {
  return (dispatch) => {
    dispatch({ type: types.RESET_ERROR });
  };
};

export default {
  getPipelineLeadList,
  getContactList,
  unparkLead,
};
