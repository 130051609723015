import axios from "../../common/api-config";

const obj = {
  async getOrgAccessOfAUser(userId) {
      const [accounts, userAccess] = await Promise.all([
        this.getManageAccessAccounts(),
        this.getUserConfiguration(userId)
      ]);

      return accounts.map(account => {
          const user_access = userAccess.find(u => u.account_id === account.id);

          return {
              ...account,
              access: account.access || (user_access && user_access.has_access)
          }
      });
  },
  async getManageAccessAccounts() {
    const url = "/account/getManageAccessAccounts";

    const response = await axios.post(url, {});

    return response.data.data.rows.map((account) => ({
      ...account,
      is_parent: account.parent_account_id === 0,
    }));
  },
  async getParentOrgUsers() {
    const url = "/users/getAllUserOfAccount";

    const response = await axios.post(url, {});
    console.log(response, url, "getParentOrgUsers");

    return response.data.data.rows.map((user) => {
      const role = user.license_type && user.license_type.display_name;
      return {
        id: user.id,
        label: `${user.name} ${role}`,
        name: user.name,
        url: user.user_avatar,
        role: user.license_type && user.license_type.display_name,
        account: user.account && user.account.name,
      }
    });
  },
  async saveConfiguration(user_id, configuration) {
    const body = {
      user_id,
      configuration,
    };
    const url = "/users/configureManageAccess";
    const response = await axios.post(url, body);
    return response.data;
  },
  async getUserConfiguration(user_id) {
    const url = "/users/getUserOrgAccess";
    const response = await axios.post(url, { user_id });
    return response.data.data.rows;
  },
};

export default obj;
