import React from 'react';
import styles from "../graphContainer.module.scss";
import PieChart from './PieChart';

function PieChartContainer(props) {
  const { leftHeaderValue, leftSubHeaderValue, leftHeaderColor, pieData, leftFooterValue, rightHeaderTitle, noData } = props;

  // const leadsTilePieChartOptions = {
  //     colors: ["#5D3299", "#CC5869", "#f6b93b", "#e55039", "#2F2A21", "#4C4130", "#816A45", "#C5A058", "#FCD06F", "#5B7262", "#252C28", "#85A98F"],
  // };

  const leadsTilePieChartOptions = {
    colors: [],
  };

  if (leftSubHeaderValue === 'Leads') {
    leadsTilePieChartOptions.colors = ["#F1F8F1", "#DAEEE0", "#BFE1C8", "#99CDA1", "#82DC8F", "#7EC788", "#6DAA76", "#4E9058", "#285D30", "#5A7D5F", "#3B533F", "#04340B"];
  } else if (leftSubHeaderValue === 'Revenue') {
    leadsTilePieChartOptions.colors = ["#F8EEEE", "#F8DBCF", "#F1C5B3", "#E6ADA0", "#FFA996", "#F7836A", "#EF5E3D", "#C14A30", "#983924", "#6B2E20", "#4F231A", "#B2DCBC"];
  } else if (leftSubHeaderValue === 'Hit Rate') {
    leadsTilePieChartOptions.colors = ["#FFF4DE", "#FFE4B4", "#FDD88F", "#FFC458", "#F9AE19", "#FF9F00", "#E49700", "#CBA15C", "#9D7D51", "#A17112", "#6F5634", "#43331E"];
  }
  else if (leftSubHeaderValue === 'Avg.Velocity') {
    leadsTilePieChartOptions.colors = ["#EAE6F6", "#B0A2D5", "#C8C3E9", "#9691B9", "#978AF1", "#9782CC", "#725DA8", "#544A99", "#635D8F", "#474360", "#1B144C", "#242040"];
  }

  return (
    <React.Fragment>
      <div className="row" style={{height: '100%', minHeight: 280}}>
        <div className="col-lg-5 center-block text-center">
          <div>
            <div className={styles.pieHeaderValue} style={{ color: noData ? '#929292' : leftHeaderColor }}>
              {leftHeaderValue}
            </div>
            <div className={styles.pieSubHeaderTital} >
              {leftSubHeaderValue}
            </div>
            <div>
              {!noData ? <PieChart
                width={100}
                height={100}
                innerRadius={25}
                outerRadius={50}
                options={leadsTilePieChartOptions}
                piedata={pieData}
              >
              </PieChart> : null}
            </div>
            {!noData && <div className={styles.pieFooterTitle}>
              {leftFooterValue}
            </div>}
          </div>
        </div>
        {!noData ? (<div className="col-lg-7" >
          <div className="border-bottom">
            <div className={styles.pieRightTitle}> {rightHeaderTitle} </div>
          </div>
          <div>
            {pieData ?
              <div className={styles.pieMonthData}>
                <ul>
                  {pieData.map((value, index) => {
                    return (
                      <React.Fragment>
                        <div className="row" >
                          <div className={styles.pieChartPerMonth} style={{ paddingRight: 15 }}>
                                {<> <span style={{ marginRight: 5, display: 'inline-block', borderRadius: 5, width: 15, height: 10, backgroundColor: leadsTilePieChartOptions.colors[index] }}>

                                </span>
                                  <span style={{ display: 'inline-block', width: 35, textAlign: 'right' }}>
                                    {value.yValue || value.yVal}
                                  </span> </>
                                }
                              </div>

                          <div className={styles.pieMonthTitleData}>
                            <li key={index}>{value.xVal}</li>
                          </div>
                        </div>
                      </React.Fragment>
                    )
                  })}
                </ul>
              </div>
              :
              <div>No data available! </div>
            }
          </div>
        </div>) : (<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <img src={require('../../../../assets/NoDataDonutChart.svg')} style={{width: 100}}/>
          <div style={{marginTop: 8, color: '#929292', fontWeight: 700}}>No data available</div>
        </div>)}
      </div>

    </React.Fragment>
  )
}

export default PieChartContainer
