import axios from "../../../common/api-config";
import { setStorageItem, getStorageItem } from "../../../common/common-functions";

export const getUserDetails = requestBody => {
  return dispatch => {
    axios.post("/users/getUserDetailsById", requestBody)
      .then(response => {
        if(response && response.data && response.data.statusCode == 200){
          dispatch({type: 'USER_DETAILS_SUCCESS',data: response.data.data});
        }else{
          dispatch({type: 'USER_DETAILS_ERROR',data: response.data.errorMessage});
        }
      })
      .catch(error => {
        dispatch({type: 'USER_DETAILS_ERROR',data: error.errorMessage});
      });
  };
};

export const updatedUserDetails = requestBody => {
  return dispatch => {
    axios.put("/users/update", requestBody)
    .then(response => {
      if(response && response.data && response.data.statusCode == 200){
        // const user = JSON.parse(localStorage.getItem("user"));
        const user = JSON.parse(getStorageItem("user"));
        const updatedUser = {
          ...user,
          name: response.data.data.name
        }
        // localStorage.setItem("user", JSON.stringify(updatedUser));
        setStorageItem("user", JSON.stringify(updatedUser));
        dispatch({type: 'USER_UPDATE_SUCCESS',data: response.data.data})
      }else{
        dispatch({type: 'USER_UPDATE_ERROR',data: response.data.errorMessage});
      }
    })
    .catch(error => {
      dispatch({type: 'USER_UPDATE_ERROR',data: error.errorMessage});
    });
  }
}

export const updateProfilePhoto = requestBody => {
  return dispatch => {
    axios.put("/users/updateAvatar", requestBody)
    // axios.put("/users/updateUserAvatar", requestBody)
    .then(response => {
      if(response && response.data && response.data.statusCode === 200){
        // const user = JSON.parse(localStorage.getItem("user"));
        const user = JSON.parse(getStorageItem("user"));
        const updatedUser = {
          ...user,
          user_avatar: response.data.data.user_avatar
        }
        // localStorage.setItem("user", JSON.stringify(updatedUser));
        setStorageItem("user", JSON.stringify(updatedUser));
        dispatch({type: 'USER_UPDATE_SUCCESS',data: response.data.data, profilePhoto: true})
        // setTimeout(dispatch({type: 'USER_UPDATE_SUCCESS',data: response.data.data, profilePhoto: true}), 2000);
      } else if (response && response.data && response.data.statusCode === 501){
        dispatch({type: 'USER_UPDATE_ERROR',data: 'Image type is not acceptable.'});
      } else {
        dispatch({type: 'USER_UPDATE_ERROR',data: response.data.errorMessage});
      }
    })
    .catch(error => {
      dispatch({type: 'USER_UPDATE_ERROR',data: error.errorMessage});
    });
  }
}

export const resetUserDetailsFlag = requestBody => {
  return dispatch => {
    dispatch({type: 'RESET_USER_UPDATE_FLAG', data: false});
  }
}

export const resetErrorMessage = () => {
  return dispatch => {
    dispatch({type: 'RESET_ERROR_MESSAGE'});
  }
}

export const startLoading = requestBody => {
  return dispatch => {
    dispatch({type: 'LOADING', data: true});
  }
}

export const removeProfileImage = requestBody => {
  return dispatch => {
    axios.put("/users/removeUserAvatar", requestBody)
    .then(response => {
      if(response && response.data && response.data.statusCode == 200){
        // const user = JSON.parse(localStorage.getItem("user"));
        const user = JSON.parse(getStorageItem("user"));
        const updatedUser = {
          ...user,
          user_avatar: null
        }
        // localStorage.setItem("user", JSON.stringify(updatedUser));
        setStorageItem("user", JSON.stringify(updatedUser));
        dispatch({type: 'USER_UPDATE_SUCCESS',data: response.data.data, removePhoto: true });
      } else{
        dispatch({type: 'USER_UPDATE_ERROR',data: response.data.errorMessage});
      }
    })
    .catch(error => {
      dispatch({type: 'USER_UPDATE_ERROR',data: error.errorMessage});
    })
  }
}

export default {
    getUserDetails
};