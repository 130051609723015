import React, { useEffect, useState } from "react";
import styles from "./pulseLiteContainer.module.scss";
import * as actions from "./redux/actions";

import LargeScreenLoader from "../../common/Loaders/PulseLoader/pulse-loader";
import salesCommunityActions from "../SalesCommunity/redux/actions";
import { selectMypipelineOrg } from "../MyPipeline/redux/actions";
// Assets
import employee_circle from "../../../assets/images/bi/employee_circle.png";
import lead_circle from "../../../assets/images/bi/lead_circle.png";
import revenue_circle from "../../../assets/images/bi/revenue_circle.png";
import EmployeeList from "./EmployeeList/EmployeeList";
import APIs from "./APIs";

//  Excel Export
import ExcelExport from "./Excel/ExcelExport";
import PDFExport from "./PDF/PDFExport";
import { toMillion } from "../../common/Util";

function ProfileContainer({organizationArray, fromDate, toDate}) {
  const [revenueData, setRevenueData] = useState(null);
  const [totalEmployee, setTotalEmployee] = useState(0);
  const [bonusReportDetailByMonth, setBonusReportDetailByMonth] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const dateRange = { from_date: fromDate, to_date: toDate };

  useEffect(() => {
    if (organizationArray && fromDate && toDate) {
      fetchData();
    }
  }, [organizationArray, fromDate, toDate]);

  const fetchData = async () => {
    setIsLoading(true);
    const [
      items,
      revenueData,
      totalEmployee,
      bonusReportDetailByMonth,
    ] = await Promise.all([
      APIs.getEmployeePointDetail(organizationArray, fromDate, toDate),
      APIs.getRevenueTotal(organizationArray, fromDate, toDate),
      APIs.getTotalEmployee(organizationArray, fromDate, toDate),
      APIs.getBonusReportByMonth(organizationArray, fromDate, toDate),
    ]);
    setItems(items);
    setRevenueData(revenueData);
    setTotalEmployee(totalEmployee);
    setBonusReportDetailByMonth(bonusReportDetailByMonth);
    setIsLoading(false);
  };

  return (
    <div>
      {isLoading && <LargeScreenLoader />}
      <div className="container-body">
        <div className="row justify-content-center mb-4">
          <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12">
            <div className="row justify-content-between">
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  paddingRight: 15,
                }}
              >
                <div>
                  {bonusReportDetailByMonth && (
                    <ExcelExport
                      exportData={bonusReportDetailByMonth}
                      selectedMonth={dateRange}
                    ></ExcelExport>
                  )}
                </div>
                {bonusReportDetailByMonth && (
                  <PDFExport
                    pdfData={bonusReportDetailByMonth}
                    selectedMonth={dateRange}
                  ></PDFExport>
                )}
              </div>
            </div>

            <div className="row justify-content-right">
              <div className="col-md-4 border-right">
                <div className="row">
                  <div className={"col-sm-6 " + styles.topSectionLeft}>
                    <img
                      className={styles.icon_pic}
                      src={employee_circle}
                      alt="avrageVelocity"
                    />
                  </div>
                  <div className={"col-sm-6 " + styles.topSection}>
                    <div className={styles.first_content_style}>
                      <div className={styles.content_values1}>
                        {totalEmployee ? totalEmployee : 0}
                      </div>
                      <p className={styles.title_Pulselite}>Employees</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 border-right">
                <div className="row">
                  <div className={"col-sm-6 " + styles.topSectionLeft}>
                    <img
                      className={styles.icon_pic}
                      src={lead_circle}
                      alt="LeadIcon"
                    />
                  </div>
                  <div className={"col-sm-6 " + styles.topSection}>
                    <div className={styles.first_content_style}>
                      <div className={styles.content_values1}>
                        {revenueData ? revenueData.leadsTotal : 0}
                      </div>
                      <p className={styles.title_Pulselite}>Total Leads</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className={"col-sm-6 " + styles.topSectionLeft}>
                    <img
                      className={styles.icon_pic}
                      src={revenue_circle}
                      alt="RevenueIcon"
                    />
                  </div>
                  <div className={"col-sm-6 " + styles.topSection}>
                    <div className={styles.first_content_style}>
                      <div className={styles.content_values1}>
                        {revenueData
                          ? `${toMillion(revenueData.revenue.toFixed(2))}`
                          : 0}
                      </div>
                      <p className={styles.title_Pulselite}>
                        Total Revenue,
                        {revenueData && revenueData.account
                          ? " " + revenueData.account.currency.short_name
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-body mt-5">
        <div className="row">
          {!isLoading && <EmployeeList employeeData={items ? items : []}></EmployeeList>}
        </div>
      </div>
    </div>
  );
}

export default ProfileContainer;
