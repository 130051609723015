import React, { Component, useRef, useLayoutEffect, useState } from "react";
import * as d3 from "d3";

class LeadsCompareGraph extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  draw(data, options, lineData, compare, dropdown) {
    console.log('+++++++++++ lineData +++++++++', lineData, data, options, compare, dropdown);

    // Remove svg
    d3.select("#" + options.id)
      .select("svg")
      .remove();

    let ticks;
    if (
      /Android|webOS|BlackBerry|IEMobile|Opera Mini|iPhone/i.test(
        navigator.userAgent
      )
    ) {
      ticks = 2;
      options.xAxisRotate = true;
    } else {
      ticks = 10;
      options.xAxisRotate = false;
    }

    const chartStyle = options.chartStyle || "vertical";
    options.margin = options.margin || {};

    // Chart parent dive height and width
    // const parentWidth = document.getElementById(options.id).parentNode.parentElement.clientWidth;
    // const parentHeight = document.getElementById(options.id).parentNode.parentElement.clientHeight;
    const parentWidth = this.props.width;
    const parentHeight = this.props.height;

    // set the dimensions and margins of the graph
    const margin = {
      top: options.margin.top || 20,
      right: options.margin.right || 20,
      bottom: options.margin.bottom || 70,
      left: options.margin.left || 50,
    };

    const width = (options.width || parentWidth) - margin.left - margin.right;

    const height =
      (options.height || parentHeight) - margin.top - margin.bottom;

    // Parse the date / time
    const parseDate = d3.timeParse(options.parseTime || "%d-%b-%y");
    const timeFormat = d3.timeFormat("%d-%b-%y");
    const tickformat = d3.timeFormat(options.tickFormat || "%d-%b-%y");

    const formatedNumber = d3.format(",");
    const decimalFormat = d3.format(".1f");

    // Tooltip div
    d3.select("#toolTip" + options.id).remove();
    const tooltip = d3.select("body").append("div").attr("class", "toolTip");

    data.sort((a, b) => {
      return a.xVal - b.xVal;
    });

    // format the data
    // data.forEach(d => d.yVal = +d.yVal);

    let x;
    let y;
    let z;

    // Set the ranges
    if (chartStyle === "vertical") {
      x = d3.scaleBand().range([0, width]).padding(0.1);
      y = d3.scaleLinear().range([height, 0]);
      z = d3.scaleLinear().range([height, 0]);

      // Scale the range of the data in the domains
      x.domain(data.map((d) => d.xVal));
      y.domain([
        0,
        // d3.max(data, d => d.yVal)
        d3.max([
          d3.max(data, (d) => d.current),
          d3.max(data, (d) => d.previous),
        ]),
      ]);

      z.domain([0, d3.max(lineData, (d) => d.avg)]);
    } else if (chartStyle === "horizontal") {
      x = d3.scaleLinear().range([0, width]);
      y = d3.scaleBand().range([height, 0]);

      // Scale the range of the data in the domains
      x.domain([0, d3.max(data, (d) => d.yVal)]);
      y.domain(data.map((d) => d.xVal)).padding(0.1);
    }

    // Adds the svg canvas
    const svg = d3
      .select("#" + options.id)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    if (options.yAxisText) {
      svg
        .append("text")
        .attr("x", -140)
        .attr("y", -35)
        .style("font-size", "12px")
        .attr("fill", "#62616E")
        .attr("transform", "rotate(-90)")
        // .attr('dx', '-15.2em')
        // .attr('dy', '-7em')
        .text(options.yAxisTextString || "Y-Axis");
    }

    if (options.zAxisText) {
      svg
        .append("text")
        .attr("x", 70)
        .attr("y", -(width + 33))
        .style("font-size", "12px")
        .attr("fill", "#62616E")
        .attr("transform", "rotate(90)")
        // .attr('dx', '-15.2em')
        // .attr('dy', '-7em')
        .text(options.zAxisTextString || "z-Axis");
    }

    // set the colour scale
    let color;

    if (options.colors) {
      if (Array.isArray(options.colors)) {
        color = d3.scaleOrdinal(options.colors);
      } else {
        color = d3.scaleOrdinal(d3[options.colors]);
      }
    } else {
      color = d3.scaleOrdinal(d3.schemeCategory10);
    }

    let bar;
    let insideBar;
    // append the rectangles for the bar chart

    const t = d3.transition().duration(800).ease(d3.easePolyOut);

    if (chartStyle === "vertical") {
      // alert(options.barWidth || 0);
      bar = svg
        .selectAll(".bar")
        .data(data)
        .enter()
        .append("rect")
        .attr("class", "bar")
        .attr("fill", (d, i) => {
          return options.multiColorBar
            ? (d.color = color(i))
            : (d.color = color(d));
          // return '#5be2cf';
        })
        .attr("x", (d) => x(d.xVal) + (options.barWidth || 0) / 2 + 33)
        .attr("width", 24)
        .attr("y", (d) => y(d.current || 0))
        .attr("height", (d) => height - y(d.current || 0))
        .attr("rx", "5px")
        .on("mousemove", (d) => {
          tooltip
            .style("left", d3.event.pageX - 30 + "px")
            .style("top", d3.event.pageY - 60 + "px")
            .style("display", "inline-block")
            .style("position", "absolute")
            .style("min-width", "50px")
            .style("width", "auto")
            .style("height", "auto")
            .style("background", "none repeat scroll 0 0 #ffffff")
            .style("border", "1px solid #fff")
            .style("border-radius", "5px 5px 5px 5px")
            .style("box-shadow", "-3px 3px 15px #888888")
            .style("padding", "5px")
            .style("text-align", "left")
            .style("font-size", "12px")
            .style("font-family", "Open Sans, sans-serif");
          tooltip.html("<strong>" + d.xVal + "</strong><br>" + d.current);
        })
        .on("mouseout", (d) => {
          tooltip.style("display", "none");
        });

      // Inside bar for previous data
      if (compare.Checked == 1) {
        insideBar = svg
          .selectAll(".inside-bar")
          .data(data)
          .enter()
          .append("rect")
          .attr("class", "inside-bar")
          .attr("fill", "#FEC55A")
          .attr("x", (d) => x(d.xVal) + (options.insideBarWidth || 0) / 2 + 39)
          .attr("width", 12)
          .attr("y", (d) => y(d.previous))
          .attr("height", (d) => height - y(d.previous))
          .attr("rx", "3px")
          .on("mousemove", (d) => {
            tooltip
              .style("left", d3.event.pageX - 30 + "px")
              .style("top", d3.event.pageY - 60 + "px")
              .style("display", "inline-block")
              .style("position", "absolute")
              .style("min-width", "50px")
              .style("width", "auto")
              .style("height", "auto")
              .style("background", "none repeat scroll 0 0 #ffffff")
              .style("border", "1px solid #fff")
              .style("border-radius", "5px 5px 5px 5px")
              .style("box-shadow", "-3px 3px 15px #888888")
              .style("padding", "5px")
              .style("text-align", "left")
              .style("font-size", "12px")
              .style("font-family", "Open Sans, sans-serif");

            tooltip.html("<strong>" + d.xVal + "</strong><br>" + d.previous);
          })
          .on("mouseout", (d) => {
            tooltip.style("display", "none");
          });
      }

      if (options.valueOnTop) {
        svg
          .selectAll(".bar")
          .data(data)
          .enter()
          .append("rect")
          .attr("class", "bar")
          .attr("x", (d) => x(d.xVal))
          .attr("width", x.bandwidth())
          .attr("y", (d) => y(d.current || 0))
          .attr("height", (d) => height - y(d.current || 0));

        svg
          .selectAll("text.bar")
          .data(data)
          .enter()
          .append("text")
          .attr("class", "bar")
          .style("font-size", "19px")
          .style("font-family", "Open Sans, sans-serif")
          .attr("text-anchor", "middle")

          .attr("x", (d) => x(d.xVal) + x.bandwidth() / 2)
          .attr("y", (d) => y(d.current || 0) - 5)
          .text((d) => {
            if (options.valueOnTopDecimal) {
              return d.current || 0;
            }
          });
      }
    } else if (chartStyle === "horizontal") {
      bar = svg
        .selectAll(".bar")
        .data(data)
        .enter()
        .append("rect")
        .attr("class", "bar")
        .attr("fill", (d, i) =>
          options.multiColorBar ? (d.color = color(i)) : (d.color = color(d))
        )
        .attr("x", (d) => x(d.xVal))
        .attr("width", (d) => width - x(d.yVal))
        .attr("y", (d) => y(d.xVal))
        .attr("height", y.bandwidth());
    }

    // Line chart code
    // Define the line
    const line = d3
      .line()
      .x((d) => x(d.xVal) + x.bandwidth() / 2)
      .y((d) => z(d.avg))
      .curve(d3.curveMonotoneX);

    const linePath = svg
      .append("path")
      .attr("class", "line") // Assign a class for styling
      .attr("fill", "none")
      .style("stroke", "#725DA8")
      .style("stroke-width", "3")
      .attr("d", line(lineData));

    svg
      .selectAll(".dot")
      .data(lineData)
      .enter()
      .append("circle") // Uses the enter().append() method
      .attr("class", (d, i) => "dot" + i) // Assign a class for styling
      .style("fill", "#725DA8")
      .style("stroke-width", 1.5)
      .attr("cx", (d) => x(d.xVal) + x.bandwidth() / 2)
      .attr("cy", (d) => z(d.avg))
      .attr("r", 4)
      .on("mousemove", (d) => {
        tooltip
          .style("left", d3.event.pageX - 30 + "px")
          .style("top", d3.event.pageY - 60 + "px")
          .style("display", "inline-block")
          .style("position", "absolute")
          .style("min-width", "50px")
          .style("width", "auto")
          .style("height", "auto")
          .style("background", "none repeat scroll 0 0 #ffffff")
          .style("border", "1px solid #fff")
          .style("border-radius", "5px 5px 5px 5px")
          .style("box-shadow", "-3px 3px 15px #888888")
          .style("padding", "5px")
          .style("text-align", "left")
          .style("font-size", "12px")
          .style("font-family", "Open Sans, sans-serif");
        tooltip.html("<strong>" + d.xVal + "</strong><br> Avg. Time: " + d.avg);
      })
      .on("mouseout", (d) => {
        tooltip.style("display", "none");
      });


    if (!options.hideXaxis) {
      // add the x Axis
      let xAxis;
      if (options.isTimeSeries) {
        xAxis = d3
          .axisBottom(x)
          .ticks(ticks)
          .tickFormat(tickformat)
          .tickValues(
            x.domain().filter((d, i) => {
              return !(i % 6);
            })
          );
      } else {
        xAxis = d3.axisBottom(x).ticks(ticks);
        // .scale(x);
      }

      const xAxisLine = svg
        .append("g")
        .attr("class", "axis")
        .attr("transform", "translate(0," + height + ")")
        .call(xAxis);

      if (options.xAxisRotate) {
        xAxisLine
          .selectAll("text")
          .style("text-anchor", "end")
          .style("font-size", "8px !important")
          .attr("dx", "-.6em")
          .attr("dy", ".10em")
          .attr("width", 5)
          .attr("transform", "rotate(-23 )");
      }

      xAxisLine.select("path").attr("stroke", "lightgray");
      xAxisLine.selectAll("line").attr("stroke", "lightgray");
    }

    if (!options.hideYaxis) {
      // add the y Axis
      const yAxisLine = svg
        .append("g")
        .attr("class", "y-axis")
        .call(
          d3
            .axisLeft(y)
            .tickArguments([
              options.ticksSizeYaxis || 6,
              options.yAxisNumebrFormat,
            ])
        );

      yAxisLine.select("path").attr("stroke", "lightgray");
      yAxisLine.selectAll("line").attr("stroke", "lightgray");
    }

    if (!options.hideZaxis) {
      // add the y Axis
      const zAxisLine = svg
        .append("g")
        .attr("transform", "translate(" + width + "," + "0)")
        .attr("class", "z-axis")
        .call(
          d3
            .axisRight(z)
            .tickArguments([
              options.ticksSizeZaxis || 6,
              options.zAxisNumebrFormat,
            ])
        );

      zAxisLine.select("path").attr("stroke", "lightgray");
      zAxisLine.selectAll("line").attr("stroke", "lightgray");
    }

    d3.selectAll(".tick")
      .selectAll("text")
      .attr("fill", "#000")
      .style("font-family", "Open Sans, sans-serif")
      .style("font-size", "11px");

    const legendKeys = [
      {
        label: dropdown > 1 ? `Last ${dropdown} Days` : `Last ${dropdown} Year`,
        color: "#725DA8",
      },
      {
        label: dropdown > 1 ? `${dropdown}-${dropdown * 2} Days` : `1-2 year`,
        color: "#FEC55A",
      },
    ];

    const nodeWidth = (d) => d.getBBox().width;

    const legend = svg
      .append("g")
      .attr("class", "legend")
      .attr("transform", "translate(0,0)");

    const lg = legend
      .selectAll("g")
      .data(legendKeys)
      .enter()
      .append("g")
      .attr("transform", (d, i) => `translate(${i * 100},${height + 25})`);

    lg.append("rect")
      .style("fill", (d) => d.color)
      .attr("x", 0)
      .attr("y", 5)
      .attr("width", 10)
      .attr("height", 5)
      .attr("rx", 3);

    lg.append("text")
      .style("font-family", "Open Sans, sans-serif")
      .style("font-size", "11px")
      .attr("x", 17.5)
      .attr("y", 10)
      .text((d) => d.label);

    let offset = 0;
    lg.attr("transform", function (d, i) {
      let x = offset;
      offset += nodeWidth(this) + 10;
      return `translate(${x},${height + 35})`;
    });

    legend.attr("transform", function () {
      // return `translate(${0},${0})`
      return `translate(${(width - nodeWidth(this)) / 2},${0})`;
    });
  }

  render() {
    this.draw(
      this.props.data,
      this.props.options,
      this.props.lineData,
      this.props.compare,
      this.props.dropDownValue
    );
    return <div ref="child" id={this.props.options.id}></div>;
  }
}

export default LeadsCompareGraph;
