import axios from "../../common/api-config";

export default {
  async getRoles() {
    const url = '/master/getRoles';
    const result = await axios.post(url, {});
    return result.data.data.rows;
  },
  async getLicenseTypes() {
    const url = '/master/getLicenseType';
    const result = await axios.post(url, {});
    return result.data.data.rows;
  },
  async getCountries() {
    const url = '/master/territory/country';
    const result = await axios.post(url, {});
    return result.data.data.rows;
  },
  async getOrganizationList() {
    const url = '/salesNews/getOrganizationList';
    const result = await axios.post(url, {});
    return result.data.data.data;
  },
  async getUserProfile(id) {
    const url = '/users/getUserById';
    const result = await axios.post(url, {id});
    return result.data.data.data;
  },
  async getDepartments() {
    const url = '/department/get';
    const result = await axios.post(url, {});
    return result.data.data.rows;
  },
  async getMyAccount() {
    const url = '/users/getMyAccount';
    const result = await axios.post(url, {});
    return result.data.data.data.name;
  },
  async updateProfile(profile) {
    const url = '/users/updateUser';
    const result = await axios.post(url, profile);
    return result.data.data;
  },
  updateProfileImage(obj) {
    let requestBody = {
      id: obj.id,
      user_avatar_old: '',
      attachment: {
        fileName: obj.fileName,
        fileExtension: obj.fileExtension,
        fileBody: obj.fileBody,
        parentFolder: obj.parentFolder
      }
    };
    console.log(requestBody, 'Req Body');
    return axios.put("/users/updateAvatar", requestBody).then(response => {
      return response.data.data.user_avatar;
    })
  }, 
}