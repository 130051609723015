import React, { useState, useEffect } from 'react';
import styles from "./apiRegistration.module.scss";
import {
    Breadcrumbs,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    Paper,
    TableRow,
    Select,
} from '@material-ui/core';
//import TablePagination from '@material-ui/core/TablePagination';
import { Link } from "react-router-dom";
import VisibilityIcon from '@material-ui/icons/Visibility';
import TablePagination from '@material-ui/core/TablePagination';
import APIs from './apis';
import CreateApp from './CreateApp';
import { withStyles } from "@material-ui/core/styles";
import _ from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import InviteUser from '../../common/InviteUser';
import { openSnackbar } from '../../common/Snackbar/snackbar';
import { getStorageItem } from "../../common/common-functions";
import LargeScreenLoader from "../../common/Loaders/PulseLoader/pulse-loader";


const currentUser = JSON.parse(getStorageItem("user"));

const ActiveOptions = [
    'Select',
    'Deactivate',
    'Delete'
  ];

  const DeActiveOptions = [
    'Select',
    'Activate',
    'Delete'
  ];  

  
const ITEM_HEIGHT = 48;

const ApiRegistrationContainer = ({ classes }) => {
    const [open, setOpen] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const [age, setAge] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [result, setResult] = useState({ count: 0, rows: [] });
    const [anchorEls, setAnchorEls] = React.useState([]);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        fetchList();
    }, []);

    const fetchList = async () => {
        const result = await APIs.getList();
        setResult(result);
        setAnchorEls(result.rows.map(_ => null));
        setIsLoading(false);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //open dialog
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (index) => () => {
        setAnchorEls(anchorEls.map((_, i) => {
          if (i === index) {
            return null;
          }
          return _;
        }));
      };

    const handleVisibility = (index) => () => {
        setResult({
            ...result,
            rows: result.rows.map((_, i) => {
                if (index === i) {
                    _.visibility = !_.visibility;
                }
                return _;
            })
        })
    };

    const handlePopupClose = () => setOpen(false);

    const deleteApp = async (id) => {
        const response = await APIs.deleteApp(id);
        if (response.data.status) {
            openSnackbar('App deleted successfully', 'snack-success');
            setResult({
                count: result.count - 1,
                rows: result.rows.filter((i) => i.id !== id)
            });
        }
    };

    const activateApp = async (id) => {
        const response = await APIs.activate(id);
        if (response.data.status) {
            openSnackbar('App activated successfully', 'snack-success');
            setResult({
                ...result,
                rows: result.rows.map(r => {
                    if (r.id === id) {
                        r.is_active = true;
                    }
                    return r;
                })
            });
        }
    };

    const deactivateApp = async (id) => {
        const response = await APIs.deactivate(id);
        if (response.data.status) {
            openSnackbar('App deactivated successfully', 'snack-success');
            setResult({
                ...result,
                rows: result.rows.map(r => {
                    if (r.id === id) {
                        r.is_active = false;
                    }
                    return r;
                })
            });
        }
    };

    const onChangeMenu = (option, id, index) => () => {
        switch (option) {
            case 'Delete':
                deleteApp(id);
                break;
            case 'Activate':
                activateApp(id);
                break;
            case 'Deactivate':
                deactivateApp(id);
                break;
            default:
                break;    
        }

        handleClose(index)();
    };

    const handleClick = (index) => (event) => {
        setAnchorEls(
          anchorEls.map((_, i) => {
            if (i === index) {
              return event.currentTarget;
            }
            return _;
          })
        );
      };
    
    const handleOnCreate = (data) => {
        setResult({
            count: result.count + 1,
            rows: [data, ...result.rows]
        })
    };  

    const minIndex = page * rowsPerPage;
    const maxIndex = page * rowsPerPage + rowsPerPage - 1;

    return (
        <>
            {isLoading && <LargeScreenLoader />}
            {!isLoading && <div className="main-wrapper">
                <div className="breadcrumbs">
                    <div className="row justify-content-between">
                        <div className={"col-md-4 " + styles.title_right_text}>
                            <h3>API Registration</h3>
                        </div>
                    </div>
                    <div className="row breadcrumbs_container">
                        <div className={"col-md-6 "}>
                            <Breadcrumbs separator="›" arial-label="Breadcrumb">
                                <Link to="/sales-news">Home</Link>
                                <Link to="/administration">Administration</Link>
                                <span>API Registration</span>
                            </Breadcrumbs>
                        </div>
                        <div className={"col-md-6 "}>
                            <nav className={styles.navbar}>
                                <span class="mr-4">{currentUser && currentUser.organizationName}</span>
                                {/*<span class="mr-4">|</span>
                                 <InviteUser class="mr-4"/> */}
                                <Button variant="contained" onClick={handleClickOpen} style={{ marginLeft: 8, backgroundColor: "#3A4860", color: "#fff", textTransform: "none" }} size="small">
                                    Register Application
                                </Button>
                                <CreateApp onCreate={handleOnCreate} open={open} onClose={handlePopupClose} />
                            </nav>
                        </div>
                    </div>
                </div>
                <hr />

                <div className={styles.title_right_text}>
                    <h3>Registered Application</h3>
                </div>
                <hr />
                <TableContainer className={styles.tableContainer} component={Paper}>
                    <Table className={styles.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={styles.MuiTableCellHead}><span>Application Name</span> <hr /></TableCell>
                                <TableCell className={styles.MuiTableCellHead}><span>Client Id</span>  <hr /></TableCell>
                                <TableCell className={styles.MuiTableCellHead}><span>Secret Id</span>  <hr /></TableCell>
                                <TableCell className={styles.MuiTableCellHead}>
                                    <span>App Status</span>  <hr />
                                </TableCell>
                                <TableCell className={styles.MuiTableCellHead}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {result.rows.filter((_, i) => i >= minIndex && i <= maxIndex).map((row, i) => (
                                <TableRow key={row.name}>
                                    <TableCell className={styles.MuiTableCellBody} scope="row">{row.app_name}</TableCell>
                                    <TableCell className={styles.MuiTableCellBody} align="center">{row.api_key}</TableCell>
                                    <TableCell align="center">
                                        <span style={{ float: "left" }}>{row.visibility ? row.secret_key : _.times(row.secret_key.length, () => '*').join('')}</span>
                                        <span>
                                            <img className={styles.eyeIcon} onClick={handleVisibility(i)} src={require('../../../assets/images/apiregister/eye.png')}/>
                                        </span>
                                    </TableCell>
                                    <TableCell className={styles.MuiTableCellBody} align="center">
                                        {row.is_active ? 'Active' : 'Deactive'}
                                        <Menu
                                            id="long-menu"
                                            anchorEl={anchorEls[i]}
                                            keepMounted
                                            open={Boolean(anchorEls[i])}
                                            onClose={handleClose(i)}
                                            PaperProps={{
                                                style: {
                                                    maxHeight: ITEM_HEIGHT * 4.5,
                                                    width: '20ch',
                                                },
                                            }}
                                        >
                                            {(row.is_active ? ActiveOptions : DeActiveOptions).map((option) => (
                                                <MenuItem onClick={onChangeMenu(option, row.id, i)} key={option} selected={option === 'Select'}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </TableCell>
                                    <TableCell align="center" padding="none">
                                        <IconButton
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            onClick={handleClick(i)}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                    <TablePagination
                        style={{ paddingBottom: 150 }}
                        rowsPerPageOptions={[5, 10, 20]}
                        component="div"
                        count={result.count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        classes={{
                            selectIcon: classes.selectIcon
                        }}
                    />
            </div>
            }
        </>
    )
}


const customStyles = (theme) => ({
                selectIcon: {
                paddingLeft: "5px"
    }
  });
  

export default withStyles(customStyles)(ApiRegistrationContainer);