const initialState = {
  notesList: null,
  errorLoadingNotes: false,
  loadingNotes: true,
  errorMessage: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_NOTES_LIST_SUCCESS":
      return {
        ...state,
        errorLoadingNotes: false,
        notesList: action.data,
        loadingNotes: false
      };

    case "GET_NOTES_LIST_FAIL":
      return {
        ...state,
        errorLoadingNotes: true,
        notesList: null,
        loadingNotes: false
      };

    case "LOADING_NOTES":
      return {
        ...state,
        notesList: null,
        errorLoadingNotes: false,
        loadingNotes: true
      };

    case "RESET_NOTES_LISTING":
      return {
        ...state,
        notesList: null,
        errorLoadingNotes: false,
        loadingNotes: true,
        errorMessage: ""
      };

    default:
      return state;
  }
};

export default reducer;
