import React, { Component } from "react";
import "./sales-news.css";
import AsideRight from "../../aside-right/component/aside-right";
import Card from "@material-ui/core/Card";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import LargeScreenLoader from "../../../common/Loaders/PulseLoader/pulse-loader";
import { Grid } from '@material-ui/core'
import NewsCard from "../../../components/NewsCard/NewsCard";
import _ from 'lodash';


const styles = () => ({
  root: {
    flexGrow: 1,
    padding: "20px"
  },
  no_records_card: {
    textAlign: 'center',
    fontSize: '16px',
    padding: '20px'
  }
});
class SalesNews extends Component {
  constructor(props) {
    super(props)

    this.state = {
      count: 1,
      countFeed: 1,
      loadingPage: false
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    if(this.props.selectedOrganizationList.length!==0)
    {const requestBody = this.props.salesNewsRequestBodySelectedOrg; // Sales news request body is taken as prop from salesNewsContainer
    requestBody.organizationArray = this.props.selectedOrganizationList;
    requestBody.paginate =  {
      page: "0",
      limit: "10"}
    this.props.initializeSalesNews(requestBody)}
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleClick = (id, salesNewsItem) => {
    this.props.history.push({
      pathname: `/sales-news/${id}`,
      state: {
        salesNews: salesNewsItem
      }
    });
  };

  handleScroll = () => {

    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );

    const windowBottom = windowHeight + window.pageYOffset
    if (windowBottom >= docHeight - 100) {
      this.loadMoreDataSalesNews();
    }

  };

  loadMoreDataSalesNews = () => {
    if (
      this.props.salesNews &&
      this.props.totalRecords > this.props.salesNews.length &&
      !this.state.loadingPage
    ) {
      const requestBody = this.props.salesNewsRequestBodySelectedOrg; // Sales news request body is taken as prop from salesNewsContainer
      requestBody.organizationArray = this.props.selectedOrganizationList;
      requestBody.paginate = {
        page: this.state.count.toString(),
        limit: "10"
      }
      this.setState({
        loadingPage: true
      }, () => {
        this.props.loadMoreSalesNews(requestBody, (err, result) => {
          if (result) {
            this.setState(preState => {
              return { count: preState.count + 1, loadingPage: false };
            });
          } else {
            this.setState({
              loadingPage: false
            })
          }
        });
      })
      
      
    }
  };
  componentDidUpdate = (prevProps, prevState) => {
    
     if(this.props.selectedOrganizationList !== prevProps.selectedOrganizationList && this.props.selectedOrganizationList.length !==0){
     this.setState({
      count:1
    })    
  }

    if (prevProps.callStatus !== this.props.callStatus) {
      this.setState({
        openNewEditor: false
      })
      // const params = {
      //   arrayFilters: [
      //     {
      //       "is_active": 1,
      //       "is_deleted": 0,
      //     }
      //   ],
      //   selectFilters: [],
      //   sort: {
      //     field: "updated_at",
      //     sortOrder: "DESC"
      //   },
      //   paginate: {
      //     page: "0",
      //     limit: "10"
      //   },
      // }

      const requestBody = this.props.salesNewsRequestBodySelectedOrg; // Sales news request body is taken as prop from salesNewsContainer
      requestBody.organizationArray = this.props.selectedOrganizationList;
      requestBody.paginate =  {
        page: "0",
        limit: "10"}

      this.props.initializeSalesNews(requestBody);  
    }
  }

  render() {
    const { totalRecords, salesNews, errorLoadingSalesNews, classes } = this.props;    
    const loader = (
      <LargeScreenLoader />
    );
    //     const fullContent = salesNews ? (


    // ) : ;

    

    const errorRenderer = (
      // <NoRecordFound variant="h4" message="No Sales news record to display" />
      <div className="col-md-12 no-records">
        No sales news found.
    </div>
    )
    return (
      <div>
          {(this.state.loadingPage || !salesNews)  && loader}
          <Grid container className="indraft-card-container">
            {(salesNews && totalRecords > 0 && salesNews.length > 0) && salesNews.map((gridItem, index) => (

              <Grid className="indraft-card-item" key={index} item md={6} lg={6} xs={12} sm={12}>
                <div onClick={() => this.handleClick(gridItem.id, gridItem)} className="all-news">
                  <NewsCard
                    currentUser={this.props.currentUser}
                    user={gridItem.user}
                    newsobj={gridItem}
                    id={gridItem.id}
                    userName={gridItem.user && gridItem.user.name}
                    userAvatar={gridItem.user && gridItem.user.user_avatar}
                    title={gridItem.title}
                    newsTime={gridItem.updated_at}
                    imagePath={gridItem.cover_image}
                    cardAction={false}
                    organizationName={gridItem.account && gridItem.account.name}
                  />
                </div>
              </Grid>

            ))}

            {salesNews && salesNews.length === 0 && errorRenderer}

          </Grid>
          {/* {errorLoadingSalesNews
        ? errorRenderer
        : salesNews
          ? fullContent
          : loader}
      {fullContent} */}
        </div>
    );
  }
}

{
  /* export default SalesNews; */
}
export default withStyles(styles)(SalesNews);
