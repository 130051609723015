import React from "react";
import { css } from "@emotion/core";
// First way to import
import { ScaleLoader } from "react-spinners";
// Another way to import. This is recommended to reduce bundle size
//import SyncLoader from "react-spinners/SyncLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default class SmallScaleLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  render() {
    return (
      <div className="sweet-loading">
        <ScaleLoader
          css={override}
          height={10}
          width={2}
          radius={3}
          //size={"150px"} this also works
          color={"green"}
          loading={this.props.loading}
        />
      </div>
    );
  }
}
