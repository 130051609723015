import React from 'react';

import TablePagination from '@material-ui/core/TablePagination'; 
import styles from "../pulseLiteContainer.module.scss";
import employeeMissingPNG from "../../../../assets/images/bi/employeeMissing.png";

import {
    Card,
    CardContent,
  } from "@material-ui/core";

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

// const rows = [
//     createData('Cupcake', 305, 3.7, 67, 4.3),
//     createData('Donut', 452, 25.0, 51, 4.9),
//     createData('Eclair', 262, 16.0, 24, 6.0),
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData('Gingerbread', 356, 16.0, 49, 3.9),
//     createData('Honeycomb', 408, 3.2, 87, 6.5),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData('Jelly Bean', 375, 0.0, 94, 0.0),
//     createData('KitKat', 518, 26.0, 65, 7.0),
//     createData('Lollipop', 392, 0.2, 98, 0.0),
//     createData('Marshmallow', 318, 0, 81, 2.0),
//     createData('Nougat', 360, 19.0, 9, 37.0),
//     createData('Oreo', 437, 18.0, 63, 4.0),
// ];

export default function EmployeeList(props) {
    //   const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(6);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = event => {
        setDense(event.target.checked);
    };

    return (
        <div className="container-body mt-5">
            {props.employeeData &&
            <div className={styles.display_name}>
                <b>Rewards Summary</b>
            </div>
            }
            <div className="row">
                {props.employeeData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => {
                        return (
                      
                            <div className={"col-md-4 mb-3 " + styles.empoyeeMargin}>
                                <div className={styles.employee_card}>
                                    <Card className={styles.card}>
                                        <CardContent>
                                            <div className="row">
                                                <div className="col-3">
                                                    <img
                                                        className={styles.empolyee_image}
                                                        src={
                                                            data.IMAGE
                                                                ? data.IMAGE
                                                                : employeeMissingPNG
                                                        }
                                                        // src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRcYhl53h5jYneDJZBHrAJkQin91O6DYR2Gj-Ijaxt6mY39V2NN"
                                                        alt="img"
                                                    />
                                                </div>
                                                <div className={ styles.details}>
                                                    <div>
                                                        {/* <span className={styles.label}>Employee Name:</span> */}
                                                        <span className={styles.titalValue}>
                                                            <b>{data.CREATED_BY}</b>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className={styles.label}>
                                                            Leads Generated-{" "}
                                                        </span>
                                                        <span className={styles.values}>
                                                            {data.LEADS_GENERATED}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className={styles.label}>
                                                            Leads Won-{" "}
                                                        </span>
                                                        <span className={styles.values}>
                                                            {data.LEADS_WON}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className={styles.label}>
                                                            Points-{" "}
                                                        </span>
                                                        <span className={styles.values}>
                                                            {" "}
                                                            {data.POINTS}{" "}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </div>
                            </div>
                        );
                    })}

                {props.employeeData.length > 0 && (
                    <div className="col-md-12">
                        <hr></hr>
                        <TablePagination
                            rowsPerPageOptions={[6, 12, 24]}
                            component="div"
                            count={props.employeeData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                        <hr></hr>
                    </div>
                )}
            </div>
        </div>
  );
}
