export const salesNewsRequestBody = {
    arrayFilters: [
      {
        is_active: 1,
        is_deleted: 0
      }
    ],
    selectFilters: ["id", "title", "cover_image", "news_body","is_has_expiry","expiry_date","created_at", "updated_at"],
    organizationArray: [],
    sort: {
      field: "updated_at",
      sortOrder: "DESC"
    },
    paginate: {
      page: "0",
      limit: "10"
    },
  };


  export const salesFeedRequestBody = 
    {
        arrayFilters: [
          {
            is_deleted: 0
          }
        ],
        selectFilters: ["id", "created_at", "action_type", "updated_at"], organizationArray: [],
        sort: {
          field: "created_at",
          sortOrder: "DESC"
        },
        paginate: {
          page: "0",
          limit: "10"
        }
       // ,user_id:""
        //user_id: this.props.currentUser.user_id
      };
  

const contactListRequestBody = {
    "arrayFilters": [
        {
            "is_deleted": 0
        }
    ],
    "selectFilters": [],
    "sort": {
        "field": "first_name",
        "sortOrder": "ASC"
    },
    "paginate": {
        "page": '',
        "limit": ''
    }
}

const getUsersParams = (orgId) => {
  return {
    account_id: orgId
  }
}

export {
  contactListRequestBody,
  getUsersParams
};