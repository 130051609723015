import React from "react";
import styles from "./RegisterComponent.module.scss";
import { Link } from "react-router-dom";
import requestAcceptedImg from "../../../../assets/images/register/request_accepted.svg";

function RequestAcceptedComponent(props) {
  const { message } = props;

  return (
    <React.Fragment>
      <h4 className={styles.registration_title}> 
        Thank you for registration! 
      </h4>
      <img className={styles.registration_image} src={requestAcceptedImg} alt="Request Accepted" />
      <div className={styles.registration_message}>
        {message}
      </div>

      <div className={styles.back_login}>
            <h5>
              Back to <Link to="/login">login</Link>
            </h5>
      </div>

    </React.Fragment>
  );
}

export default RequestAcceptedComponent;
