const initialState = {
  user: null,
  companyList: [],
  countryList: null,
  companyCount: 0,
  countryCount: 0,
  errorMessage: "",
  points: 0,
  organizationList: [],
  organizationListNews: [],
  //CRM Integration
  is_parent_org: null,
  helpListTitles: null
  //CRM Integration
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //CRM Integration
    case "ISPARENT_ORG_SUCCESS":
      return {
        ...state,
        is_parent_org: action.data,
        errorMessage: ""
      };

    case "ISPARENT_ORG_ERROR":
      return {
        ...state,
        is_parent_org: null,
        errorMessage: action.errorMessage
      };
    //CRM Integration

    case "USER_LOGOUT_SUCCESS":
      return {
        ...state,
        user: {},
        errorMessage: ""
      };

    case "USER_LOGOUT_FAILED":
      return {
        ...state,
        user: null,
        errorMessage: action.errorMessage
      };

    case "COMPANY_LIST_SUCCESS":
      return {
        ...state,
        companyList: action.data.rows,
        companyCount: action.data.count,
        errorMessage: ""
      };

    case "COMPANY_LIST_ERROR":
      return {
        ...state,
        companyList: [],
        companyCount: 0,
        errorMessage: action.errorMessage
      };

    case "COUNTRY_LIST_SUCCESS":
      return {
        ...state,
        countryList: action.data.rows,
        countryCount: action.data.count,
        errorMessage: ""
      };

    case "COUNTRY_LIST_ERROR":
      return {
        ...state,
        countryList: null,
        countryCount: 0,
        errorMessage: action.errorMessage
      };
      case "SALES_NEWS_ADD_SUCCESS" :
        return{
          
        };
      case "SALES_NEWS_ADD_ERROR" :
        return{
              
        };

      case "GET_USER_POINT":
        return {
          ...state,
          points: action.points
        };
      
      case "ORGANIZATION_LIST_SUCCESS": 
        return {
          ...state,
          organizationList: action.data
        };

      case "ORGANIZATION_LIST_ERROR":
        return {
          ...state,
          organizationList: []
        };

        case "ORGANIZATION_LIST_NEWS_SUCCESS": 
        return {
          ...state,
          organizationListNews: action.data
        };

      case "ORGANIZATION_LIST_NEWS_ERROR":
        return {
          ...state,
          organizationListNews: []
        }; 
      
      case "GET_HELPLINKS":
        return {
          ...state,
          helpListTitles: action.payload.results,
          base: action.payload._links.base
        };
        case "CLEAR_SEARCH_CONTENT":
          return {
            ...state,
            helpListTitles: []
          };
    default:
      return state;
  }
};

export default reducer;
