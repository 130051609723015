import React, { Component } from "react";
import { connect } from "react-redux";
import AssignSalesTeamCRMComponent from "./component/AssignSalesTeamCRMComponent";

class AssignSalesTeamCRMContainer extends Component {
    render() {
        const assignSalesTeamCRMComponentProps = {...this.props };
        return <AssignSalesTeamCRMComponent {...assignSalesTeamCRMComponentProps }
        />;
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        idcrm_integration: state.CRMConfigureDetails.idcrm_integration,
        idcrm_master: state.CRMIntegrationDetails.idcrm_master,
        crm_name: state.CRMIntegrationDetails.crm_name 
    };
};

export default connect(mapStateToProps)(AssignSalesTeamCRMContainer);