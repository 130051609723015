import * as types from "./types";

const initialState = {
      leadLeadMappingSelected: null,
      fieldMapping: []
};

const reducer = (state = initialState, action) => {
      switch (action.type) {
            case types.SETSTATUS_MAPPING:
                  //console.log("in case", action);
                  return {
                        ...state,
                        fieldMapping: action.payload.fieldMapping
                  }
            case types.ISLEADLEADMAPPING_SELECTED:
                  //console.log("in case", action);
                  return {
                        ...state,
                        leadLeadMappingSelected: action.payload.leadLeadMappingSelected
                  }
            default:
                  return state;
      }
};

export default reducer;
