// --------------------------------------Development Settings
export const METADATA_OIDC_SOD = {

  issuer:"https://sod.superoffice.com",
  authorization_endpoint:"https://sod.superoffice.com/login/common/oauth/authorize",
  token_endpoint:"https://sod.superoffice.com/login/common/oauth/tokens",
  end_session_endpoint:"https://sod.superoffice.com/login/logout",
  jwks_uri:"https://sod.superoffice.com/login/.well-known/jwks",
  scopes_supported:["openid"],
  response_modes_supported:["form_post","fragment","query"],
  response_types_supported:["code","id_token","code id_token","token id_token","token"],
  subject_types_supported:["public"],
  id_token_signing_alg_values_supported:["RS256"],
  token_endpoint_auth_methods_supported:["client_secret_post","client_secret_basic"],
  grant_types_supported:["implicit","authorization_code","refresh_token"],
  revocation_endpoint:"https://sod.superoffice.com/login/common/oauth/revoke"};

export const SETTING_OIDC_SOD = {
  authority: 'https://sod.superoffice.com/login',
  client_id: '3e05b83066e48e29f24716bfd78c0b65',
  redirect_uri: 'http://test.leadx.no/integrations/configureCRM',
  post_logout_redirect_uri: 'http://test.leadx.no/',
  response_type: 'id_token token ',
  scope: 'openid',
  filterProtocolClaims: true,
  loadUserInfo: false,
  metadata:
    {
      issuer: 'https://sod.superoffice.com',
      authorization_endpoint: 'https://sod.superoffice.com/login/common/oauth/authorize',
      token_endpoint: 'https://sod.superoffice.com/login/common/oauth/tokens',
      jwks_uri: 'https://sod.superoffice.com/login/.well-known/jwks',
    },
  signingKeys: [
    {
      // 'kty': 'RSA',
      // 'use': 'sig',
      // 'kid': 'Frf7jD-asGiFqADGTmTJfEq16Yw',
      // 'x5t': 'Frf7jD-asGiFqADGTmTJfEq16Yw',
      // // tslint:disable-next-line:max-line-length
      // 'x5c': ['MIIDrTCCApUCAQowDQYJKoZIhvcNAQEFBQAwgZQxCzAJBgNVBAYTAk5PMQ0wCwYDVQQIDARPc2xvMQ0wCwYDVQQHDARPc2xvMRcwFQYDVQQKDA5TdXBlck9mZmljZSBBUzEqMCgGA1UEAwwhU3VwZXJPZmZpY2UgT25saW5lIERldmVsb3BtZW50IENBMSIwIAYJKoZIhvcNAQkBFhNzZGtAc3VwZXJvZmZpY2UuY29tMCAXDTEzMDkwMzExNDIyN1oYDzIxMTMwODEwMTE0MjI3WjCBoTELMAkGA1UEBhMCTk8xDTALBgNVBAgMBE9zbG8xDTALBgNVBAcMBE9zbG8xFzAVBgNVBAoMDlN1cGVyT2ZmaWNlIEFTMTcwNQYDVQQDDC5TdXBlck9mZmljZSBPbmxpbmUgRGV2ZWxvcG1lbnQgRmVkZXJhdGVkIExvZ2luMSIwIAYJKoZIhvcNAQkBFhNzZGtAc3VwZXJvZmZpY2UuY29tMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsY6PrEDYl7VuiTaFDCnTKYwcqq2y5J/vttZWf8QCV9oOXsI77lhCqO71SavHaoRQe3gkXsWIbgpv3hwmYyDiOiqf6XdJxVarmrvvjmXDQDl9mgUZ4visDhxNPFyWjsMiLQpVUlpO73ASy7+F/cuBCTQ4g9YDX7YBh94WL+EvA9ciB6ZtRsRS945ezrDMq8Q38RjpemXekc7ObwPXOX7PzFkGcdMWLHNm6Ktj2OFIqkuJYBRmOr85IlKcuek4x0LeVDj24Min/HG+4MqVpvDzF4m2Ib5AgFI3Akl9p7Z+dCSq6KTphu8hO/LmW+E93kz6weG7dZxt7otFps0j09py+QIDAQABMA0GCSqGSIb3DQEBBQUAA4IBAQCrx88qTpQlM27TPd3i/qHt182+Gy40klx2l9ho1PW8EZqKJTZQD4+guHGLrMzYLTl0rDWcqbjVbYjZKw6xoSGAFOlKjoBCZqkiVhFyiOeXYpg4F3rNCau944nNkLr+XJ+84B8ieruGDDcf0gCN33WXmIvG69I4PmLJB9RbdVaTvmPdwonPiIZ0+i60L9w8IW8pL4EM7upIiRkpBrHMhHJr/DhmDV+P+nO8GuyYKXmgbkeTtVvskwRbO6j/USEjTFdCcAj0WHpKYAKxvgqpdfZsdfo2JAnnuv83BbuAkYxZWPip7kEMcNEPGpLp7CBr58g5JlvWr1pSsm0NNXEER3E2']
      "kty":"RSA",
      "use":"sig",
      "kid":"16B7FB8C3F9AB06885A800C64E64C97C4AB5E98C",
      "x5t":"Frf7jD-asGiFqADGTmTJfEq16Yw",
      "n"  :"sY6PrEDYl7VuiTaFDCnTKYwcqq2y5J_vttZWf8QCV9oOXsI77lhCqO71SavHaoRQe3gkXsWIbgpv3hwmYyDiOiqf6XdJxVarmrvvjmXDQDl9mgUZ4visDhxNPFyWjsMiLQpVUlpO73ASy7-F_cuBCTQ4g9YDX7YBh94WL-EvA9ciB6ZtRsRS945ezrDMq8Q38RjpemXekc7ObwPXOX7PzFkGcdMWLHNm6Ktj2OFIqkuJYBRmOr85IlKcuek4x0LeVDj24Min_HG-4MqVpvDzF4m2Ib5AgFI3Akl9p7Z-dCSq6KTphu8hO_LmW-E93kz6weG7dZxt7otFps0j09py-Q",
      "e" :"AQAB",
      "x5c":["MIIDrTCCApUCAQowDQYJKoZIhvcNAQEFBQAwgZQxCzAJBgNVBAYTAk5PMQ0wCwYDVQQIDARPc2xvMQ0wCwYDVQQHDARPc2xvMRcwFQYDVQQKDA5TdXBlck9mZmljZSBBUzEqMCgGA1UEAwwhU3VwZXJPZmZpY2UgT25saW5lIERldmVsb3BtZW50IENBMSIwIAYJKoZIhvcNAQkBFhNzZGtAc3VwZXJvZmZpY2UuY29tMCAXDTEzMDkwMzExNDIyN1oYDzIxMTMwODEwMTE0MjI3WjCBoTELMAkGA1UEBhMCTk8xDTALBgNVBAgMBE9zbG8xDTALBgNVBAcMBE9zbG8xFzAVBgNVBAoMDlN1cGVyT2ZmaWNlIEFTMTcwNQYDVQQDDC5TdXBlck9mZmljZSBPbmxpbmUgRGV2ZWxvcG1lbnQgRmVkZXJhdGVkIExvZ2luMSIwIAYJKoZIhvcNAQkBFhNzZGtAc3VwZXJvZmZpY2UuY29tMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsY6PrEDYl7VuiTaFDCnTKYwcqq2y5J/vttZWf8QCV9oOXsI77lhCqO71SavHaoRQe3gkXsWIbgpv3hwmYyDiOiqf6XdJxVarmrvvjmXDQDl9mgUZ4visDhxNPFyWjsMiLQpVUlpO73ASy7+F/cuBCTQ4g9YDX7YBh94WL+EvA9ciB6ZtRsRS945ezrDMq8Q38RjpemXekc7ObwPXOX7PzFkGcdMWLHNm6Ktj2OFIqkuJYBRmOr85IlKcuek4x0LeVDj24Min/HG+4MqVpvDzF4m2Ib5AgFI3Akl9p7Z+dCSq6KTphu8hO/LmW+E93kz6weG7dZxt7otFps0j09py+QIDAQABMA0GCSqGSIb3DQEBBQUAA4IBAQCrx88qTpQlM27TPd3i/qHt182+Gy40klx2l9ho1PW8EZqKJTZQD4+guHGLrMzYLTl0rDWcqbjVbYjZKw6xoSGAFOlKjoBCZqkiVhFyiOeXYpg4F3rNCau944nNkLr+XJ+84B8ieruGDDcf0gCN33WXmIvG69I4PmLJB9RbdVaTvmPdwonPiIZ0+i60L9w8IW8pL4EM7upIiRkpBrHMhHJr/DhmDV+P+nO8GuyYKXmgbkeTtVvskwRbO6j/USEjTFdCcAj0WHpKYAKxvgqpdfZsdfo2JAnnuv83BbuAkYxZWPip7kEMcNEPGpLp7CBr58g5JlvWr1pSsm0NNXEER3E2"]
    }
  ]
};
// --------------------------------------End Development Settings

// --------------------------------------Stage Settings
export const METADATA_OIDC_STAGE = {

  issuer:"https://qaonline.superoffice.com",
  authorization_endpoint:"https://qaonline.superoffice.com/login/common/oauth/authorize",
  token_endpoint:"https://qaonline.superoffice.com/login/common/oauth/tokens",
  end_session_endpoint:"https://qaonline.superoffice.com/login/logout",
  jwks_uri:"https://qaonline.superoffice.com/login/.well-known/jwks",
  scopes_supported:["openid"],
  response_modes_supported:["form_post","fragment","query"],
  response_types_supported:["code","id_token","code id_token","token id_token","token"],
  subject_types_supported:["public"],
  id_token_signing_alg_values_supported:["RS256"],
  token_endpoint_auth_methods_supported:["client_secret_post","client_secret_basic"],
  grant_types_supported:["implicit","authorization_code","refresh_token"],
  revocation_endpoint:"https://qaonline.superoffice.com/login/common/oauth/revoke"};

export const SETTING_OIDC_STAGE = {
  authority: 'https://qaonline.superoffice.com/login',
  client_id: '6df7832f9042bfcb64c226c69188eff8',
  redirect_uri: 'https://soauth-stage.leadx360.com/validate',
  post_logout_redirect_uri: 'https://beta.leadx360.com/',
  response_type: 'id_token token ',
  scope: 'openid',
  filterProtocolClaims: true,
  loadUserInfo: false,
  metadata:
    {
      issuer: 'https://qaonline.superoffice.com',
      authorization_endpoint: 'https://qaonline.superoffice.com/login/common/oauth/authorize',
      token_endpoint: 'https://qaonline.superoffice.com/login/common/oauth/tokens',
      jwks_uri: 'https://qaonline.superoffice.com/login/.well-known/jwks',
    },
  signingKeys: [
    {
      "kty":"RSA",
      "use":"sig",
      "kid":"16B7FB8C3F9AB06885A800C64E64C97C4AB5E98C",
      "x5t":"Frf7jD-asGiFqADGTmTJfEq16Yw",
      "n"  :"sY6PrEDYl7VuiTaFDCnTKYwcqq2y5J_vttZWf8QCV9oOXsI77lhCqO71SavHaoRQe3gkXsWIbgpv3hwmYyDiOiqf6XdJxVarmrvvjmXDQDl9mgUZ4visDhxNPFyWjsMiLQpVUlpO73ASy7-F_cuBCTQ4g9YDX7YBh94WL-EvA9ciB6ZtRsRS945ezrDMq8Q38RjpemXekc7ObwPXOX7PzFkGcdMWLHNm6Ktj2OFIqkuJYBRmOr85IlKcuek4x0LeVDj24Min_HG-4MqVpvDzF4m2Ib5AgFI3Akl9p7Z-dCSq6KTphu8hO_LmW-E93kz6weG7dZxt7otFps0j09py-Q",
      "e" :"AQAB",
      "x5c":["MIIDrTCCApUCAQowDQYJKoZIhvcNAQEFBQAwgZQxCzAJBgNVBAYTAk5PMQ0wCwYDVQQIDARPc2xvMQ0wCwYDVQQHDARPc2xvMRcwFQYDVQQKDA5TdXBlck9mZmljZSBBUzEqMCgGA1UEAwwhU3VwZXJPZmZpY2UgT25saW5lIERldmVsb3BtZW50IENBMSIwIAYJKoZIhvcNAQkBFhNzZGtAc3VwZXJvZmZpY2UuY29tMCAXDTEzMDkwMzExNDIyN1oYDzIxMTMwODEwMTE0MjI3WjCBoTELMAkGA1UEBhMCTk8xDTALBgNVBAgMBE9zbG8xDTALBgNVBAcMBE9zbG8xFzAVBgNVBAoMDlN1cGVyT2ZmaWNlIEFTMTcwNQYDVQQDDC5TdXBlck9mZmljZSBPbmxpbmUgRGV2ZWxvcG1lbnQgRmVkZXJhdGVkIExvZ2luMSIwIAYJKoZIhvcNAQkBFhNzZGtAc3VwZXJvZmZpY2UuY29tMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsY6PrEDYl7VuiTaFDCnTKYwcqq2y5J/vttZWf8QCV9oOXsI77lhCqO71SavHaoRQe3gkXsWIbgpv3hwmYyDiOiqf6XdJxVarmrvvjmXDQDl9mgUZ4visDhxNPFyWjsMiLQpVUlpO73ASy7+F/cuBCTQ4g9YDX7YBh94WL+EvA9ciB6ZtRsRS945ezrDMq8Q38RjpemXekc7ObwPXOX7PzFkGcdMWLHNm6Ktj2OFIqkuJYBRmOr85IlKcuek4x0LeVDj24Min/HG+4MqVpvDzF4m2Ib5AgFI3Akl9p7Z+dCSq6KTphu8hO/LmW+E93kz6weG7dZxt7otFps0j09py+QIDAQABMA0GCSqGSIb3DQEBBQUAA4IBAQCrx88qTpQlM27TPd3i/qHt182+Gy40klx2l9ho1PW8EZqKJTZQD4+guHGLrMzYLTl0rDWcqbjVbYjZKw6xoSGAFOlKjoBCZqkiVhFyiOeXYpg4F3rNCau944nNkLr+XJ+84B8ieruGDDcf0gCN33WXmIvG69I4PmLJB9RbdVaTvmPdwonPiIZ0+i60L9w8IW8pL4EM7upIiRkpBrHMhHJr/DhmDV+P+nO8GuyYKXmgbkeTtVvskwRbO6j/USEjTFdCcAj0WHpKYAKxvgqpdfZsdfo2JAnnuv83BbuAkYxZWPip7kEMcNEPGpLp7CBr58g5JlvWr1pSsm0NNXEER3E2"]
    }
  ]
};
// --------------------------------------End Stage Settings


// --------------------------------------Production Settings
export const METADATA_OIDC = {

  issuer:"https://online.superoffice.com",
  authorization_endpoint:"https://online.superoffice.com/login/common/oauth/authorize",
  token_endpoint:"https://online.superoffice.com/login/common/oauth/tokens",
  end_session_endpoint:"https://online.superoffice.com/login/logout",
  jwks_uri:"https://online.superoffice.com/login/.well-known/jwks",
  scopes_supported:["openid"],
  response_modes_supported:["form_post","fragment","query"],
  response_types_supported:["code","id_token","code id_token","token id_token","token"],
  subject_types_supported:["public"],
  id_token_signing_alg_values_supported:["RS256"],
  token_endpoint_auth_methods_supported:["client_secret_post","client_secret_basic"],
  grant_types_supported:["implicit","authorization_code","refresh_token"],
  revocation_endpoint:"https://online.superoffice.com/login/common/oauth/revoke"};

export const SETTING_OIDC = {
  authority: 'https://online.superoffice.com/login',
  client_id: '9dcf566c58f57ab6654d9bbbb2589713',
  redirect_uri: 'https://soauth.leadx360.com/validate',
  post_logout_redirect_uri: 'https://soauth.leadx360.com/validate',
  response_type: 'id_token token ',
  scope: 'openid',
  filterProtocolClaims: true,
  loadUserInfo: false,
  metadata:
    {
      issuer: 'https://online.superoffice.com',
      authorization_endpoint: 'https://online.superoffice.com/login/common/oauth/authorize',
      token_endpoint: 'https://online.superoffice.com/login/common/oauth/tokens',
      jwks_uri: 'https://online.superoffice.com/login/.well-known/jwks',
    },
  signingKeys: [
    {
      // 'kty': 'RSA',
      // 'use': 'sig',
      // 'kid': 'Frf7jD-asGiFqADGTmTJfEq16Yw',
      // 'x5t': 'Frf7jD-asGiFqADGTmTJfEq16Yw',
      // // tslint:disable-next-line:max-line-length
      // 'x5c': ['MIIDrTCCApUCAQowDQYJKoZIhvcNAQEFBQAwgZQxCzAJBgNVBAYTAk5PMQ0wCwYDVQQIDARPc2xvMQ0wCwYDVQQHDARPc2xvMRcwFQYDVQQKDA5TdXBlck9mZmljZSBBUzEqMCgGA1UEAwwhU3VwZXJPZmZpY2UgT25saW5lIERldmVsb3BtZW50IENBMSIwIAYJKoZIhvcNAQkBFhNzZGtAc3VwZXJvZmZpY2UuY29tMCAXDTEzMDkwMzExNDIyN1oYDzIxMTMwODEwMTE0MjI3WjCBoTELMAkGA1UEBhMCTk8xDTALBgNVBAgMBE9zbG8xDTALBgNVBAcMBE9zbG8xFzAVBgNVBAoMDlN1cGVyT2ZmaWNlIEFTMTcwNQYDVQQDDC5TdXBlck9mZmljZSBPbmxpbmUgRGV2ZWxvcG1lbnQgRmVkZXJhdGVkIExvZ2luMSIwIAYJKoZIhvcNAQkBFhNzZGtAc3VwZXJvZmZpY2UuY29tMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsY6PrEDYl7VuiTaFDCnTKYwcqq2y5J/vttZWf8QCV9oOXsI77lhCqO71SavHaoRQe3gkXsWIbgpv3hwmYyDiOiqf6XdJxVarmrvvjmXDQDl9mgUZ4visDhxNPFyWjsMiLQpVUlpO73ASy7+F/cuBCTQ4g9YDX7YBh94WL+EvA9ciB6ZtRsRS945ezrDMq8Q38RjpemXekc7ObwPXOX7PzFkGcdMWLHNm6Ktj2OFIqkuJYBRmOr85IlKcuek4x0LeVDj24Min/HG+4MqVpvDzF4m2Ib5AgFI3Akl9p7Z+dCSq6KTphu8hO/LmW+E93kz6weG7dZxt7otFps0j09py+QIDAQABMA0GCSqGSIb3DQEBBQUAA4IBAQCrx88qTpQlM27TPd3i/qHt182+Gy40klx2l9ho1PW8EZqKJTZQD4+guHGLrMzYLTl0rDWcqbjVbYjZKw6xoSGAFOlKjoBCZqkiVhFyiOeXYpg4F3rNCau944nNkLr+XJ+84B8ieruGDDcf0gCN33WXmIvG69I4PmLJB9RbdVaTvmPdwonPiIZ0+i60L9w8IW8pL4EM7upIiRkpBrHMhHJr/DhmDV+P+nO8GuyYKXmgbkeTtVvskwRbO6j/USEjTFdCcAj0WHpKYAKxvgqpdfZsdfo2JAnnuv83BbuAkYxZWPip7kEMcNEPGpLp7CBr58g5JlvWr1pSsm0NNXEER3E2']
      "kty":"RSA",
      "use":"sig",
      "kid":"16B7FB8C3F9AB06885A800C64E64C97C4AB5E98C",
      "x5t":"Frf7jD-asGiFqADGTmTJfEq16Yw",
      "n"  :"sY6PrEDYl7VuiTaFDCnTKYwcqq2y5J_vttZWf8QCV9oOXsI77lhCqO71SavHaoRQe3gkXsWIbgpv3hwmYyDiOiqf6XdJxVarmrvvjmXDQDl9mgUZ4visDhxNPFyWjsMiLQpVUlpO73ASy7-F_cuBCTQ4g9YDX7YBh94WL-EvA9ciB6ZtRsRS945ezrDMq8Q38RjpemXekc7ObwPXOX7PzFkGcdMWLHNm6Ktj2OFIqkuJYBRmOr85IlKcuek4x0LeVDj24Min_HG-4MqVpvDzF4m2Ib5AgFI3Akl9p7Z-dCSq6KTphu8hO_LmW-E93kz6weG7dZxt7otFps0j09py-Q",
      "e" :"AQAB",
      "x5c":["MIIDrTCCApUCAQowDQYJKoZIhvcNAQEFBQAwgZQxCzAJBgNVBAYTAk5PMQ0wCwYDVQQIDARPc2xvMQ0wCwYDVQQHDARPc2xvMRcwFQYDVQQKDA5TdXBlck9mZmljZSBBUzEqMCgGA1UEAwwhU3VwZXJPZmZpY2UgT25saW5lIERldmVsb3BtZW50IENBMSIwIAYJKoZIhvcNAQkBFhNzZGtAc3VwZXJvZmZpY2UuY29tMCAXDTEzMDkwMzExNDIyN1oYDzIxMTMwODEwMTE0MjI3WjCBoTELMAkGA1UEBhMCTk8xDTALBgNVBAgMBE9zbG8xDTALBgNVBAcMBE9zbG8xFzAVBgNVBAoMDlN1cGVyT2ZmaWNlIEFTMTcwNQYDVQQDDC5TdXBlck9mZmljZSBPbmxpbmUgRGV2ZWxvcG1lbnQgRmVkZXJhdGVkIExvZ2luMSIwIAYJKoZIhvcNAQkBFhNzZGtAc3VwZXJvZmZpY2UuY29tMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsY6PrEDYl7VuiTaFDCnTKYwcqq2y5J/vttZWf8QCV9oOXsI77lhCqO71SavHaoRQe3gkXsWIbgpv3hwmYyDiOiqf6XdJxVarmrvvjmXDQDl9mgUZ4visDhxNPFyWjsMiLQpVUlpO73ASy7+F/cuBCTQ4g9YDX7YBh94WL+EvA9ciB6ZtRsRS945ezrDMq8Q38RjpemXekc7ObwPXOX7PzFkGcdMWLHNm6Ktj2OFIqkuJYBRmOr85IlKcuek4x0LeVDj24Min/HG+4MqVpvDzF4m2Ib5AgFI3Akl9p7Z+dCSq6KTphu8hO/LmW+E93kz6weG7dZxt7otFps0j09py+QIDAQABMA0GCSqGSIb3DQEBBQUAA4IBAQCrx88qTpQlM27TPd3i/qHt182+Gy40klx2l9ho1PW8EZqKJTZQD4+guHGLrMzYLTl0rDWcqbjVbYjZKw6xoSGAFOlKjoBCZqkiVhFyiOeXYpg4F3rNCau944nNkLr+XJ+84B8ieruGDDcf0gCN33WXmIvG69I4PmLJB9RbdVaTvmPdwonPiIZ0+i60L9w8IW8pL4EM7upIiRkpBrHMhHJr/DhmDV+P+nO8GuyYKXmgbkeTtVvskwRbO6j/USEjTFdCcAj0WHpKYAKxvgqpdfZsdfo2JAnnuv83BbuAkYxZWPip7kEMcNEPGpLp7CBr58g5JlvWr1pSsm0NNXEER3E2"]
    }
  ],
};

export const SIGNING_KEYS = 
  { 
    "kty":"RSA",
    "use":"sig",
    "kid":"60B61583DBB2B7EEC46FD01EC0056E62451D61F3",
    "x5t":"YLYVg9uyt-7Eb9AewAVuYkUdYfM",
    "n":"-RC-K9wigt0IrC2UceGHtokG4VeNNvwXHDt6ouIhlG9X5cvKQZtoeS6eVXujILFUDTt0FjjLdUU7ZhggtjyqGMle-h44dP2zaXgzfblvSAkbgbc9LPhjvkOCJV51Mjzn-Ih7AIw_4Vh72OI5AJyR1uHuMxosKLZlMz3pfCudjBl7BJwuZDzYE0FtLUNwhhBdLqBI38aoi4xA_2bk7NN8SOmXwvieA0irPdItxnY9ZwUat2DTb7rymGMRQZ4SWZ_deTIZwYiNiSB4xlLKhks80OwB80Ke4eDc56orVjmOXNPc2DfJ3YNmmI2beXXQwbn8sR_DuTovySm2tzD7DEyJpw",
    "e":"AQAB",
    "x5c":["MIIDqzCCApMCAQgwDQYJKoZIhvcNAQEFBQAwgZMxCzAJBgNVBAYTAk5PMQ0wCwYDVQQIDARPc2xvMQ0wCwYDVQQHDARPc2xvMRcwFQYDVQQKDA5TdXBlck9mZmljZSBBUzEpMCcGA1UEAwwgU3VwZXJPZmZpY2UgT25saW5lIFByb2R1Y3Rpb24gQ0ExIjAgBgkqhkiG9w0BCQEWE3Nka0BzdXBlcm9mZmljZS5jb20wIBcNMTQwNDA0MDczNDM3WhgPMjExNDAzMTEwNzM0MzdaMIGgMQswCQYDVQQGEwJOTzENMAsGA1UECAwET3NsbzENMAsGA1UEBwwET3NsbzEXMBUGA1UECgwOU3VwZXJPZmZpY2UgQVMxNjA0BgNVBAMMLVN1cGVyT2ZmaWNlIE9ubGluZSBQcm9kdWN0aW9uIEZlZGVyYXRlZCBMb2dpbjEiMCAGCSqGSIb3DQEJARYTc2RrQHN1cGVyb2ZmaWNlLmNvbTCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEBAPkQvivcIoLdCKwtlHHhh7aJBuFXjTb8Fxw7eqLiIZRvV+XLykGbaHkunlV7oyCxVA07dBY4y3VFO2YYILY8qhjJXvoeOHT9s2l4M325b0gJG4G3PSz4Y75DgiVedTI85/iIewCMP+FYe9jiOQCckdbh7jMaLCi2ZTM96XwrnYwZewScLmQ82BNBbS1DcIYQXS6gSN/GqIuMQP9m5OzTfEjpl8L4ngNIqz3SLcZ2PWcFGrdg02+68phjEUGeElmf3XkyGcGIjYkgeMZSyoZLPNDsAfNCnuHg3OeqK1Y5jlzT3Ng3yd2DZpiNm3l10MG5/LEfw7k6L8kptrcw+wxMiacCAwEAATANBgkqhkiG9w0BAQUFAAOCAQEAofT3yTZytAdRGNOpqNQilQDW34lnEsPzwb2H4UdatNnCzoSdl4piiNMXyilJS4gwU4KMfuBDO07i+5Mpt+Mh1SSr7vrDxeU6Sq+vAjuzOE9U247PIwLZuxuKD5FLCDomrNyL4+SSzXOyPDkNBezDZmChRTdlylDDRnjxX3SM+tsx8Lqw4rwyXCOLMuubWCqYinoFelI3Rj+XDpKxcQ+nwIAhMyoZXL90fEoxdYRoKIy8Evvv17LMOFW/CexfThepu3+FbtA3+xPeSidnlXv/9WC3wG1M4nlIiIazbqoXM8gLmIUwK8BZ+tIFqCj2oJAJhr68WA4LQ+Kr8k3fnmVGnQ=="]
  }

