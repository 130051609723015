import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "./redux/actions";
import * as actionsTasks from "../Tasks/redux/actions";
import AuthenticateCRMComponent from "./component/AuthenticateCRMComponent";

class AuthenticateCRMContainer extends Component {
    render() {
        const authenticateCRMComponentProps = {...this.props };
        return <AuthenticateCRMComponent {...authenticateCRMComponentProps }
        />;
    }
}

const mapStateToProps = state => {
    return {
        loadingSalesActivitiesTasks: state.activities.loadingSalesActivitiesTasks,
        salesActivitesTasks: state.activities.salesActivitesTasks,
        errorLoadingSalesActivitesTasks: state.activities.errorLoadingSalesActivitesTasks,
        loadingTasks: state.tasks.loadingTasks
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getSalesActivititesTasks: requestBody =>
            dispatch(actions.getSalesActivititesTasks(requestBody)),
        getSalesTasks: requestBody =>
            dispatch(actionsTasks.getSalesTasks(requestBody))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthenticateCRMContainer);