import React, { useState, useRef } from "react";
import "./index.css";

const SearchInput = (props) => {
  const [searchText, setSearchText] = useState(props.initialValue);
  const { onSearch, ...rest } = props;
  const searchRef = useRef(null);

  const handleOnChange = (e) => {
    setSearchText(e.target.value);
    props.onSearch(e.target.value);
  };

  return (
    <>
      <div style={{ display: "flex", width: "100%" }}>
        <input
          value={searchText}
          {...rest}
          ref={searchRef}
          className="form-control searchInput"
          onKeyUp={handleOnChange}
          onChange={handleOnChange}
          style={{ fontStyle: "italic", ...rest.style }}
        />
        {!searchText && (
          <span style={{ display: "flex" }}>
            <img
              style={{ width: 20, marginLeft: "-25px" }}
              src={require("../../../assets/images/administration/Rounded.svg")}
            />
          </span>
        )}
      </div>
    </>
  );
};

export default SearchInput;
