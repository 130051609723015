import React from 'react';
import pdfIcon from "../../../../assets/images/bi/pdf.png";
import style from "../pulseLiteContainer.module.scss";
import moment from "moment";
// import Excel from "exceljs";
// import FileSaver from "file-saver";

// var pdfMake = require('pdfmake/build/pdfmake');
// var pdfFonts = require('pdfmake/build/vfs_fonts');


import pdfMake from "pdfmake/build/pdfmake";
import vfsFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = vfsFonts.pdfMake ? vfsFonts.pdfMake.vfs : pdfMake.vfs;


function PDFExport(props) {
    function dateRange() {
        const firstDay = new Date(props.selectedMonth.from_date);
        const lastDay = new Date(props.selectedMonth.to_date);
        return moment(firstDay).format("DD MMM YYYY") + ' To ' + moment(lastDay).format("DD MMM YYYY")
    }

    const generatePDF = Data => {
        const options = {
            fileName: "BonusReport.pdf",
            data: Data
        };
        exportPDF(options);
    }

    const exportPDF = options => {
        // PDF Configurations
        const FONTSIZE = 6.5; 

        const HeaderTop = [
  
            {rowSpan: 2, text: 'Username/email', style: 'tableHeader'},
            {rowSpan: 2,text: 'First Name', style: 'tableHeader'},
            
            {rowSpan: 2,text: 'Last Name', style: 'tableHeader'},
            {rowSpan: 2,text: 'Total Leads', style: 'tableHeader'},
            {rowSpan: 2,text: 'Bonus Point', style: 'tableHeader'},

            {text: 'Status Count', style: 'tableHeader', colSpan: 7, alignment: 'center'},
            {},
            {}, 
            {},
            {}, 
            {},
            {}, 
        ];


        const Header = [ 

            {rowSpan: 2, text: 'Username/email', style: 'tableHeader'},
            {rowSpan: 2,text: 'First Name', style: 'tableHeader'},
            {rowSpan: 2,text: 'Last Name', style: 'tableHeader'},

            {rowSpan: 2,text: 'Total Leads', style: 'tableHeader'},
            {rowSpan: 2,text: 'Bonus Point', style: 'tableHeader'},
            

             
            {text: 'New', style: 'tableHeader'},
            
            {text: 'Lead', style: 'tableHeader'},
            {text: 'Opportunity', style: 'tableHeader'},
            {text: 'Proposal', style: 'tableHeader'},

            {text: 'Negotiation', style: 'tableHeader'},
            {text: 'Won', style: 'tableHeader'},
            {text: 'Lost', style: 'tableHeader'}
        ];

        var bodyData = [];
        bodyData.push(HeaderTop);
        bodyData.push(Header);
        options.data.forEach(function(sourceRow) {
            var dataRow = [];
            dataRow.push({text: sourceRow.email, style: 'tableRow'});
            dataRow.push({text: sourceRow.first_name, style: 'tableRow'});
            dataRow.push({text: sourceRow.last_name, style: 'tableRow'});

            dataRow.push({text: sourceRow.LEADS_GENERATED, style: 'tableRow', alignment: 'center'});
            dataRow.push({text: sourceRow.POINTS, style: 'tableRow',  alignment: 'center'});
            dataRow.push({text: sourceRow.NEW, style: 'tableRow',  alignment: 'center'});

            dataRow.push({text: sourceRow.LEAD, style: 'tableRow',  alignment: 'center'});
            dataRow.push({text: sourceRow.OPPORTUNITY, style: 'tableRow',  alignment: 'center'});
            dataRow.push({text: sourceRow.PROPOSAL, style: 'tableRow',  alignment: 'center'});

            dataRow.push({text: sourceRow.NEGOTIATION, style: 'tableRow',  alignment: 'center'});
            dataRow.push({text: sourceRow.LEADS_WON, style: 'tableRow',  alignment: 'center'});
            dataRow.push({text: sourceRow.LEADS_LOST, style: 'tableRow',  alignment: 'center'});

            bodyData.push(dataRow)
          });

          var dd = {
            content: [
                {text: "The Bonus Report for the period of " + dateRange() , style: 'header'},
                {
                     table: {
                        widths: [90, 50, 40, 'auto','auto','auto', 'auto','auto','auto', 'auto','auto','auto'],
                        body: bodyData
                    },
                    layout: {
                        fillColor: function (rowIndex, node, columnIndex) {

                            return (rowIndex === 0 || rowIndex === 1) ? '#CCCCCC' : null;
                        },
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 1 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 1 : 1;
                        },
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'gray' : 'gray';
                        },
                        vLineColor: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'gray' : 'gray';
                        },
                    }
                }
            ],
            styles: {
                header: {
                    fontSize: 14,
                    bold: true,
                    margin: [0, 0, 0, 10]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 7.5,
                    color: 'black',
                    
                },
                tableRow: {
                    fontSize: 6.5,
                    color: 'black'
                }
            },
            defaultStyle: {
                // alignment: 'justify'
            }
        }
        pdfMake.createPdf(dd).download(options.fileName);
    }

    return (
        <React.Fragment>
            <div className={style.pdfDiv}>
                <img
                    onClick={() => generatePDF(props.pdfData)}
                    className={style.icon_excel}
                    src={pdfIcon}
                    alt="ExcelIcon"
                />
                <p className="ml-2">PDF</p>
            </div>
        </React.Fragment>
    )
}

export default PDFExport
