import axios from "../../common/api-config";

const getLeadStatusLogs = async (lead_id) => {
  const url = `/leads/${lead_id}/status_logs`;
  const result = await axios.get(url, {});
  return result.data.data;
};

const getLeadDocuments = async (lead_id) => {
  const url = `/leads/${lead_id}/documents`;
  const result = await axios.get(url, {});
  return result.data.data;
};

export default {
  async getLeadStatusLog(lead_id) {
    const [docResult, statusLogRestult] = await Promise.all([
      getLeadDocuments(lead_id),
      getLeadStatusLogs(lead_id),
    ]);

    return {
      count: statusLogRestult.count,
      rows: {
        lead_status_logs: statusLogRestult.rows,
        LeadDocuments: docResult.rows,
      },
    };
  },
};
