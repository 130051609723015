import React, { Component } from "react";
import * as actions from "./redux/actions";
import RegisterComponent from "./component/RegisterComponent";
import styles from "./RegisterContainer.module.scss";
import { connect } from "react-redux";

class RegisterContainer extends Component {
  render() {
    const registerComponentProps = this.props;
    return (
      <div className={styles.container}>
        <RegisterComponent {...registerComponentProps} user={this.props.user} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.user,
    errorMessage: state.login.errorMessage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    userLogin: requestBody => dispatch(actions.userRegister(requestBody))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterContainer);
