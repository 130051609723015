import * as types from "./types";
import axios from "../../../common/api-config";
import { setStorageItem } from "../../../common/common-functions";



export const userRegister = requestBody => {
  return dispatch => {
    axios
      .post("/register", requestBody)
      .then(response => {
        // Will do
      })
      .catch(error => {
        dispatch(userRegisterFailed(error.errorMessage));
      });
  };
};

const userRegisterFailed = message => {
  return {
    type: types.USER_REGISTER_FAILED,
    errorMessage: message
  };
};

const userRegisterSuccess = (data) => {
  return {
    type: types.USER_REGISTER_SUCCESS,
    data
  };
};


export default {
  userRegister
};
