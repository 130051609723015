import React, { useState } from 'react';
import moment from 'moment';
import FormControl from '@material-ui/core/FormControl';
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import calenderIcon from '../../../assets/images/data_exports/calender.png';
import calenderDownArrowIcon from '../../../assets/images/data_exports/calender_down.svg';
import styles from './style.scss';
import { LinkedCalendar } from './src';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

function MyDateRangePicker({ value, onChange, maxWidth }) {
    const [open, setOpen] = useState(false);

    const onDatesChange = ({ startDate, endDate }) => {
        onChange([moment(startDate.toDate()), moment(endDate.toDate())]);
        setOpen(false);
    }

    const style = {  height: '2.7em', paddingTop: 4, width: '100%', marginTop: 0, textAlign: 'center', backgroundColor: 'white', border: '0.75px solid #D3D3D3', borderRadius: 4 };

    if (maxWidth) {
        style.maxWidth = maxWidth;
    }

    return (
        <>
            {open && <LinkedCalendar dateRange={{from_date: moment(value[0]), to_date: moment(value[1])}} onCancel={() => setOpen(false)} onDatesChange={onDatesChange} opens='left' showDropdowns={false} />}
            <FormControl onClick={() => setOpen(!open)} style={style} variant="outlined" fullWidth>
                <div className="customDateRange_divider">
                    <img src={calenderIcon} width="15px" style={{ marginRight: '5px', marginLeft: '-8px' }} />
                    <span class="align-middle">{`${moment(value[0]).format('DD-MMM-YYYY')} - ${moment(value[1]).format('DD-MMM-YYYY')}`}</span>
                    <img src={calenderDownArrowIcon} style={{ cursor: "pointer", marginRight: " -9px", marginLeft: "8px" }} className={styles.calenderDownArrowIcon} />
                </div>
            </FormControl>
        </>
    );
}

export default MyDateRangePicker;