import React, { useState, useEffect } from "react";

// Pie chart
import AnimatedPieSVG from "./AnimatedPieSVG";

function PieChart(props) {

     // D3 Pie Chart
    //  console.log('generateData Pie Chart ==>', props.piedata);
     const formatedData = props.piedata.map((data, index) => {
      return {
        date: data.xVal,
        value: data.yVal
      };

    });
    
    return (
      <React.Fragment >
      <div>
        <AnimatedPieSVG
          data={formatedData ? formatedData : []}
          width={props.width}
          height={props.height}
          innerRadius={props.innerRadius}
          outerRadius={props.outerRadius}
          options ={ props.options }
        />
      </div>
    </React.Fragment>
    )
}

export default PieChart
