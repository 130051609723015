import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./ConfigureBrregComponent.module.scss";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import moment from "moment";
import { Link } from "react-router-dom";
import { Redirect, Router } from 'react-router';
import axios from "../../../common/api-configCRM";
import { openSnackbar } from "../../../common/Snackbar/snackbar";
import SmallLoader from "../../../common/Loaders/CircularLoader/circular-loader";
import OopsError from "../../../components/ErrorMessages/OopsError/OopsError";
import WizardError from "../../../components/ErrorMessages/WizardError/WizardError";
import NoRecordsFound from "../../../components/ErrorMessages/NoRecordsFound/NoRecordsFound";
import { withRouter } from "react-router-dom";
import { emailPattern } from '../../../common/common-types';

const stylesMaterialUI = theme => ({
  button: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    marginLeft: 0,
    marginRight: 0
  }
});

class ConfigureBrregComponent extends Component {
  state = {
    CRMClicked: false,
    whichCRM: "",
    isCancelled: false,
    /* idcrm_master: 1,
    crm_name: "", */
    CRMVersions: [],
    errorInWizard: false,
    integrationsValidity: {
      /* user_id: {
        name: "user_id",
        valid: false,
        touched: false,
        error_message: ""
      },
      password: {
        name: "password",
        valid: false,
        touched: false,
        error_message: ""
      },
      client_id: {
        name: "client_id",
        valid: false,
        touched: false,
        error_message: ""
      },
      secret_key: {
        name: "secret_key",
        valid: false,
        touched: false,
        error_message: ""
      }, */
      crm_url: {
        name: "crm_url",
        valid: false,
        touched: false,
        error_message: ""
      }
    },
    integrationsDetails: {
      "idcrm_integration": "",
      "fk_crm_id": "",
      "fk_crm_version_id": "1",
      "crm_url": "",
      "auth_type": "PUBLIC",
      "user_id": "",
      "password": "",
      "client_id": "",
      "secret_key": "",
      "active_YN": "",
      "fk_schdule_id": "",
      "scheduled_time": "",
      "leadx_to_crm_lastsyncdate": "",
      "crm_to_leadx_lastsyncdate": ""
    },
    connectionSuccessfull: null,
    loadingIntegrationDetails: true,
    errorLoadingIntegrationDetails: false
  };

  componentDidMount() {
    //getCMR configured and integrated data from db
    if (this.props.idcrm_master !== undefined &&
      this.props.idcrm_master !== null && this.props.idcrm_master !== "") {
      //axios.get(`integration/get/${this.state.idcrm_master}`).then(response => {
      axios.get(`integration/get/${this.props.idcrm_master}`).then(response => {
        //console.log("Integration Resp", response);
        if (response !== undefined &&
          response.data !== undefined &&
          response.data.status !== undefined &&
          response.data.status === 0) {
          if (response.data.data !== undefined) {
            ////CAUTION: Currently integration details have multiple records
            // it should have only 1
            // TODO Rekha remove [0] once only one record is received
            if (response.data.data.length > 0) {
              this.props.setIdCRM_Integration(response.data.data[0].idcrm_integration);
              //console.log("props updatedYYYYY", this.props);
              this.setState({
                integrationsDetails: response.data.data[0]
              });
              //update integrationsValidity
              this.validateAllIntegrationDetails();
            }
            this.setState({
              errorLoadingIntegrationDetails: false,
              loadingIntegrationDetails: false
            });
            //console.log("integrationsDetails ", this.state.integrationsDetails);
            //console.log("integrationsDetails ", this.state.loadingIntegrationDetails);
          } else {
            openSnackbar("Data Not Found.", "snack-error");
            this.setState({
              loadingIntegrationDetails: false,
              errorLoadingIntegrationDetails: true,
            });
          }
        } else {
          openSnackbar("Unable to Get Integration Information", "snack-error");
          this.setState({
            loadingIntegrationDetails: false,
            errorLoadingIntegrationDetails: true,
          });
        }
      }).catch((error) => {
        openSnackbar("Unable to Get Integration Information", "snack-error");
        this.setState({
          loadingIntegrationDetails: false,
          errorLoadingIntegrationDetails: true,
        });
        console.log(error);
      });
      //update idcrm_master in state
      axios.get(`version/get/${this.props.idcrm_master}`, {
        params: {
          //id: this.state.idcrm_master
        }
      }).then(response => {
        //console.log("response", response);
        if (response !== undefined && response.data.status !== undefined && response.data.status === 0) {
          if (response.data.data !== undefined) {
            //get the version data
            //console.log("version data in configure", response.data.data);
            this.setState({ CRMVersions: response.data.data });
            //console.log("updated state credentials", this.state.CRMVersions);
          } else {
            openSnackbar("Data Not Found.", "snack-error");
          }
        } else {
          openSnackbar("Unable to Get CRM Version Information", "snack-error");
        }
      }).catch((error) => {
        openSnackbar("Unable to Get CRM Version Information", "snack-error");
        console.log(error);
      });
    } else {
      this.setState({
        errorInWizard: true
      });
    }
  }

  validateAllIntegrationDetails() {
    const updateIntegrationsValidity = {
      ...this.state.integrationsValidity
    };

    if (this.state.integrationsDetails !== undefined) {
      /*  if (this.state.integrationsDetails.user_id !== undefined &&
         this.state.integrationsDetails.user_id !== null &&
         this.state.integrationsDetails.user_id.trim() !== "" &&
         emailPattern.test("anita.visal@neeraytech.com") === true) {
         const updateElement = {
           ...updateIntegrationsValidity["user_id"]
         }
         updateElement.valid = true;
         updateIntegrationsValidity["user_id"] = updateElement;
       }
       if (this.state.integrationsDetails.password !== undefined &&
         this.state.integrationsDetails.password.trim() !== "") {
         const updateElement = {
           ...updateIntegrationsValidity["password"]
         }
         updateElement.valid = true;
         updateIntegrationsValidity["password"] = updateElement;
       }
       if (this.state.integrationsDetails.client_id !== undefined &&
         this.state.integrationsDetails.client_id.trim() !== "") {
         const updateElement = {
           ...updateIntegrationsValidity["client_id"]
         }
         updateElement.valid = true;
         updateIntegrationsValidity["client_id"] = updateElement;
       }
       if (this.state.integrationsDetails.secret_key !== undefined &&
         this.state.integrationsDetails.secret_key.trim() !== "") {
         const updateElement = {
           ...updateIntegrationsValidity["secret_key"]
         }
         updateElement.valid = true;
         updateIntegrationsValidity["secret_key"] = updateElement;
       } */
      if (this.state.integrationsDetails.crm_url !== undefined &&
        this.state.integrationsDetails.crm_url !== null &&
        this.state.integrationsDetails.crm_url.trim() !== "") {
        const updateElement = {
          ...updateIntegrationsValidity["crm_url"]
        }
        updateElement.valid = true;
        updateIntegrationsValidity["crm_url"] = updateElement;
      }
    }
    this.setState({ integrationsValidity: updateIntegrationsValidity });
    //console.log("int validity after intial validations", this.state.integrationsValidity);
  }

  checkValidity(params) {
    /* if (this.state.integrationsDetails.auth_type === "OAUTH") {
      return (
        this.state.integrationsValidity.user_id.valid && this.state.integrationsValidity.password.valid &&
        this.state.integrationsValidity.client_id.valid &&
        this.state.integrationsValidity.secret_key.valid &&
        this.state.integrationsValidity.crm_url.valid
      );
    } else { */
    return (
      /* this.state.integrationsValidity.user_id.valid && this.state.integrationsValidity.password.valid && */
      this.state.integrationsValidity.crm_url.valid
    );
    /*  } */
  }

  handleSaveClicked = (e) => {
    let params = null;
    if (this.state.integrationsDetails !== undefined) {
      if (this.state.integrationsDetails.idcrm_integration !== "") {
        params = {
          //fk_crm_id: this.state.idcrm_master,
          idcrm_integration: this.state.integrationsDetails.idcrm_integration,
          // fk_crm_version_id: this.state.integrationsDetails.fk_crm_version_id,
          fk_crm_version_id: "1",
          /* user_id: this.state.integrationsDetails.user_id,
          password: this.state.integrationsDetails.password,
          client_id: this.state.integrationsDetails.client_id,
          secret_key: this.state.integrationsDetails.secret_key, */
          crm_url: this.state.integrationsDetails.crm_url,
          auth_type: this.state.integrationsDetails.auth_type
        }
        axios
          .put('/integration/update', { data: JSON.stringify(params) })
          .then(response => {
            //console.log("response in savenproceed", response);
            if (response !== undefined && response.data.status !== undefined &&
              response.data.status === 0) {
              openSnackbar("Saved!", "snack-success");
            } else {
              openSnackbar("Something Went Wrong!", "snack-error");
            }
          })
          .catch((error) => {
            console.log(error);
            openSnackbar("Unable to Update Integration", "snack-error");
          })
      } else {
        params = {
          fk_crm_id: this.state.idcrm_master,
          //fk_crm_version_id: this.state.integrationsDetails.fk_crm_version_id,
          fk_crm_version_id: "1",
          /* user_id: this.state.integrationsDetails.user_id,
          password: this.state.integrationsDetails.password,
          client_id: this.state.integrationsDetails.client_id,
          secret_key: this.state.integrationsDetails.secret_key, */
          crm_url: this.state.integrationsDetails.crm_url,
          auth_type: this.state.integrationsDetails.auth_type
        }
        //console.log("this.checkValidity", this.checkValidity);
        if (this.checkValidity === false) {
          openSnackbar("Please enter valid values", "snack-error");
        } else {
          axios
            .post('/integration/add', { data: JSON.stringify(params) })
            .then(response => {
              //console.log("response in savenproceed", response);
              //SHould send back the updated integration Details
              //IMPORTANT Rekha
              //update the integrationDetails with the new details
              if (response !== undefined &&
                response.data !== undefined &&
                response.data.status !== undefined &&
                response.data.status === 0) {
                /*  if (response.data.data !== undefined) { */
                //update inetrgation id for the new record
                //console.log("respo data", response.data);
                this.setState(prevState => ({
                  integrationsDetails: {                   // object that we want to update
                    ...prevState.integrationsDetails,    // keep all other key-value pairs
                    idcrm_integration: response.data.crm_id       // update the value of specific key
                  }
                }));
                /* let intDetails = [...this.state.integrationsDetails];
                intDetails.idcrm_integration = response.data.crm_id;
                console.log("new array", intDetails);
                this.setState({ integrationsDetails: intDetails }); */
                /* } else {
                  openSnackbar("Data Not Found Error", "snack-error");
                } */
              } else {
                openSnackbar("Unable to Add Integration Information", "snack-error");
              }
            })
            .catch((error) => {
              console.log(error);
              openSnackbar("Exception adding Integration Information", "snack-error");
            });
        }
      }
    } else {
      openSnackbar("Something went wrong", "snack-error");
    }
  }

  handleSaveNProceedClicked = (e) => {
    //console.log("inteDetRRRRRR", this.state.integrationsDetails);
    //console.log("configuration for CRM ", e);
    this.setState({ CRMClicked: true, whichCRM: e });

    //save to database
    let params = null;
    if (this.state.integrationsDetails !== undefined) {
      if (this.state.integrationsDetails.idcrm_integration !== "") {
        params = {
          //fk_crm_id: this.state.idcrm_master,
          idcrm_integration: this.state.integrationsDetails.idcrm_integration,
          //fk_crm_version_id: this.state.integrationsDetails.fk_crm_version_id,
          fk_crm_version_id: "1",
          /* user_id: this.state.integrationsDetails.user_id,
          password: this.state.integrationsDetails.password,
          client_id: this.state.integrationsDetails.client_id,
          secret_key: this.state.integrationsDetails.secret_key, */
          crm_url: this.state.integrationsDetails.crm_url,
          auth_type: this.state.integrationsDetails.auth_type
        }
        axios
          .put('/integration/update', { data: JSON.stringify(params) })
          .then(response => {
            //console.log("response in savenproceed", response);
          })
          .catch((error) => {
            console.log(error);
            openSnackbar("Exception Updating Integration Information", "snack-error");
          })
      } else {
        params = {
          fk_crm_id: this.state.idcrm_master,
          //fk_crm_version_id: this.state.integrationsDetails.fk_crm_version_id,
          fk_crm_version_id: "1",
          /* user_id: this.state.integrationsDetails.user_id,
          password: this.state.integrationsDetails.password,
          client_id: this.state.integrationsDetails.client_id,
          secret_key: this.state.integrationsDetails.secret_key, */
          crm_url: this.state.integrationsDetails.crm_url,
          auth_type: this.state.integrationsDetails.auth_type
        }
        axios
          .post('/integration/add', { data: JSON.stringify(params) })
          .then(response => {
            //console.log("response in savenproceed", response);
            //SHould send back the updated integration Details
            //IMPORTANT Rekha
            //update the integrationDetails with the new details
            if (response !== undefined &&
              response.data !== undefined &&
              response.data.status !== undefined &&
              response.data.status === 0) {
              /* if (response.data !== undefined) { */
              //update inetrgation id for the new record
              //console.log("respo data", response.data);
              this.setState(prevState => ({
                integrationsDetails: {                   // object that we want to update
                  ...prevState.integrationsDetails,    // keep all other key-value pairs
                  idcrm_integration: response.data.crm_id       // update the value of specific key
                }
              }), () => {
                //console.log("state crm_id updated successfully!!!!");
              });
            } else {
              openSnackbar("Unable to Add Integration Information", "snack-error");
            }
          })
          .catch((error) => {
            console.log(error);
            openSnackbar("Exception adding Integration Information", "snack-error");
          });
      }
      //save to database
      //console.log("inte detailsXXXXXX", this.state.integrationsDetails);
      if (this.state.integrationsDetails !== undefined &&
        this.state.integrationsDetails.idcrm_integration !== "") {
        //console.log("id before PUSH", this.state.integrationsDetails.idcrm_integration);
        this.props.history.push({
          pathname: '/integrations/configuredataobjsbrreg/',
          state: {
            /*              idcrm_master: this.state.idcrm_master,
                         crm_name: this.state.crm_name,
                         whichCRM: "done",
                         idcrm_integration: this.state.integrationsDetails.idcrm_integration,
                         isActive: this.state.isActive,
                         configured: this.state.configured
             */             //integrationDetails: this.state.integrationsDetails
          }
        });
      } else {
        openSnackbar("Something went wrong", "snack-error");
      }
    } else {
      openSnackbar("Something went wrong", "snack-error");
    }
  }

  handleCancelClicked = () => {
    //console.log("cancel clicked");
    this.setState({ isCancelled: true });
    //this.props.history.goBack();
    this.props.history.push("/integrations");
  }

  handleTestConnectionClicked = (e) => {
    e.preventDefault();
    this.setState({ connectionSuccessfull: null });
    if (this.state.integrationsDetails !== undefined) {
      //result from the api 
      //currently connection details hardcoded - REMOVE Rekha
      /* let params = {
        username: "anita.visal@neeraytech.com",
        password: "Test1234!4lg7yqx9lbdyYcQEB8Psxozv",
        clientId: '3MVG9n_HvETGhr3DqYwiUp1CQY5qr_bH8OU6NW8n_sL63hvu0ef8njnOXIX6yjtKyOocYEXUWQJcT8pTOqfon',
        clientSecret: '2F9C8F3915DF652F3EEAC0AF5F7193438E56D6678AE6E406A3DD01E3B099E299',
        redirectUri: 'http://localhost:8443/callback/'
      } */
      //console.log("this.checkValidity", this.checkValidity());
      //console.log("validity", this.state.integrationsValidity);
      if (this.checkValidity() === false) {
        openSnackbar("Please enter valid values", "snack-error");
      } else {
        let params = {};
        /* if (this.state.integrationsDetails.auth_type === "OAUTH") {
          params = {
            //fk_crm_version_id: this.state.integrationsDetails.fk_crm_version_id,
            fk_crm_version_id: "1",
            user_id: this.state.integrationsDetails.user_id,
            password: this.state.integrationsDetails.password,
            client_id: this.state.integrationsDetails.client_id,
            secret_key: this.state.integrationsDetails.secret_key,
            crm_url: this.state.integrationsDetails.crm_url,
            auth_type: this.state.integrationsDetails.auth_type
            //redirectUri: 'http://localhost:8443/callback/'
          }
          console.log("OAUTH", params);
        } else { */
        //USER
        params = {
          fk_crm_version_id: this.state.integrationsDetails.fk_crm_version_id,
          /* user_id: this.state.integrationsDetails.user_id,
          password: this.state.integrationsDetails.password, */
          crm_url: this.state.integrationsDetails.crm_url,
          auth_type: this.state.integrationsDetails.auth_type
          //redirectUri: 'http://localhost:8443/callback/'
        }
        //console.log("params", params);
        /*  } */
        //console.log("connection details", params);
        //currently connection details hardcoded - REMOVE Rekha
        axios
          .post("/testconnection", { data: JSON.stringify(params) })
          .then(response => {
            //console.log("testetsthgfjhsdfgjhds---", response);
            if (response !== undefined && response.data !== undefined && response.status !== undefined) {
              if (response.data.status === 0) {
                //console.log("testetsthgfjhsdfgjhds---", response.data.status);
                this.setState({ connectionSuccessfull: true });
              } else {
                this.setState({ connectionSuccessfull: false });
              }
            } else {
              this.setState({ connectionSuccessfull: false });
            }
          })
          .catch(error => {
            console.log(error);
            this.setState({ connectionSuccessfull: false });
          });
      }
    } else {
      openSnackbar('Connection Details Not Available', 'snack-error');
    }
  }

  handleCRMVersionChange = (event) => {
    //console.log("event", event.target.value);
    const value = event.target.value;
    this.setState(prevState => ({
      integrationsDetails: {                   // object that we want to update
        ...prevState.integrationsDetails,    // keep all other key-value pairs
        fk_crm_version_id: value       // update the value of specific key
      }
    }));
    /* if (value !== undefined) {
      let intDetails = [...this.state.integrationsDetails];
      intDetails.fk_crm_version_id = value;
      console.log("new array", intDetails);
      this.setState({ integrationsDetails: intDetails });
    } */
  }

  handleConnectionURLChange = (e) => {
    let name = e.target.name;
    //console.log("e value", e.target.value);
    const value = e.target.value;
    const updateIntegrationsValidity = {
      ...this.state.integrationsValidity
    }
    //console.log("int validity", updateIntegrationsValidity);
    const updatedElement = {
      ...updateIntegrationsValidity[name]
    };
    //console.log("updateElement ", updatedElement);
    updatedElement.touched = true;

    if (value.trim() === "") {
      updatedElement.error_message =
        "Please enter a username/email address";
      updatedElement.valid = false;
    } else {
      updatedElement.error_message = "";
      updatedElement.valid = true;
    }
    updateIntegrationsValidity[name] = updatedElement;

    this.setState({ integrationsValidity: updateIntegrationsValidity });
    if (this.state.integrationsValidity[name].touched === true) {
      //console.log("dsfsdfsdfsdf====conn null");
      this.setState({ connectionSuccessfull: null });
    }
    this.setState(prevState => ({
      integrationsDetails: {                   // object that we want to update
        ...prevState.integrationsDetails,    // keep all other key-value pairs
        crm_url: value       // update the value of specific key
      }
    }));
  }

  handleLoginIdChange = (e) => {
    let name = e.target.name;
    //console.log("e value", e.target.value);
    const value = e.target.value;
    const updateIntegrationsValidity = {
      ...this.state.integrationsValidity
    }
    //console.log("int validity", updateIntegrationsValidity);
    const updatedElement = {
      ...updateIntegrationsValidity[name]
    };
    //console.log("updateElement ", updatedElement);
    updatedElement.touched = true;
    if (value.trim() === "") {
      updatedElement.error_message =
        "Please enter a username/email address";
      updatedElement.valid = false;
    } else if (!emailPattern.test(value)) {
      updatedElement.error_message =
        "Please enter a valid username/email address";
      updatedElement.valid = false;
      /*   } else if (userName.length > 50) {
          updatedElement.errorMessage = "Username length too long";
          updatedElement.valid = false; */
    } else {
      updatedElement.error_message = "";
      updatedElement.valid = true;
    }
    updateIntegrationsValidity[name] = updatedElement;

    this.setState({ integrationsValidity: updateIntegrationsValidity });
    if (this.state.integrationsValidity[name].touched === true) {
      //console.log("dsfsdfsdfsdf====conn null");
      this.setState({ connectionSuccessfull: null });
    }
    this.setState(prevState => ({
      integrationsDetails: {                   // object that we want to update
        ...prevState.integrationsDetails,    // keep all other key-value pairs
        user_id: value       // update the value of specific key
      }
    }));
  }

  handlePasswordChange = (e) => {
    let name = e.target.name;
    //console.log("e value", e.target.value);
    const value = e.target.value;
    const updateIntegrationsValidity = {
      ...this.state.integrationsValidity
    }
    //console.log("int validity", updateIntegrationsValidity);
    const updatedElement = {
      ...updateIntegrationsValidity[name]
    };
    //console.log("updateElement ", updatedElement);
    updatedElement.touched = true;
    if (value.trim() === "") {
      updatedElement.error_message =
        "Please enter a valid password";
      updatedElement.valid = false;
    } else {
      updatedElement.error_message = "";
      updatedElement.valid = true;
    }
    updateIntegrationsValidity[name] = updatedElement;

    this.setState({ integrationsValidity: updateIntegrationsValidity });
    if (this.state.integrationsValidity[name].touched === true) {
      //console.log("dsfsdfsdfsdf====conn null");
      this.setState({ connectionSuccessfull: null });
    }
    this.setState(prevState => ({
      integrationsDetails: {                   // object that we want to update
        ...prevState.integrationsDetails,    // keep all other key-value pairs
        password: value       // update the value of specific key
      }
    }));
  }

  handleClientIdChange = (e) => {
    let name = e.target.name;
    //console.log("e value", e.target.value);
    const value = e.target.value;
    const updateIntegrationsValidity = {
      ...this.state.integrationsValidity
    }
    //console.log("int validity", updateIntegrationsValidity);
    const updatedElement = {
      ...updateIntegrationsValidity[name]
    };
    //console.log("updateElement ", updatedElement);
    updatedElement.touched = true;
    if (value.trim() === "") {
      updatedElement.error_message =
        "Please enter a valid client_id";
      updatedElement.valid = false;
    } else {
      updatedElement.error_message = "";
      updatedElement.valid = true;
    }
    updateIntegrationsValidity[name] = updatedElement;

    this.setState({ integrationsValidity: updateIntegrationsValidity });
    if (this.state.integrationsValidity[name].touched === true) {
      //console.log("dsfsdfsdfsdf====conn null");
      this.setState({ connectionSuccessfull: null });
    }
    this.setState(prevState => ({
      integrationsDetails: {                   // object that we want to update
        ...prevState.integrationsDetails,    // keep all other key-value pairs
        client_id: value       // update the value of specific key
      }
    }));
  }

  handleSecretKeyChange = (e) => {
    let name = e.target.name;
    //console.log("e value", e.target.value);
    const value = e.target.value;
    const updateIntegrationsValidity = {
      ...this.state.integrationsValidity
    }
    //console.log("int validity", updateIntegrationsValidity);
    const updatedElement = {
      ...updateIntegrationsValidity[name]
    };
    //console.log("updateElement ", updatedElement);
    updatedElement.touched = true;
    if (value.trim() === "") {
      updatedElement.error_message =
        "Please enter a valid secret key";
      updatedElement.valid = false;
    } else {
      updatedElement.error_message = "";
      updatedElement.valid = true;
    }
    updateIntegrationsValidity[name] = updatedElement;

    this.setState({ integrationsValidity: updateIntegrationsValidity });
    if (this.state.integrationsValidity[name].touched === true) {
      //console.log("dsfsdfsdfsdf====conn null");
      this.setState({ connectionSuccessfull: null });
    }
    this.setState(prevState => ({
      integrationsDetails: {                   // object that we want to update
        ...prevState.integrationsDetails,    // keep all other key-value pairs
        secret_key: value       // update the value of specific key
      }
    }));
  }

  handleConnectionChanged = (event) => {
    //console.log("eee", event.target.value);
    this.setState({ connectionSuccessfull: null });
    const value = event.target.value;
    if (value === "PUBLIC") {
      this.setState(prevState => ({
        integrationsDetails: {                   // object that we want to update
          ...prevState.integrationsDetails,    // keep all other key-value pairs
          auth_type: value,     // update the value of specific key
          /*client_id: "",
          secret_key: "" */
        }
      }));
    }
    this.setState(prevState => ({
      integrationsDetails: {                   // object that we want to update
        ...prevState.integrationsDetails,    // keep all other key-value pairs
        auth_type: value       // update the value of specific key
      }
    }));
  }

  render() {
    return (
      <React.Fragment>
        <div className={styles.main_wrapper}>
          <div className="row">
            <div className="col-12">

              <div className={"breadcrumbs row " + styles.centerAlign}>
                <div className="col-md-6 col-12">
                  <h2 className="heading">Integrations</h2>
                  <div className="breadcrumbs_container">
                    <Breadcrumbs separator="›" arial-label="Breadcrumb">
                      {/* <Link to="/sales-news">Home</Link>
                      <Link to="/integrations">Integrations</Link> */}
                      <span>Home</span>
                      <span>Integrations</span>
                      <span>Configure Brreg</span>
                    </Breadcrumbs>
                  </div>
                </div>
              </div>
              <div className="row container-body">
                <div className={`col-xs-12 col-md-12 col-lg-12 col-12`}>
                  <div className={styles.configureBrreg_container}>

                    <div className={styles.configureBrreg_container_header}>
                      <h1 className={styles.configureBrreg_container_heading}>
                        Step 1 - Configure Brreg Details
                        </h1>
                    </div>

                    <div className={styles.configureBrreg_container_footer} >
                      {/* start */}
                      {/* {this.state.errorLoadingIntegrationDetails ?
                        (<div><OopsError /></div>) :
                        this.state.loadingIntegrationDetails ? (<SmallLoader />) :
                          this.state.integrationsDetails.length === 0 ?
                            (<div><NoRecordsFound /></div>) : */}
                      {this.state.errorInWizard ?
                        (<div><WizardError /></div>) :
                        this.state.loadingIntegrationDetails ? (<SmallLoader />) :
                          this.state.integrationsDetails.length === 0 ?
                            (<div><NoRecordsFound /></div>) :
                            // ({ configureView }) 
                            (<div>
                              <div className="row">
                                <div className="col-12">
                                  <div className="bg-white ml-4 mr-4">
                                    <div className="mt-5 mb-4">
                                      <div className="row">
                                        {/* <div className="col-sm-2" />
                                        
                                        <div className="col-sm-8">
                                          <label className={styles.profileContainer_label__ykzKB}>Connection URL<span style={{ color: '#c00' }}> *</span></label>
                                          <input name="crm_url" onChange={this.handleConnectionURLChange} defaultValue={this.state.integrationsDetails.crm_url} className={styles.profileContainer_inputStyle__1Dduu} type="text" autoComplete="off" />
                                          {this.state.integrationsValidity.crm_url.touched ? (
                                            this.state.integrationsValidity.crm_url.valid ? null : (
                                              <span className={styles.errors}>
                                                {this.state.integrationsValidity.crm_url.error_message}
                                              </span>
                                            )
                                          ) : null}
                                        </div>
                                        <div className="col-sm-2" /> */}

                                        {/* <div className="col-sm-2" />
                                        <div className="col-sm-8">
                                          <h6 className="mb-4 mt-2 text-center">Select Type Of Data Connection</h6>
                                        </div>
                                        <div className="col-sm-2" />
 */}
                                        <div className="col-sm-2" />
                                        <div className="col-sm-4">
                                          <label>
                                            <input name="authtype_radio" type="radio" defaultValue="PUBLIC" onChange={(event) => this.handleConnectionChanged(event)} defaultChecked={this.state.integrationsDetails.auth_type == "PUBLIC"} />
                                            &nbsp;&nbsp;&nbsp;Using Public Data
                                        </label>
                                        </div>
                                        <div className="col-sm-4">
                                          {/* <label>
                                            <input name="authtype_radio" type="radio" defaultValue="SUBSCRIBED" onChange={(event) => this.handleConnectionChanged(event)} defaultChecked={this.state.integrationsDetails.auth_type == "SUBSCRIBED"} />
                                            &nbsp;&nbsp;&nbsp;Subscribed Data Upload
                                        </label> */}
                                        </div>
                                        <div className="col-sm-2" />

                                        <div className="mb-4 mt-2 col-sm-12">
                                          {/* <hr className="jss201" /> */}
                                        </div>

                                        <div className="col-sm-2" />
                                        {this.state.integrationsDetails.auth_type === "SUBSCRIBED" ?
                                          <div className="col-sm-8">
                                            <label className={styles.profileContainer_label__ykzKB}>
                                              Feature is coming soon
                                            </label>
                                          </div>
                                          : <div className="col-sm-8">
                                            <label className={styles.profileContainer_label__ykzKB}>Data Access URL<span style={{ color: '#c00' }}> *</span></label>
                                            <input name="crm_url" disabled onChange={this.handleConnectionURLChange} defaultValue={this.state.integrationsDetails.crm_url} className={styles.profileContainer_inputStyle__1Dduu} type="text" autoComplete="off" />
                                            {this.state.integrationsValidity.crm_url.touched ? (
                                              this.state.integrationsValidity.crm_url.valid ? null : (
                                                <span className={styles.errors}>
                                                  {this.state.integrationsValidity.crm_url.error_message}
                                                </span>
                                              )
                                            ) : null}
                                          </div>
                                        }
                                        <div className="col-sm-2" />

                                      </div>
                                    </div>
                                    <hr className="jss201" />
                                    <div className="mt-3 mb-5">
                                      <div style={{ textAlign: 'right' }}>
                                        <button onClick={this.handleCancelClicked} className={styles.profileContainer_update_btn__MXNDH} type="button" style={{ backgroundColor: '#e0e0e0', padding: '10px 20px', color: 'rgba(0, 0, 0, 0.87)' }}>Cancel</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                        {/* <button onClick={() => this.handleSaveClicked("step1")} type="button" className={styles.profileContainer_update_btn__MXNDH} style={{ padding: '10px 20px' }}>Save</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <button onClick={() => this.handleSaveNProceedClicked("step1")} type="button" className={styles.profileContainer_update_btn__MXNDH} style={{ padding: '10px 20px' }}>Save &amp; Proceed</button> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>)
                      }
                      {/* end */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>)
  }
}

export default withStyles(stylesMaterialUI)(withRouter(ConfigureBrregComponent));