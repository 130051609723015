import React, { useState, useEffect } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import styles from "./organization.module.scss";
import Switch from "react-switch";
import apis from "./apis";
import LargeScreenLoader from "../../common/Loaders/PulseLoader/pulse-loader";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import SnackbarComponent from "../../common/Snackbar/snackbar";
import {
  showSnackbarMessage,
  isParentOrgAdmin,
} from "../../common/common-functions";
import pendingRequestAPIs from "../PendingRequest/apis";
import IconButton from "@material-ui/core/IconButton";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import copy from "copy-to-clipboard";
import InviteUser from "../../common/InviteUser";
import OrganizationProfile from "./View";

// Assets
import profile_setting from "../../../assets/org_profile/setting_icon.png";

const PrimaryButton = styled(Button)`
  font-size: 0.75em;
  text-transform: none;
  white-space: nowrap;
  text-align: center;
  background-color: #3a4860;
  padding-left: 2em;
  padding-right: 2em;
  color: white;
  height: 4.25em;
  padding: 0 0.5em;
  padding-left: 4em;
  padding-right: 4em;
  &:hover {
    background-color: #19d98b;
  }
`;

const OutLinedButton = styled(Button)`
  font-size: 0.75em;
  text-transform: none;
  height: 4.25em;
  padding: 0 0.5em;
  white-space: nowrap;
  text-align: center;
  background-color: transparent;
  padding-left: 4em;
  padding-right: 4em;
  font-weight: bold;
  color: #3a4860;
  &:hover {
    color: #19d98b;
  }
`;

const CustomCard = styled.div`
  background-color: #ffffff;
  padding: 4.5em;
  padding-top: 1.875em;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.2);
`;

const ROLE_OPTIONS = [
  {
    label: "Basic",
    value: 1,
  },
  {
    label: "Professional",
    value: 2,
  },
  {
    label: "Manager",
    value: 3,
  },
  {
    label: "Lite",
    value: 5,
  },
];

const style = {
  control: (base) => ({
    ...base,
    border: "1px solid #c0c0c0",
    boxShadow: "#c0c0c0",
    borderColor: "#c0c0c0",
    "&:hover": {
      boxShadow: "#c0c0c0",
      borderColor: "#c0c0c0",
    },
  }),
};

function getRoleById(id) {
  return ROLE_OPTIONS.find((role) => role.value === id);
}

function getOptionById(options, id) {
  return options.find((option) => option.value == id);
}

function dropdownValueMaper(r) {
  return {
    value: r.id,
    label: r.display_name,
  };
}

export default function OrgProfileSetting(props) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  // self_user_reg_approval_required
  const [surar, setSurar] = useState(false);
  // self_user_reg_config_enabled
  const [surce, setSurce] = useState(false);
  const [crossSilo, setCrossSilo] = useState(false);
  const [handoverFeature, setHandoverFeature] = useState(false);
  const [createContact, setCreateContact] = useState(false);
  const [revenueField, setRevenueField] = useState(false);
  const [role, setRole] = useState(null);
  const [licenseOptions, setLicenseOptions] = useState([]);
  const [designationOptions, setDesignationOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [designation, setDesignation] = useState(null);
  const [department, setDepartment] = useState(null);
  const [domainList, setDomainList] = useState([]);
  const [domainListCount, setDomainListCount] = useState(10);

  const [enable_publishing_news, setEnable_publishing_news] = useState(false);
  const [enableOrgDropDownInRegistration, setEnableOrgDropDownInRegistration] = useState(false);
  const [
    enable_publishing_news_child,
    setEnable_publishing_news_child,
  ] = useState(false);

  const isCurrentUserChildAdmin = !isParentOrgAdmin();

  const [profilePage, setProfilePage] = useState(false);

  const { knowMoreLik } = props;

  useEffect(() => {
    fetchOrganizationDetails((accountPreference) => {
      fetchDependencies(accountPreference);
    });
  }, []);

  const fetchDependencies = async (accountPreference) => {
    await Promise.all([
      fetchLicenses(accountPreference),
      fetchDepartments(accountPreference),
      fetchAllRoles(accountPreference),
    ]);
  };

  const cancelProfileSetting = () => {
    setProfilePage(true);
  };

  const fetchAllRoles = async (accountPreference) => {
    const result = await pendingRequestAPIs.getAllRoles();
    const options = result.data.rows.map(dropdownValueMaper);
    setDesignationOptions(options);
    setDesignation(getOptionById(options, accountPreference.default_role_id));
  };

  const fetchLicenses = async (accountPreference) => {
    const result = await pendingRequestAPIs.getLicenses();
    const options = result.data.licenses.map(dropdownValueMaper);
    setLicenseOptions(options);
    setRole(getOptionById(options, accountPreference.default_license_id));
  };

  const fetchDepartments = async (accountPreference) => {
    const result = await pendingRequestAPIs.getAllDepartments();
    const options = result.data.rows.map((r) => ({
      value: r.id,
      label: r.name,
    }));
    setDepartmentOptions(options);
    setDepartment(
      getOptionById(options, accountPreference.default_department_id)
    );
  };

  const fetchOrganizationDetails = async (cb) => {
    const result = await apis.getOrganizationDetails();
    console.log("result", result);
    setData(result.data.data);
    const domainList =
      result.data.data.truestedDmains.trustedDomainList.data.rows;
    setDomainList(domainList.map((d) => d.domain_name));
    setIsLoading(false);
    setOtherThings(result.data.data);
    setEnableOrgDropDownInRegistration(result.data.data.profile.AccountPreference.show_org_in_registration);
    if (cb) cb(result.data.data.profile.AccountPreference);
  };

  const setOtherThings = (data) => {
    const accountPreferences = data.profile.AccountPreference;
    setSurar(accountPreferences.self_user_reg_approval_required);
    setSurce(accountPreferences.self_user_reg_config_enabled);
    setCrossSilo(accountPreferences.cross_silo_feature);
    setHandoverFeature(accountPreferences.handover_feature);
    setEnable_publishing_news(accountPreferences.enable_publishing_news);
    setEnable_publishing_news_child(
      accountPreferences.enable_publishing_news_child
    );
    setCreateContact(accountPreferences.create_contact_for_lite_user);
    setRevenueField(accountPreferences.revenue_field_for_lite_user);
  };

  const handleOnUserRegConfigChange = async (newChecked) => {
    setSurce(newChecked);
    if (!newChecked) setSurar(false);
  };

  const handleOnUserRegApprovalChange = async (newChecked) => {
    setSurar(newChecked);
  };

  const handleOnUpdate = async () => {
    setIsUpdating(true);
    const result = await apis.updateOrganizationSettings(
      surce,
      surar,
      role.value,
      designation.value,
      department.value,
      crossSilo,
      handoverFeature,
      enable_publishing_news,
      enable_publishing_news_child,
      createContact,
      revenueField,
      enableOrgDropDownInRegistration
    );

    if (result.statusCode === 200) {
      showSnackbarMessage(result.data, "snack-success");
    } else {
      showSnackbarMessage(result.errorMessage, "Technical Error");
      handleOnCancelClick();
    }
    setIsUpdating(false);
  };

  const handleRoleChange = (newRole) => {
    setRole(newRole);
  };

  const handleOnDepartmentChange = (newDepartment) => {
    setDepartment(newDepartment);
  };

  const handleOnDesignationChange = (newDesignation) => {
    setDesignation(newDesignation);
  };

  const handleOnCancelClick = () => {
    setOtherThings(data);
  };

  const handleOnCrossSiloChange = (newChecked) => {
    setCrossSilo(newChecked);
  };

  const handleOnHandoverFeatureChange = (newChecked) => {
    setHandoverFeature(newChecked);
    if (newChecked === false) {
      setCrossSilo(false);
    }
  };
  // enable_publishing_news
  const handleOnEnable_publishing_newsChange = (newChecked) => {
    setEnable_publishing_news(newChecked);
    if (newChecked === false) {
      setEnable_publishing_news_child(false);
    }
  };

  const handleOnEnable_publishing_news_childChange = (newChecked) => {
    setEnable_publishing_news_child(newChecked);
  };

  const handleOnCreateContactChange = (newChecked) => {
    setCreateContact(newChecked);
  };

  const handleOnRevenueFieldChange = (newChecked) => {
    setRevenueField(newChecked);
  };

  const handleCopyOnClick = () => {
    copy(data.registered_url);
    showSnackbarMessage("URL copied successfully.", "snack-success");
  };

  const handleLoadMoreDomains = () => {
    setDomainListCount(domainListCount + 10);
  };
  return (
    <>
      <SnackbarComponent />
      {isLoading && <LargeScreenLoader />}
      {profilePage && <OrganizationProfile />}
      {!isLoading && !profilePage && (
        <div className="main-wrapper">
          <div className="breadcrumbs">
            <div className="row justify-content-between">
              <div className="col-md-4">
                <h3 className={styles.title_Administration}>Configuration</h3>
                <div className="breadcrumbs_container">
                  <Breadcrumbs separator="›" arial-label="Breadcrumb">
                    <Link to="/sales-news">Home</Link>
                    <Link to="/administration">Administration</Link>
                    <span>Organization Profile</span>
                  </Breadcrumbs>
                </div>
              </div>
              <div className="col-md text-right">
                <label
                  className="font-weight-bold"
                  onClick={() =>
                    props.history.push("/administration/subscriptions")
                  }
                >
                  Manage Subscriptions
                </label>
                <span className="mx-2"></span>|<span className="mx-2"></span>
                <InviteUser />
                <span className="mx-2"></span>|
                <label
                  className="mx-2 font-weight-bold"
                  onClick={() =>
                    props.history.push("/administration/new_child_organization")
                  }
                >
                  Create Child Organization
                </label>
                <span className="mx-2"></span>
              </div>
            </div>
          </div>
          <hr />

          <div class="row">
            <div className="col-xs-12 col-sm-12 col-lg-8">
              <div className={styles.title_right_text}>
                User Self Registration
              </div>
              <div className={styles.card_view}>
                <CustomCard>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-8">
                          <div className={styles.title_text}>
                            User Self Registration
                          </div>
                        </div>
                        <div className={"col-md-4 " + styles.toggle_container}>
                          <Switch
                            // onChange={(newChecked) => setChecked(newChecked)}
                            onChange={handleOnUserRegConfigChange}
                            checked={surce}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor={"#19C080"}
                            height={20}
                            width={40}
                            disabled={isCurrentUserChildAdmin}
                            className={styles.toggle_button}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-8">
                          <div className={styles.title_text}>
                            Enable Registration Approval
                          </div>
                        </div>
                        <div className={"col-md-4 " + styles.toggle_container}>
                          <Switch
                            onChange={handleOnUserRegApprovalChange}
                            checked={surar}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor={"#19C080"}
                            height={20}
                            width={40}
                            disabled={isCurrentUserChildAdmin || !surce}
                            className={styles.toggle_button}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-8">
                            <div className={styles.title_text}>
                              Select Organization in User Registration Dropdown
                            </div>
                          </div>
                          <div
                            className={"col-md-4 " + styles.toggle_container}
                          >
                            <Switch
                              onChange={setEnableOrgDropDownInRegistration}
                              checked={enableOrgDropDownInRegistration}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              onColor={"#19C080"}
                              height={20}
                              width={40}
                              className={styles.toggle_button}
                            />
                          </div>
                        </div>
                      </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <div className={styles.title_text}>Email Id</div>
                    </div>
                    <div className="col-md-7">
                      <div className={styles.email_text}>
                        {data.primary_contact.email}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-md-5">
                      <div
                        className={styles.title_text}
                        style={{ marginTop: 0 }}
                      >
                        Default Role to be assigned
                      </div>
                      <div style={{ marginTop: "1em" }}>
                        <Select
                          options={licenseOptions}
                          placeholder="Select Role"
                          onChange={handleRoleChange}
                          styles={style}
                          value={role}
                          isDisabled={isCurrentUserChildAdmin}
                        />
                      </div>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-5">
                      <div
                        className={styles.title_text}
                        style={{ marginTop: 0 }}
                      >
                        Default Title to be assigned
                      </div>
                      <div style={{ marginTop: "1em" }}>
                        <Select
                          options={designationOptions}
                          placeholder="Select Title"
                          onChange={handleOnDesignationChange}
                          value={designation}
                          styles={style}
                          isDisabled={isCurrentUserChildAdmin}
                        />
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className={styles.title_text}>
                        Default Department to be assigned
                      </div>
                      <div style={{ marginTop: "1em" }}>
                        <Select
                          options={departmentOptions}
                          placeholder="Select Department"
                          onChange={handleOnDepartmentChange}
                          value={department}
                          styles={style}
                          isDisabled={isCurrentUserChildAdmin}
                        />
                      </div>
                    </div>
                  </div>
                </CustomCard>
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-lg-4">
              <div className={styles.description_text}>
                User Self Registration
              </div>
              <p className={styles.p_text}>
                Enable Self Registration to allow user registration using
                Register link or Organization specific Registration URL.
              </p>
            </div>
          </div>

          <div class="row">
            <div className="col-xs-12 col-sm-12 col-lg-8">
              <div className={styles.title_right_text}>
                LITE user configuration
              </div>
              <div className={styles.card_view}>
                <CustomCard>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-5">
                          <div className={styles.title_text}>
                            Create Contact Feature
                          </div>
                        </div>
                        <div
                          className="col-md-6"
                          style={{ paddingTop: "1.375em" }}
                        >
                          <Switch
                            // onChange={(newChecked) => setChecked(newChecked)}
                            onChange={handleOnCreateContactChange}
                            checked={createContact}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor={"#19C080"}
                            height={20}
                            width={40}
                            className={styles.toggle_button}
                            disabled={isCurrentUserChildAdmin}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6">
                          <div className={styles.title_text}>
                            Revenue Field in My Pipeline
                          </div>
                        </div>
                        <div
                          className="col-md-5"
                          style={{ paddingTop: "1.375em" }}
                        >
                          <Switch
                            // onChange={(newChecked) => setChecked(newChecked)}
                            onChange={handleOnRevenueFieldChange}
                            checked={revenueField}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor={"#19C080"}
                            height={20}
                            width={40}
                            className={styles.toggle_button}
                            disabled={isCurrentUserChildAdmin}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </CustomCard>
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-lg-4">
              <div className={styles.description_text}>
                LITE user configuration
              </div>
              <p className={styles.p_text}>
                Enable or Disable Revenue Field toggle to display or Hide
                Revenue on My Pipeline. Enable or disable Create Contact Feature
                toggle to allow Lite user create Company or Contacts.
              </p>
            </div>
          </div>
          <div class="row">
            <div className="col-xs-12 col-sm-12 col-lg-8">
              <div className={styles.title_right_text}>Sales News</div>
              <div className={styles.card_view}>
                <CustomCard>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6">
                          <div className={styles.title_text}>
                            Enable Publishing News
                          </div>
                        </div>
                        <div
                          className="col-md-5"
                          style={{ paddingTop: "1.375em" }}
                        >
                          <Switch
                            // onChange={(newChecked) => setChecked(newChecked)}
                            onChange={handleOnEnable_publishing_newsChange}
                            checked={enable_publishing_news}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor={"#19C080"}
                            height={20}
                            width={40}
                            className={styles.toggle_button}
                            disabled={isCurrentUserChildAdmin}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6">
                          <div className={styles.title_text}>
                            Enable Publish News Between Child Organization
                          </div>
                        </div>
                        <div
                          className="col-md-5"
                          style={{ paddingTop: "1.375em" }}
                        >
                          <Switch
                            // onChange={(newChecked) => setChecked(newChecked)}
                            onChange={
                              handleOnEnable_publishing_news_childChange
                            }
                            checked={enable_publishing_news_child}
                            disabled={
                              isCurrentUserChildAdmin || !enable_publishing_news
                            }
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor={"#19C080"}
                            height={20}
                            width={40}
                            className={styles.toggle_button}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </CustomCard>
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-lg-4">
              <div className={styles.description_text}>Sales News</div>
              <p className={styles.p_text}>
                Enable or Disable Sales New Publishing feature.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12 col-sm-12 col-lg-8">
              <div className={styles.title_right_text}>
                Handover & Cross Silo
              </div>
              <div className={styles.card_view}>
                <CustomCard>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6">
                          <div className={styles.title_text}>Handover</div>
                        </div>
                        <div
                          className="col-md-5"
                          style={{ paddingTop: "1.375em" }}
                        >
                          <Switch
                            // onChange={(newChecked) => setChecked(newChecked)}
                            onChange={handleOnHandoverFeatureChange}
                            checked={handoverFeature}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor={"#19C080"}
                            height={20}
                            width={40}
                            className={styles.toggle_button}
                            disabled={isCurrentUserChildAdmin}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6">
                          <div className={styles.title_text}>Cross Silo</div>
                        </div>
                        <div
                          className="col-md-5"
                          style={{ paddingTop: "1.375em" }}
                        >
                          <Switch
                            // onChange={(newChecked) => setChecked(newChecked)}
                            onChange={handleOnCrossSiloChange}
                            checked={crossSilo}
                            disabled={!handoverFeature}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor={"#19C080"}
                            height={20}
                            width={40}
                            className={styles.toggle_button}
                            disabled={isCurrentUserChildAdmin}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </CustomCard>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-4">
              <div className={styles.description_text}>
                Handover & Cross Silo
              </div>
              <p className={styles.p_text}>
                Enable or Disable Handover & Cross Silo feature across the
                organizations.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className={styles.description_text}>
                List of Trusted Domains
              </div>
              <hr className="my-3" />
            </div>
            <div className="col-12">
              {domainList.slice(0, domainListCount).map((domains) => (
                <button
                  className="py-2 px-3 mr-2 mb-2 border-0 rounded font-weight-bold"
                  style={{ backgroundColor: "#A3F0D1", color: "#63908A" }}
                >
                  {domains}
                </button>
              ))}
            </div>
            {domainList.length >= domainListCount && (
              <div className="col-12 text-center">
                <span
                  className="font-weight-bold pointer"
                  onClick={handleLoadMoreDomains}
                >
                  Load More
                </span>
              </div>
            )}
          </div>
          <hr />
          <div>
            <div className={styles.bottom_update_button}>
              <button
                onClick={cancelProfileSetting}
                type="button"
                class="btn btn-default"
              >
                <b>Cancel</b>
              </button>
              <PrimaryButton
                disabled={isCurrentUserChildAdmin || isUpdating}
                onClick={handleOnUpdate}
              >
                Save
              </PrimaryButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
