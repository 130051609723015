import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import styles from "../graphContainer.module.scss";
import OrgOptions from "../OrgOption";
import { openSnackbar } from "../../../common/Snackbar/snackbar";
import DateRangePicker from "../../../components/DateRangePicker/GraphDatePicker";
import {
  getStorageItem,
  FindString,
  FindColumnInDateRange,
} from "../../../common/common-functions";

import reqCrosIcon from "../../../../assets/images/bi/ic_cross_sale.png";
import receiveCrosIcon from "../../../../assets/images/bi/cross_sale_accept.png";

import MultiSelect from "../../../common/MultiSelect/multiSelect";

import CrossSilosChart from "../CrossSilos/CrossSilosChart";
import util from "../util";
import API from "../API";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import LargeScreenLoader from "../../../common/Loaders/PulseLoader/pulse-loader";
import LegendContainer, { Tile } from "../LegendContainer";
import NoDataBarChart from "../NoDataBarChart";

const CrossSales = ({
  organizationArray,
  oldOrganizationList,
  parent_account,
  fromDate,
  toDate,
  ...rest
}) => {
  const [selection, setSelection] = useState(0);
  const [crossSalesDate, setCrossSalesDate] = useState([
    moment().startOf("year").toDate(),
    new Date(),
  ]);
  const [crossSalesGenDashboard, setCrossSalesGenDashboard] = useState(null);
  const [
    crossSalesAcceptedDashboard,
    setCrossSalesAcceptedDashboard,
  ] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [isOpenDropdownContent, setDropdownContent] = useState(false);
  const [isFilterDropdownContent, setFilterDropdownContent] = useState("");

  const crossSaleGenerateRef = useRef(null);
  const crossSaleAcceptedRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, [organizationArray, fromDate, toDate]);

  const fetchData = async () => {
    setShowLoader(true);
    const params = {
      ...util.getParams(organizationArray, fromDate, toDate),
      parent_account_id: parent_account.id,
    };
    const result = await Promise.all([
      API.getCrossSalesGenChartdata(params),
      API.getCrossSalesAcceptedChartdata(params),
    ]);
    setCrossSalesGenDashboard(result[0]);
    setCrossSalesAcceptedDashboard(result[1]);
    setShowLoader(false);
  };

  let CrossSailoGenData = [];
  let CrossSalesGenData = 0;

  if (
    crossSalesGenDashboard &&
    crossSalesGenDashboard.CrossSalesGenratedChartValue != null
  ) {
    CrossSailoGenData = crossSalesGenDashboard.CrossSalesGenratedChartValue.labels.map(
      (x, i) => {
        return {
          xVal: x,
          yVal:
            crossSalesGenDashboard.CrossSalesGenratedChartValue.datasets[0]
              .data[i],
        };
      }
    );
    var Gendata =
      crossSalesGenDashboard.CrossSalesGenratedChartValue.datasets[0].data;
    if (Gendata.length != 0) {
      CrossSalesGenData = Gendata.reduce((total, cur) => {
        return total + cur;
      }, 0);
    }
  }

  let CrossSailoAcceptedData = [];
  let CrossSalesAcceptedData = 0;
  if (
    crossSalesAcceptedDashboard &&
    crossSalesAcceptedDashboard.CrossSalesAcceptedChartValue != null
  ) {
    CrossSailoAcceptedData = crossSalesAcceptedDashboard.CrossSalesAcceptedChartValue.labels.map(
      (x, i) => {
        return {
          xVal: x,
          yVal:
            crossSalesAcceptedDashboard.CrossSalesAcceptedChartValue.datasets[0]
              .data[i],
        };
      }
    );
    var DataForAccepted =
      crossSalesAcceptedDashboard.CrossSalesAcceptedChartValue.datasets[0].data;
    if (DataForAccepted.length != 0) {
      CrossSalesAcceptedData = DataForAccepted.reduce((total, cur) => {
        return total + cur;
      }, 0);
    }
  }

  const renderCrossSalesGeneratedChart = () => {
    const CrossSoiloChartOptions = {
      id: "crossbyDesOrg",
      chartStyle: "horizontal", // horizontal/vertical
      height: 0,
      width: 0,
      margin: {
        top: 20,
        right: 100,
        bottom: 40,
        left: 180,
      },
      hideXaxis: false,
      hideYaxis: false,
      hideCenterLine: false,
      parseTime: "%b-%y",
      hideLegend: false,
      barWidth: 20,
      insideBarWidth: 10,
      multiColorBar: true,
      colors: [
        "#5675B9",
        "#8FAEF2",
        "#39589C",
        "#E0EAFF",
        "#5F87DF",
        "#7CEAF6",
      ],
      hideTooltip: false,
      responsive: true,
      ticksSize: 5,
      xAxisRotate: false,
      yAxisText: false,
      xAxisText: false,
      yAxisTextString: "",
      xAxisTextString: "",
      yAxisDivided: true,
      valueOnTop: true,
      valueOnTopDecimal: false,
    };

    const width =
      crossSaleGenerateRef &&
      crossSaleGenerateRef.current &&
      crossSaleGenerateRef.current.offsetWidth;

    const noData = CrossSailoGenData && CrossSailoGenData.length === 0;

    return (
      <div className="container-body">
        <div className="row justify-content-center">
          <div className={"col-md-12"}>
            <Card className={styles.card}>
              <CardHeader
                className={styles.activities_container_header}
                avatar={
                  <div>
                    <p className={styles.crossSalesChartHeader}>
                      Cross Sales by Destination Org.
                    </p>
                    <p className={styles.crossSalesChartSubHeader}>
                      Count of Source Org
                    </p>
                  </div>
                }
              />
              {!noData ? (
                <CardContent>
                  <div className="row">
                    <div className="col" ref={crossSaleGenerateRef}>
                      {width && (
                        <CrossSilosChart
                          height={300}
                          width={width}
                          data={CrossSailoGenData ? CrossSailoGenData : []}
                          options={CrossSoiloChartOptions}
                        />
                      )}
                      <div style={{ width: "100%", textAlign: "center" }}>
                        Count of Destination organization
                      </div>
                      <LegendContainer>
                        {CrossSailoGenData.map((d, i) => {
                          return (
                            <Tile
                              color={CrossSoiloChartOptions.colors[i]}
                              text={d.xVal}
                              marginRight={
                                i === CrossSailoGenData.length - 1 ? 0 : 10
                              }
                            />
                          );
                        })}
                      </LegendContainer>
                    </div>
                  </div>
                </CardContent>
              ) : (
                <NoDataBarChart />
              )}
            </Card>
          </div>
        </div>
      </div>
    );
  };

  const renderCrossSalesAcceptedChart = () => {
    const width =
      crossSaleAcceptedRef &&
      crossSaleAcceptedRef.current &&
      crossSaleAcceptedRef.current.offsetWidth;
    const CrossSoiloAccceptChartOptions = {
      id: "crossAcceptedOrg",
      chartStyle: "horizontal", // horizontal/vertical
      height: 0,
      width: 0,
      margin: {
        top: 20,
        right: 100,
        bottom: 40,
        left: 180,
      },
      hideXaxis: false,
      hideYaxis: false,
      hideCenterLine: false,
      parseTime: "%b-%y",
      hideLegend: false,
      barWidth: 20,
      insideBarWidth: 60,
      multiColorBar: true,
      colors: [
        "#5675B9",
        "#8FAEF2",
        "#39589C",
        "#E0EAFF",
        "#5F87DF",
        "#7CEAF6",
      ],
      hideTooltip: false,
      responsive: true,
      ticksSize: 5,
      xAxisRotate: false,
      yAxisText: false,
      xAxisText: false,
      yAxisTextString: "",
      xAxisTextString: "",
      yAxisDivided: true,
      valueOnTop: true,
      valueOnTopDecimal: false,
    };

    const noData =
      CrossSailoAcceptedData && CrossSailoAcceptedData.length === 0;

    return (
      <div className="container-body">
        <div className="row justify-content-center">
          <div className={"col-md-12"}>
            <Card className={styles.card}>
              <CardHeader
                className={styles.activities_container_header}
                avatar={
                  <div>
                    <p className={styles.crossSalesChartHeader}>
                      Accepted by Destination Org.
                    </p>
                    <p className={styles.crossSalesChartSubHeader}>
                      Count of source Org
                    </p>
                  </div>
                }
              />
              {!noData ? (
                <CardContent>
                  <div className="row">
                    <div className="col" ref={crossSaleAcceptedRef}>
                      <CrossSilosChart
                        height={300}
                        width={width}
                        data={
                          CrossSailoAcceptedData ? CrossSailoAcceptedData : []
                        }
                        options={CrossSoiloAccceptChartOptions}
                      />
                    </div>
                    <div style={{ width: "100%", textAlign: "center" }}>
                      Count of Destination org
                    </div>
                    <LegendContainer>
                      {CrossSailoAcceptedData.map((d, i) => {
                        return (
                          <Tile
                            color={CrossSoiloAccceptChartOptions.colors[i]}
                            text={d.xVal}
                            marginRight={
                              i === CrossSailoAcceptedData.length - 1 ? 0 : 10
                            }
                          />
                        );
                      })}
                    </LegendContainer>
                  </div>
                </CardContent>
              ) : (
                <NoDataBarChart />
              )}
            </Card>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="container-body">
        <div className="container-body">
          <div className="row">
            <div className="col-md-6 border-right">
              <div className="row">
                <div className={"col-sm-6 " + styles.topSectionLeft}>
                  <img
                    className={styles.icon_img}
                    src={reqCrosIcon}
                    alt="avrageVelocity"
                  />
                </div>
                <div className={"col-sm-6 " + styles.topSection}>
                  <div className={styles.first_content_style}>
                    <div className={styles.content_values1}>
                      {CrossSalesGenData ? CrossSalesGenData : 0}
                    </div>
                    <p className={styles.title_Pulselite}>Generated by Org.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className={"col-sm-6 " + styles.topSectionLeft}>
                  <img
                    className={styles.icon_img}
                    src={receiveCrosIcon}
                    alt="LeadIcon"
                  />
                </div>
                <div className={"col-sm-6 " + styles.topSection}>
                  <div className={styles.first_content_style}>
                    <div className={styles.content_values1}>
                      {CrossSalesAcceptedData ? CrossSalesAcceptedData : 0}
                    </div>
                    <p className={styles.title_Pulselite}>
                      Accepted by Org.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {renderCrossSalesGeneratedChart()}
      {renderCrossSalesAcceptedChart()}

      {showLoader && <LargeScreenLoader />}
    </>
  );
};

export default CrossSales;
