import React, { Component } from "react";
import styles from "./AddActivitiesComponent.module.scss";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import AutoComplete from "../../../common/AutoComplete/autocomplete";
import Button from "@material-ui/core/Button";
import moment from "moment";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import { openSnackbar } from "../../../common/Snackbar/snackbar";
import DatePicker from "react-date-picker";
import { FormErrors } from "../../../common/formErrors/formErrors";
import axiosInstance from "../../../common/api-config";
import FormHelperText from "@material-ui/core/FormHelperText";
import CircularProgress from "../../../common/Loaders/CircularLoader/circular-loader";
import { commonValidation_regex, commonValidationWithLineBreak_regex} from "../../../common/common-types";


const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing.unit
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={`${classes.root} ${styles.dialogTitle}`}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    // padding: "30px"
    padding: "24px"
  }
}))(props => {
  const { children, classes } = props;
  return (
    <MuiDialogContent
      className={`${classes.root} ${styles.add_activity_dialog_content}`}
    >
      {children}
    </MuiDialogContent>
  );
});

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: "5px 0 0 0"
  }
}))(MuiDialogActions);

const stylesMaterialUI = () => ({});

class AddActivitiesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activity_type_id: 0,
      company_id: 0,
      activity_person_id: 0,
      activity_date: moment().format("YYYY-MM-DD"),
      activity_time: "00:00:00",
      leadId: props.lead_id ? props.lead_id : null,
      notes: "",
      personList: [],
      addActivityErrors: {
        activity_type_id: "",
        company_id: "",
        activity_person_id: "",
        lead_title: "",
        notes: ""
      },
      isOpen: false,
      leadListing: [],
      resetPersonID: false,
      resetLeadID: false,
      submitButton: false
    };
  }

  componentDidMount() {
    const requestBody = {
      arrayFilters: [{}],
      selectFilters: ["id", "name"],
      sort: {
        field: "name",
        sortOrder: "ASC"
      },
      paginate: {
        page: "",
        limit: ""
      }
    };
    this.props.getActivityTypes(requestBody);
    if (this.props.company_id && this.props.person_id) {
      this.setState({
        company_id: this.props.company_id,
        activity_person_id: this.props.person_id
      });
      this.fetchPersonList(this.props.company_id);
    }
    if (this.props.updateActivity) {
      const { updateActivity } = this.props;
      this.setState({
        activity_type_id: updateActivity.activity_type
          ? updateActivity.activity_type.id
          : 0,
        company_id: updateActivity.contact_company
          ? updateActivity.contact_company.id
          : 0,
        activity_person_id: updateActivity.person_id
          ? updateActivity.person_id
          : 0,
        activity_date: updateActivity.activity_date
          ? moment(updateActivity.activity_date).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
        activity_time: updateActivity.activity_time
          ? updateActivity.activity_time
          : "00:00:00",
        leadId: updateActivity.lead_id,
        notes: updateActivity.notes ? updateActivity.notes : ""
      });
      this.fetchPersonList(updateActivity.contact_company.id);
      this.getLeadList(updateActivity.contact_company.id);
    }
  }

  fetchPersonList = company_id => {
    let params = {
      arrayFilters: [
        {
          is_deleted: 0,
          company_id: company_id
        }
      ],
      selectFilters: [
        "id",
        "initials",
        "first_name",
        "last_name",
        "phone_number"
      ],
      sort: {
        field: "id",
        sortOrder: "DESC"
      },
      paginate: {
        page: "",
        limit: ""
      }
    };
    axiosInstance
      .post("/contact/person/get", params)
      .then(response => {
        if (response && response.data && response.data.statusCode === 200) {
          this.setState({
            personList: response.data.data.rows,
            resetPersonID: true
          });
        } else {
          this.setState({
            personList: [],
            resetPersonID: false
          });
        }
        // this.checkPersonValidation();
      })
      .catch(error => {
        this.setState({
          personList: [],
          resetPersonID: false
        });
      });
  };

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.errorMessage !== "") {
      nextProps.resetAddActivity();
      nextProps.resetUpdateActivity();
      // openSnackbar(nextProps.errorMessage, "snack-error");
      if(nextProps.errorMessage == undefined){
        openSnackbar("Oops, something went wrong!", "snack-error"); // akshayy
      }else{
        openSnackbar(nextProps.errorMessage, "snack-error");
      }
      nextProps.close("");
    } else if (nextProps && nextProps.addSalesActivity) {
      nextProps.resetAddActivity();
      openSnackbar("Activity added successfully.", "snack-success");
      nextProps.close("save_activity");
    } else if (nextProps && nextProps.updateSalesActivity) {
      nextProps.resetUpdateActivity();
      openSnackbar("Activity updated successfully.", "snack-success");
      nextProps.close("save_activity");
    }
    return nextProps;
  }

  getPersonList = event => {
    this.handleUserInput(event);
    let company_id = 0;
    if (event && event.target) {
      company_id = event.target.value;
    } else if (event && event.value) {
      company_id = event.value;
    }
    this.setState({ company_id, resetPersonID: false, resetLeadID: false });
    // this.setState(prevState => ({
    //   company_id,
    //   resetPersonID: false,
    //   resetLeadID: false,
    //   addActivityErrors: {
    //     ...prevState.addActivityErrors,
    //     company_id: ''
    //   }
    // }));
    this.getLeadList(company_id);
    this.fetchPersonList(company_id);
  };

  getLeadList = company_id => {
    const reqLeadList = {
      arrayFilters: [
        {
          company_id: company_id
        }
      ],
      selectFilters: ["id", "lead_title"],
      sort: {
        field: "lead_title",
        sortOrder: "ASC"
      }
      //user_id: JSON.parse(localStorage.getItem("user")).user_id
    };
    axiosInstance
      .post("/lead/getLeadList", reqLeadList)
      .then(response => {
        if (response && response.data && response.data.statusCode === 200) {
          this.setState({
            leadListing: response.data.data.rows,
            resetLeadID: true
          });
        } else {
          this.setState({ leadListing: [], resetLeadID: false });
        }
      })
      .catch(error => {
        this.setState({ leadListing: [], resetLeadID: false });
      });
  };

  checkValidPerson = async (event, newValue, autocompleteName) => {
    let activity_person_id = this.state.activity_person_id;
    let addActivityErrors = { ...this.state.addActivityErrors };
    if (newValue && newValue != "") {
      let selectedType = this.state.personList.filter(type => {
        return type.name == newValue;
      });
      if (!selectedType || selectedType.length == 0) {
        activity_person_id = 0;
        addActivityErrors["activity_person_id"] = "Please select contact person from suggestions.";
      }

    } else if (newValue.length === 0) {
      addActivityErrors["activity_person_id"] = "";
      activity_person_id = 0;
    }
    await this.setState({
      addActivityErrors,
      activity_person_id: activity_person_id
    });
  };

  checkPersonValidation = () => {
    let addActivityErrors = { ...this.state.addActivityErrors };
    if (this.state.personList.length == 0) {
      addActivityErrors["activity_person_id"] =
        "No persons added for this company";
    } else {
      addActivityErrors["activity_person_id"] = "";
    }
    this.setState({
      addActivityErrors: addActivityErrors
    });
  };

  handleUserInput = async e => {
    if (e && e.target) {
      const name = e.target.name;
      const value = e.target.value;
      this.setState({ [name]: value }, () => {
        this.validateField(name, value);
      });
    } else if (e && e.name) {
      const name = e.name;
      const value = e.value;
      await this.setState({ [name]: value }, () => {
        this.validateField(name, value);
      });

      if (name === "company_id") {
        this.setState({ activity_person_id: 0, leadId: null });
      }
    }
  };

  handleTimeChange = newTime => {
    this.setState({ activity_time: newTime && newTime.format("HH:mm:ss") });
  };

  handleSaveClick = () => {
    let addActivityErrors = { ...this.state.addActivityErrors };
    if (addActivityErrors['activity_person_id'] !== "" || addActivityErrors['lead_title'] !== "") {
      this.validateAllFields();
      return;
    }
    if (
      this.state.company_id !== 0 &&
      this.state.activity_type_id !== 0 &&
      this.state.notes.length < 500
    ) {
      if (this.props.updateActivity) {
        const requestBody = {
          id: this.props.updateActivity.id,
          lead_id: this.state.leadId,
          activity_type_id: this.state.activity_type_id,
          activity_date: this.state.activity_date,
          activity_time: this.state.activity_time,
          person_id: this.state.activity_person_id,
          company_id: this.state.company_id,
          notes: this.state.notes.trim()
          //user_id: JSON.parse(localStorage.getItem("user")).user_id
        };
        this.setState({ submitButton: true })
        this.props.updatingActivity(requestBody);
      } else {
        const requestBody = {
          lead_id: this.state.leadId,
          activity_type_id: this.state.activity_type_id,
          activity_date: this.state.activity_date,
          activity_time: this.state.activity_time,
          person_id: this.state.activity_person_id,
          company_id: this.state.company_id,
          notes: this.state.notes.trim()
          //user_id: JSON.parse(localStorage.getItem("user")).user_id
        };
        this.setState({ submitButton: true })
        this.props.addActivity(requestBody);
      }
    } else {
      this.validateAllFields();
    }
  };

  validateAllFields = () => {
    let addActivityErrors = { ...this.state.addActivityErrors };
    if (this.state.company_id === 0) {
      addActivityErrors.company_id = "This field is required";
    }

    // if (this.state.activity_person_id === 0) {
    //   addActivityErrors.activity_person_id = "This field is required";
    // }

    if (this.state.activity_type_id === 0) {
      addActivityErrors.activity_type_id = "This field is required";
    }

    if (this.state.notes.length > 500) {
      addActivityErrors.notes = "Note cannot be more than 500 characters";
    }

    this.setState({ addActivityErrors: addActivityErrors });
  };

  validateField = (fieldName, value) => {
    let addActivityErrors = { ...this.state.addActivityErrors };
    switch (fieldName) {
      case "activity_type_id":
        if (value === 0) {
          addActivityErrors["activity_type_id"] = "This field is required";
        } else {
          addActivityErrors["activity_type_id"] = "";
        }
        break;

      case "company_id":
        if (value === 0) {
          addActivityErrors["company_id"] = "This field is required";
        } else {
          addActivityErrors["company_id"] = "";
        }
        break;
      case "activity_person_id":
        if (value === 0) {
          addActivityErrors["activity_person_id"] = "Please select contact person from suggestions.";
        } else {
          addActivityErrors["activity_person_id"] = "";
        }
        break;

      case "leadId":
        if (value === 0) {
          addActivityErrors["lead_title"] = "Please select lead title from suggestions.";
        } else {
          addActivityErrors["lead_title"] = "";
        }
        break;

      case "notes":
          if (value.trim() === "") {
            addActivityErrors["notes"] = "";
        } else if (value.trim().length > 1024) {
          addActivityErrors["notes"] = "Note cannot be more than 1024 characters";
            
        }
        
        else if (
            !value.match(commonValidationWithLineBreak_regex)
        ) {
          addActivityErrors["notes"] = "Please enter a valid note";
        } else {
          addActivityErrors["notes"] = "";
        }
    
        break;
      default:
        break;
    }
    this.setState({
      addActivityErrors: addActivityErrors
    });
  };

  toggleCalendar = () => {
    if (this.state.isOpen) {
      this.setState({
        flag: "calendar",
        isOpen: false
      });
    } else {
      this.setState({
        flag: "calendar",
        isOpen: true
      });
    }
  };

  handleDateChange = date =>
    this.setState({ activity_date: moment(date).format("YYYY-MM-DD") });

  dateChangeHandler = e => { };

  checkValidCompany = async (event, newValue, autocompleteName) => {
    let addActivityErrors = { ...this.state.addActivityErrors };
    addActivityErrors["lead_title"] = "";
    addActivityErrors["activity_person_id"] = "";
    if (newValue.trim() === "" || newValue.trim() !== "") {
      addActivityErrors["company_id"] = "Please select company from suggestions";
      this.setState({
        company_id: 0,
        addActivityErrors: addActivityErrors,
        leadId: null
      })
    }
  };

  checkActivityType = async (event, newValue, autocompleteName) => {
    let addActivityErrors = { ...this.state.addActivityErrors };
    let activeTypeId = this.state.activity_type_id;
    if (newValue && newValue != "") {
      let selectedType = this.props.activityTypes.filter(type => {
        return type.name == newValue;
      });
      if (!selectedType || selectedType.length == 0) {
        addActivityErrors["activity_type_id"] =
          "Please select activity type from suggestions";
      }
      activeTypeId = 0;
    } else if (newValue.length === 0) {
      activeTypeId = 0;
      addActivityErrors["activity_type_id"] =
        "Please select activity type from suggestions";
    }
    await this.setState({
      addActivityErrors: addActivityErrors,
      activity_type_id: activeTypeId
    });
  };

  checkValidLead = async (event, newValue, autocompleteName) => {
    let addActivityErrors = { ...this.state.addActivityErrors };
    let leadId = this.state.leadId;
    if (newValue && (newValue != "" || newValue.length === 0)) {
      let selectedType = this.state.leadListing.filter(type => {
        return type.lead_title == newValue;
      });
      if (!selectedType || selectedType.length == 0) {
        addActivityErrors["lead_title"] = "Please select lead title from suggestions.";
        leadId = null;
      }
    } else if (newValue.length === 0) {
      leadId = null;
      addActivityErrors["lead_title"] = "";
    }
    await this.setState({ leadId: leadId, addActivityErrors });
  };

  render() {    
    const { classes, activityTypes, companyList } = this.props;
    const { personList, leadListing } = this.state;
    return (
      <div className={styles.add_activity +" overflow_x_hidden"}>
        <DialogTitle id="customized-dialog-title" onClose={this.props.close}>
          <div className={styles.add_activity_dialog_title}>
            {this.props.updateActivity
              ? "Update sales activity"
              : "New sales activity"}
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4">
              <div className={styles.form_group}>
                <label className={styles.form_title} htmlFor="sales-activity">
                  Sales activity<span className={styles.required_field}>*</span>
                </label>
                <div>
                  <AutoComplete
                    suggestions={activityTypes}
                    autoCompleteName="activity_type_id"
                    placeholder="Select sales activity"
                    value={this.state.activity_type_id}
                    name="activity_type_id"
                    id="activity_type_id"
                    change={this.handleUserInput}
                    onChange={(event, newValue, autocompleteName) =>
                      this.checkActivityType(event, newValue, autocompleteName)
                    }
                    disabled={this.props.activityTypes && !(this.props.activityTypes.length > 0)}
                  />
                </div>
                <FormErrors
                  formErrors={this.state.addActivityErrors}
                  field="activity_type_id"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className={styles.form_group}>
                <label className={styles.form_title} htmlFor="company_id">
                  Company<span className={styles.required_field}>*</span>
                </label>
                <div>
                  <AutoComplete
                    suggestions={companyList}
                    autoCompleteName="company"
                    placeholder="Select company"
                    getPersonList={this.getPersonList}
                    value={this.state.company_id}
                    name="company_id"
                    id="company_id"
                    disabled={this.props.updateActivity}
                    //onBlur={() => this.checkValidCompany()}
                    onChange={(event, newValue, autocompleteName) =>
                      this.checkValidCompany(event, newValue, autocompleteName)
                    }
                  />
                </div>
                <FormErrors
                  formErrors={this.state.addActivityErrors}
                  field="company_id"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className={styles.form_group}>
                <label
                  className={styles.form_title}
                  htmlFor="activity_person_id"
                >
                  Contact person
                </label>
                <div>
                  <AutoComplete
                    suggestions={personList}
                    autoCompleteName="activity_person_id"
                    placeholder="Select contact person"
                    value={this.state.activity_person_id}
                    name="activity_person_id"
                    id="activity_person_id"
                    change={this.handleUserInput}
                    disabled={this.props.updateActivity || (personList.length === 0)}
                    onChange={(event, newValue, autocompleteName) =>
                      this.checkValidPerson(event, newValue, autocompleteName)
                    }
                    resetPerson={this.state.resetPersonID}
                  />
                </div>
                <FormErrors
                  formErrors={this.state.addActivityErrors}
                  field="activity_person_id"
                />
                {this.state.company_id !== 0 ? (
                  personList.length > 0 ? null : (
                    <FormHelperText className={styles.helperText}>
                      No persons added for this company.
                    </FormHelperText>
                  )
                ) : null}
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <label className={styles.form_title}>
                Date<span className={styles.required_field}>*</span>
              </label>
              <div className={styles.datepicker}>
                <input
                  value={this.state.activity_date}
                  className={styles.inputStyle + " add_cursor_pointer"}
                  onClick={this.toggleCalendar}
                  onChange={this.dateChangeHandler}
                />
                <DatePicker
                  value={
                    new Date(
                      moment(this.state.activity_date).year(),
                      moment(this.state.activity_date).month(),
                      moment(this.state.activity_date).date()
                    )
                  }
                  clearIcon={null}
                  calendarIcon={null}
                  isOpen={this.state.isOpen}
                  onCalendarClose={this.toggleCalendar}
                  onChange={this.handleDateChange}
                  minDate={new Date()}
                  maxDate={new Date(moment().add(20, 'years'))}
                />
              </div>
              <i className="date-picker-icon" />
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className={styles.form_group}>
                <label className={styles.form_title} htmlFor="time">
                  Time<span className={styles.required_field}>*</span>
                </label>
                <TimePicker
                  style={{ width: 100 }}
                  showSecond={true}
                  value={moment(this.state.activity_time, "HH:mm:ss")}
                  onChange={this.handleTimeChange}
                  placement="bottomLeft"
                  className="add_cursor_pointer"
                //clearIcon={null}
                />
                <i className="time-picker-icon" />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className={styles.form_group}>
                <label className={styles.form_title} htmlFor="lead_id">
                  Lead title
                  <span className={styles.optional}> (optional) </span>
                </label>
                <div>
                  <AutoComplete
                    suggestions={leadListing}
                    autoCompleteName="leadId"
                    placeholder="Select lead title"
                    value={this.state.leadId}
                    name="lead_id"
                    id="lead_id"
                    change={this.handleUserInput}
                    disabled={this.props.lead_id || (leadListing.length === 0)}
                    resetLeadID={this.state.resetLeadID}
                    onChange={(event, newValue, autocompleteName) =>
                      this.checkValidLead(event, newValue, autocompleteName)
                    }
                  />
                </div>
                <FormErrors
                  formErrors={this.state.addActivityErrors}
                  field="lead_title"
                />
                {this.state.company_id !== 0 ? (
                  leadListing !== null && leadListing.length > 0 ? null : (
                    <FormHelperText className={styles.helperText}>
                      No leads are present for this company
                    </FormHelperText>
                  )
                ) : null}
              </div>
            </div>
            <div className="col-12">
              <div className={styles.form_group}>
                <label className={styles.form_title} htmlFor="notes">
                  Notes
                </label>
                <div className="notesHint">{(1024 - this.state.notes.length) + " characters left"}</div>
                <textarea
                  className={styles.textArea}
                  id="notes"
                  name="notes"
                  value={this.state.notes}
                  onChange={this.handleUserInput}
                  maxLength={1024}
                />
                <FormErrors
                  formErrors={this.state.addActivityErrors}
                  field="notes"
                />
              </div>
            </div>
          </div>
          <DialogActions>
            {this.state.submitButton ? (
              <div className={styles.submit_loader}>
                <CircularProgress
                  style={{
                    color: "#19D98C"
                  }}
                />
              </div>
            ) : (
                <Button
                  onClick={this.handleSaveClick}
                  className={styles.saveButton}
                  color="primary"
                >
                  Save
              </Button>
              )}
          </DialogActions>
        </DialogContent>
      </div>
    );
  }
}
export default withStyles(stylesMaterialUI)(AddActivitiesComponent);
