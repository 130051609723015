export const GET_IDCRM_MASTER = "GET_IDCRM_MASTER";
export const GET_CRM_NAME =
  "GET_CRM_NAME";
export const GET_ISACTIVE =
  "GET_ISACTIVE";
export const GET_CONFIGURED = "GET_CONFIGURED";
export const SET_IDCRM_MASTER = "SET_IDCRM_MASTER";
export const SET_CONFIGURED = "SET_CONFIGURED";
export const SET_CRM_NAME = "SET_CRM_NAME";
export const SET_ISACTIVE =  "SET_ISACTIVE";
export const SETIDCRM_INTEGRATION = "SETIDCRM_INTEGRATION";

  