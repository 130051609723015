import React from "react";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import styles from "./profileContainer.module.scss";
import * as actions from "./redux/actions";
import { connect } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import profile_phone_icon from "../../../assets/images/content-images/profile_phone_icon.png";
import profile_email_icon from "../../../assets/images/content-images/profile_email_icon.png";
import { openSnackbar } from "../../common/Snackbar/snackbar";
import LargeScreenLoader from "../../common/Loaders/PulseLoader/pulse-loader";
import {
  imageType,
  commonValidation_regex
} from "../../common/common-types";
import OopsError from "../../components/ErrorMessages/OopsError/OopsError";
import { getStorageItem } from "../../common/common-functions";
import imageCompression from 'browser-image-compression';
import CropperDialog from "../../common/CropperDialog/CropperDialog";


class ProfileContainer extends React.Component {
  currentUser;

  constructor(props) {
    super(props);

    this.state = {
      personFields: {
        first_name: "",
        middle_name: "",
        last_name: "",
        title: "",
        department: "",
        initials:""
      },
      personErrors: {},
      file: {},
      isEdit: false,
      startDate: "",
      endDate: "",
      activeLicense: false,
      licenseType: "",
      user_id: 0,
      user_avatar: "",
      profilePicValidation: false,
      src: "",
      cropperDialog: false,
      uri: ""
    };
  }

  componentDidMount() {
    this.getUserDetails();
  }

  getUserDetails = () => {
    // this.props.startLoading();
    // const user = JSON.parse(localStorage.getItem("user"));
    const user = JSON.parse(getStorageItem("user"));
    if (user) {
      this.setState({
        startDate: user.startDate,
        endDate: user.endDate,
        activeLicense: user.activeLicense,
        licenseType: user.license_type.display_name
      });
    }

    let params = {
      // "user_id": this.currentUser.user_id
    };
    this.props.getUserDetails(params);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.errorMessage !== "" &&
      this.props.errorMessage !== undefined
    ) {
      openSnackbar(this.props.errorMessage, "snack-error", false, () => {
        this.props.resetErrorMessage();
      });
    }
    //if (prevProps.updatedUserDetailsFlag != this.props.updatedUserDetailsFlag) {
    if (this.props.updatedUserDetailsFlag && prevProps.updatedUserDetailsFlag !== this.props.updatedUserDetailsFlag) {
      if (this.props.profilePhoto) {
        openSnackbar(
          "Profile picture updated successfully.",
          "snack-success",
          false,
          () => {
            this.props.resetUserDetailsFlag();
          }
        );
      } else if (this.props.removePhoto) {
        openSnackbar(
          "Profile picture removed successfully.",
          "snack-success",
          false,
          () => {
            this.props.resetUserDetailsFlag();
          }
        );
      } else {
        openSnackbar(
          "Profile updated successfully.",
          "snack-success",
          false,
          () => {
            this.props.resetUserDetailsFlag();
          }
        );
      }
      this.getUserDetails();
    }
  }

  // person fields on change
  handlePersonFieldsChange = e => { console.log("e.target",e.target.value);
    let { name, value } = "";
    if (e && e.target) {
      name = e.target.name;
      value = e.target.value;
    } else if (e && e.name) {
      name = e.name;
      value = e.value;
    }
    let personFields = { ...this.state.personFields };
    let personErrors = { ...this.state.personErrors };

    switch (name) {
      case "first_name":
        if (value.trim() === "") {
          personFields["first_name"] = value;
          personErrors["first_name"] = "This field is required";
        } else if (!value.match(commonValidation_regex)) {
          personFields["first_name"] = value;
          personErrors["first_name"] = "Please enter valid first name";
        } else {
          personFields["first_name"] = value;
          personErrors["first_name"] = "";
        }
        break;

      case "middle_name":
        if (value.trim() === "") {
          personFields["middle_name"] = value;
          personErrors["middle_name"] = "";
        } else if (!value.match(commonValidation_regex)) {
          personFields["middle_name"] = value;
          personErrors["middle_name"] = "Please enter valid middle name";
        } else {
          personFields["middle_name"] = value;
          personErrors["middle_name"] = "";
        }
        break;

      case "last_name":
        if (value.trim() === "") {
          personFields["last_name"] = value;
          personErrors["last_name"] = "This field is required";
        } else if (
          !value.match(commonValidation_regex) ||
          value.charAt(value.length - 1) === "'"
        ) {
          personFields["last_name"] = value;
          personErrors["last_name"] = "Please enter valid last name";
        } else {
          personFields["last_name"] = value;
          personErrors["last_name"] = "";
        }
        break;

        case "initials":
            personFields["initials"] = value;
            this.setState({
              personFields: {initials:value, ...personFields }
              
            });
            //personErrors["initials"] = "This field is required";
          break;
      default:
        break;
    }

    this.setState({
      personFields: { ...personFields },
      personErrors: { ...personErrors }
    });
  };

  // person form on submit all validations
  handlePersonOnSubmitValidation = () => {
    let personFields = this.state.personFields;
    let personErrors = {};
    let personFormIsValid = true;

    // person name
    if (typeof personFields["first_name"]) {
      if (personFields["first_name"].trim() === "") {
        personFormIsValid = personFormIsValid && false;
        personErrors["first_name"] = "This field is required";
      } else {
        if (!personFields["first_name"].match(commonValidation_regex)) {
          ///^[a-zA-Z ]+$/
          personFormIsValid = personFormIsValid && false;
          personErrors["first_name"] = "Please enter valid first name";
        }
      }
    }

    if (typeof personFields["middle_name"]) {
      if (personFields["middle_name"].trim() === "") {
        personFormIsValid = personFormIsValid && true;
        personErrors["middle_name"] = "";
      } else if (!personFields["middle_name"].match(commonValidation_regex)) {
        ///^[a-zA-Z ]+$/
        personFormIsValid = personFormIsValid && false;
        personErrors["middle_name"] = "Please enter valid middle name";
      }
    }

    if (typeof personFields["last_name"]) {
      if (personFields["last_name"].trim() === "") {
        personFormIsValid = personFormIsValid && false;
        personErrors["last_name"] = "This field is required";
      } else {
        if (!personFields["last_name"].match(commonValidation_regex)) {
          ///^[a-zA-Z ]+$/
          personFormIsValid = personFormIsValid && false;
          personErrors["last_name"] = "Please enter valid last name";
        }
      }
    }

    this.setState({ personErrors: personErrors });
    return personFormIsValid;
  };

  //Checking MIME file type
  mimeType = (headerString, blob) => {
    if (headerString.startsWith("ffd8ff")) {
      headerString = headerString.slice(0, 6);
    }

    switch (headerString) {
      case "89504e47":
      case "ffd8ff":
        this.setState({ profilePicValidation: true }, () => {
          this.checkUploadedFile(blob);
        });
        break;
      default:
        this.setState({ profilePicValidation: false }, () => {
          this.checkUploadedFile(blob);
        });
        break;
    }
  };
  //Generating MIME file type
  //Generating MIME file type
  //Generating MIME file type
  remoteCallback = async blob => {
    let header = "";
    var fr = new FileReader();
    fr.onload = e => {
      var arr = new Uint8Array(e.target.result).subarray(0, 4);
      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      this.mimeType(header, blob);
    };
    fr.readAsArrayBuffer(blob);
  };

  uploadFile = e => {
    this.setState({
      uri: e.target.value
    });
    let file = e.target.files[0];
    console.log(file);
    e.target.value = "";
    this.remoteCallback(file);
  };

  checkUploadedFile = file => {
    if (
      this.state.profilePicValidation === true &&
      (file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg")
    ) {
      if (file.size < 10485760) {
        if (file.size > 2097152) {
          const options = {
            maxSizeMB: 2,
            maxWidthOrHeight: 1920
          }
          imageCompression(file, options)
            .then(compressFile => {
              this.settingImage(compressFile)
            })
            .catch(error => {
              this.setState({
                uri: ''
              });
              openSnackbar("Image size too large", "snack-error");
            })
        } else {
          this.settingImage(file)
        }
      } else {
        this.setState({
          uri: ''
        });
        openSnackbar("Image size should not more than 10 mb.", "snack-error");
      }
    } else {
      this.setState({
        uri: ''
      });
      openSnackbar("Invalid file format", "snack-error");
    }
  };

  settingImage = file => {

    this.getBase64(file, result => {
      console.log("result**", result);
      this.setState({
        src: result,
        cropperDialog: true,
        file: file
      });

    });
  }

  //converting image into base 64
  getBase64(file, cb, sto) {
    let reader = new FileReader();
    if (file !== undefined) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result);
      };
      reader.onerror = error => {
        console.log("Error: ", error);
      };
    }
  }

  openUpdateMode = () => {
    let personFields = {
      id: this.props.userDetails ? this.props.userDetails.id : 0,
      title:
        this.props.userDetails && this.props.userDetails.role
          ? this.props.userDetails.role.display_name
          : "",
      department: this.props.userDetails
        ? this.props.userDetails.department
        : "",
      first_name: this.props.userDetails
        ? this.props.userDetails.first_name
        : "",
      middle_name:
        this.props.userDetails && this.props.userDetails.middle_name
          ? this.props.userDetails.middle_name
          : "",
      last_name: this.props.userDetails ? this.props.userDetails.last_name : "",
      initials: this.props.userDetails ? this.props.userDetails.initials : "",
    };
    this.setState({
      isEdit: true,
      personFields
    });
  };

  saveUserDetails = () => {
    if (this.handlePersonOnSubmitValidation()) {
      console.log(this.state,"this.state.personFields.initials");
      let params = {
        id: this.state.personFields.id,
        initials: this.state.personFields.initials.trim(),
        first_name: this.state.personFields.first_name.trim(),
        middle_name: this.state.personFields.middle_name.trim(),
        last_name: this.state.personFields.last_name.trim()
      };
      console.log(params,"params");
      this.props.startLoading();
      this.props.updatedUserDetails(params);
      this.setState({
        isEdit: false
      });
    }
  };

  removeImage = img => {
    const params = {
      user_avatar_old: img,
      user_avatar: null
    };
    this.props.startLoading();
    this.props.removeProfileImage(params);
  };

  // crop image button clicked
  cropImage = (val, cropper) => {
    if (val) {
      let params = {
        id: this.props.userDetails.id,
        user_avatar_old: this.props.userDetails.user_avatar,
        attachment: {
          fileName: this.state.file.name,
          fileExtension: this.state.file.type,
          fileBody: cropper
            .getCroppedCanvas()
            .toDataURL(this.state.file.type),
          parentFolder: "users"
        }
      };

      this.props.startLoading();
      this.props.updateProfilePhoto(params);

      this.setState({
        file: {},
        src: "",
        cropperDialog: false,
        profilePicValidation: false,
        uri: ""
      });
    } else {
      this.setState({
        file: {},
        src: "",
        cropperDialog: false,
        profilePicValidation: false,
        uri: ""
      });
      return;
    }
  };

  handleCloseCropDialog = () => {
    this.setState({
      src: "",
      cropperDialog: false,
      file: {},
      profilePicValidation: false
    });
  };

  render() {
    let { userDetails } = this.props;
    return (
      <div className="main-wrapper">
        {this.props.errorLoadingUserProfile ? (
          <OopsError />
        ) : (
            <>
              {this.props.loadingData ? <LargeScreenLoader /> : ""}
              <div className="breadcrumbs">
                <h3 className="heading">MY PROFILE</h3>
                <div className="breadcrumbs_container">
                  <Breadcrumbs separator="›" arial-label="Breadcrumb">
                    <Link to="/sales-news">Home</Link>
                    <span>My profile</span>
                  </Breadcrumbs>
                </div>
              </div>
              <div className="container-body">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-4 profile_bottom_space">
                    <div
                      className={
                        "card shadow mb-4 bg-white card-rounded " +
                        styles.profile_card
                      }
                    >
                      <div className={"card-title " + styles.user_avatar_section}>
                        <div className="profile_wrap">
                          {userDetails &&
                            imageType.test(userDetails.user_avatar) ? (
                              <Avatar
                                alt="Profile Picture"
                                src={
                                  userDetails.user_avatar.slice(
                                    0,
                                    userDetails.user_avatar.lastIndexOf("/")
                                  ) +
                                  "/medium_" +
                                  userDetails.user_avatar.substring(
                                    userDetails.user_avatar.lastIndexOf("/") + 1
                                  )
                                }
                                className={styles.bigAvatar}
                              />
                            ) : (
                              <Avatar
                                alt="Profile Picture"
                                src={require("../../../assets/userProfile_default.png")}
                                className={styles.bigAvatar}
                              />
                            )}
                        </div>
                        <div className={styles.profile_button_edit_remove}>
                          <input
                            id="myInput"
                            type="file"
                            ref={ref => (this.upload = ref)}
                            style={{ display: "none" }}
                            onChange={event => this.uploadFile(event)}
                            accept=".png,.jpeg,.jpg,.PNG,.JPEG,.JPG"
                          />
                          <button
                            className={styles.btn_edit}
                            onClick={e => this.upload.click()}
                          >
                            Edit
                        </button>
                          {userDetails && userDetails.user_avatar ? (
                            <button
                              className={styles.btn_remove}
                              onClick={img =>
                                this.removeImage(userDetails.user_avatar)
                              }
                            >
                              Remove
                            </button>
                          ) : null}
                        </div>
                        <div className={styles.user_name}>
                          {userDetails ? userDetails.name : ""}
                        </div>
                        <div className={styles.user_role}>
                          {userDetails && userDetails.role ? (
                            <span>
                              <span>
                                {userDetails.role.display_name
                                  ? userDetails.role.display_name
                                  : ""}
                              </span>
                              {/* <span className={styles.bold_text}>{(userDetails.license_type.actual_name === 'MANAGER' || userDetails.license_type.actual_name === 'PROFESSIONAL') ? " (Sales)" : " (Non-sales)"}</span> */}
                              <span className={styles.bold_text}>
                                {userDetails.department
                                  ? " (" + userDetails.department.name + ")"
                                  : ""}
                              </span>
                            </span>
                          ) : (
                              ""
                            )}
                        </div>
                      </div>
                      <Divider />
                      <div className={styles.card_body_1}>
                        <div className={styles.contact_info}>Contact info</div>
                        <div className={styles.contact_values}>
                          <div className={styles.email}>
                            <img
                              src={profile_email_icon}
                              className={styles.email_icon}
                            />
                            {userDetails ? userDetails.email : ""}
                          </div>
                          <div>
                            <img
                              src={profile_phone_icon}
                              className={styles.phone_icon}
                            />
                            {userDetails
                              ? (userDetails.countryCode
                                ? "+" +
                                userDetails.countryCode.dialing_code +
                                " "
                                : "") + userDetails.contact
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-8">
                    <div
                      className={
                        "card shadow mb-4 bg-white card-rounded profile_input_style " +
                        styles.profile_card
                      }
                    >
                      <div className={styles.card_body}>
                        <div className={styles.personal_info_section}>
                          <div className={styles.main_label}>Personal Info</div>
                          {!this.state.isEdit ? (
                            <div>
                              <div
                                className={styles.personal_info_firstRow + " row"}
                              >
                                <div className="col-md-4 col-sm-12">
                                  <span className={styles.persnoal_info_label}>
                                    First name:{" "}
                                  </span>
                                  <span className={styles.persnoal_info_value}>
                                    {this.props.userDetails &&
                                      this.props.userDetails.first_name
                                      ? this.props.userDetails.initials + " " + this.props.userDetails.first_name
                                      : " - "}
                                  </span>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                  <span className={styles.persnoal_info_label}>
                                    Middle name:{" "}
                                  </span>
                                  <span className={styles.persnoal_info_value}>
                                    {this.props.userDetails &&
                                      this.props.userDetails.middle_name
                                      ? this.props.userDetails.middle_name
                                      : " - "}
                                  </span>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                  <span className={styles.persnoal_info_label}>
                                    Last name:{" "}
                                  </span>
                                  <span className={styles.persnoal_info_value}>
                                    {this.props.userDetails &&
                                      this.props.userDetails.last_name
                                      ? this.props.userDetails.last_name
                                      : " - "}
                                  </span>
                                </div>
                              </div>
                              <div
                                className={
                                  styles.personal_info_secondRow + " row"
                                }
                              >
                                <div className="col-md-4 col-sm-12">
                                  <span className={styles.persnoal_info_label}>
                                    Title:{" "}
                                  </span>
                                  <span className={styles.persnoal_info_value}>
                                    {this.props.userDetails &&
                                      this.props.userDetails.role &&
                                      this.props.userDetails.role.display_name
                                      ? this.props.userDetails.role.display_name
                                      : " - "}
                                  </span>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                  <span className={styles.persnoal_info_label}>
                                    Department:{" "}
                                  </span>
                                  <span className={styles.persnoal_info_value}>
                                    {this.props.userDetails &&
                                      this.props.userDetails.department
                                      ? this.props.userDetails.department.name
                                      : " - "}
                                  </span>
                                </div>
                              </div>
                              <div className={styles.update_btn_section}>
                                <button
                                  type="button"
                                  className={styles.update_btn}
                                  onClick={this.openUpdateMode}
                                >
                                  Update
                              </button>
                              </div>
                            </div>
                          ) : (
                              <div>
                                <div className="row">
                                  <div className="col-md-4">
                                    <label className={styles.label}>Title</label>
                                    <input
                                      className={styles.inputStyle}
                                      type="text"
                                      name="title"
                                      value={this.state.personFields["title"]}
                                      onChange={this.handlePersonFieldsChange}
                                      autoComplete="off"
                                      disabled
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <label className={styles.label}>
                                      Department
                                </label>
                                    <input
                                      className={styles.inputStyle}
                                      type="text"
                                      name="department"
                                      value={
                                        this.state.personFields["department"].name
                                      }
                                      onChange={this.handlePersonFieldsChange}
                                      autoComplete="off"
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className={styles.form_group + " col-md-3"}>
                                    <label className={styles.label}>
                                      Prefix
                                      <span className={styles.required_field}>
                                        *
                                      </span>
                                    </label>
                                    <select
                                      className={styles.select_box}
                                      autoFocus={true}
                                      onChange={this.handlePersonFieldsChange}
                                      name="initials"
                                      value={this.state.personFields.initials}
                                    >
                                    {/* <option value={this.state.personFields["initials"]}>{this.state.personFields["initials"]}</option> */}
                                      <option value="Mr">Mr.</option>
                                      <option value="Mrs">Mrs.</option>
                                      <option value="Miss">Miss.</option>
                                    </select>
                                    <span className={styles.errorMessage}>
                                      {this.state.personErrors["first_name"]}
                                    </span>
                                  </div>
                                  <div className={styles.form_group + " col-md-3"}>
                                    <label className={styles.label}>
                                      First name
                                  <span className={styles.required_field}>
                                        *
                                  </span>
                                    </label>
                                    <input
                                      className={styles.inputStyle}
                                      type="text"
                                      name="first_name"
                                      value={this.state.personFields["first_name"]}
                                      onChange={this.handlePersonFieldsChange}
                                      autoComplete="off"
                                      //placeholder="Enter first name"
                                      maxLength={20}
                                    />
                                    <span className={styles.errorMessage}>
                                      {this.state.personErrors["first_name"]}
                                    </span>
                                  </div>
                                  <div className={styles.form_group + " col-md-3"}>
                                    <label className={styles.label}>
                                      Middle name
                                    </label>
                                    <input
                                      className={styles.inputStyle}
                                      type="text"
                                      name="middle_name"
                                      value={this.state.personFields["middle_name"]}
                                      onChange={this.handlePersonFieldsChange}
                                      autoComplete="off"
                                      //placeholder="Enter middle name"
                                      maxLength={20}
                                    />
                                    <span className={styles.errorMessage}>
                                      {this.state.personErrors["middle_name"]}
                                    </span>
                                  </div>
                                  <div className={styles.form_group + " col-md-3"}>
                                    <label className={styles.label}>
                                      Last name
                                  <span className={styles.required_field}>
                                        *
                                  </span>
                                    </label>
                                    <input
                                      className={styles.inputStyle}
                                      type="text"
                                      name="last_name"
                                      value={this.state.personFields["last_name"]}
                                      onChange={this.handlePersonFieldsChange}
                                      autoComplete="off"
                                      //placeholder="Enter last name"
                                      maxLength={20}
                                    />
                                    <span className={styles.errorMessage}>
                                      {this.state.personErrors["last_name"]}
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <button
                                    type="button"
                                    className={styles.update_btn}
                                    onClick={() => this.saveUserDetails()}
                                  >
                                    Save
                              </button>
                                </div>
                              </div>
                            )}
                        </div>
                        <Divider />
                        <div className={styles.contact_info_section}>
                          <div className={styles.main_label}>License Info</div>
                          <div className={styles.personal_info_firstRow + " row"}>
                            <div
                              className={
                                "col-md-4 col-sm-12 pr-0 " + styles.overflow_text
                              }
                            >
                              <span className={styles.persnoal_info_label}>
                                Organization:{" "}
                              </span>
                              <span
                                className={styles.persnoal_info_value}
                                data-toggle="tooltip"
                                title={
                                  this.props.userDetails &&
                                    this.props.userDetails.organization &&
                                    this.props.userDetails.organization.length > 15
                                    ? this.props.userDetails.organization
                                    : ""
                                }
                              >
                                {this.props.userDetails &&
                                  this.props.userDetails.organization
                                  ? this.props.userDetails.organization
                                  : " - "}
                              </span>
                            </div>
                            <div className="col-md-4 col-sm-12">
                              <span className={styles.persnoal_info_label}>
                                Type:{" "}
                              </span>
                              <span className={styles.persnoal_info_value}>
                                {this.state.licenseType !== ""
                                  ? this.state.licenseType
                                  : " - "}
                              </span>
                            </div>
                            <div className="col-md-4 col-sm-12">
                              <span className={styles.persnoal_info_label}>
                                Status:{" "}
                              </span>
                              <span className={styles.persnoal_info_value}>
                                {this.state.activeLicense ? (
                                  <span className={styles.status_active}>
                                    Active
                                  </span>
                                ) : (
                                    <span className={styles.status_inactive}>
                                      Inactive
                                    </span>
                                  )}
                              </span>
                            </div>
                          </div>
                          <div
                            className={styles.personal_info_secondRow + " row"}
                          >
                            <div className="col-md-4 col-sm-12">
                              <span className={styles.persnoal_info_label}>
                                Start date:{" "}
                              </span>
                              <span className={styles.persnoal_info_value}>
                                {this.state.startDate !== ""
                                  ? this.state.startDate
                                  : " - "}
                              </span>
                            </div>
                            <div className="col-md-4 col-sm-12">
                              <span className={styles.persnoal_info_label}>
                                End date:{" "}
                              </span>
                              <span className={styles.persnoal_info_value}>
                                {this.state.endDate !== ""
                                  ? this.state.endDate
                                  : " - "}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <CropperDialog
                      cropperDialog={this.state.cropperDialog}
                      handleCloseCropDialog={this.handleCloseCropDialog}
                      cropImage={this.cropImage}
                      src={this.state.src}
                      ratio={true}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userDetails: state.ProfileReducer.userDetails,
    updatedUserDetailsFlag: state.ProfileReducer.updatedUserDetailsFlag,
    loadingData: state.ProfileReducer.loadingData,
    profilePhoto: state.ProfileReducer.profilePhoto,
    removePhoto: state.ProfileReducer.removePhoto,
    errorMessage: state.ProfileReducer.errorMessage,
    errorLoadingUserProfile: state.ProfileReducer.errorLoadingUserProfile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUserDetails: requestBody =>
      dispatch(actions.getUserDetails(requestBody)),
    updatedUserDetails: requestBody =>
      dispatch(actions.updatedUserDetails(requestBody)),
    resetUserDetailsFlag: requestBody =>
      dispatch(actions.resetUserDetailsFlag(requestBody)),
    startLoading: () => dispatch(actions.startLoading()),
    updateProfilePhoto: requestBody =>
      dispatch(actions.updateProfilePhoto(requestBody)),
    removeProfileImage: requestBody =>
      dispatch(actions.removeProfileImage(requestBody)),
    resetErrorMessage: () => dispatch(actions.resetErrorMessage())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileContainer);
