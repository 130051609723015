import * as types from "./types";

const initialState = {
  idcrm_integration: "",
  integrationDetails: {
    "idcrm_integration": "",
    "fk_crm_id": "",
    "fk_crm_version_id": "1",
    "crm_url": "",
    "auth_type": "USER",
    "user_id": "",
    "password": "",
    "client_id": "",
    "secret_key": "",
    "active_YN": "",
    "fk_schdule_id": "",
    "scheduled_time": "",
    "leadx_to_crm_lastsyncdate": "",
    "crm_to_leadx_lastsyncdate": "",
    "system_user_token":""
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SETIDCRM_INTEGRATION:
      //console.log("in case", action);
      return {
        ...state,
        idcrm_integration: action.payload.idcrm_integration
      }
    case types.SET_INTEGRATION_DETAILS:
      //console.log("in case", action);
      return {
        ...state,
        integrationDetails: action.payload.integrationDetails
      }
    case types.SET_IDCRM_MASTER:
      //console.log("in case", action);
      return {
        ...state,
        idcrm_master: action.payload.idcrm_master
      }
    case types.SET_CRM_NAME:
      //console.log("in case", action);
      return {
        ...state,
        crm_name: action.payload.crm_name
      }
    default:
      return state;
  }
};

export default reducer;
