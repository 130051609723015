export const INITIALIZE_SALES_NEWS = 'INITIALIZE_SALES_NEWS';
export const INITIALIZE_SALES_NEWS_SUCCESS = 'INITIALIZE_SALES_NEWS_SUCCESS';
export const INITIALIZE_SALES_NEWS_FAIL = 'INITIALIZE_SALES_NEWS_FAIL';
export const INITIALIZE_PUBLISH_NEWS_SUCCESS = "INITIALIZE_PUBLISH_NEWS_SUCCESS"
export const LOAD_MORE_SALES_NEWS_SUCCESS = 'LOAD_MORE_SALES_NEWS_SUCCESS';
export const SALES_NEWS_SUCCESS = "SALES_NEWS_SUCCESS";
export const SALES_NEWS_ERROR = "SALES_NEWS_ERROR";
export const SALES_NEWS_LOADING = "SALES_NEWS_LOADING";
export const INITIALIZE_IN_DRAFT_SALES_NEWS_SUCCESS = "INITIALIZE_IN_DRAFT_SALES_NEWS_SUCCESS";
export const INITIALIZE_IN_DRAFT_SALES_NEWS_FAIL = "INITIALIZE_IN_DRAFT_SALES_NEWS_FAIL";
export const LOAD_MORE_IN_DRAFT_SALES_NEWS_SUCCESS = "LOAD_MORE_IN_DRAFT_SALES_NEWS_SUCCESS";

export const LOAD_MORE_PUBLISHED_NEWS_SUCCESS ="LOAD_MORE_PUBLISHED_NEWS_SUCCESS";
export const INITIALIZE_PUBLISHED_NEWS_FAIL ="INITIALIZE_PUBLISHED_NEWS_FAIL";
export const INITIALIZE_ACTIVE_TAB = "INITIALIZE_ACTIVE_TAB";
export const RESET_ACTIVE_INDEX = "RESET_ACTIVE_INDEX";
export const SET_SELECTED_ORGANIZATION = " SET_SELECTED_ORGANIZATION"