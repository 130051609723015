import * as types from "./types";

const initialState = {
  filterValue: [],
  parkedSelected: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.NEW_SELECTED:
      return {
        ...state,
        filterValue: [...state.filterValue, 1],
        parkedSelected: false
      };

    case types.LEAD_SELECTED:
      return {
        ...state,
        filterValue: [...state.filterValue, 2],
        parkedSelected: false
      };

    case types.OPPORTUNITY_SELECTED:
      return {
        ...state,
        filterValue: [...state.filterValue, 3],
        parkedSelected: false
      };

    case types.PROPOSAL_SELECTED:
      return {
        ...state,
        filterValue: [...state.filterValue, 4],
        parkedSelected: false
      };

    case types.NEGOTIATION_SELECTED:
      return {
        ...state,
        filterValue: [...state.filterValue, 5],
        parkedSelected: false
      };

    case types.CLOSED_SELECTED:
      return {
        ...state,
        filterValue: [...state.filterValue, 6],
        parkedSelected: false
      };

    case types.RESET_FILTER:
      return {
        ...state,
        filterValue: [],
        parkedSelected: false
      };

    case types.NEW_REMOVED:
      const filterValue1 = [...state.filterValue];
      filterValue1.splice(filterValue1.indexOf(1), 1);
      return {
        ...state,
        filterValue: filterValue1,
        parkedSelected: false
      };

    case types.LEAD_REMOVED:
      const filterValue2 = [...state.filterValue];
      filterValue2.splice(filterValue2.indexOf(2), 1);
      return {
        ...state,
        filterValue: filterValue2,
        parkedSelected: false
      };

    case types.OPPORTUNITY_REMOVED:
      const filterValue3 = [...state.filterValue];
      filterValue3.splice(filterValue3.indexOf(3), 1);
      return {
        ...state,
        filterValue: filterValue3,
        parkedSelected: false
      };

    case types.PROPOSAL_REMOVED:
      const filterValue4 = [...state.filterValue];
      filterValue4.splice(filterValue4.indexOf(4), 1);
      return {
        ...state,
        filterValue: filterValue4,
        parkedSelected: false
      };

    case types.NEGOTIATION_REMOVED:
      const filterValue5 = [...state.filterValue];
      filterValue5.splice(filterValue5.indexOf(5), 1);
      return {
        ...state,
        filterValue: filterValue5,
        parkedSelected: false
      };

    case types.CLOSED_REMOVED:
      const filterValue6 = [...state.filterValue];
      filterValue6.splice(filterValue6.indexOf(6), 1);
      return {
        ...state,
        filterValue: filterValue6,
        parkedSelected: false
      };

    case types.PARKED_FILTER_SELECTED:
      return {
        ...state,
        parkedSelected: true
      };

    case types.PARKED_FILTER_DESELECTED:
      return {
        ...state,
        parkedSelected: false
      };

    default:
      return state;
  }
};

export default reducer;
