import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import APIs from '../apis';
import styles from '../data_export.module.scss';
import { Button } from '@material-ui/core';
import MyDateRangePicker from '../../../components/DateRangePicker';
import SearchInput from '../../../components/SearchInput';

function getDefaultDateRange () {
    return {
        from_date: moment().startOf('year'),
        to_date: moment()
    };
}


const AdvanceSearch = ({ onSearch }) => {
    const [orgs, setOrgs] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState(null);
    const [dateRange, setDateRange] = useState(getDefaultDateRange());
    const [searchText, setSearchText] = useState('');
    const [searched, setSearched] = useState(false);

    useEffect(() => {
        fetchOrganizations();
    }, []);
    
    const fetchOrganizations = async () => {
        const result = await APIs.getOrganizations();
        setOrgs(result);
        const myAccount = result.find(o => o.self);
        setSelectedOrg(myAccount.value);
        onSearch({
            search_key: searchText,
            account_id: myAccount.value,
            from_date: dateRange.from_date,
            to_date: dateRange.to_date
        });
    };

    const renderOptions = (opt, index, disabled) => {
        return (
            <option selected={opt.self} disabled={disabled} value={opt.value} key={"group_data_" + index}>
                {opt.label}
            </option>
        );
    };

    const onOrgChange = (e) => setSelectedOrg(e.target.value);

    const handleOnSearchClick = () => {
        if (searched) {
            const searchText = '';
            const myAccount = orgs.find(o => o.self);
            setSelectedOrg(myAccount.value);
            const dateRange = getDefaultDateRange();
            setDateRange(dateRange);
            setSearchText(searchText);
            setSearched(false);
            onSearch({
                search_key: searchText,
                account_id: myAccount.value,
                from_date: dateRange.from_date,
                to_date: dateRange.to_date
            });
        } else {
            onSearch({
                search_key: searchText,
                account_id: selectedOrg,
                from_date: dateRange.from_date,
                to_date: dateRange.to_date
            });
            setSearched(true);
        }
    }

    const parents = orgs.filter(o => o.is_parent);
    const childs = orgs.filter(o => !o.is_parent);

    return (
        <>
            <div className="row px-2">
                <div className="col-12 py-3" style={{ backgroundColor: '#F2F2F4' }}>
                    <div className="row">
                        <div className="pl-4 pr-2 my-auto">
                            <span style={{ color: '#81808B' }} className="font-weight-bold">
                                Advance Search
                      </span>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-3">
                            <b style={{ color: '#81808B' }}>Select Organization</b>
                            <select
                                name="assignedOrg_id"
                                onChange={onOrgChange}
                                className={styles.selectCompany}
                                value={selectedOrg}
                            >
                                <option hidden>Select Organization</option>
                                {parents && parents.length > 0 && (
                                    <optgroup
                                        label="Parent Org"
                                        key={"group_parent"}
                                    >
                                        {parents.map((p, i) => renderOptions(p, i, !p.self))}
                                    </optgroup>
                                )}
                                {childs && childs.length > 0 && (
                                    <optgroup label="Child Org" key={"group_child"}>
                                        {childs.map((c, i) => renderOptions(c, i))}
                                    </optgroup>
                                )}
                            </select>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-3 px-0">
                            <br />
                            <MyDateRangePicker className="form-control" onDateChange={(dateRange) => setDateRange(dateRange)} dateRange={dateRange} />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-3">
                            <br />
                            <SearchInput value={searchText} placeholder="Search by keyword" onSearch={(key) => setSearchText(key)} />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg my-auto">
                            <br />
                            <Button onClick={handleOnSearchClick} variant="contained" size="small" className="px-3"
                                style={{ backgroundColor: '#3A4860', color: 'white', textTransform: 'capitalize' }}>
                                {searched ? 'Clear Search' :  'Search'}
                          </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdvanceSearch;