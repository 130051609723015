import React, { useEffect, useState, useRef } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import styled from "styled-components";
import styles from "./user_managment.module.scss";
import API from "./api";
import LargeScreenLoader from "../../common/Loaders/PulseLoader/pulse-loader";
import _ from "lodash";
import CustomTable from "./Table";
import TableRow from "@material-ui/core/TableRow";
import MDTableCell from "@material-ui/core/TableCell";
import InviteUser from "../../common/InviteUser";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import { isParentOrgAdmin } from "../../common/common-functions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import useOutsideClick from "./useOutsideClickHook";

const HeaderLink = styled.span`
  font-weight: 620 !important;
  font-size: 1em !important;
`;

const TableCell = styled(MDTableCell)`
  padding: 10px;
`;

const SmallProfilePic = styled.img`
  width: 2.8125em;
  height: 2.8125em;
  border-radius: 1.5625em;
  margin-right: 0.9375em;
`;

const SearchInputContainer = styled.div`
  border: 0.0625em solid #e3e3e3;
  border-radius: 0.125em;
  padding: 0.3125em;
  padding-left: 0.625em;
  text-align: right;
  width: 21.875em;
  float: right;
`;

const Span = styled.span`
  font-size: 0.875em !important;
`;

const UnderLinedText = styled(Span)`
  text-decoration: underline;
`;

const SearchInput = (props) => {
  const [searchText, setSearchText] = useState(props.initialValue);
  const { onSearch, ...rest } = props;
  const searchRef = useRef(null);

  const handleOnIconClick = () => {
    props.onSearch(searchRef.current.value);
  };

  const handleOnChange = (e) => {
    setSearchText(e.target.value);
    props.onSearch(e.target.value);
  };

  return (
    <SearchInputContainer>
      <div style={{ display: "flex", fontStyle: "italic" }}>
        <Input
          value={searchText}
          {...rest}
          ref={searchRef}
          onKeyUp={handleOnChange}
          onChange={handleOnChange}
          type="search"
          fullWidth={true}
          disableUnderline={true}
          autoFocus={searchText}
        />
        {!searchText && (
          <span style={{ display: "flex" }}>
            <img
              style={{ width: 20 }}
              src={require("../../../assets/images/administration/Rounded.svg")}
            />
          </span>
        )}
      </div>
    </SearchInputContainer>
  );
};

const headers = [
  {
    id: "first_name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "gender",
    numeric: false,
    disablePadding: true,
    label: "Gender",
    sort: true,
  },
  {
    id: "contact",
    numeric: false,
    disablePadding: true,
    label: "Phone No",
    sort: true,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
    sort: true,
  },
  {
    id: "role_assigend",
    numeric: false,
    disablePadding: true,
    label: "Role Assigned",
    sort: true,
  },
  {
    id: "organization",
    numeric: false,
    disablePadding: true,
    label: "Organization",
    sort: true,
  },
  {
    id: "is_active",
    numeric: false,
    disablePadding: true,
    label: "Status",
    sort: true,
  },
];

function MoreOrg({ orgs, id }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const ref = useRef(null);

  useOutsideClick(ref, () => {
    setAnchorEl(null);
  });

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <>
      <Span
        style={{
          height: 20,
          width: 20,
          borderRadius: 10,
          backgroundColor: anchorEl ? "#1BB77D" : "#232F44",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={handleClick}
        ref={ref}
      >
        <ExpandMoreIcon style={{ color: "white" }} />
      </Span>
      <Popper
        id={id}
        open={!!anchorEl}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
      >
        <Paper style={{ padding: 10, minWidth: 220 }}>
          {orgs.map((org) => (
            <div style={{ marginBottom: 5, color: "#6C6C77" }}>{org}</div>
          ))}
        </Paper>
      </Popper>
    </>
  );
}

export default function UserManagement({ history }) {
  const [userListResult, setUserListResult] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("first_name");
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [pendingUserCount, setPendingUserCount] = useState(0);
  const [organizationName, setOrganizationName] = useState("");

  useEffect(() => {
    // console.log(page, rowsPerPage, 'Call to server', searchText, orderBy, order);
    fetchUserList();
  }, [page, rowsPerPage, searchText, order, orderBy]);

  useEffect(() => {
    fetchPendingUserNotification();
  }, []);

  const fetchUserList = async () => {
    setIsLoading(true);
    const result = await API.getUserData(
      page,
      rowsPerPage,
      order,
      orderBy,
      searchText
    );
    setUserListResult(result.data);
    setIsLoading(false);
  };

  const fetchPendingUserNotification = async () => {
    setIsLoading(true);
    const result = await Promise.all([
      API.getPendingUserNotification(),
      API.getMyAccount(),
    ]);
    setPendingUserCount(result[0]);
    setOrganizationName(result[1]);
    setIsLoading(false);
  };

  const handleOnSearch = (searchString) => {
    if (searchString.length >= 3) {
      console.log(searchString, "Search String");
      setSearchText(searchString);
      setPage(0);
    } else if (searchString.length === 0 && searchText.length >= 3) {
      console.log(searchString, "Search String On Clear");
      setSearchText(searchString);
      setPage(0);
    }
  };

  const deboucedHandleOnSearch = _.debounce(handleOnSearch, 300);

  const handleUserRedirection = (user_id) => () => {
    history.push(`/administration/user_management/user_profile/${user_id}`);
  };

  const renderUserRecord = (row, i) => {
    const userProfilePic =
      row.user_avatar ||
      require("../../../assets/images/bi/employeeMissing.png");
    return (
      <TableRow
        key={row.id}
        style={{ opacity: row.is_active ? "100%" : "50%" }}
      >
        <TableCell style={{ textAlign: "left" }}>
          <Span
            onClick={handleUserRedirection(row.id)}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <SmallProfilePic src={userProfilePic} />
            <UnderLinedText className="ml-3">{row.name}</UnderLinedText>
          </Span>
        </TableCell>
        <TableCell>
          <Span style={{ textTransform: "capitalize" }}>{row.gender}</Span>
        </TableCell>
        <TableCell style={{ textAlign: "left", minWidth: 110 }}>
          <Span>
            {row.countryCode ? `+${row.countryCode.dialing_code}` : ""}{" "}
            {row.contact}
          </Span>
        </TableCell>
        <TableCell style={{ textAlign: "left" }}>
          <Span className="ml-3">{row.email}</Span>
        </TableCell>
        <TableCell style={{ textAlign: "left" }}>
          <Span className="ml-1">
            {row.license_type && row.license_type.display_name}
          </Span>
        </TableCell>
        <TableCell style={{ textAlign: "left" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Span className="ml-2">{row.account && row.account.name}</Span>
            {row.AccountAccesses && row.AccountAccesses.length > 1 && (
              <MoreOrg
                open={i === 0}
                id={row.id}
                orgs={row.AccountAccesses.map((a) => a.account.name)}
              />
            )}
          </div>
        </TableCell>
        <TableCell style={{ textAlign: "left" }}>
          <Span className="ml-2">
            {row.is_active === true ? "Active" : "Inactive"}
          </Span>
        </TableCell>
      </TableRow>
    );
  };

  const handleOnOrderChange = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(0);
  };

  const renderPage = () => (
    <>
      <div className="main-wrapper">
        <div className="breadcrumbs">
          <div className="row justify-content-between">
            <div className="col-md-4">
              <h3 className={styles.title_Administration}>User Management</h3>
              <div className="breadcrumbs_container">
                <Breadcrumbs separator="›" arial-label="Breadcrumb">
                  <Link to="/sales-news">Home</Link>
                  <Link to="/administration">Administration</Link>
                  <span>User Management</span>
                </Breadcrumbs>
              </div>
            </div>
            <div className="col-md text-right">
              <label className="font-weight-bold pointer">
                {organizationName}
              </label>
              <span className="mx-2"></span>|<span className="mx-2"></span>
              <Link to="/administration/user_management/pending_request">
                <label
                  className="font-weight-bold pointer"
                  style={{ color: "#202021" }}
                >
                  Pending Requests
                </label>
                <span className={styles.custombadge}>{pendingUserCount}</span>
              </Link>
              <span className="mx-2"></span>|<span className="mx-2"></span>
              <InviteUser />
              <span className="mx-2"></span>
              <Button
                onClick={() =>
                  history.push("/administration/user_management/bulk_upload")
                }
                variant="contained"
                size="medium"
                className={styles.header_btn}
              >
                Bulk Upload
              </Button>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className={"col-md-6 " + styles.request_title}>
            <HeaderLink>
              Organization User List ({userListResult && userListResult.count})
            </HeaderLink>
          </div>
          <div className={"col-md-6 "}>
            <div className="row">
              {isParentOrgAdmin() ? (
                <>
                  <div className="col-md-6" style={{ textAlign: "right" }}>
                    <Button
                      onClick={() =>
                        history.push(
                          "/administration/user_management/manage_access"
                        )
                      }
                      variant="contained"
                      size="medium"
                      className={styles.header_btn}
                      style={{ marginRight: 20 }}
                    >
                      Manage Org. Access
                    </Button>
                  </div>
                  <div className={"col-md-6 " + styles.search_box}>
                    <SearchInput
                      onSearch={deboucedHandleOnSearch}
                      placeholder="Search By Email or Organization"
                      initialValue={searchText}
                    />
                  </div>
                </>
              ) : (
                <div className={"col-md-12 " + styles.search_box}>
                  <SearchInput
                    onSearch={deboucedHandleOnSearch}
                    placeholder="Search By Email or Organization"
                    initialValue={searchText}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <hr />
        <div id="pendingusers" style={{ overflow: "overlay" }}>
          <CustomTable
            headers={headers}
            count={userListResult && userListResult.count}
            data={userListResult && userListResult.rows}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(newRowsPerPage) =>
              setRowsPerPage(newRowsPerPage)
            }
            onPageChange={(newPage) => setPage(newPage)}
            initialSortField="name"
            page={page}
            renderRow={renderUserRecord}
            onOrderChange={handleOnOrderChange}
            order={order}
            orderBy={orderBy}
          />
        </div>
      </div>
    </>
  );

  return <> {isLoading ? <LargeScreenLoader /> : renderPage()} </>;
}
