import * as types from "./types";

import axios from "../../../common/api-config";

export const initializeSalesNews = (requestBody, cb) => {

  return dispatch => {
    // fetch("http://192.168.1.227:8888/api/salesNews/get", {
    //   method: 'POST',
    //   body: JSON.stringify(requestBody),
    //   credentials: 'include',
    // })
    
    axios.post("/salesNews/get", requestBody)
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch(
            intializeSalesNewsSuccess(
              response.data.data.rows,
              response.data.data.all_publish_count,
              response.data.data.self_publish_count,
              response.data.data.self_draft_count
            )
          );
          cb && cb(null, true);
        } else {
          dispatch(intializeSalesNewsFail());
          cb && cb(true);
        }
      })
      .catch(error => {
        dispatch(intializeSalesNewsFail());
        cb && cb(true);
      });
  };
};




export const initializePublishNews = requestBody => {

  return dispatch => {
   
    axios.post("/salesNews/get", requestBody)
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch(
            intializePublishNewsSuccess(
              response.data.data.rows,
              response.data.data.all_publish_count,
              response.data.data.self_publish_count,
              response.data.data.self_draft_count,
            )
          );
        } else {
          dispatch(intializeSalesNewsFail());
        }
      })
      .catch(error => {
        dispatch(intializeSalesNewsFail());
      });
  };
};

export const intializePublishNewsSuccess = (data, totalRecords,totalPublishedNews,inDraftNewsTotalRecords) => {

  return {
    type: types.INITIALIZE_PUBLISH_NEWS_SUCCESS,
    data: data,
    totalRecords: totalRecords,
    totalPublishedNews: totalPublishedNews,
    inDraftNewsTotalRecords: inDraftNewsTotalRecords
  };
};


export const setSelectedOrganization = (data)=> {
  return {
    type : types.SET_SELECTED_ORGANIZATION,
    data: data
  }
}


export const intializeSalesNewsSuccess = (data, totalRecords,totalPublishedNews,inDraftNewsTotalRecords) => {
  return {
    type: types.INITIALIZE_SALES_NEWS_SUCCESS,
    data: data,
    totalRecords: totalRecords,
    totalPublishedNews: totalPublishedNews,
    inDraftNewsTotalRecords: inDraftNewsTotalRecords
  };
};


export const intializeSalesNewsFail = () => {
  return {
    type: types.INITIALIZE_SALES_NEWS_FAIL
  };
};

export const loadMoreSalesNews = (requestBody, callback) => {
  return dispatch => {
    axios
      .post("/salesNews/get", requestBody)
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch(loadMoreSalesNewsSuccess(response.data.data.rows));
        } else {
          dispatch(intializeSalesNewsFail());
        }
        callback && callback(null, true);
      })
      .catch(error => {
        dispatch(intializeSalesNewsFail());
        callback && callback(true, null);
      });
  };
};

export const loadMorePublishedNews = (requestBody, cb) => {
  return dispatch => {
    axios
      .post("/salesNews/get", requestBody)
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch(loadMorePublishedNewsSuccess(response.data.data.rows));
          cb && cb(null, true);
        } else {
          dispatch(intializeSalesNewsFail());
          cb && cb(true);
        }
      })
      .catch(error => {
        dispatch(intializeSalesNewsFail());
        cb && cb(true);
      });
  };
};

const loadMorePublishedNewsSuccess = data => {
  return {
    type: types.LOAD_MORE_PUBLISHED_NEWS_SUCCESS,
    data: data
  };
};

export const intializePublisheNewsFail = () => {
  return {
    type: types.INITIALIZE_PUBLISHED_NEWS_FAIL
  };
};





const loadMoreSalesNewsSuccess = data => {
  return {
    type: types.LOAD_MORE_SALES_NEWS_SUCCESS,
    data: data
  };
};

//sales news actions






export const initializeInDraftSalesNews = requestBody => {
  return dispatch => {
     
    axios.post("/salesNews/get", requestBody)
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch( {

            type: types.INITIALIZE_IN_DRAFT_SALES_NEWS_SUCCESS,
            data: response.data.data.rows,
            totalRecords: response.data.data.all_publish_count,
            totalPublishedNews: response.data.data.self_publish_count,
            inDraftNewsTotalRecords: response.data.data.self_draft_count
          });
        } else {
          dispatch({
            type: types.INITIALIZE_IN_DRAFT_SALES_NEWS_FAIL
            
          });
        }
      })
      .catch(error => {
       
        dispatch({
          type: types.INITIALIZE_IN_DRAFT_SALES_NEWS_FAIL
        });

      });
  };
};


export const loadMoreInDraftSalesNews = (requestBody, cb) => {
  return dispatch => {
     
    axios.post("/salesNews/get", requestBody)
      .then(response => {
        
        if (response.data.statusCode === 200) {
          dispatch( {
            type: types.LOAD_MORE_IN_DRAFT_SALES_NEWS_SUCCESS,
            data: response.data.data.rows,
            totalRecords: response.data.data.count
          });
          cb && cb(null, true)
        } else {
          dispatch({
            type: types.INITIALIZE_IN_DRAFT_SALES_NEWS_FAIL
            
          });
          cb && cb(true)
        }
      })
      .catch(error => {
       
        dispatch({
          type: types.INITIALIZE_IN_DRAFT_SALES_NEWS_FAIL
        });
        cb && cb(true)
      });
  };
};















export const saveToDrafts = requestBody => {
  return dispatch => {
    dispatch({ type: 'SALES_NEWS_LOADING',snackbarStatus:""})
    //dispatch({ type: 'INITIALIZE_ACTIVE_TAB',activeIndex:2})
    axios.post("/salesNews/add", requestBody)
      .then(res => {
        if (res.data.statusCode === 200) {
          dispatch({ type: 'SALES_NEWS_SUCCESS', callFlag: 1 ,snackbarStatus:"News saved as draft successfully!" });
          dispatch({ type: 'INITIALIZE_ACTIVE_TAB',activeIndex:2})
        } else if (res.data.statusCode === 501) {
          dispatch({ type: 'SALES_NEWS_ERROR', callFlag: 0,snackbarStatus:"Image type is not acceptable." })
        } else {
          dispatch({ type: 'SALES_NEWS_ERROR', callFlag: 0,snackbarStatus:"Unable to save news in draft." })
        }
      })
      .catch(error => dispatch({ type: 'SALES_NEWS_ERROR', callFlag: 0,snackbarStatus:"Unable to save news in draft." }))
  }
}


export const publishSalesNews = requestBody => {
  return dispatch => {
    dispatch({ type: 'SALES_NEWS_LOADING'});
    //dispatch({ type: 'INITIALIZE_ACTIVE_TAB',activeIndex:1});
    axios.post("/salesNews/add", requestBody)
      .then(res => {
        if (res.data.statusCode === 200) {
          dispatch({ type: 'SALES_NEWS_SUCCESS', callFlag: 1, snackbarStatus:"News published successfully!" });
          dispatch({ type: 'INITIALIZE_ACTIVE_TAB',activeIndex:1});
        } else if (res.data.statusCode === 501) {
          dispatch({ type: 'SALES_NEWS_ERROR', callFlag: 0,snackbarStatus:"Image type is not acceptable." })
        } else {
          dispatch({ type: 'SALES_NEWS_ERROR', callFlag: 0,snackbarStatus:"Unable to publish news." })
        }
      })
      .catch(error => dispatch({ type: 'SALES_NEWS_ERROR', callFlag: 0,snackbarStatus:"Unable to publish news." }))
  }
}



export const updatePublishNews = requestBody => {
  return dispatch => {
    dispatch({ type: 'SALES_NEWS_LOADING'})
    axios.put("/salesNews/update", requestBody)
      .then(res => {
        if (res.data.statusCode === 200) {
          if (requestBody.unpublish_from_publish) {
            dispatch({ type: 'SALES_NEWS_SUCCESS', callFlag: 1, snackbarStatus:"News unpublished successfully!" });
            dispatch({ type: 'INITIALIZE_ACTIVE_TAB',activeIndex:2});
          } else if (requestBody.draft_from_publish) { 
            dispatch({ type: 'SALES_NEWS_SUCCESS', callFlag: 1, snackbarStatus:"News saved as draft successfully!" });
            dispatch({ type: 'INITIALIZE_ACTIVE_TAB',activeIndex:2});
          } else if (requestBody.publish_from_draft) {
            dispatch({ type: 'SALES_NEWS_SUCCESS', callFlag: 1, snackbarStatus:"News published successfully!" });
            dispatch({ type: 'INITIALIZE_ACTIVE_TAB',activeIndex:1});
          } else if (requestBody.update) {
            dispatch({ type: 'SALES_NEWS_SUCCESS', callFlag: 1, snackbarStatus:"News updated successfully!" });
          } else {
            dispatch({ type: 'SALES_NEWS_SUCCESS', callFlag: 1, snackbarStatus:(requestBody.is_active==1)?"News published successfully!":"News updated successfully!" });
          }
        } else if (res.data.statusCode === 501) {
          dispatch({ type: 'SALES_NEWS_ERROR', callFlag: 0,snackbarStatus:"Image type is not acceptable." })
        } else {
          dispatch({ type: 'SALES_NEWS_ERROR', callFlag: 0,snackbarStatus:"Unable to update records." })  
        }
      })
      .catch(error => {
        dispatch({ type: 'SALES_NEWS_ERROR', callFlag: 0,snackbarStatus:"Unable to update records." })
      })
  }
}

export const deleteSalesNews = requestBody => {
  
  return dispatch => {
    dispatch({ type: 'SALES_NEWS_LOADING'})
    axios.delete("/salesNews/delete",{ data:requestBody })
      .then(res => {
        if (res.data.statusCode === 200) {
          dispatch({ type: 'SALES_NEWS_SUCCESS', callFlag: 1, snackbarStatus:"News deleted successfully!" })
        } else {
          dispatch({ type: 'SALES_NEWS_ERROR', callFlag: 0, snackbarStatus:"Unable to delete." })  
        }
      })
      .catch(error => {
        dispatch({ type: 'SALES_NEWS_ERROR', callFlag: 0, snackbarStatus:"Unable to delete." })
      })
  }
}

export const initializeActiveTab = requestBody =>{

  return dispatch=> {
    dispatch({ type: 'SALES_NEWS_LOADING'})
    dispatch({ type: 'INITIALIZE_ACTIVE_TAB', activeIndex:requestBody})
  }
}

export const resetActiveIndex = () => {
  return dispatch => {dispatch({ type: 'RESET_ACTIVE_INDEX'})}
}

export default {
  initializeSalesNews,
  loadMoreSalesNews
};
