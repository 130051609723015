import React from 'react';
import { Button } from '@material-ui/core';

export default function Tabs({onTabChange, currentTab}) {
  const onTabClick = (key) => () => onTabChange(key);
  
  return (
      <div className="row my-3">
          <div className="col-md-12 col-12">
              <Button variant="contained" size="small" className="px-3 font-weight-bold tabButton"
                  style={{
                      backgroundColor: currentTab == 'lead' ? '#5A626E' : '#F2F2F4',
                      color: currentTab == 'lead' ? '#fff' : '#5A626E',
                      textTransform: 'capitalize'
                  }} onClick={onTabClick('lead')}>
                  Lead Details
              </Button>
              <Button variant="contained" size="small" className="px-3 mx-2 font-weight-bold"
                  style={{
                      backgroundColor: currentTab == 'contact' ? '#5A626E' : '#F2F2F4',
                      color: currentTab == 'contact' ? '#fff' : '#5A626E',
                      textTransform: 'capitalize'
                  }} onClick={onTabClick('contact')}>
                  Contacts
              </Button>
              <Button variant="contained" size="small" className="px-3 font-weight-bold"
                  style={{
                      backgroundColor: currentTab == 'company' ? '#5A626E' : '#F2F2F4',
                      color: currentTab == 'company' ? '#fff' : '#5A626E',
                      textTransform: 'capitalize'
                  }} onClick={onTabClick('company')}>
                  Company
              </Button>
              <hr className="py-0 my-0" />
          </div>
      </div>
  );
}