import React, { useState } from "react";
import styles from "./apiRegistration.module.scss";
import {
  InputLabel,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Dialog,
  Grid,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import APIs from "./apis";
import { openSnackbar } from "../../common/Snackbar/snackbar";
import copy from "copy-to-clipboard";
import _ from "lodash";
import InputAdornment from "@material-ui/core/InputAdornment";

export default function CreateApp({ open, onClose, onCreate }) {
  const [appName, setAppName] = useState("");
  const [generated, setGenerated] = useState(false);
  const [creds, setCreds] = useState({});
  const [showSecretKey, setShowSecretKey] = useState(false);

  const handleClose = () => {
    setAppName("");
    setGenerated(false);
    onClose();
  };

  const handleOnGenerate = async () => {
    const result = await APIs.createApp(appName);
    console.log(result, "Result");

    if (result.status) {
      openSnackbar(result.message, "snack-success");
      setCreds(result.data.save_result.data);
      setGenerated(true);
      onCreate(result.data.save_result.data);
    } else {
      openSnackbar(result.message, "snack-error");
    }
  };

  const handleVisibility = () => {
    setShowSecretKey(!showSecretKey);
  };

  const handleCopyOnClick = (text, message) => () => {
    copy(text);
    openSnackbar(message, "snack-success");
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" className={styles.MuiTypography}>
        Register Application
        <CloseIcon
          aria-label="close"
          className={styles.closeButton}
          onClick={handleClose}
        />
        <hr />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <form className={styles.formControl} noValidate autoComplete="off">
            <InputLabel htmlFor="my-input">Application Name</InputLabel>
            <Grid
              className={styles.gridControl}
              container
              direction="row"
              spacing="4"
            >
              <Grid item>
                <TextField
                  variant="outlined"
                  value={appName}
                  onChange={(e) => setAppName(e.target.value)}
                />
              </Grid>
              {!generated && (
                <Grid item>
                  <Button
                    className={styles.gridControlBtn}
                    variant="contained"
                    size="large"
                    disabled={!appName && !generated}
                    onClick={handleOnGenerate}
                  >
                    Generate
                  </Button>
                </Grid>
              )}
            </Grid>
          </form>
        </DialogContentText>
        {generated && (
          <>
            {" "}
            <DialogContentText>
              <form
                className={styles.formControl}
                noValidate
                autoComplete="off"
              >
                <InputLabel htmlFor="my-input">Client Id</InputLabel>
                <Grid
                  className={styles.gridControl}
                  container
                  direction="row"
                  spacing="4"
                >
                  <Grid item>
                    <TextField
                      variant="outlined"
                      value={creds.api_key}
                      disabled
                    />
                    <img
                      className={"ml-2 mt-2 " + styles.copyIcon}
                      onClick={handleCopyOnClick(
                        creds.api_key,
                        "API Key copied!!"
                      )}
                      src={require("../../../assets/images/apiregister/copy.svg")}
                      alt={"copy"}
                    />
                  </Grid>
                </Grid>
              </form>
            </DialogContentText>
            <DialogContentText>
              <form
                className={styles.formControl}
                noValidate
                autoComplete="off"
              >
                <InputLabel htmlFor="my-input">Secret Key</InputLabel>
                <Grid
                  className={styles.gridControl}
                  container
                  direction="row"
                  spacing="4"
                >
                  <Grid item>
                    <div className="form-inline">
                      <TextField
                        variant="outlined"
                        value={
                          !showSecretKey
                            ? _.times(creds.secret_key.length, () => "*").join(
                                ""
                              )
                            : creds.secret_key
                        }
                        disabled
                      />
                      <img
                        className={styles.eyeIconOnRegisterApplication}
                        onClick={handleVisibility}
                        src={require("../../../assets/images/apiregister/eye.png")}
                      />
                      <img
                        className={"ml-3 mt-2 " + styles.copyIcon}
                        onClick={handleCopyOnClick(
                          creds.secret_key,
                          "Secret Key copied!!"
                        )}
                        src={require("../../../assets/images/apiregister/copy.svg")}
                        alt={"copy"}
                      />
                    </div>
                  </Grid>
                </Grid>
              </form>
            </DialogContentText>{" "}
          </>
        )}
        <hr />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          style={{
            fontWeight: "bold",
            textTransform: "none",
            color: "#3A4860",
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
