import * as types from "./types";

import axios from "../../../common/api-config";

export const getSalesActivititesTasks = requestBody => {
  return dispatch => {
    dispatch(loadSalesActivitiesTasks());
    axios
      .post("/salesActivity/getActivityTaskList", requestBody)
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch(getSalesActivititesTasksSuccess(response.data.data.rows));
        } else {
          dispatch(getSalesActivititesTasksFail());
        }
      })
      .catch(error => {
        dispatch(getSalesActivititesTasksFail());
      });
  };
};

export const updateTasks = requestBody => {
  return dispatch => {
    axios
      .put("/task/update", requestBody)
      .then(response => {
        if (response.statusCode === 200) {
          console.log("updated successfully");
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
};

const loadSalesActivitiesTasks = () => {
  return {
    type: types.LOAD_SALES_ACTIVITIES_TASKS
  };
};

const getSalesActivititesTasksSuccess = data => {
  return {
    type: types.GET_SALES_ACTIVITIES_TASKS_SUCCESS,
    data: data
  };
};

const getSalesActivititesTasksFail = () => {
  return {
    type: types.GET_SALES_ACTIVITIES_TASKS_FAIL
  };
};

export default {
  getSalesActivititesTasks
};
