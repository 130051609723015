import React, { useEffect, useState, useRef } from "react";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import TableRow from "@material-ui/core/TableRow";
import MDTableCell from "@material-ui/core/TableCell";
import { Link } from "react-router-dom";
import styles from "./childOrganization.module.scss";
import CustomTable from "./Table";
import styled from "styled-components";
import apis from "./apis";
import LargeScreenLoader from "../../common/Loaders/PulseLoader/pulse-loader";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import styles1 from "./../aside-left/component/aside-left.module.scss";
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from '@material-ui/icons/Close';
import MyInput from "../../components/MyInput";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import copy from 'copy-to-clipboard';
import { showSnackbarMessage } from "../../common/common-functions";

const TableCell = styled(MDTableCell)`
  padding-left: 0px;
  text-align: left;
`;

const PrimaryButton = styled(Button)`
  font-size: 0.75em;
  text-transform: none;
  white-space: nowrap;
  text-align: center;
  background-color: #3a4860;
  padding-left: 2em;
  padding-right: 2em;
  color: white;
  height: 4.25em;
  padding: 0 0.5em;
  padding-left: 4em;
  padding-right: 4em;
  &:hover {
    background-color: #19D98B;
  }
`;

const SmallProfilePic = styled.img`
  width: 2.8125em;
  height: 2.8125em;
  border-radius: 1.5625em;
  margin-right: 0.9375em;
`;

const SmalViewProfilePic = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 0.9375em;
  float: right;
  cursor: pointer;
`;

const Seperator = styled.span`
  margin: 0em 1.25em 0em 1.25em;
  color: gray;
`;

const HeaderLink = styled.span`
  font-weight: 620 !important;
  font-size: 1em !important;
`;

const SearchInputContainer = styled.div`
  border: 0.0625em solid #e3e3e3;
  border-radius: 0.125em;
  padding: 0.3125em;
  padding-left: 0.625em;
  text-align: right;
  width: 21.875em;
  float: right;
`;

const Span = styled.span`
  font-size: 0.875em !important;
`;

const UnderLinedText = styled(Span)`
  text-decoration: underline;
  cursor: pointer;
`;

const SearchInput = (props) => {
  const [searchText, setSearchText] = useState("");
  const { onSearch, ...rest } = props;
  const searchRef = useRef(null);
  const handleOnChange = (e) => {
    props.onSearch(e.target.value);
    setSearchText(e.target.value);
  };

  return (
    <SearchInputContainer>
      <div style={{ display: "flex", fontStyle: 'italic' }}>
        <Input
          {...rest}
          ref={searchRef}
          onKeyUp={handleOnChange}
          onChange={(e) => { props.onSearch(e.target.value) }}
          type="search"
          fullWidth={true}
          disableUnderline={true}
        />
        <span style={{ display: 'flex' }}>
          <img
            style={{ width: 20 }}
            src={require("../../../assets/images/administration/Rounded.svg")}
          />
        </span>
      </div>
    </SearchInputContainer>
  );
};

const headers = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Account/Organization Name",
    sort: true,
  },
  {
    id: "gender",
    numeric: false,
    disablePadding: true,
    label: "Address",
    sort: true,
  },
  {
    id: "phone_number",
    numeric: false,
    disablePadding: true,
    label: "Phone No.",
    sort: true,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Base Currency",
    sort: true,
  },
  {
    id: "role_assigned",
    numeric: false,
    disablePadding: true,
    label: "Timezone",
  },

];

const options = [
  //'Select',
  'View',
  'Edit',
  'Registration URL'
];

const ITEM_HEIGHT = 48;

function RegistrationUrlPopup({open, onClose, registationURL, onGenerateNewUrlClick}) {
  const handleCopyOnClick = () => {
    copy(registationURL);
    showSnackbarMessage('URL copied successfully.', 'snack-success');
  }

  return (
    <Dialog
        onClose={onClose} aria-labelledby="customized-dialog-title" open={open}
        fullWidth
    >
      <MuiDialogTitle id="customized-dialog-title" onClose={onClose}>
        <div className="row">
          <div className="col-md-10">
            <div className={styles.popup_title}>Registration Url</div>
          </div>
          <div className="col-md-2">
            <div style={{textAlign: 'right'}}>
              <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>  
          </div>

        </div>
      </MuiDialogTitle>
      <MuiDialogContent dividers>
        <div style={{fontWeight: 'bold'}}>
          Latest URL
        </div>
        <div className="row" style={{marginTop: 15, marginBottom: 15}}>
           <div className="col-md-10" style={{paddingTop: 5}}>
             <MyInput disabled placeholder="Registration URL" value={registationURL} />
           </div> 
           <div className="col-md-2" style={{textAlign: 'center', paddingBottom: 5}}>
            <IconButton aria-label="copy" onClick={handleCopyOnClick}>
              <FileCopyOutlinedIcon />
            </IconButton>
           </div> 
        </div>
        <PrimaryButton onClick={onGenerateNewUrlClick}>
          Generate New URL
        </PrimaryButton>
      </MuiDialogContent>
    </Dialog>
  )
};

let selectedAccountID = null;

export default function ChildOrganizationList(props) {
  const [data, setData] = useState([]);
  const [fdata, setFdata] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [createChildOrg, setCreateChildOrg] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [anchorEls, setAnchorEls] = React.useState([]);
  const [organizationName, setOrganizationName] = useState('');
  const [openRegistrationUrlPopup, setOpenRegistrationUrlPopup] = useState(false);
  const [registationURL, setRegistrationURL] = useState('');
  const open = anchorEls.map(a => Boolean(a));

  const handleClick = (index) => (event) => {
    console.log('Handle CLick');
    console.log(event.currentTarget);
    console.log('====================================');
    setAnchorEls(
      anchorEls.map((_, i) => {
        if (i === index) {
          return event.currentTarget;
        }
        return _;
      })
    );
  };

  const onChangeMenu = (option, id, index) => () => {
    console.log('onChangeMenu', option, id);
    selectedAccountID = id;
    switch (option) {
      case 'View':
        props.history.push(`/administration/child_organization/${id}`);
        break;
      case 'Edit':
        props.history.push(`/administration/child_organization/edit/${id}`);
        break;
      case 'Registration URL':
        setOpenRegistrationUrlPopup(true);
        break;
      default:
        break;  
    }
    handleClose(index)();
  };

  //onChangeMenu
  const handleClose = (index) => () => {
    setAnchorEls(anchorEls.map((_, i) => {
      if (i === index) {
        return null;
      }
      return _;
    }));
  };

  useEffect(() => {
    canCreateChildOrg();
  }, []);

  useEffect(() => {
    fetchChildOrgList();
  }, [searchText]);

  const canCreateChildOrg = async () => {
    const result = await apis.canCreateChildOrg();
    setCreateChildOrg(result.data.canCreate);
  };

  const fetchChildOrgList = async () => {
    setIsLoading(true);
    const result = await Promise.all([apis.getChildOrgListNew(searchText), apis.getMyAccount()]);
    setData(result[0].data);
    setFdata(result[0].data.rows.map(c => ({...c, registationURL: `${c.dns}/register/${c.key}`})));
    setAnchorEls(result[0].data.rows.map(_ => null))
    setOrganizationName(result[1]);
    console.log(result[0].data.rows, "Child Org User List");
    setIsLoading(false);
  };

  const handleOnSearch = (searchString) => {
    const filterRow = data.rows.filter((row) => {
      if (
        row.name
          .toLowerCase()
          .includes(searchString.toLowerCase()) 
      ) {
        return true;
      }
      return false;
    });
    setFdata(filterRow);
  };

  const handleGenerateNewURL = async () => {
    const result = await apis.updateRegistrationUrl(selectedAccountID);
    console.log(result, "RES")
    setFdata(fdata.map(a => {
      if (a.id === selectedAccountID) {
        a.registationURL = result.key;
      }
      return a;
    }))
  }

  return (
    <>
      {isLoading ? (
        <LargeScreenLoader />
      ) : (
          <div className="main-wrapper">

            <div className={"breadcrumbs row " + styles.centerAlign}>
              <div className="col-md-6 col-12">
                <h3 className={styles.title_Administration}>
                  Child Organization
                  </h3>
                <div className="breadcrumbs_container">
                  <Breadcrumbs separator="›" arial-label="Breadcrumb">
                    <Link to="/sales-news">Home</Link>
                    <Link to="/administration">Administration</Link>
                    <span>Child Organization</span>
                  </Breadcrumbs>
                </div>
              </div>
              <div className={`col-md-6 col-12 ${styles.top_container}`}>

                <span className={styles.orgName}>
                  {organizationName}
                </span>

                <span className={styles.separator}>
                  |
                </span>
                {
                  createChildOrg &&
                  <Link to="/administration/new_child_organization">
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    className={
                      styles1.header_btn +
                      " " +
                      styles1.creat_btn +
                      " " +
                      styles1.creat_new_btn +
                      " " +
                      styles.button
                    }
                  >
                    Create Child Organization
                  </Button>
                  </Link>
                }
              </div>
            </div>

            <hr />
            <div className="row">
              <div className={"col-md-6 " + styles.request_title}>
                <HeaderLink>
                  Child Organization List ({data && data.count})
              </HeaderLink>
              </div>
              <div className={"col-md-6 " + styles.search_box}>
                <SearchInput
                  onSearch={handleOnSearch}
                  placeholder="Search By Name"
                />
              </div>
            </div>
            <hr />
            <div id="pendingusers" style={{ overflow: "overlay" }}>
              <CustomTable
                headers={headers}
                data={fdata}
                initialSortField="name"
                renderRow={(row, i) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <Span style={{ display: "flex"}}>
                        <SmallProfilePic
                          src={
                            row.logo ?
                            row.logo.slice(0, row.logo.lastIndexOf('/')) + "/small_" +
                            row.logo.substring(row.logo.lastIndexOf("/") + 1)
                              : require("../../../assets/companyImage.png")}
                        />
                        <UnderLinedText
                        onClick={() => props.history.push(`/administration/child_organization/${row.id}`)} >{row.name}</UnderLinedText>
                      </Span>
                    </TableCell>
                    <TableCell>
                    <Span>{row.address_line_1}</Span>
                    </TableCell>
                    <TableCell style={{ textAlign: "center", minWidth: 110 }}>
                      <Span>{row.AccountPreference && row.AccountPreference.user && row.AccountPreference.user.countryCode ? `+${row.AccountPreference.user.countryCode.dialing_code}` : ''} {row.AccountPreference && row.AccountPreference.user && row.AccountPreference.user.contact}</Span>
                    </TableCell>
                    <TableCell>
                    <Span>{row.AccountPreference.currency.short_name}</Span>
                    </TableCell>
                    <TableCell>
                      <Span>{row.AccountPreference.timezone}</Span>
                      {/* <SmalViewProfilePic
                        src={require("../../../assets/images/child-organization/view.png")}
                      /> */}
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={handleClick(i)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        anchorEl={anchorEls[i]}
                        keepMounted
                        open={Boolean(anchorEls[i])}
                        onClose={handleClose(i)}
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                          },
                        }}
                      >
                        {options.map((option) => (
                          <MenuItem key={option} selected={option === 'View'} onClick={onChangeMenu(option, row.id, i)}>
                            {option}
                          </MenuItem>
                        ))}
                      </Menu>
                    </TableCell>
                  </TableRow>
                )}
              />
            </div>
            <RegistrationUrlPopup 
              open={openRegistrationUrlPopup} 
              onClose={() => setOpenRegistrationUrlPopup(false)} 
              registationURL={selectedAccountID && fdata.find(d => d.id === selectedAccountID).registationURL}
              onGenerateNewUrlClick={handleGenerateNewURL}            
            />
          </div>
        )}
    </>
  );
}
