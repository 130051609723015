import * as types from "./types";
import * as commonTypes from "../../../common/common-types";

const initialState = {
  leads: null,
  leadsCount: 0,
  contactList: null,
  contactCount: 0,
  ringedBell: null,
  unparkLeadFlag: false,
  errorMessage: '',
  isFetching: false,
  errorLoadingMypipelineContacts: false,
  errorLoadingMypipelineLeads: false,
  mypipelineOrgSelected: null,
  mypipelineUserSelected: null,
  contactsLoading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_CONTACT_LIST:
      return {
        ...state,
        contactsLoading: action.data
      }
    case commonTypes.HANDLE_LOADING:
      return{
        ...state,
        isFetching: action.data
      }
    case 'LEAD_LIST_SUCCESS':
      return { 
        ...state, 
        leads: action.data.rows,
        leadsCount: action.data.count,
        errorLoadingMypipelineLeads: false
      };
    case 'LOAD_MORE_LEAD_LIST_SUCCESS':
      let finalArr = [...state.leads, ...action.data.rows];
      return{
        ...state, 
        leads: finalArr,
        leadsCount: action.data.count,
      }
    case types.LEAD_LIST_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        errorLoadingMypipelineLeads: true
      }
    case types.CONTACT_LIST_SUCCESS:
      return{
        ...state,
        contactList: action.data,
        contactCount: action.count,
        errorMessage : '',
        errorLoadingMypipelineContacts: false
      }
    case types.CONTACT_LIST_FAILED:
      return {
        ...state,
        contactList: null,
        contactCount: 0,
        errorMessage: action.errorMessage,
        errorLoadingMypipelineContacts: true
      }
    case 'RING_BELL_SUCCESS':
     return {
       ...state,
       ringedBell : action.data,
       errorMessage : '',
     }
    case 'RING_BELL_ERROR': 
     return {
       ...state,
       ringedBell: null,
       errorMessage: action.errorMessage,
     }
    case 'RESET_RINGED_BELL':
      return{
        ...state,
        ringedBell: null,
        errorMessage: null,
        isFetching: false
      }
    case 'UNPARK_LEAD_SUCCESS':
      return {
        ...state,
        unparkLeadFlag: true,
        errorMessage: null,
        isFetching: false
      }
    case 'UNPARK_LEAD_FAILED':
      return {
        ...state,
        unparkLeadFlag: false,
        errorMessage: null,
        isFetching: false
      }
    case 'RESET_UNPARK_LEAD':
      return {
        ...state,
        unparkLeadFlag: false,
        errorMessage: null,
      }

    case types.SET_ORGANIZATION_MY_PIPELINE : 
      return {
        ...state,
        mypipelineOrgSelected: action.mypipelineOrgSelected
      }


    case types.SET_USER_MY_PIPELINE : 
      return {
        ...state,
        mypipelineUserSelected: action.mypipelineUserSelected
      }

    case types.RESET_ERROR :
      return {
        ...state,
        errorLoadingMypipelineContacts: false,
        errorLoadingMypipelineLeads: false
      }

    default:
      return state;
  }
};

export default reducer;
