import React from 'react';
import './my-pipeline.css';
import LinearProgress from '@material-ui/core/LinearProgress';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Divider from '@material-ui/core/Divider';
import FilterList from '@material-ui/icons/FilterList';
import contactIcon from '../../../../assets/images/content-images/contact_icon_blue.png';
import companyIcon from '../../../../assets/images/content-images/company_pink.png';
import Dialog from '@material-ui/core/Dialog';
import LeadContainer from '../../newLead/LeadContainer';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Contact from "../../newContact/NewContact.container";
import * as commonFunctions from '../../../common/common-functions';
import handoverAcceptedIcon from "../../../../assets/images/leads-icons/handoverAccepted.png";
import handoverNotAcceptedIcon from "../../../../assets/images/leads-icons/handoverNotAcceptedIcon.png";
import crossalesNotAcceptedIcon from "../../../../assets/images/leads-icons/crossalesNotAcceptedIcon.png";
import crossalesAcceptedIcon from "../../../../assets/images/leads-icons/crossalesAcceptedIcon.png";
import handshakeIconGrey from '../../../../assets/images/content-images/beforeHandshake.png';
import handshakeIconOne from '../../../../assets/images/content-images/afterHandshake.png';
import handshakeIconTwo from '../../../../assets/images/content-images/handshakeIconTwo.png';
import doubleHandshakeGreen from "../../../../assets/images/content-images/double-green-handshake_1.png";
import SmallLoader from '../../../common/Loaders/CircularLoader/circular-loader';
import LargeScreenLoader from '../../../common/Loaders/PulseLoader/pulse-loader';
import LeadDetailPopup from '../../LeadDetailPopup/component/lead-detail-popup';
import asideLeftStyles from '../../aside-left/component/aside-left.module.scss';
import Filter from '../../../components/Filter/filter';
import PersonDetailPopup from '../../PersonDetailPopup/component/personDetailPopup';
import CompanyDetailPopup from '../../CompanyDetailPopup/component/companyDetailPopup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import OopsError from '../../../components/ErrorMessages/OopsError/OopsError';
import MultiSelect from "../../../common/MultiSelect/multiSelect";
import SalesNewsContainerStyles from "../../SalesNews/SalesNewsContainer.module.css";
import { contactListRequestBody, getUsersParams } from "../../../common/apiParameters";
import axiosInstance from '../../../common/api-config';

class MyPipeline extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            openLeadModal: false,
            searchText: '',
            loading: true,
            openCompanyDetail: false,
            openPersonDetail: false,
            openEditCompany: false,
            openEditPerson: false,
            companyItem: null,
            personItem: null,
            filterValue: [1, 2, 3, 4, 5, 6], //all lead status
            currentSalesItem: null,
            pageLimit: "10",
            pageIndex: "0",
            showFilters: true,
            openAddActivityTask: false,
            hideFilter: false,
            parkedFlag: false,
            checkFilterFirstTime: false,
            selectedUser: "",  //selected user
            userList: [],   //user listing for selected organization
            menuIsOpen: undefined,
            storeCurrentUser: null
        }

        this.textFocus = React.createRef();
    }

    componentDidMount() {
        this.getUserList(this.props.currentUser.organization_id, "first_time");

        // this.getLeadListByFilter({});
        // this.getContactList();
        // this.getRevenue();        

        if (this.props.orgSelected === null && this.props.organizationList.length >= 1) {
            this.props.selectMypipelineOrg(this.props.organizationList.filter(organization => (organization.id === this.props.currentUser.organization_id)));
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.lead && this.props.leadUpdated && this.props.leadUpdated != prevProps.leadUpdated) {
            //get current filters, and get leads withrespect to filters
            this.getLeadListByFilter({
                lead_current_status_id: this.state.filterValue
            });
        }

        if (this.state.checkFilterFirstTime === false && (prevProps.leads === null && (this.props.leads && this.props.leads.length === 0))) {
            this.setState({
                hideFilter: true,
                checkFilterFirstTime: true
            });
        }

        if ((prevProps.organizationList !== this.props.organizationList) && this.props.organizationList.length > 0) {
            this.props.selectMypipelineOrg(this.props.organizationList.filter(organization => (organization.id === this.props.currentUser.organization_id)));
        }

        // if (prevProps.userSelected !== null && this.props.userSelected !== null && prevProps.userSelected !== this.props.userSelected) {
        //     if (this.state.filterValue && this.state.filterValue.length > 0) {
        //         this.getLeadListByFilter({
        //             lead_current_status_id: this.state.filterValue
        //         });
        //     } else {
        //         this.getLeadListByFilter({});
        //     }

        //     this.getRevenue();
        //     this.getContactList();
        // }
    }

    componentWillUnmount() {
        if (this.props.currentUser) {
            this.props.selectMypipelineOrg(
                this.props.organizationList.filter(organization => (organization.id === this.props.currentUser.organization_id))
            );

            this.props.selectMypipelineUser(this.state.storeCurrentUser);
        }
    }

    componentWillUnmount() {
        this.props.resetError();
    }

    getContactList = () => {
        let organizationArray = null;
        if (this.props.orgSelected === null) {
            organizationArray = [this.props.currentUser.organization_id]
        } else {
            organizationArray = [this.props.orgSelected.id]
        }

        contactListRequestBody.organizationArray = organizationArray
        this.props.getContactList(contactListRequestBody);
    };

    getRevenue = () => {
        let organizationArray = null;
        let current_user_selection = null;
        let licenseType = null;

        if (this.props.orgSelected === null) {
            organizationArray = [this.props.currentUser.organization_id]
        } else {
            organizationArray = [this.props.orgSelected.id]
        }

        if (this.props.userSelected === null) {
            current_user_selection = this.props.currentUser.user_id;
        } else {
            current_user_selection = this.props.userSelected.id;
        }

        if (this.props.userSelected === null) {
            licenseType = this.props.currentUser.license_type.actual_name;
        } else {
            licenseType = this.props.userSelected.license_type && this.props.userSelected.license_type.actual_name;
        }
        let params = {
            "revenueType": "my_pipeline",
            organizationArray: organizationArray,
            user_id: current_user_selection,
            license_type: licenseType



        }
        this.props.getRevenue(params);
    }

    getUserList = async (id, called) => {
        console.log(id, called, "getUserList");
        const params = getUsersParams(id);
        axiosInstance.post('/users/getUserListByAccountId', params)
            .then(async response => {
                if (response.data.statusCode === 200 && response.data.data.rows.length > 0) {
                    if (called === 'first_time') {
                        const selectedUser = response.data.data.rows.filter(user => user.id === this.props.currentUser.user_id);
                        console.log(selectedUser, "Selected User");
                        await this.props.selectMypipelineUser(selectedUser[0]);
                        await this.setState({
                            userList: response.data.data.rows,
                            selectedUser: selectedUser[0].first_name + " " + selectedUser[0].last_name,
                            storeCurrentUser: selectedUser[0]
                        });
                        if (this.state.filterValue && this.state.filterValue.length > 0) {
                            this.getLeadListByFilter({
                                lead_current_status_id: this.state.filterValue
                            });
                        } else {
                            this.getLeadListByFilter({});
                        }

                        this.getRevenue();
                        this.getContactList();
                    } else if (called === 'second_time') {
                        await this.setState({
                            userList: response.data.data.rows
                        });
                    } else {
                        await this.props.selectMypipelineUser(null);
                        await this.setState({
                            userList: response.data.data.rows,
                        });
                    }
                } else {
                    await this.props.selectMypipelineUser(null);
                    await this.setState({
                        userList: [],
                    });
                }
            })
            .catch(err => {
                this.props.selectMypipelineUser(null);
                this.setState({
                    userList: [],
                });
            })
    }

    SearchContact() {
    }

    handleChange(event, key) {
        this.setState({ key: event.target.value });
    }

    //dialog functions
    handleClickOpen(item, index) {
        this.setState({
            open: true,
            currentSalesItem: item
        });

        //remove z-index of header and left menu bar , if dialog is open
        var headerElement = document.getElementsByClassName(asideLeftStyles.appBar);
        if (headerElement && headerElement[0]) { headerElement[0].classList.add("modal-zindex"); }

        var leftNavElement = document.getElementsByClassName(asideLeftStyles.brand);
        if (leftNavElement && leftNavElement[0]) {
            leftNavElement[0].parentNode.classList.add("modal-zindex");
        }
    };

    handleClickCompanyOpen(item, index) {
        if (item.company_name) {
            this.setState({
                openCompanyDetail: true,
                companyItem: item
            });
        } else if (item.name) {
            this.setState({
                openPersonDetail: true,
                personItem: item
            });
        }

        //remove z-index of header and left menu bar , if dialog is open
        var headerElement = document.getElementsByClassName(asideLeftStyles.appBar);
        if (headerElement && headerElement[0]) { headerElement[0].classList.add("modal-zindex"); }

        var leftNavElement = document.getElementsByClassName(asideLeftStyles.brand);
        if (leftNavElement && leftNavElement[0]) {
            leftNavElement[0].parentNode.classList.add("modal-zindex");
        }
    };

    handleClose = () => {
        this.setState({ open: false, openCompanyDetail: false, openPersonDetail: false });
        var headerElement = document.getElementsByClassName(asideLeftStyles.appBar);
        if (headerElement && headerElement[0]) {
            headerElement[0].classList.remove("modal-zindex");
        }

        var leftNavElement = document.getElementsByClassName(asideLeftStyles.brand);
        if (leftNavElement && leftNavElement[0]) {
            leftNavElement[0].parentNode.classList.remove("modal-zindex");
        }
    };

    openUpdateLeadPopup = async (statusLogs) => {
        await this.setState({
            open: false,
            openLeadModal: true,
        });

        var headerElement = document.getElementsByClassName(asideLeftStyles.appBar);
        headerElement[0].classList.add("modal-zindex");

        var leftNavElement = document.getElementsByClassName(asideLeftStyles.brand);
        leftNavElement[0].parentNode.classList.add("modal-zindex");
    }

    handleWinCallBack = (currentSalesItem) => {
        var headerElement = document.getElementsByClassName(asideLeftStyles.appBar);
        headerElement[0].classList.add("modal-zindex");

        var leftNavElement = document.getElementsByClassName(asideLeftStyles.brand);
        leftNavElement[0].parentNode.classList.add("modal-zindex");

        this.setState({
            open: true,
            openLeadModal: false,
            currentSalesItem
        })
    }

    openLeadDetailCallBack = (currentSalesItem) => {
        var headerElement = document.getElementsByClassName(asideLeftStyles.appBar);
        headerElement[0].classList.add("modal-zindex");

        var leftNavElement = document.getElementsByClassName(asideLeftStyles.brand);
        leftNavElement[0].parentNode.classList.add("modal-zindex");

        this.setState({
            open: true,
            openLeadModal: false,
            currentSalesItem
        })
    }

    updateCompany() {
        this.setState({
            openEditCompany: true,
            openCompanyDetail: false
        })

        var headerElement = document.getElementsByClassName(asideLeftStyles.appBar);
        headerElement[0].classList.add("modal-zindex");

        var leftNavElement = document.getElementsByClassName(asideLeftStyles.brand);
        leftNavElement[0].parentNode.classList.add("modal-zindex");
    }

    updatePerson() {
        this.setState({
            openEditPerson: true,
            openPersonDetail: false
        })

        var headerElement = document.getElementsByClassName(asideLeftStyles.appBar);
        headerElement[0].classList.add("modal-zindex");

        var leftNavElement = document.getElementsByClassName(asideLeftStyles.brand);
        leftNavElement[0].parentNode.classList.add("modal-zindex");
    }

    hideModal = () => {
        this.setState({ openLeadModal: false });

        var headerElement = document.getElementsByClassName(asideLeftStyles.appBar);
        headerElement[0].classList.remove("modal-zindex");

        var leftNavElement = document.getElementsByClassName(asideLeftStyles.brand);
        leftNavElement[0].parentNode.classList.remove("modal-zindex");

        //get current filters, and get leads withrespect to filters
        this.getLeadListByFilter({
            lead_current_status_id: this.state.filterValue
        });
    };

    hideCompanyEditModal = () => {
        this.setState({ openEditCompany: false });

        var headerElement = document.getElementsByClassName(asideLeftStyles.appBar);
        headerElement[0].classList.remove("modal-zindex");

        var leftNavElement = document.getElementsByClassName(asideLeftStyles.brand);
        leftNavElement[0].parentNode.classList.remove("modal-zindex");
    };

    hidePersonEditModal = () => {
        this.setState({ openEditPerson: false });

        var headerElement = document.getElementsByClassName(asideLeftStyles.appBar);
        headerElement[0].classList.remove("modal-zindex");

        var leftNavElement = document.getElementsByClassName(asideLeftStyles.brand);
        leftNavElement[0].parentNode.classList.remove("modal-zindex");
    };

    openPersonPopup(company_id, companyObj) {
        this.setState({
            addPersonFromLead: true,
            addCompanyFromLead: false
        });

        this.showDialog(company_id, companyObj);
    }

    hideContactDialog = (newlyAddedItem) => {
        this.setState({ showDialog: false });

        //If company gets added from lead form, return added company name to lead form
        if (this.state.addCompanyFromLead && newlyAddedItem) {
            this.setState({
                addCompanyFromLead: false,
                newlyAddedCompany: newlyAddedItem
            });
            this.getCompanies();
        } else if (this.state.addPersonFromLead && newlyAddedItem) {
            this.setState({
                addPersonFromLead: false,
                newlyAddedPerson: newlyAddedItem
            });
        } else {
            var headerElement = document.getElementsByClassName(asideLeftStyles.appBar);
            headerElement[0].classList.remove(asideLeftStyles.modal_zindex);

            var leftNavElement = document.getElementsByClassName(asideLeftStyles.brand);
            leftNavElement[0].parentNode.classList.remove(asideLeftStyles.modal_zindex);
        }
    }

    //DESKTOP add new contact dialog
    showDialog = async (company_id, companyObj) => {
        await this.setState({
            showDialog: true,
            selectedCompany: company_id,
            selectedCompanyObj: companyObj
        });

        if (!this.state.show) {
            var headerElement = document.getElementsByClassName(asideLeftStyles.appBar);
            headerElement[0].classList.add(asideLeftStyles.modal_zindex);

            var leftNavElement = document.getElementsByClassName(asideLeftStyles.brand);
            leftNavElement[0].parentNode.classList.add(asideLeftStyles.modal_zindex);
        }
    };

    static getDerivedStateFromProps(nextProps) {
        console.log(nextProps, "Next Props");
        if ((nextProps && nextProps.contactList && nextProps.leads) || nextProps.errorMessage !== '') {
            return {
                loading: false
            }
        }
        return nextProps;
    }

    toggleParkedFlag = (parkedFlag) => {
        this.setState({
            parkedFlag
        });
    }

    async getLeadListByFilter(arrayFilters, pageIndex) {
        if (this.state.parkedFlag && arrayFilters && !arrayFilters.hasOwnProperty('is_won')) {
            arrayFilters.is_won = 2;
        }

        if (arrayFilters && arrayFilters.lead_current_status_id) {
            this.setState({
                filterValue: arrayFilters.lead_current_status_id
            });
        }

        if (pageIndex) {
            this.setState({
                pageIndex: pageIndex + ""
            })
        }

        let organizationArray = null;
        let current_user_selection = null;
        let licenseType = null;

        if (this.props.orgSelected === null) {
            organizationArray = [this.props.currentUser.organization_id]
        } else {
            organizationArray = [this.props.orgSelected.id]
        }

        if (this.props.userSelected === null) {
            current_user_selection = this.props.currentUser.user_id;
        } else {
            current_user_selection = this.props.userSelected.id;
        }

        if (this.props.userSelected === null) {
            licenseType = this.props.currentUser.license_type.actual_name;
        } else {
            licenseType = this.props.userSelected.license_type && this.props.userSelected.license_type.actual_name;
        }

        let params = {
            "from": "my_pipeline",
            "arrayFilters": [arrayFilters],
            "selectFilters": ["id", "lead_title", "expected_closing_date", "lead_value", "type",
                "is_confidential", "notes", "is_hand_over", "is_won", "is_bell_ringed", "project_location", "created_at", "linkedin_profile"
            ],
            "sort": {
                "field": "id",
                "sortOrder": "DESC"
            },
            "paginate": {
                "page": this.state.pageIndex,
                "limit": this.state.pageLimit
            },
            "organizationArray": organizationArray,
            "user_id": current_user_selection,
            license_type: licenseType
        }
        this.props.getPipelineLeadList(params);
    }


    getNextLeads = async () => {
        let maxPage = Math.ceil(parseInt(this.props.leadsCount) / parseInt(this.state.pageLimit));
        if (parseInt(this.state.pageIndex) < maxPage - 1) {
            let pageIndex = parseInt(this.state.pageIndex);
            await this.setState({
                pageIndex: (++pageIndex) + ""
            });
            if (this.state.filterValue && this.state.filterValue.length > 0) {
                this.getLeadListByFilter({
                    lead_current_status_id: this.state.filterValue
                });
            } else {
                this.getLeadListByFilter({});
            }
        }
    }

    setOrganization = async selectedOption => {
        this.textFocus.current.select.state.isFocused = true;
        this.setState({
            menuIsOpen: true
        });
        this.props.selectMypipelineOrg(selectedOption);
        if (this.props.currentUser.organization_id === selectedOption.id) {
            await this.setState({
                pageLimit: "10",
                pageIndex: "0",
            });
            await this.getUserList(selectedOption.id, "first_time");
        } else {
            this.getUserList(selectedOption.id);
        }
    };

    onBlurUser = () => {
        this.setState({
            menuIsOpen: undefined
        })
    }


    setUser = selectedOption => {
        this.props.selectMypipelineUser(selectedOption);
        this.setState({
            menuIsOpen: undefined,
            selectedUser: selectedOption.first_name + " " + selectedOption.last_name
        }, async () => {
            await this.setState({
                pageLimit: "10",
                pageIndex: "0",
            });
            if (this.state.filterValue && this.state.filterValue.length > 0) {
                this.getLeadListByFilter({
                    lead_current_status_id: this.state.filterValue
                });
            } else {
                this.getLeadListByFilter({});
            }

            this.getRevenue();
            this.getContactList();
        });
    }

    canSeeDropDown = () => {
        const { organizationList } = this.props;
        const currentUser = this.props.currentUser;
        const licenseType = currentUser && currentUser.license_type.actual_name;
        const hasEnoughOrgOptions = currentUser && currentUser.is_parent ? organizationList.length > 1 : organizationList.length > 0;

        return hasEnoughOrgOptions && !['BASIC', 'LITE', 'PROFESSIONAL'].includes(licenseType);
    }

    getIcon(lead) {
        const { is_accepted, type } = lead;
        if (type === 'handover') {
            return is_accepted ? { img: handoverAcceptedIcon, tooltip: 'Handover' } : { img: handoverNotAcceptedIcon, tooltip: 'Handover' };
        } else if (type === 'cross-silo') {
            return is_accepted ? { img: crossalesAcceptedIcon, tooltip: 'Cross silo sale' } : { img: crossalesNotAcceptedIcon, tooltip: 'Cross silo sale' };
        }
    }

    render() {
        let keys = this.props.contactList ? this.props.contactList.map(item => { return Object.keys(item)[0] }) : null;
        const maxPage = Math.ceil(this.props.leadsCount / parseInt(this.state.pageLimit));
        const has_revenue_field_access = this.props.currentUser && this.props.currentUser.has_revenue_field_access;
        return (
            <div>
                {this.state.loading ?
                    <LargeScreenLoader />
                    :
                    <div className="main-wrapper">
                        <div className="breadcrumbs breadcrumbs_container_orgs">
                            <div>
                                <h3 className="heading">MY PIPELINE</h3>
                                <div className="breadcrumbs_container">
                                    <Breadcrumbs separator="›" arial-label="Breadcrumb">
                                        <Link to="/sales-news">Home</Link>
                                        <span>My pipeline</span>
                                    </Breadcrumbs>
                                </div>
                            </div>
                            {this.canSeeDropDown()
                                ?
                                <div className={"orgs_user_dropdowns"}>
                                    <div className={SalesNewsContainerStyles.salesNewsMultiSelect}>
                                        <MultiSelect
                                            isMulti={false}
                                            selectName="my-pipeline-organizations"
                                            setOrganization={this.setOrganization}
                                            value={this.props.orgSelected}
                                            organizationList={this.props.organizationList}
                                            closeMenuOnSelect={true}
                                            currentUser={this.props.currentUser}
                                        />
                                    </div>
                                    <div className={SalesNewsContainerStyles.salesNewsMultiSelect}>
                                        <MultiSelect
                                            placeholder={"Select User"}
                                            isMulti={false}
                                            defaultMenuIsOpen={this.state.menuIsOpen}
                                            selectName="my-pipeline-users"
                                            setUser={this.setUser}
                                            value={this.props.userSelected}
                                            organizationList={this.state.userList}
                                            closeMenuOnSelect={true}
                                            onBlur={this.onBlurUser}
                                            innerRef={this.textFocus}
                                            currentUser={this.props.currentUser}
                                        />
                                    </div>
                                </div> : null
                            }
                        </div>
                        <div className="container-body">
                            <div className="card shadow bg-white card-rounded">
                                <div className="card-body card-image">
                                    <div className="row header-content">
                                        {has_revenue_field_access && <div className="col-md-3">
                                            <div className="content-values">{this.props.revenueData && this.props.revenueData.revenue !== 0 ? commonFunctions.getConvertedCurrency(this.props.revenueData.revenue) : 0}</div>
                                            <div className="sales-content-text">Revenue, {this.props.revenueData && this.props.revenueData.account ? this.props.revenueData.account.currency.short_name : ''}</div>
                                        </div>}
                                        <div className={has_revenue_field_access ? "col-md-3" : "col-md-4"}>
                                            <div className="content-values">{this.props.revenueData ? this.props.revenueData.leadsTotal : 0}</div>
                                            <p className="sales-content-text">Leads total</p>
                                        </div>
                                        <div className={has_revenue_field_access ? "col-md-3" : "col-md-4"}>
                                            <div className="content-values">{this.props.revenueData ? this.props.revenueData.hitRate : 0}</div>
                                            <p className="sales-content-text">Hit rate, %</p>
                                        </div>
                                        <div className={has_revenue_field_access ? "col-md-3" : "col-md-4"}>
                                            <div className="content-values">
                                                <img src={doubleHandshakeGreen} className="hand-shake-icon" alt="HandShakeIcon" />
                                            </div>
                                            <p className="sales-content-text">{this.props.revenueData ? this.props.revenueData.handShakeTotal : 0}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-md-12 col-lg-6">
                                    <div className="card shadow bg-white card-rounded ongoing-sales-card">
                                        <div className="card-title sales-header mb-0">
                                            <span className="sales-title">
                                                Ongoing sales for
                                                "{
                                                    this.props.revenueData && this.props.revenueData.account && this.props.revenueData.account.name
                                                }"
                                                <span className="sales-title" style={{ fontStyle: "italic" }}>
                                                    {
                                                        this.state.selectedUser !== "" ?
                                                            " (" + this.state.selectedUser + ")" : ""
                                                    }
                                                </span>
                                            </span>
                                            {/* <div className="filterIcon" onClick={this.displayFilters}>
                                                <FilterList />
                                            </div> */}
                                        </div>
                                        <div className={`card-body ongoing-sales-body ${this.props.errorLoadingMypipelineLeads ? 'error-removed-padding' : ''}`}>
                                            {
                                                this.props.errorLoadingMypipelineLeads ?
                                                    <div>
                                                        <OopsError />
                                                    </div> :
                                                    <div className="row">
                                                        {
                                                            //!this.state.hideFilter &&
                                                            <Filter
                                                                getLeadListByFilter={(params, pageIndex) => this.getLeadListByFilter(params, pageIndex)}
                                                                filterKey={"my_pipeline"}
                                                                parkFlag={this.toggleParkedFlag}
                                                            />
                                                        }
                                                        {
                                                            this.props.isFetching ?
                                                                <SmallLoader />
                                                                :
                                                                (
                                                                    (!this.props.leads || this.props.leads == [] || this.props.leadsCount === 0) ?
                                                                        (
                                                                            this.state.parkedFlag === true
                                                                                ? <div className="col-md-12 no-records" style={{ padding: "25px" }}>Hey, looks like there are no parked leads!</div>
                                                                                : (
                                                                                    this.state.filterValue.length === 6 ?
                                                                                        <div className="col-md-12 no-records" style={{ padding: "25px" }}>Hey, looks like you haven't added any leads yet!</div>
                                                                                        : <div className="col-md-12 no-records" style={{ padding: "25px" }}>No records found</div>
                                                                                )
                                                                        ) :
                                                                        this.props.leads.map((item, index) => {
                                                                            const icon = this.getIcon(item);
                                                                            return (
                                                                                <div className="col-md-12 sales-item sales-item-padding-top" key={index} id={"sales_item_" + index}
                                                                                    onClick={() => this.handleClickOpen(item, index)}>
                                                                                    <div className="item display-inline">
                                                                                        {/* <img className="item-img display-inline" src={item.createdBy ? item.createdBy.user_avatar : ''} alt="sales-user" /> */}
                                                                                        {/* <div className="item-desc display-inline"> */}
                                                                                        <div className="display-inline">
                                                                                            <div className="pipeline_dots">{item.lead_title}</div>
                                                                                            <div className="pipeline_dots_1">{item.contact_company ? item.contact_company.company_name : ''}</div>
                                                                                        </div>
                                                                                        <div className="handoverIcon">
                                                                                            {
                                                                                                item.createdBy && item.createdBy.license_type && (item.createdBy.license_type.actual_name == 'BASIC' || item.createdBy.license_type.actual_name == 'LITE') ?
                                                                                                    (
                                                                                                        item.is_hand_over == 0 ?
                                                                                                            <img src={handshakeIconGrey} width="33" />
                                                                                                            : (item.is_hand_over && item.is_hand_over == 1 ?
                                                                                                                <img src={handshakeIconOne} width="33" />
                                                                                                                : item.is_hand_over && item.is_hand_over == 2 ?
                                                                                                                    <img src={handshakeIconTwo} width="33" />
                                                                                                                    : ''
                                                                                                            )
                                                                                                    ) :
                                                                                                    (
                                                                                                        " "
                                                                                                    )
                                                                                            }
                                                                                            {icon && (<div className={asideLeftStyles.tooltip}>
                                                                                                <span className={asideLeftStyles.tooltiptext}>{icon.tooltip}</span>
                                                                                                <img src={icon.img} style={{marginLeft: 8}} width="33" />
                                                                                            </div>)}
                                                                                        </div>
                                                                                        <div className="item-desc display-inline float-right"><KeyboardArrowRight className="pipeline-item-icon" /></div>
                                                                                        <div className="item-desc status-section float-right">
                                                                                            <div className="item-status item-status-right-spacing">
                                                                                                {
                                                                                                    item.lead_status ?
                                                                                                        (
                                                                                                            item.lead_status.name == 'Closed' ? (item.is_won ? 'Closed (Won)' : 'Closed (Lost)') :
                                                                                                                (item.is_won == 2 ? item.lead_status.name + " (Parked)" : item.lead_status.name)
                                                                                                        )
                                                                                                        : ''
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="item-progress">
                                                                                        {
                                                                                            item && item.lead_status && item.lead_status.name ?
                                                                                                (
                                                                                                    item.lead_status.name === 'New' ?
                                                                                                        <LinearProgress className="newLinearProgress" variant="determinate" value={16} />
                                                                                                        : (
                                                                                                            item.lead_status.name === 'Lead' ?
                                                                                                                <LinearProgress className="leadLinearProgress" variant="determinate" value={32} />
                                                                                                                : (
                                                                                                                    item.lead_status.name === 'Opportunity' ?
                                                                                                                        <LinearProgress className="opportunityLinearProgress" variant="determinate" value={48} />
                                                                                                                        : (
                                                                                                                            item.lead_status.name === 'Proposal' ?
                                                                                                                                <LinearProgress className="proposalLinearProgress" variant="determinate" value={64} />
                                                                                                                                : (
                                                                                                                                    item.lead_status.name === 'Negotiation' ?
                                                                                                                                        <LinearProgress className="negotiationLinearProgress" variant="determinate" value={80} />
                                                                                                                                        : (
                                                                                                                                            item.lead_status.name === 'Closed' ?
                                                                                                                                                <LinearProgress className="closedLinearProgress" variant="determinate" value={item.is_won ? 100 : 0} />
                                                                                                                                                : <LinearProgress variant="determinate" value={5} />
                                                                                                                                        )
                                                                                                                                )
                                                                                                                        )
                                                                                                                )
                                                                                                        )
                                                                                                )
                                                                                                : <LinearProgress variant="determinate" value={5} />
                                                                                        }

                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })
                                                                )
                                                        }
                                                        {
                                                            this.props.isFetching ? '' :
                                                                (
                                                                    this.props.leadsCount && (maxPage - 1) > parseInt(this.state.pageIndex) ?
                                                                        <div className="col-md-12 sales-view-all">
                                                                            <div className="view-all" onClick={() => this.getNextLeads()}>
                                                                                View more
                                                                    <span ><FontAwesomeIcon icon={faArrowRight} className="view-all-icon" /></span>
                                                                            </div>
                                                                        </div>
                                                                        : ''
                                                                )
                                                        }
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-12 col-lg-6">
                                    <div className="card shadow bg-white card-rounded">
                                        <div className="card-title sales-header mb-0">
                                            <span className="sales-title">Contacts for "{
                                                this.props.revenueData && this.props.revenueData.account && this.props.revenueData.account.name
                                            }" </span>
                                        </div>
                                        <div className="card-body padding-0" style={{ minHeight: '443px' }}>
                                            {/* contact search box */}
                                            {/* <div className="contact-search">
                                                <TextField
                                                    id="contact-search"
                                                    variant="outlined"
                                                    type="text"
                                                    label=""
                                                    value={this.state.searchText}
                                                    onChange={(e) => this.handleChange(e, 'searchText')}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton aria-label="search" onClick={() => this.SearchContact()}>
                                                                    <img src={searchIcon} />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </div> */}
                                            {
                                                this.props.errorLoadingMypipelineContacts ?
                                                    <div>
                                                        <OopsError />
                                                    </div> :
                                                    <>
                                                        <Divider style={{ marginBottom: '10px' }} />
                                                        {
                                                            this.props.contactsLoading ?
                                                                <SmallLoader /> :
                                                                <div className="scroll-list">
                                                                    {
                                                                        this.props.contactList && this.props.contactList.length > 0 ?
                                                                            this.props.contactList.map((item, index) => {
                                                                                return (
                                                                                    <div className="row contact-list" key={index}>
                                                                                        <div className="contact-col-1">
                                                                                            <span className="contact-initial">{keys[index].toUpperCase()}</span>
                                                                                        </div>
                                                                                        <div className="contact-col-2">
                                                                                            <div className="pipeline-grid">
                                                                                                {
                                                                                                    item[keys[index]].map((contact, index) => {

                                                                                                        return (
                                                                                                            <div className="contact-item" key={'contact_' + index} onClick={() => this.handleClickCompanyOpen(contact, index)}>
                                                                                                                <div className="display-inline contact-item-inner" style={{ width: '100%' }}>
                                                                                                                    <div className="contact-first">
                                                                                                                        <img className="display-inline contact-list-display-image" src={contact.name ? contactIcon : contact.company_name ? companyIcon : null} alt="contact" />
                                                                                                                        <div className="display-inline left-section">
                                                                                                                            {
                                                                                                                                contact.company_name ?
                                                                                                                                    <div className="contact-name" data-toggle="tooltip">
                                                                                                                                        <span title={contact && contact.company_name ? contact.company_name : ''}>
                                                                                                                                            {contact && contact.company_name ? contact.company_name : ''}
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    : null
                                                                                                                            }
                                                                                                                            {
                                                                                                                                contact.name ?
                                                                                                                                    <>
                                                                                                                                        <div className="contact-name" data-toggle="tooltip">
                                                                                                                                            <span title={contact && contact.name ? contact.name : ''}>
                                                                                                                                                {contact && contact.name ? contact.name : ''}
                                                                                                                                            </span>
                                                                                                                                        </div>
                                                                                                                                        {
                                                                                                                                            contact.contact_company ?
                                                                                                                                                <div className="company-name-my-pipeline" data-toggle="tooltip">
                                                                                                                                                    <span title={contact && contact.contact_company && contact.contact_company.company_name ? contact.contact_company.company_name : ''}>
                                                                                                                                                        {contact && contact.contact_company && contact.contact_company.company_name ? contact.contact_company.company_name : ''}
                                                                                                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                : null
                                                                                                                                        }
                                                                                                                                    </>
                                                                                                                                    : null
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="display-inline right-section" >
                                                                                                                        {
                                                                                                                            contact.name ?
                                                                                                                                <>
                                                                                                                                    <div className="right-phone-text" data-toggle="tooltip">
                                                                                                                                        <span title={`${contact.countryCode ? "+" + contact.countryCode && contact.countryCode.dialing_code : ""} ${contact.phone_number ? contact.phone_number : ""}`}>
                                                                                                                                            {/* {`${contact.countryCode ? "+" + contact.countryCode.dialing_code : ""} ${contact.phone_number? contact.phone_number : "" }`} */}
                                                                                                                                            {`${contact.phone_number ? (contact.countryCode && contact.countryCode.dialing_code ? ("+" + contact.countryCode.dialing_code + " " + contact.phone_number) : (contact.phone_number)) : ''}`}
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div className="right-email-text for_email" data-toggle="tooltip">
                                                                                                                                        <span title={contact && contact.email ? contact.email : ''}>
                                                                                                                                            {contact && contact.email ? contact.email : ''}
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                </>
                                                                                                                                : null
                                                                                                                        }
                                                                                                                        {
                                                                                                                            contact.company_name ?
                                                                                                                                <>
                                                                                                                                    <div className="right-phone-text" data-toggle="tooltip">
                                                                                                                                        <span title={`${contact.contact_person_phone ? (contact.countryCode ? ("+" + contact.countryCode.dialing_code + " " + contact.contact_person_phone) : (contact.contact_person_phone)) : ''}`}>
                                                                                                                                            {`${contact.contact_person_phone ? (contact.countryCode ? ("+" + contact.countryCode.dialing_code + " " + contact.contact_person_phone) : (contact.contact_person_phone)) : ''}`}
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div className="right-email-text for_email" data-toggle="tooltip">
                                                                                                                                        <span title={contact.website ? contact.website : ''}>
                                                                                                                                            {contact.website ? contact.website : ''}
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                </>
                                                                                                                                : null
                                                                                                                        }

                                                                                                                    </div>
                                                                                                                    <div className="right_arrow_section">
                                                                                                                        <div><KeyboardArrowRight className="right-arrow-icon" /></div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            }) : <div style={{ textAlign: "center" }}>Hey, looks like you haven't added any contacts yet!</div>
                                                                    }
                                                                </div>
                                                        }
                                                    </>
                                            }
                                            {/* <div className="col-md-12 sales-view-all">
                                                <div className="view-all">
                                                    View All
                                        <span ><ArrowForward className="view-all-icon" /></span>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* sales details dialog */}
                        <Dialog
                            onClose={this.handleClose}
                            aria-labelledby="customized-dialog-title"
                            open={this.state.open} maxWidth={'lg'}
                            className="pipe-linemain-div"
                        >
                            {console.log(this.state.currentSalesItem, 'currentSales Item')}
                            <LeadDetailPopup {...this.props}
                                currentSalesItem={this.state.currentSalesItem}
                                unparkLead={(params) => this.props.unparkLead(params)}
                                resetUnparkLeadFlag={() => this.props.resetUnparkLeadFlag()}
                                openUpdateLeadPopup={() => this.openUpdateLeadPopup()}
                                // ringTheBell={()=>this.ringTheBell()} 
                                onClose={() => this.handleClose()}
                                filterList={this.state.filterValue}
                                getPipelineLeadList={this.props.getPipelineLeadList}
                            />
                        </Dialog>

                        <Dialog
                            onClose={this.handleClose}
                            aria-labelledby="customized-dialog-title"
                            open={this.state.openCompanyDetail} maxWidth={'lg'}
                            className="companyDetailPopup"
                        >
                            <CompanyDetailPopup companyItem={this.state.companyItem}
                                updateCompany={() => this.updateCompany()}
                                handleClose={() => this.handleClose()}
                                updation={
                                    (this.props.currentUser && this.props.currentUser.organization_id)
                                    ===
                                    (this.state.companyItem && this.state.companyItem.account_id)
                                }
                                organizationName={this.props.revenueData && this.props.revenueData.account && this.props.revenueData.account.name}
                            />

                        </Dialog>

                        <Dialog
                            onClose={this.handleClose}
                            aria-labelledby="customized-dialog-title"
                            open={this.state.openPersonDetail} maxWidth={'lg'}
                            className="personDetailPopup"
                        >
                            <PersonDetailPopup personItem={this.state.personItem}
                                updatePerson={() => this.updatePerson()}
                                handleClose={() => this.handleClose()}
                                updation={
                                    (this.props.currentUser && this.props.currentUser.organization_id)
                                    ===
                                    (this.state.personItem && this.state.personItem.account_id)
                                }
                                organizationName={this.props.revenueData && this.props.revenueData.account && this.props.revenueData.account.name}
                                filterList={this.state.filterValue}
                            />
                        </Dialog>
                    </div>
                }

                {/* update Lead popup */}
                <Dialog
                    className="width"
                    aria-labelledby="customized-dialog-title"
                    open={this.state.openLeadModal}
                    onClose={this.hideModal}
                    maxWidth={'lg'} className="leadFromPopup"
                >
                    <LeadContainer hideModal={this.hideModal}
                        handleWinCallBack={(lead) => this.handleWinCallBack(lead)}
                        openLeadDetailCallBack={(lead) => this.openLeadDetailCallBack(lead)}
                        currentLead={this.state.currentSalesItem}
                        openPersonPopup={(company_id, companyObj) =>
                            this.openPersonPopup(company_id, companyObj)}
                        newlyAddedCompany={this.state.newlyAddedCompany}
                        newlyAddedPerson={this.state.newlyAddedPerson} />
                </Dialog>
                {/* update company popup */}
                <Dialog
                    className="width"
                    aria-labelledby="customized-dialog-title"
                    open={this.state.openEditCompany}
                    onClose={this.hideCompanyEditModal}
                    maxWidth={'lg'}
                >
                    <Contact hideDialog={this.hideCompanyEditModal} value={0} currentCompany={this.state.companyItem} />
                </Dialog>
                {/* update person popup */}
                <Dialog
                    className="width"
                    aria-labelledby="customized-dialog-title"
                    open={this.state.openEditPerson}
                    onClose={this.hidePersonEditModal}
                    maxWidth={'lg'}
                    filterList={this.state.filterValue}
                >
                    <Contact hideDialog={this.hidePersonEditModal} value={1} currentPerson={this.state.personItem} />
                </Dialog>

                {/* add new contact */}
                <Dialog
                    className="width"
                    aria-labelledby="customized-dialog-title"
                    open={this.state.showDialog ? this.state.showDialog : false}
                    onClose={this.hideDialog}
                    maxWidth={"lg"}
                    className="contactForm"
                    filterList={this.state.filterValue}
                >
                    {this.state.addPersonFromLead ? (
                        <Contact
                            {...this.props}
                            addCompanyFromLead={this.state.addCompanyFromLead}
                            addPersonFromLead={this.state.addPersonFromLead}
                            selectedCompany={this.state.selectedCompany}
                            selectedCompanyObj={this.state.selectedCompanyObj}
                            value={1}
                            hideDialog={newlyAddedItem =>
                                this.hideContactDialog(newlyAddedItem)
                            }
                        />
                    ) : this.state.addCompanyFromLead ? (
                        <Contact
                            {...this.props}
                            addCompanyFromLead={this.state.addCompanyFromLead}
                            addPersonFromLead={this.state.addPersonFromLead}
                            selectedCompany={this.state.selectedCompany}
                            value={0}
                            hideDialog={newlyAddedItem =>
                                this.hideContactDialog(newlyAddedItem)
                            }
                            filterList={this.state.filterValue}
                        />
                    ) : (
                                <Contact
                                    {...this.props}
                                    addCompanyFromLead={this.state.addCompanyFromLead}
                                    addPersonFromLead={this.state.addPersonFromLead}
                                    selectedCompany={this.state.selectedCompany}
                                    selectedCompanyObj={this.state.selectedCompanyObj}
                                    hideDialog={newlyAddedItem =>
                                        this.hideContactDialog(newlyAddedItem)
                                    }
                                    filterList={this.state.filterValue}
                                />
                            )}
                </Dialog>
            </div>
        );
    }
}

export default MyPipeline;
