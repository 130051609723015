import React, { Component } from "react";
import { connect } from "react-redux";
import FieldsMappingCRMComponent from "./component/FieldsMappingCRMComponent";

class FieldsMappingCRMContainer extends Component {
  render() {
    const fieldsMappingCRMComponentProps = { ...this.props };
    return <FieldsMappingCRMComponent {...fieldsMappingCRMComponentProps} />;
  }
}

const mapStateToProps = state => {
  const tempState = { ...state };
  //console.log("tempState", tempState);
  //For Brreg
  if (tempState.idcrm_master == "2") {
    return {
      ...state,
      idcrm_integration: state.CRMIntegrationDetails.idcrm_integration,
      idcrm_master: state.CRMIntegrationDetails.idcrm_master,
      crm_name: state.CRMIntegrationDetails.crm_name
    };
  }
  return {
    ...state,
    idcrm_integration: state.CRMConfigureDetails.idcrm_integration,
    idcrm_master: state.CRMIntegrationDetails.idcrm_master,
    crm_name: state.CRMIntegrationDetails.crm_name
  };
};

/* const mapDispatchToProps = dispatch => {
  return {
    getActivityTypes: requestBody =>
      dispatch(actions.getActivityTypes(requestBody)),
    addActivity: requestBody => dispatch(actions.addActivity(requestBody)),
    resetAddActivity: () => dispatch(actions.resetAddActivity()),
    updatingActivity: requestBody =>
      dispatch(actions.updatingActivity(requestBody)),
    resetUpdateActivity: () => dispatch(actions.resetUpdateActivity())
  };
}; */

export default connect(
  mapStateToProps/* ,
  mapDispatchToProps */
)(FieldsMappingCRMContainer);
