import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './circular-loader.module.scss';

const ColorCircularProgress = withStyles({
    root: {
        color: '#17D98B',
    },
})(CircularProgress);


const RelativeCircularProgress = withStyles({
    root: {
        color: '#17D98B',
        position: 'relative'
    },
})(CircularProgress);

class SmallLoader extends React.Component {
    render() {
        return (
            <ColorCircularProgress className={styles.circular_progress} />
        );
    }
}

export default SmallLoader;

export const Loader = () => <RelativeCircularProgress className={styles.circular_progress} />