import axios from "../../common/api-config";

export default {
  async getEmployeePointDetail(organizationArray, fromDate, toDate) {
    const params = {
      organizationArray,
      fromDate,
      toDate,
      revenueType: "sales_community",
    };
    const result = await axios.post(
      "/pluseLite/getEmployeePointDetail",
      params
    );
    return result.data.data.data;
  },
  async getRevenueTotal(organizationArray, fromDate, toDate) {
    const params = {
      organizationArray,
      fromDate,
      toDate,
      revenueType: "sales_community",
    };

    const result = await axios.post("/lead/revenueTotal", params);
    return result.data.data;
  },
  async getTotalEmployee(organizationArray, fromDate, toDate) {
    const params = {
      organizationArray,
      fromDate,
      toDate,
      revenueType: "sales_community",
    };
    const result = await axios.post("/pluseLite/getTotalEmployee", params);
    return result.data.data.data[0].Employee;
  },
  async getBonusReportByMonth(organizationArray, fromDate, toDate) {
    const params = {
      organizationArray,
      fromDate,
      toDate,
      revenueType: "sales_community",
    };
    const result = await axios.post("/pluseLite/getBonusReportByMonth", params);
    return result.data.data.data;
  },
};
