import React from "react";

export default function NoDataBarChart({ minHeight = 350 }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight,
      }}
    >
      <img
        src={require("../../../../assets/Group 3288.svg")}
        style={{ width: 100 }}
      />
      <div style={{ marginTop: 8, color: "#929292", fontWeight: 700 }}>
        No data available
      </div>
    </div>
  );
}