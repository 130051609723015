import React, { Component } from "react";
import "./PublishNews.css";
import { withStyles } from "@material-ui/core/styles";
import LargeScreenLoader from "../../../common/Loaders/PulseLoader/pulse-loader";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
} from "@material-ui/core";
import NewsCard from "../../../components/NewsCard/NewsCard";
import NoRecordFound from "../../../components/NoRecordFound/NoRecordFound";
import { openSnackbar } from "../../../common/Snackbar/snackbar";
// import NoRecordFound from "../../../components/NoRecordFound/NoRecordFound"
import SalesNewsDialog from "../../../components/SalesNewsDialog/SalesNewsDialog";
import asideLeftStyles from "../../aside-left/component/aside-left.module.scss";
import imageCompression from "browser-image-compression";
import moment from "moment";
import Api from "../Api";

const styles = () => ({
  root: {
    flexGrow: 1,
    padding: "20px",
  },
  no_records_card: {
    textAlign: "center",
    fontSize: "16px",
    padding: "20px",
  },
});

class PublishNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openNewEditor: false,
      count: 1,
      deleteConfirmationFlag: false,
      title: "",
      discription: "",
      licenseTypeArray: [6],
      is_has_expiry: false,
      expiry_date: "",
      sales_news_organizations: [],
      file: null,
      updateImageFlag: false,
      updateFlag: false,
      loadingPage: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    if (
      !this.props.selectedOrganizationList.includes(
        this.props.currentUser.organization_id
      )
    ) {
      this.props.initializeActiveTab(0);
    }

    const requestBody = {
      arrayFilters: [
        {
          is_active: 1,
          is_deleted: 0,
          created_by: this.props.currentUser.user_id,
        },
      ],
      selectFilters: [
        "id",
        "title",
        "cover_image",
        "is_has_expiry",
        "expiry_date",
        "news_body",
        "created_at",
        "updated_at",
      ],
      // organizationArray: [this.props.currentUser.organization_id],
      organizationArray: this.props.selectedOrganizationList,

      sort: {
        field: "updated_at",
        sortOrder: "DESC",
      },
      paginate: {
        page: "0",
        limit: "10",
      },
    };
    this.props.initializePublishNews(requestBody);
  }

  setIs_has_expiry = (is_has_expiry) => {
    this.setState({
      is_has_expiry,
    });

    if (!is_has_expiry) {
      this.setState({
        expiry_date: "",
      });
    }
  };

  setExpiry_date = (expiry_date) => {
    this.setState({
      expiry_date,
    });
  };

  setOrganizationArray = (array) => {
    this.setState({
      sales_news_organizations: array,
    });
  };

  handleClick = (id, salesNewsItem) => {
    this.props.history.push({
      pathname: `/sales-news/${id}`,
      state: {
        salesNews: salesNewsItem,
      },
    });
  };

  deleteNewsClick = (id) => {
    this.setState({
      deleteConfirmationFlag: true,
      id,
    });
    var headerElement = document.getElementsByClassName(asideLeftStyles.appBar);
    headerElement[0].classList.add(asideLeftStyles.modal_zindex);

    var leftNavElement = document.getElementsByClassName(asideLeftStyles.brand);
    leftNavElement[0].parentNode.classList.add(asideLeftStyles.modal_zindex);
  };

  handleDeleteClose = () =>
    this.setState({
      deleteConfirmationFlag: false,
      id: null,
    });

  componentDidUpdate = (prevProps, prevState) => {
    window.addEventListener("scroll", this.handleScroll);

    if (
      prevProps.selectedOrganizationList !==
        this.props.selectedOrganizationList &&
      !this.props.selectedOrganizationList.includes(
        this.props.currentUser.organization_id
      )
    ) {
      this.props.initializeActiveTab(0);
    }

    if (prevProps.callStatus !== this.props.callStatus) {
      this.setState({
        openNewEditor: false,
      });
      const params = {
        arrayFilters: [
          {
            is_active: 1,
            is_deleted: 0,
            created_by: this.props.currentUser.user_id,
          },
        ],
        selectFilters: [],
        organizationArray: this.props.selectedOrganizationList,

        sort: {
          field: "updated_at",
          sortOrder: "DESC",
        },
        paginate: {
          page: "0",
          limit: "10",
        },
      };
      this.props.initializePublishNews(params);
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight - 100) {
      this.loadMoreDataSalesNews();
    }
  };

  loadMoreDataSalesNews = () => {
    if (
      this.props.publishSalesNewsdata &&
      this.props.totalPublishedNews > this.props.publishSalesNewsdata.length &&
      !this.state.loadingPage
    ) {
      const requestBody = {
        arrayFilters: [
          {
            is_active: 1,
            is_deleted: 0,
            created_by: this.props.currentUser.user_id,
          },
        ],
        selectFilters: [
          "id",
          "title",
          "cover_image",
          "is_has_expiry",
          "expiry_date",
          "news_body",
          "created_at",
          "updated_at",
        ],
        organizationArray: this.props.selectedOrganizationList,

        sort: {
          field: "updated_at",
          sortOrder: "DESC",
        },
        paginate: {
          page: this.state.count.toString(),
          limit: "10",
        },
      };

      this.setState({ loadingPage: true });

      this.props.loadMorePublishedNews(requestBody, (err, result) => {
        if (result) {
          this.setState((preState) => {
            return { count: preState.count + 1, loadingPage: false };
          });
        } else {
          this.setState({ loadingPage: false });
        }
      });
    }
  };

  returnOrganizationArrayId = (data) => {
    const ids = [];
    for (var i = 0; i < data.length; i++) {
      for (var j = 0; j < this.props.organizationListNews.length; j++) {
        if (data[i].account_id === this.props.organizationListNews[j].id) {
          ids.push(this.props.organizationListNews[j]);
        }
      }
    }
    return ids;
  };

  //  News card  //

  editNews = async (data) => {
    var headerElement = document.getElementsByClassName(asideLeftStyles.appBar);
    headerElement[0].classList.add(asideLeftStyles.modal_zindex);

    console.log(data, "DATAAA");

    const {
      id,
      url,
      user,
      cover_image,
      title,
      is_has_expiry,
      expiry_date,
      news_body,
    } = data;

    var leftNavElement = document.getElementsByClassName(asideLeftStyles.brand);
    leftNavElement[0].parentNode.classList.add(asideLeftStyles.modal_zindex);

    this.setState({
      loadingPage: true,
    });

    const [orgs, licenses] = await Promise.all([
      Api.getSalesNewsOrganizations(id),
      Api.getSalesNewsLicenseTypes(id),
    ]);
    const sales_news_organizations = this.returnOrganizationArrayId(orgs);
    const licenseTypeArray = licenses.map((a) => a.license_type_id);

    let fileName = cover_image.split("/");

    console.log(sales_news_organizations, "sales_news_organizations");
    const salesNewsState = {
      sales_news_organizations: sales_news_organizations,
      expiry_date: data.expiry_date ? data.expiry_date : "",
      id,
      url,
      user,
      cover_image,
      title,
      is_has_expiry,
      discription: news_body,
      licenseTypeArray,
      file: {
        fileName: fileName.length && fileName[fileName.length - 1],
        fileBody: cover_image,
      },
    };

    this.setState(
      {
        ...salesNewsState,
        loadingPage: false,
        prevSalesNewsState: { ...salesNewsState },
      },
      () => {
        this.setState({
          openNewEditor: true,
        });
      }
    );
  };

  //preview news

  onCloseNews = () => {
    this.setState({
      openNewEditor: false,
    });

    var headerElement = document.getElementsByClassName(asideLeftStyles.appBar);
    headerElement[0].classList.remove(asideLeftStyles.modal_zindex);

    var leftNavElement = document.getElementsByClassName(asideLeftStyles.brand);
    leftNavElement[0].parentNode.classList.remove(asideLeftStyles.modal_zindex);
  };

  // onChange = e => {
  //   let file = e.target.files[0]

  //   this.getBase64(file, (result) => {
  //     this.setState({
  //       updateFlag: true,
  //       updateImageFlag: true,
  //       file: {
  //         fileBody: result,
  //         fileName: file.name,
  //         fileExtension: file.type,
  //         parentFolder: "sales_news"
  //       }
  //     })
  //   });
  // }

  onChange = (e, valid) => {
    if (valid == true) {
      let file = e.target.files[0];
      e.target.value = "";

      // checking file size if more than 2 mb than compress
      if (file.size / 1024 / 1024 > 2) {
        const options = {
          maxSizeMB: 2,
          maxWidthOrHeight: 1920,
        };
        imageCompression(file, options)
          .then((compressedFile) => {
            this.settingImage(compressedFile);
          })
          .catch((error) => {
            this.setState({
              file: null,
            });
          });
      } else {
        this.settingImage(file);
      }
    } else {
      this.setState({
        file: null,
      });
    }
  };

  settingImage = (file) => {
    this.getBase64(file, (result) => {
      this.setState({
        updateFlag: true,
        updateImageFlag: true,
        file: {
          fileBody: result,
          fileName: file.name,
          fileExtension: file.type,
          parentFolder: "sales_news",
        },
      });
    });
  };

  //***************  converting image into base 64

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      cb(reader.result);
    };
    reader.onerror = (error) => {
      console.log("Error: ", error);
    };
  }

  previewNews = () => {
    this.setState({
      previewNewsModal: true,
    });
  };

  returnOrganizationArrayIdAPI = () => {
    const id = [];
    for (var i = 0; i < this.state.sales_news_organizations.length; i++) {
      id.push(this.state.sales_news_organizations[i].id);
    }
    return id;
  };

  updatePublishNews = async (data) => {
    data.id &&
      (await this.setState({
        id: data.id,
        title: data.title,
        discription: data.news_body,
      }));
    const editImageparams = {
      title: this.state.title.trim(),
      news_body: this.state.discription.trim(),
      cover_image_old: this.state.cover_image,
      attachment: this.state.file,
      is_active: 1,
      id: this.state.id,
      user_id: this.props.currentUser.user_id,
      update: true,
      is_has_expiry: this.state.is_has_expiry,
      expiry_date: this.state.expiry_date,
      organizationArray: this.returnOrganizationArrayIdAPI(),
      licenseTypeArray: this.state.licenseTypeArray,
    };
    const editparams = {
      title: this.state.title.trim(),
      news_body: this.state.discription.trim(),
      is_active: 1,
      id: this.state.id,
      user_id: this.props.currentUser.user_id,
      update: true,
      is_has_expiry: this.state.is_has_expiry,
      expiry_date: this.state.expiry_date,
      organizationArray: this.returnOrganizationArrayIdAPI(),
      licenseTypeArray: this.state.licenseTypeArray,
    };
    const params = {
      is_active: 1,
      id: this.state.id,
      user_id: this.props.currentUser.user_id,
      update: true,
      is_has_expiry: this.state.is_has_expiry,
      expiry_date: this.state.expiry_date,
      organizationArray: this.returnOrganizationArrayIdAPI(),
      licenseTypeArray: this.state.licenseTypeArray,
    };
    const parameters = {
      title: this.state.title,
      news_body: this.state.discription,
      attachment: this.state.file,
      is_active: 1,
      user_id: this.props.currentUser.user_id,
    };
    !this.state.updateFlag
      ? this.props.updatePublishNews(params)
      : this.state.updateImageFlag
      ? this.props.updatePublishNews(editImageparams)
      : this.props.updatePublishNews(editparams);
  };

  getInitialStateOfNews = () => {
    return this.state.prevSalesNewsState || {
      file: null,
      title: "",
      discription: "",
      is_has_expiry: false,
      expiry_date: "",
      sales_news_organizations: [
        {
          id: this.props.currentUser.organization_id,
          name: this.props.currentUser.organizationName,
        },
      ],
      licenseTypeArray: [6],
    };
  };

  resetFormData = () => {
    this.setState(this.getInitialStateOfNews());
  };
  saveToDrafts = (data) => {
    const editImageparams = {
      title: this.state.title.trim(),
      news_body: this.state.discription.trim(),
      cover_image_old: this.state.cover_image,
      attachment: this.state.file,
      is_active: 0,
      id: this.state.id,
      draft_from_publish: true,
      is_has_expiry: this.state.is_has_expiry,
      expiry_date: this.state.expiry_date,
      organizationArray: this.returnOrganizationArrayIdAPI(),
      licenseTypeArray: this.state.licenseTypeArray,
    };

    const editparams = {
      title: this.state.title.trim(),
      news_body: this.state.discription.trim(),
      is_active: 0,
      id: this.state.id,
      draft_from_publish: true,
      is_has_expiry: this.state.is_has_expiry,
      expiry_date: this.state.expiry_date,
      organizationArray: this.returnOrganizationArrayIdAPI(),
      licenseTypeArray: this.state.licenseTypeArray,
    };

    const parameters = {
      title: this.state.title,
      news_body: this.state.discription,
      attachment: this.state.file,
      is_active: 0,
      is_has_expiry: this.state.is_has_expiry,
      expiry_date: this.state.expiry_date,
      organizationArray: this.returnOrganizationArrayIdAPI(),
      licenseTypeArray: this.state.licenseTypeArray,
    };

    this.state.updateImageFlag
      ? this.props.updatePublishNews(editImageparams)
      : this.props.updatePublishNews(editparams);
    // openSnackbar("News published successfully...!","snack-success")
  };

  setNewsTitle = (title) => {
    this.setState({
      title,
      updateFlag: true,
    });
  };

  setNewDiscription = (discription) => {
    this.setState({
      discription,
      updateFlag: true,
    });
  };

  returnAllOrganizationArrayId = (data) => {
    const id = [];
    for (var i = 0; i < data.length; i++) {
      id.push(data[i].account_id);
    }
    return id;
  };

  unPublishNews = (data) => {
    const { id } = data;
    const params = {
      is_active: 0,
      id: id,
      unpublish_from_publish: true,
      is_has_expiry: data.is_has_expiry,
      expiry_date: data.expiry_date,
      organizationArray: this.returnAllOrganizationArrayId(
        data.sales_news_organizations
      ),
      licenseTypeArray: this.state.licenseTypeArray,
    };
    this.props.updatePublishNews(params);
  };

  handleLicenseTypeArrayChange = (licenseTypeArray) => {
    this.setState({
      licenseTypeArray,
    });
  };

  render() {
    const { publishSalesNewsdata, errorLoadingSalesNews, classes } = this.props;
    const loader = <LargeScreenLoader />;

    return (
      <Grid container className="indraft-card-container">
        {/* { console.log("&&&&&&&&&`&&&&&&&&&&&&&&&&&", this.props.organizationListNews)}
        { console.log("&&&&&&&&&`&&&&&&&&&&&&&&&&&", this.props.selectedOrganizationList)} */}
        {this.state.loadingPage && loader}
        <SalesNewsDialog
          licenseTypeArray={this.state.licenseTypeArray}
          onLicenseTypeArrayChange={this.handleLicenseTypeArrayChange}
          onCloseNews={this.onCloseNews}
          title={this.state.title}
          discription={this.state.discription}
          orgList={this.props.organizationListNews}
          is_has_expiry={this.state.is_has_expiry}
          setIs_has_expiry={this.setIs_has_expiry}
          organizationArray={this.state.sales_news_organizations}
          setOrganizationArray={this.setOrganizationArray}
          expiry_date={
            this.state.expiry_date !== null ? this.state.expiry_date : ""
          }
          setExpiry_date={this.setExpiry_date}
          onChange={this.onChange}
          style={{ marginTop: "200px" }}
          previewNews={this.previewNews}
          publishNews={this.updatePublishNews}
          resetFormData={this.resetFormData}
          saveToDrafts={this.saveToDrafts}
          openModal={this.state.openNewEditor}
          setNewsTitle={this.setNewsTitle}
          setNewDiscription={this.setNewDiscription}
          file={this.state.file && this.state.file}
          user={this.state.user && this.state.user}
          currentUser={this.props.currentUser}
        />
        <Dialog
          open={this.state.deleteConfirmationFlag}
          onClose={this.handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          fullWidth
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="simple-dialog-title">
            Do you really want to delete this news?
          </DialogTitle>
          <DialogActions className="action-btn-wrap">
            <Button
              variant="contained"
              onClick={() => {
                const params = {
                  id: this.state.id,
                };
                this.props.deleteSalesNews(params);
                this.handleDeleteClose();
                // openSnackbar("News Deleted successfully...!","snack-success")
              }}
              className="action-btn yes-btn"
            >
              Yes
            </Button>
            <Button
              variant="contained"
              className="action-btn"
              onClick={this.handleDeleteClose}
              autoFocus
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
        {this.props.publishSalesNewsdata ? (
          this.props.totalPublishedNews && publishSalesNewsdata ? (
            publishSalesNewsdata.map((item, index) => (
              <Grid
                onClick={() => this.handleClick(item.id, item)}
                className="indraft-card-item publish-news"
                key={index}
                item
                md={6}
                lg={6}
                xs={12}
                sm={12}
              >
                <NewsCard
                  PublishNews={this.unPublishNews}
                  currentUser={this.props.currentUser}
                  newsobj={item}
                  {...item}
                  id={item.id}
                  userName={item.user.name}
                  userAvatar={item.user.user_avatar}
                  title={item.title}
                  is_has_expiry={item.is_has_expiry}
                  expiry_date={item.expiry_date}
                  newsTime={item.updated_at}
                  imagePath={item.cover_image}
                  deleteNews={this.deleteNewsClick}
                  publishButton={true}
                  editButton={true}
                  buttonAlignment={4}
                  publishButtonText="Unpublish"
                  editNews={this.editNews}
                  callStatus={this.props.callFlag}
                  organizationName={item.account && item.account.name}
                />
              </Grid>
            ))
          ) : (
            <div className="col-md-12 no-records">No sales news found.</div>
          )
        ) : (
          loader
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(PublishNews);
