import React from 'react';

export default function LegendContainer({ children, containerStyle = {}, justifyContent = 'center' }) {
  return (
    <div style={{display: 'flex', justifyContent, width: '100%'}}>
      <div className="align-middle col-auto" style={{
        backgroundColor: '#EFEFF3',
        height: 30,
        marginTop: 10,
        borderRadius: 8,
        paddingLeft: 20,
        paddingRight: 20,
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        ...containerStyle,
      }}>
        {children}
      </div>
    </div>
  )
}

export const Tile = ({color, text, marginRight}) => {
  return (
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight}}>
      <div style={{backgroundColor: color, width: 16, height: 10, borderRadius: 6, marginRight: 10}}>

      </div>
      {text}
    </div>
  )
}