import {
    combineReducers
} from 'redux';

import salesNewsReducer from './app/pages/SalesNews/redux/reducer'
import LoginReducer from './app/pages/Login/redux/reducer';
import asideLeftReducer from './app/pages/aside-left/redux/reducer';
import salesFeedsReducer from './app/pages/aside-right/redux/reducer';
import LeadReducer from './app/pages/newLead/redux/reducer';
import mypipelineReducer from './app/pages/MyPipeline/redux/reducer';
import addNewContact from './app/pages/newContact/redux/reducer';
import salesCommunityReducer from './app/pages/SalesCommunity/redux/reducer';
import activitiesReducer from './app/pages/Activities/redux/reducer';
import tasksReducer from './app/pages/Tasks/redux/reducer';
import addActivitiesReducer from './app/pages/AddActivities/redux/reducer';
import addTaskReducer from './app/pages/AddTasks/redux/reducer';
import leadDetailReducer from './app/pages/LeadDetailPopup/redux/reducer';
import ProfileReducer from './app/pages/Profile/redux/reducer';
import notesReducer from "./app/components/NotesListing/redux/reducer";
import companyDetails from './app/pages/CompanyDetailPopup/redux/reducer';
import filterReducer from './app/components/Filter/redux/reducer';
import graphReducer from './app/pages/Graph/redux/reducer';
import CRMIntegrationsReducer from '../src/app/pages/Integrations/redux/reducer';
import CRMConfigureDetails from '../src/app/pages/ConfigureCRM/redux/reducer';
import CRMConfigureObjMappingDetails from '../src/app/pages/ConfigureDataObjsMapCRM/redux/reducer';
import subscriptionReducr from './app/pages/Subscriptions/redux/reducer';

const rootReducer = {
    salesNews: salesNewsReducer,
    login: LoginReducer,
    asideLeft: asideLeftReducer,
    salesFeeds: salesFeedsReducer,
    newLead: LeadReducer,
    mypipeline: mypipelineReducer,
    salesCommunity: salesCommunityReducer,
    addNewContact: addNewContact,
    activities: activitiesReducer,
    tasks: tasksReducer,
    addActivities: addActivitiesReducer,
    addTask: addTaskReducer,
    leadDetailReducer: leadDetailReducer,
    ProfileReducer: ProfileReducer,
    notes: notesReducer,
    companyDetails: companyDetails,
    filterData: filterReducer,
    graphData: graphReducer,
    CRMIntegrationDetails: CRMIntegrationsReducer,
    CRMConfigureDetails: CRMConfigureDetails,
    CRMConfigureObjMappingDetails: CRMConfigureObjMappingDetails,
    subscriptions:subscriptionReducr
};

const combinedRootReducer = combineReducers(rootReducer);

export default combinedRootReducer;