import axios from "../../common/api-config";

const obj = {
  async getList() {
    const url = '/account/get_account_ext_integrations';
    const response = await axios.post(url, {});
    return response.data.data;
  },
  async createApp(app_name, email='fake9@email.com') {
    const url = '/account/add_account_ext_integrations';
    const response = await axios.post(url, {app_name, email});
    return response.data.data.data;
  },
  async update(id, is_active, is_delete) {
    const url = '/account/update_account_ext_integrations';
    const response = await axios.post(url, { id, is_active, is_delete });
    return response.data.data;
  },
  activate(id) {
    return obj.update(id, true, false);
  },
  deactivate(id) {
    return obj.update(id, false, false);
  },
  deleteApp(id) {
    return obj.update(id, false, true);
  }
};

export default obj;