import React from "react";
import { Card, Grid, Paper } from "@material-ui/core";
import './NoRecordFound.css'

const NoRecordFound = (props) => {

    return(
        <Grid style={{padding:"2%"}} item md={12} lg={12} xs={12} sm={12}>
              <Card>
                <props.variant className="no_records_card">{props.message}</props.variant>
              </Card>
      </Grid>
    )
}
NoRecordFound.defaultProps={
    message:"No Records Available",
    variant:"h1"
}
export default NoRecordFound