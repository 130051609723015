export const ADD_NEW_LEAD = 'ADD_NEW_LEAD';
export const ADD_NEW_LEAD_FAILED = 'ADD_NEW_LEAD_FAILED';
export const ADD_NEW_LEAD_SUCCESS = 'ADD_NEW_LEAD_SUCCESS';
export const UPDATE_NEW_LEAD = 'UPDATE_NEW_LEAD';
export const UPDATE_NEW_LEAD_FAILED = 'UPDATE_NEW_LEAD_FAILED';
export const UPDATE_NEW_LEAD_SUCCESS = 'UPDATE_NEW_LEAD_SUCCESS';

// Handover and Cross-silo Sales
export const GET_ORG_CHILD_SUCCESS = 'GET_ORG_CHILD_SUCCESS';
export const GET_ORG_CHILD_FAILED = 'GET_ORG_CHILD_FAILED';
 