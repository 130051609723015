import React, {
    Component,
    useRef,
    useLayoutEffect,
    useState
} from 'react';
import * as d3 from 'd3';


class HandOverGenratedChart extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    draw(data, options) {
       
        // console.log(data, options);
        // Remove svg
        d3.select('#' + options.id).select('svg').remove();

        let ticks;
        if (/Android|webOS|BlackBerry|IEMobile|Opera Mini|iPhone/i.test(navigator.userAgent)) {
            ticks = 2;
        } else {
            ticks = 10;
        }

        const chartStyle = options.chartStyle || 'horizontal';
        options.margin = options.margin || {};

        // Chart parent dive height and width
        // const parentWidth = document.getElementById(options.id).parentNode.parentElement.clientWidth;
        // const parentHeight = document.getElementById(options.id).parentNode.parentElement.clientHeight;
        const parentWidth = this.props.width;
        const parentHeight = this.props.height;

        // set the dimensions and margins of the graph
        const margin = {
            top: options.margin.top || 20,
            right: options.margin.right || 20,
            bottom: options.margin.bottom || 70,
            left: options.margin.left || 50
        };

        const width = (options.width || parentWidth) - margin.left - margin.right;

        const height = (options.height || parentHeight) - margin.top - margin.bottom;

        // Parse the date / time
        const parseDate = d3.timeParse(options.parseTime || '%d-%b-%y');
        const timeFormat = d3.timeFormat('%d-%b-%y');
        const tickformat = d3.timeFormat(options.tickFormat || '%d-%b-%y');

        const formatedNumber = d3.format(',');
        const decimalFormat = d3.format('.1f');

        // Tooltip div
        d3.select('#toolTip' + options.id).remove();
        const tooltip = d3.select('body').append('div').attr('class', 'toolTip');

        // data.sort((a, b) => {
        //   return a.xVal - b.xVal;
        // });

        // format the data
        data.forEach(d => d.yVal = +d.yVal);

        let x;
        let y;

        // Set the ranges
        if (chartStyle === 'horizontal') {
            x = d3.scaleBand().range([0, width]).padding(0.10);
            y = d3.scaleLinear().range([height, 0]);

            // Scale the range of the data in the domains
            x.domain(data.map(d => d.xVal));
            y.domain([
                0,
                // d3.max(data, d => d.yVal)
                d3.max([d3.max(data, d => d.yVal), d3.max(data, d => d.yVal + Math.round(d.yVal * 0.2))])
            ]);


        } else if (chartStyle === 'horizontal') {
            x = d3.scaleLinear().range([0, width]);
            // y = d3.scaleBand().range([height, 0]);

            y = d3.scaleBand().range([height, 0]).padding(0.10);

            // Scale the range of the data in the domains
            // x.domain([0, d3.max(data, d => d.yVal)]);
            // y.domain(data.map(d => { console.log(d); return d.xVal })).padding(0.1);
            x.domain([
                0,
                d3.max([d3.max(data, d => d.yVal), d3.max(data, d => d.yVal + Math.round(d.yVal * 0.2))])
            ]);
            y.domain(data.map(d => d.xVal));
        }

        // Adds the svg canvas
        const svg = d3.select('#' + options.id)
            .append('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .append('g')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

        if (options.yAxisText) {
            svg.append('text')
                .attr('x', -120)
                .attr('y', -50)
                .style('font-size', '13px')
                .style('font-weight', '400')
                .attr('fill', '#000')
                .attr('transform', 'rotate(-90)')
                // .attr('dx', '-15.2em')
                // .attr('dy', '-7em')
                .text(options.yAxisTextString || '');
        }

        if (options.xAxisText) {
            svg.append('text')
                .attr('x', width / 2)
                .attr('y', height + margin.bottom)
                .style('font-size', '13px')
                .style('font-weight', '400')
                .attr('fill', '#000')
                // .attr('transform', 'rotate(-90)')
                // .attr('dx', '-15.2em')
                // .attr('dy', '-7em')
                .text(options.xAxisTextString    || '');
        }

        // set the colour scale
        let color;

        if (options.colors) {
            if (Array.isArray(options.colors)) {
                color = d3.scaleOrdinal(options.colors);
            } else {
                color = d3.scaleOrdinal(d3[options.colors]);
            }
        } else {
            color = d3.scaleOrdinal(d3.schemeCategory10);
        }

        let bar;
        // append the rectangles for the bar chart
        if (chartStyle === 'horizontal') {
            bar = svg.selectAll('.bar')
                .data(data)
                .enter()
                .append('rect')
                .attr('class', 'bar')
                .attr('fill', (d, i) => {
                    return options.multiColorBar ? d.color = color(i) : d.color = color(d);
                })
                .attr('x', d => x(d.xVal) + (options.barWidth || 0) / 2)
                .attr('width', x.bandwidth() - options.barWidth || 0)
                .attr('y', d => y(d.yVal))
                .attr('height', d => height - y(d.yVal));

            if (options.valueOnTop) {
                svg.selectAll('.bar')
                    .data(data)
                    .enter().append('rect')
                    .attr('class', 'bar')
                    .attr('x', d => x(d.xVal))
                    .attr('width', x.bandwidth())
                    .attr('y', d => y(d.yVal))
                    .attr('height', d => height - y(d.yVal));

                svg.selectAll('text.bar')
                    .data(data)
                    .enter()
                    .append('text')
                    .attr('class', 'bar')
                    .style('font-size', '11px')
                    .style('font-family', 'Open Sans, sans-serif')
                    .attr('text-anchor', 'middle')
                    .attr('x', d => x(d.xVal) + x.bandwidth() / 2)
                    .attr('y', d => y(d.yVal) - 5)
                    .text(d => {
                        if (options.valueOnTopDecimal) {
                            return d.yVal;
                        }
                    });
            }

        } else if (chartStyle === 'horizontal') {
            bar = svg.selectAll('.bar')
                .data(data)
                .enter()
                .append('rect')
                .attr('class', 'bar')
                .attr('fill', (d, i) => options.multiColorBar ? d.color = color(i) : d.color = color(d))
                .attr('x', d => x(d.xVal))
                .attr('width', d => x(d.yVal))
                .attr('rx', 6)
                .attr('y', d => y(d.xVal) + (options.barWidth || 0) / 2)
                .attr('height', y.bandwidth() - options.barWidth || 0);
        }

        if (!options.hideXaxis) {
            // add the x Axis
            let xAxis;
            if (options.isTimeSeries) {
                xAxis = d3.axisBottom(x)
                    .ticks(ticks);
                // .tickFormat(tickformat)
                // .tickValues(x.domain().filter((d, i) => {
                //   return !(i % 6);
                // }));
            } else {
                xAxis = d3.axisBottom(x)
                    .ticks(ticks);
                // .scale(x);
            }

            const xAxisLine = svg.append('g')
                .attr('class', 'axis')
                .attr('transform', 'translate(0,' + height + ')')
                .call(xAxis);

            if (options.xAxisRotate) {
                xAxisLine.selectAll('text')
                    .style('text-anchor', 'end')
                    .attr('dx', '-.6em')
                    .attr('dy', '.10em')
                    .attr('transform', 'rotate(-10 )');
            }

            xAxisLine.select('path').attr('stroke', 'lightgray');
            xAxisLine.selectAll('line').attr('stroke', 'lightgray');

            // xAxisLine.selectAll('text')
            //   .text(d => this._sharedService.getMultilingualString(d));
        }

        if (!options.hideYaxis) {
            // add the y Axis
            const yAxisLine = svg.append('g')
                .attr('class', 'y-axis')
                .call(d3.axisLeft(y)
                    // .ticks(options.ticksSize)
                    // .tickFormat(d => {
                    //   return options.yAxisDivided ? formatedNumber(d / 1000) : d;
                    // })
                );

            yAxisLine.select('path').attr('stroke', 'lightgray');
            yAxisLine.selectAll('line').attr('stroke', 'lightgray');

            // yAxisLine.selectAll('text')
            //   .text(d => {
            //     const formated = options.yAxisDivided ? (d / 1000) : d;
            //     return this.lang === 'option1' ? this._sharedService.getMultilingualFormattedNumber(formated, '') : formatedNumber(formated);
            //   });
        }

        // Tooltip
        if (!options.hideTooltip) {
            bar.on('mousemove', d => mousemove(d))
                .on('mouseout', d => mouseout());
        }

        function mousemove(d) {
            tooltip.style('left', d3.event.pageX - 30 + 'px')
                .style('top', d3.event.pageY - 60 + 'px')
                .style('display', 'inline-block')
                .style('position', 'absolute')
                .style('min-width', '50px')
                .style('width', 'auto')
                .style('height', 'auto')
                .style('background', 'none repeat scroll 0 0 #ffffff')
                .style('border', '1px solid #fff')
                .style('border-radius', '5px 5px 5px 5px')
                .style('box-shadow', '-3px 3px 15px #888888')
                .style('padding', '5px')
                .style('text-align', 'left')
                .style('font-size', '12px')
                .style('font-family', 'Open Sans, sans-serif');
            if (options.isTimeSeries) {
                tooltip.html('<strong>' + timeFormat(d.xVal) + ' Axis</strong><br>' + (d.yVal) + ' Leads');
            } else {
                tooltip.html('<strong>' + d.xVal + ' Axis</strong><br>' + (d.yVal) + ' Leads');
            }
        }

        function mouseout() {
            tooltip.style('display', 'none');
        }

        d3.selectAll('.tick')
            .selectAll('text')
            .attr('fill', '#707070')
            .style('font-family', 'Open Sans, sans-serif')
            .style('font-size', '11px');

        // Resize chart
        // if (this.options.responsive) {
        //     this.onResize();
        // }
    }

    render() {
        this.draw(this.props.data, this.props.options);
        return (
            <div ref="child"
            id={
                this.props.options.id
            } > 
            </div>
        )
        }
    }
    
export default  HandOverGenratedChart;