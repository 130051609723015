import * as types from "./types";

export const setIdCRM_Master = (idcrm_master) => {
  return {
    type: types.SET_IDCRM_MASTER,
    payload: {idcrm_master: idcrm_master}
  }
}

export const setCRM_Name = (crm_name) => {
  return {
    type: types.SET_CRM_NAME,
    payload: {crm_name: crm_name}
  }
}

export const setCRMisActive = (isActive) => {
  return {
    type: types.SET_ISACTIVE,
    payload: {isActive: isActive}
  }
}

export const setCRMConfigured = (configured) => {
  return {
    type: types.SET_CONFIGURED,
    payload: {configured: configured}
  }
}

export const setIdCRM_Integration = (idcrm_integration) => {
  return {
    type: types.SETIDCRM_INTEGRATION,
    payload: { idcrm_integration: idcrm_integration }
  };
};

export default {
  setCRM_Name,
  setIdCRM_Master,
  setCRMisActive,
  setCRMConfigured,
  setIdCRM_Integration
};
