import axios from "../../common/api-config";
export default {
  getChildOrgList(search_text) {
    return axios.post('/users/getPendingUsers', { search_text }).then(response => {
      return response.data;
    });
  },
  getChildOrgListNew(search_text) {
    return axios.post('/account/getChildOrganizations', { }).then(response => {
      return response.data;
    });
  },

  canCreateChildOrg(search_text) {
    return axios.post('/account/canCreateChildOrganization', { }).then(response => {
      return response.data;
    });
  },
  getMyAccount() {
    const url = '/users/getMyAccount';
    return axios.post(url, {}).then(response => {
      return response.data.data.data.name;
    });
  },
  updateRegistrationUrl(account_id) {
    const url = '/account/updateRegistrationUrl';
    return axios.post(url, {account_id}).then(response => {
      return response.data.data;
    });
  }
}