import React from 'react';
import * as commonFunctions from '../common-functions';
import styles from './stepper.module.scss';
import cx from "classnames";

class Stepper extends React.Component {
    render() {
        let { currentSalesItem } = this.props;
        if(currentSalesItem){
            if(this.props.lead_status) {
                currentSalesItem.lead_status.name = this.props.lead_status;
            }
            currentSalesItem.lead_status_logs = this.props.statusLogs;
        }

        return (
            <div>
                {
                    (currentSalesItem && currentSalesItem.lead_status.name === 'Lead') || this.props.lead_status === 'Lead' ?
                        (
                            <div className={styles.stepper_container}>
                                <span className={cx(styles.stepper_span, styles.stepper_tab_1, styles.pipeline_stepper_span)}>
                                    {commonFunctions.getTimeDifference(currentSalesItem, 2)}
                                </span>
                            </div>
                        )
                        : (currentSalesItem && currentSalesItem.lead_status.name === 'Opportunity') || this.props.lead_status === 'Opportunity' ?
                            (
                                <div className={styles.stepper_container}>
                                    <span className={cx(styles.stepper_span, styles.stepper_tab_1, styles.pipeline_stepper_span)}>{commonFunctions.getTimeDifference(currentSalesItem, 2)}</span>
                                    <span className={cx(styles.stepper_span, styles.stepper_tab_2, styles.pipeline_stepper_span)}>{commonFunctions.getTimeDifference(currentSalesItem, 3)}</span>
                                </div>
                            ) : 
                            (currentSalesItem && currentSalesItem.lead_status.name === 'Proposal') || this.props.lead_status === 'Proposal' ?
                                (
                                    <div className={styles.stepper_container}>
                                        <span className={cx(styles.stepper_span, styles.stepper_tab_1, styles.pipeline_stepper_span)}>{commonFunctions.getTimeDifference(currentSalesItem, 2)}</span>
                                        <span className={cx(styles.stepper_span, styles.stepper_tab_2, styles.pipeline_stepper_span)}>{commonFunctions.getTimeDifference(currentSalesItem, 3)}</span>
                                        <span className={cx(styles.stepper_span, styles.stepper_tab_3, styles.pipeline_stepper_span)}>{commonFunctions.getTimeDifference(currentSalesItem, 4)}</span>
                                    </div>
                                ) : 
                                (currentSalesItem && currentSalesItem.lead_status.name === 'Negotiation' ) || this.props.lead_status === 'Negotiation' ?
                                    (
                                        <div className={styles.stepper_container}>
                                            <span className={cx(styles.stepper_span, styles.stepper_tab_1, styles.pipeline_stepper_span)}>{commonFunctions.getTimeDifference(currentSalesItem, 2)}</span>
                                            <span className={cx(styles.stepper_span, styles.stepper_tab_2, styles.pipeline_stepper_span)}>{commonFunctions.getTimeDifference(currentSalesItem, 3)}</span>
                                            <span className={cx(styles.stepper_span, styles.stepper_tab_3, styles.pipeline_stepper_span)}>{commonFunctions.getTimeDifference(currentSalesItem, 4)}</span>
                                            <span className={cx(styles.stepper_span, styles.stepper_tab_4, styles.pipeline_stepper_span)}>{commonFunctions.getTimeDifference(currentSalesItem, 5)}</span>
                                        </div>
                                    ) : 
                                    (currentSalesItem && currentSalesItem.lead_status.name === 'Closed') || this.props.lead_status === 'Closed' ?
                                        (
                                            <div className={styles.stepper_container}>
                                                <span className={cx(styles.stepper_span, styles.stepper_tab_1, styles.pipeline_stepper_span)}>{commonFunctions.getTimeDifference(currentSalesItem, 2)}</span>
                                                <span className={cx(styles.stepper_span, styles.stepper_tab_2, styles.pipeline_stepper_span)}>{commonFunctions.getTimeDifference(currentSalesItem, 3)}</span>
                                                <span className={cx(styles.stepper_span, styles.stepper_tab_3, styles.pipeline_stepper_span)}>{commonFunctions.getTimeDifference(currentSalesItem, 4)}</span>
                                                <span className={cx(styles.stepper_span, styles.stepper_tab_4, styles.pipeline_stepper_span)}>{commonFunctions.getTimeDifference(currentSalesItem, 5)}</span>
                                                <span className={cx(styles.stepper_span, styles.stepper_tab_5, styles.pipeline_stepper_span)}>{commonFunctions.getTimeDifference(currentSalesItem, 6)}</span>
                                            </div>
                                        ) : ''
                }

                { //for greyed out stepper
                    !currentSalesItem && !this.props.lead_status ? 
                    <div className={styles.stepper_container}>
                        <span className={cx(styles.stepper_span,  styles.pipeline_stepper_span)}>{commonFunctions.getTimeDifference(currentSalesItem, 2)}</span>
                        <span className={cx(styles.stepper_span,  styles.pipeline_stepper_span)}>{commonFunctions.getTimeDifference(currentSalesItem, 3)}</span>
                        <span className={cx(styles.stepper_span,  styles.pipeline_stepper_span)}>{commonFunctions.getTimeDifference(currentSalesItem, 4)}</span>
                        <span className={cx(styles.stepper_span,  styles.pipeline_stepper_span)}>{commonFunctions.getTimeDifference(currentSalesItem, 5)}</span>
                        <span className={cx(styles.stepper_span,  styles.pipeline_stepper_span)}>{commonFunctions.getTimeDifference(currentSalesItem, 6)}</span>
                    </div>
                    : ''
                }
            </div>
        )
    }
}
export default Stepper;