import React, { useEffect, useState, useRef } from "react";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import TableRow from "@material-ui/core/TableRow";
import MDTableCell from "@material-ui/core/TableCell";
import { Link } from "react-router-dom";
import styles from "./pending_request.module.scss";
import CustomTable from "./Table";
import styled from "styled-components";
import apis from "./apis";
import LargeScreenLoader from "../../common/Loaders/PulseLoader/pulse-loader";
import Select from "react-select";
import { showSnackbarMessage } from "../../common/common-functions";
import InviteUser from "../../common/InviteUser";
import Input from "@material-ui/core/Input";
import Button from '@material-ui/core/Button';

const TableCell = styled(MDTableCell)`
  padding: 10px;
  text-align: center;
`;

const CustomButton = styled.button`
  border: none;
  background-color: transparent;
  color: ${(props) =>
    props.disabled ? "gray" : props.danger ? "#F38478" : "#19D98B"};
  font-weight: bold;
  font-size: 1.5em;
`;

const SmallProfilePic = styled.img`
  width: 2.8125em;
  height: 2.8125em;
  border-radius: 1.5625em;
  margin-right: 0.9375em;
`;

const Seperator = styled.span`
  margin: 0em 1.25em 0em 1.25em;
  color: gray;
`;

const HeaderLink = styled.span`
  font-weight: 620 !important;
  font-size: 1em !important;
`;

const ButtonIcon = styled.img`
  width: 1em;
  margin-right: 0.375em;
`;

// const Input = styled.input`
//   border: none;
//   font-style: italic;
//   width: 19.375em;
// `;

const SearchInputContainer = styled.div`
  border: 0.0625em solid #e3e3e3;
  border-radius: 0.125em;
  padding: 0.3125em;
  padding-left: 0.625em;
  text-align: right;
  width: 21.875em;
  float: right;
`;

const Span = styled.span`
  font-size: 0.875em !important;
`;

const UnderLinedText = styled(Span)`
  text-decoration: underline;
`;

const SearchInput = (props) => {
  const [searchText, setSearchText] = useState("");
  const { onSearch, ...rest } = props;
  const searchRef = useRef(null);
  const handleOnChange = (e) => {
    props.onSearch(e.target.value);
    setSearchText(e.target.value);
  };

  // const handleOnSearch = () => {
  //   props.onSearch(searchRef.current.value);
  // }

  const handleOnIconClick = () => {
    props.onSearch(searchRef.current.value);
  };

  return (
    <SearchInputContainer>
      <div style={{ display: "flex", fontStyle: 'italic' }}>
        <Input
          {...rest}
          ref={searchRef}
          onKeyUp={handleOnChange}
          onChange={(e) => { props.onSearch(e.target.value) }}
          type="search"
          fullWidth={true}
          disableUnderline={true}
        />
        <span style={{ display: 'flex' }}>
          <img
            style={{ width: 20 }}
            src={require("../../../assets/images/administration/Rounded.svg")}
          />
        </span>
      </div>
    </SearchInputContainer>
  );
};

const headers = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    sort: true,
  },
  {
    id: "gender",
    numeric: false,
    disablePadding: true,
    label: "Gender",
    sort: true,
  },
  {
    id: "phone_number",
    numeric: false,
    disablePadding: true,
    label: "Phone No.",
    sort: true,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
    sort: true,
  },
  {
    id: "role_assigned",
    numeric: false,
    disablePadding: true,
    label: "Role Assigned",
  },
  {
    id: "organization",
    numeric: false,
    disablePadding: true,
    label: "Organization",
  },
  {
    id: "department",
    numeric: false,
    disablePadding: true,
    label: "Department",
  },
  { id: "actions", numeric: false, disablePadding: true, label: "Actions" },
];

function getRoleById(roles, id) {
  return roles.find((role) => role.value == id);
}

export default function PendingRequest({ history }) {
  const [data, setData] = useState([]);
  const [fdata, setFdata] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [userRoles, setUserRoles] = useState({});
  const [userAccounts, setUserAccounts] = useState({});
  const [userDepartments, setUserDepartments] = useState({});
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [organizationName, setOrganizationName] = useState('');

  useEffect(() => {
    fetchOrganizationList();
    fetchRoleOptions();
    fetchDepartments();
    fetchMyAccount();
  }, []);

  useEffect(() => {
    fetchPendingUserList();
  }, [searchText]);

  const fetchMyAccount = async () => {
    const name = await apis.getMyAccount();
    setOrganizationName(name);
  }

  const fetchDepartments = async () => {
    const result = await apis.getAllDepartments();
    setDepartmentOptions(
      result.data.rows.map((r) => ({ label: r.name, value: r.id }))
    );
  };

  const fetchOrganizationList = async () => {
    const result = await apis.getOrganizationList();
    setOrganizations(
      result.data.rows.map((o) => ({ label: o.name, value: o.id }))
    );
  };

  const fetchRoleOptions = async () => {
    const result = await apis.getLicenses();
    setRoleOptions(
      result.data.licenses.map((l) => ({ value: l.id, label: l.display_name }))
    );
  };

  const getAccountId = (user_id) => {
    const row = data.data.rows.find((r) => r.id === user_id);
    return (
      (userAccounts[user_id] && userAccounts[user_id].value) ||
      (row && row.account_id)
    );
  };

  const getRoleId = (user_id) => {
    const row = data.data.rows.find((r) => r.id === user_id);
    return (
      (userRoles[user_id] && userRoles[user_id].value) || (row && row.license_type_id)
    );
  };

  const getDepartmentId = (user_id) => {
    const row = data.data.rows.find((r) => r.id === user_id);
    return (
      (userDepartments[user_id] && userDepartments[user_id].value) || (row && row.department_id)
    );
  };

  const fetchPendingUserList = async () => {
    setIsLoading(true);
    const result = await apis.getPendingUsers(searchText);
    setData(result.data);
    setFdata(result.data.data.rows);
    console.log(result.data.data.rows, "Pending User List");
    setIsLoading(false);
  };

  const fetchPendingUserListNew = async () => {
    // setIsLoading(true);
    const result = await apis.getPendingUsers(searchText);
    setData(result.data);
    setFdata(result.data.data.rows);
    console.log(result.data.data.rows, "Pending User List");
    // setIsLoading(false);
  };

  const handleOnApprove = (id) => async () => {
    // 1 is for approved
    setIsLoading(true);
    const result = await apis.updateUserApproval(
      1,
      id,
      getRoleId(id),
      getAccountId(id),
      getDepartmentId(id)
    );
    setIsLoading(false);
    if (result.statusCode === 200) {
      showSnackbarMessage(result.data, "snack-success");
    } else {
      showSnackbarMessage(result.errorMessage, "snack-error");
    }
    fetchPendingUserListNew();
    console.log(result, "Result");
  };

  const handleOnReject = (id) => async () => {
    setIsLoading(true);
    console.log(getRoleId(id), getAccountId(id), "Going for API call");
    const result = await apis.updateUserApproval(
      2,
      id,
      getRoleId(id),
      getAccountId(id),
      getDepartmentId(id)
    );

    if (result.statusCode === 200) {
      showSnackbarMessage(result.data, "snack-success");
    } else {
      showSnackbarMessage(result.errorMessage, "snack-error");
    }

    fetchPendingUserList();
    setIsLoading(false);
    console.log(result, "Result");
  };

  const handleOnRoleChange = (id) => (newRole) => {
    setUserRoles({
      ...userRoles,
      [id]: newRole,
    });
  };

  const handleOnAccountChange = (id) => (newAccount) => {
    setUserAccounts({
      ...userAccounts,
      [id]: newAccount,
    });
  };

  const handleOnDepartmentChange = (id) => (newDepartment) => {
    setUserDepartments({
      ...userDepartments,
      [id]: newDepartment,
    });
  };

  const handleOnSearch = (searchString) => {
    const filterRow = data.data.rows.filter((row) => {
      if (
        row.name_without_initials
          .toLowerCase()
          .includes(searchString.toLowerCase()) ||
        row.email.toLowerCase().includes(searchString.toLowerCase())
      ) {
        return true;
      }
      return false;
    });
    setFdata(filterRow);
  };

  return (
    <>
      {isLoading ? (
        <LargeScreenLoader />
      ) : (
          <div className="main-wrapper">
            <div className="breadcrumbs">
              <div className="row justify-content-between">
                <div className="col-md-4">
                  <h3 className={styles.title_Administration}>
                    Pending Requests
                </h3>
                  <div className="breadcrumbs_container">
                    <Breadcrumbs separator="›" arial-label="Breadcrumb">
                      <Link to="/sales-news">Home</Link>
                      <Link to="/administration">Administration</Link>
                      <Link to="/administration/user_management">User Management</Link>
                      <span>Pending Requests</span>
                    </Breadcrumbs>
                  </div>
                </div>
                <div className="col-md text-right">
                  <label className="font-weight-bold pointer">
                    {organizationName}
                      </label>
                  <span className="mx-2"></span>
                      |
                      <span className="mx-2"></span>
                  <InviteUser />
                  <span className="mx-2"></span>
                  <Button onClick={() => history.push('/administration/user_management/bulk_upload')} variant="contained" size="medium" style={{ 'backgroundColor': '#232f44', 'color': 'white' }}>
                    Bulk Upload
                      </Button>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className={"col-md-6 " + styles.request_title}>
                <HeaderLink>
                  User Self Registration Requests ({data.data && data.data.count})
              </HeaderLink>
              </div>
              <div className={"col-md-6 " + styles.search_box}>
                <SearchInput
                  onSearch={handleOnSearch}
                  placeholder="Search By Name or Email"
                />
              </div>
            </div>
            <hr />
            <div id="pendingusers" style={{ overflow: "overlay" }}>
              <CustomTable
                count={fdata.length}
                headers={headers}
                data={fdata}
                initialSortField="name"
                renderRow={(row, i) => (
                  <TableRow key={row.id}>
                    <TableCell style={{ textAlign: "left" }}>
                      <Span style={{ display: "flex", alignItems: "center" }}>
                        <SmallProfilePic
                          src={require("../../../assets/images/bi/employeeMissing.png")}
                        />
                        <UnderLinedText>{row.name}</UnderLinedText>
                      </Span>
                    </TableCell>
                    <TableCell style={{ textAlign: "left" }}>
                      <Span>{row.gender}</Span>
                    </TableCell>
                    <TableCell style={{ textAlign: "left", minWidth: 110 }}>
                      <Span>{row.countryCode ? `+${row.countryCode.dialing_code}` : ''} {row.contact}</Span>
                    </TableCell>
                    <TableCell style={{ textAlign: "left" }}>
                      <Span>{row.email}</Span>
                    </TableCell>
                    <TableCell style={{ minWidth: 160 }}>
                      <Select
                        options={roleOptions}
                        placeholder="Select Role"
                        value={getRoleById(
                          roleOptions,
                          userRoles[row.id] || row.license_type_id
                        )}
                        onChange={handleOnRoleChange(row.id)}
                      />
                      {console.log(
                        roleOptions,
                        row.license_type_id,
                        "ROle",
                        getRoleById(
                          roleOptions,
                          userRoles[row.id] || row.license_type_id
                        )
                      )}
                    </TableCell>
                    <TableCell style={{ minWidth: 180 }}>
                      <Select
                        options={organizations}
                        placeholder="Select Organization"
                        value={getRoleById(
                          organizations,
                          userAccounts[row.id] || row.account_id
                        )}
                        onChange={handleOnAccountChange(row.id)}
                      />
                    </TableCell>
                    <TableCell style={{ minWidth: 160 }}>
                      <Select
                        options={departmentOptions}
                        placeholder="Select Department"
                        value={getRoleById(
                          departmentOptions,
                          userDepartments[row.id] || row.department_id
                        )}
                        onChange={handleOnDepartmentChange(row.id)}
                      />
                    </TableCell>
                    <TableCell style={{ minWidth: 120 }}>
                      <CustomButton
                        disabled={
                          !getAccountId(row.id) ||
                          !getRoleId(row.id) ||
                          !getDepartmentId(row.id)
                        }
                        onClick={handleOnApprove(row.id)}
                      >
                        <ButtonIcon
                          src={require("../../../assets/images/pending-requests/tick.svg")}
                        />
                      </CustomButton>
                      <CustomButton
                        danger
                        onClick={handleOnReject(row.id)}
                        style={{ marginLeft: 8 }}
                      >
                        <ButtonIcon
                          src={require("../../../assets/images/pending-requests/x-mark.svg")}
                        />
                      </CustomButton>
                    </TableCell>
                  </TableRow>
                )}
              />
            </div>
          </div>
        )}
    </>
  );
}
