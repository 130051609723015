import React from "react";
import Card from "@material-ui/core/Card";
import { connect } from "react-redux";
import * as actions from "../redux/actions";
import moment from "moment";
import styles from './aside-right.module.scss';
import cx from "classnames";
import { imageType } from '../../../common/common-types';
import sound from "../../../../assets/sounds/ring_bell.mp3";

class AsideRight extends React.Component {
  constructor(props) {
    super(props);
    this.audio = new Audio(sound);
  }


  handleClick = (id, win, name, namecontent, companyName) => {
    this.props.history.push({
      pathname: `/sales-news/sales-feed/${id}`,
      state: {
        win: win,
        name: name,
        namecontent: namecontent,
        companyName: companyName
      }
    });
  };

  readSalesFeedItem = (feedItem, index) => {
    let params = {
      "sales_feed_id": feedItem.id,
      "user_id": this.props.currentUser.user_id,
      "is_read": 1
    }
    this.props.markAsRead(params);

    let element = document.getElementById("feed-item_" + index);
    element.classList.remove(styles.unread_item);
  }

  detailedAsideRight = (salesFeedItem) => {
    if (salesFeedItem.action_type === true && salesFeedItem.sales_feed_reads.length === 0) {
      this.audio.play();
    }
    this.props.history.push({
      pathname: `/sales-news/sales-feed/${salesFeedItem.id}`,
      state: {
        status: salesFeedItem.action_type,
        name: salesFeedItem.action_type != 3 ? salesFeedItem.user.name : "",
        sameCreator: salesFeedItem.lead && salesFeedItem.lead.createdBy && salesFeedItem.lead.createdBy.id === salesFeedItem.user.id,
        namecontent: salesFeedItem.action_type == 1 ? "has won a contract with" : salesFeedItem.action_type == 2 ? "has entered a new lead with" : salesFeedItem.action_type == 3 ? "has added a new lead to be approved by" : "NEW lead to be approved by Sales.",
        companyName: salesFeedItem.action_type != 3 ? salesFeedItem.contact_company.company_name : "",
        leadCreated: salesFeedItem.lead.createdBy.name,
        salesFeedItem
      }
    })
  }


  componentDidMount() {
    const requestBody = this.props.salesFeedRequestBody

    requestBody.organizationArray = this.props.selectedOrganizationList
    requestBody.paginate = {
      page: "0",
      limit: "10"
    }
    this.props.initializeSalesFeeds(requestBody);
  }

  componentDidUpdate(prevProps, prevState) {


    if (prevProps.selectedOrganizationList !== this.props.selectedOrganizationList && this.props.selectedOrganizationList.length !== 0) {
      const requestBody = this.props.salesFeedRequestBody

      //requestBody.user_id= this.props.currentUser.user_id
      requestBody.organizationArray = this.props.selectedOrganizationList
      requestBody.paginate = {
        page: "0",
        limit: "10"
      }

      this.props.initializeSalesFeeds(requestBody);
    }
  }

  render() {
    const { salesFeeds } = this.props;
    return (
      <div className={`right-container row`} ref={this.paneDidMount}>
        {salesFeeds && salesFeeds.length > 0 ? (
          salesFeeds.map((gridItem, index) => (

            <div

              className={`col-xl-12 col-lg-12 col-md-12 col-10 right-grid-col ${styles.colMargin}`}
              key={index}

            //onClick={() => this.handleClick(index+1, gridItem.win, gridItem.name, gridItem.namecontent, gridItem.companyName)}
            >
              <div className="right_bottom_section_margin"
                onClick={() => {
                  this.readSalesFeedItem(gridItem, index)
                  this.detailedAsideRight(gridItem)
                }}
              >
                <div className={styles.notification_inner_grid} id={"feed-item_" + index}>
                  <Card style={{ height: "111px" }}
                    className={
                      cx({
                        ["grid-card right-grid-card notification-grid-card"]: gridItem.action_type == 1 && gridItem && gridItem.sales_feed_reads && gridItem.sales_feed_reads[0] && gridItem.sales_feed_reads[0].is_read,
                        ["grid-card right-grid-card " + styles.unread_won_item]: gridItem.action_type == 1 && gridItem && gridItem.sales_feed_reads && gridItem.sales_feed_reads.length == 0,
                        ["grid-card right-grid-card"]: gridItem.action_type > 1 && gridItem && gridItem.sales_feed_reads && gridItem.sales_feed_reads[0] && gridItem.sales_feed_reads[0].is_read,
                        ["grid-card right-grid-card " + styles.unread_item]: gridItem.action_type > 1 && gridItem && gridItem.sales_feed_reads && gridItem.sales_feed_reads.length == 0,
                      })
                    }
                  >
                    <div className="notification-icon">
                      <div className="grid-header">
                        <div className="grid-inner-head">
                          <div className="grid-icon-container">
                            {
                              gridItem.account && gridItem.accountCreatedBy &&gridItem.account.id != gridItem.accountCreatedBy.id ?
                                <img className={styles.feed_img}
                                  src={require("../../../../assets/ic_handover.svg")}
                                  alt={"user pic"}
                                  height="36px"
                                  width="36px"
                                />
                              :
                              gridItem.user && gridItem.user.user_avatar &&
                                imageType.test(gridItem.user.user_avatar) ?
                                <img className={styles.feed_img}
                                  src={
                                    gridItem.user ?
                                      gridItem.user.user_avatar.slice(0, gridItem.user.user_avatar.lastIndexOf('/')) + "/small_" +
                                      gridItem.user.user_avatar.substring(gridItem.user.user_avatar.lastIndexOf("/") + 1)
                                      : null}
                                  alt={"user pic"}
                                  height="36px"
                                  width="36px"
                                /> : <img className={styles.feed_img}
                                  src={require("../../../../assets/userProfile.jpeg")}
                                  alt={"user pic"}
                                  height="36px"
                                  width="36px"
                                />}
                          </div>
                          {gridItem.action_type == 1 || gridItem.action_type == 2 ?
                            <div style={{ marginLeft: "16px" }} className="grid-user-name">
                              <span className={styles.newsTime}>
                                {gridItem.account && gridItem.account.name}
                              </span>
                              <span style={{ "WebkitBoxOrient": "vertical" }} className={styles.spanClass}>
                                <span className="name-content">
                                {
                                        gridItem.account && gridItem.accountCreatedBy ? 
                                        (gridItem.account.id == gridItem.accountCreatedBy.id) ?
                                          gridItem.user ? gridItem.user.name : "" :
                                          gridItem.accountCreatedBy.name
                                         :
                                        (
                                         gridItem.user ? gridItem.user.name : ""
                                        )
                                      }
                                </span>
                                <span className="content-text">
                                  {gridItem.action_type == 1
                                    ? " has won a contract with "
                                    : " has entered a new lead with "}
                                </span>
                                <span className="company-name">
                                  {(gridItem.action_type != 1 && gridItem.contact_company) ? gridItem.contact_company && gridItem.contact_company.company_name + "." : gridItem.contact_company && gridItem.contact_company.company_name + "!"}
                                </span>
                              </span>

                              <div className={styles.newsTime}>
                                {moment(gridItem.created_at).fromNow()}
                              </div>
                            </div>
                            : (
                              gridItem.action_type === 3 ?
                                <div style={{ marginLeft: "16px" }} className="grid-user-name">
                                  <span className={styles.newsTime}>
                                    {gridItem.account && gridItem.account.name}
                                  </span>
                                  <span style={{ "WebkitBoxOrient": "vertical" }} className={styles.spanClass}>
                                    <span className="name-content">
                                      {/* {" NEW lead "} */}
                                      {
                                        gridItem.account && gridItem.accountCreatedBy ? 
                                        (gridItem.account.id == gridItem.accountCreatedBy.id) ?
                                          gridItem.user ? gridItem.user.name : "" :
                                          gridItem.accountCreatedBy.name
                                         :
                                        (
                                         gridItem.user ? gridItem.user.name : ""
                                        )
                                      }
                                    </span>
                                    <span className="content-text">
                                      {" has added a new lead to be approved by "}
                                    </span>
                                    <span className="company-name">
                                      {" Sales."}
                                    </span>
                                  </span>
                                  <div className={styles.newsTime}>
                                    {moment(gridItem.created_at).fromNow()}
                                  </div>
                                </div>

                                : ''
                            )
                          }
                        </div>
                      </div>

                    </div>
                  </Card>
                </div>
              </div>
            </div>
          ))
        ) : (
            <div className="col-md-12 no-records">
              {/* No sales feeds found. */}
              Be the first one to add a new lead by clicking the 'Create new lead' button on the top header.
          </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    salesFeeds: state.salesFeeds.salesFeeds,
    errorLoadingSalesFeeds: state.salesFeeds.errorLoadingSalesFeeds,
    totalSalesFeed: state.salesFeeds.totalSalesFeed,
    selectedOrganizationList: state.salesNews.selectedOrganizationList

  };
};

const mapDispatchToProps = dispatch => {
  return {
    initializeSalesFeeds: requestBody => dispatch(actions.initializeSalesFeeds(requestBody)),
    loadMoreSalesFeeds: requestBody => dispatch(actions.loadMoreSalesFeeds(requestBody)),
    markAsRead: requestBody => dispatch(actions.markAsRead(requestBody)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AsideRight);
