import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { dayjs } from "./utils";
import { Table } from "./Table";
import CalendarBody from "./CalendarBody";
import CalendarHead from "./CalendarHead";
import PickerUI from "./PickerUI";
import styled from 'styled-components';
import moment from 'moment';

const Label = styled.div
`
  background-color: #F5F5F5;
  border-radius: 10px;
  margin-top: 10px;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  text-align: left;
  font-size: 12px !important;
  cursor: pointer;
`;

const Header = styled.div
`
  margin-top: 10px;
  font-weight: bold;
  color: #606060;
  font-size: 14px !important;
`;

function getQuarter (minDate, quarter) {
  const fromDate = moment(minDate);
  return { startDate: fromDate.clone().add('months', (quarter - 1) * 3), endDate: fromDate.clone().add('months', quarter * 3).add('days', -1) }
}
class LinkedCalendar extends React.Component {
  static propTypes = {
    opens: PropTypes.string.isRequired,
    position: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      leftCalendar: dayjs(
        props.dateRange ? props.dateRange.from_date.toDate() : new Date()
      ),
      rightCalendar: dayjs(
        props.dateRange ? props.dateRange.from_date.toDate() : new Date()
      ).add(1, "month"),
    };
  }

  static defaultProps = {
    position: "left",
  };
  createProps = () => {
    const { leftCalendar, rightCalendar } = this.state;
    const leftState = Object.assign({}, this.props, { calendar: leftCalendar });
    const rightState = Object.assign({}, this.props, {
      calendar: rightCalendar,
    });
    const { handlePrev, handleNext } = this;
    return {
      leftProps: {
        handlePrev,
        handleNext: () => {},
        handleSelected: handlePrev,
        showNext: false,
        ...leftState,
      },
      rightProps: {
        showPrev: false,
        handlePrev: () => {},
        handleNext,
        handleSelected: handleNext,
        ...rightState,
      },
    };
  };

  handlePrev = (leftCalendar) => {
    this.setState({
      leftCalendar,
      rightCalendar: leftCalendar.add(1, "month"),
    });
  };

  handleNext = (rightCalendar) => {
    this.setState({
      rightCalendar,
      leftCalendar: rightCalendar.subtract(1, "month"),
    });
  };

  renderTable = (onDatesChange, minDate) => {
    const props = this.createProps();
    const { leftProps, rightProps } = props;
    const className = classNames({
      "drp-calendar": true,
      left: true,
    });

    return [
      <div className={className} key={0}>
        <div style={{ minWidth: 150, textAlign: "center" }}>
          <Header>
            Select Quarter
          </Header>
          <Label onClick={() => onDatesChange(getQuarter(minDate, 1))}>Quarter 1</Label>
          <Label onClick={() => onDatesChange(getQuarter(minDate, 2))}>Quarter 2</Label>
          <Label onClick={() => onDatesChange(getQuarter(minDate, 3))}>Quarter 3</Label>
          <Label onClick={() => onDatesChange(getQuarter(minDate, 4))}>Quarter 4</Label>
        </div>
      </div>,
      <div className={className} key={1}>
        <div className="calendar-table">
          <Table className="table-condensed">
            <CalendarHead {...leftProps} />
            <CalendarBody {...leftProps} />
          </Table>
        </div>
      </div>,
      <div className={className} key={2}>
        <div className="calendar-table">
          <Table className="table-condensed">
            <CalendarHead {...rightProps} />
            <CalendarBody {...rightProps} />
          </Table>
        </div>
      </div>,
    ];
  };
  render() {
    const { opens, children, onDatesChange, minDate } = this.props;
    const className = classNames({
      [`opens${opens}`]: true,
      "daterangepicker ltr show-calendar": true,
    });
    return (
      <div
        className={className}
        style={{
          left: "auto",
          display: "block",
        }}
      >
        {this.renderTable(onDatesChange, minDate)}
        {children}
      </div>
    );
  }
}

export default class LinkedCalendarUI extends React.Component {
  render() {
    const uiProps = { ...this.props, component: LinkedCalendar };
    return <PickerUI {...uiProps} />;
  }
}
