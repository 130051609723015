import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./TasksComponent.module.scss";
import moment from "moment";
import SmallLoader from "../../../common/Loaders/CircularLoader/circular-loader";
import Checkbox from "../../../common/UI/ActivitiesCheckbox/ActivitiesCheckbox";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import NoRecordsFound from "../../../components/ErrorMessages/NoRecordsFound/NoRecordsFound";
import OopsError from "../../../components/ErrorMessages/OopsError/OopsError";
import _ from "lodash";
import contactIcon from "../../../../assets/images/content-images/contact-icon.png";
import companyIcon from "../../../../assets/images/content-images/company-icon.png";

const stylesMaterialUI = (theme) => ({
  button: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    marginLeft: 0,
    marginRight: 0,
  },
});

function getLeadObj(obj) {
  return obj && obj[0] && obj[0].lead;
}

class TasksComponent extends Component {
  state = {
    pop_open: false,
    item: null,
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate
    ) {
      const requestBody = {
        arrayFilters: [
          {
            dateFrom: this.props.startDate,
            dateTo: this.props.endDate,
          },
        ],
        selectFilters: [],
        sort: {
          field: "activity_time",
          sortOrder: "ASC",
        },
        paginate: {
          page: 0,
          limit: 1000,
        },
        type: "TASK",
        //user_id: JSON.parse(localStorage.getItem("user")).user_id
      };

      this.props.getSalesTasks(requestBody);
    }
  }

  handleClick = (e, task, id) => {
    e.preventDefault();
    this.setState({
      [id]: true,
      anchorEl: e.currentTarget,
      item: task,
    });
  };

  handleClose = (id) => {
    this.setState({
      [id]: undefined,
      item: null,
    });
  };

  handlePopoverMenuClicked = (value, id) => {
    if (value === "edit") {
      this.props.handleUpdateTask(this.state.item);
    } else {
      this.props.handleDeleteTask(this.state.item.id);
    }
    this.handleClose(id);
  };

  render() {
    const dates = this.props.salesTasks
      ? this.props.salesTasks.map((date) => Object.keys(date)[0])
      : null;

    const { salesTasks, errorLoadingSalesTasks, loadingTasks } = this.props;
    return (
      <div className={styles.activities_container_body}>
        {errorLoadingSalesTasks ? (
          <div>
            <OopsError />
          </div>
        ) : loadingTasks ? (
          <SmallLoader />
        ) : salesTasks ? (
          salesTasks.length === 0 ? (
            <div>
              <NoRecordsFound />
            </div>
          ) : (
            salesTasks.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <div className={styles.date_container}>
                    <p className={styles.date}>
                      {moment().format("MMMM Do, YYYY") ===
                        moment(dates[index]).format("MMMM Do, YYYY") &&
                      this.props.today
                        ? `Today, ${moment(dates[index]).format(
                            "D MMMM, YYYY"
                          )}`
                        : `${moment(dates[index]).format(
                            "dddd, D MMMM, YYYY"
                          )}`}
                    </p>
                  </div>
                  <div className={styles.activities}>
                    <>
                      {item[dates[index]].map((task, i) => {
                        if (Object.keys(task)[0] === "NO_LEAD") {
                          return (
                            <div className={styles.tasks} key={"task_" + i}>
                              {task[Object.keys(task)[0]].map((t) => {
                                return (
                                  <div key={t.id} className={styles.task}>
                                    <Checkbox
                                      task={t}
                                      startDate={this.props.startDate}
                                      endDate={this.props.endDate}
                                      from="activities"
                                    />
                                    <IconButton
                                      onClick={(e) =>
                                        this.handleClick(e, t, t.id)
                                      }
                                    >
                                      <span className={styles.dots} />
                                      <span className={styles.dots} />
                                      <span className={styles.dots} />
                                    </IconButton>
                                    <Popover
                                      open={
                                        this.state[`${t.id}`] === undefined
                                          ? false
                                          : this.state[`${t.id}`]
                                      }
                                      anchorEl={this.state.anchorEl}
                                      targetorigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                      }}
                                      onClose={() => this.handleClose(t.id)}
                                    >
                                      <MenuList>
                                        {!t.is_completed ? (
                                          <MenuItem
                                            onClick={() =>
                                              this.handlePopoverMenuClicked(
                                                "edit",
                                                t.id
                                              )
                                            }
                                            className={styles.menuitem}
                                          >
                                            <span className={styles.editicon} />
                                            Edit
                                          </MenuItem>
                                        ) : null}
                                        <MenuItem
                                          onClick={() =>
                                            this.handlePopoverMenuClicked(
                                              "delete",
                                              t.id
                                            )
                                          }
                                          className={styles.menuitem}
                                        >
                                          <span className={styles.deleteicon} />
                                          Delete
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        } else {
                          const key = Object.keys(task)[0];
                          const lead = getLeadObj(task[key]);
                          const company_name = _.get(lead, [
                            "contact_company",
                            "company_name",
                          ]);
                          const contact_person = _.get(lead, [
                            "contact_person",
                            "name",
                          ]);
                          return (
                            <div className={styles.tasks} key={"task_" + i}>
                              <div className={styles.tasks_with_lead}>
                                <p className={styles.lead_name}>{key}</p>
                                <div className={styles.contact_detail}>
                                  {company_name && (
                                    <div className={styles.contact}>
                                      <img
                                        src={companyIcon}
                                        alt="Company Icon"
                                      />
                                      <h6
                                        className={styles.contact_name}
                                        title={company_name}
                                      >
                                        {company_name}
                                      </h6>
                                    </div>
                                  )}
                                  {contact_person && <div className={styles.contact}>
                                    <img src={contactIcon} alt="Contact Icon" />
                                    <h6
                                      className={styles.contact_name}
                                      title={contact_person}
                                    >
                                      {contact_person}
                                    </h6>
                                  </div>}
                                </div>
                                {task[key].map((t) => {
                                  return (
                                    <div className={styles.task} key={t.id}>
                                      <Checkbox
                                        task={t}
                                        startDate={this.props.startDate}
                                        endDate={this.props.endDate}
                                        from="activities"
                                      />
                                      <IconButton
                                        onClick={(e) =>
                                          this.handleClick(e, t, t.id)
                                        }
                                      >
                                        <span className={styles.dots} />
                                        <span className={styles.dots} />
                                        <span className={styles.dots} />
                                      </IconButton>
                                      <Popover
                                        open={
                                          this.state[`${t.id}`] === undefined
                                            ? false
                                            : this.state[`${t.id}`]
                                        }
                                        anchorEl={this.state.anchorEl}
                                        anchorOrigin={{
                                          vertical: "top",
                                          horizontal: "left",
                                        }}
                                        targetorigin={{
                                          vertical: "top",
                                          horizontal: "left",
                                        }}
                                        onClose={() => this.handleClose(t.id)}
                                      >
                                        <MenuList>
                                          {!t.is_completed &&
                                          !(
                                            t.lead &&
                                            t.lead.lead_current_status_id === 6
                                          ) ? (
                                            <MenuItem
                                              onClick={() =>
                                                this.handlePopoverMenuClicked(
                                                  "edit",
                                                  t.id
                                                )
                                              }
                                            >
                                              <span
                                                className={styles.editicon}
                                              />
                                              Edit
                                            </MenuItem>
                                          ) : null}
                                          <MenuItem
                                            onClick={() =>
                                              this.handlePopoverMenuClicked(
                                                "delete",
                                                t.id
                                              )
                                            }
                                          >
                                            <span
                                              className={styles.deleteicon}
                                            />
                                            Delete
                                          </MenuItem>
                                        </MenuList>
                                      </Popover>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        }
                      })}
                    </>
                  </div>
                </React.Fragment>
              );
            })
          )
        ) : null}
      </div>
    );
  }
}

export default withStyles(stylesMaterialUI)(TasksComponent);
