import React from "react";
import SmallLoader from "../../../common/Loaders/CircularLoader/circular-loader";
import styles from "./NotesListingComponent.module.scss";
import moment from "moment";
import NoRecordsFound from "../../ErrorMessages/NoRecordsFound/NoRecordsFound";
import OopsError from "../../ErrorMessages/OopsError/OopsError";
import { getStorageItem } from "../../../common/common-functions";

const notesListing = (props) => {
  const dates = props.notesList
    ? props.notesList.map((date) => Object.keys(date)[0])
    : null;

  const { notesList, errorLoadingNotes, loadingNotes } = props;

  const getOrganizationId = () => {
    return JSON.parse(getStorageItem("user")).organization_id;
  };

  return (
    <div
      className={styles.activities_container_body}
      style={{ maxHeight: "242px" }}
    >
      {errorLoadingNotes ? (
        <div>
          <OopsError />
        </div>
      ) : loadingNotes ? (
        <SmallLoader />
      ) : notesList ? (
        notesList.length === 0 ? (
          <div>
            <NoRecordsFound />
          </div>
        ) : (
          notesList.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <div className={styles.date_container}>
                  <p className={styles.date}>
                    {moment().format("MMMM Do, YYYY") ===
                    moment(dates[index]).format("MMMM Do, YYYY")
                      ? `Today ${moment(dates[index]).format("D MMMM, YYYY")}`
                      : moment(dates[index]).format("dddd, D MMMM, YYYY")}
                  </p>
                </div>
                <hr />
                <div className={styles.notes}>
                  {item[dates[index]].map((task, i) => {
                    return (
                      <div key={"notes_" + i} className={styles.note}>
                        <p
                          className={
                            styles.description + " " + styles.wordBreakClass
                          }
                        >
                          {task.description ? task.description : ""}
                        </p>
                        <div className={styles.footer}>
                          <span>
                            <>
                              <span className={styles.name}>
                                {getOrganizationId() === task.user.account.id
                                  ? task.user.name
                                  : task.user.account.name
                                  ? task.user.account.name
                                  : " "}
                              </span>
                              <span className={styles.department}>
                                {` (${task.user.department ? task.user.department.name : ''})`}
                              </span>
                            </>
                          </span>

                          <span className={styles.time}>
                            {task.created_at
                              ? moment(task.created_at).format("hh:mm")
                              : ""}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </React.Fragment>
            );
          })
        )
      ) : null}
    </div>
  );
};

export default notesListing;
