import React from 'react';
import Input from "@material-ui/core/Input";

export default function MyInput(props) {
  return (
    <Input 
      inputProps={{ style: { height: '1.6em', paddingLeft: '0.75em', borderRadius: 3, border: '0.75px solid #D3D3D3', backgroundColor: '#FFF' } }} 
      fullWidth={true} 
      disableUnderline={true}
      {...props} 
    />
  );
}