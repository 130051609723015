import axios from "../../../common/api-config";

export default {
  isRegistered() {
    return axios.post('/login/isRegister', {}).then(response => {
      console.log(response);
      return response.data;
    });
  },

  getVersion() { 
    return axios.post('/login/getCurrentVersionDetails',{}).then(response => {
      console.log("version",response);
      return response.data;
    });
  },
}