import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import "cropperjs/dist/cropper.css";
import Dialog from "@material-ui/core/Dialog";
import Cropper from "react-cropper";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import styles from "./CropperDialog.module.scss";

const DialogTitle = withStyles(theme => ({
    root: {
        width: "100%",
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500]
    }
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="Close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        width: "100%",
        margin: 0,
        padding: "24px"
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        width: "100%",
        borderTop: `0`,
        margin: 0
    }
}))(MuiDialogActions);

class CropperDialog extends Component {

    cropImage = e => {
        e.preventDefault();
        if (typeof this.cropper.getCroppedCanvas() === "undefined") {
            this.props.cropImage(false);
        } else {
            this.props.cropImage(true, this.cropper);
        }
    };

    render() {
        return (<Dialog
            className={"width " + styles.popup_dialogbox}
            aria-labelledby="customized-add-tasks"
            open={this.props.cropperDialog}
            onClose={this.props.handleCloseCropDialog}
            maxWidth={"lg"}
        >
            <DialogTitle
                id="customized-dialog-title"
                onClose={this.props.handleCloseCropDialog}
            >
                <div className={styles.crop_image_title}>Crop Image</div>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={this.cropImage}>
                    <div className={styles.cropper}>
                        <Cropper
                            style={{ height: 390 }}
                            aspectRatio={this.props.ratio ? 1 : null}
                            background={false}
                            guides={false}
                            src={this.props.src}
                            ref={cropper => {
                                this.cropper = cropper;
                            }}
                            viewMode={1}
                            autoCropArea={1}
                            dragMode="move"
                        />
                    </div>
                    <DialogActions>
                        <Button
                            type="submit"
                            color="primary"
                            className={styles.cropButton}
                        >
                            Crop & upload
                              </Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>)
    }
}

export default CropperDialog;

CropperDialog.defaultProps = {
    cropperDialog: false,
    handleCloseCropDialog: () => console.log("cropper dialog closed"),
    cropImage: () => console.log("cropped image"),
    src: "",
    ratio: true
};