import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./IntegrationsComponent.module.scss";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import salesForce from "../../../../assets/images/content-images/salesforce_crm.png";
import dynamicsCRM from "../../../../assets/images/content-images/dynamics_crm.png";
import sugarCRM from "../../../../assets/images/content-images/sugar_crm.png";
import SuperOfficeCRM from "../../../../assets/images/content-images/super_office.png";
import zohoCRM from "../../../../assets/images/content-images/zoho_crm.jpg";
import newIntegration  from '../../../../../src/assets/images/content-images/new_star.png';
import brregCRM from "../../../../assets/images/content-images/brreg_crm.png";
import { Redirect } from 'react-router';
import axios from "../../../common/api-configCRM";
import { openSnackbar } from "../../../common/Snackbar/snackbar";
import SmallLoader from "../../../common/Loaders/CircularLoader/circular-loader";
import OopsError from "../../../components/ErrorMessages/OopsError/OopsError";
import HubspotCRM from "../../../../assets/images/content-images/hubspot_crm.png";

const stylesMaterialUI = theme => ({
    button: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
        marginLeft: 0,
        marginRight: 0
    }
});

class IntegrationsComponent extends Component {

    state = {
        /* CRMClicked: false,
        whichCRM: "", */
        logolist: [{ img: salesForce },
        { img: brregCRM },
        { img: SuperOfficeCRM },
        { img:  HubspotCRM},
        { img: dynamicsCRM }
        ],
        crm_array: [],
        loadingCRMs: true,
        errorLoadingCRMs: false
        /* idcrm_master: "",
        isActive: 0,
        configured: 0,
        crm_name: "" */
        /*  crm_array: [{
             idcrm_master: "1",
             crm_name: "Sales Force",
             configured: true,
             supported: true,
             logo: salesForce
         },
         {
             idcrm_master: "2",
             crm_name: "MS Dynamics",
             configured: false,
             supported: false,
             logo: dynamicsCRM
         },
         {
             idcrm_master: "3",
             crm_name: "SugarCRM",
             configured: false,
             supported: false,
             logo: sugarCRM
         },
         {
             idcrm_master: "4",
             crm_name: "Zoho CRM",
             configured: false,
             supported: false,
             logo: zohoCRM
         }] */
    };

    constructor(props) {
        super(props);
        // console.log("props", props);
        this.handleStartConfigurationClicked = this.handleStartConfigurationClicked.bind(this);
    }

    importAll = (r) => {
        let images = {};
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    }

    componentDidMount() {
        const images = this.importAll(require.context('../../../../assets/images/content-images/', false, /\.(png|jpe?g|svg)$/));
        // console.log("IIIRefsdf", images);
        this.setState({ images: images });
        // console.log("Inside int did mount");

        //this.props.getCRMDetails();
        //getCMR configured and integrated data from db
        //update idcrm_master in state
        axios.get("/get", {headers: {
                //"Access-Control-Allow-Origin": "*",
                //"Content-Type": "application/x-www-form-urlencoded"
            }
        }).then(response => {
            // console.log("response", response);
            // console.log("status", response.data.status);
            if (response.data !== undefined && response.data.status !== undefined && response.data.status === 0) {
                // console.log("in");
                if (response.data.data[0] !== undefined) {
                    //console.log(response.data);
                    // console.log("ttttt", response.data.data[0].isActive);
                    this.setState({
                        crm_array: response.data.data,
                        loadingCRMs: false,
                        errorLoadingCRMs: false
                        /* idcrm_master: response.data.data[0].idcrm_master,
                        crm_name: response.data.data[0].crm_name */
                    });/*, () => {
                    this.setState({logolist: [{img: salesForce}, 
                        {img: dynamicsCRM},
                        {img: sugarCRM},
                        {img: zohoCRM}]})
                    }); */
                    /* this.props.setIdcrm_master(response.data.data[0].idcrm_master);
                    this.props.setCRM_name(response.data.data[0].crm_name);
                    this.props.setIsActive(response.data.data[0].isActive);
                    this.props.setConfigured(response.data.data[0].configured);
                    console.log("updated propsYYYYY", this.props);
                    console.log("crm_array", this.state.crm_array);
                    console.log("crm_array1", this.state.logolist); */
                } else {
                    openSnackbar('Configuration Data Not Found', 'snack-error');
                    this.setState({
                        loadingCRMs: false,
                        errorLoadingCRMs: true
                    });
                }
            } else {
                openSnackbar('Unable to Get Configuration Information', 'snack-error');
                this.setState({
                    loadingCRMs: false,
                    errorLoadingCRMs: true
                });
            }
        }).catch((error) => {
            console.log(error);
            openSnackbar('Exception Getting Configuration Information', 'snack-error');
            this.setState({
                loadingCRMs: false,
                errorLoadingCRMs: true
            });
        });
    }

    handleStartConfigurationClicked = (e) => {
        console.log("configuration for CRM ", e);

      
        const account_id = '1';
        const crm_org_id = '1';
        const crm_id = e.idcrm_master;

       // if(e.idcrm_master == 5) // HubSpot
       // {
            // axios.get(`/check_hs_integration_exists/${account_id}/${e.idcrm_master}/${crm_org_id}`)
            //         .then(response => {
            //             console.log("response in check", response);
            //             if (response !== undefined &&
            //                 response.data.status !== undefined &&
            //                 response.data.status === 0) {
                            
            //                     this.setState({
            //                         idcrm_master: e.idcrm_master,
            //                         crm_name: e.crm_name,
            //                         isActive: e.isActive,
            //                         configured: e.configured
            //                     }, () => {
            //                         this.props.setIdcrm_master(e.idcrm_master);
            //                         this.props.setCRM_name(e.crm_name);
            //                         this.props.setIsActive(e.isActive);
            //                         this.props.setConfigured(e.configured);
                                    
            //                         if (this.state.idcrm_master == "5") { //Hubspot
            //                             this.props.setIdCRM_Integration(e.idcrm_integration);
            //                             this.props.history.push({
            //                                 pathname: '/integrations/configureCRM',
            //                             });
            //                         }
            //                     });    


            //             }
            //         })
            //         .catch((error) => {
            //             // console.log(error);
            //             openSnackbar("Exception updating status", "snack-error");
            //         });
       // }
        //update idcrm_master and crm_name here
        //else {

            this.setState({
                idcrm_master: e.idcrm_master,
                crm_name: e.crm_name,
                isActive: e.isActive,
                configured: e.configured
            }, () => {
                this.props.setIdcrm_master(e.idcrm_master);
                this.props.setCRM_name(e.crm_name);
                this.props.setIsActive(e.isActive);
                this.props.setConfigured(e.configured);
                // console.log("updated propsYYYYY", this.props);
                // console.log("crm_array", this.state.crm_array);
                // console.log("crm_array1", this.state.logolist);
                //Original Push Page *****
                if (this.state.idcrm_master == "1") { //sales force
                    this.props.history.push({
                        pathname: '/integrations/configureCRM',
                        /* state: {
                            idcrm_master: this.state.idcrm_master,
                            crm_name: this.state.crm_name,
                            isActive: this.state.isActive,
                            configured: this.state.configured
                        } */
                    });
                    //Original Push Page *****
                } else if (this.state.idcrm_master == "2") { //brreg
                    this.props.setIdCRM_Integration(e.idcrm_integration);
                    // console.log("updated propsYYYYY", this.props);
                    this.props.history.push({
                        /* pathname: '/integrations/configuredataobjsbrreg', */
                        pathname: '/integrations/configureBrreg',
                        /* state: {
                            idcrm_master: this.state.idcrm_master,
                            crm_name: this.state.crm_name,
                            isActive: this.state.isActive,
                            configured: this.state.configured
                        } */
                    });
                }
                else if (this.state.idcrm_master == "3") { //superoffice
                    this.props.setIdCRM_Integration(e.idcrm_integration);
                    // console.log("updated propsYYYYY", this.props);
                    this.props.history.push({
                        pathname: '/integrations/configureCRM',
                        /* state: {
                            idcrm_master: this.state.idcrm_master,
                            crm_name: this.state.crm_name,
                            isActive: this.state.isActive,
                            configured: this.state.configured
                        } */
                    });
                }
                else if (this.state.idcrm_master == "5") { //Hubspot
                    this.props.setIdCRM_Integration(e.idcrm_integration);
                    // console.log("updated propsYYYYY", this.props);
                    this.props.history.push({
                        pathname: '/integrations/configureCRM' 
                    });
                }
            });

        //}
    }

    componentDidUpdate() {
        // console.log("in componentdidupdate", this.state);
    }


    showDetailsDialog = () => {
        this.setState({
          showNewsDialog: true,
          open: false,
        });
        var headerElement = document.getElementsByClassName(styles.appBar);
        headerElement[0].classList.add(styles.modal_zindex);
    
        var leftNavElement = document.getElementsByClassName(styles.brand);
        leftNavElement[0].parentNode.classList.add(styles.modal_zindex);
      };

      handleImage = (logoPath) => {
        // console.log(logoPath);
        return require(logoPath);
    }

    handleActivateDeActivateClicked = (crm) => {
        // console.log("activateDeativate for crm", crm);
        if (crm !== undefined &&
            crm.isActive !== undefined) {
            const params = {
                idcrm_master: crm.idcrm_master,
                isActive: !crm.isActive
            };
            //console.log("params", JSON.stringify(params));
            axios.put('/integration/changestatus', { data: JSON.stringify(params) })
                .then(response => {
                    //console.log("response in savenproceed", response);
                    if (response !== undefined &&
                        response.data.status !== undefined &&
                        response.data.status === 0) {
                        let crm_array_temp = [...this.state.crm_array];
                        const index = crm_array_temp.findIndex((item) => item.idcrm_master === crm.idcrm_master);
                        if (index > -1) {
                            crm_array_temp[index].isActive = !crm.isActive;
                            // console.log("temp_array", crm_array_temp);
                            this.setState({ crm_array: crm_array_temp }, () => {
                                // console.log("changed crm_array", this.state.crm_array);
                            });
                            this.props.setIsActive(!crm.isActive);
                        } else {
                            let error_text = "Unable to Update Status";
                            if (response.data.status_message !== undefined &&
                                response.data.status_message !== null) {
                                error_text = error_text + " - " + response.data.status_message;
                            }
                            openSnackbar(error_text, "snack-error");
                        }
                    } else {
                        let error_text = "Unable to Update Status";
                        if (response.data.status_message !== undefined &&
                            response.data.status_message !== null) {
                            error_text = error_text + " - " + response.data.status_message;
                        }
                        openSnackbar(error_text, "snack-error");
                    }
                })
                .catch((error) => {
                    // console.log(error);
                    openSnackbar("Exception updating status", "snack-error");
                })
        }
    }

    render() {
        const btn_secondary = {
            "color": "#fff",
            "backgroundColor": "#6c757d",
            "borderColor": "#6c757d"
        }

        return (
            <React.Fragment >
                <div className={styles.main_wrapper} >
                    <div className="row" >
                        <div className="col-12" >

                            <div className={"breadcrumbs row " + styles.centerAlign} >
                                <div className="col-md-6 col-12" >
                                    <h2 className="heading" > Integrations </h2>
                                    <div className="breadcrumbs_container" >
                                        <Breadcrumbs separator="›"
                                            arial-label="Breadcrumb" >
                                            <Link to="/sales-news" > Home </Link>
                                            <span> Integrations </span>
                                        </Breadcrumbs>
                                    </div>
                                </div>
                            </div>

                            <div className="row container-body" >
                                <div className={`col-xs-12 col-md-12 col-lg-12 col-12`} >
                                    { <div >
                                            <b>CRM</b>
                                        </div > }
                                    {this.state.loadingCRMs ? (<SmallLoader />) :
                                        this.state.errorLoadingCRMs ? (<OopsError />) :
                                            <div className={styles.integrations_container_footer} >
                                                <div className="card-body" >
                                                    <div className="row">
                                                        {this.state.crm_array.map((crm, index) =>
                                                            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div className="card shadow mb-4 bg-white card-rounded profile_input_style" >
                                                                      
                                                                    {crm.configured ?
                                                                        <span style={{
                                                                            alignSelf: 'flex-end',
                                                                            margin: '5px'
                                                                        }} className={styles.checkmark}>
                                                                            <div className={styles.checkmark_circle}></div>
                                                                            <div className={styles.checkmark_stem}></div>
                                                                            <div className={styles.checkmark_kick}></div>
                                                                        </span>
                                                                        :
                                                                        <span style={{
                                                                            alignSelf: 'flex-end',
                                                                            margin: '5px'
                                                                        }} className={styles.checkmark}>
                                                                            <div className={styles.checkmark_circle_grey}></div>
                                                                            <div className={styles.checkmark_stem}></div>
                                                                            <div className={styles.checkmark_kick}></div>
                                                                        </span>
                                                                    }
                                                                    {
                                                                        crm.supported ? 
                                                                                    <span className="font-italic" style={{
                                                                                            alignSelf: 'flex-start',
                                                                                            marginLeft: '-3px',
                                                                                            top: '10px',
                                                                                            position: 'absolute',
                                                                                            boxShadow: '0px 4px 10px -6px #3a4860',
                                                                                            background: crm.is_new ? '#EB793C no-repeat' : '#BDEAC2 no-repeat',
                                                                                            padding: '2px 10px 2px 10px',
                                                                                            borderRadius:'0px 8px 8px 0px',
                                                                                            opacity: '1',
                                                                                            color: crm.is_new ? '#FFFFFF' :'#3A4860'}}>
                                                                                            {
                                                                                                crm.is_new ? <img 
                                                                                                                    borderColor='transperant' 
                                                                                                                    src={newIntegration} 
                                                                                                                    className={styles.new_integration}
                                                                                                                    title ='New Integration'>
                                                                                                            </img>
                                                                                                           : null
                                                                                            }    
                                                                                            {crm.subscription_type == 'FREE' ? 'Available for free' :
                                                                                            crm.subscription_type == 'ONETIME' ? crm.configured ? 'Subscribed' : 'One Time subscription' :
                                                                                            crm.subscription_type == 'SUBSCRIBE' ? crm.configured ? 'Subscribed' : 'Subscription available' :
                                                                                            ''} 
                                                                                            {console.log(this.state.logolist[index].img )}
                                                                                        </span>
                                                                                       :
                                                                                       <span className="font-italic" style={{
                                                                                        alignSelf: 'flex-start',
                                                                                        margin: '0px',
                                                                                        marginLeft: '-3px',
                                                                                        top: '10px',
                                                                                        position: 'absolute',
                                                                                        boxShadow: '0px 4px 10px -6px #3a4860',
                                                                                        background: '#FBCA65 0% 0% no-repeat',
                                                                                        padding: '2px 10px 2px 10px',
                                                                                        borderRadius:'0px 8px 8px 0px',
                                                                                        opacity: '1',
                                                                                        color: '#3A4860'}}>
                                                                                        {'Coming Soon'} 
                                                                                    </span> 
                                                                    }
                                                                    
                                                                    <div className={styles.profileContainer_user_avatar_section__2pI8B} >
                                                                        <img 
                                                                            className={ !crm.supported ? styles.grey_image : null} src={this.state.logolist[index].img} alt={crm.crm_name} 
                                                                            style={{ width: '100%' }}
                                                                         />
                                                                    </div>
                                                                    <div className="font-italic" style={{minWidth:40, fontSize:'10px', color:'#3A4860', alignSelf:'center', padding:'0px 10px 0px 10px'}}> 
                                                                        <center>{crm.description}</center>    
                                                                    </div>
                                                                    <div style={{
                                                                                    padding: '10px',
                                                                                    opacity: '1',
                                                                                    color: '#3A4860'}} > 
                                                                        <center><a 
                                                                            style={{
                                                                                    padding: '10px',
                                                                                    opacity: '1',
                                                                                    color: '#3A4860!important'}} 
                                                                                    onClick={() => this.showDetailsDialog()}>View details</a></center> 
                                                                    </div>
                                                                    <hr className="jss201" />
                                                                    <center >
                                                                        <button type="button"
                                                                            style={!crm.supported ? btn_secondary : null}
                                                                            className={[styles.mt - 3, styles.mr - 3, styles.mb - 3, styles.ml - 3, styles.profileContainer_update_btn__MXNDH].join(' ')}
                                                                            onClick={
                                                                                () => this.handleStartConfigurationClicked(crm)
                                                                            } disabled={crm.supported ? false : true} > {crm.supported ? crm.configured ? "Manage" : "Configure Now" : "Coming Soon"} </button>
                                                                        {crm.configured ?
                                                                            (<>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                        <button type="button"
                                                                                    style={!crm.supported ? btn_secondary : null}
                                                                                    className={crm.isActive ? [styles.mt - 3, styles.mr - 3, styles.mb - 3, styles.ml - 3, styles.profileContainer_deactivate].join(' ')
                                                                                        : [styles.mt - 3, styles.mr - 3, styles.mb - 3, styles.ml - 3, styles.profileContainer_update_btn__MXNDH].join(' ')
                                                                                    }
                                                                                    onClick={
                                                                                        () => this.handleActivateDeActivateClicked(crm)
                                                                                    }> {crm.isActive ? "De-Activate" : "Activate"} </button></>)
                                                                            : <></>}
                                                                    </center>
                                                                </div >
                                                            </div>
                                                        )}
                                                    </div>
                                                </div >
                                            </div>
                                    }
                                </div>
                            </div >
                        </div>
                    </div >
                </div>
            </React.Fragment >
        );
    }
}

export default withStyles(stylesMaterialUI)(IntegrationsComponent);