import axios from "../../common/api-config";

export default {
  async getSalesNewsOrganizations(sales_news_id) {
    const url = '/salesNews/getSalesAccount';

    const response = await axios.post(url, { sales_news_id });
    return response.data.data;
  },
  async getSalesNewsLicenseTypes(sales_news_id) {
    const url = '/salesNews/getSalesNewsLicenseTypes';

    const response = await axios.post(url, { sales_news_id });
    return response.data.data;
  },
};
