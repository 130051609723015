import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./ConfigureCRMComponent.module.scss";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import axios from "../../../common/api-configCRM";
import { openSnackbar } from "../../../common/Snackbar/snackbar";
import SmallLoader from "../../../common/Loaders/CircularLoader/circular-loader";
import WizardError from "../../../components/ErrorMessages/WizardError/WizardError";
import NoRecordsFound from "../../../components/ErrorMessages/NoRecordsFound/NoRecordsFound";
import { withRouter } from "react-router-dom";
import { emailPattern } from '../../../common/common-types';
import { Dialog, DialogActions, DialogTitle, Button } from "@material-ui/core";
import ReactDOM from 'react-dom';
import { UserManager, UserManagerSettings, User, WebStorageStateStore } from 'oidc-client';
import { METADATA_OIDC, SETTING_OIDC, METADATA_OIDC_SOD, SETTING_OIDC_SOD, SETTING_OIDC_STAGE, METADATA_OIDC_STAGE } from "../../../common/super-office-config";
 
import { setStorageItem, getStorageItem } from "../../../common/common-functions";

const stylesMaterialUI = theme => ({
  button: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    marginLeft: 0,
    marginRight: 0
  }
});

var myCrypto = require('crypto');
//var UserManager;

const onCode = (code, params) => {
  console.log("wooooo a code", code);
  console.log("alright! the URLSearchParams interface from the popup url", params);
  alert('oncode');
  this.handleSOconnectionClicked();
}
const onClose = () => console.log("closed!");

class ConfigureCRMComponent extends Component {
  state = {
    CRMClicked: false,
    whichCRM: "",
    isCancelled: false,
    crmOrgDetails : null,
    accounts : null,
    /* idcrm_master: 1,
    crm_name: "", */
    CRMVersions: [],
    errorInWizard: false,
    integrationsValidity: {
      user_id: {
        name: "user_id",
        valid: false,
        touched: false,
        error_message: ""
      },
      password: {
        name: "password",
        valid: false,
        touched: false,
        error_message: ""
      },
      client_id: {
        name: "client_id",
        valid: false,
        touched: false,
        error_message: ""
      },
      secret_key: {
        name: "secret_key",
        valid: false,
        touched: false,
        error_message: ""
      },
      crm_url: {
        name: "crm_url",
        valid: false,
        touched: false,
        error_message: ""
      },
      system_user_token: {
        name: "system_user_token",
        valid: false,
        touched: false,
        error_message: ""
      },
      api_key: {
        name: "api_key",
        valid: false,
        touched: false,
        error_message: ""
      },
      crm_org_name: {
        name: "crm_org_name",
        valid: false,
        touched: false,
        error_message: ""
      },
      crm_org_id: {
        name: "crm_org_id",
        valid: false,
        touched: false,
        error_message: ""
      },
      account_id: {
        name: "account_id",
        valid: false,
        touched: false,
        error_message: ""
      }
    },
   
    integrationsDetails: {
      "idcrm_integration": "",
      "fk_crm_id": "",
      "fk_crm_version_id": "1",
      "crm_url": "",
      "auth_type": "USER",
      "user_id": "",
      "password": "",
      "client_id": "",
      "secret_key": "",
      "active_YN": "",
      "fk_schdule_id": "",
      "scheduled_time": "",
      "leadx_to_crm_lastsyncdate": "",
      "crm_to_leadx_lastsyncdate": "",
      "system_user_token":"",
      "crm_org_id": "",
      "crm_org_name":"",
      "account_id":""
    },
    connectionSuccessfull: null,
    loadingIntegrationDetails: true,
    errorLoadingIntegrationDetails: false,
    leadMappingChanged: false,
    crmSettingsChangedDialog: false,
    crmSettingsChanged: false,
  };

  handleSOCRMAuthentication()
  {
    const tokenData  = JSON.parse(atob(window.location.href.replace(/.*#/,"").split('&').map(s=>s.replace(/.*=/,''))[4].split('.').map(d=>d.replace(/.*:/,''))[1])); 
    const [state,access_token,token_type,expires_in, id_token] = window.location.href.replace(/.*#/,"").split('&').map(s=>s.replace(/.*=/,''));
   
    const [kid,sytem_user_token,data1] = id_token.split('.').map(d=>d.replace(/.*:/,''));
    let params = {};
    // this.setState({
    //   errorInWizard: false
    // });
    
    let savedState = JSON.parse(getStorageItem("configureCRMState"));
    // e.preventDefault();
    
    this.state.CRMClicked = true;
    this.state.CRMVersions = savedState.CRMVersions;
    this.state.connectionSuccessfull = true;
    this.state.errorInWizard = false;
    this.state.integrationsDetails = savedState.integrationsDetails;
    this.state.integrationsValidity = savedState.integrationsValidity;
    this.state.isCancelled = false;
    this.state.leadMappingChanged = false;
    this.state.loadingIntegrationDetails = savedState.loadingIntegrationDetails;
    this.state.integrationsDetails.system_user_token =  JSON.parse(atob(sytem_user_token))["http://schemes.superoffice.net/identity/ctx"] + ":" + JSON.parse(atob(sytem_user_token))["http://schemes.superoffice.net/identity/system_token"];
    this.state.crm_org_name = savedState.crm_org_name;
    this.state.crm_org_id = savedState.crm_org_id;
    this.state.account_id = savedState.account_id;
    this.props.setIntegrationDetails(savedState.integrationsDetails);  
    this.props.setIdCRM_Integration(savedState.integrationsDetails.idcrm_integration);
    this.props.setIDCRM_Master(3); // SuperOffice; 
    this.props.setCRM_Name("SuperOffice");
    this.state.integrationsValidity.system_user_token.valid = true;
    console.log('Integration Details props : ', this.props.integrationDetails); 

    if (this.state.integrationsDetails !== undefined) {
      
      this.setState({ connectionSuccessfull: null });
      
      if (this.checkValidity() === false) {
        openSnackbar("Please enter valid values", "snack-error");
      } 
      else {
        this.setState({errorInWizard:false}); 
        this.setState({ crmSettingsChangedDialog: false });

        if (this.state.integrationsDetails.auth_type === "OAUTH") {
          openSnackbar('Connection established with SuperOffice', 'snack-success');
          params = {
            fk_crm_version_id: this.state.integrationsDetails.fk_crm_version_id,
            user_id: this.encrypt(this.state.integrationsDetails.user_id),
            password: this.encrypt(this.state.integrationsDetails.password),
            client_id: this.encrypt(this.state.integrationsDetails.client_id),
            secret_key: this.encrypt(this.state.integrationsDetails.secret_key),
            crm_url: this.encrypt(this.state.integrationsDetails.crm_url),
            auth_type: this.state.integrationsDetails.auth_type,
            system_user_token: this.encrypt(this.state.integrationsDetails.system_user_token)
          }
        }
         
        this.setState({ connectionSuccessfull: true });
      }
    }
  }

  componentDidMount() {
    this.props.setIntegrationDetails(this.state.integrationsDetails);
    //getCMR configured and integrated data from db
    
    const [sostate,access_token,token_type,expires_in, id_token] = window.location.href.replace(/.*#/,"").split('&').map(s=>s.replace(/.*=/,''));
    if(id_token)
    {
      this.handleSOCRMAuthentication();
    }
    else if (this.props.idcrm_master !== undefined && this.props.idcrm_master !== null && this.props.idcrm_master !== "") {
      //axios.get(`integration/get/${this.state.idcrm_master}`).then(response => {
      axios.get(`integration/get/${this.props.idcrm_master}`).then(response => {
        console.log("Integration Resp", response);
        if (response !== undefined &&  response.data.status !== undefined &&  response.data.status === 0) {
          if (response.data.data !== undefined) {
            ////CAUTION: Currently integration details have multiple records
            // it should have only 1
            // TODO Rekha remove [0] once only one record is received
            if (response.data.data.length > 0) {
              this.props.setIdCRM_Integration(response.data.data[0].idcrm_integration);
              //console.log("crm response", response.data.data[0]);
              const decryptedIntegrationDetails = this.decryptCredentials(response.data.data[0]);
              //console.log("decryptedID", decryptedIntegrationDetails);
              this.props.setIntegrationDetails(decryptedIntegrationDetails);
              /*  this.setState({
                 integrationsDetails: response.data.data[0]
               }); */
              this.setState({
                integrationsDetails: decryptedIntegrationDetails
              }, () => {
                //console.log("integrationsDetails ", this.state.integrationsDetails);
              });
              //update integrationsValidity
              this.validateAllIntegrationDetails();
            }
            this.setState({
              errorLoadingIntegrationDetails: false,
              loadingIntegrationDetails: false
            });

            //console.log("integrationsDetails ", this.state.loadingIntegrationDetails);
          } else {
            openSnackbar("Data Not Found.", "snack-error");
            this.setState({
              loadingIntegrationDetails: false,
              errorLoadingIntegrationDetails: true,
            });
          }
        } else {
          openSnackbar("Unable to Get Integration Information", "snack-error");
          this.setState({
            loadingIntegrationDetails: false,
            errorLoadingIntegrationDetails: true,
          });
        }
      }).catch((error) => {
        openSnackbar("Exception Getting Integration Information", "snack-error");
        this.setState({
          loadingIntegrationDetails: false,
          errorLoadingIntegrationDetails: true,
        });
        console.log(error);
      });
      //update idcrm_master in state
      axios.get(`version/get/${this.props.idcrm_master}`, {
        params: {
          //id: this.state.idcrm_master
        }
      }).then(response => {
        //console.log("response", response);
        if (response !== undefined && response.data.status !== undefined && response.data.status === 0) {
          if (response.data.data !== undefined) {
            //get the version data
            //console.log("version data in configure", response.data.data);
            this.setState({ CRMVersions: response.data.data });
            //console.log("updated state credentials", this.state.CRMVersions);
          } else {
            openSnackbar("Data Not Found.", "snack-error");
          }
        } else {
          openSnackbar("Unable to Get CRM Version Information", "snack-error");
        }
      }).catch((error) => {
        openSnackbar("Exception Getting CRM Version Information", "snack-error");
        console.log(error);
      });
    } 
    else {
      this.setState({
        errorInWizard: true
        //errorInWizard:false
      });
    }
  }

  encrypt = (text) => {
    if(text!==null || text!== undefined || text!== ''){
      if (process.env.REACT_APP_CRYPTO_ENCRYPTION_KEY !== undefined &&
        process.env.REACT_APP_CRYPTO_IV_LENGTH !== undefined) {
        //let iv = myCrypto.randomBytes(16);
        let iv = myCrypto.randomBytes(parseInt(process.env.REACT_APP_CRYPTO_IV_LENGTH));
        let cipher = myCrypto.createCipheriv('aes-256-cbc', Buffer.from(process.env.REACT_APP_CRYPTO_ENCRYPTION_KEY), iv);
        let encrypted = cipher.update(text);
        // let encrypted = cipher.update(text, 'utf8', 'binary');

        encrypted = Buffer.concat([encrypted, cipher.final()]);
        //console.log("encrypted userid", iv.toString('hex') + ':' + encrypted.toString('hex'));
        return iv.toString('hex') + ':' + encrypted.toString('hex');
      } else {
        openSnackbar("Technical Error Encrypting Data", "snack-error");
        return null;
      }
    }
  }

  decrypt = (text) => {
    if (text !== undefined && text !== null &&
      text !== "") {
      let textParts = text.split(':');
      if (textParts !== undefined && textParts !== null) {
        //console.log("textParts", textParts);
        //console.log("textParts", textParts[0]);

        let iv = Buffer.from(textParts.shift(), 'hex');
        let encryptedText = Buffer.from(textParts.join(':'), 'hex');
        //console.log("process", process.env.REACT_APP_CRYPTO_ENCRYPTION_KEY);
        if (process.env.REACT_APP_CRYPTO_ENCRYPTION_KEY !== undefined &&
          process.env.REACT_APP_CRYPTO_ENCRYPTION_KEY !== null) {
          let decipher = myCrypto.createDecipheriv('aes-256-cbc',
            Buffer.from(process.env.REACT_APP_CRYPTO_ENCRYPTION_KEY), iv);
          let decrypted = decipher.update(encryptedText);
          // let decrypted = decipher.update(encryptedText, 'binary', 'utf8');

          decrypted = Buffer.concat([decrypted, decipher.final()]);
          return decrypted.toString();
        }
      }
    } else {
      return "";
    }
  }

  decryptCredentials = (integrationDetails) => {
    //console.log("intDetails encrypted", integrationDetails);
    //const text = "a2896f55a239dd8bfc0ee467bf0d9432:472d81a60f3baebd0ea6387618b61b3de6b5c09c7dc8d09758c587410e6a972f";
    //console.log("decrpted hdcode", this.decrypt(text));
    if (integrationDetails !== undefined &&
      integrationDetails !== null) {
      const intDetails = { ...integrationDetails };
      intDetails.crm_url = this.decrypt(intDetails.crm_url);
      intDetails.user_id = this.decrypt(intDetails.user_id);
      intDetails.password = this.decrypt(intDetails.password);
      intDetails.client_id = this.decrypt(intDetails.client_id);
      intDetails.secret_key = this.decrypt(intDetails.secret_key);
      console.log("intDetails decrypted", intDetails);
      return intDetails;
    } else {
      return null;
    }
  }

  validateAllIntegrationDetails() {
    const updateIntegrationsValidity = {
      ...this.state.integrationsValidity
    };

    if (this.state.integrationsDetails !== undefined &&
      this.state.integrationsDetails !== null) {
      if (this.state.integrationsDetails.user_id !== undefined &&
        this.state.integrationsDetails.user_id !== null &&
        this.state.integrationsDetails.user_id.trim() !== "" &&
        emailPattern.test("anita.visal@neeraytech.com") === true) {
        const updateElement = {
          ...updateIntegrationsValidity["user_id"]
        }
        updateElement.valid = true;
        updateIntegrationsValidity["user_id"] = updateElement;
      }
      if (this.state.integrationsDetails.password !== undefined &&
        this.state.integrationsDetails.password !== null &&
        this.state.integrationsDetails.password.trim() !== "") {
        const updateElement = {
          ...updateIntegrationsValidity["password"]
        }
        updateElement.valid = true;
        updateIntegrationsValidity["password"] = updateElement;
      }
      if (this.state.integrationsDetails.client_id !== undefined &&
        this.state.integrationsDetails.client_id !== null &&
        this.state.integrationsDetails.client_id.trim() !== "") {
        const updateElement = {
          ...updateIntegrationsValidity["client_id"]
        }
        updateElement.valid = true;
        updateIntegrationsValidity["client_id"] = updateElement;
      }
      if (this.state.integrationsDetails.secret_key !== undefined &&
        this.state.integrationsDetails.secret_key !== null &&
        this.state.integrationsDetails.secret_key.trim() !== "") {
        const updateElement = {
          ...updateIntegrationsValidity["secret_key"]
        }
        updateElement.valid = true;
        updateIntegrationsValidity["secret_key"] = updateElement;
      }
      if (this.state.integrationsDetails.crm_url !== undefined &&
        this.state.integrationsDetails.crm_url !== null &&
        this.state.integrationsDetails.crm_url.trim() !== "") {
        const updateElement = {
          ...updateIntegrationsValidity["crm_url"]
        }
        updateElement.valid = true;
        updateIntegrationsValidity["crm_url"] = updateElement;
      }
      if(this.state.idcrm_master == "3"){
        if (this.state.integrationsDetails.crm_url !== undefined &&
          this.state.integrationsDetails.crm_url !== null &&
          this.state.integrationsDetails.crm_url.trim() !== "") {
          const updateElement = {
            ...updateIntegrationsValidity["system_user_token"]
          }
          updateElement.valid = true;
          updateIntegrationsValidity["system_user_token"] = updateElement;
        }
      }

      if(this.state.idcrm_master == "5"){ // HubSpot
        if (this.state.integrationsDetails.crm_org_id !== undefined &&
          this.state.integrationsDetails.crm_org_id !== null &&
          this.state.integrationsDetails.crm_org_id.trim() !== "") {
          const updateElement = {
            ...updateIntegrationsValidity["crm_org_id"]
          }
          updateElement.valid = true;
          updateIntegrationsValidity["crm_org_id"] = updateElement;

        }

        if (this.state.integrationsDetails.crm_org_name !== undefined &&
          this.state.integrationsDetails.crm_org_name !== null &&
          this.state.integrationsDetails.crm_org_name.trim() !== "") {
          const updateElement = {
            ...updateIntegrationsValidity["crm_org_name"]
          }
          updateElement.valid = true;
          updateIntegrationsValidity["crm_org_name"] = updateElement;
          
        }

      }

    }
    this.setState({ integrationsValidity: updateIntegrationsValidity });
    console.log("int validity after intial validations", this.state.integrationsValidity);
  }

  checkValidity() {
    
    if (this.state.integrationsDetails.auth_type === "APIKEY") {
        console.log(this.state.integrationsValidity.crm_url.valid,
        this.state.integrationsValidity.secret_key.valid,this.props.idcrm_master);

        if(this.props.idcrm_master == '5'){ // hubspot
          return (
            this.state.integrationsValidity.secret_key.valid &&
            this.state.integrationsValidity.crm_url.valid
          );
        }
    }
    else if (this.state.integrationsDetails.auth_type === "OAUTH") {
      console.log(this.state.integrationsValidity.client_id.valid,
        this.state.integrationsValidity.secret_key.valid,
        this.state.integrationsValidity.system_user_token.valid, this.props.idcrm_master);

      if(this.props.idcrm_master == '1'){ // Salesforce
        return (
          this.state.integrationsValidity.user_id.valid && this.state.integrationsValidity.password.valid &&
          this.state.integrationsValidity.client_id.valid &&
          this.state.integrationsValidity.secret_key.valid &&
          this.state.integrationsValidity.crm_url.valid
        );
      }
      else {//if(this.props.idcrm_master == '3'){ // SuperOffice
        return (
            this.state.integrationsValidity.client_id.valid &&
            this.state.integrationsValidity.secret_key.valid &&
            this.state.integrationsValidity.system_user_token.valid
          );
      }
      // else
      //   {
      //     return (
      //       this.state.integrationsValidity.user_id.valid && this.state.integrationsValidity.password.valid &&
      //       this.state.integrationsValidity.crm_url.valid
      //     );
      // }
    } else {
      return (
        this.state.integrationsValidity.user_id.valid && this.state.integrationsValidity.password.valid &&
        this.state.integrationsValidity.crm_url.valid
      );
    }
  }

  handleSaveClicked = () => {
    if (this.state.connectionSuccessfull === true) {
      this.props.setIntegrationDetails(this.state.integrationsDetails);
      let params = null;

      console.log("integrationDetailsRRRR", this.state.integrationsDetails, this.props.idcrm_master);

      if(this.props.idcrm_master==5) // hubspot 
      {
        console.log("params update", params);

        var chkParams = {'account_id': this.state.integrationsDetails.account_id , "crm_org_id" : this.state.integrationsDetails.crm_org_id, "crm_id" : this.props.idcrm_master}
        //crm/integration/checIfExists
        console.log(chkParams);
        axios
        .post('/integration/checIfExists', { data: JSON.stringify(chkParams) })
        .then(response => {
          console.log("Exists: ", response.data);
          if (response.data.data == null || response.data.data == undefined)
          {
            params = {
              fk_crm_id: this.props.idcrm_master,
              fk_crm_version_id: "1",
         
              user_id: this.encrypt(this.state.integrationsDetails.user_id),
              password: this.encrypt(this.state.integrationsDetails.password),
              client_id: this.encrypt(this.state.integrationsDetails.client_id),
              secret_key: this.encrypt(this.state.integrationsDetails.secret_key),
              crm_url: this.encrypt(this.state.integrationsDetails.crm_url),
              additional_integration_info : this.props.idcrm_master == '3' ? this.encrypt(this.state.integrationsDetails.system_user_token) : '',
              auth_type: this.state.integrationsDetails.auth_type,
              account_id: this.props.idcrm_master == '5' ? this.state.integrationsDetails.account_id : null,
              crm_org_id: this.props.idcrm_master == '5' ? this.state.integrationsDetails.crm_org_id : null,
              crm_org_name: this.props.idcrm_master == '5' ? this.state.integrationsDetails.crm_org_name : null 
            }

             axios
              .post('/integration/add', { data: JSON.stringify(params) })
              .then(response => {
                if (response !== undefined &&  response.data !== undefined &&  response.data.status !== undefined &&
                  response.data.status === 0) {
                  this.setState(prevState => ({
                    integrationsDetails: {                    // object that we want to update
                      ...prevState.integrationsDetails,       // keep all other key-value pairs
                      idcrm_integration: response.data.crm_id // update the value of specific key
                    }
                  }));
                } else {
                  openSnackbar("Unable to Add Integration Information", "snack-error");
                }
              })
              .catch((error) => {
                console.log(error);
                openSnackbar("Exception Updating Integration Information", "snack-error");
              });

          }
          else if ( this.state.integrationsDetails !== undefined) {
            if (this.state.integrationsDetails.idcrm_integration !== "") {

              params = {
                idcrm_integration: this.state.integrationsDetails.idcrm_integration,
                fk_crm_version_id: "1",
                
                user_id: this.encrypt(this.state.integrationsDetails.user_id),
                password: this.encrypt(this.state.integrationsDetails.password),
                client_id: this.props.idcrm_master == '5' ? this.encrypt(this.state.integrationsDetails.secret_key) : this.encrypt(this.state.integrationsDetails.client_id),
                secret_key: this.props.idcrm_master == '5' ? this.encrypt(this.state.integrationsDetails.secret_key) : this.encrypt(this.state.integrationsDetails.secret_key),
                crm_url: this.encrypt(this.state.integrationsDetails.crm_url),
                auth_type: this.state.integrationsDetails.auth_type,
                additional_integration_info : this.props.idcrm_master == '3' ? this.encrypt(this.state.integrationsDetails.system_user_token) : '',
                leadMappingChanged: this.state.leadMappingChanged == true ? 1 : 0,
                crmSettingsChanged: this.state.crmSettingsChanged == true ? 1 : 0,
                account_id: this.props.idcrm_master == '5' ? this.state.integrationsDetails.account_id : null,
                crm_org_id: this.props.idcrm_master == '5' ? this.state.integrationsDetails.crm_org_id : null,
                crm_org_name: this.props.idcrm_master == '5' ? this.state.integrationsDetails.crm_org_name : null 
              }

              axios
                .put('/integration/update', { data: JSON.stringify(params) })
                .then(response => {
                  //console.log("response in savenproceed", response);
                  if (response !== undefined && response.data.status !== undefined && response.data.status === 0) {
                    openSnackbar("Saved!", "snack-success");
                  } else {
                    openSnackbar("Something Went Wrong", "snack-error");
                  }
                })
                .catch((error) => {
                  console.log(error);
                  openSnackbar("Unable to Update Integration Information", "snack-error");
                })
            } else {

              params = {
                fk_crm_id: this.props.idcrm_master,
                fk_crm_version_id: "1",
           
                user_id: this.encrypt(this.state.integrationsDetails.user_id),
                password: this.encrypt(this.state.integrationsDetails.password),
                client_id: this.encrypt(this.state.integrationsDetails.client_id),
                secret_key: this.encrypt(this.state.integrationsDetails.secret_key),
                crm_url: this.encrypt(this.state.integrationsDetails.crm_url),
                additional_integration_info : this.props.idcrm_master == '3' ? this.encrypt(this.state.integrationsDetails.system_user_token) : '',
                auth_type: this.state.integrationsDetails.auth_type,
                account_id: this.props.idcrm_master == '5' ? this.state.integrationsDetails.account_id : null,
                crm_org_id: this.props.idcrm_master == '5' ? this.state.integrationsDetails.crm_org_id : null,
                crm_org_name: this.props.idcrm_master == '5' ? this.state.integrationsDetails.crm_org_name : null 
              }

               axios
                .post('/integration/add', { data: JSON.stringify(params) })
                .then(response => {
                  if (response !== undefined &&  response.data !== undefined &&  response.data.status !== undefined &&
                    response.data.status === 0) {
                    this.setState(prevState => ({
                      integrationsDetails: {                    // object that we want to update
                        ...prevState.integrationsDetails,       // keep all other key-value pairs
                        idcrm_integration: response.data.crm_id // update the value of specific key
                      }
                    }));
                  } else {
                    openSnackbar("Unable to Add Integration Information", "snack-error");
                  }
                })
                .catch((error) => {
                  console.log(error);
                  openSnackbar("Exception Updating Integration Information", "snack-error");
                });
               
            }
          } else {
            openSnackbar("Something went wrong", "snack-error");
          }
        });
      } else {
        // For other integrations 
        
          if (this.state.integrationsDetails !== undefined) {
            if (this.state.integrationsDetails.idcrm_integration !== "") {

              params = {
                idcrm_integration: this.state.integrationsDetails.idcrm_integration,
                fk_crm_version_id: "1",
                
                user_id: this.encrypt(this.state.integrationsDetails.user_id),
                password: this.encrypt(this.state.integrationsDetails.password),
                client_id: this.props.idcrm_master == '5' ? this.encrypt(this.state.integrationsDetails.secret_key) : this.encrypt(this.state.integrationsDetails.client_id),
                secret_key: this.props.idcrm_master == '5' ? this.encrypt(this.state.integrationsDetails.secret_key) : this.encrypt(this.state.integrationsDetails.secret_key),
                crm_url: this.encrypt(this.state.integrationsDetails.crm_url),
                auth_type: this.state.integrationsDetails.auth_type,
                additional_integration_info : this.props.idcrm_master == '3' ? this.encrypt(this.state.integrationsDetails.system_user_token) : '',
                leadMappingChanged: this.state.leadMappingChanged == true ? 1 : 0,
                crmSettingsChanged: this.state.crmSettingsChanged == true ? 1 : 0,
                account_id: this.props.idcrm_master == '5' ? this.state.integrationsDetails.account_id : null,
                crm_org_id: this.props.idcrm_master == '5' ? this.state.integrationsDetails.crm_org_id : null,
                crm_org_name: this.props.idcrm_master == '5' ? this.state.integrationsDetails.crm_org_name : null 
              }

              axios
                .put('/integration/update', { data: JSON.stringify(params) })
                .then(response => {
                  //console.log("response in savenproceed", response);
                  if (response !== undefined && response.data.status !== undefined &&
                    response.data.status === 0) {
                    openSnackbar("Saved!", "snack-success");
                  } else {
                    openSnackbar("Something Went Wrong", "snack-error");
                  }
                })
                .catch((error) => {
                  console.log(error);
                  openSnackbar("Unable to Update Integration Information", "snack-error");
                })
            } else {
              params = {
                fk_crm_id: this.props.idcrm_master,
                fk_crm_version_id: "1",
           
                user_id: this.encrypt(this.state.integrationsDetails.user_id),
                password: this.encrypt(this.state.integrationsDetails.password),
                client_id: this.encrypt(this.state.integrationsDetails.client_id),
                secret_key: this.encrypt(this.state.integrationsDetails.secret_key),
                crm_url: this.encrypt(this.state.integrationsDetails.crm_url),
                additional_integration_info : this.props.idcrm_master == '3' ? this.encrypt(this.state.integrationsDetails.system_user_token) : '',
                auth_type: this.state.integrationsDetails.auth_type,
                account_id: this.props.idcrm_master == '5' ? this.state.integrationsDetails.account_id : null,
                crm_org_id: this.props.idcrm_master == '5' ? this.state.integrationsDetails.crm_org_id : null,
                crm_org_name: this.props.idcrm_master == '5' ? this.state.integrationsDetails.crm_org_name : null 
              }

              //console.log("params add", params);
              /* if (this.checkValidity === false) {
                openSnackbar("Please enter valid values", "snack-error");
              } else { */
              axios
                .post('/integration/add', { data: JSON.stringify(params) })
                .then(response => {
                  //console.log("response in savenproceed", response);
                  if (response !== undefined &&
                    response.data !== undefined &&
                    response.data.status !== undefined &&
                    response.data.status === 0) {
                    /*  if (response.data.data !== undefined) { */
                    //update inetrgation id for the new record
                    //console.log("respo data", response.data);
                    this.setState(prevState => ({
                      integrationsDetails: {                   // object that we want to update
                        ...prevState.integrationsDetails,    // keep all other key-value pairs
                        idcrm_integration: response.data.crm_id       // update the value of specific key
                      }
                    }));
                    /* let intDetails = [...this.state.integrationsDetails];
                    intDetails.idcrm_integration = response.data.crm_id;
                    console.log("new array", intDetails);
                    this.setState({ integrationsDetails: intDetails }); */
                    /* } else {
                      openSnackbar("Data Not Found Error", "snack-error");
                    } */
                  } else {
                    openSnackbar("Unable to Add Integration Information", "snack-error");
                  }
                })
                .catch((error) => {
                  console.log(error);
                  openSnackbar("Exception Updating Integration Information", "snack-error");
                });
              /* } */
            }
          } else {
            openSnackbar("Something went wrong", "snack-error");
          }
      }
    } else {
      if(this.state.idcrm_master == 1) { // Salesforce
        openSnackbar("Test the Connection To Proceed", "snack-error");
      }
      else if (this.state.idcrm_master == 3){ // SuperOffice 
        openSnackbar("Connect to SuperOffice to Proceed", "snack-error");
      }
      else{
        openSnackbar("Test the connection to proceed", "snack-error");
      }
    }
  
  }

  handleSaveNProceedClicked = (e) => {
    //console.log("inteDetRRRRRR", this.state.integrationsDetails);
    //console.log("configuration for CRM ", e);
    if (this.state.connectionSuccessfull === true) {
      this.props.setIntegrationDetails(this.state.integrationsDetails);
      console.log('in save an proceed clicked:', this.state.integrationsDetails.secret_key);
      this.setState({ CRMClicked: true, whichCRM: e });

      //save to database
      let params = null;
      
      if(this.props.idcrm_master==5) // hubspot 
      {
       
        var chkParams = {'account_id': this.state.integrationsDetails.account_id , "crm_org_id" : this.state.integrationsDetails.crm_org_id, "crm_id" : this.props.idcrm_master}
        //crm/integration/checIfExists
        console.log('Checking Params : ', chkParams);
        axios
        .post('/integration/checIfExists', { data: JSON.stringify(chkParams) })
        .then(response => {
          console.log("Exists: ", response.data);
          if (response.data.data == null || response.data.data == undefined)
          {
            params = {
              fk_crm_id: this.props.idcrm_master,
              fk_crm_version_id: "1",
         
              user_id: this.encrypt(this.state.integrationsDetails.user_id),
              password: this.encrypt(this.state.integrationsDetails.password),
              client_id: this.props.idcrm_master == '5' ? this.encrypt(this.state.integrationsDetails.secret_key) : this.encrypt(this.state.integrationsDetails.client_id),
              secret_key: this.props.idcrm_master == '5' ? this.encrypt(this.state.integrationsDetails.secret_key) : this.encrypt(this.state.integrationsDetails.secret_key),
              crm_url: this.encrypt(this.state.integrationsDetails.crm_url),
              additional_integration_info : this.props.idcrm_master == '3' ? this.encrypt(this.state.integrationsDetails.system_user_token) : '',
              auth_type: this.state.integrationsDetails.auth_type,
              account_id: this.props.idcrm_master == '5' ? this.state.integrationsDetails.account_id : null,
              crm_org_id: this.props.idcrm_master == '5' ? this.state.integrationsDetails.crm_org_id : null,
              crm_org_name: this.props.idcrm_master == '5' ? this.state.integrationsDetails.crm_org_name : null 
            }
            console.log('Add Params', params);
            axios
            .post('/integration/add', { data: JSON.stringify(params) })
            .then(response => {
             
              if (response !== undefined && response.data !== undefined && response.data.status !== undefined &&  response.data.status === 0) {
               
                this.setState(prevState => ({
                  integrationsDetails: {                   // object that we want to update
                    ...prevState.integrationsDetails,    // keep all other key-value pairs
                    idcrm_integration: response.data.crm_id       // update the value of specific key
                  }
                }), () => {
                  //console.log("state crm_id updated successfully!!!!");
                  this.props.setIdCRM_Integration(this.state.integrationsDetails.idcrm_integration);
                  this.props.setIntegrationDetails(this.state.integrationsDetails);
                  //console.log("inte detailsXXXXXX", this.state.integrationsDetails);
                  if (this.state.integrationsDetails !== undefined &&
                    this.state.integrationsDetails.idcrm_integration !== "") {
                    //console.log("id before PUSH", this.state.integrationsDetails.idcrm_integration);
                    this.props.history.push({
                      pathname: '/integrations/configuredataobjsmapCRM/',
                      state: {}
                    });
                  } else {
                    openSnackbar("Something went wrong1", "snack-error");
                  }
                });
              } else {
                openSnackbar("Unable to Add Integration Information", "snack-error");
              }
            })
            .catch((error) => {
              console.log(error);
              openSnackbar("Exception Adding Integration Information", "snack-error");
            });

          }
          else if ( this.state.integrationsDetails !== undefined) {
            if (this.state.integrationsDetails.idcrm_integration !== "") {

              params = {
                idcrm_integration: this.state.integrationsDetails.idcrm_integration,
                fk_crm_version_id: "1",
    
                user_id: this.encrypt(this.state.integrationsDetails.user_id),
                password: this.encrypt(this.state.integrationsDetails.password),
                client_id: this.props.idcrm_master == '5' ? this.encrypt(this.state.integrationsDetails.secret_key) : this.encrypt(this.state.integrationsDetails.client_id),
                secret_key: this.props.idcrm_master == '5' ? this.encrypt(this.state.integrationsDetails.secret_key) : this.encrypt(this.state.integrationsDetails.secret_key),
                crm_url: this.encrypt(this.state.integrationsDetails.crm_url),
                additional_integration_info : this.props.idcrm_master == '3' ? this.encrypt(this.state.integrationsDetails.system_user_token) : '',
                auth_type: this.state.integrationsDetails.auth_type,
                leadMappingChanged: this.state.leadMappingChanged == true ? 1 : 0,
                crmSettingsChanged: this.state.crmSettingsChanged == true ? 1 : 0,
                account_id: this.props.idcrm_master == '5' ? this.state.integrationsDetails.account_id : null,
                crm_org_id: this.props.idcrm_master == '5' ? this.state.integrationsDetails.crm_org_id : null,
                crm_org_name: this.props.idcrm_master == '5' ? this.state.integrationsDetails.crm_org_name : null
              }

            axios.put('/integration/update', { data: JSON.stringify(params) })
            .then((response) => {
              //console.log("response in savenproceed update", response);
              if (response !== undefined &&
                response.data !== undefined && response.data.status == 0) {
                //console.log("inte detailsXXXXXX", this.state.integrationsDetails);
                if (this.state.integrationsDetails !== undefined &&
                  this.state.integrationsDetails.idcrm_integration !== "") {
                  console.log("id before PUSH", this.state.integrationsDetails.idcrm_integration);
                  this.props.history.push({
                    pathname: '/integrations/configuredataobjsmapCRM/',
                    state: {}
                  });
                } else {
                  openSnackbar("Something went wrong", "snack-error");
                }
              } else {
                openSnackbar("Inetgration Details Not Updated", "snack-error");
              }
            })
            .catch((error) => {
              console.log(error);
              openSnackbar("Exception Updating Integration Information", "snack-error");
            })
            } else {

              params = {
                fk_crm_id: this.props.idcrm_master,
                fk_crm_version_id: "1",
                user_id: this.encrypt(this.state.integrationsDetails.user_id),
                password: this.encrypt(this.state.integrationsDetails.password),
                client_id: this.props.idcrm_master == '5' ? this.encrypt(this.state.integrationsDetails.secret_key) : this.encrypt(this.state.integrationsDetails.client_id),
                secret_key: this.props.idcrm_master == '5' ? this.encrypt(this.state.integrationsDetails.secret_key) : this.encrypt(this.state.integrationsDetails.secret_key),
                crm_url: this.encrypt(this.state.integrationsDetails.crm_url),
                additional_integration_info : this.props.idcrm_master == '3' ? this.encrypt(this.state.integrationsDetails.system_user_token) : '',
                auth_type: this.state.integrationsDetails.auth_type,
                account_id: this.props.idcrm_master == '5' ? this.state.integrationsDetails.account_id : null,
                crm_org_id: this.props.idcrm_master == '5' ? this.state.integrationsDetails.crm_org_id : null,
                crm_org_name: this.props.idcrm_master == '5' ? this.state.integrationsDetails.crm_org_name : null 
              }
              console.log('Add Params1', params);
              axios.post('/integration/add', { data: JSON.stringify(params) })
              .then(response => {
 
                if (response !== undefined &&
                  response.data !== undefined &&
                  response.data.status !== undefined &&
                  response.data.status === 0) {
              
                  this.setState(prevState => ({
                    integrationsDetails: {                   // object that we want to update
                      ...prevState.integrationsDetails,    // keep all other key-value pairs
                      idcrm_integration: response.data.crm_id       // update the value of specific key
                    }
                  }), () => {
                    //console.log("state crm_id updated successfully!!!!");
                    this.props.setIdCRM_Integration(this.state.integrationsDetails.idcrm_integration);
                    this.props.setIntegrationDetails(this.state.integrationsDetails);
                    //console.log("inte detailsXXXXXX", this.state.integrationsDetails);
                    if (this.state.integrationsDetails !== undefined &&
                      this.state.integrationsDetails.idcrm_integration !== "") {
                      //console.log("id before PUSH", this.state.integrationsDetails.idcrm_integration);
                      this.props.history.push({
                        pathname: '/integrations/configuredataobjsmapCRM/',
                        state: {}
                      });
                    } else {
                      openSnackbar("Something went wrong1", "snack-error");
                    }
                  });
                } else {
                  openSnackbar("Unable to Add Integration Information", "snack-error");
                }
              })
              .catch((error) => {
                console.log(error);
                openSnackbar("Exception Adding Integration Information", "snack-error");
              });
               
            }
          } else {
            openSnackbar("Something went wrong", "snack-error");
          }
        });
      } else {
      // For other Integrations
      if (this.state.integrationsDetails !== undefined) {
        if (this.state.integrationsDetails.idcrm_integration !== "") {
          params = {
            idcrm_integration: this.state.integrationsDetails.idcrm_integration,
            fk_crm_version_id: "1",

            user_id: this.encrypt(this.state.integrationsDetails.user_id),
            password: this.encrypt(this.state.integrationsDetails.password),
            client_id: this.props.idcrm_master == '5' ? this.encrypt(this.state.integrationsDetails.secret_key) : this.encrypt(this.state.integrationsDetails.client_id),
            secret_key: this.props.idcrm_master == '5' ? this.encrypt(this.state.integrationsDetails.secret_key) : this.encrypt(this.state.integrationsDetails.secret_key),
            crm_url: this.encrypt(this.state.integrationsDetails.crm_url),
            additional_integration_info : this.props.idcrm_master == '3' ? this.encrypt(this.state.integrationsDetails.system_user_token) : '',
            auth_type: this.state.integrationsDetails.auth_type,
            leadMappingChanged: this.state.leadMappingChanged == true ? 1 : 0,
            crmSettingsChanged: this.state.crmSettingsChanged == true ? 1 : 0,
            account_id: this.props.idcrm_master == '5' ? this.state.integrationsDetails.account_id : null,
            crm_org_id: this.props.idcrm_master == '5' ? this.state.integrationsDetails.crm_org_id : null,
            crm_org_name: this.props.idcrm_master == '5' ? this.state.integrationsDetails.crm_org_name : null
          }
          //console.log("params update", params);
          axios
            .put('/integration/update', { data: JSON.stringify(params) })
            .then((response) => {
              //console.log("response in savenproceed update", response);
              if (response !== undefined &&
                response.data !== undefined && response.data.status == 0) {
                //console.log("inte detailsXXXXXX", this.state.integrationsDetails);
                if (this.state.integrationsDetails !== undefined &&
                  this.state.integrationsDetails.idcrm_integration !== "") {
                  console.log("id before PUSH", this.state.integrationsDetails.idcrm_integration);
                  this.props.history.push({
                    pathname: '/integrations/configuredataobjsmapCRM/',
                    state: {
                      //  idcrm_master: this.state.idcrm_master,
                      //  crm_name: this.state.crm_name,
                      //  whichCRM: "done",
                      //  idcrm_integration: this.state.integrationsDetails.idcrm_integration,
                      //  isActive: this.state.isActive,
                      //  configured: this.state.configured
                      //  //integrationDetails: this.state.integrationsDetails
                    }
                  });
                } else {
                  openSnackbar("Something went wrong", "snack-error");
                }
              } else {
                openSnackbar("Inetgration Details Not Updated", "snack-error");
              }
            })
            .catch((error) => {
              console.log(error);
              openSnackbar("Exception Updating Integration Information", "snack-error");
            })
        } else {
          params = {
            fk_crm_id: this.props.idcrm_master,
            //fk_crm_version_id: this.state.integrationsDetails.fk_crm_version_id,
            fk_crm_version_id: "1",
            user_id: this.encrypt(this.state.integrationsDetails.user_id),
            password: this.encrypt(this.state.integrationsDetails.password),
            client_id: this.encrypt(this.state.integrationsDetails.client_id),
            secret_key: this.encrypt(this.state.integrationsDetails.secret_key),
            crm_url: this.encrypt(this.state.integrationsDetails.crm_url),
            additional_integration_info : this.props.idcrm_master == '3' ? this.encrypt(this.state.integrationsDetails.system_user_token) : '',
            auth_type: this.state.integrationsDetails.auth_type 
          }
          //console.log("params add", params);
          axios
            .post('/integration/add', { data: JSON.stringify(params) })
            .then(response => {
              //console.log("response in savenproceed add", response);
              //SHould send back the updated integration Details
              //IMPORTANT Rekha
              //update the integrationDetails with the new details
              if (response !== undefined &&
                response.data !== undefined &&
                response.data.status !== undefined &&
                response.data.status === 0) {
                /* if (response.data !== undefined) { */
                //update inetrgation id for the new record
                //console.log("respo data", response.data);
                this.setState(prevState => ({
                  integrationsDetails: {                   // object that we want to update
                    ...prevState.integrationsDetails,    // keep all other key-value pairs
                    idcrm_integration: response.data.crm_id       // update the value of specific key
                  }
                }), () => {
                  //console.log("state crm_id updated successfully!!!!");
                  this.props.setIdCRM_Integration(this.state.integrationsDetails.idcrm_integration);
                  this.props.setIntegrationDetails(this.state.integrationsDetails);
                  //console.log("inte detailsXXXXXX", this.state.integrationsDetails);
                  if (this.state.integrationsDetails !== undefined &&
                    this.state.integrationsDetails.idcrm_integration !== "") {
                    //console.log("id before PUSH", this.state.integrationsDetails.idcrm_integration);
                    this.props.history.push({
                      pathname: '/integrations/configuredataobjsmapCRM/',
                      state: {
                        //  idcrm_master: this.state.idcrm_master,
                        //  crm_name: this.state.crm_name,
                        //  whichCRM: "done",
                        //  idcrm_integration: this.state.integrationsDetails.idcrm_integration,
                        //  isActive: this.state.isActive,
                        //  configured: this.state.configured
                        //  //integrationDetails: this.state.integrationsDetails
                      }
                    });
                  } else {
                    openSnackbar("Something went wrong1", "snack-error");
                  }
                });
              } else {
                openSnackbar("Unable to Add Integration Information", "snack-error");
              }
            })
            .catch((error) => {
              console.log(error);
              openSnackbar("Exception Adding Integration Information", "snack-error");
            });
        }
      } else {
        openSnackbar("Something went wrong", "snack-error");
      }

    }

    } else {
      openSnackbar("Test The Connection To Proceed", "snack-error");
    }
    //this.props.history.push("/integrations/configuredataobjsmapCRM");
    //alert();

  }

  handleCancelClicked = () => {
    //console.log("cancel clicked");
    this.setState({ isCancelled: true });
    //this.props.history.goBack();
    this.props.history.push("/integrations");
  }

  checkIfUserId_CRMUrlChanged = (userId, CRMUrl) => {
    //console.log("userId", userId);
    //console.log("crmurl", CRMUrl);
    //console.log("props", this.props);
    //console.log("props", this.props.integrationDetails);
    if (this.props !== undefined && this.props.integrationDetails !== undefined &&
      this.props.integrationDetails !== null) {
      //console.log("original int details", this.props.integrationDetails);
      if (this.props.integrationDetails.user_id !== undefined &&
        this.props.integrationDetails.user_id !== null &&
        this.props.integrationDetails.user_id !== "" &&
        this.props.integrationDetails.crm_url !== undefined &&
        this.props.integrationDetails.crm_url !== null &&
        this.props.integrationDetails.crm_url !== "") {
        /* const originalIntegrationUserId = this.decrypt(this.props.integrationDetails.user_id);
        const originalIntegationCRMUrl = this.decrypt(this.props.integrationDetails.crm_url); */
        const originalIntegrationUserId = this.props.integrationDetails.user_id;
        const originalIntegationCRMUrl = this.props.integrationDetails.crm_url;
        //console.log("original", originalIntegrationUserId);
        //console.log("original", originalIntegationCRMUrl);

        if (userId !== originalIntegrationUserId ||
          CRMUrl !== originalIntegationCRMUrl) {
          //console.log("XXXUser credentials changed");
          this.setState({ crmSettingsChangedDialog: true });
        } else {
          this.setState({ crmSettingsChangedDialog: false });
        }
      }
    }
  }

  getCrmOrgDetails = () => {

  if (this.state.integrationsDetails.auth_type === "APIKEY") {
    console.log('API Key : ', this.state.integrationsDetails);
    var params = {
      fk_crm_version_id: "7",
      secret_key: this.encrypt(this.state.integrationsDetails.secret_key),
      crm_url: this.encrypt(this.state.integrationsDetails.crm_url),
      auth_type: this.state.integrationsDetails.auth_type
    }
    console.log("Get Pipielines APIKEY", params);

  axios.post("/get_hs_pipelines", { data: JSON.stringify(params) }).then(response => {
      if (response !== undefined && response.data !== undefined && response.data.hspipelines !== undefined ) {
        if (response.data.hspipelines !== undefined) {
          if (response.data.hspipelines.length > 0) {
            console.log("Pipeline Details", response.data.hspipelines);
            this.setState({
              crmOrgDetails: response.data.hspipelines,
              loadingLeadStatusDetails: false,
              errorLoadingLeadStatus: false,
              showLoader: false
            }, () => {
            });
          }
          console.log("Pipelines: ", this.state.crmOrgDetails);

        } else {
          openSnackbar("Data Not Found.", "snack-error");
          this.setState({
            loadingLeadStatusDetails: false,
            errorLoadingLeadStatus: true,
            showLoader: false
          });
        }
      } else {
        openSnackbar("Unable to Get CRM Pipelines", "snack-error");
        this.setState({
          loadingLeadStatusDetails: false,
          errorLoadingLeadStatus: true,
          showLoader: false
        });
      }
    }).catch((error) => {
      openSnackbar("Exception Getting CRM Pipelines", "snack-error");
      this.setState({
        loadingLeadStatusDetails: false,
        errorLoadingLeadStatus: true,
        showLoader: false
      });
      console.log(error);
    });
  }

  }


  getAccounts = () =>{


    if (this.state.integrationsDetails.auth_type === "APIKEY") {
      console.log('API Key : ', this.state.integrationsDetails);
      var params = {
        fk_crm_version_id: "7",
        secret_key: this.encrypt(this.state.integrationsDetails.secret_key),
        crm_url: this.encrypt(this.state.integrationsDetails.crm_url),
        auth_type: this.state.integrationsDetails.auth_type
      }
      console.log("Get Pipielines APIKEY", params);
  
      axios
      .get('/accounts/get')
      .then(response => {
         if (response !== undefined && response.data !== undefined &&  response.data.status !== undefined && response.data.status === 0) {
           console.log('Accounts : ', response.data);
          if (response.data.accounts !== undefined) {
            if (response.data.accounts.length > 0) {
              console.log("Accounts : ", response.data.accounts);
              this.setState({
                accounts: response.data.accounts,
                loadingLeadStatusDetails: false,
                errorLoadingLeadStatus: false,
                showLoader: false
              }, () => {
              });
            }
            console.log("Accounts in State: ", this.state.accounts);
  
          } else {
            openSnackbar("Data Not Found.", "snack-error");
            this.setState({
              loadingLeadStatusDetails: false,
              errorLoadingLeadStatus: true,
              showLoader: false
            });
          }
        } else {
          openSnackbar("Unable to Get Accounts", "snack-error");
          this.setState({
            loadingLeadStatusDetails: false,
            errorLoadingLeadStatus: true,
            showLoader: false
          });
        }
      }).catch((error) => {
        openSnackbar("Exception Getting Accounts", "snack-error");
        this.setState({
          loadingLeadStatusDetails: false,
          errorLoadingLeadStatus: true,
          showLoader: false
        });
        console.log(error);
      });
    }
  }

  getHubSpotIntegrationDetails= async () =>{
   try {
        
      console.log('getHubSpotIntegrationdetails,', this.props.integrationDetails.crm_org_id,  this.props.integrationDetails.account_id);
      var result = await axios.get(`integration/getHS/${this.props.idcrm_master}/${this.props.integrationDetails.crm_org_id}/${this.props.integrationDetails.account_id}`).then(response => {
        console.log("Integration Resp", response);
        if (response !== undefined &&
          response.data !== undefined &&
          response.data.status !== undefined &&
          response.data.status === 0) {
          if (response.data.data !== undefined) {
          
            if (response.data.data.length > 0) {
              this.props.setIdCRM_Integration(response.data.data[0].idcrm_integration);
              const decryptedIntegrationDetails = this.decryptCredentials(response.data.data[0]);
              this.props.setIntegrationDetails(decryptedIntegrationDetails);
              this.setState({
                integrationsDetails: decryptedIntegrationDetails
              }, () => {
                //console.log("integrationsDetails ", this.state.integrationsDetails);
              });
              //update integrationsValidity
              this.validateAllIntegrationDetails();
            }
            this.setState({
              errorLoadingIntegrationDetails: false,
              loadingIntegrationDetails: false
            });

            //console.log("integrationsDetails ", this.state.loadingIntegrationDetails);
          } else {
            openSnackbar("Data Not Found.", "snack-error");
            this.setState({
              loadingIntegrationDetails: false,
              errorLoadingIntegrationDetails: true,
            });
          }
        } else {
          openSnackbar("Unable to Get Integration Information", "snack-error");
          this.setState({
            loadingIntegrationDetails: false,
            errorLoadingIntegrationDetails: true,
          });
        }
      }).catch((error) => {
        openSnackbar("Exception Getting Integration Information", "snack-error");
        this.setState({
          loadingIntegrationDetails: false,
          errorLoadingIntegrationDetails: true,
        });
        console.log(error);
      });
      //update idcrm_master in state
      axios.get(`version/get/${this.props.idcrm_master}`, {
        params: {
          //id: this.state.idcrm_master
        }
      }).then(response => {
        //console.log("response", response);
        if (response !== undefined && response.data.status !== undefined && response.data.status === 0) {
          if (response.data.data !== undefined) {
            //get the version data
            //console.log("version data in configure", response.data.data);
            this.setState({ CRMVersions: response.data.data });
            //console.log("updated state credentials", this.state.CRMVersions);
          } else {
            openSnackbar("Data Not Found.", "snack-error");
          }
        } else {
          openSnackbar("Unable to Get CRM Version Information", "snack-error");
        }
      }).catch((error) => {
        openSnackbar("Exception Getting CRM Version Information", "snack-error");
        console.log(error);
      });
    }catch(err){
      console.log('etHubSpotIntegrationDetails error ', err);
    }
  }

  handleHSTestConnectionClicked = (e)=> {
    e.preventDefault();
    this.setState({ connectionSuccessfull: null });
    
      if (this.state.integrationsDetails !== undefined) {
        if (this.checkValidity() === false) {
          openSnackbar("Please enter valid values", "snack-error");
        }
        else
        {
          if (this.state.integrationsDetails.auth_type === "APIKEY") {
            console.log('API Key : ', this.state.integrationsDetails);
            var params = {
              fk_crm_version_id: "7",
              secret_key: this.encrypt(this.state.integrationsDetails.secret_key),
              crm_url: this.encrypt(this.state.integrationsDetails.crm_url),
              auth_type: this.state.integrationsDetails.auth_type
            }
            console.log("APIKEY", params);

            axios.post("/testHSconnection", { data: JSON.stringify(params) }).then(response => {
                  console.log("HS Connection Response --- ", response);
                  if (response !== undefined && response.data !== undefined && response.status !== undefined) {
                    if (response.data.status === 200) {
                      console.log("HS Connection Status---", response.data.status);
                      this.getCrmOrgDetails();
                      this.getAccounts();
                      this.setState({ connectionSuccessfull: true });
                    } else {
                      this.setState({ connectionSuccessfull: false });
                    }
                  } else {
                    this.setState({ connectionSuccessfull: false });
                  }
                })
                .catch(error => {
                  console.log(error);
                  this.setState({ connectionSuccessfull: false });
              });
          }
        }          
      } 
  }

  handleTestConnectionClicked = (e) => {
    e.preventDefault();
    this.setState({ connectionSuccessfull: null });
    if (this.state.integrationsDetails !== undefined) {
      //result from the api 
      //currently connection details hardcoded - REMOVE Rekha
      /* let params = {
        username: "anita.visal@neeraytech.com",
        password: "Test1234!4lg7yqx9lbdyYcQEB8Psxozv",
        clientId: '3MVG9n_HvETGhr3DqYwiUp1CQY5qr_bH8OU6NW8n_sL63hvu0ef8njnOXIX6yjtKyOocYEXUWQJcT8pTOqfon',
        clientSecret: '2F9C8F3915DF652F3EEAC0AF5F7193438E56D6678AE6E406A3DD01E3B099E299',
        redirectUri: 'http://localhost:8443/callback/'
      } */
      //console.log("this.checkValidity", this.checkValidity());
      //console.log("validity", this.state.integrationsValidity);
      if (this.checkValidity() === false) {
        openSnackbar("Please enter valid values", "snack-error");
      } else {
        //this.checkIfUserId_CRMUrlChanged(this.state.integrationsDetails.user_id,
        // this.state.integrationsDetails.crm_url);
        if (this.props !== undefined && this.props.integrationDetails !== undefined &&
          this.props.integrationDetails !== null) {
          //console.log("original int details", this.props.integrationDetails);
          if (this.props.integrationDetails.user_id !== undefined &&
            this.props.integrationDetails.user_id !== null &&
            this.props.integrationDetails.user_id !== "" &&
            this.props.integrationDetails.crm_url !== undefined &&
            this.props.integrationDetails.crm_url !== null &&
            this.props.integrationDetails.crm_url !== "") {
            /* const originalIntegrationUserId = this.decrypt(this.props.integrationDetails.user_id);
            const originalIntegationCRMUrl = this.decrypt(this.props.integrationDetails.crm_url); */
            const originalIntegrationUserId = this.props.integrationDetails.user_id;
            const originalIntegationCRMUrl = this.props.integrationDetails.crm_url;
            //console.log("original", originalIntegrationUserId);
            //console.log("original", originalIntegationCRMUrl);

            if (this.state.integrationsDetails.user_id !== originalIntegrationUserId ||
              this.state.integrationsDetails.crm_url !== originalIntegationCRMUrl) {
              //console.log("XXXUser credentials changed");
              this.setState({ crmSettingsChangedDialog: true });
            } else {
              this.setState({ crmSettingsChangedDialog: false });
              //test connection 
              let params = {};
              if (this.state.integrationsDetails.auth_type === "OAUTH") {
                params = {
                  //fk_crm_version_id: this.state.integrationsDetails.fk_crm_version_id,
                  fk_crm_version_id: "1",
                  /* user_id: this.state.integrationsDetails.user_id,
                  password: this.state.integrationsDetails.password,
                  client_id: this.state.integrationsDetails.client_id,
                  secret_key: this.state.integrationsDetails.secret_key,
                  crm_url: this.state.integrationsDetails.crm_url, */
                  user_id: this.encrypt(this.state.integrationsDetails.user_id),
                  password: this.encrypt(this.state.integrationsDetails.password),
                  client_id: this.encrypt(this.state.integrationsDetails.client_id),
                  secret_key: this.encrypt(this.state.integrationsDetails.secret_key),
                  crm_url: this.encrypt(this.state.integrationsDetails.crm_url),
                  auth_type: this.state.integrationsDetails.auth_type
                  //redirectUri: 'http://localhost:8443/callback/'
                }
                //console.log("OAUTH", params);
              }
              else {
                //USER
                params = {
                  /* fk_crm_version_id: this.state.integrationsDetails.fk_crm_version_id, */
                  fk_crm_version_id: "1",
                  /* user_id: this.state.integrationsDetails.user_id,
                  password: this.state.integrationsDetails.password,
                  crm_url: this.state.integrationsDetails.crm_url, */
                  user_id: this.encrypt(this.state.integrationsDetails.user_id),
                  password: this.encrypt(this.state.integrationsDetails.password),
                  crm_url: this.encrypt(this.state.integrationsDetails.crm_url),
                  auth_type: this.state.integrationsDetails.auth_type
                  //redirectUri: 'http://localhost:8443/callback/'
                }
              }

              axios
                .post("/testconnection", { data: JSON.stringify(params) })
                .then(response => {
                  //console.log("testetsthgfjhsdfgjhds---", response);
                  if (response !== undefined && response.data !== undefined && response.status !== undefined) {
                    if (response.data.status === 0) {
                      //console.log("testetsthgfjhsdfgjhds---", response.data.status);
                      this.setState({ connectionSuccessfull: true });
                    } else {
                      this.setState({ connectionSuccessfull: false });
                    }
                  } else {
                    this.setState({ connectionSuccessfull: false });
                  }
                })
                .catch(error => {
                  console.log(error);
                  this.setState({ connectionSuccessfull: false });
                });
              //test connection 
            }
          } else {
            //openSnackbar('Connection Details Not Available', 'snack-error');
            let params = {};
            if (this.state.integrationsDetails.auth_type === "OAUTH") {
              params = {
                //fk_crm_version_id: this.state.integrationsDetails.fk_crm_version_id,
                fk_crm_version_id: "1",
                /* user_id: this.state.integrationsDetails.user_id,
                password: this.state.integrationsDetails.password,
                client_id: this.state.integrationsDetails.client_id,
                secret_key: this.state.integrationsDetails.secret_key,
                crm_url: this.state.integrationsDetails.crm_url, */
                user_id: this.encrypt(this.state.integrationsDetails.user_id),
                password: this.encrypt(this.state.integrationsDetails.password),
                client_id: this.encrypt(this.state.integrationsDetails.client_id),
                secret_key: this.encrypt(this.state.integrationsDetails.secret_key),
                crm_url: this.encrypt(this.state.integrationsDetails.crm_url),
                auth_type: this.state.integrationsDetails.auth_type
                //redirectUri: 'http://localhost:8443/callback/'
              }
              //console.log("OAUTH", params);
            } else {
              //USER
              params = {
                /* fk_crm_version_id: this.state.integrationsDetails.fk_crm_version_id, */
                fk_crm_version_id: "1",
                /* user_id: this.state.integrationsDetails.user_id,
                password: this.state.integrationsDetails.password,
                crm_url: this.state.integrationsDetails.crm_url, */
                user_id: this.encrypt(this.state.integrationsDetails.user_id),
                password: this.encrypt(this.state.integrationsDetails.password),
                crm_url: this.encrypt(this.state.integrationsDetails.crm_url),
                auth_type: this.state.integrationsDetails.auth_type
                //redirectUri: 'http://localhost:8443/callback/'
              }
              //console.log("USER", params);
            }
            //console.log("connection details", params);
            //currently connection details hardcoded - REMOVE Rekha
            axios
              .post("/testconnection", { data: JSON.stringify(params) })
              .then(response => {
                //console.log("testetsthgfjhsdfgjhds---", response);
                if (response !== undefined && response.data !== undefined && response.status !== undefined) {
                  if (response.data.status === 0) {
                    //console.log("testetsthgfjhsdfgjhds---", response.data.status);
                    this.setState({ connectionSuccessfull: true });
                  } else {
                    this.setState({ connectionSuccessfull: false });
                  }
                } else {
                  this.setState({ connectionSuccessfull: false });
                }
              })
              .catch(error => {
                console.log(error);
                this.setState({ connectionSuccessfull: false });
              });
          }
        }
      }
    }
  }

  handleSOconnectionClicked = (e) => {
    //console.log('connEnv:', process.env.REACT_APP_SO_CONNECT_TO_ENV );
    if(process.env.REACT_APP_SO_CONNECT_TO_ENV == 'sod'){
    this.UserManager = new UserManager({
      ...SETTING_OIDC_SOD,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      metadata: {
          ...METADATA_OIDC_SOD
      }
    });

    localStorage.setItem("redirectUri", window.location.pathname);
    setStorageItem("configureCRMState", JSON.stringify(this.state)); 
    this.UserManager.signinRedirect().then((data) => {
    });
  }
  else if(process.env.REACT_APP_SO_CONNECT_TO_ENV == 'stage'){
    this.UserManager = new UserManager({
      ...SETTING_OIDC_STAGE,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      metadata: {
          ...METADATA_OIDC_STAGE
      }
    });

    localStorage.setItem("redirectUri", window.location.pathname);
    setStorageItem("configureCRMState", JSON.stringify(this.state)); 
    this.UserManager.signinRedirect().then((data) => {
    });
  }
  else if(process.env.REACT_APP_SO_CONNECT_TO_ENV == 'online'){
      this.UserManager = new UserManager({
        ...SETTING_OIDC,
        userStore: new WebStorageStateStore({ store: window.sessionStorage }),
        metadata: {
            ...METADATA_OIDC
        }
      });

      localStorage.setItem("redirectUri", window.location.pathname);
      setStorageItem("configureCRMState", JSON.stringify(this.state)); 
      this.UserManager.signinRedirect().then((data) => {
      });
    }
   
  }

  
  handleSOTestConnectionClicked = (e) => {
    console.log('In So Test Connection Clicked');
    e.preventDefault();
    this.setState({ connectionSuccessfull: null });
    if (this.state.integrationsDetails !== undefined) {
 
      if (this.checkValidity() === false) {
        openSnackbar("Please enter valid values", "snack-error");
      } else {
      
        if (this.props !== undefined && this.props.integrationDetails !== undefined &&
          this.props.integrationDetails !== null) {
          if (this.props.integrationDetails.user_id !== undefined && this.props.integrationDetails.user_id !== null &&
            this.props.integrationDetails.user_id !== "" && this.props.integrationDetails.crm_url !== undefined &&
            this.props.integrationDetails.crm_url !== null && this.props.integrationDetails.crm_url !== "") {
 
            const originalIntegrationUserId = this.props.integrationDetails.user_id;
            const originalIntegationCRMUrl = this.props.integrationDetails.crm_url;
         
            if (this.state.integrationsDetails.user_id !== originalIntegrationUserId ||
              this.state.integrationsDetails.crm_url !== originalIntegationCRMUrl) {
              this.setState({ crmSettingsChangedDialog: true });
            } else {
              this.setState({ crmSettingsChangedDialog: false });
              //test connection 
              let params = {};

              if (this.state.integrationsDetails.auth_type === "OAUTH") {
                params = {
                  fk_crm_version_id: this.state.integrationsDetails.fk_crm_version_id,
                  //fk_crm_version_id: "1",
                  user_id: this.encrypt(this.state.integrationsDetails.user_id),
                  password: this.encrypt(this.state.integrationsDetails.password),
                  client_id: this.encrypt(this.state.integrationsDetails.client_id),
                  secret_key: this.encrypt(this.state.integrationsDetails.secret_key),
                  crm_url: this.encrypt(this.state.integrationsDetails.crm_url),
                  auth_type: this.state.integrationsDetails.auth_type
                  
                }
                console.log("OAUTH", params);
              } 
            
              axios
                .post("/testSOConnection", { data: JSON.stringify(params) })
                .then(response => {
                  //console.log("testetsthgfjhsdfgjhds---", response);
                  if (response !== undefined && response.data !== undefined && response.status !== undefined) {
                    if (response.data.status === 0) {
                      //console.log("testetsthgfjhsdfgjhds---", response.data.status);
                      this.setState({ connectionSuccessfull: true });
                    } else {
                      this.setState({ connectionSuccessfull: false });
                    }
                  } else {
                    this.setState({ connectionSuccessfull: false });
                  }
                })
                .catch(error => {
                  console.log(error);
                  this.setState({ connectionSuccessfull: false });
                });
              //test connection 
            }
          } else {
            //openSnackbar('Connection Details Not Available', 'snack-error');
            let params = {};
            if (this.state.integrationsDetails.auth_type === "OAUTH") {
              params = {
                fk_crm_version_id: this.state.integrationsDetails.fk_crm_version_id,
                //fk_crm_version_id: "1",
                user_id: this.encrypt(this.state.integrationsDetails.user_id),
                password: this.encrypt(this.state.integrationsDetails.password),
                client_id: this.encrypt(this.state.integrationsDetails.client_id),
                secret_key: this.encrypt(this.state.integrationsDetails.secret_key),
                crm_url: this.encrypt(this.state.integrationsDetails.crm_url),
                auth_type: this.state.integrationsDetails.auth_type
              }
            } 
            
            axios
              .post("/testSOConnection", { data: JSON.stringify(params) })
              .then(response => {
                if (response !== undefined && response.data !== undefined && response.status !== undefined) {
                  if (response.data.status === 0) {
                    this.setState({ connectionSuccessfull: true });
                  } else {
                    this.setState({ connectionSuccessfull: false });
                  }
                } else {
                  this.setState({ connectionSuccessfull: false });
                }
              })
              .catch(error => {
                console.log(error);
                this.setState({ connectionSuccessfull: false });
              });
          }
        }
      }
    }
  }

  handleCRMVersionChange = (event) => {
    //console.log("event", event.target.value);
    const value = event.target.value;
    this.setState(prevState => ({
      integrationsDetails: {                   // object that we want to update
        ...prevState.integrationsDetails,    // keep all other key-value pairs
        fk_crm_version_id: value       // update the value of specific key
      }
    }));
    /* if (value !== undefined) {
      let intDetails = [...this.state.integrationsDetails];
      intDetails.fk_crm_version_id = value;
      console.log("new array", intDetails);
      this.setState({ integrationsDetails: intDetails });
    } */
  }

  handleConnectionURLChange = (e) => {
    let name = e.target.name;
    //console.log("e value", e.target.value);
    const value = e.target.value;
    const updateIntegrationsValidity = {
      ...this.state.integrationsValidity
    }
    //console.log("int validity", updateIntegrationsValidity);
    const updatedElement = {
      ...updateIntegrationsValidity[name]
    };
    //console.log("updateElement ", updatedElement);
    updatedElement.touched = true;

    if (value.trim() === "") {
      updatedElement.error_message =
        "Please enter a username/email address";
      updatedElement.valid = false;
    } else {
      updatedElement.error_message = "";
      updatedElement.valid = true;
    }
    updateIntegrationsValidity[name] = updatedElement;

    this.setState({ integrationsValidity: updateIntegrationsValidity });
    if (this.state.integrationsValidity[name].touched === true) {
      //console.log("dsfsdfsdfsdf====conn null");
      this.setState({ connectionSuccessfull: null });
    }
    this.setState(prevState => ({
      integrationsDetails: {                   // object that we want to update
        ...prevState.integrationsDetails,    // keep all other key-value pairs
        crm_url: value       // update the value of specific key
      }
    }));
  }

  handleApiKeyChange = (e) => {

    let name = e.target.name;
    console.log("e value", e.target.value, name);
    const value = e.target.value;
    const updateIntegrationsValidity = {
      ...this.state.integrationsValidity
    }
    const updatedElement = {
      ...updateIntegrationsValidity[name]
    };
    updatedElement.touched = true;
    if (value.trim() === "") {
      updatedElement.error_message =
        "Please enter a valid Api Key ";
      updatedElement.valid = false;
    }
    else {
      updatedElement.error_message = "";
      updatedElement.valid = true;
    }
    updateIntegrationsValidity[name] = updatedElement;
    console.log(updateIntegrationsValidity);
    this.setState({ integrationsValidity: updateIntegrationsValidity });
    if (this.state.integrationsValidity[name].touched === true) {
      this.setState({ connectionSuccessfull: null });
    }
    this.setState(prevState => ({
      integrationsDetails: {                   // object that we want to update
        ...prevState.integrationsDetails,    // keep all other key-value pairs
        api_key: value,
        secret_key: value,
        client_id: value       // update the value of specific key
      }
    }));


  }

  handleLoginIdChange = (e) => {
    let name = e.target.name;
    console.log("e value", e.target.value, name);
    const value = e.target.value;
    const updateIntegrationsValidity = {
      ...this.state.integrationsValidity
    }
    //console.log("int validity", updateIntegrationsValidity);
    const updatedElement = {
      ...updateIntegrationsValidity[name]
    };
    //console.log("updateElement ", updatedElement);
    updatedElement.touched = true;
    if (value.trim() === "") {
      updatedElement.error_message =
        "Please enter a username/email address";
      updatedElement.valid = false;
    } else if (!emailPattern.test(value)) {
      updatedElement.error_message =
        "Please enter a valid username/email address";
      updatedElement.valid = false;
      /*   } else if (userName.length > 50) {
          updatedElement.errorMessage = "Username length too long";
          updatedElement.valid = false; */
    } else {
      updatedElement.error_message = "";
      updatedElement.valid = true;
    }
    updateIntegrationsValidity[name] = updatedElement;

    this.setState({ integrationsValidity: updateIntegrationsValidity });
    if (this.state.integrationsValidity[name].touched === true) {
      //console.log("dsfsdfsdfsdf====conn null");
      this.setState({ connectionSuccessfull: null });
    }
    this.setState(prevState => ({
      integrationsDetails: {                   // object that we want to update
        ...prevState.integrationsDetails,    // keep all other key-value pairs
        user_id: value       // update the value of specific key
      }
    }));
  }

  handlePasswordChange = (e) => {
    let name = e.target.name;
    //console.log("e value", e.target.value);
    const value = e.target.value;
    const updateIntegrationsValidity = {
      ...this.state.integrationsValidity
    }
    //console.log("int validity", updateIntegrationsValidity);
    const updatedElement = {
      ...updateIntegrationsValidity[name]
    };
    //console.log("updateElement ", updatedElement);
    updatedElement.touched = true;
    if (value.trim() === "") {
      updatedElement.error_message =
        "Please enter a valid password";
      updatedElement.valid = false;
    } else {
      updatedElement.error_message = "";
      updatedElement.valid = true;
    }
    updateIntegrationsValidity[name] = updatedElement;

    this.setState({ integrationsValidity: updateIntegrationsValidity });
    if (this.state.integrationsValidity[name].touched === true) {
      //console.log("dsfsdfsdfsdf====conn null");
      this.setState({ connectionSuccessfull: null });
    }
    this.setState(prevState => ({
      integrationsDetails: {                   // object that we want to update
        ...prevState.integrationsDetails,    // keep all other key-value pairs
        password: value       // update the value of specific key
      }
    }));
  }

  handleClientIdChange = (e) => {
    let name = e.target.name;
    //console.log("e value", e.target.value);
    const value = e.target.value;
    const updateIntegrationsValidity = {
      ...this.state.integrationsValidity
    }
    //console.log("int validity", updateIntegrationsValidity);
    const updatedElement = {
      ...updateIntegrationsValidity[name]
    };
    //console.log("updateElement ", updatedElement);
    updatedElement.touched = true;
    if (value.trim() === "") {
      updatedElement.error_message =
        "Please enter a valid client_id";
      updatedElement.valid = false;
    } else {
      updatedElement.error_message = "";
      updatedElement.valid = true;
    }
    updateIntegrationsValidity[name] = updatedElement;

    this.setState({ integrationsValidity: updateIntegrationsValidity });
    if (this.state.integrationsValidity[name].touched === true) {
      //console.log("dsfsdfsdfsdf====conn null");
      this.setState({ connectionSuccessfull: null });
    }
    this.setState(prevState => ({
      integrationsDetails: {                   // object that we want to update
        ...prevState.integrationsDetails,    // keep all other key-value pairs
        client_id: value       // update the value of specific key
      }
    }));
  }

  handleSecretKeyChange = (e) => {
    let name = e.target.name;
    //console.log("e value", e.target.value);
    const value = e.target.value;
    const updateIntegrationsValidity = {
      ...this.state.integrationsValidity
    }
    //console.log("int validity", updateIntegrationsValidity);
    const updatedElement = {
      ...updateIntegrationsValidity[name]
    };
    //console.log("updateElement ", updatedElement);
    updatedElement.touched = true;
    if (value.trim() === "") {
      updatedElement.error_message =
        "Please enter a valid secret key";
      updatedElement.valid = false;
    } else {
      updatedElement.error_message = "";
      updatedElement.valid = true;
    }
    updateIntegrationsValidity[name] = updatedElement;

    this.setState({ integrationsValidity: updateIntegrationsValidity });
    if (this.state.integrationsValidity[name].touched === true) {
      //console.log("dsfsdfsdfsdf====conn null");
      this.setState({ connectionSuccessfull: null });
    }
    this.setState(prevState => ({
      integrationsDetails: {                   // object that we want to update
        ...prevState.integrationsDetails,    // keep all other key-value pairs
        secret_key: value       // update the value of specific key
      }
    }));
  }

  handleAuthenticationChanged = (event) => {
    //console.log("eee", event.target.value);
    this.setState({ connectionSuccessfull: null });
    const value = event.target.value;
    console.log('integration type value : ', value);
    if (value === "USER") {
      this.setState(prevState => ({
        integrationsDetails: {                   // object that we want to update
          ...prevState.integrationsDetails,    // keep all other key-value pairs
          auth_type: value,     // update the value of specific key
          client_id: "",
          secret_key: ""
        }
      }));
    }
    if (value === "OAUTH") {
      this.setState(prevState => ({
        integrationsDetails: {                   // object that we want to update
          ...prevState.integrationsDetails,    // keep all other key-value pairs
          auth_type: value       // update the value of specific key
        }
      }));
      if (this.refs['refradio_authtype_oauth'] !== undefined &&
        this.refs['refradio_authtype_oauth'] !== null) {
        if (this.state.integrationsDetails.auth_type == "OAUTH") {
          ReactDOM.findDOMNode(this.refs["refradio_authtype_oauth"]).defaultChecked = true;
          ReactDOM.findDOMNode(this.refs["refradio_authtype_oauth"]).checked = true;
          ReactDOM.findDOMNode(this.refs["refradio_authtype_oauth"]).defaultValue = "OAUTH";
          ReactDOM.findDOMNode(this.refs["refradio_authtype_oauth"]).value = "OAUTH";
        }
      }
      //console.log("refs", this.refs);
    }
    if (value === "APIKEY") {
      this.setState(prevState => ({
        integrationsDetails: {                   // object that we want to update
          ...prevState.integrationsDetails,    // keep all other key-value pairs
          auth_type: value       // update the value of specific key
        }
      }));
      if (this.refs['refradio_authtype_apikey'] !== undefined &&
        this.refs['refradio_authtype_apikey'] !== null) {
        if (this.state.integrationsDetails.auth_type == "APIKEY") {
          ReactDOM.findDOMNode(this.refs["refradio_authtype_apikey"]).defaultChecked = true;
          ReactDOM.findDOMNode(this.refs["refradio_authtype_apikey"]).checked = true;
          ReactDOM.findDOMNode(this.refs["refradio_authtype_apikey"]).defaultValue = "APIKEY";
          ReactDOM.findDOMNode(this.refs["refradio_authtype_apikey"]).value = "APIKEY";
        }
      }
      //console.log("refs", this.refs);
    }

  }

  handleCredentialsClose_No = () => {
    this.setState({ connectionSuccessfull: null });
    this.setState({ crmSettingsChangedDialog: false });
    this.setState({ crmSettingsChanged: false });
    if (this.props.integrationDetails !== undefined) {
      this.setState({ integrationsDetails: this.props.integrationDetails }, () => {
        console.log("integrationDetails original", this.state.integrationsDetails);
        //console.log("refs", this.refs);
        if (this.refs !== undefined && this.refs !== null) {
          if (this.refs["refinput_user_id"] !== undefined &&
            this.refs['refinput_user_id'] !== null) {
            ReactDOM.findDOMNode(this.refs["refinput_user_id"]).value =
              this.state.integrationsDetails.user_id;
          }
          if (this.refs['refinput_password'] !== undefined &&
            this.refs['refinput_password'] !== null) {
            ReactDOM.findDOMNode(this.refs["refinput_password"]).value =
              this.state.integrationsDetails.password;
          }
          if (this.refs['refinput_client_id'] !== undefined &&
            this.refs['refinput_client_id'] !== null) {
            ReactDOM.findDOMNode(this.refs["refinput_client_id"]).value =
              this.state.integrationsDetails.client_id;
          }
          if (this.refs['refinput_secret_key'] !== undefined &&
            this.refs['refinput_secret_key'] !== null) {
            ReactDOM.findDOMNode(this.refs["refinput_secret_key"]).value =
              this.state.integrationsDetails.secret_key;
          }
          if (this.refs['refinput_crm_url'] !== undefined &&
            this.refs['refinput_crm_url'] !== null) {
            ReactDOM.findDOMNode(this.refs["refinput_crm_url"]).value =
              this.state.integrationsDetails.crm_url;
          }

          if (this.refs['refradio_authtype_user'] !== undefined &&
            this.refs['refradio_authtype_user'] !== null) {
            if (this.state.integrationsDetails.auth_type == "USER") {
              ReactDOM.findDOMNode(this.refs["refradio_authtype_user"]).defaultChecked = true;
              ReactDOM.findDOMNode(this.refs["refradio_authtype_user"]).checked = true;
              ReactDOM.findDOMNode(this.refs["refradio_authtype_user"]).defaultValue = "USER";
              ReactDOM.findDOMNode(this.refs["refradio_authtype_user"]).value = "USER";
            }
          }
          if (this.refs['refradio_authtype_oauth'] !== undefined &&
            this.refs['refradio_authtype_oauth'] !== null) {
            if (this.state.integrationsDetails.auth_type == "OAUTH") {
              ReactDOM.findDOMNode(this.refs["refradio_authtype_oauth"]).defaultChecked = true;
              ReactDOM.findDOMNode(this.refs["refradio_authtype_oauth"]).checked = true;
              ReactDOM.findDOMNode(this.refs["refradio_authtype_oauth"]).defaultValue = "OAUTH";
              ReactDOM.findDOMNode(this.refs["refradio_authtype_oauth"]).value = "OAUTH";
            }
          }

          if (this.refs['refradio_authtype_apikey'] !== undefined &&
          this.refs['refradio_authtype_apikey'] !== null) {
          if (this.state.integrationsDetails.auth_type == "APIKEY") {
            ReactDOM.findDOMNode(this.refs["refradio_authtype_apikey"]).defaultChecked = true;
            ReactDOM.findDOMNode(this.refs["refradio_authtype_apikey"]).checked = true;
            ReactDOM.findDOMNode(this.refs["refradio_authtype_apikey"]).defaultValue = "APIKEY";
            ReactDOM.findDOMNode(this.refs["refradio_authtype_apikey"]).value = "APIKEY";
          }
        }

          //test connection 
          let params = {};
          if (this.state.integrationsDetails.auth_type === "OAUTH") {
            params = {
              //fk_crm_version_id: this.state.integrationsDetails.fk_crm_version_id,
              fk_crm_version_id: "1",
              /* user_id: this.state.integrationsDetails.user_id,
              password: this.state.integrationsDetails.password,
              client_id: this.state.integrationsDetails.client_id,
              secret_key: this.state.integrationsDetails.secret_key,
              crm_url: this.state.integrationsDetails.crm_url, */
              user_id: this.encrypt(this.state.integrationsDetails.user_id),
              password: this.encrypt(this.state.integrationsDetails.password),
              client_id: this.encrypt(this.state.integrationsDetails.client_id),
              secret_key: this.encrypt(this.state.integrationsDetails.secret_key),
              crm_url: this.encrypt(this.state.integrationsDetails.crm_url),
              auth_type: this.state.integrationsDetails.auth_type
              //redirectUri: 'http://localhost:8443/callback/'
            }
            //console.log("OAUTH", params);
          } else {
            //USER
            params = {
              /* fk_crm_version_id: this.state.integrationsDetails.fk_crm_version_id, */
              fk_crm_version_id: "1",
              /* user_id: this.state.integrationsDetails.user_id,
              password: this.state.integrationsDetails.password,
              crm_url: this.state.integrationsDetails.crm_url, */
              user_id: this.encrypt(this.state.integrationsDetails.user_id),
              password: this.encrypt(this.state.integrationsDetails.password),
              crm_url: this.encrypt(this.state.integrationsDetails.crm_url),
              auth_type: this.state.integrationsDetails.auth_type
              //redirectUri: 'http://localhost:8443/callback/'
            }
            //console.log("USER", params);
          }
          //console.log("connection details", params);
          //currently connection details hardcoded - REMOVE Rekha
          axios
            .post("/testconnection", { data: JSON.stringify(params) })
            .then(response => {
              //console.log("testetsthgfjhsdfgjhds---", response);
              if (response !== undefined && response.data !== undefined && response.status !== undefined) {
                if (response.data.status === 0) {
                  //console.log("testetsthgfjhsdfgjhds---", response.data.status);
                  this.setState({ connectionSuccessfull: true });
                } else {
                  this.setState({ connectionSuccessfull: false });
                }
              } else {
                this.setState({ connectionSuccessfull: false });
              }
            })
            .catch(error => {
              console.log(error);
              this.setState({ connectionSuccessfull: false });
            });
          //test connection 
          //console.log("ref checked", this.refs);
        }
      });
    }
  }

  handleCredentialsClose_Yes = () => {
    this.setState({ connectionSuccessfull: null });
    this.setState({ crmSettingsChangedDialog: false });
    this.setState({ crmSettingsChanged: true });
    this.props.setIntegrationDetails(this.state.integrationsDetails);
    //test connection 
    let params = {};
    if (this.state.integrationsDetails.auth_type === "OAUTH") {
      params = {
        //fk_crm_version_id: this.state.integrationsDetails.fk_crm_version_id,
        fk_crm_version_id: "1",
        /* user_id: this.state.integrationsDetails.user_id,
        password: this.state.integrationsDetails.password,
        client_id: this.state.integrationsDetails.client_id,
        secret_key: this.state.integrationsDetails.secret_key,
        crm_url: this.state.integrationsDetails.crm_url, */
        user_id: this.encrypt(this.state.integrationsDetails.user_id),
        password: this.encrypt(this.state.integrationsDetails.password),
        client_id: this.encrypt(this.state.integrationsDetails.client_id),
        secret_key: this.encrypt(this.state.integrationsDetails.secret_key),
        crm_url: this.encrypt(this.state.integrationsDetails.crm_url),
        auth_type: this.state.integrationsDetails.auth_type
        //redirectUri: 'http://localhost:8443/callback/'
      }
      //console.log("OAUTH", params);
    } else {
      //USER
      params = {
        /* fk_crm_version_id: this.state.integrationsDetails.fk_crm_version_id, */
        fk_crm_version_id: "1",
        /* user_id: this.state.integrationsDetails.user_id,
        password: this.state.integrationsDetails.password,
        crm_url: this.state.integrationsDetails.crm_url, */
        user_id: this.encrypt(this.state.integrationsDetails.user_id),
        password: this.encrypt(this.state.integrationsDetails.password),
        crm_url: this.encrypt(this.state.integrationsDetails.crm_url),
        auth_type: this.state.integrationsDetails.auth_type
        //redirectUri: 'http://localhost:8443/callback/'
      }
      //console.log("USER", params);
    }
    //console.log("connection details", params);
    //currently connection details hardcoded - REMOVE Rekha
    axios
      .post("/testconnection", { data: JSON.stringify(params) })
      .then(response => {
        //console.log("testetsthgfjhsdfgjhds---", response);
        if (response !== undefined && response.data !== undefined && response.status !== undefined) {
          if (response.data.status === 0) {
            //console.log("testetsthgfjhsdfgjhds---", response.data.status);
            this.setState({ connectionSuccessfull: true });
          } else {
            this.setState({ connectionSuccessfull: false });
          }
        } else {
          this.setState({ connectionSuccessfull: false });
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({ connectionSuccessfull: false });
      });
    //test connection 
  }

  handlePipelineChange = async (event, hsPipeline) => {
    const value = event.target.value;
    const label = event.target[event.target.selectedIndex].text;
   
    console.log("Pipeline Change : ", value, label);
    
    try{
      console.log('updated hubspot integration details ', this.state.integrationsDetails);
      var intDetails = this.state.integrationsDetails; 
      
      intDetails.crm_org_id = value; 
      intDetails.crm_org_name=label;

    var result = await this.getHubSpotIntegrationDetails();
    console.log('updated hubspot integration details ', this.state.integrationsDetails);
    var intDetails = this.state.integrationsDetails; 
    
    intDetails.crm_org_id = value; 
    intDetails.crm_org_name=label;
    
    this.state.integrationsDetails = intDetails; 
    this.props.setIntegrationDetails(this.state.integrationsDetails);
    console.log(this.state.integrationsDetails);
    }catch(err){
      console.log(err);
    }

     
  }

  handleOrganizationChange = (event, account) => {
    const value = event.target.value;
    const label = event.target[event.target.selectedIndex].text;
    console.log(event.target);
    console.log("value", value, label);
    console.log("Org", account);
    
    var intDetails = this.state.integrationsDetails; 
    intDetails.account_id = value; 

    this.state.integrationsDetails = intDetails; 
    this.props.setIntegrationDetails(this.state.integrationsDetails);
    console.log(this.state.integrationsDetails);
  }

  render() {
    return (
      <React.Fragment>
        <div className={styles.main_wrapper}>
          <div className="row">
            <div className="col-12">

              <div className={"breadcrumbs row " + styles.centerAlign}>
                <div className="col-md-6 col-12">
                  <h2 className="heading">Integrations</h2>
                  <div className="breadcrumbs_container">
                    <Breadcrumbs separator="›" arial-label="Breadcrumb">
                      {/* <Link to="/sales-news">Home</Link>
                      <Link to="/integrations">Integrations</Link> */}
                      <span>Home</span>
                      <span>Integrations</span>
                      <span>Configure CRM</span>
                    </Breadcrumbs>
                  </div>
                </div>
              </div>
              <div className="row container-body">
                <div className={`col-xs-12 col-md-12 col-lg-12 col-12`}>
                  <div className={styles.configureCRM_container}>

                    <div className={styles.configureCRM_container_header}>
                      <h1 className={styles.configureCRM_container_heading}>
                        Step 1 of 5 - Configure CRM Details
                        </h1>
                    </div>

                    <div className={styles.configureCRM_container_footer} >
                      {/* start */}
                      {/* {this.state.errorLoadingIntegrationDetails ?
                        (<div><OopsError /></div>) :
                        this.state.loadingIntegrationDetails ? (<SmallLoader />) :
                          this.state.integrationsDetails.length === 0 ?
                            (<div><NoRecordsFound /></div>) : */}
                      {this.state.errorInWizard ?
                        (<div><WizardError /></div>) :
                        this.state.loadingIntegrationDetails ? (<SmallLoader />) :
                          this.state.integrationsDetails.length === 0 ?
                            (<div><NoRecordsFound /></div>) :
                            // ({ configureView }) 
                            (<div>
                              <div className="row">
                                <div className="col-12">
                                  <div className="bg-white ml-4 mr-4">
                                    <div className="mt-5 mb-4">
                                      <div className="row">
                                        <div className="col-sm-2" />
                                        {/*  <div className="col-sm-4">
                                      <label className={styles.profileContainer_label__ykzKB}>Choose CRM Version<span style={{ color: '#c00' }}> *</span></label>
                                      <select onChange={this.handleCRMVersionChange} className={styles.profileContainer_inputStyle__1Dduu}>
                                        <option value={''} selected={this.state.integrationsDetails.fk_crm_version_id === null}>--- Choose Version ---</option>
                                        {this.state.CRMVersions.map(crmVer =>
                                          <option selected={crmVer.idcrm_version_master == this.state.integrationsDetails.fk_crm_version_id} key={crmVer.idcrm_version_master} value={crmVer.idcrm_version_master}>{crmVer.version_name}</option>
                                        )};
                                          </select>
                                    </div> */}
                                        <div className="col-sm-8">
                                        {
                                        this.props.idcrm_master == '1' ? 
                                          (<div>
                                              <label className={styles.profileContainer_label__ykzKB}>Connection URL<span style={{ color: '#c00' }}> *</span></label>
                                              <input ref='refinput_crm_url' name="crm_url" onChange={this.handleConnectionURLChange} defaultValue={this.state.integrationsDetails.crm_url} className={styles.profileContainer_inputStyle__1Dduu} type="text" autoComplete="off" />
                                              {this.state.integrationsValidity.crm_url.touched ? (
                                            this.state.integrationsValidity.crm_url.valid ? null : (
                                              <span className={styles.errors}>
                                                {this.state.integrationsValidity.crm_url.error_message}
                                              </span>
                                              )
                                            ) :null}
                                          </div>) :
                                        this.props.idcrm_master == '5' ?
                                            (<div>
                                              <label className={styles.profileContainer_label__ykzKB}>Connection URL<span style={{ color: '#c00' }}> *</span></label>
                                              <input ref='refinput_crm_url' name="crm_url"  onChange={this.handleConnectionURLChange} defaultValue={this.state.integrationsDetails.crm_url} className={styles.profileContainer_inputStyle__1Dduu} type="text" autoComplete="off" />
                                              {this.state.integrationsValidity.crm_url.touched ? (
                                            this.state.integrationsValidity.crm_url.valid ? null : (
                                              <span className={styles.errors}>
                                                {this.state.integrationsValidity.crm_url.error_message}
                                              </span>
                                              )
                                            ) :null}
                                          </div>) :null 
                                        }
                                        </div>
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2" />

                                        <div className="col-sm-8">
                                          {/* <br /><hr className="jss201" style={{ width: '100%' }} /><br /> */}
                                          <h6 className="mb-4 mt-2 text-center">Authenication Type</h6>
                                        </div>
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2" />
                                        {this.props.idcrm_master == '5' ?
                                          <div className="col-sm-4">
                                            <label> 
                                                <input ref='refradio_authtype_apikey' name="authtype_radio" type="radio" defaultValue="APIKEY" onChange={(event) => this.handleAuthenticationChanged(event)} defaultChecked={this.state.integrationsDetails.auth_type == "APIKEY"} />
                                                &nbsp;&nbsp;&nbsp;API Key Based
                                            </label>
                                          </div>
                                         :  <> 
                                            <div className="col-sm-4">
                                              <label>
                                                <input ref='refradio_authtype_oauth' name="authtype_radio" type="radio" defaultValue="OAUTH" onChange={(event) => this.handleAuthenticationChanged(event)} defaultChecked={this.state.integrationsDetails.auth_type == "OAUTH"} />
                                                &nbsp;&nbsp;&nbsp;OAuth Based
                                            </label>
                                            </div>
                                            <div className="col-sm-4">
                                            {this.props.idcrm_master == '1' ?
                                              <label> 
                                                <input ref='refradio_authtype_user' name="authtype_radio" type="radio" defaultValue="USER" onChange={(event) => this.handleAuthenticationChanged(event)} defaultChecked={this.state.integrationsDetails.auth_type == "USER"} />
                                                &nbsp;&nbsp;&nbsp;User credentials Based
                                            </label>
                                              :  null }
                                            </div> 
                                         </>
                                         }
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2" />

                                        <div className="col-sm-2" />
                                        <div className="col-sm-2" />
                                        <div className="col-sm-8">
                                          <br /><hr className="jss201" style={{ width: '100%' }} /><br />
                                          <h4 className="mb-4 mt-2 text-center"> 
                                          {
                                            this.props.idcrm_master == '1' ? <>Login Credentials</> : 
                                            this.props.idcrm_master == '3' ? <>SuperOffice Configuration</> :
                                            this.props.idcrm_master == '5' ? <>HubSpot Configuration </> : null }
                                          </h4>
                                        </div>
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2" />

                                           <div className="col-sm-4">
                                           {this.props.idcrm_master == '1' ?
                                           <span>
                                           <label className={styles.profileContainer_label__ykzKB}>User Login ID<span style={{ color: '#c00' }}> *</span></label>
                                           <input ref='refinput_user_id' name="user_id" onChange={this.handleLoginIdChange} defaultValue={this.state.integrationsDetails.user_id} className={styles.profileContainer_inputStyle__1Dduu} type="text" autoComplete="off" />
                                           {this.state.integrationsValidity.user_id.touched ? (
                                             this.state.integrationsValidity.user_id.valid ? null : (
                                               <span className={styles.errors}>
                                                 {this.state.integrationsValidity.user_id.error_message}
                                               </span>
                                             )
                                           ) : null} 
                                         </span>
                                         :null}

                                          {this.props.idcrm_master == '5' ?
                                           <span>
                                           <label className={styles.profileContainer_label__ykzKB}>API Key<span style={{ color: '#c00' }}> *</span></label>
                                           <input ref='refinput_api_key' name="api_key" onChange={this.handleApiKeyChange} defaultValue={this.state.integrationsDetails.secret_key} className={styles.profileContainer_inputStyle__1Dduu} type="text" autoComplete="off" />
                                           {this.state.integrationsValidity.user_id.touched ? (
                                             this.state.integrationsValidity.user_id.valid ? null : (
                                               <span className={styles.errors}>
                                                 {this.state.integrationsValidity.user_id.error_message}
                                               </span>
                                             )
                                           ) : null} 
                                         </span>
                                         :null}

                                         </div>
                                        
                                        <div className="col-sm-4">
                                        {this.props.idcrm_master == '1' ?
                                        <span>
                                          <label className={styles.profileContainer_label__ykzKB}>Password<span style={{ color: '#c00' }}> *</span></label>
                                          <input ref='refinput_password' name="password" onChange={this.handlePasswordChange} defaultValue={this.state.integrationsDetails.password} className={styles.profileContainer_inputStyle__1Dduu} type="password" autoComplete="off" />
                                          {this.state.integrationsValidity.password.touched ? (
                                            this.state.integrationsValidity.password.valid ? null : (
                                              <span className={styles.errors}>
                                                {this.state.integrationsValidity.password.error_message}
                                              </span>
                                            )
                                          ) : null}
                                        </span>
                                        :null}
                                        </div>
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2" />
                                       
                                        {this.state.integrationsDetails.auth_type === "OAUTH" ?
                                          
                                          <div className="col-sm-8">  {this.props.idcrm_master == '1' ?  <>
                                            <label className={styles.profileContainer_label__ykzKB}>Client Account ID<span style={{ color: '#c00' }}> *</span></label>
                                            <input ref='refinput_client_id' disabled={true} name="client_id" onChange={this.handleClientIdChange} defaultValue={this.state.integrationsDetails.client_id} className={styles.profileContainer_inputStyle__1Dduu} type="password" autoComplete="off" />
                                            {this.state.integrationsValidity.client_id.touched ? (
                                              this.state.integrationsValidity.client_id.valid ? null : (
                                                <span className={styles.errors}>
                                                  {this.state.integrationsValidity.client_id.error_message}
                                                </span>
                                              )
                                            ) : null}
                                            <label className={styles.profileContainer_label__ykzKB}>Secret Key / Security Token<span style={{ color: '#c00' }}> *</span></label>
                                            <input ref='refinput_secret_key' disabled={true} name="secret_key" onChange={this.handleSecretKeyChange} defaultValue={this.state.integrationsDetails.secret_key} className={styles.profileContainer_inputStyle__1Dduu} type="password" autoComplete="off" />
                                            {this.state.integrationsValidity.secret_key.touched ? (
                                              this.state.integrationsValidity.secret_key.valid ? null : (
                                                <span className={styles.errors}>
                                                  {this.state.integrationsValidity.secret_key.error_message}
                                                </span>
                                              )
                                            ) : null}
                                            </>  :null }
                                            {
                                             this.props.idcrm_master == '1' ?
                                               <button onClick={this.handleTestConnectionClicked} className="btn btn-warning btn-sm mt-3" type="button" style={{ padding: '5px 20px' }}>Test Salesforce Connection</button>
                                              :
                                              this.props.idcrm_master == '5' ?
                                               <button onClick={this.handleHSTestConnectionClicked} className="btn btn-warning btn-sm mt-3" type="button" style={{ padding: '5px 20px' }}>Test HubSpot Connection</button>
                                              :
                                               <button onClick={this.handleSOconnectionClicked} className="btn btn-warning btn-sm mt-3" type="button" style={{ padding: '5px 20px' }}>Connect to {this.props.crm_name}</button>
                                            }
                                            </div>
                                              : <div className="col-sm-8">
                                                {this.props.idcrm_master == '1' ?
                                                  <button onClick={this.handleTestConnectionClicked} className="btn btn-warning btn-sm mt-3" type="button" style={{ padding: '5px 20px' }}>Test Salesforce Connection</button>
                                                :this.props.idcrm_master == '5' ?
                                                  <button onClick={this.handleHSTestConnectionClicked} className="btn btn-warning btn-sm mt-3" type="button" style={{ padding: '5px 20px' }}>Test HubSpot Connection</button>
                                                : 
                                                  <button onClick={this.handleSOconnectionClicked} className="btn btn-warning btn-sm mt-3" type="button" style={{ padding: '5px 20px' }}>Connect to {this.props.crm_name}</button>
                                              }
                                              </div>
                                            }
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2" />
                                        
                                        {this.state.connectionSuccessfull ?
                                          (<div className="col-sm-8">
                                            <div className="alert alert-success" role="alert" style={{ marginTop: 20 }}>
                                              <div className="alert-text">
                                                <h4 className="alert-heading">Connection Successful !!</h4>
                                                <p>Your credentials have been verified and the connection has been established successfully.</p>
                                                <p>
                                                  {this.state.integrationsDetails.system_user_token ? 
                                                    <span>Your token to connect to SuperOffice is genereted successfully</span>
                                                  : null
                                                   }
                                                </p>
                                                <hr />
                                                <p className="mb-0">Please proceed for further configuration.</p>
                                              </div>

                                              <div>
                                              <label className={styles.profileContainer_label__ykzKB}>Organization<span style={{ color: '#c00' }}> *</span></label>
                                              {
                                                  this.state.accounts ? 
                                                  <select className={styles.profileContainer_inputStyle__1Dduu} style={{ backgroundColor: '#fff' }} onChange={(event) => this.handleOrganizationChange(event, this.state.accounts)}>
                                                  <option value={-1} selected="1">--- Choose Organization ---</option>
                                                  {this.state.accounts.map((acc, index) =>
                                                    <option key={index} value={acc.id}>{acc.name}</option>
                                                  )
                                                  }
                                                  </select>
                                                  : <select className={styles.profileContainer_inputStyle__1Dduu} style={{ backgroundColor: '#fff' }}>
                                                    <option value={-1} selected="1"></option></select>
                                                } 
                                              </div>        
                                              <div>
                                              <label className={styles.profileContainer_label__ykzKB}>Pipeline<span style={{ color: '#c00' }}> *</span></label>
                                                {this.state.crmOrgDetails ?
                                                <select className={styles.profileContainer_inputStyle__1Dduu} style={{ backgroundColor: '#fff' }} onChange={(event) => this.handlePipelineChange(event, this.state.crmOrgDetails)}>
                                                  <option value={-1}>--- Choose HubSpot Pipeline ---</option>
                                                  {this.state.crmOrgDetails.map((orgDetails, index) =>
                                                    <option key={index} value={orgDetails.id}>{orgDetails.label}</option>
                                                  )}
                                                </select>
                                                :
                                                  <select className={styles.profileContainer_inputStyle__1Dduu} style={{ backgroundColor: '#fff' }}>
                                                    <option value={-1} selected="1"></option></select>
                                                }
                                              </div>
                                            </div>
                                          </div>) : (this.state.connectionSuccessfull !== null) ? (
                                            <div className="col-sm-6">
                                              <div className="alert alert-danger" role="alert" style={{ marginTop: 20 }}>
                                                <div className="alert-text">
                                                  <h4 className="alert-heading">Error In Connection...</h4>
                                                  <p>Sorry! The connection could not be established. Please verify your connection URL and login credentials.</p>
                                                </div>
                                              </div>
                                            </div>) : null}
                                        <div className="col-sm-2" />
                                      </div>
                                    </div>
                                    <hr className="jss201" />
                                    <div className="mt-3 mb-5">
                                      <div style={{ textAlign: 'right' }}>
                                        <button onClick={this.handleCancelClicked} className={styles.profileContainer_update_btn__MXNDH} type="button" style={{ backgroundColor: '#e0e0e0', padding: '10px 20px', color: 'rgba(0, 0, 0, 0.87)' }}>Cancel</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <button onClick={() => this.handleSaveClicked("step1")} type="button" className={styles.profileContainer_update_btn__MXNDH} style={{ padding: '10px 20px' }}>Save</button>&nbsp;&nbsp;&nbsp;&nbsp;
           <button onClick={() => this.handleSaveNProceedClicked("step1")} type="button" className={styles.profileContainer_update_btn__MXNDH} style={{ padding: '10px 20px' }}>Save &amp; Proceed</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>)
                      }
                      {/* end */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Dialog
            open={this.state.crmSettingsChangedDialog}
            onClose={this.handleCredentialsClose_No}
            aria-labelledby="alert-dialog-title"
            fullWidth
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="simple-dialog-title">You are about to change the Salesforce configuration which will overwrite the previous configuration. The configuration changes will affect new Leads or Opportunities. The information of Synchronized Leads will remain unchanged. Do you want to continue?
            </DialogTitle>
            <DialogActions className="action-btn-wrap">
              <Button
                variant="contained"
                onClick={() => {
                  this.handleCredentialsClose_Yes();
                }}
                className="action-btn yes-btn" >
                Yes
          </Button>
              <Button variant="contained" className="action-btn"
                onClick={() => {
                  this.handleCredentialsClose_No();
                }} autoFocus>
                No
          </Button>
            </DialogActions>
          </Dialog>
        </div>
      </React.Fragment>
      )
  }
}

export default withStyles(stylesMaterialUI)(withRouter(ConfigureCRMComponent));