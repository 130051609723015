import React from 'react';
import styles from './formErrors.module.scss';

export const FormErrors = ({formErrors,field}) =>
  <div className={styles.error_msg}>
    {
      Object.keys(formErrors).map((fieldName, i) => {
        if(field === fieldName && formErrors[fieldName].length > 0){
          return (
            <p key={i}> {formErrors[fieldName]}</p>
          )         
        } else {
          return '';
        }
      })
    }
  </div>