import React from 'react';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import styles from '../data_export.module.scss';

export default function DownloadSection ({onPdfClick, onExcelClick, count}) {
  return (
    <div className="d-flex py-2 justify-content-center" style={{ backgroundColor: '#CAE3CD' }}>
      <span className="my-auto form-inline">
          <b>{count}</b>
          <span className="mx-1">records found.</span>
          <b><u>Export as</u></b> &nbsp;&nbsp;
          <div onClick={onPdfClick} className="border-rounded pointer" style={{height:'30px',width:'28px',backgroundColor:'#3A4860',textAlign:'center'}}>
            <div className={styles.pdf_icon}>
            </div>
          </div>
          <span className="mx-1">OR</span>
          <div onClick={onExcelClick} className="border-rounded pointer" style={{height:'30px',width:'28px',backgroundColor:'#3A4860',textAlign:'center'}}>
            <div className={styles.excel_icon}>
            </div>
          </div>
      </span>
  </div>
  );
}