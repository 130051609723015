import React, { Component } from "react";
import styles from "./FieldsMappingCRMComponent.module.scss";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import "rc-time-picker/assets/index.css";
import CircularProgress from "../../../common/Loaders/CircularLoader/circular-loader";

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing.unit
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={`${classes.root} ${styles.dialogTitle}`}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(() => ({
  root: {
    // padding: "30px"
    padding: "24px"
  }
}))(props => {
  const { children, classes } = props;
  return (
    <MuiDialogContent
      className={`${classes.root} ${styles.fields_mapping_dialog_content}`}
    >
      {children}
    </MuiDialogContent>
  );
});

const DialogActions = withStyles(() => ({
  root: {
    margin: 0,
    padding: "5px 0 0 0"
  }
}))(MuiDialogActions);

const stylesMaterialUI = () => ({});

class FieldsMappingCRMComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      objectMapping: this.props.fieldMapping,
      isCRM_leadx_sync: this.props.isCRM_leadx_sync,
      isLeadx_CRM_sync: this.props.isLeadx_CRM_sync
      //crm_name: this.props.crm_name
    };
  }

  componentDidMount() {
    // console.log("props", this.props);
    // console.log("obj mapping", this.state.objectMapping);
    // console.log("lead_to_crm", this.state.objectMapping.lead_to_crm);
    // console.log("crm_to_lead", this.state.objectMapping.crm_to_lead);
  }

  handleCloseClick = () => {
    //console.log("Close clicked");
  };

  render() {
    return (
      <div className={styles.fields_mapping + " overflow_x_hidden"}>
        <DialogTitle id="customized-dialog-title" onClose={this.props.close}>
          <div className={styles.fields_mapping_dialog_title}>
            {this.props.crm_name} Object Mapping
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="row">
            {this.state.isLeadx_CRM_sync ? 
            <>
            <div className={this.state.isLeadx_CRM_sync && this.state.isCRM_leadx_sync ? 
            "col-sm-6 text-center" : "col-sm-12 text-center"}>
            
              <h6 style={{ backgroundColor: '#232f44', color: '#fff', padding: '10px' }}>
                LeadX&nbsp;&nbsp;&harr;&nbsp;&nbsp;{this.props.crm_name}</h6>

              <table className="table table-striped- table-bordered table-hover table-checkable">
                      <thead>
                        <tr style={{ textAlign: 'center' }}>
                          <th style={{ width: '50%', textAlign: 'center' }}>LeadX</th>
                          <th style={{ width: '50%', textAlign: 'center' }}>{this.props.crm_name}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.objectMapping.lead_to_crm.map((fieldMap, index) =>
                          <tr key={index} style={{ textAlign: 'center' }}>
                            <td>{fieldMap.from_leadx_field}</td>
                            <td>{fieldMap.to_crm_field}</td>
                          </tr>
                        )
                        }
                      </tbody>
                    </table>

            </div>
            </>
            : <></>}
            {this.state.isCRM_leadx_sync ? 
            <div className={this.state.isLeadx_CRM_sync && this.state.isCRM_leadx_sync ? 
              "col-sm-6 text-center" : "col-sm-12 text-center"}>
            <h6 style={{ backgroundColor: '#232f44', color: '#fff', padding: '10px' }}>
            {this.props.crm_name}&nbsp;&nbsp;&harr;&nbsp;&nbsp;LeadX</h6>

              <table className="table table-striped- table-bordered table-hover table-checkable">
                      <thead>
                        <tr>
                          <th style={{ width: '25%', textAlign: 'center' }}>{this.props.crm_name}</th>
                          <th style={{ width: '25%', textAlign: 'center' }}>LeadX</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.objectMapping.crm_to_lead.map((fieldMap, index) =>
                          <tr key={index} style={{ textAlign: 'center' }}>
                            <td>{fieldMap.from_crm_field}</td>
                            <td>{fieldMap.to_leadx_field}</td>
                          </tr>
                        )
                        }
                      </tbody>
                    </table>
            </div>
            : <></> }
            {/* <table className="table table-striped- table-bordered table-hover table-checkable mt-8 mb-8">
              <thead>
                <tr>
                  <th style={{ width: '50%', textAlign: 'center' }}>LeadX&nbsp;&nbsp;&harr;&nbsp;&nbsp;Salesforce</th>

                  <th style={{ width: '50%', textAlign: 'center' }}>Salesforce&nbsp;&nbsp;&harr;&nbsp;&nbsp;LeadX</th>

                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    
                  </td>
                  <td>
                    
                  </td>
                </tr>
              </tbody>
            </table> */}
          </div>
          <DialogActions>
            {this.state.submitButton ? (
              <div className={styles.submit_loader}>
                <CircularProgress
                  style={{
                    color: "#19D98C"
                  }}
                />
              </div>
            ) : (
                <Button
                  onClick={this.props.close}
                  className={styles.saveButton}
                  color="primary"
                >
                  Close
              </Button>
              )}
          </DialogActions>
        </DialogContent>
      </div>
    );
  }
}
export default withStyles(stylesMaterialUI)(FieldsMappingCRMComponent);
