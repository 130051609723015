import * as commonTypes from '../../../common/common-types';
import axios from "../../../common/api-config";

export const getPersonList = (requestBody) => {
  return dispatch => {
    dispatch({type:commonTypes.HANDLE_LOADING,data:true});
    axios
      .post("/contact/person/getPersonList", requestBody)
      .then( async (response) => {
        if(response && response.data && response.data.statusCode == 200){
          if(requestBody && requestBody.paginate && requestBody.paginate.page == 0){
            await dispatch(personListSuccess(response.data.data));
          }else{
          await dispatch(loadMorePersonListSuccess(response.data.data));
          }
          dispatch({type:commonTypes.HANDLE_LOADING,data:false});
        }else{
          await dispatch(personListFailed(response.data.errorMessage));  
          dispatch({type:commonTypes.HANDLE_LOADING,data:false});
        }
      })
      .catch(async (error) => {
        await dispatch(personListFailed(error.errorMessage));
        dispatch({type:commonTypes.HANDLE_LOADING,data:false});
      });
  };
};

const personListFailed = message => {
  return {
    type: 'PERSON_LIST_FAILED',
    errorMessage: message
  };
};

const personListSuccess = data => {
  return {
    type: 'PERSON_LIST_SUCCESS',
    data: data
  };
};

const loadMorePersonListSuccess = data => {
  return {
    type: 'LOAD_MORE_PERSON_LIST_SUCCESS',
    data
  }
}

export default {
  getPersonList, 
};
