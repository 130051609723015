import axios from "../../common/api-config";

export default {
  getChildOrgDetail(account_id) {
    return axios.post('/account/getChildOranizationAccountDetails', {account_id}).then(response => {
      const {email, country_code, contact} = response.data.data.primary_contact || {};
      const {
        // primary_contact: { email, country_code, contact },
        profile: { city_id, state_id, country_id, address_line_1, address_line_2, zipcode, logo, name },
        cityDetails,
      } = response.data.data;
      return {
        email,
        country_code,
        contact,
        name,
        city_id,
        state_id,
        country_id,
        address_line_2,
        address_line_1,
        zipcode,
        logo,
        cityName: cityDetails.name,
      };
    });
  },
  async getOrganizationDetails(account_id) {
    const [parent_detail, data] = await Promise.all([this.getParentDetail(), this.getChildOrgDetail(account_id)]);
    return { ...data, ...parent_detail};
  },
  async getCountries() {
    const url = '/master/territory/country';
    const result = await axios.post(url, {});
    return result.data.data.rows;
  },
  async getMasterCurrency() {
    const url = '/master/getMstCurrency';
    const result = await axios.post(url, {});
    return result.data.data.rows;
  },
  async getBaseCurrencyList() {
    const url = '/master/getBaseCurrencyList';
    const result = await axios.post(url, {});
    return result.data.data.rows;
  },
  async searchCountryStateByCityName(text) {
    if (!text) return [];
    const url = '/master/territory/searchCityByName';
    const result = await axios.post(url, { text });
    const options = result.data.data.data.rows.map(c => ({ value: c.id, label: c.name, country_id: c.country_id, state_id: c.state_id })).filter((_, i) => i < 10);
    console.log(result, "Res");
    console.log(options, 'Data');
    return options;
  },
  async getStates(countryId) {
    const url = '/master/territory/country/state';
    const body = {
      arrayFilters: [
        {
          country_id: countryId ? countryId.toString() : ""
        }
      ],
      selectFilters: ["id", "name"],
      sort: {
        field: "name",
        sortOrder: "ASC"
      },
      paginate: {
        page: 0
      }
    };
    const result = await axios.post(url, body);
    return result.data.data.rows;
  },
  async getLanguageList() {
    const url = '/master/getLanguageList';
    const result = await axios.post(url, {});
    return result.data.data.rows;
  },
  async getDateFormatList() {
    const url = '/master/getDateFormatList';
    const result = await axios.post(url, {});
    return result.data.data.rows;
  },
  async getTimeZoneList() {
    const url = '/master/getTimeZoneList';
    const result = await axios.post(url, {});
    return result.data.data.rows;
  },
  getParentDetail() {
    const url = '/account/getParentOrganizationDetails';
    return axios.post(url, {}).then(response => {
      console.log(response.data.data,"Res");
      const { 
        account_preference: { language_id, timezone_id, date_format_id, currency_id }, 
        client_id, dns, key, account_group 
      } = response.data.data.result.data;
      const result = {
        language_id,
        timezone_id,
        date_format_id,
        client_id,
        registered_url: `${dns}/register/${key}`,
        account_group,
        currency_id,
        other_currency_id: 1,
        dns
      }
      return result;
    });
  },
  uplaodImage(obj) {
    const url = "/account/updateCompanyAvtaar";
    let requestBody = {
      attachment: {
        fileName: obj.fileName,
        fileExtension: obj.fileExtension,
        fileBody: obj.fileBody,
        parentFolder: obj.parentFolder
      }
    };
    console.log(requestBody, 'Req Body');
    return axios.put(url, requestBody).then(response => {
      return response.data.data.s3UploadFile;
    });
  },
  async updateChildOrganization(data, account_id) {
    const url = '/account/upadteChildOrganizationDetails';
    const body = {
      account_id,
      "email": data.email,
      "name": data.name,
      "logo": data.logo,
      "address_line_1": data.address_line_1,
      "address_line_2": data.address_line_2,
      "country_id": data.country.value,
      "state_id": data.state.value,
      "city_id": data.city.value,
      "zipcode": data.zipcode,
      "language_id": data.language.value,
      "date_format_id": data.dateFormat && data.dateFormat.value,
      "timezone_id": data.timezone.value,
      "contact": data.contact,
      "area": data.countryCode.value,
      "isNewCityAdded": data.city.__isNew__,
      "cityName": data.city.label,
      "dns": data.dns,
      "currency_id": data.masterCurrency.value,
      "base_currency_id": data.baseCurrency.value
    };
    return axios.post(url, body).then(response => {
      return response.data.data;
    });
  }
}