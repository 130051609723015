import React, { Component } from "react";
import Multiselect from "@khanacademy/react-multi-select";

import styles from "./styles.module.scss";

export default class multiSelectSalesNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      isLoading: true
    };
  }
  
  
   
  options = this.props.organizationList.map((org)=> {
  if(org.id===this.props.currentUser.organization_id){
    return { value: org, label:"My company"}
  }else 
  { return { value: org, label:org.name}}
})

//   customFilter = (options, filter) => {
//     const optionIncludesText = (option) => {
//         const label = option.label || "";
//         return label.toLowerCase().includes(filter);
//     };
//         return options.filter(optionIncludesText);
// };


  handleChange = selectedOption => {    
  
        this.props.setOrganization({
          name: "org_list",
          value: selectedOption
        });
    }

  

  handleSelectedChanged = selected => {
    this.setState({ selected });
  };

  render() {
    const { selected, isLoading } = this.state;
    return (
      <div>
        <Multiselect
          options={this.options}
          onSelectedChanged={this.handleChange}
          selected={this.props.value}
          //isLoading={isLoading}
          //disabled={false}
          hasSelectAll={ false}
          //disableSearch={false}
          //filterOptions={this.customFilter}
          overrideStrings={{
            selectSomeItems: "Enter organization name",
            allItemsAreSelected: "All",
            selectAll: "Select all",
            search: "Search here"
          }}
          ItemRenderer={StudentItemRenderer}
        />
      </div>
    );
  }
}

class StudentItemRenderer extends Component {


    render() {
        const {checked, option, onClick} = this.props;

        return <span>
            <span className={styles.checkboxContainer}>
                  <span className={styles.container}>
                  <input type="checkbox" 
                  checked={checked} 
                  onChange={onClick} /> 
                  <span className={styles.checkmark}/>
                  </span> 
                  <span>{option.label}</span> 
            </span>
        </span>;
        }
    }

