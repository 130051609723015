import React, { Component } from "react";
import leadXLogo from "../../../../assets/images/login/LeadX_logo.png";
import styles from "./ForgotPasswordComponent.module.scss";
import { Button, Input, FormGroup } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { openSnackbar } from "../../../common/Snackbar/snackbar";
import LargeScreenLoader from "../../../common/Loaders/PulseLoader/pulse-loader";
import { Link } from "react-router-dom";
import axiosInstance from "../../../common/api-config";
import { emailPattern } from "../../../common/common-types";
const stylesMaterialUI = theme => ({
  input: {
    borderBottom: "none",
    color: "black",
    "&:focus": {
      borderBottom: "none"
    }
  }
});

class ForgotPasswordComponent extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    forgotPasswordForm: {
      userName: {
        value: "",
        valid: false,
        touched: false,
        errorMessage: ""
      }
    },
    isLoading: false
  };

  handleUsernameChange = e => {
    let name = e.target.name;
    let userName = e.target.value;
    const updatedLoginForm = {
      ...this.state.loginForm
    };
    const updatedFormElement = {
      ...updatedLoginForm[name]
    };
    updatedFormElement.value = userName;
    updatedFormElement.touched = true;
    if (userName.trim() === "") {
      updatedFormElement.errorMessage = "Please enter a username/email address";
      updatedFormElement.valid = false;
    } else if (!emailPattern.test(userName)) {
      updatedFormElement.errorMessage =
        "Please enter a valid registered username/email address";
      updatedFormElement.valid = false;
    } else if (userName.length > 50) {
      updatedFormElement.errorMessage = "Username length too long";
      updatedFormElement.valid = false;
    } else {
      updatedFormElement.errorMessage = "";
      updatedFormElement.valid = true;
    }
    updatedLoginForm[name] = updatedFormElement;
    this.setState({
      forgotPasswordForm: updatedLoginForm
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.validateForm()) {
      this.setState({ isLoading: true });
      const requestParams = {
        username: this.state.forgotPasswordForm.userName.value
      };
      axiosInstance
        .post("/login/forgotPassword", requestParams)
        .then(response => {
          if (response.data.statusCode === 200) {
            this.setState({
              isLoading: false,
              forgotPasswordForm: {
                userName: {
                  value: "",
                  valid: false,
                  touched: false,
                  errorMessage: ""
                }
              }
            });
            openSnackbar(
              "An email containing a link to reset your password has been sent to your registered email address. If it doesn’t appear within a few minutes, check your spam folder.",
              "snack-success",
              10000
            );
          } else {
            this.setState({
              isLoading: false
            });
            openSnackbar(
              "The entered registered email address appears to be invalid. Please try again.",
              "snack-error"
            );
          }
        })
        .catch(error => {
          this.setState({
            isLoading: false
          });
          openSnackbar(error, "snack-error");
        });
    }
  };

  validateForm = () => {
    const userName = { ...this.state.forgotPasswordForm.userName };
    let isValid = false;
    if (userName.value.trim() === "") {
      userName.errorMessage = "Please enter your email";
    } else if (!emailPattern.test(userName.value)) {
      userName.errorMessage = "Please enter a valid email";
    } else if (userName.value.length > 50) {
      userName.errorMessage = "Please enter a valid email";
    } else {
      isValid = true;
    }
    this.setState({
      forgotPasswordForm: {
        userName: { ...userName }
      }
    });

    return isValid;
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={styles.login_page}>
        {this.state.isLoading ? <LargeScreenLoader /> : ""}
        <div className={styles.login}>
          <div className={styles.logo_container}>
            <img className={styles.logo} src={leadXLogo} alt="Lead X logo" />
          </div>
          <div className={styles.welcome}>
            <h2 className={styles.heading}>Forgot password</h2>
            <h5 className={styles.heading5}>
              We just need your registered email address to send you password
              reset instructions.
            </h5>
          </div>
          <form className={styles.login_form} onSubmit={this.handleSubmit}>
            <FormGroup>
              <div className={styles.input_container}>
                <Input
                  inputProps={{
                    autoFocus: true,
                    maxLength: 50
                  }}
                  className={classes.input + " " + styles.input}
                  type="text"
                  value={this.state.forgotPasswordForm.userName.value}
                  name="userName"
                  placeholder="Enter registered username/email address"
                  onChange={this.handleUsernameChange}
                />
                {/* {this.state.forgotPasswordForm.userName.touched ? (
                  this.state.forgotPasswordForm.userName.valid ? null : (
                    <span className={styles.errors}>
                      {this.state.forgotPasswordForm.userName.errorMessage}
                    </span>
                  )
                ) : null} */}
                {this.state.forgotPasswordForm.userName.valid ? null : (
                  <span className={styles.errors}>
                    {this.state.forgotPasswordForm.userName.errorMessage}
                  </span>
                )}
              </div>
              <div className={styles.login_button_container}>
                <Button
                  className={styles.button}
                  type="submit"
                  disabled={!this.state.forgotPasswordForm.userName.valid}
                >
                  Send password reset link
                </Button>
              </div>
            </FormGroup>
          </form>
          <div className={styles.forgot_password}>
            <h5>
              Back to <Link to="/login">Login</Link>
            </h5>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(stylesMaterialUI)(ForgotPasswordComponent);
