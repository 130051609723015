import React, { useState, useRef } from "react";
import styles from "./page.module.scss";
import styled from "styled-components";
import Input from "@material-ui/core/Input";
import ClearIcon from "@material-ui/icons/Clear";
import useOutsideClick from "../../hooks/outsideClick";

const SearchInputContainer = styled.div`
  border: 0.0625em solid #e3e3e3;
  border-radius: 0.5em;
  padding: 0.3125em;
  padding-left: 1.2em;
  padding-right: 1.2em;
  text-align: right;
  width: 100%;
  float: left;
`;

const SearchInput = ({
  value,
  onSearch,
  options,
  selected,
  onSelect,
  itemComponent,
  ...rest
}) => {
  const searchRef = useRef(null);
  const [toggleDropdownContent, setToggleDropdownContent] = useState();
  const ref = useRef(null);

  useOutsideClick(ref, () => {
    setToggleDropdownContent(false);
  });

  const openDropdownContent = (value) => {
    if (value) {
      setToggleDropdownContent(value);
    } else {
      setToggleDropdownContent(toggleDropdownContent ? false : true);
    }
  };

  const handleOnChange = (e) => {
    setToggleDropdownContent(true);
    onSelect(null);
    onSearch(e.target.value);
  };

  const filteredOptions = options.filter(
    (a) => a.label.toLowerCase().search(value.toLowerCase()) !== -1
  );

  const handleOnSelect = (item) => {
    setToggleDropdownContent(false);
    onSelect(item);
  };

  const handleOnClear = () => {
    setToggleDropdownContent(false);
    onSelect(null);
  };

  const ItemComponent = itemComponent;

  return (
    <>
      <SearchInputContainer
        style={{ background: "#fff" }}
        className={styles.cust_dropdown}
      >
        <div
          ref={ref}
          style={{
            display: "flex",
            fontStyle: "italic",
            backgroundColor: "transparent",
          }}
          onClick={openDropdownContent}
        >
          {selected && !toggleDropdownContent ? (
            <>
              <ItemComponent
                item={selected}
                containerStyle={{ width: "100%" }}
              />
              <span
                onClick={handleOnClear}
                style={{ display: "flex", alignItems: "center" }}
              >
                <ClearIcon />{" "}
              </span>
            </>
          ) : (
            <>
              <Input
                value={value}
                {...rest}
                ref={searchRef}
                onKeyUp={handleOnChange}
                onChange={handleOnChange}
                type="text"
                fullWidth={true}
                disableUnderline={true}
                autoFocus={true}
                autoComplete={false}
                style={{ height: 40 }}
              />
              {!value && (
                <span style={{ display: "flex" }}>
                  <img
                    style={{ width: 20 }}
                    src={require("../../../assets/images/administration/Rounded.svg")}
                    alt="search"
                  />
                </span>
              )}
              {value && (
                <span
                  onClick={handleOnClear}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <ClearIcon />{" "}
                </span>
              )}
            </>
          )}
        </div>
        {filteredOptions.length > 0 && toggleDropdownContent && (
          <div className={styles.cust_dropdown_content}>
            <div className={styles.cust_select_panel}>
              <ul className={styles.cust_select_list}>
                {filteredOptions.map((item) => (
                  <li
                    key={item.value}
                    className={styles.cust_select_li}
                    onClick={() => handleOnSelect(item)}
                  >
                    <ItemComponent
                      item={item}
                      containerStyle={{ paddingLeft: 12, height: 50 }}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </SearchInputContainer>
    </>
  );
};

export default SearchInput;