import React, { useEffect, useState } from "react";

import { Breadcrumbs } from "@material-ui/core/";
import { Link } from "react-router-dom";
import styles from "./graphContainer.module.scss";
import * as actions from "./redux/actions";
import { connect } from "react-redux";
import salesCommunityActions from "../SalesCommunity/redux/actions";
import moment from "moment";
import Rewards from "../PulseLite2/pulseLiteContainer";
import { Select, MenuItem } from "@material-ui/core";
import leadDetailActions from "../LeadDetailPopup/redux/actions";
import { selectMypipelineOrg } from "../MyPipeline/redux/actions";
import API from "./API";
import Pulse from "./Pulse";
import Leads from "./Leads";
import Pipeline from "./PipeLineChart";
import HandOverGraphs from "./HandOverGraphs";
import CrossSilos from "./CrossSilos";
import LargeScreenLoader from "../../common/Loaders/PulseLoader/pulse-loader";
import MySelect from "../../components/SingleSelect";
import MyDateRangePicker from "../../components/DateRangePicker";
import { Button } from "@material-ui/core";
import _ from "lodash";

function getLastFewYears(startingMonth) {
  let date = moment().set("date", 1).set("month", startingMonth).clone();
  date = moment().isBefore(date) ? date.add("year", -1).clone() : date;

  const dates = [];
  for (let i = 0; i < 5; i++) {
    const toDate = date.clone().add("year", 1).add("day", -1);
    dates.push({ fromDate: date.clone(), toDate });
    date = date.add("year", -1).clone();
  }
  return dates.map((d, index) => ({ ...d, current: index === 0 }));
}

const AllOption = { value: 0, label: "All" };

const GraphContainer = ({ ...rest }) => {
  const [organizationList, setOrganizationList] = useState([]);
  const [selectedPage, setSelectedPage] = useState("Pulse");
  const [orgOptionsLoaded, setOrgOptionsLoaded] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(AllOption);
  const [financialYearOptions, setFinancialYearOptions] = useState([]);
  const [selectedFinancialYear, setSelectedFinancialYear] = useState();
  const [dateRange, setDateRange] = useState();
  const [appliedFilters, setAppliedFilters] = useState({});

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (selectedFinancialYear) {
      setDateRange({
        from_date: selectedFinancialYear.value.fromDate,
        to_date: selectedFinancialYear.value.toDate,
      });
    }
  }, [selectedFinancialYear]);

  const fetchInitialData = () => {
    Promise.all([fetchOrgList(), fetchFinancialYearStartingMonth()]).then(
      ([organizationList, dateRange]) => {
        applyFilters(organizationList, dateRange);
        setOrgOptionsLoaded(true);
      }
    );
  };

  const fetchFinancialYearStartingMonth = async () => {
    const month = await API.getFinancialYearStartingMonth();
    const financialYearOptions = getLastFewYears(month).map((a) => ({
      value: a,
      label: `Fin. Yr. ${a.fromDate.format("MMM-YY")} - ${a.toDate.format(
        "MMM-YY "
      )}`,
    }));
    setFinancialYearOptions(financialYearOptions);
    const selectedFinYear = financialYearOptions.find(
      (year) => year.value.current
    );
    setSelectedFinancialYear(selectedFinYear);
    return {
      from_date: selectedFinYear.value.fromDate,
      to_date: selectedFinYear.value.toDate,
    };
  };

  const fetchOrgList = async () => {
    const orgList = await API.getOrganisationList();
    setOrganizationList(orgList);
    setSelectedOrg(
      hasParrentAccount(orgList)
        ? AllOption
        : { value: orgList[0].id, label: orgList[0].name }
    );
    return orgList;
  };

  const handleOnPageChange = (e) => {
    setSelectedPage(e.target.value);
  };

  const hasParrentAccount = (organizationList) => {
    return organizationList.find((a) => a.parent_account_id === 0);
  };

  const renderPage = () => {
    return (
      <>
        {selectedPage === "Pulse" && <Pulse {...appliedFilters} {...rest} />}
        {selectedPage === "Leads" && <Leads {...appliedFilters} {...rest} />}
        {selectedPage === "Rewards" && (
          <Rewards
            {...appliedFilters}
            organizationList={organizationList}
            {...rest}
          />
        )}
        {selectedPage === "Pipeline" && (
          <Pipeline {...appliedFilters} {...rest} />
        )}
        {selectedPage === "Handover" && (
          <HandOverGraphs
            {...appliedFilters}
            parent_account={hasParrentAccount(organizationList)}
            {...rest}
          />
        )}
        {selectedPage === "Crosssales" && (
          <CrossSilos
            {...appliedFilters}
            parent_account={hasParrentAccount(organizationList)}
            {...rest}
          />
        )}
      </>
    );
  };

  const applyFilters = (organizationList, dateRange) => {
    const filters = {
      organizationArray:
        selectedOrg.value === 0
          ? organizationList.map((o) => o.id)
          : [selectedOrg.value],
      fromDate: dateRange.from_date,
      toDate: dateRange.to_date,
    };
    setAppliedFilters(filters);
  };

  const onDateRangeSelected = (dateRange) => {
    setDateRange(dateRange);
  };

  const handleOnApplyClick = () => {
    applyFilters(organizationList, dateRange);
  };

  const hasChangedAppliedFilter = () => {
    const currentSelectedOrgArray =
      selectedOrg.value === 0
        ? organizationList.map((o) => o.id)
        : [selectedOrg.value];
    return (
      !_.isEqual(dateRange, {
        from_date: appliedFilters.fromDate,
        to_date: appliedFilters.toDate,
      }) ||
      !_.isEqual(currentSelectedOrgArray, appliedFilters.organizationArray)
    );
  };

  const disableApplyButton = !hasChangedAppliedFilter();

  const renderHeader = (options) => (
    <>
      <div className="breadcrumbs">
        <div
          className="row justify-content-between"
          style={{ marginBottom: "-3%", height: "78px" }}
        >
          <div className="col-md-2">
            <h3 className={styles.title_Pulselite}>{selectedPage}</h3>
          </div>
          {selectedFinancialYear && (
            <div
              className="col-md-8 align-items-center justify-content-center"
              style={{
                backgroundColor: "#FFFFFF",
                margin: 10,
                marginLeft: 0,
                borderRadius: 5,
                marginRight: 17,
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ flex: 4, paddingTop: 5 }}>
                <MySelect
                  disableSearch
                  key="financial_year"
                  options={financialYearOptions}
                  selected={selectedFinancialYear}
                  onSelect={setSelectedFinancialYear}
                />
              </div>
              <div style={{ flex: 4, paddingLeft: 5, paddingTop: 5 }}>
                <MySelect
                  key="org_selector"
                  options={organizationList.map((a) => ({
                    value: a.id,
                    label: a.name,
                  }))}
                  selected={selectedOrg}
                  onSelect={setSelectedOrg}
                  allOption={hasParrentAccount(organizationList) && AllOption}
                />
              </div>
              <div style={{ flex: 5, paddingLeft: 5, minWidth: 200 }}>
                {dateRange && (
                  <MyDateRangePicker
                    maxWidth={320}
                    minWidth={180}
                    className="form-control"
                    onDateChange={onDateRangeSelected}
                    dateRange={dateRange}
                    minDate={
                      selectedFinancialYear &&
                      selectedFinancialYear.value.fromDate.toDate()
                    }
                    maxDate={
                      selectedFinancialYear &&
                      selectedFinancialYear.value.toDate.toDate()
                    }
                  />
                )}
              </div>
              <div style={{ flex: 2, textAlign: "right" }}>
                <Button
                  onClick={handleOnApplyClick}
                  variant="contained"
                  size="small"
                  className="px-3"
                  style={{
                    backgroundColor: disableApplyButton ? "#737373" : "#232F44",
                    color: "white",
                    textTransform: "capitalize",
                    width: 80,
                  }}
                  disabled={disableApplyButton}
                >
                  Apply
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className="breadcrumbs_container">
          <Breadcrumbs separator="›" arial-label="Breadcrumb">
            <Link to="/sales-news">Home</Link>
            <span>Pulse</span>
            {selectedPage !== "Pulse" && <span> {selectedPage}</span>}
          </Breadcrumbs>
        </div>
      </div>
      <hr></hr>
      <div className="breadcrumbs">
        <div
          className="row justify-content-between"
          style={{ marginBottom: "-3%", height: "78px" }}
        >
          <div className="col-md-4"></div>
          <div className="col-md-8 text-right">
            <Select
              id="select"
              disableUnderline={true}
              style={{
                border: "solid 1px #ccc",
                paddingLeft: "10px",
                borderRadius: "5px",
              }}
              value={selectedPage}
              onChange={handleOnPageChange}
            >
              {options.map((o) => (
                <MenuItem value={o.key}>{o.value}</MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </div>
    </>
  );

  const options = [
    { key: "Pulse", value: "Pulse" },
    { key: "Leads", value: "Leads" },
    { key: "Rewards", value: "Rewards" },
    { key: "Pipeline", value: "Pipeline" },
  ];

  if (hasParrentAccount(organizationList)) {
    options.push({ key: "Handover", value: "Handover" });
    options.push({ key: "Crosssales", value: "Cross sales" });
  }

  return (
    <div className="main-wrapper">
      {renderHeader(options)}
      {orgOptionsLoaded ? renderPage() : <LargeScreenLoader />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    averageVelocityData: state.graphData.averageVelocityData,
    LeadsByMonthData: state.graphData.LeadsByMonthData,
    revenueByMonthData: state.graphData.revenueByMonthData,
    leads: state.mypipeline.leads,
    leadsCount: state.mypipeline.leadsCount,
    contactList: state.mypipeline.contactList,
    contactCount: state.mypipeline.contactCount,
    revenueData: state.salesCommunity.revenueData,
    errorMessage: state.mypipeline.errorMessage,
    isFetching: state.mypipeline.isFetching,
    unparkLeadFlag: state.mypipeline.unparkLeadFlag,
    lead: state.newLead.lead,
    leadUpdated: state.newLead.leadUpdated,
    errorLoadingMypipelineContacts:
      state.mypipeline.errorLoadingMypipelineContacts,
    errorLoadingMypipelineLeads: state.mypipeline.errorLoadingMypipelineLeads,
    contactsLoading: state.mypipeline.contactsLoading,
    orgSelected: state.mypipeline.mypipelineOrgSelected,
    userSelected: state.mypipeline.mypipelineUserSelected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLeadsByMonth: (requestBody) =>
      dispatch(actions.getLeadsByMonth(requestBody)),
    getRevenue: (requestBody) =>
      dispatch(salesCommunityActions.getRevenue(requestBody)),
    getAvgVelocity: (requestBody) =>
      dispatch(actions.getAvgVelocity(requestBody)),
    getRevenueByMonth: (requestBody) =>
      dispatch(actions.getRevenueByMonth(requestBody)),
    getStatusLogs: (requestBody) =>
      dispatch(leadDetailActions.getStatusLogs(requestBody)),
    selectOrg: (requestBody) => dispatch(selectMypipelineOrg(requestBody)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GraphContainer);
