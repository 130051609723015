import axios from "../../../common/api-config";
import * as types from "./types";

export const getAllPabblyRequiredData = () => {
  return dispatch => {
    axios
      .post("/account/getAllPablyRequiredData", {})
      .then(response => {
          console.log(response.data.data,"response")
        if (response && response.data && response.data.statusCode === 200) {
          dispatch({ type: "SUBSCRIPTION_DATA", data: response.data.data});
        } else {
          dispatch({
            type: "SUBSCRIPTION_FAILED",
            errorMessage: response.data.errorMessage
          });
        }
      })
      .catch(error => {
        dispatch({
          type: "SUBSCRIPTION_FAILED",
          errorMessage: error.errorMessage
        });
      });
  };
};

export const getAccountLicenceCount = (id) => { 
  return dispatch => {
    axios
      .post("/account/getAccountLicenceCount", {"account_id":id})
      .then(response => {
        if (response && response.data && response.data.statusCode === 200) {
          dispatch({ type: "LICENCECOUNT_DATA", data: response.data.data});
        } else {
          dispatch({
            type: "LICENCECOUNT_FAILED",
            errorMessage: response.data.errorMessage
          });
        }
      })
      .catch(error => {
        dispatch({
          type: "Account Licence Failed",
          errorMessage: error.errorMessage
        });
      });
  };
};

export const getOrganizationList = () => {
  return dispatch =>{
    axios 
      .post( "/account/getOrganizationList", {})
      .then( response => {
        if (response.data.statusCode === 200){
        dispatch( {type: "ORGANIZATION_LIST_SUCCESS", data: response.data.data.rows})} else {
          dispatch({ type: "ORGANIZATION_LIST_ERROR",   data: []})
        }
      } )
      .catch( error => {
        dispatch({ type: "ORGANIZATION_LIST_ERROR",   data: []})
      })
  }
};

// export const fetchAllCustomerAddOnSubscription = () => {
//     return dispatch => {
//       axios
//         .post("account/getAllCustomerAddOnSubscription", {})
//         .then(response => {
//           if (response && response.data && response.data.statusCode === 200) {
//             dispatch({ type: "ALL_CUSTOMER_ADDON_SUBSCRIPTION", data: response.data.data});
//           } else {
//             dispatch({
//               type: "ALL_CUSTOMER_ADDON_SUBSCRIPTION_FAILED",
//               errorMessage: response.data.data.errorMessage
//             });
//           }
//         })
//         .catch(error => {
//           dispatch({
//             type: "ALL_CUSTOMER_ADDON_SUBSCRIPTION_FAILED",
//             errorMessage: error.errorMessage
//           });
//         });
//     };
//   };
 
//   export const fetchAllRemainingSubscriptionCount = () => {
//     return dispatch => {
//       axios
//         .post("account/addAllRemainingSubscriptionCount", {})
//         .then(response => {
//             console.log(response,"responseeeeee")
//           if (response && response.data && response.data.statusCode === 200) {
//             dispatch({ type: "REMAINING_SUBSCRIPTION_COUNT", data: response.data.data});
//           } else {
//             dispatch({
//               type: "REMAINING_SUBSCRIPTION_COUNT_FAILED",
//               errorMessage: response.data.data.errorMessage
//             });
//           }
//         })
//         .catch(error => {
//           dispatch({
//             type: "REMAINING_SUBSCRIPTION_COUNT_FAILED",
//             errorMessage: error.errorMessage
//           });
//         });
//     };
//   };
