import axios from "../../common/api-config";

export default {
  async getAllOtherAccounts() {
    const url = `account/getAllOtherAccounts`;
    const result = await axios.post(url, {});
    console.log(result.data.data.data.rows, "getAllOtherAccounts");
    return result.data.data.data.rows;
  },
  async getWhatsNewContent() {
    const url = `other/whatsNew`;
    const result = await axios.post(url, {});
    return result.data.data;
  },
  async getMyOrgs() {
    const url = "users/getAllMyAccounts";

    const response = await axios.post(url, {});

    const result = response.data.data.data.map((a) => ({
      id: a.id,
      name: a.name,
      logo: a.logo,
      default: a.parent_account_id === 0,
    }));

    return result;
  },
};
