import * as types from "./types";
import axios from "../../../common/api-config";

export const getCommunityLeadList = requestBody => {
  return dispatch => {
    axios.get("/leads", {params: requestBody})
      .then(async response => {
        if(response && response.data && response.data.statusCode == 200){
          if(requestBody && requestBody.paginate && requestBody.paginate.page === "0"){
            dispatch(leadListSuccess(response.data.data));
          }else{
            await dispatch(loadMoreLeadListSuccess(response.data.data));
          }
        }else{
          dispatch(leadListFailed(response.data.errorMessage));
        }
      })
      .catch(error => {
        dispatch(leadListFailed(error.errorMessage));
      });
  };
};

const leadListFailed = (message) => {
  return {
    type: types.LEAD_LIST_FAILED_SALES_COMMUNITY,
    errorMessage: message
  };
};

const leadListSuccess = data => {
  return {
    type: types.LEAD_LIST_SUCCESS_SALES_COMMUNITY,
    data: data
  };
};

const loadMoreLeadListSuccess = data => {
  return {
    type: types.LOAD_MORE_LEAD_LIST_SUCCESS_SALES_COMMUNITY,
    data
  }
}

export const getRevenue = requestBody => {
  return dispatch => {
    axios.post("/lead/revenueTotal", requestBody)
      .then(response => {
        if(response && response.data && response.data.statusCode == 200){
          dispatch({type:types.GET_REVENUE_SUCCESS_SALES_COMMUNITY, data: response.data.data});
        }else{
          dispatch({type: types.GET_REVENUE_ERROR_SALES_COMMUNITY, data: response.data.errorMessage});  
        }
        dispatch({type: 'LOADING', data: false});
      })
      .catch(error => {
        dispatch({type: types.GET_REVENUE_ERROR_SALES_COMMUNITY, data: error.errorMessage});
      });
  };
}

export const setCommunityOrganization = (org) => {
  return dispatch => {
    dispatch({ type: types.SET_ORGANIZATION_SALES_COMMUNITY, selectedSalesCommunityOrganization: org });
  }
}

export default {
  getCommunityLeadList, getRevenue
};