export const LEAD_LIST_FAILED = 'LEAD_LIST_FAILED';
export const LEAD_LIST_SUCCESS = 'LEAD_LIST_SUCCESS';

export const CONTACT_LIST_FAILED = 'CONTACT_LIST_FAILED';
export const CONTACT_LIST_SUCCESS = 'CONTACT_LIST_SUCCESS';

export const SET_ORGANIZATION_MY_PIPELINE = 'SET_ORGANIZATION_MY_PIPELINE';
export const SET_USER_MY_PIPELINE = 'SET_USER_MY_PIPELINE';

export const LOAD_CONTACT_LIST = 'LOAD_CONTACT_LIST';

export const RESET_ERROR = 'RESET_ERROR';