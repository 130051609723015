import React from 'react';
import { Link } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import styles from '../data_export.module.scss';

export default function Header () {
  return (
      <>
          <div className="breadcrumbs">
              <div className="row justify-content-between">
                  <div className="col-sm-12 col-md-5">
                      <h3 className={styles.title_Administration}>
                          Data Export
                      </h3>
                      <div className="breadcrumbs_container">
                          <Breadcrumbs separator="›" arial-label="Breadcrumb">
                              <Link to="/sales-news">Home</Link>
                              <Link to="/administration">Administration</Link>
                              <span>Data Export</span>
                          </Breadcrumbs>
                      </div>
                  </div>
              </div>
          </div>
          <hr className="py-0 mb-0" />
      </>
  );
};

