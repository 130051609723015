import React, { Component } from "react";
import { connect } from "react-redux";
import Activity from "./activityForm";
import Task from "./taskForm";
import Note from "./noteForm";
import { withStyles } from "@material-ui/core/styles";
import styles from "./ActivityTaskNote.module.scss";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import * as actionsAddActivity from "../../../pages/AddActivities/redux/actions";

const DialogTitle = withStyles(theme => ({
  root: {
    //width: "1112px",
    width: "100%",
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
    textAlign: "center"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function TabContainer(props) {
  return (
    <Typography component="div" className={props.className}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class ActivityTaskNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    };
  }

  componentDidMount() {
    const requestBody = {
      arrayFilters: [{}],
      selectFilters: ["id", "name"],
      sort: {
        field: "name",
        sortOrder: "ASC"
      },
      paginate: {
        page: "",
        limit: ""
      }
    };
    this.props.getActivityTypes(requestBody);
  }

  handleTabChange = (event, value) => {
    this.setState({
      value
    });
  };

  render() {
    const { value } = this.state;
    return (
      <div className="activity_popup_addNew overflow_x_hidden">
        <TabContainer className={styles.tabsContainerPadding}>
          <DialogTitle
            id="customized-dialog-title"
            onClose={this.props.hideDialog}
          >
            {this.props.item
              ? "Update (" + this.props.lead_id + ")"
              : "Add new (" + this.props.lead_id + ")"}
          </DialogTitle>
          <div>
            <Tabs
              className={styles.tabsPadding}
              value={value}
              onChange={this.handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="off"
            >
              <Tab
                className={
                  value === 0 ? styles.tabsDesign : styles.tabNotselected
                }
                label="Lead sales activity"
                disabled={this.props.item !== null}
              />
              <Tab
                label="Lead task"
                className={
                  value === 1 ? styles.tabsDesign : styles.tabNotselected
                }
                disabled={this.props.item !== null}
              />
              <Tab
                label="Note"
                className={
                  value === 2 ? styles.tabsDesign : styles.tabNotselected
                }
                disabled={this.props.item !== null}
              />
            </Tabs>
          </div>
          {value === 0 ? (
            <Activity
              company_id={this.props.company_id}
              company_name={this.props.company_name}
              person_id={this.props.person_id ? this.props.person_id : 0}
              lead_id={this.props.lead_id}
              person_name={this.props.person_name}
              //companyList={this.props.companyList}
              close={this.props.close}
              activityTypes={this.props.activityTypes}
              item={this.props.item}
            />
          ) : value === 1 ? (
            <Task
              item={this.props.item}
              lead_id={this.props.lead_id}
              close={this.props.close}
            />
          ) : value === 2 ? (
            <Note close={this.props.close} lead_id={this.props.lead_id} />
          ) : null}
        </TabContainer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    activityTypes: state.addActivities.activityTypes,
    companyList: state.asideLeft.companyList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getActivityTypes: requestBody =>
      dispatch(actionsAddActivity.getActivityTypes(requestBody))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityTaskNote);
