import axios from "../../common/api-config";

async function getLicenseTypesFromServer() {
  const url = "/featurePermission/getLicenses";
  const response = await axios.post(url, {});
  return response.data.data;
}

async function getModuleAndActionsForLicenseType(license_type_id) {
  const url = "/featurePermission/getModuleAndFeatures";
  const response = await axios.post(url, { license_type_id });
  console.log(response.data.data, url);
  return response.data.data;
}

const dropdownLabelMapper = (licenseType) => ({
  value: licenseType.id,
  label: licenseType.display_name,
});

async function saveRoleConfiguration(license_type_id, feature_sets=[]) {
  const url = '/featurePermission/createPermission';
  const response = await axios.post(url, { license_type_id, feature_sets });
  return response.data.data;
}

async function getMyActions() {
  const url = '/featurePermission/getActionSet';
  const response = await axios.post(url, { });

  console.log(response.data.data, url);
  return response.data.data;
}

export default {
  async getLicenseTypes() {
    const response = await getLicenseTypesFromServer();
    return response.map(dropdownLabelMapper);
  },
  getModuleAndActionsForLicenseType,
  saveRoleConfiguration,
  getMyActions
};
