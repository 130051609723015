import React from "react";
import Dialog from "@material-ui/core/Dialog";
import NewContact from "../../newContact/NewContact.container";
import LeadContainer from "../../newLead/LeadContainer";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faNewspaper } from "@fortawesome/free-solid-svg-icons";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import Toolbar from "@material-ui/core/Toolbar";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import CloseIcon from "@material-ui/icons/Close";
import ClearIcon from "@material-ui/icons/Clear";
import InputBase from "@material-ui/core/InputBase";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import AccountBox from "@material-ui/icons/AccountBox";
import { NavLink, Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import leadXlogo from "../../../../assets/new_icons/LeadX_logo_without_slogan_white-01.svg";
import rightIcon from "../../../../assets/images/bi/chevron-right.png";
import { UncontrolledPopover } from "reactstrap";
import ProfilePopup from "../../../components/ProfilePopup/profile-popup";
import SalesNewsDialog from "../../../components/SalesNewsDialog/SalesNewsDialog";
import styles from "./aside-left.module.scss";
import { openSnackbar } from "../../../common/Snackbar/snackbar";
import { imageType } from "../../../common/common-types";
import DirectWin from "../../../components/DirectWin/DirectWin";
import asideLeftStyles from "./aside-left.module.scss";
import LeadDetailPopup from "../../LeadDetailPopup/component/lead-detail-popup";
import { connect } from "react-redux";
import * as pipelineActions from "../../MyPipeline/redux/actions";
import {
  getConfluenceHelpSearchContect,
  clearSearchContect,
} from "../redux/actions";
import { getStorageItem, isAdminUser } from "../../../common/common-functions";
import imageCompression from "browser-image-compression";
import { salesFeedRequestBody } from "../../../common/apiParameters";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SearchIcon from "@material-ui/icons/Search";
import _ from "lodash";
import linkIcon from "../../../../assets/images/bi/link.png";
// import Orgs from "./Orgs";
import Api from "../Api";
import MyOrgsPopup from "./MyOrgsPopup";

const drawerWidth = 202;
const theme = createMuiTheme({
  breakpoints: {
    values: {
      md: 992,
    },
  },
});

const cssStyles = (theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    border: "none",
    zIndex: "1000000",
    overflowX: "hidden",
    overflowY: "hidden",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  margin: {
    margin: theme.spacing.unit,
  },
});

class AsideLeft extends React.Component {
  state = {
    activeMenu: "Sales news",
    is_has_expiry: false,
    expiry_date: "",
    orgList: null,
    title: "",
    discription: "",
    licenseTypeArray: [6],
    previewNewsModal: false,
    mobileOpen: false,
    open: false,
    show: false,
    showDialog: false,
    showNewsDialog: false,
    searchTitle: "",
    user: {
      name: "",
    },
    popoverOpenHelp: false,
    dialogOpenHelp: false,
    popoverOpenWhatNew: false,
    dialogOpenWhatNew: false,
    addCompanyFromLead: false,
    addPersonFromLead: false,
    directWin: false,
    currentSalesItem: null,
    filterValue: [1, 2, 3, 4, 5, 6], //all lead status,
    licenseType: "",
    organizationArray: [],
    whatsNewContent: null,
    openMyOrgDailog: false,
  };

  async componentDidMount() {
    this.getCompanies();
    this.getCategorySources();
    this.getOrganizationList();

    let organizationParams = {};
    this.props.getOrganizationListNews(organizationParams);

    this.props.getPoints();

    const countryListRequestBody = {
      arrayFilters: [{}],
      selectFilters: ["id", "name", "iso_code", "dialing_code", "is_city"],
      sort: {
        field: "name",
        sortOrder: "ASC",
      },
      paginate: {
        page: 0,
      },
    };
    this.props.getCountries(countryListRequestBody);
    // const currentUser = JSON.parse(localStorage.getItem("user"));
    const currentUser = JSON.parse(getStorageItem("user"));
    if (currentUser) {
      this.setState({
        licenseType: currentUser.license_type.actual_name,
        organizationArray: [
          {
            id: currentUser.organization_id,
            name: currentUser.organizationName,
          },
        ],
        licenseTypeArray: _.uniq([
          ...this.state.licenseTypeArray,
          _.get(currentUser, "license_type.id"),
        ]),
      });
      this.isParentOrg(currentUser.organization_id);
    }

    const result = await Api.getWhatsNewContent();
    this.setState({
      whatsNewContent: result,
    });
  }

  getCompanies() {
    let params = {
      arrayFilters: [
        {
          is_deleted: 0,
        },
      ],
      // selectFilters: ["id", "company_name"],
      selectFilters: [],
      sort: {
        field: "company_name",
        sortOrder: "ASC",
      },
      paginate: {
        page: "",
        limit: "",
      },
    };
    this.props.getCompanies(params);
  }

  //CRM Integration
  isParentOrg(orgId) {
    //const orgId = this.props.user.organization_id;

    this.props.isParentOrg(orgId);
  }
  //CRM Integration

  getOrganizationList() {
    let organizationParams = {};
    this.props.getOrganizationList(organizationParams);
  }

  getCategorySources() {
    let categoryParams = {
      arrayFilters: [{}],
      selectFilters: ["id", "name"],
      sort: {
        field: "name",
        sortOrder: "ASC",
      },
      // "paginate": {
      //     "page": 0,
      //     "limit": 100
      // }
    };

    this.props.getCategorySources(categoryParams);
  }

  //MOBILE add new dialog for create new Lead/ Contact
  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  //DESKTOP add new contact dialog
  showDialog = async (company_id, companyObj) => {
    await this.setState({
      showDialog: true,
      open: false,
      selectedCompany: company_id,
      selectedCompanyObj: companyObj,
    });

    if (!this.state.show) {
      var headerElement = document.getElementsByClassName(styles.appBar);
      headerElement[0].classList.add(styles.modal_zindex);

      var leftNavElement = document.getElementsByClassName(styles.brand);
      leftNavElement[0].parentNode.classList.add(styles.modal_zindex);
    }
  };

  logout() {
    this.props.userLogout();
  }

  hideDialog = (newlyAddedItem) => {
    this.setState({ showDialog: false });

    //If company gets added from lead form, return added company name to lead form
    if (this.state.addCompanyFromLead && newlyAddedItem) {
      this.setState({
        addCompanyFromLead: false,
        newlyAddedCompany: newlyAddedItem,
      });
      this.getCompanies();
    } else if (this.state.addPersonFromLead && newlyAddedItem) {
      this.setState({
        addPersonFromLead: false,
        newlyAddedPerson: newlyAddedItem,
      });
    } else {
      var headerElement = document.getElementsByClassName(styles.appBar);
      headerElement[0].classList.remove(styles.modal_zindex);

      var leftNavElement = document.getElementsByClassName(styles.brand);
      leftNavElement[0].parentNode.classList.remove(styles.modal_zindex);
    }
  };

  //DESKTOP add new lead dialog
  showModal() {
    this.setState({
      show: true,
      open: false,
    });

    var headerElement = document.getElementsByClassName(styles.appBar);
    headerElement[0].classList.add(styles.modal_zindex);

    var leftNavElement = document.getElementsByClassName(styles.brand);
    leftNavElement[0].parentNode.classList.add(styles.modal_zindex);
  }

  hideModal = () => {
    this.setState({ show: false });
    var headerElement = document.getElementsByClassName(styles.appBar);
    headerElement[0].classList.remove(styles.modal_zindex);

    var leftNavElement = document.getElementsByClassName(styles.brand);
    leftNavElement[0].parentNode.classList.remove(styles.modal_zindex);
  };

  handleDrawerToggle = () => {
    this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
  };

  componentWillReceiveProps(nextProps) {
    // var user = JSON.parse(localStorage.getItem("user"));
    var user = JSON.parse(getStorageItem("user"));
    if (user == null) {
      this.props.history.push("/login");
    }
  }

  //****************************************************//
  //                 SALES NEWS FUNCTIONS               //
  //****************************************************//

  showNewsDialog = () => {
    this.setState({
      showNewsDialog: true,
      open: false,
    });
    var headerElement = document.getElementsByClassName(styles.appBar);
    headerElement[0].classList.add(styles.modal_zindex);

    var leftNavElement = document.getElementsByClassName(styles.brand);
    leftNavElement[0].parentNode.classList.add(styles.modal_zindex);
  };

  onCloseNews = () => {
    this.setState({
      showNewsDialog: false,
      file: null,
      title: "",
      discription: "",
      is_has_expiry: false,
      expiry_date: "",
      organizationArray: [
        {
          id: this.currentUser.organization_id,
          name: this.currentUser.organizationName,
        },
      ],
    });
    var headerElement = document.getElementsByClassName(styles.appBar);
    headerElement[0].classList.remove(styles.modal_zindex);

    var leftNavElement = document.getElementsByClassName(styles.brand);
    leftNavElement[0].parentNode.classList.remove(styles.modal_zindex);
  };

  setNewsTitle = (title) => {
    this.setState({
      title,
    });
  };

  setIs_has_expiry = (is_has_expiry) => {
    this.setState({
      is_has_expiry,
    });

    if (!is_has_expiry) {
      this.setState({
        expiry_date: "",
      });
    }
  };

  setExpiry_date = (expiry_date) => {
    this.setState({
      expiry_date,
    });
  };

  setOrganizationArray = (array) => {
    this.setState({
      organizationArray: array,
    });
  };

  returnOrganizationArrayId = () => {
    const id = [];
    for (var i = 0; i < this.state.organizationArray.length; i++) {
      id.push(this.state.organizationArray[i].id);
    }
    return id;
  };

  setNewDiscription = (discription) => {
    this.setState({
      discription,
    });
  };

  saveToDrafts = (user_id) => {
    const params = {
      title: this.state.title.trim(),
      news_body: this.state.discription.trim(),
      attachment: this.state.file,
      is_active: 0,
      is_has_expiry: this.state.is_has_expiry,
      expiry_date: this.state.expiry_date,
      organizationArray: this.returnOrganizationArrayId(),
      // user_id: user_id
      licenseTypeArray: this.state.licenseTypeArray.filter(
        (license) => license !== "public"
      ),
    };

    this.props.saveToDrafts(params);

    this.setState({
      showNewsDialog: false,
      file: null,
      title: "",
      discription: "",
      is_has_expiry: false,
      expiry_date: "",
      licenseTypeArray: [6],
    });
  };

  resetFormData = () => {
    this.setState({
      file: null,
      title: "",
      discription: "",
      is_has_expiry: false,
      expiry_date: "",
      organizationArray: [
        {
          id: this.currentUser.organization_id,
          name: this.currentUser.organizationName,
        },
      ],
      licenseTypeArray: [6],
    });
  };

  //*************** Image uploading logic
  publishNews = (user_id) => {
    const params = {
      title: this.state.title.trim(),
      news_body: this.state.discription.trim(),
      attachment: this.state.file,
      is_active: 1,
      is_has_expiry: this.state.is_has_expiry,
      expiry_date: this.state.expiry_date,
      organizationArray: this.returnOrganizationArrayId(),
      licenseTypeArray: this.state.licenseTypeArray.filter(
        (license) => license !== "public"
      ),
    };

    this.props.publishSalesNews(params);

    this.setState({
      showNewsDialog: false,
      file: null,
      title: "",
      discription: "",
      is_has_expiry: false,
      expiry_date: "",
      organizationArray: [
        {
          id: this.currentUser.organization_id,
          name: this.currentUser.organizationName,
        },
      ],
      licenseTypeArray: [6],
    });
  };

  previewNews = () => {
    this.setState({
      previewNewsModal: true,
    });
  };

  debouncedHelpSearch = _.debounce(this.props.getHelpSearch, 400);

  onhandleSearchChange = (event) => {
    this.setState({ searchTitle: event.target.value });
    if (
      event.target.value !== null &&
      event.target.value !== this.state.searchTitle
    ) {
      this.debouncedHelpSearch(event.target.value);
    }
  };

  handleClearSearch = () => {
    this.setState({ searchTitle: "" });
    this.props.clearSearchContect(null);
  };

  onChange = (e, valid) => {
    if (valid == true) {
      let file = e.target.files[0];
      e.target.value = "";

      // checking file size if more than 2 mb than compress
      if (file.size / 1024 / 1024 > 2) {
        const options = {
          maxSizeMB: 2,
          maxWidthOrHeight: 1920,
        };
        imageCompression(file, options)
          .then((compressedFile) => {
            this.settingImage(compressedFile);
          })
          .catch((error) => {
            this.setState({
              file: null,
            });
          });
      } else {
        this.settingImage(file);
      }
    } else {
      this.setState({
        file: null,
      });
    }
  };

  settingImage = (file) => {
    this.getBase64(file, (result) => {
      this.setState({
        file: {
          fileBody: result,
          fileName: file.name,
          fileExtension: file.type,
          parentFolder: "sales_news",
        },
      });
    });
  };

  //***************  converting image into base 64
  getBase64(file, cb, sto) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      cb(reader.result);
    };
    reader.onerror = (error) => {};
  }

  toggle(e) {
    if (e) {
      e.stopPropagation();
    }
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  }

  openCompanyPopupFromLead() {
    this.setState({
      addCompanyFromLead: true,
      addPersonFromLead: false,
    });

    this.showDialog();
  }

  openPersonPopup(company_id, company) {
    this.setState({
      addPersonFromLead: true,
      addCompanyFromLead: false,
    });

    this.showDialog(company_id, company);
  }

  handleWinCallBack = (currentSalesItem) => {
    var headerElement = document.getElementsByClassName(asideLeftStyles.appBar);
    headerElement[0].classList.add("modal-zindex");

    var leftNavElement = document.getElementsByClassName(asideLeftStyles.brand);
    leftNavElement[0].parentNode.classList.add("modal-zindex");

    this.setState({
      openLeadDetail: true,
      show: false,
      currentSalesItem,
    });
  };

  handleClose = () => {
    this.setState({ open: false, openLeadDetail: false });
    var headerElement = document.getElementsByClassName(asideLeftStyles.appBar);
    if (headerElement && headerElement[0]) {
      headerElement[0].classList.remove("modal-zindex");
    }

    var leftNavElement = document.getElementsByClassName(asideLeftStyles.brand);
    if (leftNavElement && leftNavElement[0]) {
      leftNavElement[0].parentNode.classList.remove("modal-zindex");
    }
    if (window.location.pathname === "/sales-news") {
      const requestBody = salesFeedRequestBody;
      requestBody.organizationArray = this.props.selectedOrganizationList;
      requestBody.paginate = {
        page: "0",
        limit: "10",
      };

      // const requestBody = {
      //   arrayFilters: [
      //     {
      //       is_deleted: 0
      //     }
      //   ],
      //   selectFilters: ["id", "created_at", "action_type", "updated_at"], "organizationArray": [
      //     3

      //   ],
      //   sort: {
      //     field: "created_at",
      //     sortOrder: "DESC"
      //   },
      //   paginate: {
      //     page: "0",
      //     limit: "10"
      //   }
      // };
      this.props.initializeSalesFeeds(requestBody);
    }
  };

  activeMenuClickHandler = (url) => {
    this.setState({
      activeMenu: url,
      mobileOpen: false,
    });
  };

  handleLicenseTypeArrayChange = (licenseTypeArray) => {
    this.setState({
      licenseTypeArray,
    });
  };

  handleLinkRedirection = (link) => () => {
    window.open(link);
  };

  toggleMyOrgDailog = (newValue) => () => {
    this.setState({
      openMyOrgDailog: newValue,
    });
  };

  renderPopupsForHelp = () => {
    const toggleForHelp = () => {
      this.props.clearSearchContect(null);
      let newPopoverOpenHelp = this.state.popoverOpenHelp ? false : true;
      this.setState({ popoverOpenHelp: newPopoverOpenHelp });
    };

    const handleOpenHelp = () => {
      this.setState({ popoverOpenHelp: false });
      this.setState({ dialogOpenHelp: true });
    };

    const handleWhatNew = () => {
      this.setState({ popoverOpenWhatNew: false });
      this.setState({ dialogOpenWhatNew: true });
    };

    const handledialogCloseHelp = () => {
      this.setState({ dialogOpenHelp: false, searchTitle: "" });
    };

    return (
      <>
        <Popover
          placement="bottom"
          isOpen={this.state.popoverOpenHelp}
          target="Popover2"
          toggle={toggleForHelp.bind(this)}
        >
          <PopoverBody className={styles.popOverBody}>
            <List component="nav" aria-label="secondary mailbox folders">
              <ListItem button>
                <ListItemText primary="Get Help" onClick={handleOpenHelp} />
              </ListItem>
              <ListItem button>
                <ListItemText primary="What is new" onClick={handleWhatNew} />
              </ListItem>
            </List>
          </PopoverBody>
        </Popover>
        <Dialog
          onClose={handledialogCloseHelp}
          className={styles.autocompleteDialog}
          open={this.state.dialogOpenHelp}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            className={styles.autocompleteDialogTitle}
            onClose={handledialogCloseHelp}
            id="form-dialog-title"
          >
            LeadX 360 Help
          </DialogTitle>
          <IconButton
            aria-label="close"
            className={styles.closeButton}
            onClick={handledialogCloseHelp}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent className={styles.autocompleteDialogContent}>
            <DialogContentText>
              <div className="get-help">
                <InputBase
                  fullWidth
                  className={styles.autoComplteInput}
                  placeholder="LeadX registration process"
                  value={this.state.searchTitle}
                  onChange={this.onhandleSearchChange}
                  endAdornment={
                    <InputAdornment position="end">
                      {this.state.searchTitle != "" && (
                        <ClearIcon
                          style={{
                            cursor: "pointer",
                            fontSize: "small",
                          }}
                          onClick={this.handleClearSearch}
                        />
                      )}
                    </InputAdornment>
                  }
                  inputProps={{
                    autocomplete: "on",
                    borderBottom: "none",
                  }}
                />
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogContent>
            {this.props &&
            this.props.helpListTitles &&
            this.props.helpListTitles.length > 0
              ? this.props.helpListTitles.map((link) => {
                  return (
                    <>
                      <List component="nav" aria-label="main mailbox folders">
                        <ListItem
                          button
                          onClick={this.handleLinkRedirection(
                            `${this.props.base}${link._links.webui}`
                          )}
                        >
                          <Link href="#" className={styles.autoComplteLink}>
                            <div className="row">
                              <div className="col-md-11">{link.title}</div>
                              <div className="col-md-1">
                                <img
                                  className={styles.linkIcon}
                                  src={linkIcon}
                                ></img>
                              </div>
                            </div>
                          </Link>
                        </ListItem>
                      </List>
                      <Divider />
                    </>
                  );
                })
              : this.state.searchTitle != "" && (
                  <>
                    <List
                      component="nav"
                      aria-label="main mailbox folders"
                      className={styles.ulList}
                    >
                      <ListItem button>
                        <Link href="#" className={styles.autoComplteLink}>
                          {"No Data Found"}
                        </Link>
                      </ListItem>
                    </List>
                    <Divider />
                  </>
                )}
            <div className={styles.dialogFooter}>
              <p>In case of queries, please mail us at</p>
              <p>support@leadx360.com or customersuccess@leadx360.com</p>
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  };

  renderWhatsNewPopup = () => {
    const handledialogCloseWhatNew = () => {
      this.setState({ dialogOpenWhatNew: false });
    };

    return (
      <Dialog
        onClose={handledialogCloseWhatNew}
        className={styles.autocompleteDialog}
        open={this.state.dialogOpenWhatNew}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          className={styles.autocompleteDialogTitle}
          onClose={handledialogCloseWhatNew}
          id="form-dialog-title"
        >
          {this.state.whatsNewContent.title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          className={styles.closeButton}
          onClick={handledialogCloseWhatNew}
        >
          <CloseIcon />
        </IconButton>
        <hr className={styles.popupHr}></hr>
        <DialogContent className={styles.dialogContent}>
          {this.state.whatsNewContent.releases.map((release, i) => {
            const lastRecord =
              this.state.whatsNewContent.releases.length - 1 === i;
            return (
              <Card className={styles.cardRoot}>
                <CardContent
                  className={
                    lastRecord
                      ? styles.secondCardContainer
                      : styles.firstCardContainer
                  }
                >
                  <Typography
                    className={styles.cardTitle}
                    color="textSecondary"
                    gutterBottom
                  >
                    {release.title}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h2"
                    className={styles.cardSubtitle}
                  >
                    {release.date} | Version {release.version}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="p"
                    className={styles.cardContent}
                  >
                    {release.bullet.title}
                    <ul>
                      {release.bullet.points.map((point) => (
                        <li>{point}</li>
                      ))}
                    </ul>
                    <br />
                  </Typography>
                </CardContent>
              </Card>
            );
          })}
        </DialogContent>
      </Dialog>
    );
  };

  currentUser = JSON.parse(getStorageItem("user"));

  render() {
    let activeMenu = "";
    if (window.location.pathname.includes("sales-community")) {
      activeMenu = "Sales community";
    } else if (window.location.pathname.includes("sales-news")) {
      activeMenu = "Sales news";
    } else if (window.location.pathname.includes("my-pipeline")) {
      activeMenu = "My pipeline";
    } else if (window.location.pathname.includes("activities")) {
      activeMenu = "Activities";
    } else if (window.location.pathname.includes("administration")) {
      activeMenu = "Administration";
    } else if (window.location.pathname.includes("pulse")) {
      activeMenu = "Pulse";
    }

    const { open } = this.state;
    const { classes, theme, user, is_parent_org } = this.props;
    const currentUser = JSON.parse(getStorageItem("user"));
    const drawer = (
      <div className={styles.brand}>
        <div
          className={styles.header_bar + " " + classes.toolbar}
          onClick={() => this.activeMenuClickHandler("Sales news")}
        >
          <Link to="/sales-news">
            <img
              className={styles.brand_logo_aside_left}
              src={leadXlogo}
              alt="brand-logo"
            />
          </Link>
        </div>
        <List className={styles.left_navigation}>
          {[
            {
              img: require("../../../../assets/images/menu-icon/new/icon_leadx_Sales News.svg"),
              menu: "Sales news",
              url: "/sales-news",
              disabled: false,
              hide: false,
            },
            {
              img:
                this.state.licenseType !== "" &&
                this.state.licenseType === "LITE"
                  ? require("../../../../assets/images/menu-icon/new/icon_leadx_Sales Community_disabled-01.svg")
                  : require("../../../../assets/images/menu-icon/new/icon_leadx_Sales Community.svg"),
              menu: "Sales community",
              url: "/sales-community",
              disabled:
                this.state.licenseType !== "" &&
                this.state.licenseType === "LITE"
                  ? true
                  : false,
              hide: this.state.licenseType === "LITE",

              // img: require("../../../../assets/images/menu-icon/new/icon_leadx_Sales Community.svg"),
              // menu: "Sales community",
              // url: "/sales-community",
              // disabled: false,
              // hide: false
            },
            {
              img: require("../../../../assets/images/menu-icon/new/icon_leadx_My Pipeline.svg"),
              menu: "My pipeline",
              url: "/my-pipeline",
              disabled: isAdminUser(),
              hide: false,
            },
            {
              img:
                this.state.licenseType !== "" &&
                (this.state.licenseType === "BASIC" ||
                  this.state.licenseType === "LITE")
                  ? require("../../../../assets/images/menu-icon/new/icon_leadx_Activity_disabled-01.svg")
                  : require("../../../../assets/images/menu-icon/new/icon_leadx_Activity.svg"),
              menu: "Activities",
              url: "/activities",
              disabled:
                this.state.licenseType !== "" &&
                (this.state.licenseType === "BASIC" ||
                  this.state.licenseType === "LITE" ||
                  isAdminUser())
                  ? true
                  : false,
              hide: this.state.licenseType === "LITE",
            },
            {
              img: require("../../../../assets/images/menu-icon/new/icon_leadx_Pulse.svg"),
              menu: "Pulse",
              url: "/pulse",
              disabled: false,
              hide:
                this.state.licenseType !== "" &&
                (this.state.licenseType === "MANAGER" ||
                  this.state.licenseType === "ADMINISTRATOR")
                  ? false
                  : true,
            },
            {
              img: require("../../../../assets/images/menu-icon/new/icon_leadx_Administrator-01.svg"),
              menu: "Administration",
              url: "/administration",
              disabled: false,
              hide:
                this.state.licenseType !== "" &&
                this.state.licenseType === "ADMINISTRATOR"
                  ? false
                  : true,
            },
            // {
            //   img: require("../../../../assets/images/menu-icon/new/icon_leadx_Integration.svg"),
            //   menu: "Integrations",
            //   url: "/integrations",
            //   disabled: false,
            //   hide:
            //     is_parent_org !== null &&
            //     is_parent_org == true &&
            //     this.state.licenseType !== "" &&
            //     this.state.licenseType === "MANAGER"
            //       ? false
            //       : true
            // }
          ].map((item, index) => (
            <div
              className={item.menu === activeMenu ? styles.activeNavBar : ""}
              onClick={() => this.activeMenuClickHandler(item.menu)}
              key={item.menu}
            >
              {item.hide ? (
                ""
              ) : item.disabled ? (
                <NavLink
                  to="#"
                  disabled
                  //onClick={e => e.preventDefault()}
                  title="Access denied! Please contact your sales manager to upgrade your license."
                >
                  <ListItem>
                    <div className={styles.icon_image_container}>
                      <img
                        height="19px"
                        width="22px"
                        src={item.img}
                        alt="menu-icon"
                      />
                    </div>
                    <ListItemText
                      className={`${styles.menu_list} ${styles.disabled_activity}`}
                      primary={item.menu}
                    />
                  </ListItem>
                </NavLink>
              ) : (
                <NavLink to={item.url}>
                  <ListItem>
                    <div className={styles.icon_image_container}>
                      <img
                        height="19px"
                        width="22px"
                        src={item.img}
                        alt="menu-icon"
                      />
                    </div>
                    <ListItemText
                      className={styles.menu_list}
                      primary={item.menu}
                    />
                  </ListItem>
                </NavLink>
              )}
              <Divider />
            </div>
          ))}
        </List>
      </div>
    );

    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.root + " " + styles.header_edge_fix}>
          <CssBaseline />
          <AppBar
            position="fixed"
            className={styles.appBar + " " + classes.appBar}
          >
            <div className={"row"}>
              <div
                className={
                  "col-xl-1 col-lg-1 col-md-3 col-8 tab-logo " +
                  styles.mobile_logo_content
                }
              >
                <Link to="/sales-news">
                  <div
                    className={
                      styles.brand_logo +
                      " " +
                      styles.header_bar +
                      " " +
                      styles.brand_logo_mobile +
                      " " +
                      classes.toolbar
                    }
                  />
                </Link>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={this.handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </div>

              <div
                className={
                  "col-xl-11 col-lg-11 col-md-9 col-4 padding_right " +
                  styles.create_box
                }
              >
                <span className={styles.add_icon} onClick={this.onOpenModal} />
                <Link to="/profile" onClick={this.props.toggle}>
                  <div className="btn_mobile_view_profie" />
                </Link>
                <button
                  className={styles.btn_mobile_desktop_hide}
                  onClick={() => this.logout()}
                >
                  {" "}
                  <i className={styles.logout_icon} />
                </button>
                <Modal open={open} onClose={this.onCloseModal} center>
                  <h2 className={styles.popup_title}>Create new:</h2>
                  <div className={styles.btn_wrapper}>
                    <Button
                      variant="contained"
                      size="medium"
                      color="primary"
                      className={
                        styles.header_btn +
                        " " +
                        styles.creat_btn +
                        " " +
                        styles.creat_new_btn
                      }
                      onClick={() => this.showModal()}
                    >
                      Create new lead
                    </Button>

                    {currentUser && currentUser.has_create_access && (
                      <Button
                        variant="contained"
                        size="medium"
                        className={
                          styles.header_btn +
                          " " +
                          styles.creat_btn +
                          " " +
                          styles.margin_0 +
                          " " +
                          styles.remove_right_margin
                        }
                        onClick={() => this.showDialog()}
                      >
                        Create new contact
                      </Button>
                    )}
                    {user &&
                      user.license_type &&
                      user.license_type.actual_name === "MANAGER" && (
                        <React.Fragment>
                          <Button
                            variant="contained"
                            size="medium"
                            className={
                              styles.header_btn +
                              " " +
                              styles.creat_btn +
                              " " +
                              styles.margin_0 +
                              " " +
                              styles.remove_right_margin
                            }
                            onClick={() => this.showNewsDialog()}
                          >
                            Create sales news
                          </Button>
                          {this.props.organizationListNews !== null && (
                            <SalesNewsDialog
                              licenseTypeArray={this.state.licenseTypeArray}
                              onLicenseTypeArrayChange={
                                this.handleLicenseTypeArrayChange
                              }
                              onCloseNews={this.onCloseNews}
                              title={this.state.title}
                              orgList={this.props.organizationListNews}
                              is_has_expiry={this.state.is_has_expiry}
                              setIs_has_expiry={this.setIs_has_expiry}
                              expiry_date={this.state.expiry_date}
                              setExpiry_date={this.setExpiry_date}
                              organizationArray={this.state.organizationArray}
                              setOrganizationArray={this.setOrganizationArray}
                              currentUser={this.currentUser}
                              discription={this.state.discription}
                              onChange={this.onChange}
                              previewNews={this.previewNews}
                              publishNews={this.publishNews}
                              resetFormData={this.resetFormData}
                              saveToDrafts={this.saveToDrafts}
                              openModal={this.state.showNewsDialog}
                              setNewsTitle={this.setNewsTitle}
                              setNewDiscription={this.setNewDiscription}
                              file={this.state.file && this.state.file}
                              currentUser={user && user}
                              userna={user && user.name}
                            />
                          )}
                        </React.Fragment>
                      )}
                  </div>
                </Modal>
                <div
                  className={
                    "row mar_left " +
                    styles.header_row +
                    " " +
                    styles.create_content
                  }
                >
                  <div
                    className={
                      "col-xl-7 col-lg-7 col-md-6 col-sm-5 " +
                      styles.right_header_border +
                      " text-right " +
                      styles.btn_content
                    }
                  >
                    <div className={styles.btn_wrapper}>
                      {/* <div className={styles.border_left_header}></div> */}
                      {/* <Orgs /> */}
                      <Dialog
                        className="width"
                        aria-labelledby="customized-dialog-title"
                        open={this.state.show ? this.state.show : false}
                        onClose={this.hideModal}
                        maxWidth={"lg"}
                        className={styles.leadFromPopup}
                      >
                        <LeadContainer
                          hideModal={this.hideModal}
                          handleWinCallBack={(lead) =>
                            this.handleWinCallBack(lead)
                          }
                          openCompanyPopup={() => {
                            this.openCompanyPopupFromLead();
                          }}
                          openPersonPopup={(company_id, companyObj) =>
                            this.openPersonPopup(company_id, companyObj)
                          }
                          newlyAddedCompany={this.state.newlyAddedCompany}
                          newlyAddedPerson={this.state.newlyAddedPerson}
                        />
                      </Dialog>

                      <Dialog
                        onClose={this.handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={
                          this.state.openLeadDetail
                            ? this.state.openLeadDetail
                            : false
                        }
                        maxWidth={"lg"}
                      >
                        <LeadDetailPopup
                          currentUser={user}
                          currentSalesItem={this.state.currentSalesItem}
                          onClose={() => this.handleClose()}
                          filterList={this.state.filterValue}
                          getPipelineLeadList={this.props.getPipelineLeadList}
                        />
                      </Dialog>

                      <Dialog
                        className="width"
                        aria-labelledby="customized-dialog-title"
                        open={
                          this.state.directWin ? this.state.directWin : false
                        }
                        onClose={this.hideModal}
                        maxWidth={"lg"}
                        className={styles.leadFromPopup}
                      >
                        {console.log("Direct Win")}
                        <DirectWin />
                      </Dialog>
                      {!isAdminUser() && (
                        <Button
                          variant="contained"
                          size="medium"
                          onClick={() => this.showModal()}
                          color="primary"
                          className={
                            classes.margin +
                            " " +
                            styles.header_btn +
                            " " +
                            styles.creat_btn +
                            " " +
                            styles.creat_new_btn
                          }
                        >
                          <span className="form-inline">
                            <i
                              className={
                                styles.btn_icon + " " + styles.creat_new_icon
                              }
                            ></i>
                            Create new lead
                          </span>
                        </Button>
                      )}
                      <Dialog
                        className="width"
                        aria-labelledby="customized-dialog-title"
                        open={
                          this.state.showDialog ? this.state.showDialog : false
                        }
                        onClose={this.hideDialog}
                        maxWidth={"lg"}
                        className="contactForm"
                      >
                        {this.state.addPersonFromLead ? (
                          <NewContact
                            {...this.props}
                            addCompanyFromLead={this.state.addCompanyFromLead}
                            addPersonFromLead={this.state.addPersonFromLead}
                            selectedCompany={this.state.selectedCompany}
                            selectedCompanyObj={this.state.selectedCompanyObj}
                            value={1}
                            hideDialog={(newlyAddedItem) =>
                              this.hideDialog(newlyAddedItem)
                            }
                          />
                        ) : this.state.addCompanyFromLead ? (
                          <NewContact
                            {...this.props}
                            addCompanyFromLead={this.state.addCompanyFromLead}
                            addPersonFromLead={this.state.addPersonFromLead}
                            selectedCompany={this.state.selectedCompany}
                            value={0}
                            hideDialog={(newlyAddedItem) =>
                              this.hideDialog(newlyAddedItem)
                            }
                          />
                        ) : (
                          <NewContact
                            {...this.props}
                            addCompanyFromLead={this.state.addCompanyFromLead}
                            addPersonFromLead={this.state.addPersonFromLead}
                            selectedCompany={this.state.selectedCompany}
                            hideDialog={(newlyAddedItem) =>
                              this.hideDialog(newlyAddedItem)
                            }
                            selectedCompanyObj={this.state.selectedCompanyObj}
                          />
                        )}
                      </Dialog>

                      {currentUser &&
                        currentUser.has_create_access &&
                        !isAdminUser() && (
                          <Button
                            variant="contained"
                            size="medium"
                            onClick={() => this.showDialog()}
                            color="primary"
                            className={`${classes.margin} ${
                              styles.header_btn
                            } ${styles.creat_contact_btn} ${
                              user.license_type &&
                              user.license_type.actual_name !== "MANAGER"
                                ? styles.remove_right_margin
                                : ""
                            }`}
                          >
                            <span className="form-inline">
                              <i
                                className={
                                  styles.btn_icon +
                                  " " +
                                  styles.creat_contact_icon
                                }
                              />
                              Create new contact
                            </span>
                          </Button>
                        )}
                      {((this.state.licenseType !== "" &&
                        this.state.licenseType === "MANAGER") ||
                        isAdminUser()) && (
                        <React.Fragment>
                          <Button
                            variant="contained"
                            size="medium"
                            onClick={() => this.showNewsDialog()}
                            color="primary"
                            className={
                              classes.margin +
                              " " +
                              styles.header_btn +
                              " " +
                              styles.creat_btn +
                              " " +
                              styles.remove_right_margin
                            }
                          >
                            <span className="form-inline">
                              <i
                                icon={faNewspaper}
                                className={
                                  styles.btn_icon +
                                  " " +
                                  styles.create_news_icon
                                }
                              />
                              Create sales news
                            </span>
                          </Button>
                          {this.props.organizationListNews !== null && (
                            <SalesNewsDialog
                              licenseTypeArray={this.state.licenseTypeArray}
                              onLicenseTypeArrayChange={
                                this.handleLicenseTypeArrayChange
                              }
                              onCloseNews={this.onCloseNews}
                              title={this.state.title}
                              orgList={this.props.organizationListNews}
                              is_has_expiry={this.state.is_has_expiry}
                              setIs_has_expiry={this.setIs_has_expiry}
                              expiry_date={this.state.expiry_date}
                              setExpiry_date={this.setExpiry_date}
                              organizationArray={this.state.organizationArray}
                              setOrganizationArray={this.setOrganizationArray}
                              currentUser={this.currentUser}
                              discription={this.state.discription}
                              onChange={this.onChange}
                              previewNews={this.previewNews}
                              publishNews={this.publishNews}
                              resetFormData={this.resetFormData}
                              saveToDrafts={this.saveToDrafts}
                              openModal={this.state.showNewsDialog}
                              setNewsTitle={this.setNewsTitle}
                              setNewDiscription={this.setNewDiscription}
                              file={this.state.file && this.state.file}
                              currentUser={user && user}
                              userna={user && user.name}
                            />
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                  <div
                    className={
                      "col-md-4 col-sm-5 " +
                      styles.right_header_border +
                      " " +
                      styles.user_content
                    }
                  >
                    <div
                      id="Popover1"
                      className={"col-md-9 " + styles.user_inner_content}
                    >
                      <div className={styles.user_profile_image}>
                        <i className={styles.star_icon} />
                        {user && user.user_avatar ? (
                          imageType.test(user.user_avatar) ? (
                            <img
                              src={
                                user.user_avatar.slice(
                                  0,
                                  user.user_avatar.lastIndexOf("/")
                                ) +
                                "/small_" +
                                user.user_avatar.substring(
                                  user.user_avatar.lastIndexOf("/") + 1
                                )
                              }
                              className={styles.header_icon}
                              style={{ display: "inline-block" }}
                              width="47"
                              height="47"
                            />
                          ) : (
                            <img
                              src={require("../../../../assets/userProfile.jpeg")}
                              className={styles.header_icon}
                              style={{ display: "inline-block" }}
                              width="47"
                              height="47"
                            />
                          )
                        ) : (
                          <img
                            src={require("../../../../assets/userProfile.jpeg")}
                            className={styles.header_icon}
                            style={{ display: "inline-block" }}
                            width="47"
                            height="47"
                          />
                        )}
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          verticalAlign: "top",
                        }}
                      >
                        <div className={styles.user_name}>
                          {user && user.name ? user.name : ""}
                        </div>
                        <div className={styles.points}>
                          {this.props.points} points
                        </div>
                      </div>
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className={styles.profile_down_icon}
                      />
                      <UncontrolledPopover
                        trigger="legacy"
                        placement="bottom"
                        isOpen={this.state.popoverOpen}
                        target="Popover1"
                        toggle={this.toggle.bind(this)}
                        className={styles.profile_popover}
                        //modifier added to prevent browser crash when profile popup is opened.
                        modifiers={{ flip: { behavior: ["bottom"] } }}
                      >
                        <ProfilePopup
                          toggle={this.toggle.bind(this)}
                          user={user}
                          points={this.props.points}
                        />
                      </UncontrolledPopover>
                    </div>
                    <div className={"col-md-2 " + styles.help}>
                      <img
                        src={require("../../../../assets/MyOrgs.svg")}
                        style={{ width: 36 }}
                        alt="My Orgs"
                        onClick={this.toggleMyOrgDailog(true)}
                      />
                      <MyOrgsPopup
                        open={this.state.openMyOrgDailog}
                        onClose={this.toggleMyOrgDailog(false)}
                        history={this.props.history}
                      />
                    </div>

                    <div className={"col-md-2 " + styles.help}>
                      <img
                        id="Popover2"
                        src={require("../../../../assets/Help.png")}
                        style={{ width: 36 }}
                        alt="Help"
                      />
                      {this.renderPopupsForHelp()}
                      {this.state.whatsNewContent && this.renderWhatsNewPopup()}
                    </div>

                    <div
                      className={"col-md-2 " + styles.logout_content}
                      onClick={() => this.logout()}
                      title="Logout"
                    >
                      <i className={styles.logout_icon} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AppBar>
          <nav className={classes.drawer}>
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
              <Drawer
                container={this.props.container}
                variant="temporary"
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    helpListTitles: state.asideLeft && state.asideLeft.helpListTitles,
    base: state.asideLeft && state.asideLeft.base,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPipelineLeadList: (requestBody) =>
      dispatch(pipelineActions.getPipelineLeadList(requestBody)),
    getHelpSearch: (payload) =>
      dispatch(getConfluenceHelpSearchContect(payload)),
    clearSearchContect: (payload) => dispatch(clearSearchContect(payload)),
  };
};

export default withStyles(cssStyles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(AsideLeft)
);
