import axios from "../../../common/api-config";
import { openSnackbar } from "../../../common/Snackbar/snackbar";
import axiosCRM from "../../../common/api-configCRM";

export const userLogout = () => {
  return dispatch => {
    axios
      .post("/login/logout", {})
      .then(response => {
        localStorage.removeItem("user");
        localStorage.removeItem("_access_token_");
        dispatch(userLogoutSuccess());
        openSnackbar("You've been logged out successfully.", "snack-success");
      })
      .catch(error => {
        dispatch(userLogoutFailed(error.errorMessage));
      });
  };
};

const userLogoutFailed = message => {
  return {
    type: "USER_LOGOUT_FAILED",
    errorMessage: message
  };
};

const userLogoutSuccess = () => {
  return {
    type: "USER_LOGOUT_SUCCESS"
  };
};

export const getCompanies = requestBody => {
  return dispatch => {
    axios
      .post("/contact/company/get", requestBody)
      .then(response => {
        dispatch({ type: "COMPANY_LIST_SUCCESS", data: response.data.data });
      })
      .catch(error => {
        dispatch({ type: "COMPANY_LIST_ERROR", data: error.errorMessage });
      });
  };
};

// CRM Integration 
export const isParentOrg = requestBody => {
  return dispatch => {
    //console.log("rexrequestBody", requestBody)
    axiosCRM.get(`/integration/isParentOrg/${requestBody}`)
      .then(response => {
        //console.log("rexresponse", response);
        if (response !== undefined && response.data !== undefined && response.data.status != undefined) {
          if (response.data.status == 0 && response.data.is_parent_org !== undefined) {
            //console.log("isparentlog in action", response.data.is_parent_org);
            let tokenData = response.data.token;
            //console.log("token", tokenData);
            localStorage.setItem('integration_token', "Bearer " + tokenData);
            dispatch({ type: "ISPARENT_ORG_SUCCESS", data: response.data.is_parent_org });
          } else {
            dispatch({ type: "ISPARENT_ORG_ERROR", data: "Unable To Get Parent Organization Information" });
          }
        } else {
          dispatch({ type: "ISPARENT_ORG_ERROR", data: "Unable To Get Parent Organization Information" });
        }
      })
      .catch(error => {
        dispatch({ type: "ISPARENT_ORG_ERROR", data: error.errorMessage });
      });
  };
};
// CRM Integration 

export const getOrganizationList = requestBody => {
  return dispatch =>{
    axios 
      .post( "/account/getOrganizationList",requestBody)
      .then( response => {
        if (response.data.statusCode === 200){
        dispatch( {type: "ORGANIZATION_LIST_SUCCESS", data: response.data.data.rows})} else {
          dispatch({ type: "ORGANIZATION_LIST_ERROR",   data: []})
        }
      } )
      .catch( error => {
        dispatch({ type: "ORGANIZATION_LIST_ERROR",   data: []})
      })
  }
}


export const getOrganizationListNews = requestBody => {
  return dispatch =>{
    axios 
      .post( "/salesNews/getOrganizationList",requestBody)
      .then( response => {
        if (response.data.statusCode === 200){
        dispatch( {type: "ORGANIZATION_LIST_NEWS_SUCCESS", data: response.data.data.rows})} else {
          dispatch({ type: "ORGANIZATION_LIST_NEWS_ERROR",   data: []})
        }
      } )
      .catch( error => {
        dispatch({ type: "ORGANIZATION_LIST_NEWS_ERROR",   data: []})
      })
  }
}


export const getCountries = requestBody => {
  return dispatch => {
    axios
      .post("/master/territory/country", requestBody)
      .then(response => {
        dispatch({ type: "COUNTRY_LIST_SUCCESS", data: response.data.data });
      })
      .catch(error => {
        dispatch({ type: "COUNTRY_LIST_ERROR", data: error.errorMessage });
      });
  };
};

export const getPoints = () => {
  return dispatch => {
    axios
      .post("/bonusPoint/getUserPointsTotal", {})
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: "GET_USER_POINT",
            points:
              response.data.data &&
              response.data.data.pointsData &&
              response.data.data.pointsData.points
                ? parseInt(response.data.data.pointsData.points)
                : 0
          });
        }
      })
      .catch(error => {});
  };
};

export const getConfluenceHelpSearchContect = (payload) => {
  console.log(payload,"payloadpayload");
  return dispatch => {
    axios
      .post("/account/getConfluenceHelpSearchContect", {"title":payload})
      .then(response => { console.log("response", response.data.data.results);
        if (response.data.statusCode === 200) {
          dispatch({
            type: "GET_HELPLINKS",
            payload: response.data.data
          });
        }
      })
      .catch(error => {});
  };
};

export const clearSearchContect = (payload) => {
  return dispatch => {
    axios
      .post("/account/getConfluenceHelpSearchContect", {})
      .then(response => { 
          dispatch({
            type: "CLEAR_SEARCH_CONTENT",
            payload: []
          });
      })
      .catch(error => {});
  };
 
};
export default {
  userLogout,
  getCompanies,
  getCountries,
  getPoints,
  getOrganizationList,
  getOrganizationListNews,
  getConfluenceHelpSearchContect,
  //CRM Integration
  isParentOrg,
  
  //CRM Integration
};
