import axios from "../../common/api-config";

export default {
  getOrganizationDetails() {
    const MOCK_DATA = {
      "profile": {
        "id": 10,
        "account_group_id": 3,
        "parent_account_id": 0,
        "name": "LeadX Test",
        "logo": "https://s3.eu-north-1.amazonaws.com/dev-media.leadx.no/leadX-logo.png",
        "address_line_1": "P.O.Box 6",
        "address_line_2": "N-1324 Lysaker, Norway",
        "dns": "https://test.leadx.no",
        "country_id": 1,
        "state_id": 1,
        "city_id": 1,
        "zipcode": "411111",
        "is_deleted": false,
        "created_by": 1,
        "created_at": "2020-05-09T07:23:30.000Z",
        "updated_at": "2020-05-09T07:23:30.000Z",
        "registered_url": 'Lorem ipsum',
        "client_id": 'LX80880',
        "AccountPreference": {
          "id": 10,
          "timezone": "GMT +05:30",
          "date_format": "yyyy-mm-dd",
          "language": "ENG",
          "self_user_reg_config_enabled": true,
          "self_user_reg_approval_required": false,
          "theme": "blue",
          "currency_id": 3,
          "default_license_id": 5,
          "default_role_id": 7,
          "default_department_id": 21,
          "primary_contact_user_id": 110,
          "cross_silo_feature": true,
          "handover_feature": true,
          "create_contact_for_lite_user": true,
          "revenue_field_for_lite_user": true,
          "enable_publishing_news": true,
          "enable_publishing_news_child": true,
          "currency": {
            "id": 3,
            "short_name": "NOK",
            "long_name": "Norwegian Krone",
            "created_at": "2019-07-19T15:24:12.000Z",
            "updated_at": null
          },
          "user": {
            "contact": "7218577897",
            "email": "ravindra@velement.io",
            "country_code": 163
          }
        },
        "AccountGroup": {
          "name": "LEADX"
        }
      },
      "currencies": {
        "count": 3,
        "rows": [
          {
            "id": 1,
            "short_name": "USD",
            "long_name": "United States Dollar",
            "created_at": "2019-05-22T05:38:07.000Z",
            "updated_at": "2019-05-22T05:38:07.000Z"
          },
          {
            "id": 2,
            "short_name": "EUR",
            "long_name": "Euro",
            "created_at": "2019-07-19T15:24:11.000Z",
            "updated_at": null
          },
          {
            "id": 3,
            "short_name": "NOK",
            "long_name": "Norwegian Krone",
            "created_at": "2019-07-19T15:24:12.000Z",
            "updated_at": null
          }
        ]
      },
      "primary_contact": {
        "contact": "7218577897",
        "email": "ravindra@velement.io",
        "country_code": 163
      }
    };

    return Promise.resolve(MOCK_DATA);
  },
  async getCountries() {
    const url = '/master/territory/country';
    const result = await axios.post(url, {});
    return result.data.data.rows;
  },
  async getMasterCurrency() {
    const url = '/master/getMstCurrency';
    const result = await axios.post(url, {});
    return result.data.data.rows;
  },
  async getBaseCurrencyList() {
    const url = '/master/getBaseCurrencyList';
    const result = await axios.post(url, {});
    return result.data.data.rows;
  },
  async searchCountryStateByCityName(text) {
    if (!text) return [];
    const url = '/master/territory/searchCityByName';
    const result = await axios.post(url, { text });
    const options = result.data.data.data.rows.map(c => ({ value: c.id, label: c.name, country_id: c.country_id, state_id: c.state_id })).filter((_, i) => i < 10);
    console.log(result, "Res");
    console.log(options, 'Data');
    return options;
  },
  async getStates(countryId) {
    const url = '/master/territory/country/state';
    const body = {
      arrayFilters: [
        {
          country_id: countryId ? countryId.toString() : ""
        }
      ],
      selectFilters: ["id", "name"],
      sort: {
        field: "name",
        sortOrder: "ASC"
      },
      paginate: {
        page: 0
      }
    };
    const result = await axios.post(url, body);
    return result.data.data.rows;
  },
  async getLanguageList() {
    const url = '/master/getLanguageList';
    const result = await axios.post(url, {});
    return result.data.data.rows;
  },
  async getDateFormatList() {
    const url = '/master/getDateFormatList';
    const result = await axios.post(url, {});
    return result.data.data.rows;
  },
  async getTimeZoneList() {
    const url = '/master/getTimeZoneList';
    const result = await axios.post(url, {});
    return result.data.data.rows;
  },
  getParentDetail() {
    const url = '/account/getParentOrganizationDetails';

    return axios.post(url, {}).then(response => {
      console.log(response.data.data,"Res");
      const { 
        account_preference: { language_id, timezone_id, date_format_id, currency_id }, 
        client_id, dns, key, account_group 
      } = response.data.data.result.data;
      const result = {
        language_id,
        timezone_id,
        date_format_id,
        client_id,
        registered_url: `${dns}/register/${key}`,
        account_group,
        currency_id,
        other_currency_id: 2
      }
      return result;
    });
  },
  uplaodImage(obj) {
    const url = "/account/updateCompanyAvtaar";
    let requestBody = {
      attachment: {
        fileName: obj.fileName,
        fileExtension: obj.fileExtension,
        fileBody: obj.fileBody,
        parentFolder: obj.parentFolder
      }
    };
    console.log(requestBody, 'Req Body');
    return axios.put(url, requestBody).then(response => {
      return response.data.data.s3UploadFile;
    });
  },
  async createChildOrganization(data) {
    const url = '/account/addChildOrganization';
    const body = {
      "email": data.email,
      "name": data.name,
      "logo": data.logo,
      "address_line_1": data.address_line_1,
      "address_line_2": data.address_line_2,
      "country_id": data.country.value,
      "state_id": data.state.value,
      "city_id": data.city.value,
      "zipcode": data.zipcode,
      "language_id": data.language.value,
      "date_format_id": data.dateFormat && data.dateFormat.value,
      "timezone_id": data.timezone.value,
      "contact": data.contact,
      "area": data.countryCode.value,
      "isNewCityAdded": data.city.__isNew__,
      "cityName": data.city.label,
      "dns": data.dns,
      "currency_id": data.masterCurrency.value,
      "base_currency_id": data.baseCurrency.value
    };
    return axios.post(url, body).then(response => {
      return response.data.data;
    });
  }
}