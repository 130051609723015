import * as types from "./types";

const initialState = {
  addSalesActivity: null,
  updateSalesActivity: null,
  addSalesActivityError: null,
  activityTypes: null,
  errorMessage: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_SALES_ACTIVITIES_TYPE_SUCCESS:
      return {
        ...state,
        activityTypes: action.data.rows,
        errorMessage: ""
      };

    case types.GET_ALL_SALES_ACTIVITIES_TYPE_ERROR:
      return {
        ...state,
        activityTypes: null,
        errorMessage: action.data
      };

    case types.RESET_ADD_ACTIVITY:
      return {
        ...state,
        addSalesActivity: null,
        errorMessage: ""
      };
    case types.ADD_ACTIVITY_SUCCESS:
      return {
        ...state,
        addSalesActivity: action.data,
        errorMessage: ""
      };

    case types.ADD_ACTIVITY_FAILED:
      return {
        ...state,
        addSalesActivity: null,
        errorMessage: action.errorMessage
      };

    case types.RESET_UPDATE_ACTIVITY:
      return {
        ...state,
        updateSalesActivity: null,
        errorMessage: ""
      };

    case types.UPDATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        updateSalesActivity: action.data,
        errorMessage: ""
      };

    case types.UPDATE_ACTIVITY_FAILED:
      return {
        ...state,
        updateSalesActivity: null,
        errorMessage: action.errorMessage
      };

    default:
      return state;
  }
};

export default reducer;
