import React, {useState, useEffect} from "react";
// import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import styles from "./list.module.scss";
import styles1 from "./../aside-left/component/aside-left.module.scss";
import { isParentOrgAdmin } from "../../common/common-functions";
import { connect } from 'react-redux';
import API from './API';
import LargeScreenLoader from '../../common/Loaders/PulseLoader/pulse-loader';

function OptionCard(props) {
  const { imageSource, title, text, knowMoreLik, disabled } = props;
  const style = disabled ? {opacity: 0.5} : {};
  return (
    <Card className={styles.card_height} style={style}>
      <CardContent style={{ padding: 0 }}>
        <div className={"row " + styles.card_padding}>
          <div
            className={"col-md-4 border-right " + styles.image_container}
          >
            <img className={styles.display_image} src={imageSource} />
          </div>
          <div className="col-md-8" style={{ padding: '1.25em', paddingLeft: '2.5em' }}>
            <div className={styles.option_title}>{title}</div>
            <div style={{ marginTop: '1.25em', marginBottom: '1.25em' }}>{text}</div>
            <Link disabled={!knowMoreLik} to={disabled ? null : knowMoreLik}>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                disabled={disabled || !knowMoreLik}
                className={
                  styles1.header_btn +
                  " " +
                  styles1.creat_btn +
                  " " +
                  styles1.creat_new_btn
                }
                style={{ marginLeft: 0, minWidth: '6.25em' }}
              >
                Know More
              </Button>
            </Link>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

const integrationText = `LeadX integrations allows you to integrate with external platforms / applications`;
const orgProfileText = `View and update Organization Profile, user registration configuration`;
const subscriptionText = `Manage your organization and child organization subscriptions`;
const childOrgText = `Manage your child organization information, Child organization users`;
const dataExportText = `Export your contacts, company details, Leads information`;
const userMgmtText = `Manage your users, invite users, manage registration requests`;
const apiRegistrationText = `Register an App to connect to LeadX API Platform to access LeadX REST APIs.`;
const roleConfigText = `Configure Role and Feature Access`;

function AdministrationOptionListing({ user }) {
  const [features, setFeatures] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    fetchFeatures();
  }, []);

  const fetchFeatures = async () => {
    setShowLoader(true);
    const features = await API.getFeatureStatus();
    console.log(features, 'features');
    setFeatures(features);
    setShowLoader(false);
  };

  const renderOptions = () => {
    return (
      <>
        <div className="row">

          <div className={"col-xs-12 col-sm-12 col-lg-6 " + styles.bottomPadding}>
            <OptionCard
              text={integrationText}
              title="Integrations"
              disabled={features["Integrations"]}
              imageSource={require("../../../assets/images/administration/integration.svg")}
              knowMoreLik={isParentOrgAdmin(user) && "/integrations"}
            />
          </div>

          <div className={"col-xs-12 col-sm-12 col-lg-6 " + styles.bottomPadding}>
            <OptionCard
              text={orgProfileText}
              title="Organization Profile"
              disabled={features["Organization Profile"]}
              imageSource={require("../../../assets/images/administration/org profile.svg")}
              knowMoreLik="/administration/organization_profile"
            />
          </div>
        </div>

        <div className="row">

          <div className={"col-xs-12 col-sm-12 col-lg-6 " + styles.bottomPadding}>
            <OptionCard
              text={subscriptionText}
              title="Subscriptions"
              disabled={features["Subscriptions"] || !isParentOrgAdmin()}
              imageSource={require("../../../assets/images/administration/subscription.svg")}
              knowMoreLik={isParentOrgAdmin(user) && '/administration/subscriptions'}
            />
          </div>

          <div className={"col-xs-12 col-sm-12 col-lg-6 " + styles.bottomPadding}>
            <OptionCard
              text={childOrgText}
              title="Child Organizations"
              disabled={features["Child Organizations"]}
              imageSource={require("../../../assets/images/administration/child org.svg")}
              knowMoreLik={isParentOrgAdmin(user) && "/administration/child_organization"}
            />
          </div>
        </div>
        <div className="row" >

          <div className={"col-xs-12 col-sm-12 col-lg-6 " + styles.bottomPadding}>
            <OptionCard
              text={dataExportText}
              title="Data Export"
              disabled={features["Data Export"]}
              imageSource={require("../../../assets/images/administration/data export.svg")}
              knowMoreLik="/administration/data_export"
            />
          </div>

          <div className={"col-xs-12 col-sm-12 col-lg-6 " + styles.bottomPadding}>
            <OptionCard
              text={userMgmtText}
              title="User Management"
              disabled={features["User Management"]}
              imageSource={require("../../../assets/images/administration/user managemnt.svg")}
              knowMoreLik="/administration/user_management"
            />
          </div>
          <div className={"col-xs-12 col-sm-12 col-lg-6 " + styles.bottomPadding}>
            <OptionCard
              text={apiRegistrationText}
              title="API Registration"
              disabled={features["API Registration"]}
              imageSource={require("../../../assets/images/administration/apiregistration.svg")}
              knowMoreLik={isParentOrgAdmin(user) && "/administration/api_registration"}
            />
          </div>
          <div className={"col-xs-12 col-sm-12 col-lg-6 " + styles.bottomPadding}>
            <OptionCard
              text={roleConfigText}
              title="Role Configuration"
              disabled={features["Role Configuration"]}
              imageSource={require("../../../assets/shield.svg")}
              knowMoreLik={"/administration/role_config"}
            />
          </div>
        </div>
      </>
    )
  }

  const renderPage = () => {
    return (
      <div className="main-wrapper">
        <div className="breadcrumbs">
          <div className="row justify-content-between">
            <div className="col-md-4">
              <h3 className={styles.title_Administration}>Administration</h3>
            </div>
          </div>
          <div className="breadcrumbs_container">
            <Breadcrumbs separator="›" arial-label="Breadcrumb">
              <Link to="/sales-news">Home</Link>
              <span>Administration</span>
            </Breadcrumbs>
          </div>
        </div>
        <hr />
        {showLoader ? <LargeScreenLoader/> : renderOptions()}
      </div>
    );
  }

  return renderPage();
}

const mapStateToProps = (state) => ({
  user: state.ProfileReducer.userDetails || state.asideLeft.user,
});

export default connect(mapStateToProps)(AdministrationOptionListing);