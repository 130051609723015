import axios from "../../common/api-config";

const BASE_URL = process.env.REACT_APP_API_BASE_URL.replace('/api/v2', '');

function download(filename, text) {
  var element = document.createElement('a');

  function getFileType(filename) {
    const extention = filename.split(".")[1];
    if (extention === 'pdf') return 'application/pdf';
    return 'application/xlsx';
  }

  element.setAttribute('href', `data:${getFileType(filename)};base64,${encodeURIComponent(text)}`);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

const obj = {
  async getOrganizations() {
    const url = '/account/getOrgOptions';
    const response = await axios.post(url, {});
    return response.data.data.options;
  },
  async downloadLeadPdf(advanceSearchObj) {
    const url = '/lead/dataExport/downloadPDF';
    const {
      account_id,
      from_date,
      to_date,
      search_key
    } = advanceSearchObj;
    const body = {
      account_id,
      from_date,
      to_date,
      search_key
    };
    const response = await axios.post(url, body);
    window.open(`${BASE_URL}${response.data.data.filename}`, 'Download');
    return response.data.data;
  },
  getPDFUrl(type) {
    if (type === 'lead') {
      return '/lead/dataExport/downloadPDF'
    } else if (type === 'company') {
      return '/contact/company/dataExport/downloadPDF';
    } else if (type === 'contact') {
      return '/contact/person/dataExport/downloadPDF';
    }
  },
  getExcelUrl(type) {
    if (type === 'lead') {
      return '/lead/dataExport/downloadExcel'
    } else if (type === 'company') {
      return '/contact/company/dataExport/downloadExcel';
    } else if (type === 'contact') {
      return '/contact/person/dataExport/downloadExcel';
    }
  },
  downloadPDF(type, advanceSearchObj) {
    const url = obj.getPDFUrl(type);
    return obj.downloadFile(url, advanceSearchObj);
  },
  async downloadFile(url, advanceSearchObj) {
    const {
      account_id,
      from_date,
      to_date,
      search_key
    } = advanceSearchObj;
    const body = {
      account_id,
      from_date,
      to_date,
      from_date_string: from_date.format('YYYY-MM-DD'),
      to_date_string: to_date.format('YYYY-MM-DD'),
      search_key
    };
    const response = await axios.post(url, body);
    download(response.data.data.fileName, response.data.data.fileData);
    return response;
  },
  downloadExcel(type, advanceSearchObj) {
    const url = obj.getExcelUrl(type);
    return obj.downloadFile(url, advanceSearchObj);
  },
  async getLeadList(page, limit, sortOrder, field, advanceSearchObj={}) {
    const url = '/lead/dataExport';
    return obj.getList(url, page, limit, sortOrder, field, advanceSearchObj);
  },
  async getCompanyList(page, limit, sortOrder, field, advanceSearchObj={}) {
    const url = '/contact/company/dataExport';
    return obj.getList(url, page, limit, sortOrder, field, advanceSearchObj);
  },
  getContactList(page, limit, sortOrder, field, advanceSearchObj={}) {
    const url = '/contact/person/dataExport';
    return obj.getList(url, page, limit, sortOrder, field, advanceSearchObj);
  },
  async getList(url, page, limit, sortOrder, field, advanceSearchObj) {
    const {
      account_id,
      from_date,
      to_date,
      search_key
    } = advanceSearchObj;
    const body = {
      paginate : {
        page,
        limit
      },
      sort: {
        sortOrder,
        field
      },
      account_id,
      from_date,
      to_date,
      search_key
    };
    const response = await axios.post(url, body);
    return response.data.data;
  }
}

export default obj;