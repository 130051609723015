export default {
  getParams(organizationArray, fromDate, toDate) {
    console.log(organizationArray, fromDate, toDate, "getParams");
    const params = {
      revenueType: "sales_community",
      organizationArray,
      fromDate: fromDate && fromDate.toDate(),
      toDate: toDate && toDate.toDate()
    };
    return params;
  }
}