import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./ConfigureDataObjsMapCRMComponent.module.scss";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Dialog, DialogActions, DialogTitle, Button } from "@material-ui/core";
import FieldsMappingCRMContainer from "../../FieldsMappingCRM/FieldsMappingCRMContainer";
import axios from "../../../common/api-configCRM";
import { openSnackbar } from "../../../common/Snackbar/snackbar";
import SmallLoader from "../../../common/Loaders/CircularLoader/circular-loader";
import OopsError from "../../../components/ErrorMessages/OopsError/OopsError";
import WizardError from "../../../components/ErrorMessages/WizardError/WizardError";
import NoRecordsFound from "../../../components/ErrorMessages/NoRecordsFound/NoRecordsFound";
import { withRouter } from "react-router-dom";
import ReactDOM from 'react-dom';

const stylesMaterialUI = theme => ({
  button: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    marginLeft: 0,
    marginRight: 0
  }
});

class ConfigureDataObjsMapCRMComponent extends Component {
  state = {
    CRMClicked: false,
    whichCRM: "",
    goBack: false,
    viewMapping: false,
    viewStatusMapping: false,
    /* idcrm_master: 1,
    crm_name: "SalesForce",
    idcrm_integration: "", */
    loadingObjectMapping: true,
    errorLoadingObjectMapping: false,
    selectedObjectMapping: [],
    loadingEntitiesDetails: true,
    errorLoadingEntitiesDetails: false,
    objectMapping: [],
    origObjectMapping: [],
    statusMapping: [],
    errorLoadingStatusMappingDetails: false,
    loadingStatusMappingDetails: true,
    errorInWizard: false,
    groupbased_objectMapping: [],
    isCRM_leadx_sync: true,
    isLeadx_CRM_sync: true,
    leadMappingChangedDialog: false,
    leadMappingChanged: false,
    existingLeadMappingId: "",
    originalExistingLeadMappingId: "",
    changedLeadMappingId: ""
  };

  componentDidMount() {
    console.log("propRRRRs", this.props.idcrm_master);
    if (this.props.idcrm_master !== undefined &&
      this.props.idcrm_master !== null &&
      this.props.idcrm_master !== "" &&
      this.props.idcrm_integration !== undefined &&
      this.props.idcrm_integration !== null &&
      this.props.idcrm_integration !== "") {
      /* if (this.state.idcrm_integration !== undefined &&
        this.state.idcrm_integration !== "") { */
      axios.get(`/entities/get/${this.props.idcrm_integration}`)
        .then(response => {
          // console.log("response entities", response);
          if (response !== undefined &&
            response.data !== undefined &&
            response.data.status !== undefined &&
            response.data.status === 0) {
            if (response.data.data !== undefined) {
              if (response.data.data.length > 0) {
                this.setState({
                  origObjectMapping: response.data.data
                }, () => {
                  //let s = JSON.stringify(this.state.objectMapping);
                  // console.log("original response", response.data.data);
                  //create different array
                  let group_objMappingArray = [];
                  let group_nameArray = [];
                  if (this.state.origObjectMapping !== undefined &&
                    this.state.origObjectMapping.length > 0) {
                    this.state.origObjectMapping.forEach((item, index) => {
                      let i = group_nameArray.findIndex(x => x.group_name == item.group_name);
                      if (i <= -1) {
                        group_nameArray.push({ id: index, group_name: item.group_name });
                      }
                    });
                    // console.log("group_name_array", group_nameArray);
                    if (group_nameArray !== undefined &&
                      group_nameArray.length > 0) {
                      group_nameArray.forEach((groupItem) => {
                        //console.log("groupitem", groupItem);
                        let objMapping = [];
                        let selected_Id = "";
                        let copyOrigObjectMapping = [...this.state.origObjectMapping];
                        objMapping = copyOrigObjectMapping.filter((item) => {
                          //console.log("i", item);
                          return item.group_name === groupItem.group_name
                        });
                        //console.log("group name item", objMapping);
                        if (objMapping !== undefined) {
                          let copyObjMapping = [...objMapping];
                          const selectedObj = copyObjMapping.filter((objMap) => {
                            //console.log("gggg", objMap);
                            return objMap.leadxtocrm !== 0 || objMap.crmtoleadx !== 0
                          });
                          //console.log("selectedObj", selectedObj);
                          if (selectedObj !== undefined &&
                            selectedObj.length > 0) {
                            selected_Id = selectedObj[0].idcrm_entity_master;
                          } else {
                            selected_Id = objMapping[0].idcrm_entity_master;
                          }
                          //console.log("selected id", selected_Id);
                          group_objMappingArray.push({
                            selected_idcrm_entity_master: selected_Id,
                            group_name: groupItem.group_name,
                            objectMapping: objMapping
                          });
                          //console.log("group array", group_objMappingArray);
                        }
                      })
                    }
                  }
                  //console.log("grp array final", group_objMappingArray);
                  if (group_objMappingArray !== undefined &&
                    group_objMappingArray.length > 0) {
                    this.setState({
                      groupbased_objectMapping: group_objMappingArray,
                      errorLoadingEntitiesDetails: false,
                      loadingEntitiesDetails: false
                    }, () => {
                      //console.log("FinalFinal", this.state.groupbased_objectMapping);
                      // loop throught the array to get leadmappingid 
                      // i.e selected id of the object with multiple objectmappings
                      for (let index1 = 0; index1 < this.state.groupbased_objectMapping.length; index1++) {
                        const element = this.state.groupbased_objectMapping[index1];
                        //console.log("elementRRRR", element);
                        if (element !== undefined && element !== null) {
                          if (element.objectMapping !== undefined &&
                            element.objectMapping.length > 1) {
                            this.setState({ existingLeadMappingId: element.selected_idcrm_entity_master });
                            this.state.originalExistingLeadMappingId = element.selected_idcrm_entity_master;
                            //console.log("existingLeadMappingId", this.state.existingLeadMappingId);
                          }

                        }
                      }
                    });
                  }

                });
              }
              /* this.setState({
                errorLoadingEntitiesDetails: false,
                loadingEntitiesDetails: false
              }); */
              //console.log("FinalFinal", this.state.groupbased_objectMapping);
              // console.log("entitiesDetails ", this.state.objectMapping);
              // console.log("entitiesDetails ", this.state.loadingEntitiesDetails);
            } else {
              openSnackbar("Entities Data Not Found.", "snack-error");
              this.setState({
                loadingEntitiesDetails: false,
                errorLoadingEntitiesDetails: true,
              });
            }
          } else {
            openSnackbar("Exception Getting Entities Information", "snack-error");
            this.setState({
              loadingEntitiesDetails: false,
              errorLoadingEntitiesDetails: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
      /* } else {
        openSnackbar("Something Went Wrong", "snack-error");
      } */
      /* }); */
      console.log("response status mapping", this.props.idcrm_master,this.props.idcrm_integration);
      axios.get(`/statusmapping/get/${this.props.idcrm_master}/${this.props.idcrm_integration}`)
        .then(response => {
          console.log("response status mapping", response);
          if (response !== undefined &&
            response.data !== undefined &&
            response.data.status !== undefined &&
            response.data.status === 0) {
            if (response.data.data !== undefined) {
              if (response.data.data.length > 0) {
                this.setState({
                  statusMapping: response.data.data
                }, () => {
                  this.props.setStatusMapping(this.state.statusMapping);
                });
              } else {
                openSnackbar("Status Mapping Data Not Found.", "snack-error");
                this.setState({
                  errorLoadingStatusMappingDetails: false,
                  loadingStatusMappingDetails: true,
                });
              }

              this.setState({
                errorLoadingStatusMappingDetails: false,
                loadingStatusMappingDetails: false
              });
              // console.log("statusMapDetails ", this.state.statusMapping);
              // console.log("statusMapDetails ", this.state.loadingStatusMappingDetails);
            } else {
              openSnackbar("Status Mapping Data Not Found.", "snack-error");
              this.setState({
                errorLoadingStatusMappingDetails: false,
                loadingStatusMappingDetails: true,
              });
            }
          } else {
            openSnackbar("Unable to Get Status Mapping Data.", "snack-error");
            this.setState({
              errorLoadingStatusMappingDetails: false,
              loadingStatusMappingDetails: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      /* openSnackbar("Error occured. Try Again", "snack-error"); */
      this.setState({
        errorInWizard: true
      });
    }
  }

  handleSaveNProceedClicked = (e) => {
    // console.log("configuration for CRM ", e);
    this.setState({ CRMClicked: true, whichCRM: e });
    if (this.checkValidity() === true) {
      let params = null;
      /* if (this.state.integrationsDetails !== undefined) {
        if (this.state.integrationsDetails.idcrm_integration !== "") { */
      /* params format -
       data ={
        "integration_id" : "1",
        "data_rows" : [
        {
        "fk_integration_id":"1", 
        "fk_entity":"1", 
        "leadxtocrm":"1", 
        "crmtoleadx":"0"
        },
        {
        "fk_integration_id":"1", 
        "fk_entity":"2", 
        "leadxtocrm":"1", 
        "crmtoleadx":"1"
        }]
        } */
      let data_rows = [];
      if (this.state.objectMapping !== undefined && this.state.objectMapping.length > 0) {
        this.state.objectMapping.map(objMap => {
          // console.log("objMap ", objMap);
          let obj = {
            fk_integration_id: this.props.idcrm_integration,
            fk_entity: objMap.idcrm_entity_master,
            leadxtocrm: objMap.leadxtocrm,
            crmtoleadx: objMap.crmtoleadx
          }
          data_rows.push(obj);
        });
         console.log("data_rows", data_rows);
      };
      params = {
        integration_id: this.props.idcrm_integration,
        data_rows: data_rows,
        leadMappingChanged: this.state.leadMappingChanged == true ? 1 : 0
      }
      // console.log("params", params);
      axios
        .post('/integration_direction/add', { data: JSON.stringify(params) })
        .then(response => {
          // console.log("response in savenproceed", response);
          if (response !== undefined && response.data.status !== undefined &&
            response.data.status === 0) {
            openSnackbar("Saved!", "snack-success");
            // check if ok to proceed even if there is error saving data
            /*  this.props.history.push({
               pathname: '/integrations/assignsalesteamCRM',
               state: { */
            this.props.history.push({
              pathname: '/integrations/statusMappingCRM',
              state: {
                /*  idcrm_master: this.state.idcrm_master,
                 idcrm_integration: this.state.idcrm_integration,
                 isActive: this.state.isActive,
                 configured: this.state.configured
                 */ //integrationDetails: this.state.integrationDetails
              }
            });
          } else {
            openSnackbar("Something Went Wrong!", "snack-error");
          }
        })
        .catch((error) => {
          console.log(error);
          openSnackbar("Exception Getting Integration Direction Information", "snack-error");
        })

      /*  // check if ok to proceed even if there is error saving data
       this.props.history.push({
         pathname: '/integrations/assignsalesteamCRM',
         state: {
           idcrm_master: this.state.idcrm_master,
           idcrm_integration: this.state.idcrm_integration
           //integrationDetails: this.state.integrationDetails
         }
       }); */
    } else {
      //openSnackbar("Select Mapping For Lead To Continue", "snack-error");
      openSnackbar("Corresponding Mappings for Lead, Company & Contacts Not Selected or Matching", "snack-error");
    }
  }

  handleGoBackClicked = () => {
    // console.log("go back clicked");
    this.setState({ goBack: true });
    this.props.history.goBack();
  }

  handleViewMappingClicked = (e) => {
    // console.log("Mapping for ", e);
    const objectMapsForEntity = [...this.state.origObjectMapping];
    const objectMap = objectMapsForEntity.filter(objMap => {
      if (objMap.idcrm_entity_master === e) {
        return true;
      }
    });
    // console.log("obj maps for selected e", objectMap);
    if (objectMap !== undefined && objectMap.length > 0) {
      this.setState({
        selectedObjectMapping: objectMap[0],
        viewMapping: true
      });
      /* if (objectMap[0].crmtoleadx === 1 || objectMap[0].leadxtocrm === 1) {
        this.setState({
          selectedObjectMapping: objectMap[0],
          viewMapping: true
        });
      } else {
        this.setState({
          selectedObjectMapping: [],
          viewMapping: false
        });
      } */
    }
  }

  handleCloseViewMapping = () => {
    // console.log("close dialog", value);

    this.setState({
      viewMapping: false,
      // updateActivity: null,
      // updateTask: null
    });
    // this.removeClasses();
  };

  handleMappingCheckboxClick = (objMap, objMapCheckbox, whichCheckbox, selectedIdCRMMaster, i) => {
    //const objectMapArray = objectMapArr;
    const selectedId = selectedIdCRMMaster;
    const ref = i;
    //console.log("selectedId handleMappingCheckbox", selectedId);
    //console.log("objMapCheckbox", objMapCheckbox);
    //console.log("whichCheckbox", whichCheckbox);
    let checkedValue = objMapCheckbox;
    if (objMapCheckbox === 0) {
      checkedValue = 1;
    } else if (objMapCheckbox === 1) {
      checkedValue = 0;
    }
    //console.log("checked checked", objMapCheckbox);
    //console.log(objMap);
    //console.log("selectedId handleMappingCheckbox", selectedId);
    for (let index = 0; index < this.state.groupbased_objectMapping.length; index++) {
      let objArray = this.state.groupbased_objectMapping[index];
      //console.log("objArray", objArray.selected_idcrm_entity_master);
      //console.log("objArray1", selectedId);
      if (objArray.selected_idcrm_entity_master == selectedId) {
        for (let index1 = 0; index1 < objArray.objectMapping.length; index1++) {
          let obj = objArray.objectMapping[index1];
          //console.log("obj", obj);
          //console.log("obj", objMap.idcrm_entity_master);
          if (obj.idcrm_entity_master === objMap.idcrm_entity_master &&
            obj.idcrm_entity_master == selectedId) {
            if (whichCheckbox == "leadxtocrm") {
              //console.log("leadxtocrm");
              obj.leadxtocrm = checkedValue;
            } else {
              //console.log("crmtoleadx");
              obj.crmtoleadx = checkedValue;

            }

            //console.log("changed objmap", obj);
            objArray.objectMapping[index1] = obj;
            //console.log("changed objArray", objArray);
            let temp_groupbased_objectMapping = [...this.state.groupbased_objectMapping];
            let tempObjArray = temp_groupbased_objectMapping[index];
            //console.log("tempObjArray", tempObjArray);
            tempObjArray = objArray;
            //console.log("changed tempObjArray", tempObjArray);
            temp_groupbased_objectMapping[index] = tempObjArray;
            //console.log("changed temp_groupbased_onjctMapping", temp_groupbased_objectMapping);
            this.setState({ groupbased_objectMapping: temp_groupbased_objectMapping }, () => {
              //console.log("changed groupbased", this.state.groupbased_objectMapping);
            });
          } else {
            if (objMap.idcrm_entity_master !== selectedId) {
              openSnackbar("Option Not Selected", "snack-error");
              obj.crmtoleadx = 0;
              obj.leadxtocrm = 0;
              // console.log("ref", ref);
              // console.log("refs", this.refs);
              // console.log("refref", this.refs[ref]);
              ReactDOM.findDOMNode(this.refs[ref]).checked = false; // !this.refs[ref].checked;
            }
          }
        }
      }
    }
    // console.log("handlemapping checkbox", this.state.groupbased_objectMapping);
  }

  handleMappingCheckboxClick1 = (objMap, objMapCheckbox, whichCheckbox, selectedIdCRMMaster, i, objMappingArr) => {
    //const objectMapArray = objectMapArr;
    const selectedId = selectedIdCRMMaster;
    const ref = i;
    //console.log("selectedId handleMappingCheckbox", selectedId);
    //console.log("objMapCheckbox", objMapCheckbox);
    //console.log("whichCheckbox", whichCheckbox);
    //console.log("objMappingArr", objMappingArr);
    let checkedValue = objMapCheckbox;
    if (objMapCheckbox === 0) {
      checkedValue = 1;
    } else if (objMapCheckbox === 1) {
      checkedValue = 0;
    }
    //console.log("checked checked", objMapCheckbox);
    //console.log(objMap);
    //console.log("selectedId handleMappingCheckbox", selectedId);
    for (let index = 0; index < this.state.groupbased_objectMapping.length; index++) {
      const objArray = this.state.groupbased_objectMapping[index];
      //console.log("objArray", objArray.selected_idcrm_entity_master);
      //console.log("objArray1", selectedId);
      if (objArray.selected_idcrm_entity_master == selectedId) {
        for (let index1 = 0; index1 < objArray.objectMapping.length; index1++) {
          const obj = objArray.objectMapping[index1];
          //console.log("obj", obj);
          //console.log("obj", objMap.idcrm_entity_master);
          if (obj.idcrm_entity_master === objMap.idcrm_entity_master &&
            obj.idcrm_entity_master == selectedId) {
            if (whichCheckbox === "leadxtocrm") {
              //console.log("leadxtocrm1");
              obj.leadxtocrm = checkedValue;
              /*  if (obj.leadx_entity.toLowerCase() == "lead" ||
                 obj.crm_entity.toLowerCase() == "lead") {
           
               } else {
 
               } */
            } else {
              //console.log("crmtoleadx1");
              obj.crmtoleadx = checkedValue;
              // if checkedValue is true then change Company and Contact value 
              // for leadxtocrm true
            }

            //console.log("changed objmap", obj);
            //console.log("changed objmap", obj);
            objArray.objectMapping[index1] = obj;
            //console.log("changed objArray", objArray);
            let temp_groupbased_objectMapping = [...this.state.groupbased_objectMapping];
            let tempObjArray = temp_groupbased_objectMapping[index];
            //console.log("tempObjArray", tempObjArray);
            tempObjArray = objArray;
            //console.log("changed tempObjArray", tempObjArray);
            temp_groupbased_objectMapping[index] = tempObjArray;
            //console.log("changed temp_groupbased_onjctMapping", temp_groupbased_objectMapping);
            this.setState({ groupbased_objectMapping: temp_groupbased_objectMapping }, () => {
              //console.log("changed groupbased", this.state.groupbased_objectMapping);
            });
          } else {
            if (objMap.idcrm_entity_master !== selectedId) {
              openSnackbar("Option Not Selected", "snack-error");
              obj.crmtoleadx = 0;
              obj.leadxtocrm = 0;
              // console.log("ref", ref);
              // console.log("refs", this.refs);
              // console.log("refref", this.refs[ref]);
              ReactDOM.findDOMNode(this.refs[ref]).checked = false; // !this.refs[ref].checked;
            }
          }
        }
      }
    }
    // console.log("handlemapping checkbox", this.state.groupbased_objectMapping);
  }

  handleSaveClicked = () => {
    if (this.checkValidity() === true) {
      //check if the lead-lead lead-opportunity mappings are changed
      let params = null;
      /*  if (this.state.integrationsDetails !== undefined) {
         if (this.state.integrationsDetails.idcrm_integration !== "") { */
      let data_rows = [];
      // console.log("object mapping in save", this.state.objectMapping);
      if (this.state.objectMapping !== undefined && this.state.objectMapping.length > 0) {
        for (let index = 0; index < this.state.objectMapping.length; index++) {
          const objMap = this.state.objectMapping[index];
          let obj = {
            fk_integration_id: this.props.idcrm_integration,
            fk_entity: objMap.idcrm_entity_master,
            leadxtocrm: objMap.leadxtocrm,
            crmtoleadx: objMap.crmtoleadx
          }
          
          data_rows.push(obj);
        }
        // console.log("data_rows", data_rows);
      };
      params = {
        integration_id: this.props.idcrm_integration,
        data_rows: data_rows,
        leadMappingChanged: this.state.leadMappingChanged == true ? 1 : 0
      }
      //console.log("params", params);
      axios
        .post('/integration_direction/add', { data: JSON.stringify(params) })
        .then(response => {
          // console.log("response in savenproceed", response);
          if (response !== undefined && response.data.status !== undefined &&
            response.data.status === 0) {
            openSnackbar("Saved!", "snack-success");
          } else {
            openSnackbar("Something Went Wrong!", "snack-error");
          }
        })
        .catch((error) => {
          console.log(error);
          openSnackbar("Exception Updating Integration Direction Information", "snack-error");
        })
    } else {
      openSnackbar("Corresponding Mappings for Lead, Company & Contacts Not Selected or Matching", "snack-error");
    }
    /* }
    } else {
     console.log("Something went wrong");
     openSnackbar("Something Went Wrong", "snack-error");
   } */
  }

  checkValidity() {
    let leadMapValidityFailed = false;
    let selected_leadxtocrm = 0;
    let selected_crmtoleadx = 0;
    // company contacts validation
    let companyLeadMapError = false;
    let companyCRMMapError = false;
    let contactsLeadMapError = false;
    let contactsCRMMapError = false;
    /* let index_companyObj = -1;
    let index_contactsObj = -1; */
    // console.log("maps", this.state.groupbased_objectMapping);
    if (this.state.groupbased_objectMapping !== undefined &&
      this.state.groupbased_objectMapping.length > 0) {
      // console.log("in1");
      //generate final array
      let finalObjectMappingArray = [];
      for (let index1 = 0; index1 < this.state.groupbased_objectMapping.length; index1++) {
        const element = this.state.groupbased_objectMapping[index1];
        // console.log("objMap", element.objectMapping);
        for (let index = 0; index < element.objectMapping.length; index++) {
          const elementObj = element.objectMapping[index];
          //console.log("selected", element.selected_idcrm_entity_master);
          //console.log("selected1", elementObj.idcrm_entity_master);
          if (element.selected_idcrm_entity_master === elementObj.idcrm_entity_master &&
            elementObj.leadx_entity.toLowerCase() == "lead") {
            //console.log("inside");
            //console.log("elementObj", elementObj);
            finalObjectMappingArray.push(elementObj);
            if (elementObj.leadxtocrm == 0 && elementObj.crmtoleadx == 0) {
              // selected lead has no mapping selected
              leadMapValidityFailed = true;
            }

            // save which of the lead - lead , lead - opportunity is selected 
            // this flag needs to be passed to next status mapping step
            if (elementObj.leadx_entity !== undefined &&
              elementObj.crm_entity !== undefined &&
              elementObj.leadx_entity.toLowerCase() == "lead" &&
              elementObj.crm_entity.toLowerCase() == "lead") {
              //console.log("INSIDE SETTING FLAG TO true");
              this.props.setLeadLeadMappingSelected(true);
              /* if (elementObj.idcrm_entity_master !== this.state.existingLeadMappingId) {
                console.log("XXXLead Mapping changed to lead lead");
                this.setState({ leadMappingChanged: true });
              } */
            } else {
              //console.log("INSIDE SETTING FLAG TO false");
              this.props.setLeadLeadMappingSelected(false);
              /* if (elementObj.idcrm_entity_master !== this.state.existingLeadMappingId) {
                console.log("XXXLead Mapping changed to lead opp");
                this.setState({ leadMappingChanged: true });
              } */
            }

            // get the mappings selected for lead
            // this is required for checking corresponding company and contacts mapping validity
            if (elementObj.leadxtocrm == 1) {
              selected_leadxtocrm = 1;
            } else {
              selected_leadxtocrm = 0;
            }
            if (elementObj.crmtoleadx == 1) {
              selected_crmtoleadx = 1;
            } else {
              selected_crmtoleadx = 0;
            }

          } else {
            // console.log("element in else", elementObj);
            if (element.selected_idcrm_entity_master !== elementObj.idcrm_entity_master &&
              elementObj.leadx_entity.toLowerCase() == "lead") {
              elementObj.leadxtocrm = 0;
              elementObj.crmtoleadx = 0;
            }
            // console.log("element in else", elementObj);
            finalObjectMappingArray.push(elementObj);
            // console.log("final arrayrrrrr", finalObjectMappingArray);
          }
        }
      }
      //console.log("final final", finalObjectMappingArray);
      const index_companyObj = finalObjectMappingArray.findIndex(ele => ele.leadx_entity.toLowerCase() == "company");
      const index_contactsObj = finalObjectMappingArray.findIndex(ele => ele.leadx_entity.toLowerCase() == "contacts");
      this.state.objectMapping = finalObjectMappingArray;
      // console.log("final final", this.state.objectMapping);
      /*  this.setState({ objectMapping: finalObjectMappingArray }, () => {console.log("objectmapping final", this.state.objectMapping)}); */

      // check whether lead-lead or lead-opportunity has atleast 1 mapping selected
      if (leadMapValidityFailed == true) {
        openSnackbar("Atleast Select Single Mapping For Lead", "snack-error");
        return false;
      }

      //console.log("ico", index_companyObj);
      //console.log("icon", index_contactsObj);
      if (index_companyObj !== -1 && finalObjectMappingArray !== undefined &&
        finalObjectMappingArray.length > 0) {
        const companyObj = finalObjectMappingArray[index_companyObj];
        //console.log("ico", companyObj);
        if (companyObj !== undefined && companyObj !== null) {
          if (selected_leadxtocrm == 1 && companyObj.leadxtocrm == 0) {
            // error
            // user needs to select company and contacts corresponding mapping if 
            // the mapping is selected for lead
            /*  const errorString = "Select Same Mapping for " + companyObj.leadx_entity;
             openSnackbar(errorString, "snack-error"); */
            companyLeadMapError = true;
          }
          if (selected_crmtoleadx == 1 && companyObj.crmtoleadx == 0) {
            // error
            // user needs to select company and contacts corresponding mapping if 
            // the mapping is selected for lead
            /* const errorString = "Select Same Mapping for " + companyObj.leadx_entity;
            openSnackbar(errorString, "snack-error"); */
            companyCRMMapError = true;
          }
        }
      } else {
        openSnackbar("Something went Wrong", "snack-error");
        return false;
      }
      if (index_contactsObj !== -1 && finalObjectMappingArray !== undefined &&
        finalObjectMappingArray.length > 0) {
        const contactsObj = finalObjectMappingArray[index_contactsObj];
        //console.log("icon", contactsObj);
        if (contactsObj !== undefined && contactsObj !== null) {
          if (selected_leadxtocrm == 1 && contactsObj.leadxtocrm == 0) {
            // error
            // user needs to select company and contacts corresponding mapping if 
            // the mapping is selected for lead
            /* const errorString = "Select Same Mapping for " + contactsObj.leadx_entity;
            openSnackbar(errorString, "snack-error"); */
            contactsLeadMapError = true;
          }
          if (selected_crmtoleadx == 1 && contactsObj.crmtoleadx == 0) {
            // error
            // user needs to select company and contacts corresponding mapping if 
            // the mapping is selected for lead
            /* const errorString = "Select Same Mapping for " + contactsObj.leadx_entity;
            openSnackbar(errorString, "snack-error"); */
            contactsCRMMapError = true;
          }
        }
      } else {
        openSnackbar("Something went Wrong", "snack-error");
        return false;
      }
      //console.log("err", companyCRMMapError);
      //console.log("err", companyLeadMapError);
      //console.log("err", contactsLeadMapError);
      //console.log("err", contactsCRMMapError);
      if (companyCRMMapError == true || companyLeadMapError == true ||
        contactsLeadMapError == true || contactsCRMMapError == true) {
        const errorString = "Select Same Mapping for Company and Contacts Objects";
        openSnackbar(errorString, "snack-error");
        return false;
      }

      // generate final array
      let result = finalObjectMappingArray.find((objMap) => objMap.leadxtocrm === 1 || objMap.crmtoleadx === 1);
      // console.log("result result objmap", result);
      if (result === undefined) {
        //console.log("here here");
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  handleObjectSelected = (event, objMap, selectedIdCRMMaster, i) => {
    // const objectArray = objectArr;
    const selectedId = selectedIdCRMMaster;

    //console.log("objMap", objMap);
    //console.log("id selected", selectedId);
    const value = event.target.value;
    this.state.changedLeadMappingId = value;
    //console.log("value", value);
    //console.log("selected id and existing", value, this.state.existingLeadMappingId);
    if (value !== this.state.existingLeadMappingId) {
      //console.log("XXXLead Mapping changed to lead opp");
      this.setState({ leadMappingChangedDialog: true });
    }
    const salesTeamAssignment = [
      ...this.state.groupbased_objectMapping
    ];
    salesTeamAssignment.map(obj => {
      if (obj.selected_idcrm_entity_master === selectedId) {
        const numValue = parseInt(value);
        if (numValue !== undefined && numValue > 0) {
          obj.selected_idcrm_entity_master = numValue;
          obj.objectMapping.map(obj => {
            //make other mapping value false
            if (obj.idcrm_entity_master !== value) {
              obj.leadxtocrm = 0;
              obj.crmtoleadx = 0;

              //console.log("1", "refleadx_" + selectedId);
              //console.log("1", "refcrm_" + selectedId);
              //console.log(this.refs[ref]);
              if (this.refs !== undefined && this.refs !== null &&
                this.refs["refleadx_" + selectedId] !== undefined && this.refs["refleadx_" + selectedId] !== null &&
                this.refs["refcrm_" + selectedId] !== undefined && this.refs["refcrm_" + selectedId] !== null) {
                ReactDOM.findDOMNode(this.refs["refleadx_" + selectedId]).checked = false;
                ReactDOM.findDOMNode(this.refs["refcrm_" + selectedId]).checked = false;
              }
            }
          })
        }
      }
    });
    // console.log("item from sleecsdfsdfsfds", salesTeamAssignment);
    if (salesTeamAssignment !== undefined &&
      salesTeamAssignment.length > 0) {
      this.setState({ groupbased_objectMapping: salesTeamAssignment }, () => {
        // console.log("group changedcyeeeyyeye", this.state.groupbased_objectMapping);
      });
    }
    // console.log("item from sleecsdfsdfsfds", this.state.groupbased_objectMapping);
  }

  handleViewStatusMappingClicked = () => {
    // console.log("status clicked");
    // console.log("Mapping for id", this.props.idcrm_master);
    if (this.state.statusMapping !== undefined &&
      this.state.statusMapping.length > 0) {
      this.setState({ viewStatusMapping: true });
    }
  }

  handleCloseStatusViewMapping = () => {
    // console.log("close dialog", value);
    this.setState({
      viewStatusMapping: false,
    });
  };

  handleChatterCheckboxClick = () => {
    // console.log("chkbox clicked", objMap);
  }

  handleLeadMappingClose_No = () => {
    this.setState({ leadMappingChangedDialog: false });
    this.setState({ leadMappingChanged: false});
    //console.log("existing", this.state.existingLeadMappingId);
    //console.log("changed", this.state.changedLeadMappingId);
    const salesTeamAssignment = [
      ...this.state.groupbased_objectMapping
    ];
    salesTeamAssignment.map(obj => {
      //console.log("obj", obj);
      if (obj.selected_idcrm_entity_master == this.state.changedLeadMappingId) {
        const numValue = this.state.existingLeadMappingId;
        //console.log("numvalue", numValue);
        if (numValue !== undefined && numValue > 0) {
          obj.selected_idcrm_entity_master = numValue;
        }
      }
    });
    //console.log("item from sleecsdfsdfsfds", salesTeamAssignment);
    if (salesTeamAssignment !== undefined &&
      salesTeamAssignment.length > 0) {
      this.setState({ groupbased_objectMapping: salesTeamAssignment }, () => {
        //console.log("group changedcyeeeyyeye", this.state.groupbased_objectMapping);
      });
    }
    //change the radio button to lead lead
    const refOriginalLeadMappingId = "refradio_" + this.state.existingLeadMappingId;
    //console.log("refXXXX", refOriginalLeadMappingId);
    //console.log("refs", this.refs);
    if (this.refs !== undefined && this.refs !== null &&
      this.refs["refradio_" + this.state.existingLeadMappingId] !== undefined && this.refs["refradio_" + this.state.existingLeadMappingId] !== null) {
      //console.log("INSIDE", this.refs["refradio_" + this.state.existingLeadMappingId]);
      ReactDOM.findDOMNode(this.refs["refradio_" + this.state.changedLeadMappingId]).defaultChecked = false;
      ReactDOM.findDOMNode(this.refs["refradio_" + this.state.changedLeadMappingId]).checked = false;
      ReactDOM.findDOMNode(this.refs["refradio_" + this.state.existingLeadMappingId]).defaultChecked = true;
      ReactDOM.findDOMNode(this.refs["refradio_" + this.state.existingLeadMappingId]).checked = true;
      // this.refs["refradio_1"].checked = true;
      // this.refs["refradio_6"].checked = false;
      //console.log("ref checked", this.refs);
    }
  }

  handleLeadMappingClose_Yes = () => {
    this.setState({ leadMappingChangedDialog: false });
    this.setState({ leadMappingChanged: true});
    //console.log("changedMapId", this.state.changedLeadMappingId);
    this.setState({ existingLeadMappingId: this.state.changedLeadMappingId }, () => {
      //console.log("existingMapId", this.state.existingLeadMappingId);
    });
  }

  clearStatuses = () => {
    //console.log("in clearStatuses");
    let temp_statusMapping = [...this.state.statusMapping];
    if (temp_statusMapping !== undefined &&
      temp_statusMapping !== null &&
      temp_statusMapping.length > 0) {
      for (let index = 0; index < temp_statusMapping.length; index++) {
        const element = temp_statusMapping[index];
        //console.log("status mapping element", element);
        element.crm_status = "";
        temp_statusMapping[index] = element;
      }
      if (temp_statusMapping !== undefined && temp_statusMapping.length > 0) {
        this.setState({ statusMapping: temp_statusMapping }, () => {
          //console.log("cleared statusmapping", this.state.statusMapping);
        })
      }
    }
  }

  render() {
    const style = {
      border: 'none !important',
      marginBottom: '0',
      marginLeft: '0',
      marginRight: '0',
      borderStyle: 'hidden',
      padding: '0'
    }

    return (

      <React.Fragment>
        <div className={styles.main_wrapper}>
          <div className="row">
            <div className="col-12">

              <div className={"breadcrumbs row " + styles.centerAlign}>
                <div className="col-md-6 col-12">
                  <h2 className="heading">Integrations</h2>
                  <div className="breadcrumbs_container">
                    <Breadcrumbs separator="›" arial-label="Breadcrumb">
                      {/* <Link to="/sales-news">Home</Link>
                      <Link to="/integrations">Integrations</Link>
                      <Link to="/integrations/configureCRM">Configure CRM</Link> */}
                      {/* <Link to="/integrations/authenticateCRM">Authenticate</Link> */}
                      <span>Home</span>
                      <span>Integrations</span>
                      <span>Configure CRM</span>
                      <span>Configure Data Object Mappings</span>
                    </Breadcrumbs>
                  </div>
                </div>
              </div>
              <div className="row container-body">
                <div className={`col-xs-12 col-md-12 col-lg-12 col-12`}>
                  <div className={styles.configureDataObjsMapCRM_container}>

                    <div className={styles.configureDataObjsMapCRM_container_header}>
                      <h1 className={styles.configureDataObjsMapCRM_container_heading}>
                        Step 2 of 5 - Configure Data Objects Mapping
                      </h1>
                    </div>

                    <div className={styles.configureDataObjsMapCRM_container_footer} >
                      {/* start */}
                      {this.state.errorInWizard ? (<div><WizardError /></div>) :
                        this.state.errorLoadingEntitiesDetails ?
                          (<div><OopsError /></div>) :
                          this.state.loadingEntitiesDetails ? (<SmallLoader />) :
                            this.state.groupbased_objectMapping.length === 0 ?
                              (<div><NoRecordsFound /></div>) :
                              (<div>
                                <div className="row">
                                  <div className="col-12">
                                    <div className="bg-white ml-4 mr-4">
                                      <table className="table table-striped- table-bordered mt-4 mb-4">
                                        <thead>
                                          <tr>
                                            {/*  <td>
                                            <> */}
                                            <th style={{ width: '20%', textAlign: 'center' }}>LeadX Data Objects</th>
                                            <th style={{ width: '40%', textAlign: 'center' }}>Mapping</th>
                                            <th style={{ width: '20%', textAlign: 'center' }}>{this.props.crm_name} Data Objects</th>
                                            <th style={{ width: '20%', textAlign: 'center' }} />
                                            {/* </>
                                          </td>*/}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.groupbased_objectMapping === undefined ?
                                            <div><OopsError /></div>
                                            :
                                            (this.state.groupbased_objectMapping.map((objectMapArray, index) =>
                                              (objectMapArray !== undefined ?
                                                (<tr key={index} >
                                                  {objectMapArray.objectMapping !== undefined &&
                                                    objectMapArray.objectMapping.length > 1 ?

                                                    (
                                                      <td colSpan="4" style={{ padding: '0' }}>
                                                        <table className="table table-striped-" style={style}>
                                                          {objectMapArray.objectMapping.map((objMap, index) =>
                                                            (<tbody key={index}>
                                                              <tr style={{ 'border': 'none !important', 'borderStyle': 'hidden' }}>
                                                                <td className="borderless" style={{ width: '20%' }}>
                                                                  <label>
                                                                    <input ref={'refradio_' + objMap.idcrm_entity_master} name="objects_radio" type="radio" value={objMap.idcrm_entity_master} onChange={(event) => this.handleObjectSelected(event, objMap, objectMapArray.selected_idcrm_entity_master, 'refradio_' + objMap.idcrm_entity_master)} checked={objectMapArray.selected_idcrm_entity_master == objMap.idcrm_entity_master} />
                                                                    &nbsp;&nbsp;&nbsp;
                                                                  <strong>
                                                                      {objMap.leadx_entity}
                                                                    </strong>
                                                                  </label>
                                                                </td>
                                                                <td style={{ width: '40%', textAlign: 'center' }}>
                                                                  <label className="switch">
                                                                    <input ref={'refleadx_' + objMap.idcrm_entity_master} type="checkbox" defaultChecked={objMap.leadxtocrm == true} onChange={() => this.handleMappingCheckboxClick1(objMap, objMap.leadxtocrm, "leadxtocrm", objectMapArray.selected_idcrm_entity_master, 'refleadx_' + objMap.idcrm_entity_master, objectMapArray.objectMapping)} /><span className="slider round" />
                                                                  </label>&nbsp;&nbsp;<strong>LeadX to {this.props.crm_name}</strong>
                                                                  <br />
                                                                  {/* <hr style={{ marginTop: '0.6rem', marginBottom: '0.5rem' }} /> */}
                                                                  <label className="switch">
                                                                    <input ref={'refcrm_' + objMap.idcrm_entity_master} type="checkbox" defaultChecked={objMap.crmtoleadx == true} onChange={() => this.handleMappingCheckboxClick(objMap, objMap.crmtoleadx, "crmtoleadx", objectMapArray.selected_idcrm_entity_master, 'refcrm_' + objMap.idcrm_entity_master)} /><span className="slider round" />
                                                                  </label>&nbsp;&nbsp;<strong>{this.props.crm_name} to LeadX</strong>
                                                                </td>
                                                                <td style={{ width: '20%', textAlign: 'center' }}>
                                                                  <strong>
                                                                    {objMap.crm_entity}
                                                                  </strong>
                                                                </td>
                                                                <td style={{ width: '20%', textAlign: 'center' }}>
                                                                  <button onClick={() => this.handleViewMappingClicked(objMap.idcrm_entity_master)} className="btn btn-outline-secondary btn-sm" type="button" style={{ padding: '5px 20px' }}>View Mapping</button>
                                                                </td>
                                                              </tr>
                                                              {/* {objMap.leadx_entity.toLowerCase() == "lead" ?
                                                              <tr style={{ backgroundColor: '#f7f7f7', textAlign: 'center' }}>
                                                                <td colSpan="4">
                                                                  <label>
                                                                    <input type="checkbox" defaultChecked />
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;Map to Chatter
                                                                  </label>
                                                                </td>
                                                              </tr> :
                                                              <></>
                                                            } */}

                                                            </tbody>)
                                                          )}
                                                        </table>
                                                      </td>)

                                                    :

                                                    (objectMapArray.objectMapping.map(objMap =>
                                                      <>
                                                        {/* <td style={{
                                                        width: '20%',
                                                        backgroundColor: (objMap.leadx_entity.toLowerCase() == "company" ||
                                                          objMap.leadx_entity.toLowerCase() == "contacts") ? "#d2d2d2" : "transparent"
                                                      }}> */}
                                                        <td style={{ width: '20%' }}>
                                                          <strong>
                                                            {objMap.leadx_entity}
                                                          </strong>
                                                        </td>
                                                        {/* <td style={{
                                                        width: '40%', textAlign: 'center', backgroundColor: (objMap.leadx_entity.toLowerCase() == "company" ||
                                                          objMap.leadx_entity.toLowerCase() == "contacts") ? "#d2d2d2" : "transparent"
                                                      }}> */}
                                                        <td style={{ width: '40%', textAlign: 'center' }}>
                                                          <label className="switch">
                                                            <input type="checkbox" defaultChecked={objMap.leadxtocrm} onChange={() => this.handleMappingCheckboxClick1(objMap, objMap.leadxtocrm, "leadxtocrm", objectMapArray.selected_idcrm_entity_master)} /><span className="slider round" />
                                                          </label>&nbsp;&nbsp;<strong>LeadX to {this.props.crm_name}</strong>
                                                          <br />
                                                          {/*  <hr style={{ marginTop: '0.6rem', marginBottom: '0.5rem' }} /> */}
                                                          <label className="switch">
                                                            <input type="checkbox" defaultChecked={objMap.crmtoleadx} onChange={() => this.handleMappingCheckboxClick(objMap, objMap.crmtoleadx, "crmtoleadx", objectMapArray.selected_idcrm_entity_master)} /><span className="slider round" />
                                                          </label>&nbsp;&nbsp;<strong>{this.props.crm_name} to LeadX</strong>
                                                        </td>
                                                        {/* <td style={{
                                                        width: '20%', textAlign: 'center', backgroundColor: (objMap.leadx_entity.toLowerCase() == "company" ||
                                                          objMap.leadx_entity.toLowerCase() == "contacts") ? "#d2d2d2" : "transparent"
                                                      }}> */}
                                                        <td style={{ width: '20%', textAlign: 'center' }}>
                                                          <strong>
                                                            {objMap.crm_entity}
                                                          </strong>
                                                        </td>
                                                        {/* <td style={{
                                                        width: '20%', textAlign: 'center', backgroundColor: (objMap.leadx_entity.toLowerCase() == "company" ||
                                                          objMap.leadx_entity.toLowerCase() == "contacts") ? "#d2d2d2" : "transparent"
                                                      }}> */}
                                                        <td style={{ width: '20%', textAlign: 'center' }}>
                                                          <button onClick={() => this.handleViewMappingClicked(objMap.idcrm_entity_master)} className="btn btn-outline-secondary btn-sm" type="button" style={{ padding: '5px 20px' }}>View Mapping</button>
                                                        </td>
                                                      </>
                                                    ))

                                                  }
                                                </tr>)
                                                :
                                                <></>)
                                            )
                                            )
                                          }
                                        </tbody>
                                      </table>
                                      <hr className="jss201" />
                                      <div className="mt-3 mb-5">
                                        <div style={{ textAlign: 'right' }}>
                                          <button onClick={this.handleGoBackClicked} className={styles.profileContainer_update_btn__MXNDH} type="button" style={{ backgroundColor: '#e0e0e0', padding: '10px 20px', color: 'rgba(0, 0, 0, 0.87)' }}>Go Back</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <button onClick={() => this.handleSaveClicked("step2")} type="button" className={styles.profileContainer_update_btn__MXNDH} style={{ padding: '10px 20px' }}>Save</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                  <button onClick={() => this.handleSaveNProceedClicked("step2")} type="button" className={styles.profileContainer_update_btn__MXNDH} style={{ padding: '10px 20px' }}>Save &amp; Proceed</button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>)
                      }

                      {/* end */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Dialog
            className="width"
            aria-labelledby="customized-add-activities"
            open={this.state.viewMapping ? this.state.viewMapping : false}
            onClose={this.handleCloseViewMapping}
            maxWidth={"lg"}
          >
            <FieldsMappingCRMContainer
              close={this.handleCloseViewMapping}
              fieldMapping={this.state.selectedObjectMapping}
              isCRM_leadx_sync={this.state.isCRM_leadx_sync}
              isLeadx_CRM_sync={this.state.isLeadx_CRM_sync}
            // crm_name={this.state.objectMapping[0].crm_name}
            /* updateActivity={this.state.updateActivity}
            lead_id={
              this.state.updateActivity === null
                ? null
                : this.state.updateActivity.lead_id 
            }*/
            />
          </Dialog>
          <Dialog
            open={this.state.leadMappingChangedDialog}
            onClose={this.handleLeadMappingClose_No}
            aria-labelledby="alert-dialog-title"
            fullWidth
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="simple-dialog-title">Changing existing Lead Mapping will remove all the previous Status mappings in the next step. Do you want to continue ?
            </DialogTitle>
            <DialogActions className="action-btn-wrap">
              <Button
                variant="contained"
                onClick={() => {
                  this.handleLeadMappingClose_Yes();
                  // delete the status mappings -TODO
                  this.clearStatuses();
                }}
                className="action-btn yes-btn" >
                Yes
          </Button>
              <Button variant="contained" className="action-btn"
                onClick={() => {
                  this.handleLeadMappingClose_No();
                  // set the radio button to existing mapping - TODO 
                }} autoFocus>
                No
          </Button>
            </DialogActions>
          </Dialog>
          {/* <Dialog
            className="width"
            aria-labelledby="customized-add-activities"
            open={this.state.viewStatusMapping ? this.state.viewStatusMapping : false}
            onClose={this.handleCloseStatusViewMapping}
            maxWidth={"lg"}
          >
            <StatusMappingCRMContainer
              close={this.handleCloseStatusViewMapping}
              fieldMapping={this.state.statusMapping}
            />
          </Dialog> */}
        </div>
        {/*  } */}
      </React.Fragment >
    );
  }
}

export default withStyles(stylesMaterialUI)(withRouter(ConfigureDataObjsMapCRMComponent));