import React from 'react'
import excelIcon from "../../../../assets/images/bi/excel.png";
import styles from "../pulseLiteContainer.module.scss";
import Excel from "exceljs";
import FileSaver from "file-saver";
import moment from "moment";


function ExcelExport(props) { 
    // Employee Sample Data  
    // email: "santoshManager@leadx.no"
    // CREATED_BY_FNAME: ""9E"YcYseF!ZEhN)&D/f Q$U/fA<XY"
    // CREATED_BY_LNAME: "QG*VT)miji\&=+tqL]cb g:pjq#8m"
    // IMAGE: "https://leadxiprogrammer.s3.ap-south-1.amazonaws.com/Leadx-staging/users/1581576319163_users.jpg"
    // LEADS_GENERATED: 2627
    // NEW: "0"
    // LEAD: "142"
    // OPPORTUNITY: "0"
    // PROPOSAL: "0"
    // NEGOTIATION: "0"
    // COMPLETE: "0"
    // LEADS_WON: "2414"
    // LEADS_LOST: "71"
    // POINTS: "50.00"
    // CREATED_BY: "Santosh Kotnis"

//     id: 52
// email: "tom@leadx.no"
// first_name: "Tom"
// last_name: "Aas"
// user_avatar: "https://leadxiprogrammer.s3.ap-south-1.amazonaws.com/Leadx-staging/users/1583316003511_users.jpg"
// LEADS_GENERATED: "7"
// NEW: "0"
// LEAD: "0"
// OPPORTUNITY: "0"
// PROPOSAL: "0"
// NEGOTIATION: "0"
// LEADS_WON: "7"
// LEADS_LOST: "1"
// POINTS: "4400.00"

    const exportExcel = Data => {
        Data.forEach(element => {
            // delete element["CREATED_BY_FNAME"];
            // delete element["CREATED_BY_LNAME"];
            delete element["IMAGE"];
            // const name = element["CREATED_BY"].split(" ");
            element["email"] = element["email"];
            element["FIRST_NAME"] = element['first_name'];
            element["LAST_NAME"] = element["last_name"];

            element["LEADS_GENERATED"] = element["LEADS_GENERATED"];
            element["BONUS_POINT"] = element["POINTS"];
            element["NEW"] = element["NEW"];

            element["LEAD"] = element["LEAD"];
            element["OPPORTUNITY"] = element["OPPORTUNITY"];
            element["PROPOSAL"] = element["PROPOSAL"];

            element["NEGOTIATION"] = element["NEGOTIATION"];
            element["LEADS_WON"] = element["LEADS_WON"];
            element["LEADS_LOST"] = element["LEADS_LOST"];
        });
        const options = {
            fileName: "Bonus Report",
            data: Data,
            colWidth: 10
        };
        exportExcelData(options);
    };

    function dateRange() {
        const firstDay = new Date(props.selectedMonth.from_date);
        const lastDay = new Date(props.selectedMonth.to_date);
        return moment(firstDay).format("DD MMM YYYY") + ' To ' + moment(lastDay).format("DD MMM YYYY") 
    }

    function exportExcelData(options) {
        const fileName = options.fileName;
        const data = options.data;
        const columnWidth = options.colWidth;

        // Create workbook and worksheet
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet(fileName);

        worksheet.addRow([]);
        // Add blank row        
        
        worksheet.addRow(["The Bonus Report for the period of " + dateRange() ]);
        worksheet.addRow([]);

        // Add Header Row
        const headers = ["Username/email", "First Name", "Last Name", "Total Leads", "Bonus Point", " New", "Lead", "Opportunity", "Proposal", "Negotiation", "Won", "Lost"];
        const headerRow = worksheet.addRow(headers);
        // Cell Style : Fill and Border
        headerRow.eachCell((cell, n) => {
            // console.log("n=",n);
            // console.log("cell=",cell);
            
            cell.font = {
                name: "Open Sans, sans-serif",
                size: 7,
                bold: true,
                color: "fff",
            };
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "c1c1c0" }
            };
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" }
            };

            if (n === 1 || n === 2 || n === 3 ) {
                cell.alignment = {
                    vertical: "middle",
                    horizontal: "left"
                };
            } else {
                cell.alignment = {
                    vertical: "middle",
                    horizontal: "center"
                };
            }
       
        });

        let keys = [];
        // keys = Object.keys(data[0]);


        keys = ["email", "FIRST_NAME", "LAST_NAME", "LEADS_GENERATED", "BONUS_POINT", "NEW", "LEAD", "OPPORTUNITY", "PROPOSAL", "NEGOTIATION", "LEADS_WON", "LEADS_LOST"];
        // Add Data and Conditional
        const converteddata = data.map((obj, i) => {
            return keys.map(key => {
                const filterxt = obj[key];
                return filterxt;
            });
        });

        let row;
        converteddata.forEach((d, i) => {
            row = worksheet.addRow(d);
            row.font = {
                name: "Open Sans, sans-serif",
                size: 7,
                bold: false
            };
            row.alignment = { vertical: "middle", horizontal: "center" };
        });

        worksheet.columns.forEach((column, i) => {
            ++i;
            if (i === 1 || i === 2 || i === 3 ) {
                worksheet.getColumn(i).alignment = {
                    vertical: "middle",
                    horizontal: "left"
                };
            }
            worksheet.getColumn(1).width = columnWidth + 15;
            worksheet.getColumn(2).width = columnWidth + 10;
            worksheet.getColumn(3).width = columnWidth + 10;
            worksheet.getColumn(i).width = columnWidth;
        });

        // Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((d) => {
            const blob = new Blob([d], {
                type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });
            FileSaver.saveAs(blob, fileName + ".xlsx");
        });
    }

    return (
        <React.Fragment>
            <img
                onClick={() => exportExcel(props.exportData)}
                className={styles.icon_pdf}
                src={excelIcon}
                alt="ExcelIcon"
            />
            <p>Excel</p>
        </React.Fragment>
    )
}

export default ExcelExport
