import axios from "../../common/api-config";

export default {
  getParentAccountGroup() {
    const url = '/account/getParentOrganizationDetails';
    return axios.post(url, {}).then(response => {
      const { 
        account_group 
      } = response.data.data.result.data;
      return account_group;
    });
  },
  getChildOrgDetail(account_id) {
    return axios.post('/account/getChildOranizationAccountDetails', {account_id}).then(response => {
      const data = response.data.data;
      return { ...data, registered_url: data.profile.dns ? `${data.profile.dns}/register/${data.profile.key}` : '' };
    });
  },
  async getOrganizationDetails(account_id) {
    const [account_group, data] = await Promise.all([this.getParentAccountGroup(), this.getChildOrgDetail(account_id)]);
    return { ...data, profile: { ...data.profile, AccountGroup: account_group }};
  },
  updateOrganizationSettings(
    self_user_reg_config_enabled,
    self_user_reg_approval_required,
    default_license_id,
    default_role_id,
    default_department_id,
    cross_silo_feature,
    handover_feature,
    enable_publishing_news,
    enable_publishing_news_child,
    create_contact_for_lite_user,
    revenue_field_for_lite_user
  ) {
    const body = {
      self_user_reg_config_enabled: self_user_reg_config_enabled ? 1 : 0,
      self_user_reg_approval_required: self_user_reg_approval_required ? 1 : 0,
      default_license_id,
      cross_silo_feature: cross_silo_feature ? 1 : 0,
      handover_feature: handover_feature ? 1 : 0,
      create_contact_for_lite_user: create_contact_for_lite_user ? 1 : 0,
      revenue_field_for_lite_user: revenue_field_for_lite_user ? 1 : 0,
      enable_publishing_news: enable_publishing_news ? 1 : 0,
      enable_publishing_news_child: enable_publishing_news_child ? 1 : 0,
      default_department_id,
      default_role_id
    };
    return axios.post('/account/updateOrganizationSettings', body).then(response => response.data);
  },
  getMyAccount() {
    const url = '/users/getMyAccount';
    return axios.post(url, {}).then(response => {
      return response.data.data.data.name;
    });
  }
}