import * as types from "./types";

import axios from "../../../common/api-config";

export const initializeSalesFeeds = requestBody => {
  return dispatch => {
    axios
      .post("/salesFeed/get", requestBody)
      .then(response => {
        console.log(response, "salesFeed/get");
        if (response.data.statusCode === 200) {
          dispatch(
            intializeSalesFeedsSuccess(
              response.data.data.rows,
              response.data.data.count
            )
          );
        } else {
          dispatch(intializeSalesFeedsFail());  
        }
      })
      .catch(error => {
        dispatch(intializeSalesFeedsFail());
      });
  };
};

export const intializeSalesFeedsSuccess = (data, count) => {
  return {
    type: types.INITIALIZE_SALES_FEEDS_SUCCESS,
    data: data,
    totalSalesFeed: count
  };
};

export const intializeSalesFeedsFail = () => {
  return {
    type: types.INITIALIZE_SALES_FEEDS_FAIL
  };
};

export const loadMoreSalesFeeds = requestBody => {
  return dispatch => {
    axios
      .post("/salesFeed/get", requestBody)
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch(loadMoreSalesFeedsSuccess(response.data.data.rows));
        } else {
          dispatch(intializeSalesFeedsFail());  
        }
      })
      .catch(error => {
        dispatch(intializeSalesFeedsFail());
      });
  };
};

const loadMoreSalesFeedsSuccess = data => {
  return {
    type: types.LOAD_MORE_SALES_FEEDS_SUCCESS,
    data: data
  };
};

export const markAsRead = requestBody => {
  return dispatch => {
    axios
      .post("/salesFeed/addSalesFeedRead", requestBody)
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch({type:'MARK_AS_READ_SUCCESS',data:response.data.data.rows});
        } else {
          dispatch({type:'MARK_AS_READ_ERROR',data:null});  
        }
      })
      .catch(error => {
        dispatch({type:'MARK_AS_READ_ERROR',data:null});
      });
  };
}

export default {
  initializeSalesFeeds,
  loadMoreSalesFeeds,
  markAsRead
};
