import React, { useState, useEffect, useRef } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import LargeScreenLoader from "../../common/Loaders/PulseLoader/pulse-loader";
//import Select from 'react-select';
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import SnackbarComponent from "../../common/Snackbar/snackbar";
import styles from "./helper.module.scss";
import APIs from "./apis";
import AsyncSelect from "react-select/async-creatable";
import Select from "react-select";
import _ from "lodash";
import grey from "@material-ui/core/colors/grey";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import imageCompression from "browser-image-compression";
import { openSnackbar } from "../../common/Snackbar/snackbar";
import CropperDialog from "../../common/CropperDialog/CropperDialog";
import IconButton from "@material-ui/core/IconButton";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import copy from "copy-to-clipboard";
import { showSnackbarMessage } from "../../common/common-functions";
import InviteUser from "../../common/InviteUser";
import { isParentOrgAdmin } from "../../common/common-functions";
import { getDatesFromMonth } from "../../common/Util";
import SingleSelect from "../../components/SingleSelect";

const CardTitle = styled.span`
  font-weight: 650;
  font-size: 1rem !important;
`;

const PrimaryButton = styled(Button)`
  font-size: 0.75em;
  text-transform: none;
  white-space: nowrap;
  text-align: center;
  background-color: #3a4860;
  padding-left: 2em;
  padding-right: 2em;
  color: white;
  height: 4.25em;
  padding: 0 0.5em;
  padding-left: 4em;
  padding-right: 4em;
  &:hover {
    background-color: #19d98b;
  }
`;

const OutLinedButton = styled(Button)`
  font-size: 0.75em;
  text-transform: none;
  height: 4.25em;
  padding: 0 0.5em;
  white-space: nowrap;
  text-align: center;
  background-color: transparent;
  padding-left: 4em;
  padding-right: 4em;
  font-weight: bold;
  color: #3a4860;
  &:hover {
    color: #19d98b;
  }
`;

const CustomCard = styled.div`
  background-color: #ffffff;
  padding: 4.5em;
  padding-top: 1.875em;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.2);
`;

// const debouncedCitySearch = _.debounce(, 300);

const MONTH_OPTIONS = [
  { label: "January", value: 0 },
  { label: "February", value: 1 },
  { label: "March", value: 2 },
  { label: "April", value: 3 },
  { label: "May", value: 4 },
  { label: "June", value: 5 },
  { label: "July", value: 6 },
  { label: "August", value: 7 },
  { label: "September", value: 8 },
  { label: "October", value: 9 },
  { label: "November", value: 10 },
  { label: "December", value: 11 },
];

function EditChildOrg({ history, match }) {
  const [isLoading, setIsLoading] = useState(false);
  const [features, setFeatures] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [countryCodeOptions, setCountryCodeOptions] = useState([]);
  const [dateFormatOptions, setDateFormatOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [timezoneOptions, setTimezoneOptions] = useState([]);
  const [masterCurrencyOptions, setMasterCurrencyOptions] = useState([]);
  const [baseCurrencyOptions, setBaseCurrencyOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [childOrganization, setChildOrganization] = useState({
    country: null,
    state: null,
    city: null,
    language: null,
    dateFormat: null,
    timezone: null,
  });
  const inputFile = useRef(null);
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    countryCode: null,
    contact: "",
    baseCurrency: null,
    masterCurrency: null,
    address_line_1: "",
    address_line_2: "",
    zipcode: "",
    city: null,
    state: null,
    country: null,
  });
  const [selectedMonth, setSelectedMonth] = useState(
    MONTH_OPTIONS.find((m) => m.value === 0)
  );

  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid #c0c0c0",
      boxShadow: "#c0c0c0",
      borderColor: "#c0c0c0",
      "&:hover": {
        boxShadow: "#c0c0c0",
        borderColor: "#c0c0c0",
      },
    }),
  };

  const [profilePicValidation, setProfilePicValidation] = useState(true);
  // const [uri, seturi] = useState('');
  const [src, setImageSrc] = useState("");
  const [cropperDialog, setCropperDialog] = useState(false);
  const [file, setFileForImage] = useState("");

  useEffect(() => {
    fetchData();
    fetchFeatures();
  }, []);

  useEffect(() => {
    fetchStates();
  }, [childOrganization.country]);

  useEffect(() => {
    if (childOrganization.city) {
      setChildOrganization({
        ...childOrganization,
        country: countryOptions.find(
          (c) => c.value === childOrganization.city.country_id
        ),
      });
    }
  }, [childOrganization.city]);

  const fetchFeatures = async () => {
    const features = await APIs.getFeatureStatus();
    setFeatures(features);
  };

  const fetchStates = async () => {
    if (childOrganization.country) {
      const result = await APIs.getStates(childOrganization.country.value);
      const options = result.map((r) => ({ value: r.id, label: r.name }));
      setStateOptions(options);
      if (childOrganization.city) {
        setChildOrganization({
          ...childOrganization,
          state: options.find(
            (c) => c.value === childOrganization.city.state_id
          ),
        });
      }
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    const result = await Promise.all([
      APIs.getCountries(),
      APIs.getDateFormatList(),
      APIs.getLanguageList(),
      APIs.getTimeZoneList(),
      APIs.getMasterCurrency(),
      APIs.getBaseCurrencyList(),
      APIs.getOrganizationDetails(match.params.id),
    ]);

    const countryCodeOptions = result[0].map((c) => ({
      value: c.id,
      label: `${c.iso_code} +${c.dialing_code}`,
    }));
    setCountryCodeOptions(countryCodeOptions);
    const countryOptions = result[0].map((c) => ({
      value: c.id,
      label: c.name,
    }));
    setCountryOptions(countryOptions);
    const dFormatOptions = result[1].map((d) => ({
      value: d.id,
      label: d.format,
    }));
    setDateFormatOptions(dFormatOptions);
    const lOptions = result[2].map((l) => ({ value: l.id, label: l.language }));
    setLanguageOptions(lOptions);
    const tOptions = result[3].map((t) => ({ value: t.id, label: t.timezone }));
    setTimezoneOptions(tOptions);
    const masterCurrencyOptions = result[4].map((m) => ({
      value: m.id,
      label: m.short_name,
    }));
    setMasterCurrencyOptions(masterCurrencyOptions);
    const baseCurrencyOptions = result[5].map((b) => ({
      value: b.id,
      label: b.short_name,
    }));
    setBaseCurrencyOptions(baseCurrencyOptions);
    const {
      language_id,
      date_format_id,
      timezone_id,
      currency_id,
      other_currency_id,
      country_code,
      country_id,
      state_id,
      city_id,
      cityName,
      fin_year_starting_month,
    } = result[6];

    console.log(result[6], "Res 6");

    setChildOrganization({
      ...result[6],
      language: lOptions.find((l) => l.value === language_id),
      dateFormat: dFormatOptions.find((d) => d.value === date_format_id),
      timezone: tOptions.find((t) => t.value === timezone_id),
      baseCurrency: baseCurrencyOptions.find((b) => b.value === currency_id),
      masterCurrency: masterCurrencyOptions.find(
        (m) => m.value === other_currency_id
      ),
      countryCode: countryCodeOptions.find((r) => r.value === country_code),
      country: countryOptions.find((c) => c.value === country_id),
      city: { value: city_id, state_id, country_id, label: cityName },
      fin_year_starting_month,
    });

    setSelectedMonth(
      MONTH_OPTIONS.find((m) => {
        if (fin_year_starting_month) {
          return m.value === fin_year_starting_month;
        } else {
          return m.value === 0;
        }
      })
    );

    setIsLoading(false);
  };

  const handleCancelOnClick = () => {
    history.goBack();
  };

  const isValidForm = () => {
    const errors = {};

    const {
      name,
      email,
      countryCode,
      contact,
      city,
      state,
      country,
      zipcode,
      address_line_1,
      baseCurrency,
      masterCurrency,
    } = childOrganization;

    if (!name) {
      errors.name = "Name is required field";
    }

    if (!city) {
      errors.city = "City is required field";
    }

    if (!state) {
      errors.state = "State is required field";
    }

    if (!country) {
      errors.country = "Country is required filed";
    }

    const contactRegex = /^\d{8,12}$/;
    if (!contact) {
      errors.contact = "Phone Number is required field";
    } else if (!contactRegex.test(contact)) {
      errors.contact = "Invalid Phone Number";
    }

    if (!countryCode) {
      errors.countryCode = "Code is required";
    }

    if (!zipcode) {
      errors.zipcode = "Zipcode is required field";
    }

    if (!address_line_1) {
      errors.address_line_1 = "Address Line 1 is required field";
    }

    if (!baseCurrency) {
      errors.baseCurrency = "Base currency is required field";
    }

    if (!masterCurrency) {
      errors.masterCurrency = "Master Currency is required field";
    }

    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!email) {
      errors.email = "Email is required field";
    } else if (!emailRegex.test(email)) {
      errors.email = "Invalid email";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleUpdateOnClick = async () => {
    if (isValidForm()) {
      setIsLoading(true);
      const result = await APIs.updateChildOrganization(
        { ...childOrganization, fin_year_starting_month: selectedMonth.value },
        match.params.id
      );
      setIsLoading(false);
      setChildOrganization({
        ...childOrganization,
        fin_year_starting_month: selectedMonth.value,
      });
      openSnackbar(
        "Organization updated successfully",
        result.success ? "snack-success" : "snack-error"
      );
    }
  };

  const handleOnChange = (key) => (value) => {
    setChildOrganization({
      ...childOrganization,
      [key]: value,
    });
  };

  const handleOnTextChange = (key) => (e) => {
    let value = e.target.value;
    if (key === "contact") {
      value = value.replace(/[^0-9]*/g, "");
    }
    handleOnChange(key)(value);
  };

  //Checking MIME file type
  const mimeType = (headerString, blob) => {
    console.log(headerString);
    if (headerString.startsWith("ffd8ff")) {
      headerString = headerString.slice(0, 6);
      console.log(headerString);
    }
    setProfilePicValidation(true);
    switch (headerString) {
      case "89504e47":
      case "ffd8ff":
        checkUploadedFile(blob);
        break;
      default:
        // setProfilePicValidation(false);
        checkUploadedFile(blob);
        break;
    }
  };

  const checkUploadedFile = (file) => {
    console.log(file.type, profilePicValidation);
    if (
      profilePicValidation === true &&
      (file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg")
    ) {
      if (file.size < 10485760) {
        if (file.size > 2097152) {
          const options = {
            maxSizeMB: 2,
            maxWidthOrHeight: 1920,
          };
          imageCompression(file, options)
            .then((compressFile) => {
              settingImage(compressFile);
            })
            .catch((error) => {
              openSnackbar("Image size too large", "snack-error");
            });
        } else {
          settingImage(file);
        }
      } else {
        openSnackbar("Image size should not more than 10 mb.", "snack-error");
      }
    } else {
      openSnackbar("Invalid file format", "snack-error");
    }
  };

  const settingImage = async (file) => {
    const base64String = await getBase64(file);
    console.log(base64String);
    setImageSrc(base64String);
    setCropperDialog(true);
    setFileForImage(file);
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const remoteCallback = async (blob) => {
    let header = "";
    var fr = new FileReader();
    fr.onload = (e) => {
      var arr = new Uint8Array(e.target.result).subarray(0, 4);
      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      mimeType(header, blob);
    };
    fr.readAsArrayBuffer(blob);
  };

  const uploadProfile = (e) => {
    let file = e.target.files[0];
    console.log(file);
    e.target.value = "";
    remoteCallback(file);
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const onChangeFile = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    uploadProfile(event);
  };

  const cropImage = async (val, cropper) => {
    if (val) {
      let params = {
        fileName: file.name,
        fileExtension: file.type,
        fileBody: cropper.getCroppedCanvas().toDataURL(file.type),
        parentFolder: "users",
      };
      setCropperDialog(false);
      setIsLoading(true);
      const result = await APIs.uplaodImage(params);
      setChildOrganization({
        ...childOrganization,
        logo: result,
      });
      setIsLoading(false);
    }
  };

  const handleGenerateNewURL = async () => {
    setIsLoading(true);
    const URLUpdateResult = await APIs.updateRegistrationUrl(match.params.id);
    console.log(URLUpdateResult);
    setChildOrganization({
      ...childOrganization,
      registered_url: URLUpdateResult.key,
    });
    setIsLoading(false);
    showSnackbarMessage("New URL generated successfully.", "snack-success");
  };

  const handleCloseCropDialog = () => {
    setCropperDialog(false);
    setImageSrc("");
    setFileForImage({});
    setProfilePicValidation(false);
  };

  const handleCopyOnClick = () => {
    copy(childOrganization.registered_url);
    showSnackbarMessage("URL copied successfully.", "snack-success");
  };

  console.log(childOrganization, "Child");

  const [fromDate, toDate] = getDatesFromMonth(
    childOrganization.fin_year_starting_month || 0
  );

  const [selectedFromDate, selectedToDate] = getDatesFromMonth(
    selectedMonth.value
  );

  return (
    <>
      <SnackbarComponent />
      {isLoading && <LargeScreenLoader />}
      {!isLoading && (
        <div className="main-wrapper">
          <div className="breadcrumbs">
            <div className="row justify-content-between">
              <div className="col-md-4">
                <h3 className={styles.title_Administration}>Administration</h3>
                <div className="breadcrumbs_container">
                  <Breadcrumbs separator="›" arial-label="Breadcrumb">
                    <Link to="/sales-news">Home</Link>
                    <Link to="/administration">Administration</Link>
                    <span>Organization Profile</span>
                  </Breadcrumbs>
                </div>
              </div>
              {/* <div className="col-md text-right">
                {features["Subscriptions"] || !isParentOrgAdmin() ? (
                  <label className={styles.disabled}></label>
                ) : (
                  <label
                    className={styles.cursor_pointer}
                    onClick={() =>
                      history.push("/administration/subscriptions")
                    }
                  >
                    Manage Subscriptions <span className="mx-2"></span> |
                  </label>
                )}
                <span className="mx-2"></span>
                <InviteUser />
                <span className="mx-2"></span>

                {features["Child Organizations"] || !isParentOrgAdmin() ? (
                  <label className={styles.disabled}></label>
                ) : (
                  <label
                    className={styles.cursor_pointer}
                    onClick={() =>
                      history.push("/administration/new_child_organization")
                    }
                  >
                    |<span className="mx-2"></span>
                    Create Child Organization
                  </label>
                )}
                <span className="mx-2"></span>
              </div> */}
            </div>
          </div>
          <hr />
          <div className="row">
            {/* Left Side */}
            <div className="col-xs-12 col-sm-12 col-lg-4">
              <div className={styles.containerCustom}>
                <img
                  src={childOrganization.logo}
                  className={styles.image + " img img-responsive"}
                  style={{
                    height: "165px",
                    width: "165px",
                    borderRadius: "50%",
                  }}
                />
                <div className={styles.middle}>
                  <div className={styles.rounded} onClick={onButtonClick}>
                    <CameraAltOutlinedIcon
                      fontSize="small"
                      style={{ color: grey[50], alignSelf: "center" }}
                    ></CameraAltOutlinedIcon>
                    <input
                      type="file"
                      id="file"
                      ref={inputFile}
                      accept="image/*"
                      onChange={onChangeFile}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.title_text}>
                <label className={styles.title_text}>
                  Account / Organization Name
                </label>
              </div>
              <div>
                <input
                  className={styles.inputStyleOrg + " " + styles.inputStyle}
                  type="text"
                  autoComplete="off"
                  placeholder="Enter Child Account/Org. Name"
                  value={childOrganization.name}
                  onChange={handleOnTextChange("name")}
                />
              </div>
              <span className={styles.errorMessage}>{errors.name}</span>
              <div className={styles.title_text}>
                <label className={styles.title_text}>Customer ID</label>
              </div>
              <div>
                <input
                  className={styles.inputStyleOrg + " " + styles.inputStyle}
                  type="text"
                  autoComplete="off"
                  maxLength="15"
                  placeholder="Customer ID"
                  value={childOrganization.client_id}
                  disabled
                />
              </div>
              <div className={styles.title_text}>
                <label className={styles.title_text}>
                  Sub domain for web users
                </label>
              </div>
              <div>
                <input
                  className={styles.inputStyleOrg + " " + styles.inputStyle}
                  type="text"
                  autoComplete="off"
                  maxLength="15"
                  placeholder="Sub domain"
                  value={childOrganization.dns}
                  disabled
                />
              </div>
              {childOrganization.registered_url && (
                <>
                  <div className={styles.title_text}>Registered URL</div>
                  <div className="row">
                    <div className={"col-md-6 " + styles.url_container}>
                      {childOrganization.registered_url}
                    </div>
                    <div className="col-md-1">
                      <IconButton aria-label="copy" onClick={handleCopyOnClick}>
                        <FileCopyOutlinedIcon />
                      </IconButton>
                    </div>
                    <div class="col-md-6">
                      <PrimaryButton
                        onClick={handleGenerateNewURL}
                        className={styles.generateUrlBtn}
                      >
                        Generate New Url
                      </PrimaryButton>
                    </div>
                  </div>
                </>
              )}
            </div>

            {/* Right Side */}
            <div className="col-xs-12 col-sm-12 col-lg-8">
              <div className={styles.title_right_text}>Primary Contact</div>
              <div className={styles.card_view}>
                <CustomCard>
                  <div className="row">
                    <div className="col-md-6">
                      <label className={styles.title_text}>Email Id</label>
                      <input
                        className={styles.inputStyle}
                        type="text"
                        name="personEmail"
                        disabled
                        autoComplete="off"
                        maxLength="100"
                        placeholder="Enter Email Id"
                        value={childOrganization.email}
                        onChange={handleOnTextChange("email")}
                      />
                      <span className={styles.errorMessage}>
                        {errors.email}
                      </span>
                    </div>
                    <div className="col-md-6">
                      <div class="row">
                        <div className="col-6 padding_right">
                          <label className={styles.title_text}>Phone No.</label>
                        </div>
                      </div>
                      <div class="row">
                        <div
                          className="col-4 padding_right padding_right_remove"
                          style={{ marginTop: 5 }}
                        >
                          <Select
                            options={countryCodeOptions}
                            placeholder="Code"
                            styles={style}
                            isDisabled={true}
                            value={childOrganization.countryCode}
                            onChange={handleOnChange("countryCode")}
                          />
                          <span className={styles.errorMessage}>
                            {errors.countryCode}
                          </span>
                        </div>
                        <div className="col-8 padding_left">
                          <input
                            className={styles.inputStyle}
                            type="text"
                            name="personPhoneNumber"
                            //value={this.state.personFields["personPhoneNumber"]}
                            //onChange={this.handlePhoneNumberChange}
                            autoComplete="off"
                            maxLength="15"
                            placeholder="Enter Phone No."
                            value={childOrganization.contact}
                            disabled
                            onChange={handleOnTextChange("contact")}
                          />
                          <span className={styles.errorMessage}>
                            {errors.contact}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: "1.25em" }}>
                    *Primary Contact will act as an Admin of a system
                  </div>
                </CustomCard>
              </div>

              <div className={styles.title_right_text}>
                Account / Organization Name
              </div>
              <div className={styles.card_view}>
                <CustomCard>
                  <div className="row">
                    <div className="col-md-4">
                      <div>
                        <label className={styles.title_text}>
                          Account Group
                        </label>
                      </div>
                      <div>
                        <input
                          className={styles.inputStyle}
                          type="text"
                          autoComplete="off"
                          placeholder="Enter Account Group"
                          value={
                            childOrganization &&
                            childOrganization.account_group &&
                            childOrganization.account_group.name
                          }
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div>
                        <label className={styles.title_text}>
                          Base Currency
                        </label>
                      </div>

                      <div style={{ marginTop: 6 }}>
                        <Select
                          options={baseCurrencyOptions}
                          placeholder="Base Currency"
                          value={childOrganization.baseCurrency}
                          onChange={handleOnChange("baseCurrency")}
                          isDisabled
                        />
                        <span className={styles.errorMessage}>
                          {errors.baseCurrency}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div>
                        <label className={styles.title_text}>
                          Other Currencies
                        </label>
                      </div>

                      <div>
                        <div style={{ marginTop: 6 }}>
                          <Select
                            options={masterCurrencyOptions}
                            placeholder="Master Currency"
                            value={childOrganization.masterCurrency}
                            onChange={handleOnChange("masterCurrency")}
                            isDisabled
                          />
                          <span className={styles.errorMessage}>
                            {errors.masterCurrency}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </CustomCard>
              </div>

              <div className={styles.title_right_text}>
                Account / Organization Settings
              </div>
              <div className={styles.card_view}>
                <CustomCard>
                  <div className="row">
                    <div className="col-md-6">
                      <div>
                        <label className={styles.title_text}>Date Format</label>
                      </div>
                      <div style={{ marginTop: 6 }}>
                        <Select
                          options={dateFormatOptions}
                          placeholder="Date Format"
                          value={childOrganization.dateFormat}
                          onChange={handleOnChange("dateFormat")}
                          isDisabled
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div>
                        <label className={styles.title_text}>
                          Default Language
                        </label>
                      </div>
                      <div style={{ marginTop: 6 }}>
                        <Select
                          options={languageOptions}
                          placeholder="Language"
                          value={childOrganization.language}
                          onChange={handleOnChange("language")}
                          isDisabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div>
                        <label className={styles.title_text}>Timezone</label>
                      </div>
                      <div style={{ marginTop: 6 }}>
                        <Select
                          options={timezoneOptions}
                          placeholder="Time Zone"
                          value={childOrganization.timezone}
                          onChange={handleOnChange("timezone")}
                          isDisabled
                        />
                      </div>
                    </div>
                  </div>
                </CustomCard>
              </div>

              <div className={styles.title_right_text}>Address</div>
              <div className={styles.card_view}>
                <CustomCard>
                  <div className="row">
                    <div className="col-md-6">
                      <div>
                        <label className={styles.title_text}>
                          Address Line 1
                        </label>
                      </div>
                      <div>
                        <input
                          className={styles.inputStyle}
                          type="text"
                          //name="personEmail"
                          //value={this.state.personFields["personEmail"]}
                          //onChange={this.handlePersonFieldsChange}
                          autoComplete="off"
                          maxLength="100"
                          placeholder="Address Line 1"
                          value={childOrganization.address_line_1}
                          onChange={handleOnTextChange("address_line_1")}
                        />
                        <span className={styles.errorMessage}>
                          {errors.address_line_1}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div>
                        <label className={styles.title_text}>
                          Address Line 2
                        </label>
                      </div>
                      <div>
                        <input
                          className={styles.inputStyle}
                          type="text"
                          //name="personEmail"
                          //value={this.state.personFields["personEmail"]}
                          //onChange={this.handlePersonFieldsChange}
                          autoComplete="off"
                          maxLength="100"
                          placeholder="Address Line 2"
                          value={childOrganization.address_line_2}
                          onChange={handleOnTextChange("address_line_2")}
                        />
                        <span className={styles.errorMessage}>
                          {errors.address_line_2}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <div>
                        <label className={styles.title_text}>City</label>
                      </div>
                      <div style={{ marginTop: 6 }}>
                        <AsyncSelect
                          name="search"
                          cacheOptions
                          isClearable
                          styles={style}
                          loadOptions={APIs.searchCountryStateByCityName}
                          onChange={handleOnChange("city")}
                          value={childOrganization.city}
                          placeholder="Search City by name"
                        />
                        <span className={styles.errorMessage}>
                          {errors.city}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div>
                        <label className={styles.title_text}>State</label>
                      </div>
                      <div style={{ marginTop: 6 }}>
                        <Select
                          styles={style}
                          options={stateOptions}
                          placeholder="State"
                          isDisabled={!childOrganization.country}
                          onChange={handleOnChange("state")}
                          value={childOrganization.state}
                        />
                        <span className={styles.errorMessage}>
                          {errors.state}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div>
                        <label className={styles.title_text}>Country</label>
                      </div>
                      <div style={{ marginTop: 6 }}>
                        <Select
                          options={countryOptions}
                          placeholder="Country"
                          styles={style}
                          value={childOrganization.country}
                          onChange={handleOnChange("country")}
                        />
                        <span className={styles.errorMessage}>
                          {errors.country}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div>
                        <label className={styles.title_text}>Zip</label>
                      </div>
                      <div>
                        <input
                          className={styles.inputStyle}
                          type="text"
                          autoComplete="off"
                          maxLength="100"
                          placeholder="Zip"
                          value={childOrganization.zipcode}
                          onChange={handleOnTextChange("zipcode")}
                        />
                        <span className={styles.errorMessage}>
                          {errors.zipcode}
                        </span>
                      </div>
                    </div>
                  </div>
                </CustomCard>
              </div>

              {isParentOrgAdmin() && (
                <>
                  <div style={{ marginTop: "1.875em" }}>
                    <CardTitle>Set Financial Year </CardTitle>
                    *(Current financial year is {fromDate.format(
                      "MMMM YYYY"
                    )} - {toDate.format("MMMM YYYY")})
                  </div>
                  <div className={styles.card_view}>
                    <CustomCard>
                      <div className="row" style={{ marginBottom: 10 }}>
                        <div className="col-12">
                          <CardTitle>Start Month</CardTitle>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <SingleSelect
                            disableSearch
                            key="financial_year"
                            options={MONTH_OPTIONS}
                            selected={selectedMonth}
                            onSelect={setSelectedMonth}
                          />
                        </div>
                        <div
                          className="col-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          Your financial year will be set to{" "}
                          {selectedFromDate.format("MMMM YYYY")} -{" "}
                          {selectedToDate.format("MMMM YYYY")}
                        </div>
                      </div>
                    </CustomCard>
                  </div>
                </>
              )}

              <div className="row">
                <div className="col-12">
                  <CropperDialog
                    cropperDialog={cropperDialog}
                    handleCloseCropDialog={handleCloseCropDialog}
                    cropImage={cropImage}
                    src={src}
                    ratio={true}
                  />
                </div>
              </div>

              <div className={styles.card_view}>
                <div className={styles.bottom_update_button}>
                  {/* Temporary hide this section - Do not delete below code */}
                  <OutLinedButton
                    onClick={handleCancelOnClick}
                    style={{ marginRight: "1.875em" }}
                  >
                    Cancel
                  </OutLinedButton>

                  <PrimaryButton
                    disabled={isUpdating}
                    onClick={handleUpdateOnClick}
                  >
                    Update
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EditChildOrg;
