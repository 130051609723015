import React, { useState, useEffect, Component } from "react";
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Checkbox,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SalesNewsDialogStyle from "./SalesNewsDialog.module.css";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  commonValidation_regex,
  commonValidationWithLineBreak_regex,
} from "../../common/common-types";
import PreviewNewsDialog from "./PreviewNewsDialog";
import "./PreviewNewsDialog.css";
import moment from "moment";
import DatePicker from "react-date-picker";
import styles from "../../pages/newLead/component/NewLead.module.scss";
import Chip from "@material-ui/core/Chip";
import MuiTextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Multiselect from "@khanacademy/react-multi-select";
import DropDownstyles from "./style.module.scss";
import styled from "styled-components";
import Api from "./Api";

const TextField = styled(MuiTextField)`
  & label.Mui-focused {
    color: #757575;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #c0c0c0;
      border-width: 1px;

      &:hover {
        border-color: #c0c0c0 !important;
        border-width: 1px !important;
      }
    }
  }
`;

function CustomCheckBox({ label, checked, onChange }) {
  return (
    <span style={{ paddingTop: 5, paddingBottom: 5 }} onClick={onChange}>
      <span className={DropDownstyles.checkboxContainer}>
        <span className={DropDownstyles.container}>
          <input type="checkbox" checked={checked} onChange={onChange} />
          <span className={DropDownstyles.checkmark} />
        </span>
        <span>{label}</span>
      </span>
    </span>
  );
}

function StudentItemRenderer(props) {
  const { checked, option, onClick, disabled } = props;

  const disabledStyle = disabled ? { opacity: "50%" } : {};

  return (
    <span>
      <span className={DropDownstyles.checkboxContainer}>
        <span className={DropDownstyles.container}>
          <input
            type="checkbox"
            checked={checked}
            onChange={disabled ? () => {} : onClick}
            disabled={disabled}
          />
          <span className={DropDownstyles.checkmark} style={disabledStyle} />
        </span>
        <span style={{ disabledStyle }}>{option.label}</span>
      </span>
    </span>
  );
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const remoteCallback = async (e, callBack) => {
  //if(e.target.files.length){
  let header = "";
  var fr = new FileReader();
  fr.onload = (e) => {
    var arr = new Uint8Array(e.target.result).subarray(0, 4);
    for (let i = 0; i < arr.length; i++) {
      header += arr[i].toString(16);
    }
    mimeType(header, callBack);
  };
  fr.readAsArrayBuffer(e.target.files[0]);
  //}
};

const mimeType = (headerString, callBackFn) => {
  if (headerString.startsWith("ffd8ff")) {
    headerString = headerString.slice(0, 6);
  }
  switch (headerString) {
    case "89504e47":
    case "ffd8ff":
      callBackFn(true);
      break;
    default:
      callBackFn(false);
      break;
  }
};

const regexCheck = (value, id) => {
  if (value.trim() !== "") {
    switch (id) {
      case "title":
        if (!value.match(commonValidation_regex)) {
          return false;
        }

        break;

      case "discription":
        if (!value.match(commonValidationWithLineBreak_regex)) {
          return false;
        }

        break;

      default:
        break;
    }
  }

  return true;
};

const labelMapper = (license) => ({
  value: license.id,
  label: license.display_name,
});

const disableAdmin = (license) => ({
  ...license,
  disabled: license.value === 6,
});

function useLicenseTypes() {
  const [licenseTypes, setLicenseTypes] = useState([]);

  useEffect(() => {
    fetchLicenseTypes();
  }, []);

  const fetchLicenseTypes = async () => {
    const values = await Api.getLicenseTypes();
    setLicenseTypes(values.map(labelMapper).map(disableAdmin));
  };

  return { licenseTypes };
}

const SalesNewsDialog = (props) => {
  const [previewNewsModal, setPreviewNewsModal] = useState(false);
  const [errorTitle, seterrorTitle] = useState(false);
  const [errorPatternTitle, seterrorPatternTitle] = useState(false);
  const [errorFile, seterrorFile] = useState(false);
  const [errorDiscription, seterrorDiscription] = useState(false);
  const [errorPatternDiscription, seterrorPatternDiscription] = useState(false);
  const [errorimageType, seterrorimageType] = useState(false);
  const [errorimageSize, seterrorimageSize] = useState(false);
  const [errorDate, setErrorDate] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { licenseTypes } = useLicenseTypes();

  const toggleCalendar = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const handleChange = (event) => {
    console.log("Change");
    if (!props.is_has_expiry) {
      setErrorDate(true);
    } else {
      setErrorDate(false);
    }
    props.setIs_has_expiry(!props.is_has_expiry);
  };

  const findCurrentOrg = () => {
    return {
      id: props.currentUser.organization_id,
      name: props.currentUser.organizationName,
    };
  };

  const disableChips = (fixedOptions, option) => {
    let index = fixedOptions.findIndex((opt) => opt.id === option.id);
    if (index !== -1) {
      return true;
    } else {
      return false;
    }
  };

  const fixedOptions = [findCurrentOrg()];
  // console.log('fixed opetions === > ', fixedOptions);
  // const [value, setValue] = React.useState([...fixedOptions]);

  return (
    <div>
      <Dialog
        className={SalesNewsDialogStyle.SalesNewsPopup}
        open={props.openModal ? props.openModal : false}
        onClose={() => {
          seterrorTitle(false);
          seterrorFile(false);
          seterrorDiscription(false);
          seterrorimageType(false);
          seterrorimageSize(false);
          seterrorPatternTitle(false);
          seterrorPatternDiscription(false);
          setErrorDate(false);

          props.onCloseNews();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <Grid container>
          <Grid item md={12} lg={12} xs={12} sm={12}>
            <DialogTitle
              className={
                SalesNewsDialogStyle.newsTitle + " sales-pop-header-space"
              }
              id="sales-news-title"
            >
              <span className={SalesNewsDialogStyle.newsTitle}>Post news</span>
              <IconButton
                className={SalesNewsDialogStyle.closeBtn}
                aria-label="Close"
                onClick={() => {
                  seterrorTitle(false);
                  seterrorDiscription(false);
                  seterrorFile(false);
                  seterrorimageType(false);
                  seterrorimageSize(false);
                  seterrorPatternTitle(false);
                  seterrorPatternDiscription(false);
                  setErrorDate(false);

                  props.onCloseNews();
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
          </Grid>

          <Grid item md={12} lg={12} xs={12} sm={12}>
            <Divider absolute={false} />
          </Grid>

          <Grid className={SalesNewsDialogStyle.dialogContents} container>
            <Grid item md={6} lg={6} xs={12} sm={12}>
              <div className={SalesNewsDialogStyle.newTitleLabelWrap}>
                <label htmlFor="title">
                  News title{" "}
                  <span className={SalesNewsDialogStyle.requiredSpan}>
                    {" *"}
                  </span>
                </label>
                <label
                  htmlFor="title"
                  className={SalesNewsDialogStyle.charTitle}
                >
                  {100 - props.title.length}
                  {" chars left"}
                </label>
              </div>
              <input
                autoFocus
                maxLength="100"
                onChange={(event) => {
                  seterrorTitle(false);
                  if (event.target.value.trim() === "") {
                    seterrorPatternTitle(true);
                  } else {
                    regexCheck(event.target.value, "title")
                      ? seterrorPatternTitle(false)
                      : seterrorPatternTitle(true);
                  }

                  props.setNewsTitle(event.target.value);
                }}
                id="title"
                type="text"
                value={props.title}
                className={
                  errorTitle
                    ? SalesNewsDialogStyle.inputField +
                      " " +
                      SalesNewsDialogStyle.errorBorder
                    : SalesNewsDialogStyle.inputField
                }
              />
              <FormHelperText className={SalesNewsDialogStyle.errorMessage}>
                {errorTitle
                  ? "This field is required"
                  : errorPatternTitle
                  ? "This field is invalid"
                  : ""}
              </FormHelperText>
            </Grid>

            <Grid item xs={12} md={6} lg={6} sm={12}>
              <Grid container>
                <label
                  htmlFor="inputImageLabel"
                  className={SalesNewsDialogStyle.uploadLabel}
                >
                  Upload image
                  <span className={SalesNewsDialogStyle.requiredSpan}>
                    {" *"}
                  </span>
                </label>
                <div className={SalesNewsDialogStyle.inputFileWrapper}>
                  <label
                    id="inputImageLabel"
                    className={
                      errorFile
                        ? SalesNewsDialogStyle.labelFileWrapper +
                          " " +
                          SalesNewsDialogStyle.errorBorder
                        : SalesNewsDialogStyle.labelFileWrapper
                    }
                    htmlFor="file"
                  >
                    <p className={SalesNewsDialogStyle.imgSpanSelect}>
                      {props.file && !errorFile
                        ? props.file.fileName
                        : "Select image"}
                    </p>
                    <span className={SalesNewsDialogStyle.browseFileButton}>
                      Browse
                    </span>
                  </label>
                  {errorFile ? (
                    <FormHelperText
                      className={SalesNewsDialogStyle.errorMessage}
                    >
                      {errorimageType
                        ? "The image file is invalid or image type is not allowed. Allowed types: jpg, jpeg, png"
                        : errorimageSize
                        ? "Image size exceeds the maximum limit. Maximum image size allowed is 10MB"
                        : "This field is required"}{" "}
                    </FormHelperText>
                  ) : (
                    <FormHelperText
                      className={SalesNewsDialogStyle.imageHelperText}
                    >
                      Upload only .JPG, .PNG file, Total file size should not
                      exceed 10MB
                    </FormHelperText>
                  )}
                  <input
                    type="file"
                    name="file"
                    id="file"
                    onChange={(event) => {
                      event.persist();
                      remoteCallback(event, (imgValue) => {
                        if (
                          imgValue === true &&
                          event.target.files.length &&
                          props.maxSize > event.target.files[0].size &&
                          props.accept.indexOf(
                            event.target.files[0].type.split("/")[1]
                          ) !== -1
                        ) {
                          seterrorFile(false);
                          seterrorimageType(false);
                          seterrorimageSize(false);
                          props.onChange(event, true);
                          event.target.value = "";
                        } else if (
                          event.target.files.length &&
                          (imgValue === false ||
                            props.accept.indexOf(
                              event.target.files[0].type.split("/")[1]
                            ) == -1)
                        ) {
                          seterrorFile(true);
                          seterrorimageType(true);
                          seterrorimageSize(false);
                          props.onChange(event, false);
                          event.target.value = "";
                        } else if (event.target.files.length === 0) {
                          seterrorFile(false);
                          seterrorimageType(false);
                          seterrorimageSize(false);
                          props.onChange(event, false);
                          event.target.value = "";
                        } else {
                          seterrorFile(true);
                          seterrorimageType(false);
                          seterrorimageSize(true);
                          props.onChange(event, false);
                          event.target.value = "";
                        }
                      });
                    }}
                    multiple={props.multiple}
                    name="_photos"
                    accept={props.accept}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid
              className={SalesNewsDialogStyle.newsDescriptionContainer}
              item
              xs={12}
              md={12}
              lg={12}
              sm={12}
            >
              <label htmlFor="discription">
                News description
                <span className={SalesNewsDialogStyle.requiredSpan}>
                  {" *"}
                </span>
              </label>
              <textarea
                rows="5"
                cols="70"
                style={{ resize: "none" }}
                className={
                  errorDiscription
                    ? SalesNewsDialogStyle.discriptionTextArea +
                      " " +
                      SalesNewsDialogStyle.errorBorder
                    : SalesNewsDialogStyle.discriptionTextArea
                }
                id="discription"
                onChange={(event) => {
                  seterrorDiscription(false);
                  if (event.target.value.trim() === "") {
                    seterrorPatternDiscription(true);
                  } else {
                    regexCheck(event.target.value, "discription")
                      ? seterrorPatternDiscription(false)
                      : seterrorPatternDiscription(true);
                  }
                  props.setNewDiscription(event.target.value);
                }}
                value={props.discription && props.discription}
                type="text"
              />

              <FormHelperText className={SalesNewsDialogStyle.errorMessageText}>
                {errorDiscription
                  ? "This field is required"
                  : errorPatternDiscription
                  ? "This field is invalid"
                  : " "}
              </FormHelperText>
            </Grid>
            {/* Organization */}
            {props.currentUser.enable_publishing_news && (
              <Grid
                className={SalesNewsDialogStyle.newsDescriptionContainer}
                item
                xs={12}
                md={12}
                lg={12}
                sm={12}
              >
                {console.log("@@@@@@@@@", props.organizationArray)}
                <Autocomplete
                  multiple
                  id="checkboxes"
                  options={props.orgList}
                  value={props.organizationArray}
                  onChange={(event, newValue) => {
                    console.log("====================================");
                    console.log(newValue);
                    console.log("====================================");
                    props.setOrganizationArray([
                      ...fixedOptions,
                      ...newValue.filter((option) => {
                        let index = fixedOptions.findIndex(
                          (opt) => opt.id === option.id
                        );
                        if (index === -1) {
                          return true;
                        } else {
                          return false;
                        }
                      }),
                    ]);
                    // setValue();
                  }}
                  // disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  // getOptionSelected={(option) => option.id === value[0].id}
                  getOptionDisabled={(option) => {
                    return disableChips(fixedOptions, option);
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <CustomCheckBox checked={selected} label={option.name} />
                    </React.Fragment>
                  )}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        label={option.name}
                        {...getTagProps({ index })}
                        disabled={disableChips(fixedOptions, option)}
                        // fixedOptions.indexOf(option) !== -1}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Publish news to"
                      placeholder="Select Organization"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
            )}

            {/* New Section */}
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
              sm={12}
              className={SalesNewsDialogStyle.expiryDateCheckBox}
              style={{ paddingTop: 0 }}
            >
              <label
                htmlFor="can_be_seen_by"
                className={SalesNewsDialogStyle.uploadLabel}
                style={{ marginLeft: 0 }}
              >
                Can Be Seen By
              </label>
              <div
                className={SalesNewsDialogStyle.inputFileWrapper}
                style={{ marginLeft: 0 }}
              >
                <Multiselect
                  options={licenseTypes}
                  onSelectedChanged={(selectedOptions) => {
                    const canBeSeenBy =
                      selectedOptions.length === 0 ? [6] : selectedOptions;

                    props.onLicenseTypeArrayChange(canBeSeenBy);
                  }}
                  selected={props.licenseTypeArray}
                  hasSelectAll={true}
                  disableSearch={true}
                  overrideStrings={{
                    selectSomeItems: "Select option",
                    allItemsAreSelected: "Public",
                    selectAll: "Public",
                  }}
                  ItemRenderer={StudentItemRenderer}
                />
              </div>
            </Grid>

            <Grid
              item
              md={6}
              lg={6}
              xs={12}
              sm={12}
              className="p_position remove_scrollY_pop_date"
            >
              {/* Date */}

              <label
                htmlFor="inputImageLabel"
                className={SalesNewsDialogStyle.uploadLabel}
              >
                Expiry Date
              </label>
              <div
                className={SalesNewsDialogStyle.inputFileWrapper}
                style={{ marginBottom: 15 }}
              >
                <CustomCheckBox
                  checked={props.is_has_expiry}
                  onChange={handleChange}
                  label="This news has expiry date"
                />
              </div>
              <div className={SalesNewsDialogStyle.inputFileWrapper}>
                <input
                  placeholder="dd-mmm-yyyy"
                  value={
                    props.expiry_date
                      ? moment(props.expiry_date).format("DD-MMM-YYYY")
                      : ""
                  }
                  className={
                    SalesNewsDialogStyle.inputStyle + " add_cursor_pointer"
                  }
                  onClick={toggleCalendar}
                  disabled={!props.is_has_expiry}
                  //   onChange={dateChangeHandler}
                />
                <DatePicker
                  calendarClassName={styles.dateLayout}
                  disabled={!props.is_has_expiry}
                  value={
                    props.expiry_date !== ""
                      ? new Date(
                          moment(props.expiry_date).year(),
                          moment(props.expiry_date).month(),
                          moment(props.expiry_date).date()
                        )
                      : props.expiry_date
                  }
                  clearIcon={null}
                  calendarIcon={null}
                  isOpen={isOpen}
                  onCalendarClose={toggleCalendar}
                  onChange={(event) => {
                    setErrorDate(false);
                    props.setExpiry_date(moment(event).format("YYYY-MM-DD"));
                  }}
                  minDate={new Date(moment().add(1, "days"))}
                  maxDate={new Date(moment().add(20, "years"))}
                />

                <FormHelperText className={SalesNewsDialogStyle.errorMessage}>
                  {errorDate ? "This field is required" : ""}
                </FormHelperText>
              </div>
              <i className={SalesNewsDialogStyle.date_picker_icon}></i>
            </Grid>

            <Grid
              justify="flex-end"
              container
              className={SalesNewsDialogStyle.bottomBar}
            >
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sm={12}
                className={SalesNewsDialogStyle.psticky}
              >
                {
                  <Button
                    className={`${SalesNewsDialogStyle.btnAll + " pstatic"} 
                                ${SalesNewsDialogStyle.resetBtn}`}
                    variant="contained"
                    onClick={() => {
                      seterrorTitle(false);
                      seterrorFile(false);
                      seterrorDiscription(false);
                      seterrorimageType(false);
                      seterrorimageSize(false);
                      seterrorPatternTitle(false);
                      seterrorPatternDiscription(false);
                      setErrorDate(false);
                      props.resetFormData();
                    }}
                  >
                    {props.resetButtonText}
                  </Button>
                }
                <Button
                  className={`${SalesNewsDialogStyle.btnAll + " pstatic"} 
                                    ${SalesNewsDialogStyle.publishBtn}`}
                  variant="contained"
                  onClick={() => {
                    if (
                      props.title &&
                      props.discription &&
                      props.file &&
                      !errorPatternDiscription &&
                      !errorPatternTitle
                    ) {
                      if (props.is_has_expiry && props.expiry_date) {
                        seterrorTitle(false);
                        seterrorDiscription(false);
                        seterrorFile(false);
                        seterrorPatternTitle(false);
                        seterrorPatternDiscription(false);
                        setErrorDate(false);
                        props.publishNews(props.currentUser.user_id);
                      } else if (!props.is_has_expiry) {
                        seterrorTitle(false);
                        seterrorDiscription(false);
                        seterrorFile(false);
                        seterrorPatternTitle(false);
                        seterrorPatternDiscription(false);
                        setErrorDate(false);
                        props.publishNews(props.currentUser.user_id);
                      }
                    }
                    if (!props.title) {
                      seterrorTitle(true);
                    }
                    if (!props.discription) {
                      seterrorDiscription(true);
                    }
                    if (!props.file) {
                      seterrorFile(true);
                    }

                    if (props.is_has_expiry && !props.expiry_date) {
                      setErrorDate(true);
                    }
                  }}
                >
                  {props.publishButtonText}
                </Button>
                {props.saveToDrafts && (
                  <Button
                    className={`${SalesNewsDialogStyle.btnAll + " pstatic"}
                                 ${SalesNewsDialogStyle.saveBtn}`}
                    variant="outlined"
                    onClick={() => {
                      if (
                        props.title &&
                        props.discription &&
                        props.file &&
                        !errorPatternDiscription &&
                        !errorPatternTitle
                      ) {
                        seterrorTitle(false);
                        seterrorDiscription(false);
                        seterrorFile(false);
                        seterrorPatternTitle(false);
                        seterrorPatternDiscription(false);
                        props.saveToDrafts(props.currentUser.user_id);
                      }
                      if (!props.title) {
                        seterrorTitle(true);
                      }
                      if (!props.discription) {
                        seterrorDiscription(true);
                      }
                      if (!props.file) {
                        seterrorFile(true);
                      }
                      if (props.is_has_expiry && !props.expiry_date) {
                        setErrorDate(true);
                      }
                    }}
                  >
                    {props.saveToDraftsButtonText}
                  </Button>
                )}
                <Button
                  className={`${SalesNewsDialogStyle.btnAll + " pstatic"} 
                                ${SalesNewsDialogStyle.previewBtn}`}
                  variant="text"
                  onClick={() => {
                    if (
                      props.title &&
                      props.discription &&
                      props.file &&
                      !errorPatternDiscription &&
                      !errorPatternTitle
                    ) {
                      seterrorTitle(false);
                      seterrorDiscription(false);
                      seterrorFile(false);
                      seterrorPatternTitle(false);
                      seterrorPatternDiscription(false);
                      setPreviewNewsModal(!previewNewsModal);
                    }
                    if (!props.title) {
                      seterrorTitle(true);
                    }
                    if (!props.discription) {
                      seterrorDiscription(true);
                    }
                    if (!props.file) {
                      seterrorFile(true);
                    }
                    if (props.is_has_expiry && !props.expiry_date) {
                      setErrorDate(true);
                    }
                  }}
                >
                  {props.previewButtonText}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
      <PreviewNewsDialog
        onClosePreview={() => setPreviewNewsModal(!previewNewsModal)}
        previewNewsDialog={previewNewsModal}
        imagePreview={props.file && props.file.fileBody}
        title={props.title}
        is_has_expiry={props.is_has_expiry}
        expiry_date={props.expiry_date}
        discription={props.discription}
        userAvatar={props.currentUser && props.currentUser.user_avatar}
        userName={props.currentUser && props.currentUser.name}
      />
    </div>
  );
};
export default SalesNewsDialog;

SalesNewsDialog.defaultProps = {
  // saveToDrafts: () => console.log("saveToDrafts"),
  // resetFormData: () => console.log("resetFormData"),
  // previewNews: () => console.log("previewNews"),
  multiple: false,
  accept: "image/jpeg, image/png",
  maxSize: 10485760,
  resetButtonText: "Reset",
  saveToDraftsButtonText: "Save as draft",
  publishButtonText: "Publish",
  previewButtonText: "Preview",
};
