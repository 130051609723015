import * as types from "./types";

const initialState = {
  averageVelocityData: 0,
  LeadsByMonthData: null,
  revenueByMonthData: null

};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'AVERAGE_VELOCITY_SUCCESS':
      return {
        ...state,
        averageVelocityData: action.data
      };
    case 'AVERAGE_VELOCITY_ERROR':
      return {
        ...state,
        averageVelocityData: 0
      };

    case 'LEADBYMONTH_SUCCESS':
      return {
        ...state,
        LeadsByMonthData: action.data
      };

    case 'LEADBYMONTH_ERROR':
      return {
        ...state,
        LeadsByMonthData: null
      };


    case 'REVENUEBYMONTH_SUCCESS':
      return {
        ...state,
        revenueByMonthData: action.data
      };

    case 'REVENUEBYMONTH_ERROR':
      return {
        ...state,
        revenueByMonthData: null
      };
    default:
      return state;
  }
};

export default reducer;