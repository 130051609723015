import React, { useState, useRef } from "react";
import styles from "./select.module.scss";
import useOutsideClick from "../../hooks/outsideClick";
// import from '.'

function Select({
  options,
  onSelect,
  selected,
  allOption,
  disableSearch,
  placeholder = '',
  disabled=false,
  fullWidth = false,
  customStyle = {},
  liStyle = {},
  headingStyle={},
  labelStyle = {},
  dropDownContentStyle = {},
  textStyle = {}
}) {
  const [isOpenDropdownContent, setIsOpenDropdownContent] = useState();
  const [isFilterDropdownContent, setIsFilterDropdownContent] = useState("");
  const ref = useRef(null);

  const openDropdownContent = () => {
    !disabled && setIsOpenDropdownContent(isOpenDropdownContent ? false : true);
  };

  useOutsideClick(ref, () => {
    setIsOpenDropdownContent(false);
  });

  const handleOnSelect = (...params) => {
    setIsOpenDropdownContent(false);
    onSelect(...params);
  }

  return (
    <>
      <div
        className={
          "col-md-12 " +
          styles.selectOrgOptions +
          " " +
          (fullWidth && styles.fullWidth)
        }
        ref={ref}
        style={{ display: "inline-block", ...customStyle, opacity: disabled ? '50%' : '100%' }}
        onClick={(e) => {
          if (disabled) {
            e.stopPropagation();
          }
        }}
      >
        <div>
          <div className={styles.cust_dropdown}>
            <div
              className={
                isOpenDropdownContent
                  ? styles.cust_dropdown_heading_open
                  : styles.cust_dropdown_heading
              }
              onClick={openDropdownContent}
              style={{...headingStyle, background: disabled ? '#E6E6E6': 'transparent'}}
            >
              <span className={styles.cust_dropdown_heading_value}>
                {!selected && placeholder}
              </span>
              <span className={styles.cust_dropdown_heading_value} style={textStyle}>
                {selected && selected.label}
              </span>
              <span className={styles.cust_dropdown_heading_dropdown_arrow}>
                {isOpenDropdownContent ? (
                  <span className={styles.cust_up_arrow}></span>
                ) : (
                  <span className={styles.cust_dwn_arrow}></span>
                )}
              </span>
            </div>
            {isOpenDropdownContent && (
              <div className={styles.cust_dropdown_content} style={dropDownContentStyle}>
                <div className={styles.cust_select_panel}>
                  {!disableSearch && (
                    <div className={styles.cust_search_box}>
                      <input
                        className={styles.cust_search_input}
                        value={isFilterDropdownContent}
                        onChange={(e) =>
                          setIsFilterDropdownContent(e.target.value)
                        }
                        placeholder="Search here"
                        type="text"
                      />
                    </div>
                  )}
                  <ul className={styles.cust_select_list}>
                    {allOption && (
                      <li
                        className={styles.cust_select_li}
                        onClick={() => handleOnSelect(allOption)}
                      >
                        {selected && selected.value === 0 ? (
                          <label className={styles.cust_item} style={labelStyle}>
                            <b>{allOption.label}</b>
                          </label>
                        ) : (
                          <label className={styles.cust_item} style={labelStyle}>
                            {allOption.label}
                          </label>
                        )}
                      </li>
                    )}
                    {options
                      .filter((option) =>
                        option.label
                          .toLowerCase()
                          .includes(isFilterDropdownContent.toLowerCase())
                      )
                      .map((item) => (
                        <li
                          key={item.value}
                          className={
                            selected && selected.value !== item.value
                              ? styles.cust_select_li
                              : styles.cust_select_item
                          }
                          style={liStyle}
                          onClick={() => handleOnSelect(item)}
                        >
                          {selected && selected.value === item.value ? (
                            <label className={styles.cust_item} style={labelStyle}>
                              <b>{item.label}</b>
                            </label>
                          ) : (
                            <label className={styles.cust_item} style={labelStyle}>
                              {item.label}
                            </label>
                          )}
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Select;
