import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "./redux/actions";
import ConfigureDataObjsMapCRMComponent from "./component/ConfigureDataObjsMapCRMComponent";

class ConfigureDataObjsMapCRMContainer extends Component {
    render() {
        const configureDataObjsMapCRMComponentProps = {...this.props };
        return <ConfigureDataObjsMapCRMComponent {...configureDataObjsMapCRMComponentProps }
        />;
    }
}

const mapStateToProps = state => {
    //console.log("state in mapptoprops", state);
    return {
        ...state,
        idcrm_integration: state.CRMConfigureDetails.idcrm_integration,
        idcrm_master: state.CRMIntegrationDetails.idcrm_master,
        crm_name: state.CRMIntegrationDetails.crm_name,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setStatusMapping: (fieldMapping) =>
        dispatch(actions.setStatusMapping(fieldMapping)),
        setLeadLeadMappingSelected: (leadLeadMappingSelected) => 
        dispatch(actions.setLeadLeadMappingSelected(leadLeadMappingSelected))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConfigureDataObjsMapCRMContainer);