import * as types from "./types";

const initialState = {
  lead: null,
  leadUpdated: false,
  leadAdded: false,
  // personList: null,
  // personCount: 0,
  leadSourceList: null,
  leadSourceCount: 0,
  leadCategoryList: null,
  leadCategoryCount: 0,
  currencyList: null,
  currencyCount: 0,
  assignedToList: null,
  orgChildList: {options: [], disabled: true},
  assignedToCount: 0,
  errorMessage: "",
  errorLeadSourceList: false,
  errorLeadCategoryList: false,
  errorAddNewLead: false,
  errorUpdateLead: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "RESET_LEAD":
      return {
        ...state,
        lead: null,
        errorMessage: "",
        errorLeadSourceList: false,
        errorAddNewLead: false,
        errorUpdateLead: false,
      };

    case types.ADD_NEW_LEAD_SUCCESS:
      return {
        ...state,
        lead: true,
        leadAdded: action.data,
        leadUpdated: false,
        errorMessage: "",
      };

    case types.ADD_NEW_LEAD_FAILED:
      return {
        ...state,
        lead: null,
        errorMessage: action.errorMessage,
        errorAddNewLead: true,
      };

    case types.UPDATE_NEW_LEAD_SUCCESS:
      return {
        ...state,
        lead: true,
        leadUpdated: action.data,
        leadAdded: false,
        errorMessage: "",
      };

    case types.UPDATE_NEW_LEAD_FAILED:
      return {
        ...state,
        lead: null,
        leadUpdated: false,
        errorMessage: action.errorMessage,
        errorUpdateLead: true,
      };

    // case 'PERSON_LIST_SUCCESS':
    //   return{
    //     ...state,
    //     personList: action.data.rows,
    //     personCount: action.data.count,
    //   };

    // case 'PERSON_LIST_ERROR':
    //   return{
    //     ...state,
    //     personList: null,
    //     personCount: 0,
    //     errorMessage: action.errorMessage
    //   };

    // case 'RESET_PERSON_LIST':
    //   return {
    //     ...state,
    //     personList: null,
    //     personCount: 0
    //   }
    case "LEAD_SOURCE_LIST_SUCCESS":
      return {
        ...state,
        leadSourceList: action.data.rows,
        leadSourceCount: action.data.count,
        errorMessage: "",
        errorLeadSourceList: false,
      };

    case "LEAD_SOURCE_LIST_ERROR":
      return {
        ...state,
        leadSourceList: null,
        leadSourceCount: 0,
        errorMessage: action.errorMessage,
        errorLeadSourceList: true,
      };

    case "CATEGORY_SOURCE_LIST_SUCCESS":
      return {
        ...state,
        leadCategoryList: action.data.rows,
        leadCategoryCount: action.data.count,
        errorMessage: "",
        errorLeadCategoryList: false,
      };

    case "CATEGORY_SOURCE_LIST_ERROR":
      return {
        ...state,
        leadCategoryList: null,
        leadCategoryCount: 0,
        errorMessage: action.errorMessage,
        errorLeadCategoryList: true,
      };

    case "CURRENCY_LIST_SUCCESS":
      return {
        ...state,
        currencyList: action.data.rows,
        currencyCount: action.data.count,
        errorMessage: "",
      };

    case "CURRENCY_LIST_ERROR":
      return {
        ...state,
        currencyList: null,
        currencyCount: 0,
        errorMessage: action.errorMessage,
      };

    case "ASSIGNEDTO_LIST_SUCCESS":
      return {
        ...state,
        assignedToList: action.data.rows,
        assignedToCount: action.data.count,
        errorMessage: "",
      };
    case "ASSIGNEDTO_LIST_ERROR":
      return {
        ...state,
        assignedToList: null,
        assignedToCount: 0,
        errorMessage: action.errorMessage,
      };

    case types.GET_ORG_CHILD_SUCCESS:
      return {
        ...state,
        orgChildList: action.data,
        errorMessage: "",
      };
    case types.GET_ORG_CHILD_FAILED:
      return {
        ...state,
        orgChildList: {options: [], disabled: false},
        errorMessage: action.errorMessage,
      };
    default:
      return state;
  }
};

export default reducer;
