import axios from "../../common/api-config";
export default {
  getPendingUsers(search_text) {
    return axios.post('/users/getPendingUsers', { search_text }).then(response => {
      return response.data;
    });
  },
  updateUserApproval(user_approval, user_id, license_type_id, account_id, department_id) {
    const body =  { user_approval, user_id, license_type_id, account_id, department_id };
    return axios.post('/users/updateUserApproval', body).then(response => {
      return response.data;
    })
  },
  getOrganizationList() {
    return axios.post('/account/getOrganizationList', {}).then(response => response.data);
  },
  getLicenses() {
    return axios.post('/account/getLicenses', {}).then(response => response.data);
  },
  getAllDepartments() {
    return axios.post('/department/get', {}).then(response => response.data);
  },
  getAllRoles() {
    return axios.post('/master/getRoles', {}).then(response => response.data);
  },
  getMyAccount() {
    const url = '/users/getMyAccount';
    return axios.post(url, {}).then(response => {
      return response.data.data.data.name;
    });
  }
}