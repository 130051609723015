import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import { css } from '@emotion/core';
import styles from './pulse-loader.module.scss';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class LargeScreenLoader extends React.Component{

    render(){
        return (
            <div className={styles.sweet_loading}>
                <PulseLoader
                    css={override}
                    sizeUnit={"px"}
                    size={25}
                    color={'#5fda8e'}
                />   
            </div>
        );
    }
}

export default LargeScreenLoader;