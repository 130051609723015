import React from "react";
import styles from '../NewContact.module.scss';
import AutoComplete from '../../../common/AutoComplete/autocomplete';
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import axiosInstance from "../../../common/api-config";
import axios from "axios";
import CircularProgress from "../../../common/Loaders/CircularLoader/circular-loader";
import { emailPattern, companyLeadName_regex, companyCode_regex, zipCode_regex, firstName_regex, lastName_regex, commonValidation_regex, commonValidationWithLineBreak_regex } from "../../../common/common-types";
import { openSnackbar } from "../../../common/Snackbar/snackbar";
import SmallScaleLoader from "../../../common/Loaders/SyncLoader/sync-loader";
import axiosCRM from '../../../common/api-configCRM';

const DialogContent = withStyles(theme => ({
    root: {
        // width: '950px',
        margin: 0,
        padding: theme.spacing.unit * 2
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        // width: '950px',
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit
    }
}))(MuiDialogActions);


class Company extends React.Component {
    constructor(props) {
        super(props);
        this.textInput = null;
        this.state = {
            companyFields: {
                companyName: "",
                companyCode: "",
                companyOfficeAddress1: "",
                companyOfficeAddress2: "",
                zipcode: "",
                companyCountry: 0,
                companyState: 0,
                companyCity: 0,
                companyWebSite: "http://",
                companyContactPersonName: "",
                contact_country_code: '',
                companyContactPersonPhoneNumber: "",
                companyContactPersonEmail: "",
                companyNote: "",
                first_name: "",
                last_name: "",
                email: "",
                country_code: "",
                phone_number: "",
                is_city: "",
                asyncCompanyList: []
            },
            companyErrors: {
                companyCode: "",
                companyOfficeAddress1: "",
                companyOfficeAddress2: "",
                zipcode: "",
                companyCountry: "",
                companyState: "",
                companyCity: "",
                companyWebSite: "",
                companyContactPersonName: "",
                contact_country_code: '',
                companyContactPersonPhoneNumber: "",
                companyContactPersonEmail: "",
                companyNote: "",
                first_name: "",
                last_name: "",
                email: "",
                country_code: "",
                phone_number: "",
                companyNameId: ""
            },
            codeValid: null,
            resetState: false,
            resetCity: false,
            submitButton: false,
            submitButtonOnblur: false,
            add_contact: false,
            updatedStateList: [],
            loadingCompanyList: false,
            submitButtonBlur: false
        }
    }


    componentDidMount() {
        // this.textInput.focus();
        if (this.props.currentCompany) {
            let currentCompany = this.props.currentCompany;
            this.setState({
                value: this.props.value,
                companyFields: {
                    companyNameId: currentCompany.id,
                    companyName: currentCompany.company_name,
                    asyncCompanyList: [{ ...currentCompany }],
                    companyCode: currentCompany.company_code ? currentCompany.company_code : "",
                    companyOfficeAddress1: currentCompany.office_address_line_1 ? currentCompany.office_address_line_1 : "",
                    companyOfficeAddress2: currentCompany.office_address_line_2 ? currentCompany.office_address_line_2 : "",
                    zipcode: currentCompany.zipcode ? currentCompany.zipcode : "",
                    companyCountry: currentCompany.country_id ? currentCompany.country_id : 0,
                    companyState: currentCompany.state_id ? currentCompany.state_id : currentCompany.country && currentCompany.country.is_city == 1 ? "other" : null,
                    companyCity: currentCompany.city_id ? currentCompany.city_id : 0,
                    companyWebSite: currentCompany.website ? currentCompany.website : "http://",
                    companyContactPersonName: currentCompany.contact_person_name ? currentCompany.contact_person_name : "",
                    contact_country_code: currentCompany.countryCode ? currentCompany.countryCode.id : "",
                    companyContactPersonPhoneNumber: currentCompany.contact_person_phone ? currentCompany.contact_person_phone.toString() : "",
                    companyContactPersonEmail: currentCompany.contact_person_email ? currentCompany.contact_person_email : "",
                    companyNote: currentCompany.notes ? currentCompany.notes : '',
                    is_city: currentCompany.country ? currentCompany.country["is_city"] : ""

                }
            });

            if (currentCompany.country_id) {
                this.props.getAllStates(currentCompany.country_id);


            }

            // NOTE: needs to be revisited
            let companyStat = currentCompany.state_id ? currentCompany.state_id : currentCompany.country && currentCompany.country.is_city == 1 ? "other" : null

            if (currentCompany.country_id && companyStat) {

                this.props.getAllCities(
                    currentCompany.country_id,
                    companyStat
                    //NOTE: to be rectified

                    //currentCompany.state_id,
                    // currentCompany["country"]["is_city"],

                );
            }
        }
    }

    componentWillUnmount() {
        //clearing state and city data from redux
        this.props.resetStateCity();
    }

    // company fields on change
    handleCompanyFieldsChange = async (e) => {
        let { name, value } = '';
        if (e && e.target) {
            name = e.target.name;
            value = e.target.value;
        } else if (e && e.name) {
            name = e.name;
            value = e.value;
        }
        let companyFields = { ...this.state.companyFields };
        let companyErrors = { ...this.state.companyErrors };
        let submitButtonOnblur = false;

        if (
            name === "companyCountry" ||
            name === "companyState" ||
            name === "companyCity"
        ) {
            if (value === "") {
                value = 0;
            }
            else if (value === "other") {
                value = "other"
            }
            else {
                value = parseInt(value);
            }
        }

        switch (name) {
            case "companyName":
                if (typeof value === "object") {
                    companyFields["companyName"] = value.company_name ? value.company_name : "";
                    companyFields["companyCode"] = value.company_code ? value.company_code : "";
                    this.onCompanyCodeEnter(companyFields["companyCode"]);

                    companyFields["companyOfficeAddress1"] = value.office_address_line_1 ? value.office_address_line_1 : "";
                    companyFields["zipcode"] = value.zipcode ? value.zipcode : "";
                    companyFields["companyCountry"] = value.country_id ? value.country_id : 0;

                    companyFields["companyState"] = 0;
                    companyFields["companyCity"] = 0;
                    this.props.resetStateCity();
                    await this.setState({
                        resetState: true,
                        resetCity: true
                    });
                    if (companyFields["companyCountry"] !== 0) {
                        this.props.getAllStates(companyFields["companyCountry"]);
                        //prepopulate country_code
                        if (this.props.countryList) {
                            let selectedCountry = this.props.countryList.filter((country, index) => {
                                return country.id == companyFields["companyCountry"];
                            });
                            if (selectedCountry && selectedCountry[0] && selectedCountry[0].id) {
                                companyFields["contact_country_code"] = selectedCountry[0].id;
                                companyFields["is_city"] = selectedCountry[0].is_city;

                            }
                        }
                    }
                    companyFields["companyWebSite"] = value.website ? value.website : "http://";

                    companyErrors["companyName"] = "";
                    companyErrors["companyCode"] = "";
                    companyErrors["companyOfficeAddress1"] = "";
                    companyErrors["zipcode"] = "";
                    companyErrors["companyCountry"] = "";
                    companyErrors["companyWebSite"] = "";
                }
                // else if (!value.match(commonValidation_regex)) { // /^(?![0-9]*$)[a-zA-Z0-9]+$/
                //     // \w matches letters, digits, and underscores
                //     // \s matches spaces, tabs, and line breaks
                //     // - matches the hyphen
                //     companyFields["companyName"] = value;
                //     companyErrors["companyName"] = "Please enter valid company name";
                // } else {
                //     companyFields["companyName"] = value;
                //     companyErrors["companyName"] = "";
                // }
                break;

            case "companyCode":
                if (value.trim() === "") {
                    submitButtonOnblur = false;
                    companyFields["companyCode"] = value;
                    companyErrors["companyCode"] = "";
                } else if (!value.match(commonValidation_regex)) {
                    companyFields["companyCode"] = value;
                    companyErrors["companyCode"] = "Please enter valid company code";
                } else {
                    submitButtonOnblur = true;
                    companyFields["companyCode"] = value;
                    companyErrors["companyCode"] = "";
                }
                break;

            case "companyOfficeAddress1":
                if (value.trim() === "") {
                    companyFields["companyOfficeAddress1"] = value;
                    companyErrors["companyOfficeAddress1"] = "";
                }
                else if (!value.match(commonValidation_regex)) {
                    companyFields['companyOfficeAddress1'] = value;
                    companyErrors['companyOfficeAddress1'] = "Please enter valid address"
                }
                else {
                    companyFields["companyOfficeAddress1"] = value;
                    companyErrors["companyOfficeAddress1"] = "";
                }
                break;

            case "companyOfficeAddress2":
                if (value.trim() === "") {
                    companyFields["companyOfficeAddress2"] = value;
                    companyErrors["companyOfficeAddress2"] = "";
                }
                else if (!value.match(commonValidation_regex)) {
                    companyFields['companyOfficeAddress2'] = value;
                    companyErrors['companyOfficeAddress2'] = "Please enter valid address"
                }
                else {
                    companyFields["companyOfficeAddress2"] = value;
                    companyErrors["companyOfficeAddress2"] = "";
                }
                break;

            case "zipcode":
                // if (value.trim() === "") {
                //     companyFields["zipcode"] = value;
                //     companyErrors["zipcode"] =
                //         "Please enter company postal address";
                // } else {
                if (!value.match(zipCode_regex)) {
                    companyFields["zipcode"] = value;
                    companyErrors["zipcode"] = "Please enter valid zip code";
                } else {
                    companyFields["zipcode"] = value;
                    companyErrors["zipcode"] = "";
                }

                // }
                break;

            case "companyCountry":
                // if (value === 0) {
                //     companyFields["companyCountry"] = value;
                //     companyErrors["companyCountry"] = "Please select company country";
                // } else {
                companyFields["companyCountry"] = value;
                companyFields["companyState"] = 0;
                companyFields["companyCity"] = 0;
                companyErrors["companyCountry"] = "";

                this.props.resetStateCity();

                await this.setState({
                    resetState: true,
                    resetCity: true
                })

                this.props.getAllStates(value);

                //prepopulate country_code
                if (this.props.countryList) {
                    let selectedCountry = this.props.countryList.filter((country, index) => {
                        return country.id == value;
                    });
                    if (selectedCountry && selectedCountry[0] && selectedCountry[0].id) {
                        companyFields["contact_country_code"] = selectedCountry[0].id;
                        companyFields["is_city"] = selectedCountry[0].is_city;

                    }
                }

                break;

            case "companyState":
                // if (value === 0) {
                //     companyFields["companyState"] = value;
                //     companyErrors["companyState"] = "Please select company state";
                // } else {
                companyFields["companyState"] = value;
                companyFields["companyCity"] = 0;
                companyErrors["companyState"] = "";
                this.props.resetCity();
                await this.setState({
                    resetState: false,
                    resetCity: true
                })
                this.props.getAllCities(
                    this.state.companyFields.companyCountry,
                    value,
                    // companyFields["is_city"]
                );
                // }
                break;

            case "companyCity":
                // if (value === 0) {
                //     companyFields["companyCity"] = value;
                //     companyErrors["companyCity"] = "Please select company city";
                // } else {
                await this.setState({
                    resetCity: false
                })
                companyFields["companyCity"] = value;
                companyErrors["companyCity"] = "";
                // }
                break;

            case "companyWebSite":
                // if (value.trim() === "") {
                //     companyFields["companyWebSite"] = value;
                //     companyErrors["companyWebSite"] = "Please enter company url";
                // } else 
                if (value.trim() === "" || value === "http://") {
                    companyFields["companyWebSite"] = value;
                    companyErrors["companyWebSite"] = "";
                } else if (
                    !value.trim().match(
                        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
                    )
                ) {
                    companyFields["companyWebSite"] = value;
                    companyErrors["companyWebSite"] = "Please enter valid company url";
                } else {
                    companyFields["companyWebSite"] = value;
                    companyErrors["companyWebSite"] = "";
                }
                break;

            case "companyContactPersonName":
                if (value.trim() === "") {
                    submitButtonOnblur = false;
                    companyFields["companyContactPersonName"] = value;
                    companyErrors["companyContactPersonName"] = "";
                } else if (!value.match(commonValidation_regex)) {       ///^[a-zA-ZæÆøØåÅ ]+$/
                    companyFields["companyContactPersonName"] = value;
                    companyErrors["companyContactPersonName"] = "Please enter valid name";
                } else {
                    submitButtonOnblur = true;
                    companyFields["companyContactPersonName"] = value;
                    companyErrors["companyContactPersonName"] = "";
                }
                break;

            case "contact_country_code":
                companyFields["contact_country_code"] = value;
                companyErrors["contact_country_code"] = "";
                break;

            case "companyContactPersonEmail":
                if (value.trim() === "") {
                    submitButtonOnblur = false;
                    companyFields["companyContactPersonEmail"] = value;
                    companyErrors["companyContactPersonEmail"] =
                        "";
                } else if (
                    !value.match(emailPattern)
                ) {
                    companyFields["companyContactPersonEmail"] = value;
                    companyErrors["companyContactPersonEmail"] =
                        "Please enter a valid email";
                } else {
                    submitButtonOnblur = true;
                    companyFields["companyContactPersonEmail"] = value;
                    companyErrors["companyContactPersonEmail"] = "";
                }
                break;

            case "companyNote":
                if (value.trim() === "") {
                    companyFields["companyNote"] = value;
                    companyErrors["companyNote"] = "";
                }
                else if (value.length > 1024) {
                    companyFields["companyNote"] = value;
                    companyErrors["companyNote"] = "Note should not be greater than 500 characters";
                }

                else if (!value.match(commonValidationWithLineBreak_regex)) {
                    companyFields['companyNote'] = value;
                    companyErrors['companyNote'] = "Please enter valid note"
                }
                else {
                    companyFields["companyNote"] = value;
                    companyErrors["companyNote"] = "";
                }
                break;

            // if (value.length > 1024) {
            //     companyFields["companyNote"] = value;
            //     companyErrors["companyNote"] =
            //         "Note should not be greater than 500 characters";
            // } else {
            //     companyFields["companyNote"] = value;
            //     companyErrors["companyNote"] = "";
            // }
            // break;

            case "first_name":
                if (value.trim() === "") {
                    companyFields["first_name"] = value.trim();
                    companyErrors["first_name"] = "This field is required";
                } else if (!value.match(firstName_regex)) {
                    companyFields["first_name"] = value;
                    companyErrors["first_name"] = "Please enter valid first name";
                } else {
                    companyFields["first_name"] = value.trim();
                    companyErrors["first_name"] = "";
                }
                break;

            case "last_name":
                if (value.trim() === "") {
                    companyFields["last_name"] = value.trim();
                    companyErrors["last_name"] = "This field is required";
                } else if (!value.match(lastName_regex) || value.charAt(value.length - 1) === "'") {
                    companyFields["last_name"] = value;
                    companyErrors["last_name"] = "Please enter valid last name";
                } else {
                    companyFields["last_name"] = value.trim();
                    companyErrors["last_name"] = "";
                }
                break;

            case "country_code":
                companyFields["country_code"] = value;
                companyErrors["country_code"] = "";
                break;

            case "email":
                if (value.trim() === "") {
                    companyFields["email"] = value;
                    companyErrors["email"] = "This field is required";
                } else if (
                    !value.match(emailPattern)
                ) {
                    companyFields["email"] = value;
                    companyErrors["email"] = "Please enter a valid email";
                } else {
                    companyFields["email"] = value;
                    companyErrors["email"] = "";
                }
                break;

            default:
                break;
        }

        this.setState({
            companyFields: { ...companyFields },
            companyErrors: { ...companyErrors },
            submitButtonOnblur: submitButtonOnblur,
        });

    };

    //onBlur check for contact person name
    contactPersonNameValidation = () => {
        if (this.state.companyErrors['companyContactPersonName'] === '' && this.state.companyFields['companyContactPersonName'] !== '') {
            let contactPersonName = this.state.companyFields['companyContactPersonName'];
            let contactPersonNameError;

            contactPersonName = contactPersonName.replace(/^\s+|\s+$/g, "");
            let lastName = "";
            let firstName = "";
            if (contactPersonName.indexOf(" ") !== -1) {
                lastName = contactPersonName.split(" ").pop();
                firstName = contactPersonName.lastIndexOf(" ");
                firstName = contactPersonName.substring(0, firstName);
            } else {
                firstName = contactPersonName;
            }
            contactPersonNameError = this.checkName(firstName, lastName);

            this.setState(prevState => ({
                ...prevState,
                companyFields: { ...prevState.companyFields },
                companyErrors: {
                    ...prevState.companyErrors,
                    companyContactPersonName: contactPersonNameError
                },
                submitButtonOnblur: false
            }))
        }
    }

    checkName = (firstName, lastName) => {
        let message;
        if (firstName.length > 20 && lastName.length > 20) {
            message = "Your First & Last Name length is greater than 20.";
        } else if (firstName.length > 20) {
            message = "Your First Name length is greater than 20.";
        }
        else if (lastName.length > 20) {
            message = "Your Last Name length is greater than 20.";
        } else {
            message = "";
        }
        return message;
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.stateList != this.props.stateList) {

            this.setState({
                updatedStateList: this.props.stateList

            }, () => {

                if (this.state.updatedStateList && (this.state.companyFields["is_city"] == true || this.state.companyFields["is_city"] == 1)) {

                    this.setState({
                        updatedStateList: [...this.state.updatedStateList, { id: "other", name: "Other" }]
                    }, () => {
                        if (this.state.companyFields["companyState"] == null || this.state.companyFields["companyState"] == 0) {
                            this.handleCompanyFieldsChange({ name: "companyState", value: "other" })
                        }

                    })
                }

            })

        }

    }


    handlePhoneNumberChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        const companyFields = { ...this.state.companyFields };
        const companyErrors = { ...this.state.companyErrors };
        if (value.trim() === "") {
            companyFields[name] = value.trim();
            companyErrors[name] = "";
        } else if (!value.match(/^[0-9]+$/)) {
            //companyFields[name] = "";
            // companyErrors[name] = "";
            // if(value.length < 9) {
            //     companyErrors[name] = "Phone number should not be less than 8 digits.";
            // }
        } else if (value.length < 8) {
            companyFields[name] = value;
            companyErrors[name] = "Phone number should not be less than 8 digits.";
        } else {
            companyFields[name] = value;
            companyErrors[name] = "";
        }

        this.setState({
            companyFields: companyFields,
            companyErrors: companyErrors
        });
    }

    // submitting company form
    handleCompanyFormSubmit = async () => {
        let companyErrors = { ...this.state.companyErrors };
        if (companyErrors["companyCode"] !== "" ||
            companyErrors["companyOfficeAddress1"] !== "" ||
            companyErrors["companyOfficeAddress2"] !== "" ||
            companyErrors["zipcode"] !== "" || companyErrors["companyCountry"] !== "" ||
            companyErrors["companyState"] !== "" || companyErrors["companyCity"] !== "" ||
            companyErrors["companyWebSite"] !== "" || companyErrors["companyContactPersonName"] !== "" ||
            companyErrors["contact_country_code"] !== "" ||
            companyErrors["companyContactPersonPhoneNumber"] !== "" ||
            companyErrors["companyContactPersonEmail"] !== "" ||
            companyErrors["companyNote"] !== "" ||
            companyErrors["first_name"] !== "" ||
            companyErrors["last_name"] !== "" ||
            companyErrors["email"] !== "" ||
            companyErrors["country_code"] !== "" ||
            companyErrors["phone_number"]) {
            this.handleCompanyOnSubmitValidation();
            return;
        }
        if (this.handleCompanyOnSubmitValidation()) {
            await this.setState({ submitButton: true });
            if (this.props.currentCompany) {
                this.handleUpdateCompany();
            } else {
                this.handleSaveCompany();
            }
        }
        // }
    };

    //onblur call for company code
    onCompanyCodeEnter = value => {
        // let value = e.target.value;
        if (value.trim() !== "" && this.state.companyErrors['companyCode'] === '') {
            let params = {
                "company_code": value
            }
            if (this.props.currentCompany) {
                params.id = this.props.currentCompany.id
            }
            axiosInstance.post("/contact/company/checkCompanyCode", params)
                .then(response => {
                    if (response.data.statusCode === 200) {
                        let companyErrors = { ...this.state.companyErrors };
                        companyErrors["companyCode"] = "";
                        this.setState({
                            codeValid: true,
                            companyErrors: { ...companyErrors },
                            submitButtonOnblur: false
                        });
                    } else {
                        let companyErrors = { ...this.state.companyErrors };
                        companyErrors["companyCode"] = "Company code should be unique";
                        this.setState({
                            codeValid: false,
                            submitButtonOnblur: false,
                            companyErrors: { ...companyErrors }
                        });
                    }
                })
                .catch(error => {
                    if (!error.status) {
                        openSnackbar("Oops, something went wrong!", "snack-error");
                        this.props.hideDialog();
                    } else {
                        let companyErrors = { ...this.state.companyErrors };
                        companyErrors["companyCode"] = "Company code should be unique";
                        this.setState({
                            codeValid: false,
                            submitButtonOnblur: false,
                            companyErrors: { ...companyErrors }
                        });
                    }

                });
        }
    }

    //onblur email validation
    onBlurEmailValidation = e => {
        let value = e.target.value;
        if (value.trim() !== "" && this.state.companyErrors['companyContactPersonEmail'] === '') {
            let params = {
                "contact_person_email": value
            }
            if (this.props.currentCompany) {
                params.id = this.props.currentCompany.id
            }
            axiosInstance.post("/contact/company/checkCompanyEmail", params)
                .then(response => {
                    if (response.data.statusCode === 200) {
                        let companyErrors = { ...this.state.companyErrors };
                        companyErrors["companyContactPersonEmail"] = "";
                        this.setState({
                            companyErrors: { ...companyErrors },
                            submitButtonOnblur: false
                        });
                    } else {
                        let companyErrors = { ...this.state.companyErrors };
                        companyErrors["companyContactPersonEmail"] = "Company email should be unique";
                        this.setState({
                            submitButtonOnblur: false,
                            companyErrors: { ...companyErrors }
                        });
                    }
                })
                .catch(error => {
                    if (!error.status) {
                        openSnackbar("Oops, something went wrong!", "snack-error");
                        this.props.hideDialog();
                    } else {
                        let companyErrors = { ...this.state.companyErrors };
                        companyErrors["companyContactPersonEmail"] = "Company email should be unique";
                        this.setState({
                            submitButtonOnblur: false,
                            companyErrors: { ...companyErrors }
                        });
                    }

                });
        }
    }

    // company form on submit all validations
    handleCompanyOnSubmitValidation = () => {
        let companyFields = this.state.companyFields;
        let companyErrors = { ...this.state.companyErrors };
        let companyFormIsValid = true;
        if (typeof companyFields["companyName"]) {
            if (companyFields["companyName"].toString().trim() === "") {
                companyFormIsValid = false;
                companyErrors["companyName"] = "This field is required";
            } else if (companyFields["companyName"].toString().trim().length > 100) {
                companyFormIsValid = false;
                companyErrors["companyName"] = "Max limit for company name is 100 characters";
            } else if (!companyFields["companyName"].toString().match(commonValidation_regex)) { // /^(?![0-9]*$)[a-zA-Z0-9]+$/
                companyFormIsValid = false;
                companyErrors["companyName"] = "Please enter valid company name";
            }
        }

        if (companyFormIsValid) {
            this.setState({ companyErrors: companyErrors });
        } else {
            this.setState({ companyErrors: companyErrors, submitButton: false });
        }

        return companyFormIsValid;
    };

    // save company contact request body
    handleSaveCompany = () => {
        const companyRequestBody = {
            company_name: this.state.companyFields.companyName.trim(),
            company_code: this.state.companyFields.companyCode.trim(),
            office_address_line_1: this.state.companyFields.companyOfficeAddress1.trim(),
            office_address_line_2: this.state.companyFields.companyOfficeAddress2.trim(),
            zipcode: this.state.companyFields.zipcode.trim(),
            country_id: this.state.companyFields.companyCountry ? (this.state.companyFields.companyCountry == 0 ? "" : this.state.companyFields.companyCountry.toString()) : "",
            state_id: this.state.companyFields.companyState ? (this.state.companyFields.companyState == 0 || this.state.companyFields.companyState == "other" ? "" : this.state.companyFields.companyState.toString()) : "",
            city_id: this.state.companyFields.companyCity ? (this.state.companyFields.companyCity == 0 ? "" : this.state.companyFields.companyCity.toString()) : "",
            website: this.state.companyFields.companyWebSite === 'http://' ? '' : this.state.companyFields.companyWebSite,
            contact_person_name: this.state.companyFields.companyContactPersonName.trim(),
            contact_country_code: this.state.companyFields.contact_country_code,
            contact_person_phone: this.state.companyFields.companyContactPersonPhoneNumber ? (this.state.companyFields.companyContactPersonPhoneNumber == 0 ? "" : this.state.companyFields.companyContactPersonPhoneNumber) : "",
            contact_person_email: this.state.companyFields.companyContactPersonEmail,
            notes: this.state.companyFields.companyNote.trim()
        };

        // if (this.state.add_contact) {
        //     companyRequestBody.first_name = this.state.companyFields.first_name;
        //     companyRequestBody.last_name = this.state.companyFields.last_name;
        //     companyRequestBody.country_code = this.state.companyFields.country_code.toString();
        //     companyRequestBody.phone_number = this.state.companyFields.phone_number;
        //     companyRequestBody.email = this.state.companyFields.email;
        //     companyRequestBody.is_contact_person = "yes";
        // }

        if (this.state.companyFields.companyContactPersonName.trim() !== "") {
            companyRequestBody.is_contact_person = "yes";
        }

        this.props.addNewCompany(companyRequestBody);
    };

    handleUpdateCompany = () => {
        const companyRequestBody = {
            id: this.props.currentCompany.id,
            company_name: this.state.companyFields.companyName.trim(),
            company_code: this.state.companyFields.companyCode.trim(),
            office_address_line_1: this.state.companyFields.companyOfficeAddress1.trim(),
            office_address_line_2: this.state.companyFields.companyOfficeAddress2.trim(),
            zipcode: this.state.companyFields.zipcode.trim(),
            country_id: this.state.companyFields.companyCountry ? (this.state.companyFields.companyCountry == 0 ? "" : this.state.companyFields.companyCountry.toString()) : "",
            state_id: this.state.companyFields.companyState ? (this.state.companyFields.companyState == 0 || this.state.companyFields.companyState == "other" ? "" : this.state.companyFields.companyState.toString()) : "",
            city_id: this.state.companyFields.companyCity ? (this.state.companyFields.companyCity == 0 ? "" : this.state.companyFields.companyCity.toString()) : "",
            website: this.state.companyFields.companyWebSite === 'http://' ? '' : this.state.companyFields.companyWebSite,
            //contact_person_name: this.state.companyFields.companyContactPersonName,
            contact_country_code: this.state.companyFields.contact_country_code,
            contact_person_phone: this.state.companyFields.companyContactPersonPhoneNumber ? (this.state.companyFields.companyContactPersonPhoneNumber == 0 ? "" : this.state.companyFields.companyContactPersonPhoneNumber) : "",
            contact_person_email: this.state.companyFields.companyContactPersonEmail,
            notes: this.state.companyFields.companyNote.trim()
        };

        this.props.updateCompany(companyRequestBody);
    };

    // on update if fields changed to empty, handle the state
    onChangeAutocomplete = async (event, newValue, key) => {
        let companyFields = { ...this.state.companyFields };
        let companyErrors = { ...this.state.companyErrors };
        switch (key) {
            case "contactCompanyName":
                if (!newValue || newValue == "") {
                    companyFields["companyName"] = "";
                    companyFields["companyCode"] = "";
                    companyFields["companyOfficeAddress1"] = "";
                    companyFields["zipcode"] = "";
                    companyFields["companyCountry"] = 0;
                    companyFields["asyncCompanyList"] = [];
                    companyFields["companyState"] = 0;
                    companyFields["companyCity"] = 0;
                    companyFields["contact_country_code"] = '';

                    companyErrors["companyName"] = "This field is required";
                    await this.setState({ companyFields, companyErrors, submitButtonOnblur: false });
                } else {
                    companyFields["companyName"] = "";

                    if (!newValue.match(commonValidation_regex)) {
                        companyFields["companyName"] = newValue;
                        companyErrors["companyName"] = "Please enter valid company name";
                        await this.setState({ companyFields, companyErrors });
                    } else {
                        companyFields["companyName"] = newValue;
                        companyErrors["companyName"] = "";
                        await this.setState({ companyFields, companyErrors },
                            async () => {
                                if (newValue.length > 0 && this.state.companyFields["companyName"] !== "") {
                                    await this.setState({ loadingCompanyList: true })
                                    this.fetchCompaniesAsnc(newValue);
                                }
                            });
                    }
                }
                break;

            case "companyCountry":
                if (!newValue || newValue == "") {
                    companyFields["companyCountry"] = "";
                    companyFields["companyState"] = "";
                    companyFields["companyCity"] = "";
                    companyFields["contact_country_code"] = "";
                    companyFields['is_city'] = "";
                    this.props.resetStateCity();
                    companyErrors["companyCountry"] = "";
                    companyErrors["companyState"] = "";
                    companyErrors["companyCity"] = "";
                    await this.setState({ companyFields, companyErrors, resetState: true, resetCity: true });
                } else {
                    let selectedCountry = this.props.countryList.filter((country, index) => {
                        return country.name === newValue;
                    });
                    if (!selectedCountry || selectedCountry.length == 0) {
                        companyFields["companyCountry"] = "";
                        companyFields["companyState"] = "";
                        companyFields["companyCity"] = "";
                        companyFields["contact_country_code"] = "";
                        companyFields['is_city'] = "";

                        this.props.resetStateCity();
                        companyErrors["companyCountry"] = "Please select country from suggestions";
                        companyErrors["companyState"] = "";
                        companyErrors["companyCity"] = "";
                    }
                    await this.setState({ companyFields, companyErrors, resetState: true, resetCity: true });
                }
                break;

            case "companyState":
                if (!newValue || newValue == "") {
                    companyFields["companyState"] = "";
                    companyFields["companyCity"] = "";
                    companyErrors["companyState"] = "";
                    companyErrors["companyCity"] = "";
                    this.props.resetCity();
                    await this.setState({ companyFields, companyErrors, resetCity: true });
                } else {
                    let selectedState = this.props.stateList.filter((state, index) => {
                        return state.name === newValue;
                    });
                    if (!selectedState || selectedState.length == 0) {
                        companyFields["companyState"] = "";
                        companyFields["companyCity"] = "";
                        companyErrors["companyState"] = "Please select state from suggestions";
                        companyErrors["companyCity"] = "";
                        this.props.resetCity();
                        await this.setState({ companyFields, companyErrors, resetCity: true });
                    }
                }
                break;
            case "companyCity":
                if (!newValue || newValue == "") {
                    companyFields["companyCity"] = "";
                    companyErrors["companyCity"] = "";
                    await this.setState({ companyFields, companyErrors });
                } else {
                    let selectedCity = this.props.cityList.filter((city, index) => {
                        return city.name === newValue;
                    });
                    if (!selectedCity || selectedCity.length == 0) {
                        companyFields["companyCity"] = "";
                        companyErrors["companyCity"] = "Please select city from suggestions";
                        await this.setState({ companyFields, companyErrors });
                    }
                }
                break;
            default:
                break;
        }
    }

    toggleContactFields = e => {
        e.preventDefault();
        const companyFields = { ...this.state.companyFields };
        const companyErrors = { ...this.state.companyErrors };
        companyFields["first_name"] = "";
        companyFields["last_name"] = "";
        companyFields["email"] = "";
        companyFields["country_code"] = "";
        companyFields["phone_number"] = "";

        companyErrors["first_name"] = "";
        companyErrors["last_name"] = "";
        companyErrors["email"] = "";
        companyErrors["country_code"] = "";
        companyErrors["phone_number"] = "";

        if (this.state.add_contact) {
            this.setState({
                add_contact: false,
                companyFields: companyFields,
                companyErrors: companyErrors
            })
        } else {
            this.setState({
                add_contact: true,
                companyFields: companyFields,
                companyErrors: companyErrors
            })
        }
    }

    fetchCompaniesAsnc = (newValue) => {
        // Modified for API access
       /*axios
             .get("https://leadx-crm.herokuapp.com/crm/brreg_companies/get/" + newValue) */
        axiosCRM
           .get(`/brreg_companies/get/${newValue}`)
            .then(response => {
                let companyFields = { ...this.state.companyFields };
                companyFields["asyncCompanyList"] = response.data.brreg_companies;
                this.setState({
                    companyFields: companyFields,
                    loadingCompanyList: false
                });

            })
            .catch(error => {
                let companyErrors = { ...this.state.companyErrors };
                let companyFields = { ...this.state.companyFields };
                companyErrors["companyName"] = "Error loading company list, please try again";
                companyFields["asyncCompanyList"] = [];
                companyFields["companyName"] = "";
                companyFields["companyCode"] = "";
                companyFields["companyOfficeAddress1"] = "";
                companyFields["zipcode"] = "";
                companyFields["companyCountry"] = 0;
                companyFields["companyState"] = 0;
                companyFields["companyCity"] = 0;
                companyFields["contact_country_code"] = '';
                this.setState({
                    companyFields,
                    companyErrors,
                    loadingCompanyList: false
                });
            });
    }

    getUpdationCompanyName = () => {
        let updationCompany = null;
        if (this.props.currentCompany) {
            updationCompany = this.state.companyFields.asyncCompanyList.find(company => {
                return company.id === this.props.currentCompany.id
            });
        }
        return updationCompany.company_name;
    }

    render() {
        return (
            <div>
                <DialogContent className={styles.lead_dialog_content}>

                    <div>
                        <div className="row">
                            <div className="form-group col-md-6 col-lg-3">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <label className={styles.label}>
                                        Company name
                                        <span className={styles.required_field}>*</span>
                                    </label>
                                    <div
                                        style={{ paddingRight: "10px", }}
                                    >
                                        <SmallScaleLoader
                                            loading={this.state.loadingCompanyList}
                                        />
                                    </div>
                                </div>

                                <AutoComplete
                                    suggestions={this.state.companyFields.asyncCompanyList}
                                    autoCompleteName="contactCompanyName"
                                    placeholder="Select company name"
                                    handleCompanyFieldsChange={this.handleCompanyFieldsChange}
                                    value={this.props.currentCompany ? this.state.companyFields["companyNameId"] : this.state.companyFields["companyName"]}
                                    onChange={
                                        (event, newValue, key) => {
                                            this.setState({ submitButtonOnblur: true });
                                            this.onChangeAutocomplete(event, newValue, key);
                                        }
                                    }
                                    onBlur={
                                        e => {
                                            this.setState({ submitButtonOnblur: false })
                                        }
                                    }
                                    loadingCompanyList={this.state.loadingCompanyList}
                                />
                                <span className={styles.errorMessage}>
                                    {this.state.companyErrors["companyName"]}
                                </span>
                            </div>

                            <div className="form-group col-md-6 col-lg-3">
                                <label className={styles.label}>Company registration number</label>
                                <input
                                    className={styles.inputStyle}
                                    type="text"
                                    name="companyCode"
                                    value={this.state.companyFields["companyCode"]}
                                    onChange={this.handleCompanyFieldsChange}
                                    autoComplete="off"
                                    maxLength="20"
                                    //placeholder="Enter company code"
                                    onBlur={e => this.onCompanyCodeEnter(e.target.value)}
                                />
                                <span className={styles.errorMessage}>
                                    {this.state.companyErrors["companyCode"]}
                                </span>
                            </div>
                            <div className="form-group col-md-6 col-lg-3">
                                <label className={styles.label}>Office address line 1</label>
                                <input
                                    className={styles.inputStyle}
                                    type="text"
                                    name="companyOfficeAddress1"
                                    value={this.state.companyFields["companyOfficeAddress1"]}
                                    onChange={this.handleCompanyFieldsChange}
                                    autoComplete="off"
                                    maxLength="255"
                                //placeholder="Enter office address line 1"
                                />
                                <span className={styles.errorMessage}>
                                    {this.state.companyErrors["companyOfficeAddress1"]}
                                </span>
                            </div>

                            <div className="form-group col-md-6 col-lg-3">
                                <label className={styles.label}>Office address line 2</label>
                                <input
                                    className={styles.inputStyle}
                                    type="text"
                                    name="companyOfficeAddress2"
                                    value={this.state.companyFields["companyOfficeAddress2"]}
                                    onChange={this.handleCompanyFieldsChange}
                                    autoComplete="off"
                                    maxLength="255"
                                //placeholder="Enter office address line 2"
                                />
                                <span className={styles.errorMessage}>
                                    {this.state.companyErrors["companyOfficeAddress2"]}
                                </span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-md-6 col-lg-3">
                                <label className={styles.label}>Zip/Postal address</label>
                                <input
                                    className={styles.inputStyle}
                                    type="text"
                                    name="zipcode"
                                    value={this.state.companyFields["zipcode"]}
                                    onChange={this.handleCompanyFieldsChange}
                                    autoComplete="off"
                                    maxLength="10"
                                //placeholder="Enter zip/postal address"
                                />
                                <span className={styles.errorMessage}>
                                    {this.state.companyErrors["zipcode"]}
                                </span>
                            </div>

                            <div className="form-group col-md-6 col-lg-3">
                                <label className={styles.label}>Country registered</label>
                                <AutoComplete
                                    suggestions={this.props.countryList}
                                    autoCompleteName="companyCountry"
                                    placeholder="Select country name"
                                    handleCompanyFieldsChange={this.handleCompanyFieldsChange}
                                    value={this.state.companyFields["companyCountry"]}
                                    onChange={(event, newValue, key) => this.onChangeAutocomplete(event, newValue, key)}
                                    is_city={this.state.companyFields["is_city"]}
                                />
                                <span className={styles.errorMessage}>
                                    {this.state.companyErrors["companyCountry"]}
                                </span>
                            </div>

                            <div className="form-group col-md-6 col-lg-3">
                                <label className={styles.label}>State</label>
                                <AutoComplete
                                    suggestions={this.state.updatedStateList}
                                    autoCompleteName="companyState"
                                    resetState={this.state.resetState}
                                    placeholder="Select state name"
                                    handleCompanyFieldsChange={this.handleCompanyFieldsChange}
                                    value={this.state.companyFields["companyState"]}

                                    onChange={(event, newValue, key) => this.onChangeAutocomplete(event, newValue, key)}
                                    is_city={this.state.companyFields["is_city"]}

                                    disabled={this.props.stateList && this.props.stateList.length !== 0 ? false : true}
                                />
                                <span className={styles.errorMessage}>
                                    {this.state.companyErrors["companyState"]}
                                </span>
                            </div>
                            <div className="form-group col-md-6 col-lg-3">
                                <label className={styles.label}>City</label>
                                <AutoComplete
                                    suggestions={this.props.cityList}
                                    autoCompleteName="companyCity"
                                    resetCity={this.state.resetCity}
                                    placeholder="Select city name"
                                    handleCompanyFieldsChange={this.handleCompanyFieldsChange}
                                    value={this.state.companyFields["companyCity"]}
                                    onChange={(event, newValue, key) => this.onChangeAutocomplete(event, newValue, key)}
                                    disabled={this.props.cityList && this.props.cityList.length !== 0 ? false : true}
                                />
                                <span className={styles.errorMessage}>
                                    {this.state.companyErrors["companyCity"]}
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-md-6 col-lg-3">
                                <label className={styles.label}>Website</label>
                                <div>
                                    {/* <div className="httpPrefix">{"http://"}</div> */}
                                    <input
                                        //className={`${styles.inputStyle} ${styles.input_website}`}
                                        className={`${styles.inputStyle}`}
                                        type="text"
                                        name="companyWebSite"
                                        value={this.state.companyFields["companyWebSite"]}
                                        onChange={this.handleCompanyFieldsChange}
                                        autoComplete="off"
                                        maxLength="100"
                                        placeholder=""
                                    />
                                </div>
                                <span className={styles.errorMessage}>
                                    {this.state.companyErrors["companyWebSite"]}
                                </span>
                            </div>

                            <div className="form-group col-md-6 col-lg-3">
                                <div className="row">
                                    <div className="col-4 padding_right padding_right_remove">
                                        <label className={styles.label}>Code</label>
                                        <select className={styles.selectCompany}
                                            name="contact_country_code"
                                            value={this.state.companyFields["contact_country_code"]}
                                            onChange={this.handleCompanyFieldsChange}>
                                            <option value="">Select</option>
                                            {
                                                this.props.countryList ?
                                                    this.props.countryList.map((country, index) => {
                                                        return (
                                                            <option value={country.id} key={"code_" + index}>
                                                                {/* {country.dialing_code + " (" + country.iso_code + ")"} */}
                                                                {country.iso_code + " +" + country.dialing_code}
                                                                {/* {"+"+ country.dialing_code} */}
                                                            </option>
                                                        )
                                                    })
                                                    : ''
                                            }
                                        </select>
                                    </div>
                                    <div className="col-8 padding_left">
                                        <label className={styles.label}>Phone number</label>
                                        <input
                                            className={styles.inputStyle}
                                            type="text"
                                            name="companyContactPersonPhoneNumber"
                                            value={
                                                this.state.companyFields[
                                                "companyContactPersonPhoneNumber"
                                                ]
                                            }
                                            onChange={this.handlePhoneNumberChange}
                                            autoComplete="off"
                                            maxLength="15"
                                        //placeholder="Enter phone number"
                                        />
                                    </div>
                                </div>
                                <span className={styles.errorMessage}>
                                    {
                                        this.state.companyErrors[
                                        "companyContactPersonPhoneNumber"
                                        ]
                                    }
                                </span>
                            </div>
                            <div className="form-group col-md-6 col-lg-3">
                                <label className={styles.label}>Email address</label>
                                <input
                                    className={styles.inputStyle}
                                    type="text"
                                    name="companyContactPersonEmail"
                                    value={
                                        this.state.companyFields["companyContactPersonEmail"]
                                    }
                                    onChange={this.handleCompanyFieldsChange}
                                    autoComplete="off"
                                    maxLength="100"
                                    onBlur={this.onBlurEmailValidation}
                                //placeholder="Enter email address"
                                />
                                <span className={styles.errorMessage}>
                                    {this.state.companyErrors["companyContactPersonEmail"]}
                                </span>
                            </div>
                            {
                                this.props.currentCompany ?
                                    null :
                                    <div className="form-group col-md-6 col-lg-3">
                                        <label className={styles.label}>Contact person</label>
                                        <input
                                            className={styles.inputStyle}
                                            type="text"
                                            name="companyContactPersonName"
                                            value={this.state.companyFields["companyContactPersonName"]}
                                            onChange={this.handleCompanyFieldsChange}
                                            autoComplete="off"
                                            maxLength="50"
                                            onBlur={this.contactPersonNameValidation}
                                        />
                                        <span className={styles.errorMessage}>
                                            {this.state.companyErrors["companyContactPersonName"]}
                                        </span>
                                    </div>
                            }
                        </div>
                        <div className="row">
                            <div className="form-group col-md-12">
                                <label className={styles.label}>Note</label>
                                <div className="notesHint">{(1024 - this.state.companyFields["companyNote"].length) + " characters left"}</div>
                                <textarea
                                    className={styles.taxtArea}
                                    name="companyNote"
                                    value={this.state.companyFields["companyNote"]}
                                    onChange={this.handleCompanyFieldsChange}
                                    autoComplete="off"
                                    maxLength="1024"
                                />
                                <span className={styles.errorMessage}>
                                    {this.state.companyErrors["companyNote"]}
                                </span>
                            </div>
                        </div>
                    </div>

                </DialogContent>
                <DialogActions className={styles.removeBorder}>
                    {
                        this.state.submitButton ?
                            <div className={styles.submit_loader}>
                                <CircularProgress
                                    style={{
                                        color: "#19D98C"
                                    }}
                                />
                            </div> :
                            <Button
                                disabled={this.state.submitButton || this.state.submitButtonOnblur}
                                className={styles.saveButton}
                                onClick={this.handleCompanyFormSubmit}
                                color="primary"
                            >
                                {"Save"}
                            </Button>
                    }
                </DialogActions>
            </div>
        )
    }
}

export default Company;