import React, { Component } from "react";
import * as actions from "./redux/actions";
import {userLogout} from "../aside-left/redux/actions";
import LoginComponent from "./component/LoginComponent";
import styles from "./LoginContainer.module.scss";
import { connect } from "react-redux";

class LoginContainer extends Component {
  render() {
    const loginComponentProps = this.props;
    return (
      <div className={styles.login_page}>
        <LoginComponent {...loginComponentProps} user={this.props.user} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.user,
    errorMessage: state.login.errorMessage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    userLogin: requestBody => dispatch(actions.userLogin(requestBody)),
    resetUserLogin: () => dispatch(actions.resetUserLogin()),
    logout: () => dispatch(userLogout())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer);
