import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./AssignSalesTeamCRMComponent.module.scss";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import moment from "moment";
import { Link } from "react-router-dom";
import { Redirect, Router } from 'react-router';
import axios from "../../../common/api-configCRM";
import { openSnackbar } from "../../../common/Snackbar/snackbar";
import SmallLoader from "../../../common/Loaders/CircularLoader/circular-loader";
import OopsError from "../../../components/ErrorMessages/OopsError/OopsError";
import WizardError from "../../../components/ErrorMessages/WizardError/WizardError";
import NoRecordsFound from "../../../components/ErrorMessages/NoRecordsFound/NoRecordsFound";
import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Dialog from "@material-ui/core/Dialog";
import UserAssignmentCRMContainer from "../../UserAssignmentCRM/UserAssignmentCRMContainer";
import CryptoJS from "crypto-js";
import LargeScreenLoader from "../../../common/Loaders/PulseLoader/pulse-loader";
const stylesMaterialUI = theme => ({
  button: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    marginLeft: 0,
    marginRight: 0
  }
});

class AssignSalesTeamCRMComponent extends Component {
  state = {
    CRMClicked: false,
    whichCRM: "",
    isCancelled: false,
    /* idcrm_master: 1,
    idcrm_integration: "",
    crm_name: "SalesForce", */
    CRMVersions: [],
    CRMUsers: [],
    leadXUsers: [],
    CRMDetails_for_SalesTeam: [],
    loadingDetails: true,
    errorLoadingDetails: false,
    loadingCRMUsers: true,
    errorLoadingCRMUsers: false,
    sales_team_assignment: [],
    /* {integration_id, fk_lead_type, user} */
    leadx_team_assignment: [],
    lead_types: [],
    loadinglead_typesDetails: true,
    errorLoadingLead_TypesDetails: false,
    loadingLeadXUsers: true,
    errorLoadingLeadXUsers: false,
    isEditClicked: false,
    leadTypeToBeEdited: undefined,
    newLead_type_id: undefined,
    newLead_type: undefined,
    newUser: "",
    errorInWizard: false,
    sf_username: "",
    showLoader:false
  };

  componentDidMount() {
    //console.log("in Will mount", this.state.idcrm_master);
    // if (this.props.history.location.state !== undefined) {
    //console.log("props", this.props);
    /* if (this.props.history.location.state !== undefined) {
      console.log("idcrm_integration in step3", this.props.history.location.state.idcrm_integration);
      this.setState({
        idcrm_integration: this.props.history.location.state.idcrm_integration,
        idcrm_master: this.props.history.location.state.idcrm_master,
        isActive: this.props.history.location.state.isActive,
        configured: this.props.history.location.state.configured
      }, () => { */
    if (this.props.idcrm_master !== undefined &&
      this.props.idcrm_master !== null &&
      this.props.idcrm_master !== "" &&
      this.props.idcrm_integration !== undefined &&
      this.props.idcrm_integration !== null &&
      this.props.idcrm_integration !== "") {
       
      if(this.props.idcrm_master == 3) { // SuperOffice
        this.setState({showLoader:true});       
        axios.get(`/crm_so_users/get/${this.props.idcrm_integration}`)
        .then(response => {
          console.log("Users Resp", response);
          if (response !== undefined &&
            response.data !== undefined &&
            response.data.status !== undefined &&
            response.data.status === 0) {
            if (response.data.users !== undefined) {
              this.setState({ CRMUsers: response.data.users });
              //console.log("CRMUsers ", this.state.CRMUsers);
              this.setState({ loadingCRMUsers: false, errorLoadingCRMUsers: false, showLoader: false  });
            } else {
              this.setState({ CRMUsers: [] });
              this.setState({ loadingCRMUsers: false, errorLoadingCRMUsers: true, showLoader: false });
              openSnackbar("Unable To Get CRM User Information", "snack-error");
            }
          } else {
            openSnackbar("Unable To Get CRM User Information", "snack-error");
            this.setState({ CRMUsers: [] });
            this.setState({ loadingCRMUsers: false, errorLoadingCRMUsers: true, showLoader: false  });
          }
        }).catch((error) => {
          openSnackbar("Exception in the Get CRM Users request.", "snack-error");
          console.log(error);
          this.setState({ CRMUsers: [] });
          this.setState({ loadingCRMUsers: false, errorLoadingCRMUsers: true, showLoader: false  });
        });
        
        // get details
      axios.get(`/salesteamassignments/get/${this.props.idcrm_integration}`).then(response => {
        //console.log("LeadType Resp", response);
        if (response !== undefined &&
          response.data !== undefined &&
          response.data.status !== undefined &&
          response.data.status === 0) {
          if (response.data.sales_team_assignment !== undefined &&
            response.data.sales_team_assignment.length > 0 &&
            response.data.leadx_team_assignment !== undefined &&
            response.data.leadx_team_assignment.length > 0) {
            this.setState({
              sales_team_assignment: response.data.sales_team_assignment,
              leadx_team_assignment: response.data.leadx_team_assignment,
              errorLoadingDetails: false,
              loadingDetails: false
            }, () => {
              //console.log("leadx_team_assignment", this.state.leadx_team_assignment);
              //console.log("sales_team_assignment", this.state.sales_team_assignment);
            });
          }
        } else {
          openSnackbar("Unable To Get Sales Team Assignments", "snack-error");
          this.setState({ sales_team_assignment: [] });
          this.setState({
            loadingDetails: false,
            errorLoadingDetails: true,
          });
        }
      }).catch((error) => {
        openSnackbar("Exception in the Get Sales Team Assignments Request.", "snack-error");
        console.log(error);
        this.setState({ sales_team_assignment: [] });
        this.setState({
          loadingDetails: false,
          errorLoadingDetails: true,
        });
      });
      }

      if(this.props.idcrm_master == 5) { // HubSpot
        this.setState({showLoader:true});       
        axios.get(`/crm_hs_users/get/${this.props.idcrm_integration}`)
        .then(response => {
          console.log("Users Resp", response);
          if (response !== undefined &&
            response.data !== undefined &&
            response.data.status !== undefined &&
            response.data.status === 0) {
            if (response.data.users !== undefined) {
              this.setState({ CRMUsers: response.data.users });
              //console.log("CRMUsers ", this.state.CRMUsers);
              this.setState({ loadingCRMUsers: false, errorLoadingCRMUsers: false, showLoader: false  });
            } else {
              this.setState({ CRMUsers: [] });
              this.setState({ loadingCRMUsers: false, errorLoadingCRMUsers: true, showLoader: false });
              openSnackbar("Unable To Get CRM User Information", "snack-error");
            }
          } else {
            openSnackbar("Unable To Get CRM User Information", "snack-error");
            this.setState({ CRMUsers: [] });
            this.setState({ loadingCRMUsers: false, errorLoadingCRMUsers: true, showLoader: false  });
          }
        }).catch((error) => {
          openSnackbar("Exception in the Get CRM Users request.", "snack-error");
          console.log(error);
          this.setState({ CRMUsers: [] });
          this.setState({ loadingCRMUsers: false, errorLoadingCRMUsers: true, showLoader: false  });
        });
        
        // get details
      axios.get(`/salesteamassignments/get/${this.props.idcrm_integration}`).then(response => {
        //console.log("LeadType Resp", response);
        if (response !== undefined &&
          response.data !== undefined &&
          response.data.status !== undefined &&
          response.data.status === 0) {
          if (response.data.sales_team_assignment !== undefined &&
            response.data.sales_team_assignment.length > 0 &&
            response.data.leadx_team_assignment !== undefined &&
            response.data.leadx_team_assignment.length > 0) {
            this.setState({
              sales_team_assignment: response.data.sales_team_assignment,
              leadx_team_assignment: response.data.leadx_team_assignment,
              errorLoadingDetails: false,
              loadingDetails: false
            }, () => {
              //console.log("leadx_team_assignment", this.state.leadx_team_assignment);
              //console.log("sales_team_assignment", this.state.sales_team_assignment);
            });
          }
        } else {
          openSnackbar("Unable To Get Sales Team Assignments", "snack-error");
          this.setState({ sales_team_assignment: [] });
          this.setState({
            loadingDetails: false,
            errorLoadingDetails: true,
          });
        }
      }).catch((error) => {
        openSnackbar("Exception in the Get Sales Team Assignments Request.", "snack-error");
        console.log(error);
        this.setState({ sales_team_assignment: [] });
        this.setState({
          loadingDetails: false,
          errorLoadingDetails: true,
        });
      });
      }
      else if(this.props.idcrm_master == 1){ // Salesforce 
      //get crm_users
      axios.get(`/crm_users/get/${this.props.idcrm_integration}`)
        .then(response => {
          console.log("Users Resp", response);
          if (response !== undefined &&
            response.data !== undefined &&
            response.data.status !== undefined &&
            response.data.status === 0) {
            if (response.data.users !== undefined) {
              this.setState({ CRMUsers: response.data.users });
              //console.log("CRMUsers ", this.state.CRMUsers);
              this.setState({ loadingCRMUsers: false, errorLoadingCRMUsers: false });
            } else {
              this.setState({ CRMUsers: [] });
              this.setState({ loadingCRMUsers: false, errorLoadingCRMUsers: true });
              openSnackbar("Unable To Get CRM User Information", "snack-error");
            }
          } else {
            openSnackbar("Unable To Get CRM User Information", "snack-error");
            this.setState({ CRMUsers: [] });
            this.setState({ loadingCRMUsers: false, errorLoadingCRMUsers: true });
          }
        }).catch((error) => {
          openSnackbar("Exception in the Get CRM Users request.", "snack-error");
          console.log(error);
          this.setState({ CRMUsers: [] });
          this.setState({ loadingCRMUsers: false, errorLoadingCRMUsers: true });
        });

      // get details
      axios.get(`/salesteamassignments/get/${this.props.idcrm_integration}`).then(response => {
        //console.log("LeadType Resp", response);
        if (response !== undefined &&
          response.data !== undefined &&
          response.data.status !== undefined &&
          response.data.status === 0) {
          if (response.data.sales_team_assignment !== undefined &&
            response.data.sales_team_assignment.length > 0 &&
            response.data.leadx_team_assignment !== undefined &&
            response.data.leadx_team_assignment.length > 0) {
            this.setState({
              sales_team_assignment: response.data.sales_team_assignment,
              leadx_team_assignment: response.data.leadx_team_assignment,
              errorLoadingDetails: false,
              loadingDetails: false
            }, () => {
              //console.log("leadx_team_assignment", this.state.leadx_team_assignment);
              //console.log("sales_team_assignment", this.state.sales_team_assignment);
            });
          }
        } else {
          openSnackbar("Unable To Get Sales Team Assignments", "snack-error");
          this.setState({ sales_team_assignment: [] });
          this.setState({
            loadingDetails: false,
            errorLoadingDetails: true,
          });
        }
      }).catch((error) => {
        openSnackbar("Exception in the Get Sales Team Assignments Request.", "snack-error");
        console.log(error);
        this.setState({ sales_team_assignment: [] });
        this.setState({
          loadingDetails: false,
          errorLoadingDetails: true,
        });
      });
    }
      /* }); */
    } else {
      this.setState({ errorInWizard: true });
    }

    //get leadx users
    axios.get(`/leadxusers/get`)
      .then(response => {
        //console.log("Users Resp", response);
        if (response !== undefined &&
          response.data !== undefined &&
          response.data.status !== undefined &&
          response.data.status === 0) {
          if (response.data.leadx_users !== undefined) {
            this.setState({ leadXUsers: response.data.leadx_users }, () => {
             /*  this.decryptLeadXUsers(); */
            });
            //console.log("leadXUsers ", this.state.leadXUsers);
            this.setState({ loadingLeadXUsers: false, errorLoadingLeadXUsers: false });
          } else {
            this.setState({ leadXUsers: [] });
            this.setState({ loadingLeadXUsers: false, errorLoadingLeadXUsers: true });
            openSnackbar("Unable to Get LeadX Users", "snack-error");
          }
        } else {
          openSnackbar("Unable to Get LeadX Users", "snack-error");
          this.setState({ leadXUsers: [] });
          this.setState({ loadingLeadXUsers: false, errorLoadingLeadXUsers: true });
        }
      }).catch((error) => {
        openSnackbar("Exception in the Get LeadX Users request.", "snack-error");
        console.log(error);
        this.setState({ CRMUleadXUserssers: [] });
        this.setState({ loadingLeadXUsers: false, errorLoadingLeadXUsers: true });
      });

    //get leadType master
    axios.get(`/leadtype/get`).then(response => {
      //console.log("LeadType Resp", response);
      if (response !== undefined &&
        response.data !== undefined &&
        response.data.status !== undefined &&
        response.data.status === 0) {
        if (response.data.lead_types !== undefined &&
          response.data.lead_types.length > 0) {
          this.setState({ lead_types: response.data.lead_types });
          this.setState({ loadinglead_typesDetails: false, errorLoadingLead_TypesDetails: false });
          //console.log("lead_types", this.state.lead_types);
        } else {
          openSnackbar("Unable to Get LeadX Lead Types", "snack-error");
          this.setState({ lead_types: [] });
          this.setState({ loadinglead_typesDetails: false, errorLoadingLead_TypesDetails: true });
        }
      } else {
        openSnackbar("Unable to Get LeadX Lead Types", "snack-error");
        this.setState({ lead_types: [] });
        this.setState({ loadinglead_typesDetails: false, errorLoadingLead_TypesDetails: true });
      }
    }).catch((error) => {
      openSnackbar("Exception in the LeadX Lead Type Request.", "snack-error");
      console.log(error);
      this.setState({ sales_team_assignment: [] });
      this.setState({
        loadinglead_typesDetails: false,
        errorLoadingLead_TypesDetails: true
      });
    });
  }

  /* decryptLeadXUsers = () => {
    let temp_LeadXUsers = [...this.state.leadXUsers];
    console.log("leadxusers", this.state.leadXUsers);
    console.log("leadxusers", temp_LeadXUsers);
   
    for (let index = 0; index < temp_LeadXUsers.length; index++) {
      const element = temp_LeadXUsers[index];
      console.log("element decrypt", element);
      if (element !== undefined && element.name !== undefined) {
        const bytes = CryptoJS.AES.decrypt(element.name, process.env.REACT_APP_CRYPTO_KEY_LOCAL_STORAGE);
        const temp_element = { ...temp_LeadXUsers[index] };
        temp_element.first_name = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        temp_LeadXUsers[index] = temp_element;
      }
    }
    console.log("decrypted usersRRRRR", temp_LeadXUsers);
  } */

  handleSaveNProceedClicked = (e) => {
    if (!this.checkValiditiy() === true) {
      var dataObject = {
        integration_id: this.props.idcrm_integration,
        sales_team_assignment: this.state.sales_team_assignment,
        leadx_team_assignment: this.state.leadx_team_assignment
      }
      //console.log("dataobject saved", JSON.stringify(dataObject));
      axios
        .post("/salesteamassignment/update/", { data: JSON.stringify(dataObject) })
        .then(response => {
          //console.log("Users Resp", response);
          if (response !== undefined &&
            response.data !== undefined &&
            response.data.status !== undefined &&
            response.data.status === 0) {
            openSnackbar("Saved!", "snack-success");

            this.setState({ CRMClicked: true, whichCRM: e });
            this.props.history.push({
              pathname: '/integrations/syncdataschedulerCRM',
              state: {
                /* idcrm_master: this.state.idcrm_master,
                idcrm_integration: this.props.idcrm_integration,
                isActive: this.state.isActive,
                configured: this.state.configured */
              }
            })

          } else {
            openSnackbar("Error while updating Sales Team Assignments", "snack-error");
            this.setState({ CRMUsers: [] });
          }
        }).catch((error) => {
          openSnackbar("Exception in the Sales Team Assignments Update Request.", "snack-error");
          console.log(error);
          this.setState({ CRMUsers: [] });
        });
    } else {
      openSnackbar("Please Enter Valid Values", "snack-error");
    }
  }

  handleGoBackClicked = () => {
    //console.log("go back clicked");
    this.setState({ goBack: true });
    this.props.history.goBack();
  }

  onChangeSpecificUser = (event, index, whichUserMapping) => {
    //console.log("onChangeSpUr", whichUserMapping);
    const value = event.target.value;
    //console.log("value", value);
    let updateSpecificCred = [];
    if (whichUserMapping == "sales_to_leadx") {
      updateSpecificCred = [
        ...this.state.sales_team_assignment
      ]
    } else {
      updateSpecificCred = [
        ...this.state.leadx_team_assignment
      ]
    }
    let updateElement = {};
    if (updateSpecificCred !== undefined &&
      updateSpecificCred.length > 0) {
      updateElement = {
        ...updateSpecificCred[index]
      }
    }
    if (updateElement !== undefined &&
      updateElement.user !== undefined) {

      updateElement.user = value;
      if (whichUserMapping == "sales_to_leadx") {
        if (this.state.CRMUsers !== undefined && this.state.CRMUsers !== null &&
          this.state.CRMUsers.length > 0) {
          const sf_name_obj = this.state.CRMUsers.find(item => {
            if (item.Id == value) {
              //console.log("Name", item.Name);
              return item.Name;
            }
          });
          //console.log(sf_name_obj);
          if (sf_name_obj !== undefined && sf_name_obj.Name !== undefined) {
            updateElement.sf_username = sf_name_obj.Name;
          } else {
            updateElement.sf_username = "";
          }
        } else {
          updateElement.sf_username = "";
        }
      }
      //console.log("updateEl", updateElement);
      updateSpecificCred[index] = updateElement;
      //console.log("upspcr", updateSpecificCred);
      //this.setState({ specific_cred: [...this.state.specific_cred, updateElement]});
      if (whichUserMapping == "sales_to_leadx") {
        this.setState({ sales_team_assignment: updateSpecificCred }, () => {
          //console.log("updated sales_team_assignment", this.state.sales_team_assignment);
        });

      } else {
        this.setState({ leadx_team_assignment: updateSpecificCred }, () => {
          //console.log("updated leadx_team_assignment", this.state.leadx_team_assignment);
        });
      }
    }
  }

  onChangeLeadType = (e, whichleadType) => {
    const value = e.target.value;
    this.setState({
      newLead_type: null,
      newLead_type_id: null
    });
    //console.log("onChangeLeadType", value);
    if (value !== undefined) {
      this.setState({ newLead_type_id: value }, () => {
        let lead_type = this.state.lead_types.find((item) => {
          //console.log("item", item);
          return item.id == value
        });
        //console.log("newleadtype", lead_type);
        if (lead_type !== undefined) {
          this.setState({ newLead_type: lead_type }, () => {
            //console.log("newleadtype", lead_type);
          });
        }
      })
    }
    //"leadx_to_sales"
  }

  onChangeUser = (e, whichleadType) => {
    const value = e.target.value;
    //console.log("onChangeUser", value);
    //console.log("whichleadType", whichleadType);
    if (value !== undefined) {
      this.setState({ newUser: value })
      if (whichleadType === "sales_to_leadx") {
        //send the CRM User Name too
        if (this.state.CRMUsers !== undefined && this.state.CRMUsers !== null &&
          this.state.CRMUsers.length > 0) {
          const sf_name = this.state.CRMUsers.find(item => {
            if (item.Id == value) {
              return item.Name;
            }
          });
          //console.log("sf-name", sf_name);
          this.setState({ sf_username: sf_name });
        }
      }
    }
    //"leadx_to_sales"
  }

  handleSaveClicked = () => {
    if (!this.checkValiditiy() === true) {
      var dataObject = {
        integration_id: this.props.idcrm_integration,
        sales_team_assignment: this.state.sales_team_assignment,
        leadx_team_assignment: this.state.leadx_team_assignment
      }
      //console.log("dataobject saved", JSON.stringify(dataObject));
      axios
        .post("/salesteamassignment/update/", { data: JSON.stringify(dataObject) })
        .then(response => {
          //console.log("Users Resp", response);
          if (response !== undefined &&
            response.data !== undefined &&
            response.data.status !== undefined &&
            response.data.status === 0) {
            openSnackbar("Saved", "snack-success");
          } else {
            openSnackbar("Error while updating Sales Team Assignments", "snack-error");
          }
        }).catch((error) => {
          openSnackbar("Exception in the Sales Team Assignments Update Request.", "snack-error");
          console.log(error);
        });
    } else {
      openSnackbar("Please select valid users", "snack-error");
    }
  }

  checkValiditiy() {
    if (this.state.sales_team_assignment !== undefined &&
      this.state.sales_team_assignment.length > 0) {
      //console.log("in1");
      let result = this.state.sales_team_assignment.find((cred) =>
        cred.lead_type === "Default" && cred.user.trim() === "");
      //console.log("result result", result);
      if (result !== undefined) {
        return true;
      } else {
        if (this.state.leadx_team_assignment !== undefined &&
          this.state.leadx_team_assignment.length > 0) {
          //console.log("in1");
          let result = this.state.leadx_team_assignment.find((cred) =>
            cred.lead_type === "Default" && cred.user.trim() === "");
          //console.log("result result", result);
          if (result !== undefined) {
            return true;
          }
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
  }

  handleUnAssignClicked = (event, leadType) => {
    //console.log("leadtype unassigned", leadType);
    if (leadType.lead_type !== "Default") {
      if (this.state.sales_team_assignment !== undefined &&
        this.state.sales_team_assignment.length > 0) {
        let salesTeamAssignment = this.state.sales_team_assignment.filter((item) =>
          item.fk_lead_type !== leadType.fk_lead_type);
        //console.log("filtered", salesTeamAssignment);

        //console.log("not spliced array", this.state.sales_team_assignment);
        if (salesTeamAssignment !== undefined &&
          salesTeamAssignment.length > 0) {
          this.setState({
            sales_team_assignment: salesTeamAssignment
          }, () => {
            //console.log("spliced array", this.state.sales_team_assignment);
          });
        } else {
          openSnackbar("Something went wrong", "snack-error");
        }
      } else {
        openSnackbar("Something went wrong", "snack-error");
      }
    } else {
      openSnackbar("Default Cannot Be Removed", "snack-error");
    }
  }

  handleEditClicked = (event, leadType) => {
    //console.log("leadtype Edit", this.state.CRMUsers);
    //console.log("leadtype Edit", leadType);
    if (leadType !== undefined) {
      this.setState({ leadTypeToBeEdited: leadType }, () => {
        if (this.state.leadTypeToBeEdited !== undefined &&
          this.state.CRMUsers !== undefined && this.state.CRMUsers.length > 0 &&
          this.state.lead_types !== undefined && this.state.lead_types.length > 0) {
          this.setState({ isEditClicked: true });
        }
      });
    }
  }

  handleCloseEdit = () => {
    this.setState({ isEditClicked: false });
  }

  handleSaveAfterEdit = (leadType) => {
    //console.log("handle save after edit", leadType);
    this.setState({ isEditClicked: false });
    if (leadType !== undefined) {
      const index = this.state.sales_team_assignment.findIndex((item) =>
        item.fk_lead_type === leadType.fk_lead_type);
      //console.log("index", index);
      if (index > -1) {
        const salesTeamAssignment = [...this.state.sales_team_assignment];
        const salesElement = salesTeamAssignment[index];
        salesElement.user = leadType.user;
        salesTeamAssignment[index] = salesElement;
        this.setState({ sales_team_assignment: salesTeamAssignment }, () => {
          //console.log("updated sales_team_assignment", this.state.sales_team_assignment);
        });
      }
    }
  }

  handleAddUserClicked = () => {
    //console.log("Add clicked");
    //console.log("newlead_type", this.state.newLead_type);
    if (this.state.newLead_type_id !== undefined &&
      this.state.newLead_type !== undefined &&
      this.state.newUser !== undefined &&
      this.props.idcrm_integration !== undefined &&
      this.props.idcrm_integration !== "") {
      //console.log("Ready to add");
      let result = false;
      if (this.state.sales_team_assignment !== undefined &&
        this.state.sales_team_assignment.length > 0) {
        for (let index = 0; index < this.state.sales_team_assignment.length; index++) {
          const element = this.state.sales_team_assignment[index];
          //console.log("item", element);
          if (element !== undefined &&
            this.state.newLead_type !== undefined &&
            this.state.newLead_type.id !== undefined) {
            if (element.fk_lead_type === this.state.newLead_type.id) {
              result = true;
            }
          }
        }
      }

      //console.log("result", result);
      if (!result) {
        const item = {
          integration_id: this.props.idcrm_integration,
          fk_lead_type: parseInt(this.state.newLead_type_id),
          lead_type: this.state.newLead_type.name,
          user: this.state.newUser,
          sf_username: this.state.sf_username
        };
        let salesTeamAssignment = [...this.state.sales_team_assignment];
        salesTeamAssignment.push(item);
        //console.log("new salesteamassignment", salesTeamAssignment);
        this.setState({
          sales_team_assignment: salesTeamAssignment
        });
      } else {
        openSnackbar("Lead Type Already Present", "snack-error");
      }
    }
  }

  render() {
    return (
      <React.Fragment>
       {
          this.state.showLoader && <LargeScreenLoader />
        }
        <div className={styles.main_wrapper}>
          <div className="row">
            <div className="col-12">

              <div className={"breadcrumbs row " + styles.centerAlign}>
                <div className="col-md-6 col-12">
                  <h2 className="heading">Integrations</h2>
                  <div className="breadcrumbs_container">
                    <Breadcrumbs separator="›" arial-label="Breadcrumb">
                      {/* <Link to="/sales-news">Home</Link>
                      <Link to="/integrations">Integrations</Link>
                      <Link to="/integrations/configureCRM">Configure CRM</Link> */}
                      {/*  <Link to="/integrations/authenticateCRM">Authenticate</Link> */}
                      <span>Home</span>
                      <span>Integrations</span>
                      <span>Configure CRM</span>
                      <span>Configure Data Object Mappings</span>
                      <span>Assign Sales Team</span>
                    </Breadcrumbs>
                  </div>
                </div>
              </div>

              <div className="row container-body">
                <div className={`col-xs-12 col-md-12 col-lg-12 col-12`}>
                  <div className={styles.assignSalesTeamCRM_container}>

                    <div className={styles.assignSalesTeamCRM_container_header}>
                      <h1 className={styles.assignSalesTeamCRM_container_heading}>
                    Step 4 of 5 - Assign Sales Manager of {this.props.crm_name}
                      </h1>
                    </div>

                    <div className={styles.assignSalesTeamCRM_container_footer} >
                      {/* start */}
                      {this.state.errorInWizard ? (<div><WizardError /></div>) :
                        this.state.errorLoadingDetails ||
                          this.state.errorLoadingLead_TypesDetails ||
                          this.state.errorLoadingCRMUsers ?
                          (<div><OopsError /></div>) :
                          (this.state.loadingDetails &&
                            this.state.loadingCRMUsers &&
                            this.state.loadinglead_typesDetails) ? (<SmallLoader />) :
                            /* this.state.sales_team_assignment.length === 0 ||
                              this.state.leadx_team_assignment.length === 0 ||
                              this.state.CRMUsers.length === 0 ||
                              this.state.lead_types.length === 0 ?
                              (<div><NoRecordsFound /></div>) : */
                            (<div>
                              <div className="row">
                                <div className="col-12">
                                  <div className="bg-white ml-4 mr-4">

                                    <div className="mt-5 mb-4">
                                      <div className="row">
                                        <div className="col-sm-1 mb-3" />

                                        <div className="col-sm-10">
                                          <table className="table table-striped- table-bordered table-hover table-checkable">
                                            <thead>
                                              <tr style={{ backgroundColor: '#f2f2f2', textAlign: 'center' }}>
                                                <th colSpan="4">{this.props.crm_name} To LeadX</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {this.state.leadx_team_assignment.map((leadType, index) =>
                                                <tr key={index} style={{ textAlign: 'center' }}>
                                                  <td style={{ 'verticalAlign': 'middle' }}>
                                                    <label>
                                                      {leadType.lead_type === "Default" ?
                                                        <strong>
                                                          {leadType.lead_type}
                                                        </strong>
                                                        : <>{leadType.lead_type}</>}
                                                    </label>
                                                  </td>
                                                  <td>
                                                    <select className={styles.profileContainer_inputStyle__1Dduu} style={{ backgroundColor: '#fff' }} onChange={(e) => this.onChangeSpecificUser(e, index, "leadx_to_sales")}>
                                                      <option value={-1} selected={leadType.user == ''}>--- Choose User ---</option>
                                                      
                                                      {
                                                      this.state.leadXUsers.map(leadxuser => 
                                                       <option key={leadxuser.id} value={leadxuser.id} selected={leadType.user == leadxuser.id}>{leadxuser.email} {leadxuser.username}</option>
                                                     )}
                                                    </select>
                                                  </td>
                                                </tr>
                                              )}
                                            </tbody>
                                          </table>
                                        </div>

                                        <div className="col-sm-1 mb-3" />
                                      </div>

                                      <hr className="jss201" />
                                      <br />
                                      <div className="row">
                                        <div className="col-sm-1 mb-3" />
                                        <div className="col-sm-10">
                                          <table className="table table-striped- table-bordered table-hover table-checkable">
                                            <thead>
                                              <tr style={{ backgroundColor: '#f2f2f2', textAlign: 'center' }}>
                                                <th colSpan="4">Leadx To {this.props.crm_name}</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td>
                                                  <select className={styles.profileContainer_inputStyle__1Dduu} style={{ backgroundColor: '#fff' }} onChange={(e) => this.onChangeLeadType(e, "leadx_to_sales")}>
                                                    <option value={''}>--- Choose Lead Type ---</option>
                                                    {this.state.lead_types.map(lead_type =>
                                                      <option key={lead_type.id} value={lead_type.id} >{lead_type.name}</option>
                                                    )}
                                                  </select>
                                                </td>
                                                <td>
                                                  <select className={styles.profileContainer_inputStyle__1Dduu} style={{ backgroundColor: '#fff' }} onChange={(e) => this.onChangeUser(e, "leadx_to_sales")}>
                                                    <option value={''}>--- Choose User ---</option>
                                                    {this.state.CRMUsers.map(crmuser =>
                                                      <option key={crmuser.Id} value={crmuser.Id}>{crmuser.Name}</option>
                                                    )}
                                                  </select>
                                                </td>
                                                <td style={{ width: '20%', textAlign: 'center', verticalAlign: 'middle' }}>
                                                  <button onClick={this.handleAddUserClicked} className="btn btn-outline-secondary btn-sm" type="button" style={{ padding: '5px 20px' }}>Add</button>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                        <div className="col-sm-1 mb-3" />
                                      </div>

                                      <br />

                                      <div className="row">
                                        <div className="col-sm-1 mb-3" />

                                        <div className="col-sm-10">
                                          <table className="table table-striped- table-bordered table-hover table-checkable">
                                            <thead>
                                              {/* <tr style={{ backgroundColor: '#f7f7f7', textAlign: 'center' }}>
                                            <th colSpan="4">Leadx To SalesForce</th>
                                          </tr> */}
                                              <tr style={{ backgroundColor: '#f7f7f7', textAlign: 'center' }}>
                                                <th>Type</th>
                                                <th>User Assignment</th>
                                                {/* <th>Edit</th> */}
                                                <th>Remove</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {this.state.sales_team_assignment.map((leadType, index) =>
                                                <tr key={index} style={{ textAlign: 'center' }}>
                                                  <td style={{ 'verticalAlign': 'middle' }}>
                                                    <label>
                                                      {leadType.lead_type === "Default" ?
                                                        <strong>
                                                          {leadType.lead_type}
                                                        </strong>
                                                        : <>{leadType.lead_type}</>
                                                      }
                                                    </label>
                                                  </td>
                                                  <td>
                                                    <select className={styles.profileContainer_inputStyle__1Dduu} style={{ backgroundColor: '#fff' }} onChange={(e) => this.onChangeSpecificUser(e, index, "sales_to_leadx")}>
                                                      <option value={''} selected={leadType.user == ''}>--- Choose User ---</option>
                                                      {this.state.CRMUsers.map(crmuser =>
                                                        <option key={crmuser.Id} value={crmuser.Id} selected={leadType.user == crmuser.Id}>{crmuser.Name}</option>
                                                      )}
                                                    </select>
                                                  </td>
                                                  {/* <td style={{ width: '20%', textAlign: 'center', verticalAlign: 'middle' }}>
                                                  <IconButton aria-label="edit"
                                                    onClick={e => this.handleEditClicked(e, leadType)}>
                                                    <CreateIcon />
                                                  </IconButton> */}
                                                  {/* {(leadType.user !== undefined && leadType.user !== '' && leadType.user !== '--- Choose User ---') ?
                                                  <button onClick={() => this.handleEditClicked(leadType)} className="btn btn-outline-secondary btn-sm" type="button" style={{ padding: '5px 20px' }}>
                                                    Edit
                                                  </button>
                                                  :
                                                  <></>} */}
                                                  {/*  </td> */}
                                                  <td style={{ width: '20%', textAlign: 'center', verticalAlign: 'middle' }}>
                                                    {/* <button onClick={() => this.handleUnAssignClicked(leadType)} className="btn btn-outline-secondary btn-sm" type="button" style={{ padding: '5px 20px' }}>
                                                  Un-Assign
                                                  </button> */}
                                                    {leadType.lead_type !== "Default" ?
                                                      <IconButton aria-label="clear"
                                                        onClick={e => this.handleUnAssignClicked(e, leadType)}>
                                                        <ClearIcon />
                                                      </IconButton>
                                                      : <></>}
                                                  </td>
                                                </tr>
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                        <div className="col-sm-1 mb-3" />

                                      </div>
                                    </div>
                                    <hr className="jss201" />
                                    <div className="mt-3 mb-5">
                                      <div style={{ textAlign: 'right' }}>
                                        <button onClick={this.handleGoBackClicked} className={styles.profileContainer_update_btn__MXNDH} type="button" style={{ backgroundColor: '#e0e0e0', padding: '10px 20px', color: 'rgba(0, 0, 0, 0.87)' }}>Go Back</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <button onClick={() => this.handleSaveClicked("step3")} type="button" className={styles.profileContainer_update_btn__MXNDH} style={{ padding: '10px 20px' }}>Save</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <button onClick={() => this.handleSaveNProceedClicked("step3")} type="button" className={styles.profileContainer_update_btn__MXNDH} style={{ padding: '10px 20px' }}>Save &amp; Proceed</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>)
                        }
                      {/* start */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Dialog
            className="width"
            aria-labelledby="customized-add-activities"
            open={this.state.isEditClicked ? this.state.isEditClicked : false}
            onClose={this.handleCloseEdit}
            maxWidth={"lg"}
          >
            <UserAssignmentCRMContainer
              close={this.handleCloseEdit}
              save={this.handleSaveAfterEdit}
              fieldMapping={this.state.leadTypeToBeEdited}
              lead_types={this.state.lead_types}
              CRMUsers={this.state.CRMUsers}
            />
          </Dialog>
        </div>
      </React.Fragment >
    );
  }
}

export default withStyles(stylesMaterialUI)(AssignSalesTeamCRMComponent);