import React, { useState, useEffect } from 'react';
import styles from './invite_user.module.scss';
//Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
//Form Inputs
import Button from '@material-ui/core/Button';
import Input from "@material-ui/core/Input";
import Select from 'react-select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import APIs from '../APIs';
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Loader } from '../Loaders/CircularLoader/circular-loader';

const emptyChecker = (value) => !!(value && value.trim());

const InviteUsersValidationSchema = Yup.object().shape({
    users: Yup.array()
        .of(
            Yup.object()
                .shape({
                    first_name: Yup.string().required('Required Field').test('empty-check', 'Required Field', emptyChecker),
                    last_name: Yup.string().required('Required Field').test('empty-check', 'Required Field', emptyChecker),
                    email: Yup.string().required('Required Field').email('Invalid email'),
                    initials: Yup.string().required('Required Field'),
                    contact: Yup.string().required('Required Field').matches(/^\d{8,12}$/, 'Invalid Phone Number'),
                    country_code: Yup.string().required('Required Field'),
                    license_type_id: Yup.string().required('Required Field'),
                    gender: Yup.string().required('Required Field')
                })
        )
});

const style = {
    control: base => ({
      ...base,
      border: "1px solid #c0c0c0",
      boxShadow: "#c0c0c0",
      borderColor:"#c0c0c0",
      '&:hover': {
        boxShadow: "#c0c0c0",
      borderColor:"#c0c0c0",
     }}),
    option: base => ({
        ...base,
    })
};

const prefixOptions = [
    { 'label': 'Mr', value: 'Mr' },
    { 'label': 'Miss', value: 'Miss' },
    { 'label': 'Mrs', value: 'Mrs' }
];

function getFieldName(fieldName) {
    return fieldName.split('.');
}

const FieldInput = ({ field, form, ...rest }) => {
    const [key, index, fieldKey] = getFieldName(field.name);
    let value = field.value || '';
    value = fieldKey === 'contact' ? value.replace(/[^0-9]/g, '') : value;

    return (
        <>
            <Input
                inputProps={{ style: { height: '1.6em', paddingLeft: '0.75em', borderRadius: 3, border: '0.75px solid #D3D3D3', backgroundColor: '#FFF' } }}
                fullWidth={true}
                disableUnderline={true}
                {...field}
                {...rest}
                value={value}
            />
            {
                (form.submitCount || (form.touched[key] &&
                    form.touched[key][index] &&
                    form.touched[key][index][fieldKey])) &&
                form.errors[key] &&
                form.errors[key][index] &&
                form.errors[key][index][fieldKey] &&
                <div style={{ color: 'red' }}>{form.errors[key][index][fieldKey]}</div>}
        </>
    )
}

const CustomInputComponent = ({ label, containerClassName, field, ...rest }) => (
    <div className={containerClassName}>
        <div className={styles.title_text}>
            {label}
        </div>
        <div style={{ marginTop: '1em' }}>
            <FieldInput field={field} {...rest} />
        </div>
    </div>
);

const FieldSelect = ({ field, form, ...rest }) => {
    const [key, index, fieldKey] = getFieldName(field.name);
    return (
        <>
            <Select
                {...field}
                maxMenuHeight={220}
                menuPlacement="auto"
                onChange={(change) => form.setFieldValue(field.name, change)}
                {...rest}
            />
            {
                (form.submitCount || (form.touched[key] &&
                    form.touched[key][index] &&
                    form.touched[key][index][fieldKey])) &&
                form.errors[key] &&
                form.errors[key][index] &&
                form.errors[key][index][fieldKey] &&
                <div style={{ color: 'red' }}>{form.errors[key][index][fieldKey]}</div>}
        </>
    );
}

const CustomSelectInput = ({ label, containerClassName, options, field, form, ...rest }) => (
    <div className={containerClassName}>
        <div className={styles.title_text}>
            {label}
        </div>
        <div style={{ marginTop: '1em' }}>
            <FieldSelect options={options} field={field} form={form} {...rest} />
        </div>
    </div>
);

const CustomGenderInput = ({ label, containerClassName, form, field, ...rest }) => {
    const [key, index, fieldKey] = getFieldName(field.name);

    return (
        <div className={containerClassName}>
            <div className={styles.title_text}>
                {label}
            </div>
            <div style={{ marginTop: '1em' }}>
                <RadioGroup aria-label="gender" {...field} row style={{ maxHeight: 36 }}>
                    <FormControlLabel value="male" control={<Radio color="default" />} label="Male" />
                    <FormControlLabel value="female" control={<Radio color="default" />} label="Female" />
                </RadioGroup>
                {
                    (form.submitCount || (form.touched[key] &&
                        form.touched[key][index] &&
                        form.touched[key][index][fieldKey])) &&
                    form.errors[key] &&
                    form.errors[key][index] &&
                    form.errors[key][index][fieldKey] &&
                    <div style={{ color: 'red' }}>{form.errors[key][index][fieldKey]}</div>}
            </div>
        </div>
    )
}



const defaultInviteUserObject = {};

function SuccessMessagePopup({ onClose, message }) {
    return (
        <Dialog
            onClose={onClose} aria-labelledby="customized-dialog-title" open={!!message}
            fullWidth
        >
            <MuiDialogTitle id="customized-dialog-title" onClose={onClose}>
                <div className="row">
                    <div className="col-md-12">
                        <div className={styles.popup_title}>Invitation Status
              <div className={styles.closeIcon}>
                                <IconButton aria-label="close" onClick={onClose}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </div>
                        <div className={styles.tableDiv}>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className={styles.th}>Email</th>
                                        <th scope="col" className={styles.th}>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {message && message.data && message.data.map(successData =>
                                        <tr>
                                            <td>{successData.email}</td>
                                            {successData.success == false ?
                                                <td>{successData.reason}</td> :
                                                <td>{"Invitation Sent"}</td>
                                            }
                                        </tr>

                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </MuiDialogTitle>
        </Dialog>
    )
};


function InviteUserDialog({ open, onClose, defaultSelectedOrg }) {
    const [coutriesOptions, setCountriesOptions] = useState([]);
    const [licensesOptions, setLicensesOptions] = useState([]);
    const [organizationNameOptions, setOrganizationNameOptions] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const fetchOptions = async () => {
        const result = await Promise.all([
            APIs.getCountries(),
            APIs.getLicenseTypes(),
            APIs.getOrganizations()
        ]);
        setCountriesOptions(
            result[0]
                .map(c => ({ value: c.dialing_code, label: `${c.iso_code}+${c.dialing_code}` })));
        setLicensesOptions(
            result[1]
                .filter(l => l.actual_name !== 'ADMINISTRATOR')
                .filter(l => l.actual_name !== 'EXTERNAL_API')
                .map(r => ({ value: r.id, label: r.display_name }))
        );
        const orgOptions = result[2].map(r => ({ value: r.id, label: r.name, my_account: r.my_account }));
        setOrganizationNameOptions(orgOptions);
        if (defaultSelectedOrg) {
            setSelectedOrg(orgOptions.find(o => o.value == defaultSelectedOrg));
        } else {
            setSelectedOrg(orgOptions.find(o => o.my_account));
        }

    };

    useEffect(() => {
        fetchOptions();
    }, []);

    const renderRows = (values) => (arrayHelpers) => {
        return values.users.map((user, index) => (
            <>
                <div className="row">
                    <Field
                        name={`users.${index}.initials`}
                        component={CustomSelectInput}
                        options={prefixOptions}
                        label="Prefix"
                        styles={style}
                        placeholder="Prefix"
                        containerClassName="col-xs-6 col-sm-6 col-md-2"
                    />
                    <Field
                        name={`users.${index}.first_name`}
                        component={CustomInputComponent}
                        label="First Name"
                        placeholder="First Name"
                        containerClassName="col-xs-6 col-sm-6 col-md-2"
                    />
                    <Field
                        name={`users.${index}.middle_name`}
                        component={CustomInputComponent}
                        label="Middle Name"
                        placeholder="Middle Name"
                        containerClassName="col-xs-6 col-sm-6 col-md-2"
                    />
                    <Field
                        name={`users.${index}.last_name`}
                        component={CustomInputComponent}
                        label="Last Name"
                        placeholder="Last Name"
                        containerClassName="col-xs-6 col-sm-6 col-md-2"
                    />
                    <Field
                        name={`users.${index}.gender`}
                        component={CustomGenderInput}
                        label="Gender"
                        containerClassName="col-xs-6 col-sm-6 col-md-3"
                    />
                </div>
                <div className="row mt-4">
                    <Field
                        name={`users.${index}.email`}
                        component={CustomInputComponent}
                        label="Email"
                        placeholder="Email"
                        containerClassName="col-xs-6 col-sm-6 col-md-2"
                    />
                    <div className="col-xs-6 col-sm-6 col-md-3">
                        <div className={styles.title_text}>
                            Phone No.
                            </div>
                        <div style={{ marginTop: '1em' }} className="row no-gutters">
                            <div className="col-xs-2 col-sm-2 col-md-4 col-lg-4">
                                <Field
                                    name={`users.${index}.country_code`}
                                    component={FieldSelect}
                                    placeholder="Code"
                                    options={coutriesOptions}
                                    styles={style}
                                    maxMenuHeight={100}
                                />
                            </div>
                            <div className="col-xs col-sm col-md-8 col-lg-8">
                                <Field
                                    name={`users.${index}.contact`}
                                    component={FieldInput}
                                    placeholder="Phone Number"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-4">
                        <div className={styles.title_text}>
                             Role
                        </div>
                        <div style={{ marginTop: '1em' }} className="row no-gutters">
                            <div className="col-xs-2 col-sm-2 col-md-4 col-lg-4">
                            <Field
                                name={`users.${index}.license_type_id`}
                                component={FieldSelect}
                                placeholder="Role"
                                options={licensesOptions}
                                maxMenuHeight={100}
                                styles={style}
                            />
                            </div>
                        </div>
                    </div>
                    
                </div>
                <hr className="mb-2" />
            </>
        ))
    }

    const handleAddMoreInviteUsers = (arrayHelpers) => () => {
        arrayHelpers.push(defaultInviteUserObject);
    }

    const renderForm = ({ values, submitForm, resetForm }) => (
        <Form>
            <FieldArray
                name="users"
                render={(arrayHelpers) => (
                    <Dialog open={open && !showSuccessMessage} onClose={onClose} fullWidth={true} maxWidth="xl" aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">
                            <div className={styles.lead_dialog_title}>
                                Invite User
                            </div>
                        </DialogTitle>
                        <div className="row my-2 py-2" style={{ backgroundColor: "#e0dfdc	" }}>
                            <div className="col-md-4"></div>
                            <div className="col-md-4">
                                <Select id="organization" name="organization"
                                    style={{ alignItems: 'center' }}
                                    options={organizationNameOptions}
                                    styles={style}
                                    value={selectedOrg}
                                    onChange={(org) => setSelectedOrg(org)}
                                    placeholder="Select Organization/Child Orgnization"
                                />
                            </div>
                            <div className="col-md-4"></div>
                        </div>
                        <DialogContent className={
                            styles.lead_dialog_content
                        }>
                            {renderRows(values)(arrayHelpers)}
                            {values.users.length < 10 && <label className="mt-3 font-weight-bold pointer" onClick={handleAddMoreInviteUsers(arrayHelpers)}>+ Invite More Users</label>}
                        </DialogContent>
                        <DialogActions className={styles.removeBorder}>
                            <Button onClick={(e) => { setShowLoader(false); resetForm(); onClose(e); }} variant="contained" className={styles.saveButton} color="default">
                                Cancel
                            </Button>
                            {
                                showLoader ? <div style={{ marginRight: 20, marginTop: 20 }}><Loader /></div> : (
                                    <Button onClick={() => submitForm()} variant="contained" size="small" style={{ backgroundColor: '#0b0e22', color: 'white' }} className={styles.saveButton}>
                                        Send
                                    </Button>)
                            }
                        </DialogActions>
                    </Dialog>
                )}
            />
        </Form>
    )

    const handleSuccessMessageClose = (e) => {
        setShowSuccessMessage('');
        onClose(e);
    };

    return (
        <div>
            <Formik
                initialValues={{ users: [defaultInviteUserObject] }}
                onSubmit={async (values, { resetForm }) => {
                    setShowLoader(true);
                    const result = await APIs.inviteUsers(selectedOrg.value, values.users);
                    setShowLoader(false);
                    setShowSuccessMessage(result);
                    resetForm();
                }}
                validationSchema={InviteUsersValidationSchema}
                render={renderForm}
            />
            <SuccessMessagePopup message={showSuccessMessage} onClose={handleSuccessMessageClose} />
        </div >
    )
}

function InviteUser({ defaultSelectedOrg }) {
    const [open, setOpen] = useState(false);

    const handleOnClose = (e) => {
        e && e.stopPropagation();
        setOpen(false);
    };

    return (
        <label onClick={() => setOpen(true)} className="font-weight-bold pointer">
            Invite User
            <InviteUserDialog defaultSelectedOrg={defaultSelectedOrg} open={open} onClose={handleOnClose} />
        </label>

    );
}

export default InviteUser;