import React, { useEffect, useRef } from "react";
import {
  select
} from "d3";
import * as d3 from 'd3';
import useResizeObserver from "./useResizeObserver";
import { toMillion } from "../../../common/Util";

const normalizeStackData = (data) => data.map(d => {
  const { Won, Lost, Open } = d;
  const total = Won + Lost + Open;
  const wonPercentage = total > 0 ? (Won / total) * 100 : 0;
  const lostPercentage = total > 0 ? (Lost / total) * 100 : 0;
  const openPercentage = total > 0 ? (Open / total) * 100 : 0;
  return {
    ...d,
    Won: wonPercentage,
    Lost: lostPercentage,
    Open: openPercentage
  }
});

/**
 * Component that renders a WeightDelChart
 */
function WeightDelChart({ data, keys, colors, xAxislabel }) {
  const svgRef = useRef();
  const wrapperRef = useRef();
  const dimensions = useResizeObserver(wrapperRef);


  // will be called initially and on every data change
  useEffect(() => {
    const svg = select(svgRef.current);
    const { width, height } = dimensions || wrapperRef.current.getBoundingClientRect();

    console.log(svg, 'Svg', +svg.attr("width"));

    const margin = { top: 20, right: 60, bottom: 30, left: 40 };
    // const width = +svg.attr("width") - margin.left - margin.right;
    // const height = +svg.attr("height") - margin.top - margin.bottom;
    const g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    const x = d3.scaleBand()
      .range([0, width])
      .padding(0.7)

    const y = d3.scaleLinear()
      .range([height, 0]);

    const z = d3.scaleOrdinal()
      .range(["#ED5230", "#4A2B24", "#F68E77"]);

    const stack = d3.stack()
      .order(d3.stackOrderNone)
      .offset(d3.stackOffsetExpand);

    const processedData = data.map((d) => ({
      name: d.month,
      won: d.Won,
      gross: d.Gross,
      open: d.Open,
      total: d.Won + d.Gross + d.Open
    }));

    console.log(data, processedData, 'PrcoessedData');

    processedData.forEach(function (d) { d.satisfied = d.totalHours - d.leftHours });
    processedData.sort(function (a, b) { return b.totalHours - a.totalHours; });

    console.log(processedData, "Data");

    x.domain(processedData.map(function (d) { return d.name; }));
    z.domain(["won", "lost", "open"]);

    const serie = g.selectAll(".serie")
      .data(stack.keys(["won", "lost", "open"])(processedData))
      .enter().append("g")
      .attr("class", "serie")
      .attr("fill", function (d) { console.log(d, 'd', z(d.key)); return z(d.key); });

    serie.selectAll("rect")
      .data(function (d) { return d; })
      .enter().append("rect")
      .attr('class', 'bar')
      .attr("x", function (d) { return x(d.data.name); })
      .attr("y", function (d) { console.log(y(d[1])); return y(d[1]); })
      .attr("height", function (d) { return y(d[0]) - y(d[1]); })
      .attr("width", x.bandwidth());

    g.append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(x));

    g.append("g")
      .call(d3.axisLeft(y).ticks(10, "%"));


    svg.selectAll("text")
      .attr("fill", "#707070")
      .style('font-size', '11px')
      .style('font-family', 'Open Sans, sans-serif');

    svg.selectAll("line")
      .attr('stroke', "lightgray");

    svg.selectAll('path')
      .attr('stroke', "lightgray");

    const bar = svg.selectAll('.bar');
    console.log(bar, 'Bar');

    // d3.select('#toolTip').remove();
    const tooltip = d3.select('body').append('div').attr('class', 'toolTip');

    bar.on('mousemove', d => mousemove(d))
      .on('mouseout', d => mouseout());

    function mousemove(d) {
      tooltip.style('left', d3.event.pageX - 30 + 'px')
        .style('top', d3.event.pageY - 60 + 'px')
        .style('display', 'inline-block')
        .style('position', 'absolute')
        .style('min-width', '50px')
        .style('width', 'auto')
        .style('height', 'auto')
        .style('background', 'none repeat scroll 0 0 #ffffff')
        .style('border', '1px solid #fff')
        .style('border-radius', '5px 5px 5px 5px')
        .style('box-shadow', '-3px 3px 15px #888888')
        .style('padding', '5px')
        .style('text-align', 'left')
        .style('font-size', '12px')
        .style('font-family', 'Open Sans, sans-serif');
      // console.log(d, "D Mouse Move");

      const html = `<strong>${d.data.name}</strong><br>Won: ${toMillion(d.data.won)}, Open: ${toMillion(d.data.open)}, Gross: ${toMillion(d.data.gross)},`
      tooltip.html(html);
    }

    function mouseout() {
      tooltip.style('display', 'none');
    }

  }, [colors, data, dimensions, keys]);

  return (
    <React.Fragment>
      <div ref={wrapperRef} style={{ height: '200px', width: '95%' }}>
        <svg ref={svgRef} key={Math.random()} style={{ height: '240px', width: '105%' }}>
        </svg>
      </div>
    </React.Fragment>
  );
}

export default WeightDelChart;