import React, { Component } from "react";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import styles from "./subscription.module.scss";
import styled from "styled-components";
import { Select, MenuItem } from "@material-ui/core";
import lead_warning from "../../../assets/caution.png";
import img1 from "../../../assets/images/administration/1.png";
import img2 from "../../../assets/images/administration/2.png";
import img3 from "../../../assets/images/administration/3.png";
import img5 from "../../../assets/images/administration/5.png";
import administrator from "../../../assets/subscription/administrator.svg";
import businessman from "../../../assets/subscription/businessman.svg";
import favorite from "../../../assets/subscription/favorite.svg";
import { connect } from "react-redux";
import { getAllPabblyRequiredData, getOrganizationList, getAccountLicenceCount  } from "./redux/action";
import { getStorageItem, CURR } from "../../common/common-functions";
import { CURRENCY_SYMBOL } from '../../common/common-types';
import CloseIcon from '@material-ui/icons/Close';
import {selectMypipelineOrg} from '../MyPipeline/redux/actions';
import axiosInstance from "../../common/api-config";
import LargeScreenLoader from '../../common/Loaders/PulseLoader/pulse-loader';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';


const RenewalTitle = styled.span`
  font-weight: 620 !important;
  font-size: 1em !important;
`;

function formatDate(date) {
  return moment(date).format('DD-MMM-YYYY');
}

class SubscriptionsContainer extends Component {
  constructor(props) {       
    super(props);
    // this.handleChangeLicence = this.handleChangeLicence.bind(this);
    this.state = {
      open: false,
      all:"",
      isOpenDropdownContent: false,
      isFilterDropdownContent: "",
      selection: -1,
      organizationList:[],
      selection:null,
      hide:false
    };
    this.setOrganization = this.setOrganization.bind(this); 
  }

  componentDidMount() {
    this.props.getAllPabblyRequiredData();
    this.props.getAccountLicenceCount(JSON.parse(getStorageItem("user")).organization_id); 
    this.getOrgList();
  };
  clicked(){
    console.log(this.state.hide,"hidehide");
    this.setState({
      hide: true
    })
}
  getOrgList = () => {
    const user = JSON.parse(getStorageItem("user"));
    axiosInstance
      .post("/account/getOrganizationList", {})
      .then(response => {
        if (response.data.statusCode === 200) {

          if (response.data.data.rows.length > 0) {
            this.setState({
              organizationList: response.data.data.rows,
              selection: response.data.data.rows.filter(organization => (organization.id === user.organization_id))[0]
            });
          } else {
            this.setState({
              organizationList: response.data.data.rows,
              // selection: response.data.data.rows[0].id
              selection: 0
            });
          }


        } else {
          this.setState({
            organizationList: []
          });
        }
      })
      .catch(error => {
        this.setState({
          organizationList: []
        });
      });
  };
  componentDidUpdate(prevProps,preState){
  //  if{ const user = JSON.parse(getStorageItem("user"));
  //   this.props.organizationListData && this.props.organizationListData.length!=null && this.props.selectOrg(this.props.organizationListData.filter(organization => (organization.id === user.organization_id)));
  //   }
  //   if (this.props && this.props.orgSelected && this.props.orgSelected.id !== preState.orgSelected && preState.orgSelected.id) {
  //     console.log("preState",this.props.orgSelected);
  //   }
  }

  setOrganization = selectedOption => {  
    this.props.getAccountLicenceCount(selectedOption.id); 
    // this.props.selectOrg(selectedOption);
    this.setState({ isOpenDropdownContent: false })
    this.setState({selection:selectedOption})
  };

  // handleChangeLicence(event) {
  //   this.props.getAccountLicenceCount(event.target.value); console.log("props",event.target.value);
  //   const checkOrg = this.props && this.props.organizationListData.filter((getOrganization) => getOrganization.id==event.target.value)
  //   let currOrg = checkOrg[0].name
  //   this.setState({all:currOrg})
  // }

  setFilterDropdownContent=(e)=>{
    this.setState({isFilterDropdownContent:e})
  }

  openDropdownContent = () => {
    this.setState({ isOpenDropdownContent:  this.state.isOpenDropdownContent?false:true })
  };


  render() {
    const iconImages = {
      1: img1,
      2: img2,
      3: img3,
      5: img5,
    }
    const userIcon = (id) => {
      const filtered = Object.keys(iconImages)
        .filter(key => key == id)
        .reduce((obj, key) => {
          obj[key] = iconImages[key];
          return obj;
        }, {});
      return Object.values(filtered)[0];
    }

    const dateFormater = ((date) => {
      return new Date(date).toString().split(' ').slice(0, 5).join(' ');
    })

    const handleClickOpen = () => {
      this.setState({ open: true });
    };

    const handleClose = () => {
      this.setState({ open: false });
    };

    const formatDesc = (event) => {
      const str = event.substring(3).slice(0, -4);
      return str;
    };

    let newLicenceCount = this.props && this.props.licenceCount && this.props.licenceCount.rows && this.props.licenceCount.rows.filter(item => item.license_type.display_name != JSON.parse(getStorageItem("user")).license_type.display_name)
    return (
      <div className="main-wrapper">
        <div className="breadcrumbs">
          <div className="row justify-content-between">
            <div className="col-md-4">
              <h3 className={styles.title_Administration}>Subscription Managemment</h3>
            </div>
          </div>
          {!this.props.manage_link && <LargeScreenLoader />}
          <div className="breadcrumbs_container">
            <Breadcrumbs separator="›" arial-label="Breadcrumb">
              <Link to="/sales-news">Home</Link>
              <Link to="/administration">Administration</Link>
              <span>Subscriptions Managemment</span>
            </Breadcrumbs>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className={"col-md-3 " + styles.title_Users} >
          <div className={styles.cust_dropdown}>
            <div className={this.state.isOpenDropdownContent ? styles.cust_dropdown_heading_open : styles.cust_dropdown_heading} onClick={this.openDropdownContent}>
              <span className={styles.cust_dropdown_heading_value}>{this.state&&this.state.selection&&this.state.selection.name}</span>
              <span className={styles.cust_dropdown_heading_dropdown_arrow}>{this.state.isOpenDropdownContent ? <span className={styles.cust_up_arrow}></span> : <span className={styles.cust_dwn_arrow}></span>}</span>
            </div>
            {this.state.isOpenDropdownContent && <div className={styles.cust_dropdown_content}>
              <div className={styles.cust_select_panel}>
                <div className={styles.cust_search_box}>
                  <input className={styles.cust_search_input} value={this.state.isFilterDropdownContent} onChange={(e) => this.setFilterDropdownContent(e.target.value)} placeholder="Search here" type="text" />
                </div>
                <ul className={styles.cust_select_list}>
                  <li className={styles.cust_select_li} onClick={()=>this.setOrganization({ id: 0, name: "All" })}>
                    {this.state.selection.id === 0 ?
                      <label className={styles.cust_item}><b>{"All"}</b></label> :
                      <label className={styles.cust_item}>{"All"}</label>
                    }
                  </li>
                  {this.state.organizationList.filter(org => org.name.toLowerCase().includes(this.state.isFilterDropdownContent.toLowerCase())).map(
                    orgItem =>
                      <li className={this.state.selection.id!== orgItem.id ? styles.cust_select_li : styles.cust_select_item} onClick={() => this.setOrganization(orgItem)}>
                        {this.state.selection.id === orgItem.id ?
                          <label className={styles.cust_item}><b>{orgItem.name}</b></label> :
                          <label className={styles.cust_item}>{orgItem.name}</label>
                        }
                      </li>
                  )}
                </ul>
              </div>
            </div>}
          </div>
            {/* <Select onClick={this.handleChangeLicence}
              style={{
                fontWeight: "bold" 
              }}
              value="0"
              disableUnderline
              id="select"
            >
              {this.props && this.props.organizationListData && this.props.organizationListData.length > 1 &&
                <MenuItem key={0} value={0}>
                  {this.state.all==""?"All":this.state.all}
                </MenuItem>
              }
              {this.props && this.props.organizationListData && this.props.organizationListData.map((organizationList) => (
                <MenuItem key={organizationList.id} value={organizationList.id} style={{ width: "140px" }}>
                  {organizationList.name}
                </MenuItem>
              ))}
            </Select> */}
          </div>
          <div className={"col-md-9 " + styles.headerBtn}>
            <Button variant="contained" style={{ backgroundColor: "#3A4860", color: "#fff", textTransform: "none" }} size="small">
              <a target="_blank"
                className={styles.RenewalText}
                style={{ backgroundColor: "#3A4860", textTransform: "none" }}
                href={this.props.manage_link}>Manage Subscription</a>
            </Button>
          </div>
        </div>
        <div className={styles.usercount_flex}>
          {
            newLicenceCount && newLicenceCount.map((licenses) => {
              return (
                <div className="flex-item border-right">
                  <div className="row">
                    <div className={"col-sm-6 " + styles.flex_item_content}>
                      <img
                        className={styles.icon_pic}
                        src={userIcon(licenses.license_type_id)}
                        alt="LeadIcon"
                      />
                    </div>
                    <div className={"col-sm-6 " + styles.flex_item_values}>
                      <div className={styles.flex_userCount}>
                        <div className={styles.userCount_value}>
                          <span style={{ fontWeight: "bold" }}>{licenses.total_in_use}</span>/<span>{licenses.purchased_quantity}</span>
                        </div>
                        <p className={styles.userCount_title}>{licenses.license_type.display_name}</p>
                      </div>
                    </div>
                  </div>
                </div>)
            })}
        </div>

        {/*/*Subscriptions Section*/}
        <div className={styles.title_right_text}>
          Subscriptions
        </div>
        <ul className={styles.cards}>
          {
            this.props &&
            this.props.allPlansData && this.props.allPlansData.map((subscriptionPlans) => {
              console.log(subscriptionPlans.plan_description,"subscriptionPlans");
              return (<li className={styles.cards__item}>
                <div className={styles.card}>
                  <div className={styles.card__title}>
                    <h4>{subscriptionPlans.plan_name}</h4>
                    {subscriptionPlans.has_bought &&
                      <span className={styles.checkmark}>
                        <div className={styles.checkmark_stem}></div>
                        <div className={styles.checkmark_kick}></div>
                      </span>}
                    <hr className={styles.hr} />
                  </div>
                  <div className={styles.card__content}>
                    <div className="row">
                      <div className="col-md-4">
                        <div className={styles.card_title_text}>{"Type"}</div>
                        <div className={styles.card_content_inner}>{subscriptionPlans.billing_period == "m" ? 'Monthly' : 'Yearly'}</div>
                      </div>
                      <div className="col-md-8">
                        <div className={styles.card_title_text}>{"Plan"}</div>
                        <div className={styles.card_content_inner}>{subscriptionPlans.plan_name}</div>
                      </div>
                      <hr className={styles.hr} />
                    </div>
                    <div className="row" style={{height: 200}}>
                   
                      <div className="col-md-4">
                        <div className={styles.card_title_text}>{"Description"}</div>
                        
                        <div className={styles.card_truncation}>
                          <div dangerouslySetInnerHTML={{__html: subscriptionPlans.plan_description}} >
                          </div>
                          
                        </div>
                        <PopupState variant="popover" popupId="demo-popup-popover">
                          {(popupState) => (
                            <div>
                              <Button variant="contained" className={styles.popoverClass} color="primary" {...bindTrigger(popupState)}>
                                Show more
                              </Button>
                              <Popover
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'center',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'center',
                                }}
                              >
                                <Box p={2}>
                                  <Typography>
                                    <div dangerouslySetInnerHTML={{__html: subscriptionPlans.plan_description}}></div>
                                </Typography>
                                </Box>
                              </Popover>
                            </div>
                          )}
                        </PopupState>
                      </div>
                      <div className="col-md-8">
                        <div className={styles.card_title_text}>{"Billing Cycle"}</div>
                        <div className={styles.card_content_inner}>
                          {subscriptionPlans.activation_date && (<>{`Plan Activation Date : ${formatDate(subscriptionPlans.activation_date)}`} <br /> </>)}
                          {subscriptionPlans.expiry_date && (<>{`Plan Expiry Date : ${formatDate(subscriptionPlans.expiry_date)}`} <br /> </>)}
                          {subscriptionPlans.trial_expiry_date && (<>{`Trial Expiry Date : ${formatDate(subscriptionPlans.trial_expiry_date)}`} <br /> </>)}
                          {subscriptionPlans.last_billing_date && (<>{`Last Billing Date : ${formatDate(subscriptionPlans.last_billing_date)}`} <br /> </>)}
                          {subscriptionPlans.next_billing_date && (<>{`Next Billing Date : ${formatDate(subscriptionPlans.next_billing_date)}`} <br /> </>)}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className={styles.card_title_text}>{"Total Amount"}</div>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className={"col-md-6 " + styles.card_title_text}>{CURRENCY_SYMBOL}{subscriptionPlans.price}</div>
                          <div className="col-md-6">
                            {subscriptionPlans.has_bought ? (<button className={styles.card_button} style={{ background: "#3A4860" }}>
                                  <a target="_blank" href={subscriptionPlans.manage_link}>{"Manage"}</a>
                                </button>) : (<button className={styles.card_button} style={{ background: "#3A4860" }}>
                                  <a target="_blank" href={subscriptionPlans.buy_link}>{"Buy"}</a>
                                </button>) }

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>)
            })}
        </ul>

        {/*/*Addons Section*/}
        <div className={"row " + "container"}>
          <span className={styles.addons_title}>{"Addons" + " "} </span>
          {/* <span className={styles.addons_note1}> {"Your Subscription will expire in 15 days." + " "}</span>{" "}
          <span className={styles.addons_note2}>{" Renew Now"}</span> */}
        </div>
        <ul className={styles.cards}>
          {
            this.props &&
            this.props.customerSubscriptionAddOnData && this.props.customerSubscriptionAddOnData.map((addonSubscription) => {
              return (
                <li className={styles.cards__item}>
                  <div className={styles.card_addons}>  
                    <div className={styles.warnIconRightCorner}>
                    <img
                        src={lead_warning}
                        alt="LeadIcon"
                    />  
                    </div>
                    <div className={styles.card_addons__title}>
                      <h4>{addonSubscription.name}</h4>
                      <hr className={styles.hr} />
                    </div>
                    <div className={styles.card_addons__content}>
                      <div className="row">
                        {/* <div className="col-md-3">
                  { <div className={styles.card_addons_title_text}>User</div>
                  <p className={styles.card_addons_content_inner}> 8 users </p> }
                </div> */}
                        <div className="col-md-4">
                          <div className={styles.card_addons_title_text}>Type</div>
                          <div className={styles.card_addons_content_inner}>{addonSubscription.billing_period == "m" ? 'Monthly' : 'Yearly'}</div>
                        </div>
                        <div className="col-md-8">
                          <div className={styles.card_addons_title_text}>Category</div>
                          {addonSubscription && addonSubscription.category_list && addonSubscription.category_list.map((category) => {
                            return (
                              <div className={styles.card_addons_content_inner}>{category.category_name}</div>
                            )
                          })}
                        </div>
                        <hr className={styles.hr} />
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className={styles.card_addons_title_text}>Description</div>
                          <div className={styles.card_addons_content_inner}>
                            <ul>
                              <li>{addonSubscription.name}</li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div className={styles.card_addons_title_text}>Billing Cycle</div>
                          <div className={styles.card_content_inner}>
                            {addonSubscription.activation_date && (<>{`Plan Activation Date : ${formatDate(addonSubscription.activation_date)}`} <br /> </>)}
                            {addonSubscription.expiry_date && (<>{`Plan Expiry Date : ${formatDate(addonSubscription.expiry_date)}`} <br /> </>)}
                            {addonSubscription.trial_expiry_date && (<>{`Trial Expiry Date : ${formatDate(addonSubscription.trial_expiry_date)}`} <br /> </>)}
                            {addonSubscription.last_billing_date && (<>{`Last Billing Date : ${formatDate(addonSubscription.last_billing_date)}`} <br /> </>)}
                            {addonSubscription.next_billing_date && (<>{`Next Billing Date : ${formatDate(addonSubscription.next_billing_date)}`} <br /> </>)}
                          </div>
                        </div>
                        <hr className={styles.hr} />
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className={styles.card_addons_title_text}>Total Amount</div>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            <div className={"col-md-6 " + styles.card_addons_title_text}>{CURRENCY_SYMBOL}{addonSubscription.price}</div>
                            <div className="col-md-6">
                              {/* <button className={styles.card_addons_button} onClick={handleClickOpen} open={this.state.open}>
                                Renew
                              </button> */}
                              <Dialog open={this.state.open} onClose={handleClose} aria-labelledby="form-dialog-title">
                                <DialogTitle style={{ textAlign: "center" }} id="form-dialog-title">Renew</DialogTitle>
                                <DialogContent>
                                  <DialogContentText>
                                    <p>For Renewal or more information please contact us at </p>
                                    <p style={{ textAlign: "center", fontWeight: 'bold', textDecorationLine: 'underline' }}>customersuccess@leadx360.com</p>
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <Button onClick={handleClose} color="primary">
                                    Cancel
                                  </Button>
                                  <Button onClick={handleClose} color="primary">
                                    <a href={addonSubscription.checkout_page} target="_blank">Contact Us</a>
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              )
            })}
        </ul>
      </div>
    )
  }
}

const mapStateToProps = state => { 
  return {
    acessUrlData: state && state.subscriptions && state.subscriptions.acessUrlData,
    manage_link: state && state.subscriptions.manage_link,
    customerSubscriptionData: state && state.subscriptions && state.subscriptions.customerSubscriptionData,
    customerSubscriptionAddOnData: state && state.subscriptions && state.subscriptions.customerSubscriptionAdOnData,
    allRemainingSubscriptionCountData: state && state.subscriptions && state.subscriptions.allRemainingSubscriptionCount,
    allPlansData: state && state.subscriptions && state.subscriptions.allPlansData,
    organizationListData: state && state.subscriptions&& state.subscriptions.organizationListData,
    licenceCount: state && state.subscriptions && state.subscriptions.licenceCount,
    orgSelected: state.mypipeline.mypipelineOrgSelected,
  }

};

const mapDispatchToProps = dispatch => {
  return {
    getAllPabblyRequiredData: () => dispatch(getAllPabblyRequiredData()),
    getOrganizationList: () => dispatch(getOrganizationList()),
    getAccountLicenceCount: (id) => dispatch(getAccountLicenceCount(id)),
    selectOrg: requestBody => dispatch(selectMypipelineOrg(requestBody)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionsContainer);