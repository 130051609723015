import React from "react";
import styles from "./NewLead.module.scss";
import Button from "@material-ui/core/Button";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import DatePicker from "react-date-picker";
import moment from "moment";
import handshake from "../../../../assets/images/content-images/grey-handshake.png"; //beforeHandshake.png';
import handshake1 from "../../../../assets/images/content-images/one-green-handshake.png"; //afterHandshake.png';
import handshake2 from "../../../../assets/images/content-images/two-green-handshake.png";

import closeIcon from "../../../../assets/images/spl/close.png";
import { openSnackbar } from "../../../common/Snackbar/snackbar";
import { FormErrors } from "../../../common/formErrors/formErrors";
import CircularProgress from "@material-ui/core/CircularProgress";
import AutoComplete from "../../../common/AutoComplete/autocomplete";
import AutoCompleteWithAdd from "../../../common/AutoCompleteWithAdd/autocompletewithadd";
import Stepper from "../../../common/Stepper/stepper";
import ArrowForward from "@material-ui/icons/ArrowForward";
import ArrowBack from "@material-ui/icons/ArrowBack";
import add_icon from "../../../../assets/images/content-images/lead_add_icon.png";
import axios from "../../../common/api-config";
import cx from "classnames";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  companyLeadName_regex,
  projectLocation_regex,
  leadValue_regex,
  commonValidation_regex,
  commonValidationWithLineBreak_regex,
} from "../../../common/common-types";
import {
  contactListRequestBody,
  salesFeedRequestBody,
} from "../../../common/apiParameters";

import Avatar from "@material-ui/core/Avatar";
import CropperDialog from "../../../common/CropperDialog/CropperDialog";
import SmallLoader from "../../../common/Loaders/CircularLoader/circular-loader";
import Tooltip from "@material-ui/core/Tooltip";
import imageCompression from "browser-image-compression";
import { fileNameFromUrl } from "../../../common/common-functions";
import LargeScreenLoader from "../../../common/Loaders/PulseLoader/pulse-loader";
import SmallScaleLoader from "../../../common/Loaders/SyncLoader/sync-loader";
import { orgData } from "./sample";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    maxWidth: "120px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}))(Tooltip);

const DialogTitle = withStyles((theme) => ({
  root: {
    width: "100%",
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))((props) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    width: "100%",
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    width: "100%",
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions);

const getData = (nextProps) => {
  if (window.location.pathname == "/sales-news") {
    const requestBody = salesFeedRequestBody;
    requestBody.organizationArray = nextProps.selectedOrganizationList;
    requestBody.paginate = {
      page: "0",
      limit: "10",
    };
    nextProps.initializeSalesFeeds(requestBody);
  } else if (window.location.pathname == "/sales-community") {
    let organizationArray =
      nextProps.selectedSalesCommunityOrganization !== null
        ? nextProps.selectedSalesCommunityOrganization.map((org) => org.id)
        : [nextProps.currentUser.organization_id];

    let reqParams = {
      revenueType: "sales_community",
      organizationArray: organizationArray,
    };
    nextProps.getRevenueCommunity(reqParams);
  } else if (window.location.pathname == "/my-pipeline") {
    //handled get lead api in my-pipeline with respect to filters

    let organizationArray =
      nextProps.mypipelineOrgSelected !== null
        ? [nextProps.mypipelineOrgSelected.id]
        : [nextProps.currentUser.organization_id];

    let current_user_selection =
      nextProps.mypipelineUserSelected === null
        ? nextProps.currentUser.user_id
        : nextProps.mypipelineUserSelected.id;

    let licenseType =
      nextProps.mypipelineUserSelected === null
        ? nextProps.currentUser.license_type.actual_name
        : nextProps.mypipelineUserSelected.license_type.actual_name;

    const revenueParams = {
      revenueType: "my_pipeline",
      organizationArray: organizationArray,
      user_id: current_user_selection,
      license_type: licenseType,
    };
    nextProps.getRevenue(revenueParams);
  }
};

const currentUserHasExpectedLicenseType = (currentUser, expectedLicenseTypes) => {
  const license_type = currentUser && currentUser.license_type;
  return license_type ? expectedLicenseTypes.includes(license_type.actual_name) : false;
};

class NewLead extends React.Component {
  formErrors = {
    lead_title: "",
    company_id: "",
    contact_id: "",
    contact_person_phone: "",
    lead_source_id: "",
    expected_closing_date: "",
    lead_value: "",
    is_confidential: "",
    notes: "",
    category_id: "",
    linkedin_profile: "",
  };

  constructor(props) {
    super(props);
    this.fileValue = null;
    this.textInput = null;
    this.state = {
      lead_id: null,
      lead_title: "",

      company_id: "",
      newAddedCompany: "",

      contact_id: "",
      newAddedContactPerson: "",

      contact_person_country_code: "",
      contact_person_phone: "",
      lead_source_id: "",
      //category
      category_id: "",
      newAddedCategory: "",
      expected_closing_date: "", //moment(new Date()).format('YYYY-MM-DD'),
      lead_value: "",
      currency_id: this.props.currentUser && this.props.currentUser.currency.id,
      is_confidential: 0,
      project_location: "",
      is_hand_over: this.getInitialIsHandOver(),
      asssignedTo_id: "",
      assignedOrg_id: "",
      notes: "",
      lead_status: "",
      lead_status_id: "",
      is_won: null,
      formValid: false,
      errorMessage: [],
      selectedYESRadio: "",
      selectedNORadio: "",
      handshakeImg: handshake,
      stepper1: "",
      stepper2: "",
      stepper3: "",
      stepper4: "",
      stepper5: "",
      currentUser: "",
      selectedWinBtn: "",
      selectedLossBtn: "",
      selectedParkBtn: "",
      open: false,
      personList: [],
      resetPerson: false,
      NoPersonFlag: false,
      submitButtonBlur: false,

      uploadedImage: "",
      cropperDialog: false,
      cropResult: "",
      uploadedFiles: [],
      uploadFilesLoader: false,
      linkedin_profile: "http://",
      asyncCompanyList: [],
      loadingCompanyList: false,
    };
  }

  getInitialIsHandOver() {
    const { currentLead, currentUser } = this.props;
    if (currentLead) {
      if (currentUserHasExpectedLicenseType(currentUser, ['MANAGER', 'PROFESSIONAL'])) {
        return currentLead.is_accepted ? 2 : 1;
      }

      return currentLead.is_hand_over;
    }

    return currentUserHasExpectedLicenseType(currentUser, ['MANAGER', 'PROFESSIONAL']) ? 1 : 0;
  }

  componentDidMount() {
    this.textInput.focus();
    //get lead source master
    let params = {
      arrayFilters: [{}],
      selectFilters: [],
      sort: {
        field: "name",
        sortOrder: "ASC",
      },
      paginate: {
        page: "",
        limit: "",
      },
    };

    this.props.getLeadSources(params);

    let reqParams = {
      arrayFilters: [{}],
      selectFilters: [],
      sort: {
        field: "short_name",
        sortOrder: "ASC",
      },
      paginate: {
        page: "",
        limit: "",
      },
    };
    this.props.getCurrencyMaster(reqParams);

    if (this.state.is_confidential) {
      this.addClass("yes");
    } else {
      this.addClass("no");
    }
    // fetching data for edit
    if (this.props.currentLead) {
      let currentLead = this.props.currentLead;
      this.setState({
        lead_id: currentLead.id,
        lead_title: currentLead.lead_title ? currentLead.lead_title : "",

        company_id: currentLead.contact_company
          ? currentLead.contact_company.id + ""
          : "",
        contact_id: currentLead.contact_person
          ? currentLead.contact_person.id + ""
          : "",
        linkedin_profile: currentLead.linkedin_profile
          ? currentLead.linkedin_profile
          : "http://",
        contact_person_country_code:
          currentLead.contact_person && currentLead.contact_person.countryCode
            ? currentLead.contact_person.countryCode.id
            : "",
        contact_person_phone:
          currentLead.contact_person && currentLead.contact_person.phone_number
            ? currentLead.contact_person.phone_number
            : "",
        lead_value: currentLead.lead_value ? currentLead.lead_value + "" : "",
        currency_id:
          currentLead.currency && currentLead.currency.id
            ? currentLead.currency.id
            : "",
        expected_closing_date: currentLead.expected_closing_date
          ? moment(currentLead.expected_closing_date).format("YYYY-MM-DD")
          : "",
        is_confidential: currentLead.is_confidential
          ? currentLead.is_confidential
          : 0,
        project_location: currentLead.project_location
          ? currentLead.project_location
          : "",
        notes: "", // currentLead.notes && currentLead.notes != null ? currentLead.notes : '',
        old_notes: currentLead.notes ? currentLead.notes : "",
        lead_status:
          currentLead.lead_status && currentLead.lead_status.name
            ? currentLead.lead_status.name
            : "",
        lead_status_id:
          currentLead.lead_status && currentLead.lead_status.id
            ? currentLead.lead_status.id
            : "",
        lead_source_id:
          currentLead.lead_source && currentLead.lead_source.id
            ? currentLead.lead_source.id
            : "",
        // category added
        category_id:
          currentLead.category && currentLead.category.id
            ? currentLead.category.id
            : "",

        is_hand_over:
          currentLead.is_hand_over !== undefined &&
            currentLead.is_hand_over !== null
            ? currentLead.is_hand_over
            : 0,
        createdBy_id:
          currentLead.createdBy && currentLead.createdBy.id
            ? currentLead.createdBy.id
            : 0,
        asssignedTo_id:
          currentLead.assignedTo && currentLead.assignedTo.id && currentLead.is_hand_over == 2
            ? currentLead.assignedTo.id
            : 0,
        assignedOrg_id: "",
        // currentLead.assignedTo && currentLead.assignedTo.id
        //   ? currentLead.assignedTo.id
        //   : 0,
      });

      //get status logs
      let params = {
        lead_id: currentLead.id,
      };
      this.props.getStatusLogs(params);

      if (currentLead.is_confidential) {
        this.addClass("yes");
      } else {
        this.addClass("no");
      }

      //get persons, if company is selected
      if (currentLead.contact_company && currentLead.contact_company.id) {
        this.getPersons(currentLead.contact_company.id);
      }

      if (
        this.props.currentUser &&
        this.props.currentUser.license_type &&
        (this.props.currentUser.license_type.actual_name === "MANAGER" ||
          this.props.currentUser.license_type.actual_name === "PROFESSIONAL") &&
        this.state.is_hand_over
      ) {
        this.setState({
          handshakeImg: handshake1,
        });
      }
    } else if (
      this.props.currentUser &&
      this.props.currentUser.license_type &&
      (this.props.currentUser.license_type.actual_name === "MANAGER" ||
        this.props.currentUser.license_type.actual_name === "PROFESSIONAL")
    ) {
      //if sales person creating new lead, set status to lead(for stepper)
      this.setState({
        lead_status_id: 2,
        lead_status: "Lead",
      });
    }

    //get assigned to users list is professional licence
    // JAY
    if (
      this.props.currentUser &&
      this.props.currentUser.license_type &&
      (this.props.currentUser.license_type.actual_name === "MANAGER" ||
        this.props.currentUser.license_type.actual_name === "PROFESSIONAL") &&
      this.state.assignedOrg_id === this.state.assignedOrg_id
    ) {
      this.props.getAssignedToList({ license_type_ids: [2, 3] });
      this.props.getOrgChildList({});
      // if (this.props.currentLead === undefined || (this.props.currentLead && this.props.currentLead.assignedTo && this.props.currentLead.is_hand_over == 2)) {
      //   this.setState({
      //     asssignedTo_id: this.props.currentUser.user_id,
      //   });
      // }
      if (this.props.currentLead === undefined || (this.props.currentLead && this.props.currentLead.assignedTo)) {
        this.setState({
          asssignedTo_id: this.props.currentUser.user_id,
        });
      }
    }

    //setting the default category to product
    if (this.props.leadCategoryList && this.props.currentLead === undefined) {
      this.props.leadCategoryList.find((category) => {
        if (category.name.toLowerCase() === "product/service") {
          this.setState({
            category_id: category.id,
          });
        }
      });
    }
  }

  componentWillUnmount() {
    this.props.resetDocuments();
  }

  // JAY
  updateTeamMember() {
    if (
      this.props.currentUser &&
      this.props.currentUser.license_type &&
      (this.props.currentUser.license_type.actual_name === "MANAGER" ||
        this.props.currentUser.license_type.actual_name === "PROFESSIONAL") &&
      this.state.assignedOrg_id ===
      this.props.currentUser.organization_id.toString()
    ) {
      // this.props.getAssignedToList({});
      // if (this.props.currentUser.user_id == this.state.asssignedTo_id) {
      if (this.state.asssignedTo_id == 0) {
        this.setState({
          asssignedTo_id: this.props.currentUser.user_id,
        });
      }

      // }
    } else {
      this.setState({
        asssignedTo_id: 0,
      });
    }
  }

  getCategorySources() {
    let categoryParams = {
      arrayFilters: [{}],
      selectFilters: ["id", "name"],
      sort: {
        field: "name",
        sortOrder: "ASC",
      },
      // "paginate": {
      //     "page": 0,
      //     "limit": 100
      // }
    };

    this.props.getCategorySources(categoryParams);
  }

  async getPersons(company_id) {
    if (company_id) {
      let params = {
        arrayFilters: [
          {
            is_deleted: 0,
            company_id: company_id,
          },
        ],
        selectFilters: [
          "id",
          "initials",
          "first_name",
          "last_name",
          "phone_number",
          "country_code",
          "email"
        ],
        sort: {
          field: "first_name",
          sortOrder: "ASC",
        },
        paginate: {
          page: "",
          limit: "",
        },
      };

      await axios
        .post("/contact/person/get", params)
        .then(async (response) => {
          if (response && response.data && response.data.statusCode == 200) {
            await this.setState({
              personList: response.data.data.rows,
              personCount: response.data.data.count,
            });
          } else {
            await this.setState({
              personList: [],
              personCount: 0,
            });
          }
        })
        .catch((error) => {
          console.log(error.errorMessage);
        });
    }
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (
      nextProps &&
      nextProps.errorLeadSourceList &&
      nextProps.errorLeadSourceList !== prevState.errorLeadSourceList
    ) {
      nextProps.resetLead();
      nextProps.hideModal();
      openSnackbar("Oops, something went wrong!", "snack-error");
    }

    nextProps.resetLead();
    if (
      nextProps &&
      nextProps.lead &&
      !nextProps.leadUpdated &&
      nextProps.leadAdded
    ) {
      openSnackbar("Lead added successfully.", "snack-success");
      if (
        nextProps.leadAdded &&
        (nextProps.leadAdded[0].is_won === true ||
          nextProps.leadAdded[0].is_won === 1 ||
          nextProps.leadAdded[0].is_won === 0)
      ) {
        nextProps.leadAdded[0]["lead_status"] = { id: 6, name: "Closed" };
        nextProps.handleWinCallBack &&
          nextProps.handleWinCallBack(nextProps.leadAdded[0]);
      }
      nextProps.hideModal();
      getData(nextProps);
      return nextProps.lead;
    } else if (nextProps && nextProps.lead && nextProps.leadUpdated) {
      if (
        nextProps.leadUpdated &&
        (nextProps.leadUpdated[0].is_won === true ||
          nextProps.leadUpdated[0].is_won === 1 ||
          nextProps.leadUpdated[0].is_won === 0)
      ) {
        nextProps.leadUpdated[0]["lead_status"] = { id: 6, name: "Closed" };
        nextProps.handleWinCallBack &&
          nextProps.handleWinCallBack(nextProps.leadUpdated[0]);
        openSnackbar("Lead updated successfully.", "snack-success");
      } else {
        // nextProps.hideModal();
        if (nextProps.openLeadDetailCallBack) {
          nextProps.openLeadDetailCallBack({
            ...nextProps.leadUpdated[0],
            // lead_status: { id: 6, name: 'Closed' },
          });
        }
        openSnackbar("Lead updated successfully.", "snack-success");
      }
      getData(nextProps);
      return nextProps;
    }
    if (nextProps && nextProps.errorMessage) {
      openSnackbar(nextProps.errorMessage, "snack-error");
      nextProps.resetLead();
      nextProps.hideModal();
    } else if (
      nextProps &&
      nextProps.errorAddNewLead &&
      nextProps.errorAddNewLead !== prevState.errorAddNewLead
    ) {
      openSnackbar("Oops, something went wrong!", "snack-error");
      nextProps.resetLead();
      nextProps.hideModal();
    } else if (
      nextProps &&
      nextProps.errorUpdateLead &&
      nextProps.errorUpdateLead !== prevState.errorUpdateLead
    ) {
      openSnackbar("Oops, something went wrong!", "snack-error");
      nextProps.resetLead();
      nextProps.hideModal();
    }
    return nextProps;
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.state.category_id !== prevState.category_id) {
      this.getCategorySources();
    }

    if (
      this.props.newlyAddedCompany !== undefined &&
      this.props.newlyAddedCompany != prevProps.newlyAddedCompany
    ) {
      if (this.props.newlyAddedCompany.id) {
        this.getPersonList({ value: this.props.newlyAddedCompany.id });
        // this.setState({
        //     company_id: this.props.newlyAddedCompany.id
        // });
      }
      this.formErrors["company_id"] = "";
    } else if (
      this.props.newlyAddedPerson &&
      this.props.newlyAddedPerson != prevProps.newlyAddedPerson
    ) {
      await this.setState({ waitPersonFlag: true });
      await this.getPersonList({ value: this.state.company_id });
      await this.setState({ waitPersonFlag: false });
    }

    if (this.props.documents !== prevProps.documents) {
      // let params = {
      //   lead_id: this.props.currentLead.id
      // };
      // this.props.getStatusLogs(params);

      this.setState({
        uploadedFiles: this.props.documents,
      });
    }
  };

  getPersonList = async (event) => {
    this.handleUserInput(event);
    let company_id = 0;
    if (event && event.target) {
      company_id = event.target.value;
    } else if (event && event.value) {
      company_id = event.value;
    }

    if (company_id !== 0) {
      await this.setState({
        company_id,
        resetPerson: true,
        contact_id: "",
        contact_person_phone: "",
        newAddedCompany: "",
        newAddedContactPerson: "",
        submitButtonBlur: false,
      });
      await this.getPersons(company_id);
    }
  };

  getPersonPhone = (event) => {
    this.setState({
      resetPerson: false,
      submitButtonBlur: false,
    });
    this.handleUserInput(event);
    let contact_id = "";
    if (event && event.target) {
      contact_id = event.target.value;
    } else {
      contact_id = event.value;
    }
    var person = this.state.personList.filter((item) => {
      return parseInt(item.id) === parseInt(contact_id) || item.name === contact_id;
    });

    let phone = "";
    let PhoneValidation = '';
    let contact_name ="";
    if (event) {
      if (event.contact_person_phone) {
        phone = event.contact_person_phone;
        PhoneValidation = event.contact_person_phone;
        contact_name =  event.contact_name;
      }
      else if (person && person[0]) {
        PhoneValidation = person[0].phone_number;
        phone = person[0].phone_number;
        contact_id = person[0].id;
        contact_name = person[0].name;
      } else if (contact_id === '') {
        PhoneValidation = "-";
      }

      // if (
      //   person &&
      //   person[0] &&
      //   person[0].countryCode &&
      //   person[0].countryCode.hasOwnProperty("dialing_code")
      // ) {
      //   phone =
      //     "+" +
      //     person[0].countryCode.dialing_code +
      //     " " +
      //     event.contact_person_phone;
      // }
    }
    this.setState({
      contact_id: contact_id,
      //contact_name: event && event.contact_name ? event.contact_name : "",
      contact_name:contact_name,
      contact_country_code:
        person && person[0] && person[0].countryCode
          ? person[0].countryCode.dialing_code
          : "",
      contact_person_country_code:
        person && person[0] && person[0].countryCode
          ? person[0].countryCode.id
          : "",
      contact_person_phone: phone,
      contact_person_phone_empty:!PhoneValidation,
      newAddedCompany: "",
      newAddedContactPerson: "",
    });
  };

  setLeadSource = (event) => {
    this.handleUserInput(event);
  };

  // setCategorySource
  setCategorySource = async (event) => {
    await this.setState({ newAddedCategory: "", submitButtonBlur: false });
    this.handleUserInput(event);
  };

  addClass(key) {
    switch (key) {
      case "yes":
        this.setState({
          selectedYESRadio: styles.selectedBox,
          selectedNORadio: "",
          is_confidential: 1,
        });
        break;
      case "no":
        this.setState({
          selectedYESRadio: "",
          selectedNORadio: styles.selectedBox,
          is_confidential: 0,
        });
        break;
      case "win":
        this.setState({
          selectedWinBtn:
            this.state.selectedWinBtn != "" ? "" : styles.selectedBox,
          selectedLossBtn: "",
          selectedParkBtn: "",
          is_won: this.state.selectedWinBtn != "" ? null : 1,
          // lead_status_id: this.state.new_lead_status_id,// close lead
          assignedOrg_id: this.props.currentUser.organization_id,
          asssignedTo_id: this.state.currentUser.user_id,
        });
        this.formErrors["is_won"] = "";
        break;
      case "loss":
        this.setState({
          selectedWinBtn: "",
          selectedLossBtn:
            this.state.selectedLossBtn != "" ? "" : styles.selectedBox,
          selectedParkBtn: "",
          is_won: this.state.selectedLossBtn != "" ? null : 0,
          // lead_status_id: this.state.new_lead_status_id,// close lead
          assignedOrg_id: this.props.currentUser.organization_id,
          asssignedTo_id: this.state.currentUser.user_id,
        });
        this.formErrors["is_won"] = "";
        break;
      case "park":
        this.setState({
          selectedWinBtn: "",
          selectedLossBtn: "",
          selectedParkBtn:
            this.state.selectedParkBtn != "" ? "" : styles.selectedBox,
          is_won: this.state.selectedParkBtn != "" ? null : 2,
          assignedOrg_id: this.props.currentUser.organization_id,
          // lead_status_id: this.state.new_lead_status_id
        });
        this.formErrors["is_won"] = "";
        break;
      default:
        break;
    }
  }

  handoverToSales() {
    if (
      this.props.currentUser &&
      this.props.currentUser.license_type &&
      (this.props.currentUser.license_type.actual_name === "MANAGER" ||
        this.props.currentUser.license_type.actual_name === "PROFESSIONAL")
    ) {

      const is_hand_over = this.state.is_hand_over == 2 ? 1 : 2;
      //if handover button is clicked, assignedTo dropdown should have current user selected.
      if (is_hand_over === 2) {
        this.state.assignedOrg_id = this.props.currentUser.organization_id;
        this.state.asssignedTo_id = this.props.currentUser.user_id;
      } else {
        // this.state.asssignedTo_id = 0;
        this.state.assignedOrg_id = "";
      }

      this.setState({
        handshakeImg: this.state.is_hand_over == 2 ? handshake1 : handshake2,
        is_hand_over
      });

      this.formErrors["is_hand_over"] = "";
    } else if (
      this.props.currentUser &&
      this.props.currentUser.license_type &&
      (this.props.currentUser.license_type.actual_name === "BASIC" ||
        this.props.currentUser.license_type.actual_name === "LITE")
    ) {
      this.setState({
        handshakeImg: this.state.is_hand_over == 1 ? handshake : handshake1,
        is_hand_over:
          this.state.is_hand_over && this.state.is_hand_over == 1
            ? 0
            : this.state.is_hand_over == 0
              ? 1
              : 0,
      });
    }
  }

  async addLead() {
   

    const selectedOrg = this.state.assignedOrg_id || (this.props.currentLead && this.props.currentLead.account.id) || (!this.currentLead && this.props.currentUser.organization_id);
    //validate form
    let formValid = this.validateAllFieldsForm();
    var errorArr = Object.values(this.formErrors).filter((error) => {
      return error !== "";
    });
    if (formValid && errorArr.length === 0) {
      if (/^\d+$/.test(this.state.contact_id)) {
        await this.updatePerson();
      }
      else{
        await this.addNewPerson();
      }
      //if professional user updating handovered lead, check if he has clicked on handover image,
      //or he has assigned that lead to some one else
      if (
        this.props.currentUser &&
        this.props.currentUser.license_type &&
        (this.props.currentUser.license_type.actual_name === "MANAGER" ||
          this.props.currentUser.license_type.actual_name === "PROFESSIONAL") &&
        this.state.is_hand_over == 1 &&
        !selectedOrg
      ) {
        this.formErrors["is_hand_over"] = "Please accept this lead";
      } else {
        let params = {
          // "user_id": this.props.currentUser.user_id,
          category_id: this.state.category_id || null,

          lead_source_id: this.state.lead_source_id || null,
          lead_title: this.state.lead_title.trim(),
          company_id: this.state.company_id || null,
          expected_closing_date: this.state.expected_closing_date || null,
          lead_value: this.state.lead_value.trim(),
          currency_id: this.state.currency_id || null,
          is_confidential: this.state.is_confidential,
          contact_id: this.state.contact_id || null,
          contact_country_code: this.state.contact_country_code || null,
          contact_person_phone: this.state.contact_person_phone || null,
          project_location: this.state.project_location.trim(),
          is_hand_over: this.state.is_hand_over,
          assigned_to: null,
          organization_id: this.state.assignedOrg_id || null,
          is_won:
            this.state.is_won == 1 || this.state.is_won == 0
              ? this.state.is_won
              : this.state.is_won == 2
                ? 2
                : null,
          is_bell_ringed: 0,
          document: this.state.uploadedFiles,
          linkedin_profile:
            this.state.linkedin_profile.trim() === "http://"
              ? null
              : this.state.linkedin_profile.search("http") !== -1
                ? this.state.linkedin_profile
                : this.state.linkedin_profile.trim() === ""
                  ? null
                  : "http://" + this.state.linkedin_profile,
        };

        if (
          this.state.is_won === 1 ||
          this.state.is_won === true ||
          this.state.is_won === 0
        )
          this.setState({
            lead_status: "Closed",
            new_lead_status_id: 6,
            selectedParkBtn: "",
          });

        if (this.state.contact_person_phone) {
          let phoneArr = this.state.contact_person_phone.split(" ");
          params.contact_person_phone =
            phoneArr && phoneArr[1] ? phoneArr[1] : null;
        }
        if (this.state.notes && this.state.notes !== "") {
          params.notes = this.state.notes;
        }
        if (this.state.lead_status_id && this.state.lead_status_id != "") {
          params.lead_current_status_id = this.state.lead_status_id;
        } else if (
          this.props.currentUser &&
          this.props.currentUser.license_type &&
          (this.props.currentUser.license_type.actual_name === "MANAGER" ||
            this.props.currentUser.license_type.actual_name === "PROFESSIONAL")
        ) {
          params.lead_current_status_id = 2;
        } else if (
          this.props.currentUser &&
          this.props.currentUser.license_type &&
          (this.props.currentUser.license_type.actual_name === "BASIC" ||
            this.props.currentUser.license_type.actual_name === "LITE")
        ) {
          params.lead_current_status_id = 1;
        }
        //if status updated
        if (
          this.state.new_lead_status_id &&
          this.state.new_lead_status_id > this.state.lead_status_id
        ) {
          params.lead_current_status_id = this.state.new_lead_status_id;
        }
        if (
          this.state.new_lead_status_id &&
          this.state.new_lead_status_id == 6 &&
          this.state.is_won != 1 &&
          this.state.is_won != 0
        ) {
          this.formErrors["is_won"] = "Please select won or lost status";
          return;
        }
        //if "New" lead is handovered, change its status to "Lead"
        if (
          this.props.currentUser &&
          this.props.currentUser.license_type &&
          (this.props.currentUser.license_type.actual_name === "MANAGER" ||
            this.props.currentUser.license_type.actual_name ===
            "PROFESSIONAL") &&
          this.state.asssignedTo_id == this.props.currentUser.user_id &&
          this.state.is_hand_over == 2 &&
          this.state.lead_status_id == 1
        ) {
          params.lead_current_status_id = 2;
        }
        if (this.state.is_won == 1 || this.state.is_won == 0) {
          //if won/ loss close the lead
          params.lead_current_status_id = 6;
        }

        if (this.state.lead_id) {
          params.id = this.state.lead_id;
          params.is_bell_ringed = 0;
          params.assigned_to =
            this.state.asssignedTo_id == "" ? null : this.state.asssignedTo_id;

          this.setState({
            submitButton: true,
          });
          await this.props.updateLead(params);
          this.props.getPoints();
        } else {
          //if sales professional adds a lead, assign it to himself or someone else
          if (
            this.props.currentUser &&
            this.props.currentUser.license_type &&
            (this.props.currentUser.license_type.actual_name === "MANAGER" ||
              this.props.currentUser.license_type.actual_name ===
              "PROFESSIONAL") &&
            this.state.asssignedTo_id &&
            this.state.asssignedTo_id != ""
          ) {
            params.assigned_to =
              this.state.asssignedTo_id == ""
                ? null
                : this.state.asssignedTo_id;
          }
          this.setState({
            submitButton: true,
          });
          console.log(params, 'Add Lead')
          await this.props.addLead(params);
          this.props.getPoints();
          let fromValue =
            window.location.pathname === "/sales-community"
              ? "sales-community"
              : window.location.pathname === "/my-pipeline"
                ? "my_pipeline"
                : "";

          const filterVals = {};
          if (fromValue === "my_pipeline" && this.props.parkedSelected) {
            filterVals.is_won = 2;
          }
          if (this.props.filterList.length !== 0) {
            filterVals.lead_current_status_id = this.props.filterList;
          }

          let organizationArray = [this.props.currentUser.organization_id];

          if (
            window.location.pathname === "/sales-community" &&
            fromValue !== ""
          ) {
            organizationArray =
              this.props.selectedSalesCommunityOrganization !== null
                ? this.props.selectedSalesCommunityOrganization.map(
                  (org) => org.id
                )
                : [this.props.currentUser.organization_id];
            let params1 = {
              from: fromValue,
              arrayFilters: [filterVals],
              selectFilters: [
                "id",
                "lead_title",
                "expected_closing_date",
                "lead_value",
                "is_confidential",
                "notes",
                "is_hand_over",
                "is_won",
                "is_bell_ringed",
                "project_location",
                "created_at",
              ],
              sort: {
                field: "id",
                sortOrder: "DESC",
              },
              paginate: {
                page: fromValue === "sales-community" ? "0" : "",
                limit: fromValue === "sales-community" ? "10" : "",
              },
              organizationArray: organizationArray,
            };
            this.props.getCommunityLeadList(params1);
          } else if (
            window.location.pathname === "/my-pipeline" &&
            fromValue !== ""
          ) {
            let licenseType = null;
            organizationArray =
              this.props.mypipelineOrgSelected === null
                ? [this.props.currentUser.organization_id]
                : [this.props.mypipelineOrgSelected.id];

            let current_user_selection =
              this.props.mypipelineUserSelected === null
                ? [this.props.currentUser.user_id]
                : [this.props.mypipelineUserSelected.id];

            licenseType =
              this.props.mypipelineUserSelected === null
                ? [this.props.currentUser.license_type.actual_name]
                : [this.props.mypipelineUserSelected.license_type.actual_name];

            let params1 = {
              from: fromValue,
              arrayFilters: [filterVals],
              selectFilters: [
                "id",
                "lead_title",
                "expected_closing_date",
                "lead_value",
                "is_confidential",
                "notes",
                "is_hand_over",
                "is_won",
                "is_bell_ringed",
                "project_location",
                "created_at",
                "linkedin_profile",
              ],
              sort: {
                field: "id",
                sortOrder: "DESC",
              },
              paginate: {
                page: fromValue === "sales-community" ? "0" : "0",
                limit: fromValue === "sales-community" ? "10" : "10",
              },
              organizationArray: organizationArray,
              user_id: current_user_selection instanceof Array ? current_user_selection[0] : current_user_selection,
              license_type: licenseType,
            };
            this.props.getPipelineLeadList(params1);
          }
        }
      }
    }
  }

  handleUserInput(e, update_team_member) {
    if (e && e.target) {
      const name = e.target.name;
      const value = e.target.value;
      this.setState({ [name]: value }, () => {
        this.validateField(name, value);
        if (update_team_member) {
          this.updateTeamMember();
        }
      });
    } else if (e && e.name && e.value !== undefined) {
      const name = e.name;

      const value = e.value;
      this.setState({ [name]: value }, () => {
        this.validateField(name, value);
      });
    }
  }

  handleDateChange = (date) => {
    this.setState(
      {
        expected_closing_date: moment(date).format("YYYY-MM-DD"),
      },
      () => {
        this.validateField("expected_closing_date", date);
      }
    );
  };

  checkHandoverValidation(e) {
    if (this.formErrors["is_hand_over"] !== "") {
      this.formErrors["is_hand_over"] = "";
    }
  }

  validateAllFieldsForm() {

    if (this.state.lead_title.trim() === "") {
      this.formErrors["lead_title"] = "This field is required";
    } else if (!this.state.lead_title.match(companyLeadName_regex)) {
      this.formErrors["lead_title"] = "Please enter valid lead title.";
    }
    if (this.state.company_id === "" || this.state.company_id === 0) {
      this.formErrors["company_id"] = "This field is required";
    }
    if (
      this.state.lead_value.trim() !== "" &&
      (this.state.currency_id === "" || this.state.currency_id === 0)
    ) {
      this.formErrors["currency_id"] = "This field is required";
    }
    if(this.state.contact_person_phone!=""){
      this.formErrors["contact_person_phone"] = "";
      if(this.state.contact_person_country_code == ""){
        this.formErrors["contact_person_phone"] = "Phone select valid country code";
      }
      if(this.state.contact_person_phone.length<8 || this.state.contact_person_phone.length>15){
        this.formErrors["contact_person_phone"] = "Phone number should be 8 to 15 digit long";
      }     
    }

    var errorArr = Object.values(this.formErrors).filter((error) => {
      return error !== "";
    });
    if (errorArr.length === 0) {
      this.setState({
        formValid: true,
      });
      return true;
    } else {
      this.setState({
        formValid: false,
      });
    }
    return false;
  }

  validateField(fieldName, value) {
    let leadFormValid = true;

    switch (fieldName) {
      case "lead_title":
        if (value.trim() === "") {
          this.formErrors["lead_title"] = "This field is required";
          leadFormValid = false;
        } else if (!value.match(companyLeadName_regex)) {
          this.formErrors["lead_title"] = "This field is invalid";
          leadFormValid = false;
        } else if (value.length > 50) {
          this.formErrors["lead_title"] = "Max length is 50 characters.";
          leadFormValid = false;
        } else {
          this.formErrors["lead_title"] = "";
        }
        break;

      case "company_id":
        if (value === "") {
          this.formErrors["company_id"] = "This field is required";
          leadFormValid = false;
        } else {
          this.formErrors["company_id"] = "";
        }
        break;

      case "contact_id":
        if (typeof value === "number") {
          this.formErrors["contact_id"] = "";
        }
        break;

      case "lead_source_id":
        if (typeof value === "number") {
          this.formErrors["lead_source_id"] = "";
        }

        break;

      case "linkedin_profile":
        if (value.trim() === "" || value === "http://") {
          this.formErrors["linkedin_profile"] = "";
        } else if (
          !value
            .trim()
            .match(
              /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
            )
        ) {
          this.formErrors["linkedin_profile"] = "This field is invalid";
          leadFormValid = false;
        } else {
          this.formErrors["linkedin_profile"] = "";
        }
        break;

      case "category_id":
        if (value) {
          this.formErrors["category_id"] = "";
          this.setState({ formValid: true });
        }
        break;

      case "lead_value":
        if (value.trim() === "") {
          this.formErrors["lead_value"] = "";
        } else if (!value.match(leadValue_regex)) {
          this.formErrors["lead_value"] = "This field is invalid";
          leadFormValid = false;
        } else {
          this.formErrors["lead_value"] = "";
        }
        break;
      case "currency_id":
        if (this.state.lead_value !== "" && value.trim() === "") {
          this.formErrors["currency_id"] = "This field is required";
          leadFormValid = false;
        } else {
          this.formErrors["currency_id"] = "";
        }
        break;
      case "project_location":
        if (value.trim() === "") {
          this.formErrors["project_location"] = "";
        } else if (!value.match(commonValidation_regex)) {
          this.formErrors["project_location"] = "This field is invalid";
          leadFormValid = false;
        } else {
          this.formErrors["project_location"] = "";
        }
        break;
      case "notes":
        if (value.trim() === "") {
          this.formErrors["notes"] = "";
        } else if (!value.match(commonValidationWithLineBreak_regex)) {
          this.formErrors["notes"] = "This field is invalid";
          leadFormValid = false;
        } else {
          this.formErrors["notes"] = "";
        }
        break;

      default:
        break;
    }
    this.setState({
      formValid: leadFormValid,
    });
  }

  errorClass(error) {
    return error.length === 0 ? "" : styles.has_error;
  }

  goToNextStatus() {
    switch (this.state.lead_status) {
      case "":
        if (
          this.props.currentUser &&
          this.props.currentUser.license_type &&
          (this.props.currentUser.license_type.actual_name === "MANAGER" ||
            this.props.currentUser.license_type.actual_name === "PROFESSIONAL")
        ) {
          this.setState({
            lead_status: "Lead",
            new_lead_status_id: 2,
          });
        }
        break;
      case "Lead":
        this.setState({
          lead_status: "Opportunity",
          new_lead_status_id: 3,
        });
        break;
      case "Opportunity":
        this.setState({
          lead_status: "Proposal",
          new_lead_status_id: 4,
        });
        break;
      case "Proposal":
        this.setState({
          lead_status: "Negotiation",
          new_lead_status_id: 5,
        });
        break;
      case "Negotiation":
        this.setState({
          lead_status: "Closed",
          new_lead_status_id: 6,
          selectedParkBtn: "",
        });
        break;
      default:
        break;
    }
    this.formErrors["is_won"] = "";
  }

  goToPrevStatus() {
    if (
      this.state.new_lead_status_id &&
      this.state.new_lead_status_id > this.state.lead_status_id
    ) {
      switch (this.state.lead_status) {
        case "Opportunity":
          this.setState({
            lead_status: "Lead",
            new_lead_status_id: 2,
          });
          break;
        case "Proposal":
          this.setState({
            lead_status: "Opportunity",
            new_lead_status_id: 3,
          });
          break;
        case "Negotiation":
          this.setState({
            lead_status: "Proposal",
            new_lead_status_id: 4,
          });
          break;
        case "Closed":
          this.setState({
            lead_status: "Negotiation",
            new_lead_status_id: 5,
          });
          break;
        default:
          break;
      }
    }
    this.formErrors["is_won"] = "";
  }

  openCompanyPopup() {
    this.props.openCompanyPopup();
  }

  openPersonPopup() {
    if (
      !this.state.company_id ||
      this.state.company_id === "" ||
      this.state.company_id === null
    ) {
      this.formErrors["company_id"] = "Please select company";
      this.setState({
        formValid: false,
      });
    } else {
      let selectedCompany = this.props.companyList.filter((company, index) => {
        return company.id == this.state.company_id;
      });
      this.props.openPersonPopup(this.state.company_id, selectedCompany[0]);
    }
  }

  fetchCompaniesAsnc = (newValue) => {
    let requestBody = {
      arrayFilters: [{}],
      selectFilters: [],
      searchFilter: [
        {
          company_name: newValue.trim(),
        },
      ],
      sort: {
        field: "id",
        sortOrder: "DESC",
      },
    };

    axios
      .post("/contact/company/get", requestBody)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            asyncCompanyList: response.data.data.rows,
            loadingCompanyList: false,
          });
        } else {
          this.setState({
            asyncCompanyList: [],
            loadingCompanyList: false,
          });
        }
      })
      .catch((error) => {
        this.formErrors["company_id"] =
          "Error loading company list, please try again";
        this.setState({
          asyncCompanyList: [],
          loadingCompanyList: false,
        });
      });
  };

  checkValidCompany(event, newValue, autocompleteName) {
    if (newValue.trim() === "") {
      this.formErrors["company_id"] = "This field is required";
      this.formErrors["contact_id"] = "";

      this.setState({
        newAddedCompany: "",
        company_id: "",
        contact_id: "",
        formValid: false,
        personList: [],
        contact_person_country_code: "",
        contact_person_phone: "",
        resetPerson: true,
        asyncCompanyList: [],
      });
    } else if (!newValue.match(commonValidation_regex)) {
      this.formErrors["company_id"] = "This field is invalid";
      this.setState({
        newAddedCompany: newValue,
        company_id: "",
        contact_id: "",
        formValid: false,
        personList: [],
        contact_person_country_code: "",
        contact_person_phone: "",
        resetPerson: true,
      });
    } else {
      this.formErrors["company_id"] = "";
      this.setState(
        {
          newAddedCompany: newValue,
          company_id: "",
          contact_id: "",
          contact_person_country_code: "",
          contact_person_phone: "",
          personList: [],
        },
        async () => {
          if (newValue.length > 0 && this.state.company_id === "") {
            this.setState({ loadingCompanyList: true });
            this.fetchCompaniesAsnc(newValue);
          }
        }
      );
    }
  }

  checkValidPerson(event, newValue, autoCompleteName) {
    if (newValue.trim() === "") {
      this.formErrors["contact_id"] = "";
      this.setState({
        newAddedContactPerson: newValue,
        contact_id: "",
        contact_person_country_code: "",
        contact_person_phone: "",
        contact_person_phone_empty: false
      });
    } else if (!newValue.match(commonValidation_regex)) {
      this.formErrors["contact_id"] = "This field is invalid";
      this.setState({
        newAddedContactPerson: newValue,
        contact_id: "",
        contact_person_country_code: "",
        contact_person_phone: "",
      });
    } else if (newValue && newValue != "") {
      this.formErrors["contact_id"] = "";
      this.setState({
        newAddedContactPerson: newValue,
        contact_id: "",
        contact_person_country_code: "",
        contact_person_phone: "",
      });
      if (/^\d+$/.test(this.state.contact_id)) {
        this.setState({
          contact_person_phone_empty: false
        });
      }
      else{
        this.setState({
          contact_person_phone_empty: true
        });
      
      }
    }
  }

  checkPersonValidation() {
    if (this.state.personList.length == 0) {
      this.setState({
        NoPersonFlag: true,
      });
    } else {
      this.formErrors["contact_id"] = "";
      this.setState({
        NoPersonFlag: false,
        formValid: true,
      });
    }
  }

  checkValidLeadSource = (event, newValue, autoCompleteName) => {
    if (newValue && newValue != "") {
      let selectedLeadSource = this.props.leadSourceList.filter(
        (leadSource) => {
          return leadSource.name == newValue;
        }
      );

      if (!selectedLeadSource || selectedLeadSource.length == 0) {
        this.formErrors["lead_source_id"] =
          "Please select lead source from suggestions";
        this.setState({
          lead_source_id: "",
        });
      }
    } else {
      this.formErrors["lead_source_id"] = "";
      this.setState({
        lead_source_id: "",
      });
    }
  };

  // check valid lead category
  checkValidCategorySource = (event, newValue, autoCompleteName) => {
    if (newValue.trim() === "") {
      this.formErrors["category_id"] = "";

      this.setState({
        formValid: true,
        category_id: "",
        newAddedCategory: "",
      });
    } else if (!newValue.match(commonValidation_regex)) {
      this.formErrors["category_id"] = "This field is invalid";
      this.setState({
        newAddedCategory: newValue,
      });
    } else {
      this.formErrors["category_id"] = "";
      this.setState({
        formValid: true,
        category_id: "",
        newAddedCategory: newValue,
      });
    }
  };

  dateChangeHandler = (e) => { };

  toggleCalendar = () => {
    if (this.state.isOpen) {
      this.setState({
        flag: "calendar",
        isOpen: false,
      });
    } else {
      this.setState({
        flag: "calendar",
        isOpen: true,
      });
    }
  };

  //adding new company onblur
  addNewCompany = () => {
    let value = this.state.newAddedCompany.trim();
    if (value !== "" && this.formErrors["company_id"] === "") {
      const params = {
        company_name: value,
      };
      axios
        .post("/contact/company/add", params)
        .then((response) => {
          if (response.data.data.status === 200) {
            this.getContactsList();
            this.getCompanies();
            this.formErrors["company_id"] = "";
            this.setState({
              newAddedCompany: "",
              newAddedContactPerson: "",
              company_id: response.data.data.data.id,
              submitButtonBlur: false,
            });
          } else {
            this.formErrors["company_id"] = response.data.errorMessage;
            this.setState({
              newAddedCompany: "",
              newAddedContactPerson: "",
              company_id: "",
              submitButtonBlur: false,
            });
          }
        })
        .catch((error) => {
          this.formErrors["company_id"] =
            "Error adding company, please try again";
          this.setState({
            newAddedCompany: "",
            newAddedContactPerson: "",
            company_id: "",
            submitButtonBlur: false,
          });
        });
    } else {
      this.setState({ submitButtonBlur: false });
    }
  };

  async updatePerson() {
    if (/^\d+$/.test(this.state.contact_id) == false) {
      return;
    }

    var person = this.state.personList.filter((item) => {
      return parseInt(item.id) === parseInt(this.state.contact_id);
    });

    const params = {
      //company_id: this.state.company_id,
      id: this.state.contact_id,
      //first_name: this.state.contact_name,
      phone_number: this.state.contact_person_phone,
      country_code: this.state.contact_person_country_code,
    };

    await axios
        .put("/contact/person/update", params)
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.getContactsList();
            this.getPersons(this.state.company_id);
            this.setState({
              newAddedCompany: "",
              newAddedContactPerson: "",
              //contact_id: response.data.data.data.id,
              //submitButtonBlur: false,
            });
            //this.formErrors["contact_id"] = "";
          } else if (response.data.statusCode === 400) {
            this.formErrors["contact_id"] = response.data.errorMessage;
            this.setState({
              newAddedCompany: "",
              newAddedContactPerson: "",
              contact_id: "",
              submitButtonBlur: false,
            });
          } else {
            this.setState({
              newAddedCompany: "",
              newAddedContactPerson: "",
              contact_id: "",
              submitButtonBlur: false,
            });
            this.formErrors["contact_id"] = response.data.errorMessage;
          }
        })
        .catch((error) => {
          this.setState({
            newAddedCompany: "",
            newAddedContactPerson: "",
            contact_id: "",
            submitButtonBlur: false,
          });
          this.formErrors["contact_id"] =
            "Error adding person, please try again";
        });
  };

  //adding new person onblur
  async addNewPerson() {
    if (/^\d+$/.test(this.state.contact_id)) {
      return;
    }

    //let value = this.state.newAddedContactPerson.trim();
    let value = this.state.contact_id.trim();
    if (
      value !== "" &&
      this.state.company_id !== "" &&
      this.formErrors["contact_id"] === ""
    ) {
      const params = {
        company_id: this.state.company_id,
        contact_person_name: value,
        phone_number: this.state.contact_person_phone,
        country_code: this.state.contact_person_country_code,
        is_only_name: "yes",
      };

      await axios
        .post("/contact/person/add", params)
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.getContactsList();
            this.getPersons(this.state.company_id);
            this.setState({
              newAddedCompany: "",
              newAddedContactPerson: "",
              contact_id: response.data.data.data.id,
              submitButtonBlur: false,
            });
            this.formErrors["contact_id"] = "";
          } else if (response.data.statusCode === 400) {
            this.formErrors["contact_id"] = response.data.errorMessage;
            this.setState({
              newAddedCompany: "",
              newAddedContactPerson: "",
              contact_id: "",
              submitButtonBlur: false,
            });
          } else {
            this.setState({
              newAddedCompany: "",
              newAddedContactPerson: "",
              contact_id: "",
              submitButtonBlur: false,
            });
            this.formErrors["contact_id"] = response.data.errorMessage;
          }
        })
        .catch((error) => {
          this.setState({
            newAddedCompany: "",
            newAddedContactPerson: "",
            contact_id: "",
            submitButtonBlur: false,
          });
          this.formErrors["contact_id"] =
            "Error adding person, please try again";
        });
    } else {
      this.setState({ submitButtonBlur: false });
    }
  };

  //adding new category onblur
  addCategorySource = async () => {
    let value = this.state.newAddedCategory.trim();
    if (value !== "" && this.formErrors["category_id"] === "") {
      let params = {
        name: value,
      };
      await axios
        .post("/master/addCategory", params)
        .then(async (response) => {
          if (response && response.data && response.data.statusCode === 200) {
            this.formErrors["category_id"] = "";
            await this.setState({
              category_id: response.data.data.data.id,
              submitButtonBlur: false,
            });
          } else {
            this.formErrors["category_id"] = response.data.errorMessage;
            this.setState({ category_id: "", submitButtonBlur: false });
          }
        })
        .catch((error) => {
          this.formErrors["category_id"] =
            "Error adding category, please try again";
          this.setState({ category_id: "", submitButtonBlur: false });
        });
    } else {
      this.setState({ submitButtonBlur: false });
    }
  };

  getCompanies = () => {
    let params = {
      arrayFilters: [
        {
          is_deleted: 0,
        },
      ],
      selectFilters: [],
      sort: {
        field: "company_name",
        sortOrder: "ASC",
      },
      paginate: {
        page: "",
        limit: "",
      },
    };
    this.props.getCompanies(params);
  };

  //for getting contact listing on my-pipeline
  //page after company/person added successfully on blur event
  getContactsList = () => {
    if (window.location.pathname === "/my-pipeline") {
      let organizationArray = null;

      if (this.props.mypipelineOrgSelected === null) {
        organizationArray = [this.props.currentUser.organization_id];
      } else {
        organizationArray = [this.props.mypipelineOrgSelected.id];
      }

      contactListRequestBody.organizationArray = organizationArray;
      this.props.getContactList(contactListRequestBody);
    }
  };

  fileSelected = (e) => {
    let file = e.target.files[0];
    e.target.value = "";
    if (file) {
      if (
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg"
      ) {
        if (file.size < 10485760) {
          this.mimeTypeCheck(file);
        } else {
          openSnackbar("Image size should not more than 10 mb.", "snack-error");
        }
      } else {
        // file size check
        if (file.size < 2097152 && file.size > 0) {
          this.setState(
            {
              submitButtonBlur: true,
            },
            () => {
              this.mimeTypeCheck(file);
            }
          );
        } else {
          openSnackbar(
            "File size should be less than 2MB & more than 0KB.",
            "snack-error"
          );
          return;
        }
      }
    }
  };

  mimeTypeCheck = (file) => {
    //mimetype check
    let header = "";
    let fr = new FileReader();
    fr.onload = async (e) => {
      let arr = new Uint8Array(e.target.result).subarray(0, 4);
      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }

      if (header.startsWith("ffd8ff")) {
        header = header.slice(0, 6);
      }

      if (header !== "") {
        switch (header) {
          case "d0cf11e0": //ppt & doc
          case "504b34": //pptx & docx
          case "25504446": //pdf
            this.setState(
              {
                uploadFilesLoader: true,
              },
              () => {
                this.uploadFile(file);
              }
            );
            break;

          //for images
          case "ffd8ff":
          case "89504e47":
            this.loadingFile(file);
            break;

          default:
            openSnackbar(
              "Invalid file format! Only .jpg, .png, .doc, .ppt, .pdf.",
              "snack-error"
            );
            await this.setState({
              submitButtonBlur: false,
            });
            return;
        }
      } else {
        await this.setState({
          submitButtonBlur: false,
        });
        return;
      }
    };
    fr.readAsArrayBuffer(file);
  };

  loadingFile = (file) => {
    this.fileValue = {
      name: file.name,
      type: file.type,
    };
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ uploadedImage: reader.result });
    };
    reader.readAsDataURL(file);
    this.setState({
      cropperDialog: true,
    });
  };

  cropImage = async (val, cropper) => {
    if (val) {
      this.setState(
        {
          cropResult: cropper.getCroppedCanvas().toDataURL(),
          cropperDialog: false,
        },
        async () => {
          let file = await this.dataURLtoFile(this.state.cropResult);
          await this.setState({
            uploadFilesLoader: true,
          });

          if (file.size > 2097152) {
            const options = {
              maxSizeMB: 2,
              maxWidthOrHeight: 1920,
            };
            imageCompression(file, options)
              .then((compressFile) => {
                this.fileValue = null;
                const newFile = new File([compressFile], compressFile.name, {
                  type: compressFile.type,
                  lastModified: compressFile.lastModified,
                });

                this.uploadFile(newFile);
              })
              .catch((error) => {
                openSnackbar("Image size too large", "snack-error");
                this.setState({
                  uploadedImage: "",
                  cropperDialog: false,
                  cropResult: "",
                  uploadFilesLoader: false,
                  submitButtonBlur: false,
                });
                this.fileValue = null;
              });
          } else {
            this.fileValue = null;
            this.uploadFile(file);
          }
        }
      );
    } else {
      this.setState({
        uploadedImage: "",
        cropperDialog: false,
        cropResult: "",
        uploadFilesLoader: false,
        submitButtonBlur: false,
      });
      this.fileValue = null;
    }
  };

  dataURLtoFile = (dataurl) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    const fileExtension = mime.split("/")[1];

    const fileName =
      Math.floor(Date.now() / 1000).toString() + "." + fileExtension;

    return new File([u8arr], this.fileValue.name, {
      type: this.fileValue.type,
    });
  };

  uploadFile = (file) => {
    if (file !== null) {
      const imageData = new FormData();
      imageData.append("document", file);
      imageData.append("document_name", file.name);

      axios
        .post("/documents", imageData)
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.setState((prevState) => {
              return {
                ...prevState,
                uploadedFiles: [...prevState.uploadedFiles, response.data.data],
                uploadFilesLoader: false,
                submitButtonBlur: false,

                uploadedImage: "",
                cropResult: "",
              };
            });
          } else {
            openSnackbar(
              "Invalid file format! Only .jpg, .png, .doc, .ppt, .pdf.",
              "snack-error"
            );
            this.setState({
              uploadFilesLoader: false,
              submitButtonBlur: false,
              uploadedImage: "",
              cropResult: "",
            });
          }
        })
        .catch((err) => {
          openSnackbar("Failed to upload file.", "snack-error");
          this.setState({
            uploadFilesLoader: false,
            submitButtonBlur: false,
            uploadedImage: "",
            cropResult: "",
          });
        });
    }
  };

  removeFile = async (file) => {
    let removeFileParams;
    if (file.id) {
      removeFileParams = {
        document_id: file.id,
        old_document_path: file.document_path,
      };
    } else {
      removeFileParams = {
        old_document_path: file.document_path,
      };
    }

    await this.setState({
      uploadFilesLoader: true,
    });

    axios
      .post("/lead/removeDocument", removeFileParams)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let uploadedFiles = this.state.uploadedFiles;
          uploadedFiles = uploadedFiles.filter(
            (file) =>
              file.document_path !== response.data.data.old_document_path
          );
          this.setState({
            uploadedFiles: uploadedFiles,
            uploadFilesLoader: false,
          });
        } else {
          this.setState({
            uploadedFiles: [],
            uploadFilesLoader: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          uploadedFiles: [],
          uploadFilesLoader: false,
        });
      });
  };

  handleCloseCropDialog = () => {
    this.setState({
      uploadedImage: "",
      cropperDialog: false,
      cropResult: "",
    });
  };

  handlePhoneNumberChange = (e) => {
    let value = e.target.value;
  if (value!="" && !value.match(/^[0-9]+$/)) {
        //personFields["personPhoneNumber"] = "";
        //personErrors["personPhoneNumber"] = "Only numbers are allowed";
        // if (value.length < 9) {
        //     personErrors["personPhoneNumber"] = "Phone number should not be less than 8 digits.";
        // }
    }else{
      if (e && e.target) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value }, () => {
          this.validateField(name, value);
         
        });
      } else if (e && e.name && e.value !== undefined) {
        const name = e.name;
  
        const value = e.value;
        this.setState({ [name]: value }, () => {
          this.validateField(name, value);
        });
      }
    }
   
   
}

  retriveAvtar = (uploadedFile) => {
    const { currentUser } = this.props;
    const type = uploadedFile.document_type;

    const title = fileNameFromUrl(uploadedFile.document_path);

    switch (type) {
      case "DOC":
        return (
          <LightTooltip
            //title={uploadedFile.document_name ? uploadedFile.document_name : ""}
            title={title}
            placement="top"
          >
            <div className={styles.img_avator}>
              <Avatar
                alt="Profile Picture"
                src={require("../../../../assets/images/spl/icon_doc.png")}
                className={`${styles.avataar} ${styles.icon}`}
              />
              {this.props.currentLead &&
                currentUser.license_type &&
                currentUser.license_type.actual_name !== "BASIC" &&
                currentUser.license_type.actual_name !== "LITE" ? (
                  (this.props.currentLead.assignedTo &&
                    this.props.currentLead.assignedTo.id) ===
                    (this.props.currentUser && this.props.currentUser.user_id) ? (
                      <button
                        onClick={() => this.removeFile(uploadedFile)}
                        className={styles.img_avator_close}
                      >
                        <img
                          width="17px"
                          height="17px"
                          src={closeIcon}
                          alt="Close"
                        />
                      </button>
                    ) : null
                ) : (
                  <button
                    onClick={() => this.removeFile(uploadedFile)}
                    className={styles.img_avator_close}
                  >
                    <img width="17px" height="17px" src={closeIcon} alt="Close" />
                  </button>
                )}
            </div>
          </LightTooltip>
        );

      case "PPT":
        return (
          <LightTooltip
            //title={uploadedFile.document_name ? uploadedFile.document_name : ""}
            title={title}
            placement="top"
          >
            <div className={styles.img_avator}>
              <Avatar
                alt="Profile Picture"
                src={require("../../../../assets/images/spl/icon_ppt.png")}
                className={`${styles.avataar} ${styles.icon}`}
              />
              {this.props.currentLead &&
                currentUser.license_type &&
                currentUser.license_type.actual_name !== "BASIC" &&
                currentUser.license_type.actual_name !== "LITE" ? (
                  (this.props.currentLead.assignedTo &&
                    this.props.currentLead.assignedTo.id) ===
                    (this.props.currentUser && this.props.currentUser.user_id) ? (
                      <button
                        onClick={() => this.removeFile(uploadedFile)}
                        className={styles.img_avator_close}
                      >
                        <img
                          width="17px"
                          height="17px"
                          src={closeIcon}
                          alt="Close"
                        />
                      </button>
                    ) : null
                ) : (
                  <button
                    onClick={() => this.removeFile(uploadedFile)}
                    className={styles.img_avator_close}
                  >
                    <img width="17px" height="17px" src={closeIcon} alt="Close" />
                  </button>
                )}
            </div>
          </LightTooltip>
        );

      case "JPG":
      case "PNG":
        return (
          <LightTooltip
            //title={uploadedFile.document_name ? uploadedFile.document_name : ""}
            title={title}
            placement="top"
          >
            <div className={styles.img_avator}>
              <Avatar
                alt="Profile Picture"
                src={uploadedFile.document_path}
                className={styles.avataar}
              />
              {this.props.currentLead &&
                currentUser.license_type &&
                currentUser.license_type.actual_name !== "BASIC" &&
                currentUser.license_type.actual_name !== "LITE" ? (
                  (this.props.currentLead.assignedTo &&
                    this.props.currentLead.assignedTo.id) ===
                    (this.props.currentUser && this.props.currentUser.user_id) ? (
                      <button
                        onClick={() => this.removeFile(uploadedFile)}
                        className={styles.img_avator_close}
                      >
                        <img
                          width="17px"
                          height="17px"
                          src={closeIcon}
                          alt="Close"
                        />
                      </button>
                    ) : null
                ) : (
                  <button
                    onClick={() => this.removeFile(uploadedFile)}
                    className={styles.img_avator_close}
                  >
                    <img width="17px" height="17px" src={closeIcon} alt="Close" />
                  </button>
                )}
            </div>
          </LightTooltip>
        );

      case "PDF":
        return (
          <LightTooltip
            //title={uploadedFile.document_name ? uploadedFile.document_name : ""}
            title={title}
            placement="top"
          >
            <div className={styles.img_avator}>
              <Avatar
                alt="Profile Picture"
                src={require("../../../../assets/images/spl/icon_pdf.png")}
                className={`${styles.avataar} ${styles.icon}`}
              />
              {this.props.currentLead &&
                currentUser.license_type &&
                currentUser.license_type.actual_name !== "BASIC" &&
                currentUser.license_type.actual_name !== "LITE" ? (
                  (this.props.currentLead.assignedTo &&
                    this.props.currentLead.assignedTo.id) ===
                    (this.props.currentUser && this.props.currentUser.user_id) ? (
                      <button
                        onClick={() => this.removeFile(uploadedFile)}
                        className={styles.img_avator_close}
                      >
                        <img
                          width="17px"
                          height="17px"
                          src={closeIcon}
                          alt="Close"
                        />
                      </button>
                    ) : null
                ) : (
                  <button
                    onClick={() => this.removeFile(uploadedFile)}
                    className={styles.img_avator_close}
                  >
                    <img width="17px" height="17px" src={closeIcon} alt="Close" />
                  </button>
                )}
            </div>
          </LightTooltip>
        );

      default:
        return <div className={styles.img_avator}></div>;
    }
  };

  //get company name while updation
  getUpdationCompanyName = () => {
    let updationCompany = null;
    if (this.props.currentLead) {
      updationCompany = this.props.companyList.find((company) => {
        return company.id === this.props.currentLead.contact_company.id;
      });
    }
    return updationCompany.company_name;
  };

  getFormattedData = (arr) => {
    return {
      parents: arr.filter((a) => a.is_parent),
      childs: arr.filter((a) => !a.is_parent),
    };
  };

  getHandshakeImg = () => {
    // this.state.is_won === null ? this.state.handshakeImg : 
    const is_hand_over = this.state.is_won === null ? this.state.is_hand_over : this.getInitialIsHandOver();

    if (!is_hand_over) {
      return this.state.handshakeImg;
    }

    return is_hand_over === 1 ? handshake1 : handshake2;
  }

  shouldShowAcceptAsLeadButton() {
    const { currentLead, currentUser } = this.props;

    if (currentLead && currentLead.is_hand_over === 2 ) {
      return false;
    }

    const license_types = ['MANAGER', 'PROFESSIONAL'];
    return (
      currentUserHasExpectedLicenseType(currentUser, license_types) && 
      this.state.lead_status_id !== 6
    )
  } 

  render() {
    const { uploadedFiles, uploadFilesLoader } = this.state;
    const { parents, childs } = this.getFormattedData(this.props.orgChildList.options);
    const selectedOrg = this.state.assignedOrg_id || (this.props.currentLead && this.props.currentLead.account.id) || (!this.currentLead && this.props.currentUser.organization_id);

    const renderOptions = (opt, index) => {
      return (
        <option selected={opt.self} value={opt.value} key={"group_data_" + index}>
          {opt.label}
        </option>
      );
    };

    const stepper =
      this.state.lead_id ||
        (this.props.currentUser &&
          this.props.currentUser.license_type &&
          (this.props.currentUser.license_type.actual_name == "MANAGER" ||
            this.props.currentUser.license_type.actual_name ==
            "PROFESSIONAL")) ? (
          <div>
            <div className={styles.lead_stepper}>
              <div>Pipeline stage</div>
              <Stepper
                currentSalesItem={this.props.currentLead}
                lead_status={this.state.lead_status}
                statusLogs={this.props.statusLogs}
              />

              <div className={styles.stepper_btns + " row wrap_prev_next"}>
                <div className="div_prev">
                  <button
                    type="button"
                    value="prev"
                    onClick={() => this.goToPrevStatus()}
                    disabled={
                      !this.state.new_lead_status_id ||
                      (this.state.new_lead_status_id &&
                        this.state.new_lead_status_id <=
                        this.state.lead_status_id)
                    }
                    className={
                      styles.stepper_btn +
                      " " +
                      styles.prev_stepper_btn +
                      " button_prev"
                    }
                  >
                    <ArrowBack className={styles.backArrow} />
                  Prev
                </button>
                </div>
                <div className={styles.status_name + " div_middle"}>
                  {this.state.lead_status}
                </div>
                <div className="div_next">
                  <button
                    type="button"
                    value="next"
                    onClick={() => this.goToNextStatus()}
                    className={
                      styles.stepper_btn +
                      " " +
                      styles.next_stepper_btn +
                      " button_next"
                    }
                  >
                    Next
                  <ArrowForward className={styles.nextArrow} />
                  </button>
                </div>
              </div>
            </div>
            {/* win , loss, park buttons */}
            <div className="row">
              <div className="col-md-4">
                <div
                  id="win"
                  className={
                    this.state.selectedWinBtn + " " + styles.leadStatusBtns
                  }
                  onClick={() => this.addClass("win")}
                >
                  Won
              </div>
              </div>
              <div className="col-md-4">
                <div
                  id="loss"
                  className={
                    this.state.selectedLossBtn + " " + styles.leadStatusBtns
                  }
                  onClick={() => this.addClass("loss")}
                >
                  Lost
              </div>
              </div>
              <div className="col-md-4">
                <div
                  id="park"
                  className={cx(
                    this.state.selectedParkBtn,
                    styles.leadStatusBtns,
                    {
                      [styles.disabled_parkBtn]:
                        this.state.new_lead_status_id == 6,
                    }
                  )}
                  onClick={() => this.addClass("park")}
                >
                  Parked
              </div>
              </div>
            </div>
            <FormErrors formErrors={this.formErrors} field="is_won" />
          </div>
        ) : (
          ""
        );

    return (
      <div>
        <DialogTitle
          id="customized-dialog-title"
          onClose={this.props.hideModal}
        >
          <div className={styles.lead_dialog_title}>
            {this.state.lead_id
              ? "Update / Edit lead (" + this.state.lead_id + ")"
              : "New lead"}
          </div>
        </DialogTitle>
        <DialogContent
          className={
            styles.lead_dialog_content +
            " lead_dialog_content_remove_overflow-y"
          }
        >
          <div className={styles.lead_content}>
            {/* stage stepper */}
            {this.state.lead_status === "New" ? "" : stepper}
            <div className="row">
              <div className="col-md-6 col-lg-3">
                <div className={styles.form_group}>
                  <div className={styles.lead_char}>
                    <label className={styles.form_title}>
                      Lead title<span className={styles.required_field}>*</span>
                    </label>
                    <div className="notesHint">
                      {50 - this.state.lead_title.length + " characters left"}
                    </div>
                  </div>
                  <div>
                    <input
                      id="outlined-name"
                      name="lead_title"
                      className={styles.inputStyle}
                      value={this.state.lead_title}
                      onChange={(event) => this.handleUserInput(event)}
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      minLength="2"
                      maxLength="50"
                      ref={(elem) => (this.textInput = elem)}
                    />
                  </div>
                  <FormErrors formErrors={this.formErrors} field="lead_title" />
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className={styles.form_group + " row"}>
                  <div className="col-md-12 col-sm-12">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <label className={styles.form_title}>
                        Company<span className={styles.required_field}>*</span>
                      </label>
                      <div style={{ paddingRight: "10px" }}>
                        <SmallScaleLoader
                          loading={this.state.loadingCompanyList}
                        />
                      </div>
                    </div>

                    <div>
                      {this.state.lead_id ? (
                        <input
                          disabled
                          //value={this.getUpdationCompanyName()}
                          value={
                            this.props.currentLead &&
                            this.props.currentLead.contact_company &&
                            this.props.currentLead.contact_company.company_name
                          }
                          className={styles.inputStyle}
                        />
                      ) : (
                          <AutoComplete
                            //suggestions={this.props.companyList}
                            suggestions={
                              this.state.loadingCompanyList
                                ? []
                                : this.state.asyncCompanyList
                            }
                            autoCompleteName="company"
                            placeholder="Select company name"
                            getPersonList={this.getPersonList}
                            value={this.state.company_id}
                            onBlur={this.addNewCompany}
                            disabled={this.state.lead_id ? true : false}
                            newlyAddedCompany={this.props.newlyAddedCompany}
                            loadingCompanyList={this.state.loadingCompanyList}
                            onChange={(event, newValue, autocompleteName) => {
                              this.setState({ submitButtonBlur: true });
                              this.checkValidCompany(
                                event,
                                newValue,
                                autocompleteName
                              );
                            }}
                          />
                        )}
                    </div>
                    <FormErrors
                      formErrors={this.formErrors}
                      field="company_id"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className={styles.form_group + " row"}>
                  <div className="col-md-12 col-sm-12">
                    <label className={styles.form_title}>
                      Contact person name
                    </label>
                    <AutoComplete
                      suggestions={this.state.personList}
                      autoCompleteName="person"
                      resetPerson={this.state.resetPerson}
                      placeholder="Select contact person"
                      name="contact_id"
                      id="personID"
                      disabled={this.state.company_id === ""}
                      getPersonPhone={this.getPersonPhone}
                      //onBlur={this.addNewPerson}
                      onBlur={this.getPersonPhone}
                      value={this.state.contact_id}
           
                      onChange={(event, newValue, autoCompleteName) => {
                        this.checkValidPerson(
                          event,
                          newValue,
                          autoCompleteName
                        );
                        this.setState({ submitButtonBlur: true });
                      }}
                    />
                    
                  </div>
                </div>
              </div>
              <div className={styles.form_group + " col-md-6 col-lg-3"}>
                <div className="row">
                  <div className="col-5 padding_right padding_right_remove">
                    <label className={styles.form_title}>Code</label>
                    <select className={styles.selectCompany}
                      name="contact_person_country_code"
                      value={this.state.contact_person_country_code}
                      disabled={!this.state.contact_person_phone_empty}
                      onChange={event => this.handleUserInput(event)}
                    >
                      <option value="">Select</option>
                      {
                        this.props.countryList ?
                          this.props.countryList.map((country, index) => {
                            return (
                              <option value={country.id} key={"code_" + index}>
                                {country.iso_code + " +" + country.dialing_code}
                              </option>
                            )
                          })
                          : ''
                      }
                    </select>
                    <span className={styles.errorMessage}>
                      {/* {`${this.state.personErrors["country_code"] ?
                        this.state.personErrors["country_code"] : ""}`} */}
                    </span>
                  </div>

                  <div className="col-7 padding_left">
                    
                    <label className={styles.form_title}>Phone number</label>
                    <input
                      name="contact_person_phone"
                      className={styles.inputStyle}
                      type="text"
                      value={this.state.contact_person_phone}
                      onChange={(event) => this.handlePhoneNumberChange(event)}
                      disabled={!this.state.contact_person_phone_empty} 
                     
                      maxLength="15"
                     
                    />
                    <span className={styles.errorMessage}>
                      {/* {`${this.state.personErrors["personPhoneNumber"] ?
                        this.state.personErrors["personPhoneNumber"] : ""}`} */}
                    </span>
                  </div>
                <div className="col-12">
                {!this.state.NoPersonFlag && (
                      <FormErrors
                        formErrors={this.formErrors}
                        field="contact_person_phone"
                      />
                    )}
                </div>
                </div>
              </div>
            </div>

            {this.props.currentUser &&
              this.props.currentUser.license_type &&
              (this.props.currentUser.license_type.actual_name == "MANAGER" ||
                this.props.currentUser.license_type.actual_name ==
                "PROFESSIONAL" ||
                this.props.currentUser.license_type.actual_name === "BASIC" ||
                this.props.currentUser.license_type.actual_name === "LITE") ? ( //&& this.state.lead_id
                <div className="row">
                  <div className="col-md-6 col-lg-3">
                    <div className={styles.form_group}>
                      <label className={styles.form_title}>
                        LinkedIn profile
                    </label>
                      <input
                        id="linkedin_profile"
                        name="linkedin_profile"
                        placeholder="Enter profile link here"
                        className={styles.inputStyle}
                        value={this.state.linkedin_profile}
                        onChange={(event) => this.handleUserInput(event)}
                        margin="normal"
                        variant="outlined"
                        autoComplete="off"
                      // minLength="2"
                      // maxLength="50"
                      //ref={elem => (this.textInput = elem)}
                      />
                    </div>
                    <FormErrors
                      formErrors={this.formErrors}
                      field="linkedin_profile"
                    />
                  </div>

                  <div className="col-md-6 col-lg-3">
                    <div className={styles.form_group}>
                      <label className={styles.form_title}>
                        Upload photo/document
                    </label>
                      <div>
                        <input
                          id="myInput"
                          type="file"
                          ref={(ref) => (this.doc = ref)}
                          style={{ display: "none" }}
                          onChange={(event) => this.fileSelected(event)}
                          accept=".png,.jpeg,.jpg,.PNG,.JPEG,.JPG,.pdf,.doc,.ppt,.docx,.pptx"
                        />
                        <button
                          className={`
                            ${styles.browse_btn} 
                            ${styles.lead_radio} ${styles.selectedBox} 
                            ${
                            (uploadedFiles.length === 5 || uploadFilesLoader
                              ? styles.disabled_yes_no
                              : "") ||
                            (this.props.currentLead &&
                              this.props.currentUser.license_type &&
                              (this.props.currentUser.license_type
                                .actual_name !== "BASIC" ||
                                this.props.currentUser.license_type
                                  .actual_name !== "LITE")
                              ? (this.props.currentLead.assignedTo &&
                                this.props.currentLead.assignedTo.id) ===
                                (this.props.currentUser &&
                                  this.props.currentUser.user_id)
                                ? ""
                                : styles.disabled_yes_no
                              : "")
                            }
                          `}
                          onClick={(e) => this.doc.click()}
                          disabled={
                            (uploadedFiles.length === 5 || uploadFilesLoader
                              ? true
                              : false) ||
                            (this.props.currentLead &&
                              this.props.currentUser.license_type &&
                              this.props.currentUser.license_type.actual_name !==
                              "BASIC"
                              ? (this.props.currentLead.assignedTo &&
                                this.props.currentLead.assignedTo.id) ===
                                (this.props.currentUser &&
                                  this.props.currentUser.user_id)
                                ? false
                                : true
                              : false)
                          }
                        >
                          Browse
                      </button>
                      </div>
                      <FormHelperText className={styles.footerHint}>
                        Only .jpg, .png, .doc, .ppt, .pdf.
                    </FormHelperText>
                    </div>
                  </div>
                  <div className={`col-md-6 col-lg-6`}>
                    <div className={styles.form_group + " row"}>
                      <div
                        className={`${styles.uploaded_items} col-md-12 col-sm-12`}
                      >
                        {uploadFilesLoader ? (
                          <div className={`col-md-1 col-lg-1`}>
                            <SmallLoader />
                          </div>
                        ) : uploadedFiles.length > 0 ? (
                          uploadedFiles.map((uploadedFile, index) => {
                            return (
                              <div key={index} className={styles.circle_overlap}>
                                {this.retriveAvtar(uploadedFile)}
                              </div>
                            );
                          })
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

            {this.props.currentUser &&
              this.props.currentUser.license_type &&
              (this.props.currentUser.license_type.actual_name == "MANAGER" ||
                this.props.currentUser.license_type.actual_name ==
                "PROFESSIONAL") ? (
                <>
                  <div className="row">
                    <div className="col-md-6 col-lg-3 p_position remove_scrollY_pop_date">
                      <label className={styles.form_title}>
                        Expected closing date
                    </label>
                      <div
                        className={styles.datepicker + " " + styles.form_group}
                      >
                        <input
                          placeholder="yyyy-mm-dd"
                          value={this.state.expected_closing_date}
                          className={styles.inputStyle + " add_cursor_pointer"}
                          onClick={this.toggleCalendar}
                          onChange={this.dateChangeHandler}
                        />
                        <DatePicker
                          calendarClassName={styles.dateLayout}
                          value={
                            this.state.expected_closing_date !== ""
                              ? new Date(
                                moment(this.state.expected_closing_date).year(),
                                moment(
                                  this.state.expected_closing_date
                                ).month(),
                                moment(this.state.expected_closing_date).date()
                              )
                              : this.state.expected_closing_date
                          }
                          clearIcon={null}
                          calendarIcon={null}
                          isOpen={this.state.isOpen}
                          onCalendarClose={this.toggleCalendar}
                          onChange={(event) => this.handleDateChange(event)}
                          minDate={new Date()}
                          maxDate={new Date(moment().add(20, "years"))}
                        />
                      </div>
                      <i className={styles.date_picker_icon}></i>
                      <FormErrors
                        formErrors={this.formErrors}
                        field="expected_closing_date"
                      />
                    </div>

                    {/* category added */}
                    <div className="col-md-6 col-lg-3">
                      <div className={styles.form_group}>
                        <label className={styles.form_title}>Category</label>
                        <div>
                          <AutoComplete
                            suggestions={this.props.leadCategoryList}
                            autoCompleteName="leadCategory"
                            placeholder="Select category name"
                            name="category_id"
                            setCategorySource={this.setCategorySource}
                            onBlur={this.addCategorySource}
                            value={this.state.category_id}
                            onChange={(event, newValue, autoCompleteName) => {
                              this.checkValidCategorySource(
                                event,
                                newValue,
                                autoCompleteName
                              );
                              this.setState({ submitButtonBlur: true });
                            }}
                          />
                        </div>
                        <FormErrors
                          formErrors={this.formErrors}
                          field="category_id"
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-3">
                      <div className="row">
                        <div className={styles.form_group + " col-md-8"}>
                          <label className={styles.form_title}>Lead value</label>
                          <input
                            className={styles.inputStyle}
                            type="text"
                            name="lead_value"
                            value={this.state.lead_value}
                            onChange={(event) => this.handleUserInput(event)}
                            minLength="2"
                            maxLength="10"
                            autoComplete="off"
                          />
                          <FormErrors
                            formErrors={this.formErrors}
                            field="lead_value"
                          />
                        </div>

                        <div
                          className={

                            " col-md-4 " +
                            styles.form_group +
                            " " +
                            styles.pl_0
                          }
                        >
                          <label className={styles.form_title}>&nbsp;</label>
                          <select
                            className={
                              styles.selectCompany + " " + styles.currency_field
                            }
                            name="currency_id"
                            value={this.state.currency_id}
                            onChange={(event) => this.handleUserInput(event)}
                          >
                            <option hidden>Select</option>
                            {this.props.currencyList &&
                              this.props.currencyList.length > 0
                              ? this.props.currencyList.map((item, index) => {
                                return (
                                  <option
                                    value={item.id}
                                    key={"currency_" + index}
                                  >
                                    {item.short_name}
                                  </option>
                                );
                              })
                              : ""}
                          </select>
                          <FormErrors
                            formErrors={this.formErrors}
                            field="currency_id"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-3">
                      <label className={styles.form_title}>
                        Is the lead value confidential?
                    </label>
                      <div className={styles.form_group + " row"}>
                        <div
                          className={styles.yesno_btn}
                          onClick={() => this.addClass("yes")}
                        >
                          <div
                            id="yes-radio"
                            className={`${styles.lead_radio} ${
                              this.state.selectedYESRadio
                              } ${
                              this.state.lead_value !== "" &&
                                this.formErrors.lead_value === ""
                                ? ""
                                : styles.disabled_yes_no
                              }`}
                          >
                            Yes
                        </div>
                        </div>
                        <div
                          className={styles.yesno_btn}
                          onClick={() => this.addClass("no")}
                        >
                          <div
                            id="no-radio"
                            className={`${styles.lead_radio} ${
                              this.state.selectedNORadio
                              } ${
                              this.state.lead_value !== "" &&
                                this.formErrors.lead_value === ""
                                ? ""
                                : styles.disabled_yes_no
                              }`}
                          >
                            No
                        </div>
                        </div>
                      </div>
                      <FormErrors
                        formErrors={this.formErrors}
                        field="is_conficential"
                      />
                    </div>
                  </div>
                  <div className="row">
                    {/* project location */}

                    <div className="col-md-6 col-lg-3">
                      <label className={styles.form_title}>
                        Project location
                    </label>
                      <div className={styles.form_group}>
                        <input
                          id="project_location"
                          name="project_location"
                          //placeholder="Enter project location"
                          className={styles.inputStyle}
                          value={this.state.project_location}
                          onChange={(event) => this.handleUserInput(event)}
                          margin="normal"
                          variant="outlined"
                          autoComplete="off"
                          maxLength="50"
                        />
                      </div>
                      <FormErrors
                        formErrors={this.formErrors}
                        field="project_location"
                      />
                    </div>

                    <div className="col-md-6 col-lg-3">
                      <div className={styles.form_group}>
                        <label className={styles.form_title}>Lead source</label>
                        <div>
                          <AutoComplete
                            suggestions={this.props.leadSourceList}
                            autoCompleteName="leadSource"
                            placeholder="Select lead source name"
                            name="lead_source_id"
                            // onBlur={() => this.checkValidLeadSource()}
                            setLeadSource={this.setLeadSource}
                            value={this.state.lead_source_id}
                            onChange={(event, newValue, autoCompleteName) =>
                              this.checkValidLeadSource(
                                event,
                                newValue,
                                autoCompleteName
                              )
                            }
                          />
                        </div>
                        <FormErrors
                          formErrors={this.formErrors}
                          field="lead_source_id"
                        />
                      </div>
                    </div>

                  </div>{" "}
                  {(
                    <div className={"row " + styles.handover_section}>
                      {
                        //     (   //for basic user
                        //     this.props.currentUser && this.props.currentUser.license_type
                        //     && this.props.currentUser.license_type.actual_name === 'BASIC'
                        // ) ||
                        //for sales manager.
                        
                        // this.props.currentUser.user_id == this.state.asssignedTo_id
                        this.shouldShowAcceptAsLeadButton() ? (
                            // *************
                            <div className={styles.form_group + " col-lg-2"}>
                              <label className={`${styles.form_title} `}>
                                Accept as lead
                              </label>
                              <div className="row ml-0">
                                <div
                                  className={
                                    " col-md-12 " +
                                    styles.form_group +
                                    " " +
                                    styles.handover_btn
                                  }
                                >
                                  <Button
                                    disabled={this.state.is_won !== null}
                                    className={styles.handshakeBtn}
                                    onClick={() => this.handoverToSales()}
                                  >
                                    <img
                                      src={this.getHandshakeImg()}
                                      alt="handshake"
                                      width="150"
                                      height="50"
                                    />
                                  </Button>
                                </div>
                              </div>
                              <FormErrors
                                formErrors={this.formErrors}
                                field="is_hand_over"
                              />
                            </div>
                          ) : (
                            ""
                          )
                      }

                      {this.shouldShowAcceptAsLeadButton() ? (
                          <div
                            className={
                              styles.form_group + " col-lg-1 " + styles.handover_or
                            }
                          >
                            <span className={styles.orbackground}>OR</span>
                          </div>
                        ) : (
                          ""
                        )}

                      {currentUserHasExpectedLicenseType(this.props.currentUser, ['MANAGER', 'PROFESSIONAL']) ? (
                          <>
                            <div
                              className={styles.form_group + " col-md-6 col-lg-3"}
                            >
                              <label
                                className={
                                  styles.form_title + " test-assign-margin-bottom"
                                }
                              >
                                Assign to
                            <span className={styles.required_field}>*</span>
                              </label>
                              <div>
                                <select
                                  className={`${styles.selectCompany} ${
                                    this.state.handshakeImg == handshake2 ||
                                      this.state.is_won == 1 ||
                                      this.state.is_won == 0
                                      ? styles.disabled_assignedToBtn
                                      : ""
                                    } `}
                                  value={selectedOrg}
                                  name="assignedOrg_id"
                                  onChange={(event) => {
                                    this.handleUserInput(event, true);
                                    this.checkHandoverValidation(event);
                                  }}
                                  disabled={
                                    this.state.handshakeImg == handshake2 ||
                                    this.state.is_won != null ||
                                    this.props.orgChildList.disabled ||
                                    (this.props.currentLead && this.props.currentLead.is_hand_over == 2) ||
                                    !currentUserHasExpectedLicenseType(this.props.currentUser, ['MANAGER', 'PROFESSIONAL'])
                                  }
                                >
                                  <option hidden>Select Organization</option>

                                  {parents && parents.length > 0 && (
                                    <optgroup
                                      label="Parent Org"
                                      key={"group_parent"}
                                    >
                                      {parents.map((p, i) => renderOptions(p, i))}
                                    </optgroup>
                                  )}
                                  {childs && childs.length > 0 && (
                                    <optgroup label="Child Org" key={"group_child"}>
                                      {childs.map((c, i) => renderOptions(c, i))}
                                    </optgroup>
                                  )}
                                </select>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}

                      {currentUserHasExpectedLicenseType(this.props.currentUser, ['MANAGER', 'PROFESSIONAL']) ? (
                          <>
                            <div
                              className={styles.form_group + " col-md-6 col-lg-3"}
                            >
                              <label
                                className={
                                  styles.form_title + " test-assign-margin-bottom"
                                }
                              >
                                Select Team Member
                              </label>
                              <div>
                                <select
                                  className={`${styles.selectCompany} ${
                                    this.state.handshakeImg == handshake2 ||
                                      this.state.is_won == 1 ||
                                      this.state.is_won == 0 ||
                                      selectedOrg !=
                                      this.props.currentUser.organization_id.toString()
                                      ? styles.disabled_assignedToBtn
                                      : ""
                                    } `}
                                  value={this.state.asssignedTo_id}
                                  name="asssignedTo_id"
                                  onChange={(event) => {
                                    this.handleUserInput(event);
                                    this.checkHandoverValidation(event);
                                  }}
                                  disabled={
                                    this.state.handshakeImg == handshake2 ||
                                    this.state.is_won == 1 ||
                                    this.state.is_won == 0 ||
                                    selectedOrg !=
                                    this.props.currentUser.organization_id.toString()
                                  }
                                >
                                  <option hidden>Select assigned to</option>
                                  {this.props.assignedToList &&
                                    this.props.assignedToList.length > 0
                                    ? this.props.assignedToList.map(
                                      (item, index) => {
                                        return (
                                          <option
                                            value={item.id}
                                            key={"assignedTo_" + index}
                                          >
                                            {item.id &&
                                              item.id ==
                                              this.props.currentUser.user_id
                                              ? "Self"
                                              : item.name_without_initials}
                                          </option>
                                        );
                                      }
                                    )
                                    : ""}
                                </select>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                    </div>
                  )}
                </>
              ) : (
                ""
              )}

            {currentUserHasExpectedLicenseType(this.props.currentUser, ['BASIC', 'LITE']) ? (
                <div className={` ${styles.basic_handshake} row`}>
                  <div
                    className={
                      styles.form_group + " col-md-12" + styles.basic_handshake
                    }
                  >
                    <label
                      className={`${styles.form_title} ${styles.handover_styling}`}
                    >
                      Handover to sales ?
                    </label>
                    <div className="row ml-0">
                      <div
                        className={
                          " col-md-12 " +
                          styles.form_group +
                          " " +
                          styles.handover_btn
                        }
                      >
                        <Button
                          className={styles.handshakeBtn}
                          onClick={() => this.handoverToSales()}
                        >
                          <img
                            src={
                              this.state.handshakeImg == handshake
                                ? handshake //handshakeLarge
                                : this.state.handshakeImg == handshake1
                                  ? handshake1 //handshake1Large
                                  : this.state.handshakeImg == handshake1
                                    ? handshake2 //handshake2Large
                                    : ""
                            }
                            alt="handshake"
                          //width="160"
                          //height="84"
                          />
                        </Button>
                      </div>
                    </div>
                    <FormErrors
                      formErrors={this.formErrors}
                      field="is_hand_over"
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

            <div className="row">
              <div
                className={
                  styles.form_group + " col-md-12 " + styles.note_padding
                }
              >
                <label className={styles.form_title}>
                  {this.state.lead_id ? "Add new note" : "Note"}
                </label>
                <div className="notesHint">
                  {1024 - this.state.notes.length + " characters left"}
                </div>
                <textarea
                  className={styles.taxtArea}
                  id="noter-text-area"
                  name="notes"
                  value={this.state.notes}
                  onChange={(event) => this.handleUserInput(event)}
                  maxLength="1024"
                />
                <FormErrors formErrors={this.formErrors} field="notes" />
              </div>
            </div>
          </div>
          <DialogActions className={styles.removeBorder}>
            {this.state.submitButton ? (
              <CircularProgress
                style={{
                  color: "#19D98C",
                  marginRight: 78,
                  marginBottom: 20,
                }}
              />
            ) : (
                <Button
                  className={styles.saveButton}
                  disabled={
                    this.state.submitButton || this.state.submitButtonBlur
                  }
                  onClick={() => this.addLead()}
                  color="primary"
                >
                  {"Save"}
                </Button>
              )}
          </DialogActions>
          <CropperDialog
            cropperDialog={this.state.cropperDialog}
            handleCloseCropDialog={this.handleCloseCropDialog}
            cropImage={this.cropImage}
            src={this.state.uploadedImage}
            ratio={false}
          />
        </DialogContent>
      </div>
    );
  }
}

export default NewLead;
