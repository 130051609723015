import axios from "axios";
import { userLogout } from "../pages/Login/redux/actions";
import CryptoJS from "crypto-js";
import { getStorageItem } from "../common/common-functions";

// const BASE_URL = "http://localhost:8888/api/v3"; //rahul pc ip
// const BASE_URL = "http://192.168.151.186:8888/api";   //sachin pc ip
//const BASE_URL = 'http://13.48.82.111:8888/api';   //alpha-staging server ip
// const BASE_URL = "https://prod-alb.leadx.no/api/v2";  //prod build
// const BASE_URL = "https://dev-alb.leadx.no/api/v2"; //dev server ******
// const BASE_URL = "https://alpha-albv2.leadx.no/api"; //alpha v2
// const BASE_URL = "https://test-albv2.leadx.no/api/v2";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

axios.defaults.withCrendentails = true;

const hashCode = (s = "") => {
  return s.split("").reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
};

var axiosInstance = axios.create({
  withCredentials: true,
  timeout: 150000,
  baseURL: BASE_URL,
  "Access-Control-Allow-Credentials": true,
  /* other custom settings */
});

//const isDevEnv = process.env.NODE_ENV === "development";
const isDevEnv = process.env.NODE_ENV !== "development"; 

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter((callback) => callback(access_token));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

const getNewAccessToken = () => {
  const refreshToken = localStorage.getItem("_refresh_token_");
  const body = {
    refresh_token: refreshToken,
  };
  const shouldEncrypt =
    !isDevEnv && process.env.REACT_APP_CRYPTO_KEY !== undefined;
  const data = shouldEncrypt
    ? JSON.stringify({
        sar: CryptoJS.AES.encrypt(
          JSON.stringify(body),
          process.env.REACT_APP_CRYPTO_KEY
        ).toString(),
      })
    : body;

  const headers = {
    authorization: localStorage.getItem("_access_token_"),
  };

  headers["Content-Type"] = "application/json;charset=UTF-8";
  headers["phk"] = hashCode(JSON.stringify(body));

  return axios
    .post(`${BASE_URL}/users/access_token`, data, {
      headers,
    })
    .then((res) => {
      let response;
      if (shouldEncrypt) {
        let bytes = CryptoJS.AES.decrypt(
          res.data.data,
          process.env.REACT_APP_CRYPTO_KEY
        );
        try {
          response = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        } catch (error) {
          return null;
        }
      } else {
        response = res.data.data;
      }
      const access_token = `bearer ${response.access_token}`;
      localStorage.setItem("_access_token_", access_token);
      return access_token;
    });
};

axiosInstance.interceptors.request.use(
  async (config) => {
    // perform a task before the request is sent
    config.headers["Authorization"] = await localStorage.getItem(
      "_access_token_"
    );
    let currentUser = getStorageItem("user");

    if (currentUser) {
      currentUser = JSON.parse(currentUser);
      config.headers["account"] = currentUser.organization_id;
    }

    console.log(config.data, "ok");
    config.headers["phk"] = hashCode(JSON.stringify(config.data));
    console.log(isDevEnv, "Is Dev environment");

    // extending the timeout for sales news (add & update) and profile updation for image
    if (
      config.url === `/salesNews/add` ||
      config.url === `/salesNews/update` ||
      config.url === `/users/updateAvatar` ||
      config.url === `/login/checkTokenExist` ||
      config.url === `/lead/addLeadDocument` ||
      config.url === "/users/bulkUpload" ||
      config.url === "/account/updateCompanyAvtaar" ||
      isDevEnv
    ) {
      config.timeout = 30000;
      if ("/users/downloadSampeFile" === config.url) {
        config.headers["responseType"] = "blob";
      }
      if (
        [`/lead/addLeadDocument`, "/users/bulkUpload"].indexOf(config.url) !==
        -1
      ) {
        config.headers["Content-Type"] = "multipart/form-data";
      }
      return config;
    } else {
      let data = config.data;
      if (process.env.REACT_APP_CRYPTO_KEY !== undefined) {
        config.data = JSON.stringify({
          sar: CryptoJS.AES.encrypt(
            JSON.stringify(data),
            process.env.REACT_APP_CRYPTO_KEY
          ).toString(),
        });
        config.headers["Content-Type"] = "application/json;charset=UTF-8";
      }
    }
    return config;
  },
  (error) => {
    // handle the error
    console.log("error error error", error);
    return Promise.reject(error);
  }
);

function prepareResponse(config) {
  config.data.statusCode = config.status;
  config.data.status = config.status === 200;

  if (config.data.statusCode === 200) {
    let data = config.data.data;
    if (!isDevEnv && process.env.REACT_APP_CRYPTO_KEY !== undefined) {
      let bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_CRYPTO_KEY);
      try {
        config.data.data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      } catch (error) {
        console.log(error);
      }
    }
  }

  return config;
}

function redirectUserToLogin() {
  userLogout();
  window.location.replace("/");
}

axiosInstance.interceptors.response.use(
  (config) => {
    // perform a task before the request is sent
    config = prepareResponse(config);
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest.url.includes('login')) {
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;
        getNewAccessToken()
          .then((access_token) => {
            onAccessTokenFetched(access_token);
          })
          .catch((error) => {
            onAccessTokenFetched(null);
          })
          .finally(() => {
            isAlreadyFetchingAccessToken = false;
          });
      }

      const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber(async (access_token) => {
          if (!access_token) {
            redirectUserToLogin();
            const response = {
              data: {
                statusCode: 401,
                errorMessage: "Unauthorized",
                status: false,
              },
            };
            resolve(response);
          } else {
            originalRequest.headers.Authorization = access_token;
            const result = await axios(originalRequest);
            resolve(prepareResponse(result));
          }
        });
      });
      return retryOriginalRequest;
    } else {
      console.log(error.response);
      const response = {
        data: {
          statusCode: error.response && error.response.status,
          errorMessage: error.response && error.response.data.errorMessage,
          status: false,
        },
      };
      return Promise.resolve(response);
    }
  }
);
export default axiosInstance;
