import React, { useState, useEffect, useRef } from "react";
import styles from "../graphContainer.module.scss";
import revenue_circle from "../../../../assets/images/bi/revenue_circle.png";
import grossrevenue from "../../../../assets/images/bi/grossrevenue.png";
import util from "../util";
import API from "../API";
import { Card, CardContent } from "@material-ui/core";

import WeightDelChart from "../PipeLineChart/weightDeal";
import GrossRevChart from "../PipeLineChart/grossRevChart";
import LeadBySatgeChart from "../PipeLineChart/leadByStage";
import LeadsBySourceChart from "../PipeLineChart/leadBySource";
import LargeScreenLoader from "../../../common/Loaders/PulseLoader/pulse-loader";
import { toMillion } from "../../../common/Util";
import LegendContainer, { Tile } from "../LegendContainer";
import NoDataBarChart from "../NoDataBarChart";

const PipeLine = ({ organizationArray, fromDate, toDate, ...rest }) => {
  const [weightDealValue, setWeightDealValue] = useState(0);
  const [grossRevValue, setGrossRevValue] = useState(0);
  const [weightChartData, setWeightChartData] = useState(null);
  const [grossRevForcastChartData, setGrossRevForcastChartData] = useState(
    null
  );
  const [leadBySourceChartData, setLeadBySourceChartData] = useState(null);
  const [leadByStageChartData, setLeadByStageChartData] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const weightedDealRef = useRef(null);
  const grossRevRef = useRef(null);
  const leadBySourceRef = useRef(null);
  const leadByStageRef = useRef(null);

  const preValue = {
    labels: [],
    datasets: [
      {
        type: "bar",
        label: "Total",
        backgroundColor: "#999900",
        barThickness: 15,
        data: [],
      },
    ],
  };

  useEffect(() => {
    fetchData();
    fetchWeightDealValue();
    fetchRevenueForcastData();
    fetchLeadBySource();
    fetchLeadByStage();
  }, [organizationArray, fromDate, toDate]);

  const fetchData = async () => {
    setShowLoader(true);
    const params = util.getParams(organizationArray, fromDate, toDate);
    const [widgetDealValue, grossRevValue] = await Promise.all([
      API.getWedgitDealValue(params),
      API.getGrossRevValue(params),
    ]);

    setTimeout(() => {
      fetchLeadBySource();
      fetchRevenueForcastData();
      fetchWeightDealValue();
    }, 50);
    setWeightDealValue(widgetDealValue);
    setGrossRevValue(grossRevValue);
    setShowLoader(false);
  };

  const fetchLeadByStage = async (date) => {
    const params = {
      ...util.getParams(organizationArray, fromDate, toDate),
    };
    const leadByStageChartData = await API.getLeadByStage(params);
    setLeadByStageChartData(leadByStageChartData);
  };

  const fetchLeadBySource = async () => {
    const params = {
      ...util.getParams(organizationArray, fromDate, toDate),
    };
    const leadBySourceChartData = await API.getLeadBySource(params);
    setLeadBySourceChartData(leadBySourceChartData);
  };

  const fetchWeightDealValue = async () => {
    const params = {
      ...util.getParams(organizationArray, fromDate, toDate),
    };
    const weightChartData = await API.getWeightChartData(params);
    setWeightChartData(weightChartData);
  };

  const fetchRevenueForcastData = async () => {
    const params = {
      ...util.getParams(organizationArray, fromDate, toDate),
    };
    const grossRevForcastChartData = await API.getGrossRevForcastData(params);
    setGrossRevForcastChartData(grossRevForcastChartData);
  };

  const buildData = (weightChartData) => {
    if (weightChartData != null) {
      let data = weightChartData.labels.map((x) => {
        return { month: x };
      });
      data.forEach((x, i) => {
        x.Won =
          weightChartData.datasets.find((x) => x.label == "Won").data[i] || 0;
        x.Open =
          weightChartData.datasets.find((x) => x.label == "Open").data[i] || 0;
        x.Gross =
          weightChartData.datasets.find((x) => x.label == "Gross").data[i] || 0;
      });
      return data;
    }
  };

  function isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  const roundAndFixed = (num) => (isFloat(num) ? num.toFixed(2) : num);

  const renderWeightChart = () => {
    const weightedDealPWidth =
      weightedDealRef &&
      weightedDealRef.current &&
      weightedDealRef.current.offsetWidth;
    const data = buildData(weightChartData);
    const allKeys = ["Won", "Open", "Gross"];
    const colors = {
      Won: "#ED5230",
      Open: "#F68E77",
      Lost: "#4A2B24",
    };

    const noData =
      data && data.every((a) => a.Won === 0 && a.Open === 0 && a.Gross === 0);

    console.log(noData, "No Data");

    return (
      <div className={"col-md-6 " + styles.chartPadding}>
        <Card className={styles.card}>
          <div className={"col-md-12 " + styles.cardHeader}>
            <div className="row">
              <div className="col-md-6">
                <h1 className={styles.activities_container_heading}>
                  Weighted Deal Value
                </h1>
              </div>
            </div>
          </div>
          {!noData ? (
            <>
              <CardContent>
                <div className="row">
                  <div className="col" ref={weightedDealRef}>
                    {weightedDealPWidth && data && (
                      <WeightDelChart
                        heighty={250}
                        widthx={weightedDealPWidth}
                        xAxislabel="month"
                        data={data}
                        keys={allKeys}
                        colors={colors}
                      />
                    )}
                  </div>
                </div>
              </CardContent>
              <div className="col" style={{ marginBottom: 10, marginTop: 25 }}>
                <LegendContainer
                  justifyContent="flex-end"
                  containerStyle={{
                    width: "90%",
                    justifyContent: "flex-end",
                    marginRight: "0px !important",
                  }}
                >
                  <Tile color="#ED5230" text="Won" marginRight={15} />
                  <Tile color="#F68E77" text="Open" marginRight={15} />
                </LegendContainer>
              </div>
              <div class="col" style={{ overflowX: "auto" }}>
                <table className={styles.graphTable}>
                  <tr className={styles.header}>
                    <td></td>
                    {weightChartData &&
                      weightChartData.labels.map((x) => {
                        return <td>{x}</td>;
                      })}
                  </tr>
                  <tr className={styles.tbody}>
                    <td>
                      <Tile color="#ED5230" text="Won" />
                    </td>
                    {weightChartData &&
                      weightChartData.labels.map((x, i) => {
                        return (
                          <td>
                            {(weightChartData &&
                              toMillion(roundAndFixed(
                                weightChartData.datasets[0].data[i]
                              )))}
                          </td>
                        );
                      })}
                  </tr>
                  <tr>
                    <td>
                      <Tile color="#F68E77" text="Open" />
                    </td>
                    {weightChartData &&
                      weightChartData.labels.map((x, i) => {
                        return (
                          <td>
                            {toMillion(roundAndFixed(
                              weightChartData.datasets[1].data[i]
                            ))}
                          </td>
                        );
                      })}
                  </tr>
                  <tr>
                    <td>
                      <Tile color="transparent" text="Gross" />
                    </td>

                    {weightChartData &&
                      weightChartData.labels.map((x, i) => {
                        return (
                          <td>
                            {(weightChartData &&
                              toMillion(roundAndFixed(
                                weightChartData.datasets[2].data[i]
                              )))}
                          </td>
                        );
                      })}
                  </tr>
                </table>
              </div>
            </>
          ) : (
            <NoDataBarChart />
          )}
        </Card>
      </div>
    );
  };

  const renderGrossRevenueForcastChart = () => {
    const grossPWidth =
      grossRevRef && grossRevRef.current && grossRevRef.current.offsetWidth;
    const grossRevChartOptions = {
      id: "grossRevChart",
      chartStyle: "vertical", // horizontal/vertical
      innerBar: false,
      height: 0,
      width: 0,
      margin: {
        top: 20,
        right: 100,
        bottom: 40,
        left: 100,
      },
      hideXaxis: false,
      hideYaxis: false,
      hideCenterLine: false,
      parseTime: "%b-%y",
      hideLegend: false,
      barWidth: 2,
      insideBarWidth: 10,
      multiColorBar: true,
      colors: ["#FEC55A"],
      hideTooltip: false,
      responsive: true,
      ticksSizeYaxis: 5,
      yAxisNumebrFormat: "s",
      xAxisRotate: false,
      yAxisText: true,
      yAxisTextString: "Axis Revenue",
      yAxisDivided: true,
      valueOnTop: true,
      valueOnTopDecimal: false,
    };

    let grossRevChartData = [];
    if (grossRevForcastChartData !== null) {
      grossRevChartData = grossRevForcastChartData.labels.map((x, i) => {
        return { xVal: x, yVal: grossRevForcastChartData.datasets[0].data[i] };
      });
    }

    const revChartData = {
      ...preValue,
      ...grossRevForcastChartData,
    };

    const noData =
      grossRevChartData && grossRevChartData.every((a) => a.yVal === 0);

    return (
      <div className={"col-md-6 " + styles.chartPadding}>
        <Card className={styles.card}>
          <div className={"col-md-12 " + styles.cardHeader}>
            <div className="row">
              <div className="col-md-6">
                <h1 className={styles.activities_container_heading}>
                  Gross Revenue Forecast
                </h1>
              </div>
            </div>
          </div>
          {!noData ? (
            <>
              <CardContent>
                <div className="row">
                  <div className="col" ref={grossRevRef}>
                    {grossPWidth && (
                      <GrossRevChart
                        height={215}
                        width={grossPWidth}
                        data={grossRevChartData ? grossRevChartData : []}
                        options={grossRevChartOptions}
                      />
                    )}
                  </div>
                </div>
              </CardContent>
              <div className="col" style={{ marginBottom: 10 }}>
                <LegendContainer
                  justifyContent="flex-end"
                  containerStyle={{
                    width: "90%",
                    justifyContent: "flex-end",
                    marginRight: "0px !important",
                  }}
                >
                  <Tile color="#FBB147" text="Total" marginRight={15} />
                </LegendContainer>
              </div>
              <div class="col" style={{ overflowX: "auto" }}>
                <table className={styles.graphTable}>
                  <tr className={styles.header}>
                    <td></td>
                    {revChartData &&
                      revChartData.labels.map((x) => {
                        return <td>{x}</td>;
                      })}
                  </tr>
                  <tr className={styles.tbody}>
                    <Tile color="#FBB147" text="Total" marginRight={15} />
                    {revChartData.labels.map((x, i) => {
                      return (
                        <td>
                          {(revChartData && toMillion(revChartData.datasets[0].data[i]))}
                        </td>
                      );
                    })}
                  </tr>
                </table>
              </div>
            </>
          ) : (
            <NoDataBarChart />
          )}
        </Card>
      </div>
    );
  };

  const renderLeadBySourceChart = () => {
    const leadBySourcePWidth =
      leadBySourceRef &&
      leadBySourceRef.current &&
      leadBySourceRef.current.offsetWidth;
    const LeadBySourceOptions = {
      id: "leadBySource",
      chartStyle: "vertical", // horizontal/vertical
      innerBar: false,
      height: 0,
      width: 0,
      margin: {
        top: 20,
        right: 100,
        bottom: 40,
        left: 100,
      },
      hideXaxis: false,
      hideYaxis: false,
      hideCenterLine: false,
      parseTime: "%b-%y",
      hideLegend: false,
      barWidth: 2,
      insideBarWidth: 10,
      multiColorBar: true,
      colors: ["#FEC55A"],
      hideTooltip: false,
      responsive: true,
      ticksSizeYaxis: 5,
      yAxisNumebrFormat: "s",
      xAxisRotate: true,
      yAxisText: true,
      yAxisTextString: "Axis No. of Leads",
      yAxisDivided: true,
      valueOnTop: true,
      valueOnTopDecimal: false,
    };

    let LeadBySource = [];
    if (leadBySourceChartData != null) {
      LeadBySource = leadBySourceChartData.labels.map((x, i) => {
        return { xVal: x, yVal: leadBySourceChartData.datasets[0].data[i] };
      });
    }

    const LeadSourceData = {
      ...{
        labels: [],
        datasets: [
          {
            type: "bar",
            label: "Total",
            backgroundColor: "#FBB147",
            barThickness: 15,
            data: [],
          },
        ],
      },
      ...leadBySourceChartData,
    };

    const noData = LeadBySource && LeadBySource.length === 0;

    return (
      <div className={"col-md-6 " + styles.chartPadding}>
        <Card className={styles.card}>
          <div className={"col-md-12 " + styles.cardHeader}>
            <div className="row">
              <div className="col-md-6">
                <h1 className={styles.activities_container_heading}>
                  Leads by Sources
                </h1>
              </div>
            </div>
          </div>
          {!noData ? (
            <>
              <CardContent>
                <div className="row">
                  <div className="col" ref={leadBySourceRef}>
                    {leadBySourcePWidth && (
                      <LeadsBySourceChart
                        height={250}
                        width={leadBySourcePWidth}
                        data={LeadBySource ? LeadBySource : []}
                        options={LeadBySourceOptions}
                      />
                    )}
                  </div>
                </div>
              </CardContent>
              <div className="col" style={{ marginBottom: 10 }}>
                <LegendContainer
                  justifyContent="flex-end"
                  containerStyle={{
                    width: "90%",
                    justifyContent: "flex-end",
                    marginRight: "0px !important",
                  }}
                >
                  <Tile color="#FBB147" text="Total" marginRight={15} />
                </LegendContainer>
              </div>
              <div class="col">
                <table className={styles.graphTable}>
                  <tr className={styles.header}>
                    <td></td>
                    {LeadSourceData &&
                      LeadSourceData.labels.map((x) => {
                        return <td>{x}</td>;
                      })}
                  </tr>
                  <tr className={styles.tbody}>
                    <Tile color="#FBB147" text="Total" marginRight={15} />
                    {LeadSourceData &&
                      LeadSourceData.labels.map((x, i) => {
                        return (
                          <td>
                            {(LeadSourceData &&
                              LeadSourceData.datasets[0].data[i]) ||
                              0}
                          </td>
                        );
                      })}
                  </tr>
                </table>
              </div>
            </>
          ) : (
            <NoDataBarChart />
          )}
        </Card>
      </div>
    );
  };

  const renderLeadByStageChart = () => {
    const leadByStagePWidth =
      leadByStageRef &&
      leadByStageRef.current &&
      leadByStageRef.current.offsetWidth;
    const LeadByStageOptions = {
      id: "leadbyStageChart",
      chartStyle: "horizontal", // horizontal/vertical
      height: 0,
      width: 0,
      margin: {
        top: 20,
        right: 40,
        bottom: 40,
        left: 80,
      },
      hideXaxis: false,
      hideYaxis: false,
      hideCenterLine: false,
      parseTime: "%b-%y",
      hideLegend: false,
      barWidth: 10,
      insideBarWidth: 10,
      multiColorBar: true,
      colors: ["#FEC55A"],
      hideTooltip: false,
      responsive: true,
      ticksSize: 5,
      xAxisRotate: false,
      yAxisText: true,
      xAxisText: true,
      yAxisTextString: "Axis No. of Leads",
      xAxisTextString: "",
      yAxisDivided: true,
      valueOnTop: true,
      valueOnTopDecimal: false,
    };

    let LeadBySatge = [];
    if (leadByStageChartData !== null) {
      LeadBySatge = leadByStageChartData.labels.map((x, i) => {
        return { xVal: x, yVal: leadByStageChartData.datasets[0].data[i] };
      });
    }

    const LeadStageData = {
      ...{
        labels: [],
        datasets: [
          {
            type: "bar",
            label: "Total",
            backgroundColor: "#FBB147",
            barThickness: 15,
            data: [],
          },
        ],
      },
      ...leadByStageChartData,
    };

    const noData = LeadBySatge && LeadBySatge.length === 0;

    return (
      <div className={"col-md-6 " + styles.chartPadding}>
        <Card className={styles.card}>
          <div className={"col-md-12 " + styles.cardHeader}>
            <div className="row">
              <div className="col-md-6">
                <h1 className={styles.activities_container_heading}>
                  Lead By Stage
                </h1>
              </div>
            </div>
          </div>
          {!noData ? (
            <>
              <CardContent>
                <div className="row">
                  <div className="col" ref={leadByStageRef}>
                    {leadByStagePWidth && (
                      <LeadBySatgeChart
                        height={280}
                        width={leadByStagePWidth}
                        data={LeadBySatge ? LeadBySatge : []}
                        options={LeadByStageOptions}
                      />
                    )}
                  </div>
                </div>
              </CardContent>
              <div className="col" style={{ marginBottom: 10 }}>
                <LegendContainer
                  justifyContent="flex-end"
                  containerStyle={{
                    width: "90%",
                    justifyContent: "flex-end",
                    marginRight: "0px !important",
                  }}
                >
                  <Tile color="#FBB147" text="Total" marginRight={15} />
                </LegendContainer>
              </div>
              <div class="col">
                <table className={styles.graphTable}>
                  <tr className={styles.header}>
                    <td></td>
                    {LeadStageData.labels.map((x) => {
                      return <td>{x}</td>;
                    })}
                  </tr>
                  <tr className={styles.tbody}>
                    <Tile color="#FBB147" text="Total" marginRight={15} />
                    {LeadStageData &&
                      LeadStageData.labels.map((x, i) => {
                        return (
                          <td>
                            {(LeadStageData &&
                              LeadStageData.datasets[0].data[i]) ||
                              0}
                          </td>
                        );
                      })}
                  </tr>
                </table>
              </div>
            </>
          ) : (
            <NoDataBarChart />
          )}
        </Card>
      </div>
    );
  };

  const renderPage = () => {
    return (
      <>
        <div className="container-body">
          <div className="row">
            <div className="col-md-6 border-right">
              <div className="row">
                <div className={"col-sm-6 " + styles.topSectionLeft}>
                  <img
                    className={styles.icon_img}
                    src={revenue_circle}
                    alt="avrageVelocity"
                  />
                </div>
                <div className={"col-sm-6 " + styles.topSection}>
                  <div className={styles.first_content_style}>
                    <div className={styles.content_values1}>
                      {`${toMillion(weightDealValue.toFixed(0))}`}
                    </div>
                    <p className={styles.title_Pulselite}>
                      Weighted Deal Value{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 border-right">
              <div className="row">
                <div className={"col-sm-6 " + styles.topSectionLeft}>
                  <img
                    className={styles.icon_img}
                    src={grossrevenue}
                    alt="LeadIcon"
                  />
                </div>
                <div className={"col-sm-6 " + styles.topSection}>
                  <div className={styles.first_content_style}>
                    <div className={styles.content_values1}>
                      {`${toMillion(grossRevValue.toFixed(0))}`}
                    </div>
                    <p className={styles.title_Pulselite}>
                      {" "}
                      Gross Revenue Forecast
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-body">
          <div className="row justify-content-center mb-4">
            {renderWeightChart()}
            {renderGrossRevenueForcastChart()}
          </div>
        </div>

        <div className="container-body">
          <div className="row justify-content-center mb-4">
            {renderLeadBySourceChart()}
            {renderLeadByStageChart()}
          </div>
        </div>
      </>
    );
  };

  return <>{showLoader ? <LargeScreenLoader /> : renderPage()}</>;
};

export default PipeLine;
