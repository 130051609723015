import React, { Component } from "react";
import styles from "./WizardError.module.scss";

const wizardError = (props) => (
    <div className={styles.wizard_error}>
        <img className={styles.wizard_error_img} src={require('../../../../assets/oops.png')} alt={"Wizard Error!"} />
        <p className={styles.wizard_error_text}>Wizard Error!!!</p>
        <p className={styles.wizard_error_text2}>Follow the wizard steps to Configure</p>
    </div>
);

export default wizardError;