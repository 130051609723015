import React, { Component } from "react";
import { connect } from "react-redux";
import SyncDataSchedulerCRMComponent from "./component/SyncDataSchedulerCRMComponent";

class SyncDataSchedulerCRMContainer extends Component {
    render() {
        const syncDataSchedulerComponentProps = {...this.props };
        return <SyncDataSchedulerCRMComponent {...syncDataSchedulerComponentProps }
        />;
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        idcrm_integration: state.CRMConfigureDetails.idcrm_integration,
        idcrm_master: state.CRMIntegrationDetails.idcrm_master,
        crm_name: state.CRMIntegrationDetails.crm_name,
        isActive: state.CRMIntegrationDetails.isActive,
        configured: state.CRMIntegrationDetails.configured
    };
};

/* const mapDispatchToProps = dispatch => {
    return {
        getSalesActivititesTasks: requestBody =>
            dispatch(actions.getSalesActivititesTasks(requestBody)),
        getSalesTasks: requestBody =>
            dispatch(actionsTasks.getSalesTasks(requestBody))
    };
}; */

export default connect(
    mapStateToProps/* ,
    mapDispatchToProps */
)(SyncDataSchedulerCRMContainer);