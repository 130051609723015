import * as types from "./types";

export const setIdCRM_Integration = (idcrm_integration) => {
  return {
    type: types.SETIDCRM_INTEGRATION,
    payload: { idcrm_integration: idcrm_integration }
  };
};


export default {
  setIdCRM_Integration
};
