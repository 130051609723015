import React, { Component } from "react";
import "./App.scss";
import { Route as LibRoute, Redirect, Switch } from "react-router-dom";
import Login from "./app/pages/Login/LoginContainer";
import CompleteSalesNews from "./app/pages/CompleteSalesNews/CompleteSalesNewsConatiner";
import { PrivateRoute } from "./app/auth/privateRoute";
import SalesNewsContainer from "./app/pages/SalesNews/SalesNewsContainer";
import SalesFeedContainer from "./app/pages/SalesFeed/SalesFeedContainer";
import SalesCommunityContainer from "./app/pages/SalesCommunity/sales-community-container";
import MyPipelineContainer from "./app/pages/MyPipeline/my-pipline-container";
import ActivitiesContainer from "./app/pages/Activities/ActivitiesContainer";
import SnackbarComponent from "./app/common/Snackbar/snackbar";
import ForgotPassword from "./app/pages/ForgotPassword/component/forgotPassword";
import Register from "./app/pages/Register/RegisterContainer";
import ProfileContainer from "./app/pages/Profile/profileContainer";
import ResetPassword from "./app/pages/ResetPassword/ResetPasswordContainer";
import RedirectComponent from "./app/pages/Redirect/redirect";
import { openSnackbar } from "./app/common/Snackbar/snackbar";
import GraphContainer from "./app/pages/Graph";
import PulseLite2 from "./app/pages/PulseLite2/pulseLiteContainer";
import IntegrationsContainer from "./app/pages/Integrations/IntegrationsContainer";
import ConfigureCRMContainer from "./app/pages/ConfigureCRM/ConfigureCRMContainer";
import AuthenticateCRMContainer from "./app/pages/AuthenticateCRM/AuthenticateCRMContainer";
import AssignSalesTeamCRMContainer from "./app/pages/AssignSalesTeamCRM/AssignSalesTeamCRMContainer";
import SyncDataSchedulerCRMContainer from "./app/pages/SyncDataSchedulerCRM/SyncDataSchedulerCRMContainer";
import ConfigureDataObjsMapCRMContainer from "./app/pages/ConfigureDataObjsMapCRM/ConfigureDataObjsMapCRMContainer";
import ConfigureDataObjsBrregContainer from "./app/pages/ConfigureDataObjsBrreg/ConfigureDataObjsBrregContainer";
import ConfigureBrregContainer from "./app/pages/ConfigureBrreg/ConfigureBrregContainer";
import StatusMappingCRMContainer from "./app/pages/StatusMappingCRM/StatusMappingCRMContainer";
import Administration from "./app/pages/Administration/List";
import OrganizationProfile from "./app/pages/OrganizationProfile/View";
import PendingRequest from "./app/pages/PendingRequest";
import ChildOrganizationList from "./app/pages/ChildOrganization"
import NewChildOrganizationContainer from "./app/pages/newChildOrganization"
import UserManagement from './app/pages/UserManagement';
import UserProfile from './app/pages/UserProfile';
import ApiRegistration from './app/pages/ApiRegistration';
import DataExort from './app/pages/DataExport';
import SubscriptionsContainer from "./app/pages/Subscriptions/";
import BulkUploadUser from './app/pages/BulkUploadUser';
import EditChildOrg from './app/pages/EditChildOrg';
import ChildOrganizationDetail from './app/pages/ChildOrganizationDetail';
import ApiIntegration from "./app/pages/ApiIntegration";
import ErrorBoundry from './app/components/ErrorBoundry';
import UpdateOrgProfile from './app/pages/UpdateOrgProfile';
import ManageAccess from './app/pages/ManageAccess';
import LoadingNewOrg from "./app/pages/LoadingNewOrg";
import RoleConfiguration from "./app/pages/RoleConfiguration";
import PlayGround from "./app/pages/PlayGround";

const Route = (props) => <ErrorBoundry><LibRoute {...props} /></ErrorBoundry>

class App extends Component {
  render() {
    window.addEventListener("load", function () {
      function updateOnlineStatus(event) {
        var condition = navigator.onLine ? "online" : "offline";
        if (condition === "offline") {
          openSnackbar("No internet connection", "snack-error");
        } else {
          openSnackbar("Internet available", "snack-success");
        }
      }

      window.addEventListener("online", updateOnlineStatus);
      window.addEventListener("offline", updateOnlineStatus);
    });

    return (
      <>
        <SnackbarComponent />
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/loading/:account_id/:org/:is_parent" component={LoadingNewOrg} />
          <Route
            path="/register/:key?"
            component={Register}
          />
          <Route
            exact
            path="/reset-password/:token"
            component={ResetPassword}
          />
          <Route exact path="/redirect" component={RedirectComponent} />
          <PrivateRoute
            path="/sales-news/sales-feed/:id"
            component={SalesFeedContainer}
          />
          <PrivateRoute path="/sales-news/:id" component={CompleteSalesNews} />
          <PrivateRoute
            exact
            path="/sales-news"
            component={SalesNewsContainer}
          />
          <PrivateRoute
            exact
            path="/sales-community"
            component={SalesCommunityContainer}
          />
          <PrivateRoute
            exact
            path="/my-pipeline"
            component={MyPipelineContainer}
          />
          <PrivateRoute
            exact
            path="/activities"
            component={ActivitiesContainer}
          />
          <PrivateRoute exact path="/pulse" component={GraphContainer} />
          <PrivateRoute exact path="/pulse-reward" component={PulseLite2} />
          <PrivateRoute adminOnly exact path="/administration" component={Administration} />
          <PrivateRoute adminOnly exact path="/administration/organization_profile" component={OrganizationProfile} />
          <PrivateRoute adminOnly exact path="/administration/role_config" component={RoleConfiguration} />
          <PrivateRoute adminAndParentOnly exact path="/administration/organization_profile/:id/edit" component={UpdateOrgProfile} />

          <PrivateRoute adminOnly exact path="/administration/api_integration" component={ApiIntegration} />
          {/*  Child Org */}
          <PrivateRoute adminAndParentOnly exact path="/administration/child_organization" component={ChildOrganizationList} />

          <PrivateRoute adminOnly exact path="/administration/user_management" component={UserManagement} />
          <PrivateRoute adminAndParentOnly exact path="/administration/user_management/manage_access" component={ManageAccess} />
          <PrivateRoute adminOnly exact path="/administration/user_management/bulk_upload" component={BulkUploadUser} />
          <PrivateRoute adminOnly exact path="/administration/user_management/user_profile/:id" component={UserProfile} />

          {/* Data Export */}
          <PrivateRoute adminOnly exact path="/administration/data_export" component={DataExort} />

          {/*  Child Org */}
          <PrivateRoute adminOnly exact path="/administration/child_organization" component={ChildOrganizationList} />

          <PrivateRoute adminOnly exact path="/administration/new_child_organization" component={NewChildOrganizationContainer} />
          <PrivateRoute adminOnly exact path="/administration/child_organization/:id" component={ChildOrganizationDetail} />
          <PrivateRoute adminOnly exact path="/administration/child_organization/edit/:id" component={EditChildOrg} />

          <PrivateRoute adminOnly exact path="/administration/user_management/pending_request" component={PendingRequest} />
          <PrivateRoute adminOnly exact path="/administration/api_registration" component={ApiRegistration} />
          <PrivateRoute exact path="/profile" component={ProfileContainer} />
          {/* By Rekha 7.12.19 */}
          <PrivateRoute
            exact
            path="/integrations"
            component={IntegrationsContainer}
            adminAndParentOnly
          />
          <PrivateRoute
            exact
            path="/integrations/configureCRM"
            component={ConfigureCRMContainer}
          />
          <PrivateRoute
            exact
            path="/integrations/authenticateCRM"
            component={AuthenticateCRMContainer}
          />
          <PrivateRoute
            exact
            path="/integrations/assignsalesteamCRM"
            component={AssignSalesTeamCRMContainer}
          />
          <PrivateRoute
            exact
            path="/integrations/syncdataschedulerCRM"
            component={SyncDataSchedulerCRMContainer}
          />
          <PrivateRoute
            exact
            path="/integrations/configuredataobjsmapCRM"
            component={ConfigureDataObjsMapCRMContainer}
          />
          <PrivateRoute
            exact
            path="/integrations/configureBrreg"
            component={ConfigureBrregContainer}
          />
          <PrivateRoute
            exact
            path="/integrations/configuredataobjsbrreg"
            component={ConfigureDataObjsBrregContainer}
          />
          <PrivateRoute
            exact
            path="/integrations/statusMappingCRM"
            component={StatusMappingCRMContainer}
          />
          <PrivateRoute
            exact
            path="/administration/subscriptions"
            component={SubscriptionsContainer}
            adminAndParentOnly
          />
          <PrivateRoute
            exact
            path="/playground"
            component={PlayGround}
          />
          {/* By Rekha 7.12.19 */}
          <Route
            path="/"
            render={() => {
              return <Redirect to="/login" />;
            }}
          />
        </Switch>
      </>
    );
  }
}

export default App;
