import axios from "../../common/api-config";

const obj = {
    async refreshAccountsToSwitch(account_id) {
        const url = `users/refreshAccountsToSwitch`;
        const response = await axios.post(url, { account_id });
        return response.data;
    }
};

export default obj;
