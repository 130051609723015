import Autosuggest from "react-autosuggest";
import React from "react";
import styles from "./autocompletewithadd.module.scss";

class AutoCompleteWithAdd extends React.Component {
  constructor() {
    super();

    this.state = {
      value: "",
      suggestions: [],
      inputChanged: false
    };
  }

  componentDidMount() {
    this.setState({
      suggestions: this.props.suggestions ? this.props.suggestions : []
    });
  }

  componentDidUpdate = async (prevProps, prevState) => {
    if (
      this.props.value &&
      (this.props.value != prevProps.value ||
        this.props.suggestions != prevProps.suggestions)
    ) {
      var selectedRecord = this.props.suggestions
        ? this.props.suggestions.filter(item => {
            return item.id == this.props.value;
          })
        : [];

      if (this.props.autoCompleteName === "leadCategory") {
        this.setState({
          value:
            typeof selectedRecord !== "undefined" &&
            selectedRecord[0] &&
            selectedRecord[0].name &&
            !this.state.inputChanged
              ? selectedRecord[0].name
              : this.state.value
        });
      }
    }
  };

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    let suggestions =
      inputLength === 0
        ? this.props.suggestions
        : this.props.suggestions
        ? this.props.suggestions.filter(
            record =>
              record.name.toLowerCase().slice(0, inputLength) === inputValue
          )
        : [];

    if (suggestions.length === 0) {
      return [{ isAddNew: true }];
    }

    suggestions = [...suggestions, { isAddNew: true }];
    return suggestions;
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.

  getSuggestionValue = suggestion => {
    if (suggestion.isAddNew) {
      return this.state.value;
    }

    return suggestion.name;
  };

  // Use your imagination to render suggestions.
  renderSuggestion = suggestion => {
    if (suggestion.isAddNew && this.state.value !== "") {
      return (
        <span>
          Add new: <strong>{this.state.value}</strong>
        </span>
      );
    }
    return <div>{suggestion.name}</div>;
  };

  shouldRenderSuggestions = value => {
    return true;
  };

  onChange = async (event, { newValue }) => {
    if (this.props.autoCompleteName === "leadCategory") {
      this.props.onChange(event, newValue, "leadCategory");
    }

    this.setState({
      value: newValue,
      inputChanged: true
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    switch (this.props.autoCompleteName) {
      case "leadCategory":
        if (suggestion.isAddNew) {
          this.props.addCategorySource({
            name: "name",
            value: this.state.value
          });

          break;
        }

        this.props.setCategorySource({
          name: "category_id",
          value: suggestion.id
        });
        break;
    }
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = async ({ value }) => {
    await this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: this.props.suggestions
    });
  };

  render() {
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    let inputProps = {
      placeholder: this.props.placeholder,
      value,
      onChange: this.onChange,
      onBlur: this.onBlur,
      disabled: false,
      maxLength: 30
    };

    if (this.props.disabled) {
      inputProps.disabled = true;
    }

    return (
      <Autosuggest
        theme={styles}
        suggestions={suggestions ? suggestions : []}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={this.onSuggestionSelected}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        // highlightFirstSuggestion={true}
      />
    );
  }
}

export default AutoCompleteWithAdd;

// ++++++++++++++++++++++++++++++++++++++++++++++

// import React from "react";
// import Autocomplete from "react-autocomplete";
// import axios from "../../../common/api-config";

// export default class App extends ({ Component }) {
//   constructor(props, context) {
//     super(props, context);

//     // Set initial State
//     this.state = {
//       // Current value of the select field
//       value: "",
//       // Data that will be rendered in the autocomplete
//       // As it is asynchronous, it is initially empty
//       autocompleteData: [],
//       errorMessage: ""
//     };

//     // Bind `this` context to functions of the class
//     this.onChange = this.onChange.bind(this);
//     this.onSelect = this.onSelect.bind(this);
//     this.getItemValue = this.getItemValue.bind(this);
//     this.renderItem = this.renderItem.bind(this);
//     this.retrieveDataAsynchronously = this.retrieveDataAsynchronously.bind(
//       this
//     );
//   }

//   /**
//    * Updates the state of the autocomplete data with the remote data obtained via AJAX.
//    *
//    * @param {String} searchText content of the input that will filter the autocomplete data.
//    * @return {Nothing} The state is updated but no value is returned
//    */
//   retrieveDataAsynchronously(searchText) {
//     const params = {
//       arrayFilters: [{}],
//       selectFilters: [],
//       searchFilter: [
//         {
//           company_name: "Apple"
//         }
//       ],
//       sort: {
//         field: "id",
//         sortOrder: "DESC"
//       },
//       paginate: {
//         page: "0",
//         limit: "20"
//       }
//     };

//     axios.post(" ", params).then(response => {
//       if (response.data.data.status == 200) {
//         this.setState({
//           autocompleteData: response.data
//         });
//       } else {
//         this.setState({
//           errorMessage: "Error loading data"
//         });
//       }
//     });

//     let _this = this;

//     // Url of your website that process the data and returns a
//     let url = `mywebsite/searchApi?query=${searchText}`;

//     // Configure a basic AJAX request to your server side API
//     // that returns the data according to the sent text
//     let xhr = new XMLHttpRequest();
//     xhr.open("GET", url, true);
//     xhr.responseType = "json";
//     xhr.onload = () => {
//       let status = xhr.status;

//       if (status == 200) {
//         // In this example we expects from the server data with the structure of:
//         // [
//         //    {
//         //        label: "Some Text",
//         //        value: 1,
//         //    },
//         //    {
//         //        label: "Some Other Text",
//         //        value: 1,
//         //    },
//         // ]
//         // But you can obviously change the render data :)

//         // Update the state with the remote data and that's it !
//         _this.setState({
//           autocompleteData: xhr.response
//         });

//         // Show response of your server in the console
//         console.log(xhr.response);
//       } else {
//         console.error("Cannot load data from remote source");
//       }
//     };

//     xhr.send();
//   }

//   /**
//    * Callback triggered when the user types in the autocomplete field
//    *
//    * @param {Event} e JavaScript Event
//    * @return {Event} Event of JavaScript can be used as usual.
//    */
//   onChange(e) {
//     this.setState({
//       value: e.target.value
//     });

//     /**
//      * Handle the remote request with the current text !
//      */
//     this.retrieveDataAsynchronously(e.target.value);

//     console.log("The Input Text has changed to ", e.target.value);
//   }

//   /**
//    * Callback triggered when the autocomplete input changes.
//    *
//    * @param {Object} val Value returned by the getItemValue function.
//    * @return {Nothing} No value is returned
//    */
//   onSelect(val) {
//     this.setState({
//       value: val
//     });

//     console.log("Option from 'database' selected : ", val);
//   }

//   /**
//    * Define the markup of every rendered item of the autocomplete.
//    *
//    * @param {Object} item Single object from the data that can be shown inside the autocomplete
//    * @param {Boolean} isHighlighted declares wheter the item has been highlighted or not.
//    * @return {Markup} Component
//    */
//   renderItem(item, isHighlighted) {
//     return (
//       <div style={{ background: isHighlighted ? "lightgray" : "white" }}>
//         {item.label}
//       </div>
//     );
//   }

//   /**
//    * Define which property of the autocomplete source will be show to the user.
//    *
//    * @param {Object} item Single object from the data that can be shown inside the autocomplete
//    * @return {String} val
//    */
//   getItemValue(item) {
//     // You can obviously only return the Label or the component you need to show
//     // In this case we are going to show the value and the label that shows in the input
//     // something like "1 - Microsoft"
//     return `${item.value} - ${item.label}`;
//   }

//   render() {
//     return (
//       <div>
//         <Autocomplete
//           getItemValue={this.getItemValue}
//           items={this.state.autocompleteData}
//           renderItem={this.renderItem}
//           value={this.state.value}
//           onChange={this.onChange}
//           onSelect={this.onSelect}
//         />
//       </div>
//     );
//   }
// }
