import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "./redux/actions";
import ConfigureCRMComponent from "./component/ConfigureCRMComponent";

class ConfigureCRMContainer extends Component {
    render() {
        const configureCRMComponentProps = { ...this.props };
        return <ConfigureCRMComponent {...configureCRMComponentProps}
        />;
    }
}

const mapStateToProps = state => {
    console.log("state in mapstatetoprops", state.CRMConfigureDetails.idcrm_integration);
    return {
        ...state,
        idcrm_integration: state.CRMConfigureDetails.idcrm_integration,
        idcrm_master: state.CRMIntegrationDetails.idcrm_master,
        crm_name: state.CRMIntegrationDetails.crm_name,
        isActive: state.CRMIntegrationDetails.isActive,
        configured: state.CRMIntegrationDetails.configured,
        integrationDetails: state.CRMConfigureDetails.integrationDetails
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setIdCRM_Integration: (idcrm_integration) => dispatch(actions.setIdCRM_Integration(idcrm_integration)) ,
        //dispatch({type: "SETIDCRM_INTEGRATION", payload: {idcrm_integration: idcrm_integration}}),
        setIntegrationDetails: (integrationDetails) =>dispatch(actions.setIntegrationDetails(integrationDetails)),
        setIDCRM_Master: (idcrm_master) => dispatch(actions.setIDCRM_Master(idcrm_master)),
        setCRM_Name: (crm_name) => dispatch(actions.setCRM_Name(crm_name))
        // setIsActive: (isActive) =>dispatch(actions.setCRMisActive(isActive)),
        // setConfigured: (configured) =>dispatch(actions.setCRMConfigured(configured))  
      };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConfigureCRMContainer);