import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./ActivitiesComponent.module.scss";
// import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import SalesActivities from "../../../components/SalesActivities/SalesActivities";
import Tasks from "../../Tasks/TasksContainer";
import moment from "moment";
import LargeScreenLoader from "../../../common/Loaders/PulseLoader/pulse-loader";
import IconButton from "@material-ui/core/IconButton";
import addIcon from "../../../../assets/add_icon.png";
import filterIcon from "../../../../assets/filter_icon.png";
import Dialog from "@material-ui/core/Dialog";
import AddActivitiesContainer from "../../AddActivities/AddActivitiesContainer";
import AddTasksContainer from "../../AddTasks/AddTasksContainer";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { Link } from "react-router-dom";
import asideLeftStyles from "../../aside-left/component/aside-left.module.scss";
import axiosInstance from "../../../common/api-config";
import { openSnackbar } from "../../../common/Snackbar/snackbar";
import MyDateRangePicker from "../../../components/DateRangePicker";

const stylesMaterialUI = (theme) => ({
  button: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    marginLeft: 0,
    marginRight: 0,
  },
});

class ActivitiesComponent extends Component {
  state = {
    date: [moment(), moment()],
    dateRange: {
      from_date: moment().startOf("month"),
      to_date: moment(),
    },
    addSalesActivities: false,
    addTasks: false,
    flag: "day",
    updateActivity: null,
    updateTask: null,
    isOpen: false,
  };

  componentDidMount() {
    this.getActivitties();
    this.getTasks();
  }

  getActivitties = (dateRange) => {
    console.log();
    const requestBodySalesActivitiesTasks = {
      arrayFilters: [
        {
          dateFrom: this.state.date[0].format("YYYY-MM-DD"),
          dateTo: this.state.date[1].format("YYYY-MM-DD"),
          is_deleted: 0,
        },
      ],
      selectFilters: [],
      sort: {
        field: "activity_time",
        sortOrder: "ASC",
      },
      paginate: {
        page: "0",
        limit: "1000",
      },
      type: "BOTH",
      //user_id: JSON.parse(localStorage.getItem("user")).user_id
    };

    this.props.getSalesActivititesTasks(requestBodySalesActivitiesTasks);
  };

  getTasks = () => {
    const requestBody = {
      arrayFilters: [
        {
          dateFrom: this.state.date[0].format("YYYY-MM-DD"),
          dateTo: this.state.date[1].format("YYYY-MM-DD"),
        },
      ],
      selectFilters: [],
      sort: {
        field: "activity_time",
        sortOrder: "ASC",
      },
      paginate: {
        page: "0",
        limit: "1000",
      },
      type: "TASK",
      //user_id: JSON.parse(localStorage.getItem("user")).user_id
    };

    this.props.getSalesTasks(requestBody);
  };

  handleOpenAddActivity = () => {
    this.setState({ addSalesActivities: true });
    this.addClasses();
  };

  handleCloseAddActivity = (value) => {
    if (value === "save_activity") {
      const requestBodySalesActivitiesTasks = {
        arrayFilters: [
          {
            dateFrom: this.state.date[0].format("YYYY-MM-DD"),
            dateTo: this.state.date[1].format("YYYY-MM-DD"),
          },
        ],
        selectFilters: [],
        sort: {
          field: "activity_time",
          sortOrder: "ASC",
        },
        paginate: {
          page: 0,
          limit: 1000,
        },
        type: "BOTH",
        //user_id: JSON.parse(localStorage.getItem("user")).user_id
      };
      this.props.getSalesActivititesTasks(requestBodySalesActivitiesTasks);
    }
    this.setState({
      addSalesActivities: false,
      updateActivity: null,
      updateTask: null,
    });
    this.removeClasses();
  };

  handleOpenAddTask = () => {
    this.setState({ addTasks: true });
    this.addClasses();
  };

  handleUpdateActivity = (activity) => {
    this.setState({ addSalesActivities: true, updateActivity: activity });
    this.addClasses();
  };

  handleUpdateTask = (task) => {
    this.setState({ addTasks: true, updateTask: task });
    this.addClasses();
  };

  handleDeleteTask = (id) => {
    const req = {
      id: id,
      is_deleted: 1,
      //user_id: JSON.parse(localStorage.getItem("user")).user_id
    };
    axiosInstance
      .put("/task/update", req)
      .then((response) => {
        if (response && response.data && response.data.statusCode === 200) {
          openSnackbar("Task deleted successfully.", "snack-success");
          this.getActivitties();
          this.getTasks();
        } else {
          openSnackbar("Task deleting failed.", "snack-error");
        }
      })
      .catch((error) => {
        openSnackbar("Task deleting failed.", "snack-error");
      });
  };

  handleDeleteActivity = (id) => {
    const req = {
      id: id,
      is_deleted: 1,
      //user_id: JSON.parse(localStorage.getItem("user")).user_id
    };
    axiosInstance
      .put("/salesActivity/update", req)
      .then((response) => {
        if (response && response.data && response.data.statusCode === 200) {
          openSnackbar("Activity deleted successfully.", "snack-success");
          this.getActivitties();
        } else {
          openSnackbar("Activity deleting failed.", "snack-error");
        }
      })
      .catch((error) => {
        openSnackbar("Activity deleting failed.", "snack-error");
      });
  };

  handleCloseAddTask = (value) => {
    if (value === "after_save_task") {
      const requestBodySalesActivitiesTasks = {
        arrayFilters: [
          {
            dateFrom: this.state.date[0].format("YYYY-MM-DD"),
            dateTo: this.state.date[1].format("YYYY-MM-DD"),
          },
        ],
        selectFilters: [],
        sort: {
          field: "activity_time",
          sortOrder: "ASC",
        },
        paginate: {
          page: 0,
          limit: 1000,
        },
        type: "BOTH",
        //user_id: JSON.parse(localStorage.getItem("user")).user_id
      };
      this.props.getSalesActivititesTasks(requestBodySalesActivitiesTasks);

      const requestBody = {
        arrayFilters: [
          {
            dateFrom: this.state.date[0].format("YYYY-MM-DD"),
            dateTo: this.state.date[1].format("YYYY-MM-DD"),
          },
        ],
        selectFilters: [],
        sort: {
          field: "activity_time",
          sortOrder: "ASC",
        },
        paginate: {
          page: 0,
          limit: 1000,
        },
        type: "TASK",
        //user_id: JSON.parse(localStorage.getItem("user")).user_id
      };
      this.props.getSalesTasks(requestBody);
    }

    this.setState({ addTasks: false, updateTask: null, updateActivity: null });
    this.removeClasses();
  };

  addClasses = () => {
    var headerElement = document.getElementsByClassName(asideLeftStyles.appBar);
    headerElement[0].classList.add(asideLeftStyles.modal_zindex);
    var leftNavElement = document.getElementsByClassName(asideLeftStyles.brand);
    leftNavElement[0].parentNode.classList.add(asideLeftStyles.modal_zindex);
  };

  removeClasses = () => {
    var headerElement = document.getElementsByClassName(asideLeftStyles.appBar);
    headerElement[0].classList.remove(asideLeftStyles.modal_zindex);
    var leftNavElement = document.getElementsByClassName(asideLeftStyles.brand);
    leftNavElement[0].parentNode.classList.remove(asideLeftStyles.modal_zindex);
  };

  toggleCalendar = () => {
    if (this.state.isOpen) {
      this.setState({
        flag: "calendar",
        isOpen: false,
      });
    } else {
      this.setState({
        flag: "calendar",
        isOpen: true,
      });
    }
  };

  onDateRangeSelected = (dateRange) => {
    this.setState(
      {
        date: [dateRange.from_date, dateRange.to_date],
      },
      () => {
        this.getActivitties();
        this.getTasks();
      }
    );
  };

  handleDayClicked = () => {
    if (this.state.flag !== "day") {
      this.setState({
        flag: "day",
        date: [moment(), moment()],
      });
    }
  };

  handleWeekClicked = () => {
    if (this.state.flag !== "week") {
      this.setState({
        flag: "week",
        date: [moment().startOf("week"), moment().endOf("week")],
      });
    }
  };

  handleMonthClicked = () => {
    if (this.state.flag !== "month") {
      this.setState({
        flag: "month",
        date: [moment().startOf("month"), moment().endOf("month")],
      });
    }
  };

  render() {
    console.log("this.state.dateRange", this.state.dateRange);
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className={styles.main_wrapper}>
          <div className="row">
            <div className="col-12">
              <div className={"breadcrumbs row " + styles.centerAlign}>
                <div className="col-md-4 col-12 activities_headingcontainer">
                  <h2 className="heading">ACTIVITIES</h2>
                  <div className="breadcrumbs_container">
                    <Breadcrumbs separator="›" arial-label="Breadcrumb">
                      <Link to="/sales-news">Home</Link>
                      <span>Activities</span>
                    </Breadcrumbs>
                  </div>
                </div>
                <div className={`col-md-8 col-12 ${styles.calendar_container}`}>
                  <div className={styles.datePicker}>
                    <MyDateRangePicker
                      maxWidth={320}
                      className="form-control"
                      onDateChange={this.onDateRangeSelected}
                      dateRange={{
                        from_date: this.state.date[0],
                        to_date: this.state.date[1],
                      }}
                    />
                  </div>
                  {/* <DateRangePicker
                    onChange={this.onDateRangeSelected}
                    value={[
                      new Date(
                        this.state.date[0].year(),
                        this.state.date[0].month(),
                        this.state.date[0].date()
                      ),
                      new Date(
                        this.state.date[1].year(),
                        this.state.date[1].month(),
                        this.state.date[1].date()
                      )
                    ]}
                    clearIcon={null}
                    isOpen={this.state.isOpen}
                    calendarIcon={null}
                    format="y-MM-d"
                    onCalendarClose={() => this.toggleCalendar()}
                  /> 
                   <button
                    className={
                      this.state.flag === "calendar"
                        ? `${styles.buttons} ${
                            styles.selected
                          } react-daterange-picker__calendar-button react-daterange-picker__button`
                        : `${
                            styles.buttons
                          } react-daterange-picker__calendar-button react-daterange-picker__button`
                    }
                    onClick={() => this.toggleCalendar()}
                  >
                    <i className={`${styles.date_picker_icon}`} />
                  </button> */}
                  <button
                    className={
                      this.state.flag === "day"
                        ? `${styles.buttons} ${styles.selected}`
                        : `${styles.buttons}`
                    }
                    onClick={this.handleDayClicked}
                  >
                    Today
                  </button>
                  <button
                    className={
                      this.state.flag === "week"
                        ? `${styles.buttons} ${styles.selected}`
                        : `${styles.buttons}`
                    }
                    onClick={this.handleWeekClicked}
                  >
                    Week
                  </button>
                  <button
                    className={
                      this.state.flag === "month"
                        ? `${styles.buttons} ${styles.selected}`
                        : `${styles.buttons}`
                    }
                    onClick={this.handleMonthClicked}
                  >
                    Month
                  </button>
                </div>
              </div>
              <div className="row container-body">
                {/* {this.props.loadingSalesActivitiesTasks &&
                this.props.loadingTasks ? (
                  <LargeScreenLoader />
                ) : (
                  ""
                )} */}
                <div className={`col-xs-12 col-md-12 col-lg-6 col-12`}>
                  <div className={styles.activities_container}>
                    <div className={styles.activities_container_header}>
                      <h1 className={styles.activities_container_heading}>
                        Sales activities
                      </h1>
                      <div className={styles.activities_container_actions}>
                        {/* <IconButton className={classes.button}>
                            <img src={filterIcon} />
                          </IconButton> */}
                        <IconButton
                          className={classes.button}
                          onClick={this.handleOpenAddActivity}
                        >
                          <img src={addIcon} />
                        </IconButton>
                      </div>
                    </div>
                    <SalesActivities
                      startDate={this.state.date[0].format("YYYY-MM-DD")}
                      endDate={this.state.date[1].format("YYYY-MM-DD")}
                      update={this.props.getSalesActivititesTasks}
                      loadingSalesActivitiesTasks={
                        this.props.loadingSalesActivitiesTasks
                      }
                      salesActivitesTasks={this.props.salesActivitesTasks}
                      errorLoadingSalesActivitesTasks={
                        this.props.errorLoadingSalesActivitesTasks
                      }
                      from="activities"
                      handleUpdateActivity={this.handleUpdateActivity}
                      handleDeleteActivity={this.handleDeleteActivity}
                      handleUpdateTask={this.handleUpdateTask}
                      handleDeleteTask={this.handleDeleteTask}
                      editDelete={true}
                      leadTitle={true}
                      today={this.state.flag === "day"}
                    />
                    <div className={styles.activities_container_footer} />
                  </div>
                </div>
                <div className={`col-xs-12 col-md-12 col-lg-6 col-12`}>
                  <div className={styles.activities_container}>
                    <div className={styles.activities_container_header}>
                      <h1 className={styles.activities_container_heading}>
                        Tasks
                      </h1>
                      <div className={styles.activities_container_actions}>
                        {/* <IconButton className={classes.button}>
                            <img src={filterIcon} />
                          </IconButton> */}
                        <IconButton
                          className={classes.button}
                          onClick={this.handleOpenAddTask}
                        >
                          <img src={addIcon} />
                        </IconButton>
                        <Dialog
                          className="width"
                          aria-labelledby="customized-add-tasks"
                          open={
                            this.state.addTasks ? this.state.addTasks : false
                          }
                          onClose={this.handleCloseAddTask}
                          maxWidth={"lg"}
                        >
                          <AddTasksContainer
                            close={this.handleCloseAddTask}
                            //leadList={this.props.leadList}
                            updateTask={this.state.updateTask}
                          />
                        </Dialog>
                      </div>
                    </div>
                    <Tasks
                      startDate={this.state.date[0].format("YYYY-MM-DD")}
                      endDate={this.state.date[1].format("YYYY-MM-DD")}
                      handleUpdateTask={this.handleUpdateTask}
                      handleDeleteTask={this.handleDeleteTask}
                      today={this.state.flag === "day"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Dialog
            className="width"
            aria-labelledby="customized-add-activities"
            open={
              this.state.addSalesActivities
                ? this.state.addSalesActivities
                : false
            }
            onClose={this.handleCloseAddActivity}
            maxWidth={"lg"}
          >
            <AddActivitiesContainer
              close={this.handleCloseAddActivity}
              updateActivity={this.state.updateActivity}
              lead_id={
                this.state.updateActivity === null
                  ? null
                  : this.state.updateActivity.lead_id
              }
            />
          </Dialog>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(stylesMaterialUI)(ActivitiesComponent);
