import React, { Component } from "react";
import styles from "./UserAssignmentCRMComponent.module.scss";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import "rc-time-picker/assets/index.css";
import CircularProgress from "../../../common/Loaders/CircularLoader/circular-loader";

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing.unit
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={`${classes.root} ${styles.dialogTitle}`}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(() => ({
  root: {
    // padding: "30px"
    padding: "24px"
  }
}))(props => {
  const { children, classes } = props;
  return (
    <MuiDialogContent
      className={`${classes.root} ${styles.userassignment_mapping_dialog_content}`}>
      {children}
    </MuiDialogContent>
  );
});

const DialogActions = withStyles(() => ({
  root: {
    margin: 0,
    padding: "5px 0 0 0"
  }
}))(MuiDialogActions);

const stylesMaterialUI = () => ({});

class UserAssignmentCRMComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leadType: this.props.fieldMapping,
      CRMUsers: this.props.CRMUsers,
      lead_types: this.props.lead_types,
      isSaveClicked: false
    };
  }

  componentDidMount() {
    //console.log("props", this.props);
    this.setState({ props: this.props });
    /*  this.state = {
       lead_type: this.props.fieldMapping,
       CRMUsers: this.props.CRMUsers,
       lead_types: this.props.lead_types
     }; */
  }
  /* 
    handleSave() {
      console.log("In dialog handleSave");
      this.props.save(this.state.leadType);
    } */

  handleCloseDoneNReady = () => {
    //console.log("Close clicked");
  };

  onChangeUser = (e, whichleadType) => {
    const value = e.target.value;
    //console.log("onChangeUser", value);
    if (value !== undefined) {
      let tempLeadType = { ...this.state.leadType };
      tempLeadType.user = value;
      this.setState({ leadType: tempLeadType });
    }
    //"leadx_to_sales"
  }

  handleSave = () => {
    this.setState({ isSaveClicked: true }, () => {
      //console.log("Inside handleSave");
      this.state.props.save(this.state.leadType);
    });
  }

  render() {
    return (
      <div className={styles.userassignment_mapping + " overflow_x_hidden"}>
        <DialogTitle id="customized-dialog-title" onClose={this.props.close}>
          <div className={styles.userassignment_mapping_dialog_title}>
            Edit Information
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="col-12">
            <div className="bg-white ml-4 mr-4">
              {/* 
              <div className="mt-5 mb-4"> */}
              <div className="row">
                <div className="col-sm-1 mb-3" />
                <div className="col-sm-10">
                  <table className="table table-striped- table-bordered table-hover table-checkable">
                    <thead>
                      <tr style={{ backgroundColor: '#f2f2f2', textAlign: 'center' }}>
                        <th colSpan="4">Leadx To SalesForce</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {this.state.leadType.lead_type}
                        </td>
                        <td>
                          <select className={styles.profileContainer_inputStyle__1Dduu} style={{ backgroundColor: '#fff' }} onChange={(e) => this.onChangeUser(e, "leadx_to_sales")}>
                            <option value={''}>--- Choose User ---</option>
                            {this.state.CRMUsers.map(crmuser =>
                              <option key={crmuser.Username} selected={this.state.leadType.user == crmuser.Username} value={crmuser.Username}>{crmuser.FirstName + " " + crmuser.LastName}</option>
                            )}
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-sm-1 mb-3" />
              </div>
              {/*      </div> */}
            </div>
          </div>
          <DialogActions>
            {this.state.submitButton ? (
              <div className={styles.submit_loader}>
                <CircularProgress
                  style={{
                    color: "#19D98C"
                  }}
                />
              </div>
            ) : (<>
              <hr className="jss201" />
              <div className="mt-3 mb-5">
                <div style={{ textAlign: 'right' }}>
                  <Button
                    onClick={this.props.close}
                    className={styles.saveButton}
                    color="primary"
                  >
                    Close
                  </Button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                    /*  onClick={this.state.isSaveClicked ? this.props.save(this.state.leadType): null} */
                    onClick={this.handleSave}
                    className={styles.saveButton}
                    color="primary"
                  >
                    Save
                    </Button>
                </div>
              </div>
            </>
              )}
          </DialogActions>
        </DialogContent>
      </div>
    );
  }
}
export default withStyles(stylesMaterialUI)(UserAssignmentCRMComponent);
