import React, { Component } from "react";
import { connect } from "react-redux";
import ConfigureDataObjsBrregComponent from "./component/ConfigureDataObjsBrregComponent";

class ConfigureDataObjsBrregContainer extends Component {
    render() {
        const configureDataObjsBrregComponentProps = {...this.props };
        return <ConfigureDataObjsBrregComponent {...configureDataObjsBrregComponentProps }
        />;
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        idcrm_integration: state.CRMIntegrationDetails.idcrm_integration,
        idcrm_master: state.CRMIntegrationDetails.idcrm_master,
        crm_name: state.CRMIntegrationDetails.crm_name
    };
};

/* const mapDispatchToProps = dispatch => {
    return {
        getSalesActivititesTasks: requestBody =>
            dispatch(actions.getSalesActivititesTasks(requestBody)),
        getSalesTasks: requestBody =>
            dispatch(actionsTasks.getSalesTasks(requestBody))
    };
};
 */
export default connect(
    mapStateToProps/* ,
    mapDispatchToProps */
)(ConfigureDataObjsBrregContainer);