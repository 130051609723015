import React, { useState, useEffect } from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import styles from "./organization.module.scss";
import apis from './apis';
import LargeScreenLoader from "../../common/Loaders/PulseLoader/pulse-loader";
import styled from 'styled-components';
import SnackbarComponent from "../../common/Snackbar/snackbar";
import IconButton from '@material-ui/core/IconButton';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import copy from 'copy-to-clipboard';
import { showSnackbarMessage } from "../../common/common-functions";
import InviteUser from "../../common/InviteUser";
import Button from '@material-ui/core/Button';


const CustomCard = styled.div`
  background-color: #FFFFFF;
  padding: 4.5em;
  padding-top: 1.875em;
  box-shadow: 0 0.25em 0.5em 0 rgba(0,0,0,0.2);
`;

export default function OrganizationProfile(props) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [organizationName, setOrganizationName] = useState('');

  useEffect(() => {
    fetchOrganizationDetails((accountPreference) => {
    });
  }, []);


  const fetchOrganizationDetails = async (cb) => {
    const result = await apis.getOrganizationDetails(props.match.params.id);
    setData(result);
    const orgName = await apis.getMyAccount();
    setOrganizationName(orgName);
    setIsLoading(false);

    if (cb) cb(result.profile.AccountPreference);
  };

  const handleCopyOnClick = () => {
    copy(data.registered_url);
    showSnackbarMessage('URL copied successfully.', 'snack-success');
  }

  const handleOnUpdate = () => {
    props.history.push(`/administration/child_organization/edit/${props.match.params.id}`);
  }

  return (
    <>
      <SnackbarComponent />
      {isLoading && <LargeScreenLoader />}
      {!isLoading && <div className="main-wrapper">
        <div className="breadcrumbs">
          <div className="row justify-content-between">
            <div className="col-md-4">
              <h3 className={styles.title_Administration}>
                Child Organization Profile
              </h3>
              <div className="breadcrumbs_container">
                <Breadcrumbs separator="›" arial-label="Breadcrumb">
                  <Link to="/sales-news">Home</Link>
                  <Link to="/administration">Administration</Link>
                  <span>Child Organization </span>
                </Breadcrumbs>
              </div>
            </div>
            <div className="col-md text-right">
              <label className="font-weight-bold pointer" onClick={() => props.history.push('/administration/organization_profile')}>
                {organizationName}
              </label>
              <span className="mx-2"></span>
                |
              <span className="mx-2"></span>
              <InviteUser defaultSelectedOrg={props.match.params.id} />
              <span className="mx-2"></span>
              <Button onClick={handleOnUpdate} variant="contained" size="medium" style={{ 'backgroundColor': '#232f44', 'color': 'white','textTransform':'capitalize' }}>
                 Update
              </Button>
            </div>
          </div>
        </div>
        <hr />
        <div className="row mb-5">
          <div className="col-xs-12 col-sm-12 col-lg-4">
            <img className={styles.org_profile_logo} src={data.profile.logo} />
            <div className={styles.title_text}>
              Child Account / Organization Name
          </div>
            <div>{data.profile.name}</div>
            <div className={styles.title_text}>
              Customer ID
          </div>
            <div>{data.profile.client_id}</div>
            <div className={styles.title_text}>
              Sub domain for web users
          </div>
            <div>{data.profile.dns}</div>
            <div className={styles.title_text}>
              Address
            </div>
            <div>{data.profile.address_line_1 + ' ' + data.profile.address_line_2}</div>
            {data.registered_url &&
              <><div className={styles.title_text}>
                Registered URL
            </div>
                <div className="row">
                  <div className={"col-md-6 " + styles.url_container}>
                    {data.registered_url}
                  </div>
                  <div className="col-md-1">
                    <IconButton aria-label="copy" onClick={handleCopyOnClick}>
                      <FileCopyOutlinedIcon />
                    </IconButton>
                  </div>
                </div></>}
          </div>

          <div className="col-xs-12 col-sm-12 col-lg-8">
            <div className={styles.title_right_text}>
              Primary Contact
          </div>
            <div className={styles.card_view}>
              <CustomCard>
                <div className="row">
                  <div className="col-md-4">
                    <div className={styles.title_text}>Email Id</div>
                    {<div>{data.primary_contact && data.primary_contact.email}</div>}
                  </div>
                  <div className="col-md-4">
                    <div className={styles.title_text}>Phone No.</div>
                    {<div>+{data.primary_contact && data.primary_contact.countryCode.dialing_code} {data.primary_contact && data.primary_contact.contact}</div>}
                  </div>
                  <div className="col-md-4">
                    <div className={styles.title_text}>Password</div>
                    <div>********</div>
                  </div>
                </div>
                <div style={{ marginTop: '1.25em' }}>
                  *Primary Contact will act as an Admin of a system
                </div>
              </CustomCard>
            </div>

            <div className={styles.title_right_text}>
            Child Account / Organization Name
          </div>
            <div className={styles.card_view}>
              <CustomCard>
                <div className="row">
                  <div className="col-md-4">
                    <div className={styles.title_text}>Account Group</div>
                    <div>{data.profile.AccountGroup && data.profile.AccountGroup.name}</div>
                  </div>
                  <div className="col-md-4">
                    <div className={styles.title_text}>Base Currency</div>
                    <div>{data.profile.AccountPreference.currency.short_name}</div>
                  </div>
                  <div className="col-md-4">
                    <div className={styles.title_text}>Other Currencies</div>
                    {data.currencies.rows.map((c) => c.short_name).join(', ')}
                  </div>
                </div>
              </CustomCard>
            </div>

            <div className={styles.title_right_text}>
            Child Account / Organization Settings
            </div>
            <div className={styles.card_view}>
              <CustomCard>
                <div className="row">
                  <div className="col-md-4">
                    <div className={styles.title_text}>Date Format</div>
                    <div>{data.profile.AccountPreference.dateformatid.format}</div>
                  </div>
                  <div className="col-md-4">
                    <div className={styles.title_text}>Default Language</div>
                    <div>{data.profile.AccountPreference.languageid.language}</div>
                  </div>
                  <div className="col-md-4">
                    <div className={styles.title_text}>Timezone</div>
                    <div>{data.profile.AccountPreference.timezoneid.timezone}</div>
                  </div>
                </div>
              </CustomCard>
            </div>
          </div>
        </div>
      </div>
      }
    </>
  )
}