import axios from "../../common/api-config";

export default {
  getUserData(page, limit, sortOrder, field, searchTerm) {
    const url = '/users/getAllUserList';
    const body = {
      paginate: {page, limit},
      sort: {sortOrder: sortOrder.toUpperCase(), field},
      searchTerm,
    };
    return axios.post(url, body).then(response => {
      return response.data;
    });
  },
  getPendingUserNotification() {
    const url = '/users/pendingUserCount';
    return axios.post(url, {}).then(response => {
      return response.data.data.data;
    });
  },
  getMyAccount() {
    const url = '/users/getMyAccount';
    return axios.post(url, {}).then(response => {
      return response.data.data.data.name;
    });
  }
}