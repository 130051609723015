import React, { Component } from "react";
import { connect } from "react-redux";
import IntegrationsComponent from "./component/IntegrationsComponent";
import * as actions from "./redux/actions";

class IntegrationsContainer extends Component {
  render() {
    const integrationsComponentProps = { ...this.props };
    //console.log("in container", integrationsComponentProps);
    return <IntegrationsComponent {...integrationsComponentProps} />;
  }
}

const mapStateToProps = state => {
 //console.log("state in mapstatetoprops", state);
  return {
    idcrm_master: state.CRMIntegrationDetails.idcrm_master,
    isActive: state.CRMIntegrationDetails.isActive,
    crm_name: state.CRMIntegrationDetails.crm_name,
    configured: state.CRMIntegrationDetails.configured,
    idcrm_integration: state.CRMConfigureDetails.idcrm_integration,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setIdcrm_master: (idcrm_master) =>
      //dispatch({type: "SET_IDCRM_MASTER", payload: {idcrm_master: idcrm_master}}),
      dispatch(actions.setIdCRM_Master(idcrm_master)),
    setCRM_name: (crm_name) =>
      dispatch(actions.setCRM_Name(crm_name)),
    setIsActive: (isActive) =>
      dispatch(actions.setCRMisActive(isActive)),
    setConfigured: (configured) =>
      dispatch(actions.setCRMConfigured(configured)),
    setIdCRM_Integration: (idcrm_integration) =>
      //dispatch({type: "SETIDCRM_INTEGRATION", payload: {idcrm_integration: idcrm_integration}}),
      dispatch(actions.setIdCRM_Integration(idcrm_integration))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntegrationsContainer);
