import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import SalesCommunity from './component/sales-community';
import leadDetailActions from '../LeadDetailPopup/redux/actions';
import OopsError from "../../components/ErrorMessages/OopsError/OopsError";
import { getStorageItem } from "../../common/common-functions";

class SalesCommunityContainer extends Component {
    render() {
        const salesCommunityProps = { ...this.props };
        const currentUser = JSON.parse(getStorageItem('user'));
        return (
            <>
                {
                    this.props.errorLoadingCommunityLeads || this.props.errorLoadingCommunityRevenue ?
                        <div>
                            <OopsError />
                        </div>
                        :
                        <SalesCommunity {...salesCommunityProps} currentUser={currentUser} />
                }
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        communityLeads: state.salesCommunity.communityLeads,
        communityLeadsCount: state.salesCommunity.communityLeadsCount,
        revenueData: state.salesCommunity.revenueData,
        errorLoadingCommunityLeads: state.salesCommunity.errorLoadingCommunityLeads,
        errorLoadingCommunityRevenue: state.salesCommunity.errorLoadingCommunityRevenue,
        statusLogs: state.leadDetailReducer.statusLogs,
        organizationList: state.asideLeft.organizationList,
        selectedSalesCommunityOrganization: state.salesCommunity.selectedSalesCommunityOrganization
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCommunityLeadList: requestBody => dispatch(actions.getCommunityLeadList(requestBody)),
        getRevenue: requestBody => dispatch(actions.getRevenue(requestBody)),
        getStatusLogs: requestBody => dispatch(leadDetailActions.getStatusLogs(requestBody)),
        setCommunityOrganization: id => dispatch(actions.setCommunityOrganization(id)),
        resetDocuments: () => dispatch(leadDetailActions.resetDocuments())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesCommunityContainer);