import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  FormGroup,
  Dialog,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import leadXLogo from "../../../../assets/images/login/LeadX_logo.png";
import styles from "./RegisterComponent.module.scss";
import LargeScreenLoader from "../../../common/Loaders/PulseLoader/pulse-loader";
import apis from "./apis";
import SnackbarComponent from "../../../common/Snackbar/snackbar";
import {
  handleTechnicalError,
  showSnackbarMessage,
} from "../../../common/common-functions";
import loginAPI from "../../Login/component/apis";
import { commonValidation_regex } from "../../../common/common-types.js";
import MySelect from "../../../components/SingleSelect";

import PasswordLinkComponent from "./PasswordLinkComponent";
import RequestAcceptedComponent from "./RequestAcceptedComponent";

let registeredSuccessfully = false;

function RegisterComponent(props) {
  const requiredFields = [
    "initials",
    "first_name",
    "last_name",
    "email",
    "contact",
    "gender",
    "country_code",
  ];
  const [registerData, setRegisterData] = useState({
    initials: "",
    first_name: "",
    last_name: "",
    middle_name: "",
    email: "",
    contact: "",
    gender: "",
    country_code: "",
    key: props.match.params.key,
  });
  const [touched, setTouched] = useState({
    first_name: false,
    last_name: false,
    middle_name: false,
    email: false,
    contact: false,
    gender: false,
    country_code: false,
    initials: false,
  });
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    middle_name: "",
    email: "",
    contact: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [passwordLink, setPasswordLink] = useState(false);
  const [requestedAccepted, setRequestedAccepted] = useState(false);
  const [serverMessage, setServerMessage] = useState("Testing");
  const [countryCodes, setCountryCodes] = useState([]);
  const [org, setOrg] = useState({});
  const [orgOptions, setOrgOptions] = useState([]);

  const { classes } = props;

  useEffect(() => {
    fetchCountryCodes();
    checkIsRegister();
    fetchAccounts();
  }, []);

  useEffect(() => {
    setRegisterData({...registerData, key: org.value});
  }, [org]);

  const fetchAccounts = async () => {
    const orgs = await apis.getAllRegisterAccount();
    console.log(orgs, "getAllRegisterAccount");
    if (orgs.length > 0) {
      setOrgOptions(orgs.map(o => ({ value: o.key, label: o.name })));
      setOrg(getSelectedAccount(orgs));
    }
    
  }

  const getSelectedAccount = (orgs) => {
    const parentAccount = orgs.find(org => {
      if (props.match.params.key) {
        return org.key === props.match.params.key;
      } else {
        return org.parent_account_id === 0 
      }
    });
    return { value: parentAccount.key, label: parentAccount.name };
  }

  console.log(props.match.params.key, "Keys");

  const checkIsRegister = async () => {
    const result = await loginAPI.isRegistered();
    if (!result.data.self_user_reg_config_enabled) {
      window.location.replace("/login");
    }
  };

  const fetchCountryCodes = async () => {
    try {
      const countryCodes = await apis.getCountryCodes();
      setCountryCodes(countryCodes.data);
    } catch (error) {
      handleTechnicalError();
    }
    setIsLoading(false);
  };

  const handleOnChange = (field) => (event) => {
    const newData = {
      ...registerData,
      [field]: event.target.value,
    };
    setRegisterData(newData);
    setTouched({
      ...touched,
      [field]: true,
    });
    setErrors({
      ...errors,
      [field]: getErrors(field, newData),
    });
  };

  const checkValidity = () => {
    const keys = Object.keys(registerData);
    const requiredFieldsTouched = requiredFields.every((k) => touched[k]);
    const noFieldHasError = keys.every((k) => !errors[k]);

    return requiredFieldsTouched && noFieldHasError;
  };

  const trimFields = (data) => {
    const keys = Object.keys(data);
    const newObj = {};
    keys.forEach((k) => {
      newObj[k] = data[k] && data[k].trim();
    });
    return newObj;
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const result = await apis.doRegistration(trimFields(registerData));
      const message = result.data.message;
      setServerMessage(message);
      if (result.data.isSuccess && result.data.isPasswordReset) {
        setPasswordLink(true);
        registeredSuccessfully = result.data.isSuccess;
      } else if (result.data.isSuccess && !result.data.isPasswordReset) {
        setRequestedAccepted(true);
      }
      if (!result.data.isSuccess && result.data.isPasswordReset === null) {
        setDialogOpen(true);
      }

      if (message && !result.data.isSuccess) {
        showSnackbarMessage(message, "snack-error");
      }
    } catch (error) {
      handleTechnicalError();
    }

    setIsLoading(false);
  };

  const getErrors = (field, registerData) => {
    let error = "";
    const { first_name, last_name, middle_name, email, contact } = registerData;

    if (field === "first_name") {
      if (!first_name.trim()) {
        error = "This field is required";
      } else if (!commonValidation_regex.test(first_name.trim())) {
        error = "Please enter valid first name";
      }
    } else if (field === "last_name") {
      if (!last_name.trim()) {
        error = "This field is required";
      } else if (!commonValidation_regex.test(last_name.trim())) {
        error = "Please enter valid last name";
      }
    } else if (field === "middle_name") {
      if (middle_name && !commonValidation_regex.test(middle_name.trim())) {
        error = "Please enter valid middle name";
      }
    } else if (field === "email") {
      const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!email.trim()) {
        error = "This field is required";
      } else if (!emailRegex.test(email.trim())) {
        error = "Please enter valid email";
      }
    } else if (field === "contact") {
      const phoneRegex = /^[0-9]{8,}$/;
      if (!contact.trim()) {
        error = "This field is required";
      } else if (!phoneRegex.test(contact.trim())) {
        error = "Phone number should be minimum 8 digit long.";
      }
    }
    return error;
  };

  const renderError = (field) => {
    return (
      touched[field] &&
      errors[field] && <span className={styles.errors}>{errors[field]}</span>
    );
  };

  const handleOnCloseClick = () => {
    setDialogOpen(false);
    if (registeredSuccessfully) {
      window.location.replace("/login");
    }
  };

  return (
    <>
      <SnackbarComponent />
      {isLoading ? <LargeScreenLoader /> : ""}
      <div className={styles.register}>
        <div className={styles.logo_container}>
          <img className={styles.logo} src={leadXLogo} alt="Lead X logo" />
        </div>
        {!passwordLink && !requestedAccepted ? (
          <div>
            <div className={styles.welcome}>
              <h2 className={styles.heading}>Register User</h2>
              <h5>Please provide the information below</h5>
            </div>
            <form className={styles.register_form} onSubmit={handleOnSubmit}>
              <FormGroup>
                <div className={styles.input_container}>
                  <div className={"row " + styles.prefix}>
                    <div className="col-4 select_box">
                      <select
                        value={registerData.initials}
                        className={styles.select_box}
                        value={registerData.initials}
                        autoFocus={true}
                        onChange={handleOnChange("initials")}
                      >
                        <option value="" disabled selected="selected">
                          Prefix
                        </option>
                        <option value="Mr">Mr.</option>
                        <option value="Mrs">Mrs.</option>
                        <option value="Miss">Miss.</option>
                      </select>
                    </div>
                    <div className="col-8 padding_left">
                      <Input
                        inputProps={{
                          maxLength: 50,
                        }}
                        disableUnderline={true}
                        className={styles.phone_input}
                        type="text"
                        name="first_name"
                        placeholder="Enter First Name"
                        value={registerData.first_name}
                        onChange={handleOnChange("first_name")}
                      />
                      {renderError("first_name")}
                    </div>
                  </div>
                </div>
                <div className={styles.input_container}>
                  <Input
                    inputProps={{
                      maxLength: 50,
                    }}
                    disableUnderline={true}
                    className={classes.input + " " + styles.input}
                    type="text"
                    value={registerData.middle_name}
                    name="middle_name"
                    placeholder="Enter Middle Name"
                    onChange={handleOnChange("middle_name")}
                  />
                  {renderError("middle_name")}
                </div>
                <div className={styles.input_container}>
                  <Input
                    inputProps={{
                      maxLength: 50,
                    }}
                    disableUnderline={true}
                    className={classes.input + " " + styles.input}
                    type="text"
                    value={registerData.last_name}
                    name="last_name"
                    placeholder="Enter Last Name"
                    onChange={handleOnChange("last_name")}
                  />
                  {renderError("last_name")}
                </div>
                <div
                  className={styles.input_container}
                  style={{ textAlign: "left" }}
                >
                  <div className={"row " + styles.radioContainer}>
                    <div className="col-5">
                      <span className={styles.gender_label}>Select Gender</span>
                    </div>
                    <div className="col-7 padding_left">
                      <span className={styles.radio_container}>
                        <input
                          className={styles.radio_button}
                          checked={registerData.gender === "male"}
                          onChange={handleOnChange("gender")}
                          type="radio"
                          id="male"
                          name="gender"
                          value="male"
                        />
                        <label className={styles.radioControlLabel} for="male">
                          Male
                        </label>
                      </span>
                      <span className={styles.radio_container}>
                        <input
                          className={styles.radio_button}
                          checked={registerData.gender === "female"}
                          onChange={handleOnChange("gender")}
                          type="radio"
                          id="female"
                          name="gender"
                          value="female"
                        />
                        <label
                          className={styles.radioControlLabel}
                          for="female"
                        >
                          Female
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles.input_container}>
                  <Input
                    inputProps={{
                      maxLength: 50,
                    }}
                    disableUnderline={true}
                    className={styles.input}
                    type="text"
                    name="email"
                    placeholder="Enter Email"
                    onChange={handleOnChange("email")}
                  />
                  {renderError("email")}
                </div>
                <div className={styles.input_container}>
                  <div className={"row"}>
                    <div className="col-4  select_box ">
                      <select
                        className={styles.select_box}
                        value={registerData.country_code}
                        onChange={handleOnChange("country_code")}
                      >
                        <option value="" disabled selected="selected">
                          Code
                        </option>
                        {countryCodes.map((country) => {
                          return (
                            <option value={country.id}>
                              {country.iso_code} +{country.dialing_code}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-8 padding_left">
                      <Input
                        inputProps={{
                          maxLength: 50,
                        }}
                        disableUnderline={true}
                        className={styles.phone_input}
                        type="text"
                        name="contact"
                        placeholder="Enter Phone No."
                        value={registerData.contact}
                        onChange={handleOnChange("contact")}
                      />
                      {renderError("contact")}
                    </div>
                  </div>
                </div>
                {orgOptions.length > 0 && <div className={styles.input_container}>
                  <MySelect
                    dropDownContentStyle={{marginTop: -6}}
                    textStyle={{color: 'black', textAlign: 'left', marginTop: 4}}
                    fullWidth
                    labelStyle={{color: 'black', textAlign: 'left', paddinLeft: 12, paddingBottom: 8}}
                    headingStyle={{height: 44, borderRadius: 8}}
                    options={orgOptions}
                    selected={org}
                    disabled={!!props.match.params.key}
                    onSelect={(org) => {setOrg(org)}}
                  />
                </div>}
                <div className={styles.register_button_container}>
                  <Button
                    disabled={!checkValidity()}
                    className={styles.button}
                    type="submit"
                    onSubmit={handleOnSubmit}
                  >
                    Register
                  </Button>
                </div>
              </FormGroup>
            </form>
            <Dialog open={dialogOpen}>
              <DialogContent>
                <div
                  style={{
                    textAlign: "right",
                    marginTop: -25,
                    marginRight: -15,
                  }}
                >
                  <IconButton aria-label="close" onClick={handleOnCloseClick}>
                    <span style={{ fontWeight: "bold" }}>X</span>
                  </IconButton>
                </div>
                <div style={{ width: 450, textAlign: "center" }}>
                  {serverMessage}
                </div>
              </DialogContent>
            </Dialog>
          </div>
        ) : passwordLink && !requestedAccepted ? (
          <PasswordLinkComponent message={serverMessage} />
        ) : (
          !passwordLink &&
          requestedAccepted && (
            <RequestAcceptedComponent message={serverMessage} />
          )
        )}
      </div>
    </>
  );
}

export default withStyles(styles)(RegisterComponent);
