import axios from "../../common/api-config";

async function getLicenses() {
  const url = `/master/getLicenseType`;
  const result = await axios.post(url, {});
  return result.data.data.rows;
}

export default {
  async getLicenseTypes() {
    const result = await getLicenses();
    return result;
  },
};
