const initialState = {
    customerSubscriptionData: null,
    customerSubscriptionAdOnData: null,
    allRemainingSubscriptionCount:null,
    acessUrlData:null,
    organizationListData: null,
    licenceCount: null,
    errorMessage: "",
    orgSelected: null
};

const reducer = (state = initialState, action) => { console.log("subred",action)
    switch (action.type) {
        case "SUBSCRIPTION_DATA":
            return {
                ...state,
                customerSubscriptionData: action.data.customerSubscriptionData,
                allRemainingSubscriptionCount:action.data.allPlansData,
                customerSubscriptionAdOnData:action.data.addOnSubscriptionData,
                acessUrlData:action.data.acessUrlData,
                allPlansData:action.data.allPlansData,
                orgSelected: action.data.orgSelected,
                errorMessage: "",
                manage_link: action.data.manage_link
            };
        case "SUBSCRIPTION_FAILED":
            return {
                ...state,
                errorMessage: action.errorMessage
            };
        case "ALL_CUSTOMER_ADDON_SUBSCRIPTION":
            return {
                ...state,
                customerSubscriptionAdOnData: action.data,
                errorMessage: ""
            };
        case "ALL_CUSTOMER_ADDON_SUBSCRIPTION_FAILED":
            return {
                ...state,
                errorMessage: action.errorMessage
            };
        case "REMAINING_SUBSCRIPTION_COUNT":
            return {
                ...state,
                allRemainingSubscriptionCount: action.data,
                errorMessage: ""
            };
        case "REMAINING_SUBSCRIPTION_COUNT_FAILED":
            return {
                ...state,
                errorMessage: action.errorMessage
            };
        case "ORGANIZATION_LIST_SUCCESS":
            return {
                ...state,
                organizationListData: action.data,
                errorMessage: ""
            };
        case "ORGANIZATION_LIST_ERROR":
            return {
                ...state,
                errorMessage: action.errorMessage
            };
        case "LICENCECOUNT_DATA":
            return {
                ...state,
                licenceCount: action.data,
                errorMessage: ""
            };
        case "LICENCECOUNT_FAILED":
            return {
                ...state,
                errorMessage: action.errorMessage
            };
        default:
            return state;
    }
};

export default reducer;
