import axios from "../../../common/api-config";
import {
  setStorageItem,
  getStorageItem
} from "../../../common/common-functions";

export const getAvgVelocity = requestBody => {
  return dispatch => {
    axios.post("/pluseLite/getAvgVelocity", requestBody)
      .then(response => {
        // console.log('+++++++++++++  Average velocity +++++++++++++', response.data.data.data[0].AvgVelocity)
        if (response && response.data && response.data.statusCode == 200) {
          dispatch({
            type: 'AVERAGE_VELOCITY_SUCCESS',
            data: response.data.data.data[0].AvgVelocity
          });
        } else {
          dispatch({
            type: 'AVERAGE_VELOCITY_ERROR',
            data: response.data.errorMessage
          });
        }
      })
      .catch(error => {
        dispatch({
          type: 'AVERAGE_VELOCITY_ERROR',
          data: error.errorMessage
        });
      });
  };
};

export const getLeadsByMonth = requestBody => {
  return dispatch => {
    axios.post("/pluseLite/getLeadsByMonth", requestBody)
      .then(response => {
        // console.log("+++++++++++ LeadsByMonth +++++++++++++++++", response)
        if (response && response.data && response.data.statusCode == 200) {
          dispatch({
            type: 'LEADBYMONTH_SUCCESS',
            data: response.data.data.data
          });
        } else {
          dispatch({
            type: 'LEADBYMONTH_ERROR',
            data: response.data.errorMessage
          });
        }
      })
      .catch(error => {
        dispatch({
          type: 'LEADBYMONTH_ERROR',
          data: error.errorMessage
        });
      });
  };
};

export const getRevenueByMonth = requestBody => {
  return dispatch => {
    axios.post("/pluseLite/getRevenueByMonth", requestBody)
      .then(response => {
        // console.log("+++++++++++ RevenueByMonth +++++++++++++++++", response)
        if (response && response.data && response.data.statusCode == 200) {
          dispatch({
            type: 'REVENUEBYMONTH_SUCCESS',
            data: response.data.data.data
          });
        } else {
          dispatch({
            type: 'REVENUEBYMONTH_ERROR',
            data: response.data.errorMessage
          });
        }
      })
      .catch(error => {
        dispatch({
          type: 'REVENUEBYMONTH_ERROR',
          data: error.errorMessage
        });
      });
  };
};




export default {
  // getUserDetails
};