import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CardContent from "@material-ui/core/CardContent";
import styles from "./aside-left.module.scss";
import Api from "../Api";

function groupElements(arr, n) {
  return arr.reduce(
    (r, e, i) => (i % n ? r[r.length - 1].push(e) : r.push([e])) && r,
    []
  );
}

function MyOrgsPopup({ onClose, open, history }) {
  const [orgs, setOrgs] = useState([]);

  useEffect(() => {
    fetchOrgs();
  }, []);

  const fetchOrgs = async () => {
    const orgs = await Api.getMyOrgs();
    setOrgs(orgs);
  };

  const handleOnOrgClick = (org) => () => {
    history.push(`/loading/${org.id}/${org.name}/${org.default}`);
    onClose();
  };

  const array = groupElements(orgs, 3);

  return (
    <Dialog
      onClose={onClose}
      className={styles.autocompleteDialog}
      open={open}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle
        className={styles.autocompleteDialogTitle}
        onClose={onClose}
        id="form-dialog-title"
      >
        My Organisations {`(${orgs.length})`}
      </DialogTitle>
      <IconButton
        aria-label="close"
        className={styles.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <hr className={styles.popupHr}></hr>
      <DialogContent
        className={styles.dialogContent}
        style={{ overflowX: "auto", maxHeight: 400 }}
      >
        {array.map((groupedOrgs, i) => {
          const isLastElement = i === array.length - 1;

          return (
            <Card className={styles.cardRoot}>
              <CardContent
                className={
                  isLastElement
                    ? styles.secondCardContainer
                    : styles.firstCardContainer
                }
              >
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    minWidth: 400,
                  }}
                >
                  {groupedOrgs.map((org, i) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "column",
                          alignItems: "center",
                          marginBottom: 8,
                          cursor: "pointer",
                        }}
                        onClick={handleOnOrgClick(org)}
                      >
                        <img
                          src={
                            org.logo ||
                            require("../../../../assets/companyImage.png")
                          }
                          alt={`${org.name}'s logo`}
                          style={{
                            width: 50,
                            height: 50,
                            borderRadius: 25,
                            marginRight: 8,
                          }}
                        />
                        <span
                          style={{ fontWeight: "normal", textAlign: "center" }}
                        >
                          {org.default && (
                            <img
                              style={{width: 16, marginRight: 2}}
                              src={require("../../../../assets/Pin.svg")}
                              alt="Pin"
                            />
                          )}
                          {org.name}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </CardContent>
            </Card>
          );
        })}
      </DialogContent>
    </Dialog>
  );
}

export default MyOrgsPopup;
