import * as types from "./types";

export const setIdCRM_Integration = (idcrm_integration) => {
  return {
    type: types.SETIDCRM_INTEGRATION,
    payload: { idcrm_integration: idcrm_integration }
  };
};


export const setIntegrationDetails = (integrationDetails) => {
  //console.log("in action", integrationDetails)
  return {
    type: types.SET_INTEGRATION_DETAILS,
    payload: { integrationDetails: integrationDetails }
  };
};

export const setIDCRM_Master = (idcrm_master) => {
 // console.log("in action master", idcrm_master)
  return {
    type: types.SET_IDCRM_MASTER,
    payload: { idcrm_master: idcrm_master }
  };
};

export const setCRM_Name = (crm_name) => {
  //console.log("in action name", crm_name)
  return {
    type: types.SET_CRM_NAME,
    payload: { crm_name: crm_name }
  };
};


export default {
  setIdCRM_Integration,
  setIntegrationDetails,
  setCRM_Name,
  setIDCRM_Master
};
