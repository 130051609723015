import React from "react";
import axios from "axios";
import { userLogout } from "../pages/Login/redux/actions";
import CryptoJS from "crypto-js";

//const BASE_URL = 'http://13.48.115.95:8888/api'; //dev server ip
// const BASE_URL = "http://192.168.151.130:8888/api"; // rahul pc ip
//const BASE_URL = 'http://13.48.82.111:8888/api';     // alpha-staging server ip
// const BASE_URL = "https://prod-alb.leadx.no/api"; //prod build
//const BASE_URL = "https://dev-alb.leadx.no/api";
//const BASE_URL = "https://leadx-crm.herokuapp.com/crm";
//const BASE_URL = "https://leadxdev.herokuapp.com/crm"; //development branch
//const BASE_URL = "http://13.48.115.95:8000/crm"; // alpha v2
//const BASE_URL = "http://13.48.108.45:8000/crm"; //crm integration instance on dev-alb
//const BASE_URL = "https://alpha-int-albv2.leadx.no/crm"; //alpha v2 dns
//const BASE_URL = "https://dev-alb.leadx.no/crm";

const BASE_URL = process.env.REACT_APP_CRM_API_BASE_URL;

axios.defaults.withCrendentails = false;

var axiosInstance = axios.create({
  withCredentials: false,
  timeout: 120000,
  baseURL: BASE_URL,
  // "Access-Control-Allow-Origin": "*"
  //"Access-Control-Allow-Credentials": false  
  /* other custom settings */
});


axiosInstance.interceptors.request.use(
  async config => {

    // perform a task before the request is sent
    config.headers["authorization"] = await localStorage.getItem("integration_token");
    //for Brreg
    config.headers["x-access-token"] = await localStorage.getItem("integration_token");
    //for Brreg
    //console.log("headers", config.headers);
    config.timeout = 30000;
    config.headers["Content-Type"] = "application/json;charset=UTF-8";
   /*  let data = config.data;
    if (process.env.REACT_APP_CRYPTO_KEY  !== undefined) {
      config.data = JSON.stringify({
        sar: CryptoJS.AES.encrypt(
          JSON.stringify(data),
          process.env.REACT_APP_CRYPTO_KEY 
        ).toString()
      });
    } */
    //config.headers["phk"] = hashCode(JSON.stringify(config.data));

    // extending the timeout for sales news (add & update) and profile updation for image
    /* if (
      config.url === `/salesNews/add` ||
      config.url === `/salesNews/update` ||
      config.url === `/users/updateAvatar` ||
      config.url === `/login/checkTokenExist` ||
      config.url === `/lead/addLeadDocument`
    ) {
      config.timeout = 30000;
      if (config.url === `/lead/addLeadDocument`) {
        config.headers["Content-Type"] = "multipart/form-data";
      }
      return config;
    } else { 
      let data = config.data;
      if (process.env.REACT_APP_CRYPTO_KEY !== undefined) {
        config.data = JSON.stringify({
          sar: CryptoJS.AES.encrypt(
            JSON.stringify(data),
            process.env.REACT_APP_CRYPTO_KEY
          ).toString()
        });
        config.headers["Content-Type"] = "application/json;charset=UTF-8";
      }
     } */
    //console.log("config in request", config);
    return config;
  },
  error => {
    // handle the error
    //console.log("error error error", error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  config => {
    //console.log("config in response", config);
    // perform a task before the request is sent

    if (config.data.statusCode == 403 || config.data.statusCode == 406) {
      userLogout();
      // ReactDOM.render(<Redirect to="/login" />,"root")
      window.location.replace("/");
    }

    /* if (config.data.statusCode === 200) {
      let data = config.data.data;
      if (process.env.REACT_APP_CRYPTO_KEY  !== undefined) {
        let bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_CRYPTO_KEY );
        config.data.data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
    } */
    //console.log("config in response", config);
    return config;
  },
  error => {
    // handle the error
    console.log("error error error", error);

    return Promise.reject(error);
  }
);

export default axiosInstance;
