import axios from "../../common/api-config";

export default {
  async getOrganisationList() {
    const result  = await axios.post("/account/getOrganizationList", {});
    return result.data.data.rows;
  },
  async getLeadTable(body) {
    const url = '/pluseLite/getLeadTableDetail';
    const result = await axios.post(url, body);
    return result.data.data.rows;
  },
  async getLeadStage (body) {
    const url = '/pluseLite/getLeadStages';
    const result = await axios.post(url, body);
    return result.data.data.data;
  },
  async getHitRate(body) {
    const url = '/pluseLite/getHitRateByMonth';
    const result = await axios.post(url, body);
    return result.data.data.data;
  },
  async getAvgVelocityMonthWise(body) {
    const url = '/pluseLite/getAvgVelocityByMonth';
    const result = await axios.post(url, body);
    return result.data.data.data;
  },
  async getLeadStagesAvgTime(body) {
    const url = '/pluseLite/getLeadStagesAvgTime';
    const result = await axios.post(url, body);
    return result.data.data.data;
  },
  async getLeadAging(body) {
    const url = '/pluseLite/getLeadAging';
    const result = await axios.post(url, body);
    return result.data.data.data;
  },
  async getWedgitDealValue(body) {
    const url = '/pluseLite/getWedgitDealValue';
    const result = await axios.post(url, body);
    const data = result.data.data.data;
    return (data && data[0] && data[0][0].Weightvalue) || 0;
  },
  async getGrossRevValue(body) {
    const url = '/pluseLite/getGrossRevValue';
    const result = await axios.post(url, body);
    const data = result.data.data.data;
    return (data && data[0] && data[0][0].GrossAvgvalue) || 0;
  },
  async getWeightChartData(body) {
    const url = '/pluseLite/getWeightByMonth';
    const result = await axios.post(url, body);
    return result.data.data;
  },
  async getGrossRevForcastData(body) {
    const url = '/pluseLite/getGrossRevForcastByMonth';
    const result = await axios.post(url, body);
    return result.data.data;
  },
  async getLeadBySource(body) {
    const url = '/pluseLite/getLeadBySourceByMonth';
    const result = await axios.post(url, body);
    return result.data.data;
  },
  async getLeadByStage(body) {
    const url = '/pluseLite/getLeadByStageMonthWise';
    const result = await axios.post(url, body);
    return result.data.data;
  },
  async getHandOverGeneratedByParents(body) {
    const url = '/pluseLite/getHandOverGeneratedByParents';
    const result = await axios.post(url, body);
    return result.data.data;
  },
  async getHandOverAcceptedByParents(body) {
    const url = '/pluseLite/getHandOverAcceptedByParents';
    const result = await axios.post(url, body);
    return result.data.data;
  },
  async getCrossSalesGenChartdata(body) {
    const url = '/pluseLite/getCrossSalesGenDashboardData';
    const result = await axios.post(url, body);
    console.log(result,'getCrossSalesGenChartdata');
    return result.data.data;
  },
  async getCrossSalesAcceptedChartdata(body) {
    const url = '/pluseLite/getCrossSalesacceptDashboardData';
    const result = await axios.post(url, body);
    return result.data.data;
  },
  async getFinancialYearStartingMonth() {
    const url = '/account/getFinYearStartingMonth';
    const result = await axios.post(url, {});
    return result.data.data.month;
  }
}