import React, { useState, useEffect } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import styles from "./organization.module.scss";
import Switch from "react-switch";
import apis from "./apis";
import LargeScreenLoader from "../../common/Loaders/PulseLoader/pulse-loader";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import SnackbarComponent from "../../common/Snackbar/snackbar";
import { showSnackbarMessage } from "../../common/common-functions";
import OrgProfileSetting from "./OrgProfileSetting";
import pendingRequestAPIs from "../PendingRequest/apis";
import IconButton from "@material-ui/core/IconButton";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import copy from "copy-to-clipboard";
import InviteUser from "../../common/InviteUser";
import { isParentOrgAdmin } from "../../common/common-functions";
// Assets
import profile_setting from "../../../assets/org_profile/setting_icon.png";
import { getDatesFromMonth } from "../../common/Util";

const PrimaryButton = styled(Button)`
  font-size: 0.75em;
  text-transform: none;
  white-space: nowrap;
  text-align: center;
  background-color: #3a4860;
  padding-left: 2em;
  padding-right: 2em;
  color: white;
  height: 4.25em;
  padding: 0 0.5em;
  padding-left: 4em;
  padding-right: 4em;
  &:hover {
    background-color: #19d98b;
  }
`;

const OutLinedButton = styled(Button)`
  font-size: 0.75em;
  text-transform: none;
  height: 4.25em;
  padding: 0 0.5em;
  white-space: nowrap;
  text-align: center;
  background-color: transparent;
  padding-left: 4em;
  padding-right: 4em;
  font-weight: bold;
  color: #3a4860;
  &:hover {
    color: #19d98b;
  }
`;

const CustomCard = styled.div`
  background-color: #ffffff;
  padding: 4.5em;
  padding-top: 1.875em;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.2);
`;

const ROLE_OPTIONS = [
  {
    label: "Basic",
    value: 1,
  },
  {
    label: "Professional",
    value: 2,
  },
  {
    label: "Manager",
    value: 3,
  },
  {
    label: "Lite",
    value: 5,
  },
];

function getRoleById(id) {
  return ROLE_OPTIONS.find((role) => role.value === id);
}

function getOptionById(options, id) {
  return options.find((option) => option.value == id);
}

function dropdownValueMaper(r) {
  return {
    value: r.id,
    label: r.display_name,
  };
}

export default function OrganizationProfile(props) {
  console.log("props", props);
  const [features, setFeatures] = useState([]);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  // self_user_reg_approval_required
  const [surar, setSurar] = useState(false);
  // self_user_reg_config_enabled
  const [surce, setSurce] = useState(false);
  const [crossSilo, setCrossSilo] = useState(false);
  const [handoverFeature, setHandoverFeature] = useState(false);
  const [createContact, setCreateContact] = useState(false);
  const [revenueField, setRevenueField] = useState(false);
  const [role, setRole] = useState(null);
  const [licenseOptions, setLicenseOptions] = useState([]);
  const [designationOptions, setDesignationOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [designation, setDesignation] = useState(null);
  const [department, setDepartment] = useState(null);
  const [setting, setSetting] = useState(false);
  const [registationURL, setRegistrationURL] = useState("");

  const [enable_publishing_news, setEnable_publishing_news] = useState(false);
  const [
    enable_publishing_news_child,
    setEnable_publishing_news_child,
  ] = useState(false);

  useEffect(() => {
    fetchOrganizationDetails((accountPreference) => {
      fetchDependencies(accountPreference);
      fetchFeatures();
    });
  }, []);

  const fetchFeatures = async () => {
    const features = await apis.getFeatureStatus();
    console.log("features", features);
    setFeatures(features);
  };

  const fetchDependencies = async (accountPreference) => {
    await Promise.all([
      fetchLicenses(accountPreference),
      fetchDepartments(accountPreference),
      fetchAllRoles(accountPreference),
    ]);
  };

  const fetchAllRoles = async (accountPreference) => {
    const result = await pendingRequestAPIs.getAllRoles();
    const options = result.data.rows.map(dropdownValueMaper);
    setDesignationOptions(options);
    setDesignation(getOptionById(options, accountPreference.default_role_id));
  };

  const fetchLicenses = async (accountPreference) => {
    const result = await pendingRequestAPIs.getLicenses();
    console.log(result, "Result");
    const options = result.data.licenses.map(dropdownValueMaper);
    setLicenseOptions(options);
    setRole(getOptionById(options, accountPreference.default_license_id));
  };

  const fetchDepartments = async (accountPreference) => {
    const result = await pendingRequestAPIs.getAllDepartments();
    const options = result.data.rows.map((r) => ({
      value: r.id,
      label: r.name,
    }));
    setDepartmentOptions(options);
    setDepartment(
      getOptionById(options, accountPreference.default_department_id)
    );
  };

  const fetchOrganizationDetails = async (cb) => {
    const result = await apis.getOrganizationDetails();
    console.log("result", result);
    setData(result.data.data);
    setRegistrationURL(
      result.data.data.profile.dns + "/register/" + result.data.data.profile.key
    );
    setIsLoading(false);
    setOtherThings(result.data.data);

    if (cb) cb(result.data.data.profile.AccountPreference);
  };

  const setOtherThings = (data) => {
    const accountPreferences = data.profile.AccountPreference;
    setSurar(accountPreferences.self_user_reg_approval_required);
    setSurce(accountPreferences.self_user_reg_config_enabled);
    setCrossSilo(accountPreferences.cross_silo_feature);
    setHandoverFeature(accountPreferences.handover_feature);
    setEnable_publishing_news(accountPreferences.enable_publishing_news);
    setEnable_publishing_news_child(
      accountPreferences.enable_publishing_news_child
    );
    setCreateContact(accountPreferences.create_contact_for_lite_user);
    setRevenueField(accountPreferences.revenue_field_for_lite_user);
  };

  const handleOnUserRegConfigChange = async (newChecked) => {
    setSurce(newChecked);
    if (!newChecked) setSurar(false);
  };

  const handleOnUserRegApprovalChange = async (newChecked) => {
    setSurar(newChecked);
  };

  const handleOnUpdate = async () => {
    setIsUpdating(true);
    const result = await apis.updateOrganizationSettings(
      surce,
      surar,
      role.value,
      designation.value,
      department.value,
      crossSilo,
      handoverFeature,
      enable_publishing_news,
      enable_publishing_news_child,
      createContact,
      revenueField
    );

    if (result.statusCode === 200) {
      showSnackbarMessage(result.data, "snack-success");
    } else {
      showSnackbarMessage(result.errorMessage, "Technical Error");
      handleOnCancelClick();
    }
    setIsUpdating(false);
  };

  const handleRoleChange = (newRole) => {
    setRole(newRole);
  };

  const handleOnDepartmentChange = (newDepartment) => {
    setDepartment(newDepartment);
  };

  const handleOnDesignationChange = (newDesignation) => {
    setDesignation(newDesignation);
  };

  const handleOnCancelClick = () => {
    setOtherThings(data);
  };

  const handleOnCrossSiloChange = (newChecked) => {
    setCrossSilo(newChecked);
  };

  const handleOnHandoverFeatureChange = (newChecked) => {
    setHandoverFeature(newChecked);
    if (newChecked === false) {
      setCrossSilo(false);
    }
  };
  // enable_publishing_news
  const handleOnEnable_publishing_newsChange = (newChecked) => {
    setEnable_publishing_news(newChecked);
    if (newChecked === false) {
      setEnable_publishing_news_child(false);
    }
  };

  const handleOnEnable_publishing_news_childChange = (newChecked) => {
    setEnable_publishing_news_child(newChecked);
  };

  const handleOnCreateContactChange = (newChecked) => {
    setCreateContact(newChecked);
  };

  const handleOnRevenueFieldChange = (newChecked) => {
    setRevenueField(newChecked);
  };

  const handleCopyOnClick = () => {
    copy(registationURL);
    showSnackbarMessage("URL copied successfully.", "snack-success");
  };
  const handleProfileSetting = () => {
    setSetting(true);
  };

  const handleGenerateNewURL = async () => {
    setIsLoading(true);
    const URLUpdateResult = await apis.updateRegistrationUrl(data.profile.id);
    setRegistrationURL(URLUpdateResult.key);
    setIsLoading(false);
    showSnackbarMessage("New URL generated successfully.", "snack-success");
  };

  const handleOnUpdatOrgProfileClick = () => {
    props.history.push(
      `/administration/organization_profile/${data.profile.id}/edit`
    );
  };

  const [fromDate, toDate] = getDatesFromMonth(
    (data &&
      data.profile &&
      data.profile.AccountPreference &&
      data.profile.AccountPreference.fin_year_starting_month) ||
      0
  );

  return (
    <>
      <SnackbarComponent />
      {isLoading && <LargeScreenLoader />}
      {setting && <OrgProfileSetting history={props.history} />}
      {!isLoading && !setting && (
        <div className="main-wrapper">
          <div className="breadcrumbs">
            <div className="row justify-content-between">
              <div className="col-md-4">
                <h3 className={styles.title_Administration}>Administration</h3>
                <div className="breadcrumbs_container">
                  <Breadcrumbs separator="›" arial-label="Breadcrumb">
                    <Link to="/sales-news">Home</Link>
                    <Link to="/administration">Administration</Link>
                    <span>Organization Profile</span>
                  </Breadcrumbs>
                </div>
              </div>
              <div className="col-md text-right">
                {features["Subscriptions"] || !isParentOrgAdmin() ? (
                  <label className={styles.disabled}></label>
                ) : (
                  <label
                    className={styles.cursor_pointer}
                    onClick={() =>
                      props.history.push("/administration/subscriptions")
                    }
                  >
                    Manage Subscriptions <span className="mx-2"></span> |
                  </label>
                )}
                <span className="mx-2"></span>
                <InviteUser />
                <span className="mx-2"></span>

                {features["Child Organizations"] || !isParentOrgAdmin() ? (
                  <label className={styles.disabled}></label>
                ) : (
                  <label
                    className={styles.cursor_pointer}
                    onClick={() =>
                      props.history.push(
                        "/administration/new_child_organization"
                      )
                    }
                  >
                    |<span className="mx-2"></span>
                    Create Child Organization
                  </label>
                )}
                <span className="mx-2"></span>
                {isParentOrgAdmin() && (
                  <Button
                    variant="contained"
                    onClick={handleOnUpdatOrgProfileClick}
                    size="medium"
                    style={{ backgroundColor: "#232f44", color: "white" }}
                  >
                    Update
                  </Button>
                )}
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-lg-4">
              <img
                className={styles.org_profile_logo}
                src={data.profile.logo}
              />
              <div className={styles.title_text}>
                Account / Organization Name
              </div>
              <div>{data.profile.name}</div>
              <div className={styles.title_text}>Customer ID</div>
              <div>{data.profile.id}</div>
              <div className={styles.title_text}>Sub domain for web users</div>
              <div>{data.profile.dns}</div>
              <div className={styles.title_text}>Address</div>
              <div>
                {data.profile.address_line_1 +
                  " " +
                  data.profile.address_line_2}
              </div>
              <div className={styles.title_text}>Registered URL</div>
              <div className="row">
                <div className={"col-md-6 " + styles.url_container}>
                  {registationURL}
                </div>
                <div className="col-md-1">
                  <IconButton aria-label="copy" onClick={handleCopyOnClick}>
                    <FileCopyOutlinedIcon />
                  </IconButton>
                </div>
                <div class="col-md-6">
                  <PrimaryButton
                    onClick={handleGenerateNewURL}
                    className={styles.generateUrlBtn}
                  >
                    Generate New Url
                  </PrimaryButton>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-8">
              <img
                src={profile_setting}
                className={styles.icon_pic}
                onClick={handleProfileSetting}
              />
              <div className={styles.title_right_text}>Primary Contact</div>
              <div className={styles.card_view}>
                <CustomCard>
                  <div className="row">
                    <div className="col-md-4">
                      <div className={styles.title_text}>Email Id</div>
                      {<div>{data.primary_contact.email}</div>}
                    </div>
                    <div className="col-md-4">
                      <div className={styles.title_text}>Phone No.</div>
                      {
                        <div>
                          +{data.primary_contact.countryCode && data.primary_contact.countryCode.dialing_code}{" "}
                          {data.primary_contact.contact}
                        </div>
                      }
                    </div>
                    <div className="col-md-4">
                      <div className={styles.title_text}>Password</div>
                      <div>********</div>
                    </div>
                  </div>
                  <div style={{ marginTop: "1.25em" }}>
                    *Primary Contact will act as an Admin of a system
                  </div>
                </CustomCard>
              </div>

              <div className={styles.title_right_text}>
                Account / Organization Name
              </div>
              <div className={styles.card_view}>
                <CustomCard>
                  <div className="row">
                    <div className="col-md-4">
                      <div className={styles.title_text}>Account Group</div>
                      <div>{data.profile.AccountGroup.name}</div>
                    </div>
                    <div className="col-md-4">
                      <div className={styles.title_text}>Base Currency</div>
                      <div>
                        {data.profile.AccountPreference.currency.short_name}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className={styles.title_text}>Other Currencies</div>
                      {data.currencies.rows.map((c) => c.short_name).join(', ')}
                    </div>
                  </div>
                </CustomCard>
              </div>

              <div className={styles.title_right_text}>
                Account / Organization Settings
              </div>
              <div className={styles.card_view}>
                <CustomCard>
                  <div className="row">
                    <div className="col-md-4">
                      <div className={styles.title_text}>Date Format</div>
                      <div>{data.profile.AccountPreference.dateformatid.format}</div>
                    </div>
                    <div className="col-md-4">
                      <div className={styles.title_text}>Default Language</div>
                      <div>{data.profile.AccountPreference.languageid.language}</div>
                    </div>
                    <div className="col-md-4">
                      <div className={styles.title_text}>Timezone</div>
                      <div>{data.profile.AccountPreference.timezoneid.timezone}</div>
                    </div>
                  </div>
                </CustomCard>
              </div>

              {isParentOrgAdmin() && (
                <>
                  <div className={styles.title_right_text}>Financial Year</div>
                  <div className={styles.card_view}>
                    <CustomCard>
                      Your Financial year will be set to{" "}
                      {fromDate.format("MMMM YYYY")} -{" "}
                      {toDate.format("MMMM YYYY")}
                    </CustomCard>
                  </div>
                </>
              )}

              <div>
                <div className={styles.bottom_update_button}></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
