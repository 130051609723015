import React, { useState, useRef } from "react";
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import calenderIcon from "../../../assets/images/data_exports/calender.png";
import calenderDownArrowIcon from "../../../assets/images/data_exports/calender_down.svg";
import styles from "./style.scss";
import { LinkedCalendar } from "./src";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import useOutsideClick from '../../hooks/outsideClick';

function MyDateRangePicker({
  dateRange,
  onDateChange,
  maxWidth,
  minWidth = 250,
  ...rest
}) {
  const [open, setOpen] = useState(false);
  const ref = useRef();

  useOutsideClick(ref, () => {
    if (open) setOpen(false);
  });

  const onDatesChange = ({ startDate, endDate }) => {
    onDateChange({
      from_date: moment(startDate.toDate()),
      to_date: moment(endDate.toDate()),
    });
    setOpen(false);
  };

  const style = {
    height: "2.6em",
    paddingTop: 4,
    width: "95%",
    paddingRight: 4,
    textAlign: "center",
    backgroundColor: "white",
    border: "0.75px solid #D3D3D3",
    borderRadius: 4,
    minWidth,
    marginTop: 1,
  };

  if (maxWidth) {
    style.maxWidth = maxWidth;
  }

  return (
    <FormControl
      style={style}
      variant="outlined"
      fullWidth
      ref={ref}
    >
      <div className="customDateRange_divider" onClick={() => setOpen(!open)}>
        <img
          src={calenderIcon}
          width="15px"
          style={{ marginRight: "5px" }}
        />
        <span class="align-middle">{`${moment(dateRange.from_date).format(
          "DD-MMM-YYYY"
        )} - ${moment(dateRange.to_date).format("DD-MMM-YYYY")}`}</span>
        <img
          src={calenderDownArrowIcon}
          style={{
            cursor: "pointer",
            marginRight: " -9px",
            marginLeft: "8px",
          }}
          className={styles.calenderDownArrowIcon}
        />
      </div>
      {open && (
        <LinkedCalendar
          dateRange={dateRange}
          onCancel={() => setOpen(false)}
          onDatesChange={onDatesChange}
          showDropdowns={false}
          {...rest}
        />
      )}
    </FormControl>
  );
}

export default MyDateRangePicker;
