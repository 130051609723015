import React, { Component } from "react";
import styles from "./DoneNReadyCRMComponent.module.scss";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import "rc-time-picker/assets/index.css";
import CircularProgress from "../../../common/Loaders/CircularLoader/circular-loader";
import { openSnackbar } from "../../../common/Snackbar/snackbar";
import axios from "../../../common/api-configCRM";

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing.unit
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={`${classes.root} ${styles.dialogTitle}`}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(() => ({
  root: {
    // padding: "30px"
    padding: "24px"
  }
}))(props => {
  const { children, classes } = props;
  return (
    <MuiDialogContent
      className={`${classes.root} ${styles.done_n_ready_mapping_dialog_content}`}
    >
      {children}
    </MuiDialogContent>
  );
});

const DialogActions = withStyles(() => ({
  root: {
    margin: 0,
    padding: "5px 0 0 0"
  }
}))(MuiDialogActions);

const stylesMaterialUI = () => ({});

class DoneNReadyCRMComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idcrm_master: this.props.fieldMapping,
      isActive: this.props.isActive
      //crm_name: this.props.crm_name
    };
  }

  componentDidMount() {
    // console.log("props", this.props);
  }

  handleCloseDoneNReady = () => {
    // console.log("Close clicked");
    this.props.history.push({
      pathname: '/integrations'
    });
  };

  handleActivateDeactivate = () => {
    // console.log("handleActivateDeactivate clicked", this.state.isActive);

    if (this.state.idcrm_master !== undefined &&
      this.state.isActive !== undefined) {
      const params = {
        idcrm_master: this.state.idcrm_master,
        isActive: !this.state.isActive
      };
      // console.log("params", params);
      axios.put('/integration/changestatus', { data: JSON.stringify(params) })
        .then(response => {
          // console.log("response in savenproceed", response);
          if (response !== undefined &&
            response.data.status !== undefined &&
            response.data.status === 0) {
            this.setState({ isActive: !this.state.isActive }, () => {
              // console.log("state active", this.state.isActive);
            });
          } else {
            openSnackbar("Unable to Update Status", "snack-error");
          }
        })
        .catch((error) => {
          console.log(error);
          openSnackbar("Exception updating status", "snack-error");
        })
    }
  }

  render() {
    return (
      <div className={styles.done_n_ready_mapping + " overflow_x_hidden"}>
        <DialogTitle id="customized-dialog-title" onClose={this.props.close}>
          <div className={styles.done_n_ready_mapping_dialog_title}>
            LeadX Integration Wizard
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-sm-12 text-center">
              <h6 style={{ padding: '10px' }}>
                <strong>Congratulations!!!</strong> The Integration has been configured successfully. Activate to start the synchronization.</h6>
            </div>
          </div>
          <DialogActions>
            {this.state.submitButton ? (
              <div className={styles.submit_loader}>
                <CircularProgress
                  style={{
                    color: "#19D98C"
                  }}
                />
              </div>
            ) : (<>
              <hr className="jss201" />
              <div className="mt-3 mb-5">
                <div style={{ textAlign: 'right' }}>
                  {/* {this.state.isActive ? <>
                    <Button
                      onClick={this.handleActivateDeactivate}
                      className={styles.deactivate_grey}
                      color="primary"
                      disabled={!this.state.isActive}
                    >
                      De-Activate
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;</>
                    : <>
                      <Button
                        onClick={this.handleActivateDeactivate}
                        className={styles.activate_green}
                        color="primary"
                        disabled={this.state.isActive}
                      >
                        Activate
                    </Button>
                      &nbsp;&nbsp;&nbsp;&nbsp;</>} */}
                  <Button
                    onClick={this.props.close}
                    className={styles.saveButton}
                    color="primary"
                  >
                    Done
                    </Button>
                </div>
              </div>
            </>
              )}
          </DialogActions>
        </DialogContent>
      </div>
    );
  }
}
export default withStyles(stylesMaterialUI)(DoneNReadyCRMComponent);
