import * as types from "./types";

const initialState = {
  salesTasks: null,
  errorLoadingSalesTasks: false,
  loadingTasks: true
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SALES_TASKS_SUCCESS:
      return {
        ...state,
        errorLoadingSalesTasks: false,
        salesTasks: action.data,
        loadingTasks: false
      };

    case types.GET_SALES_TASKS_FAIL:
      return {
        ...state,
        errorLoadingSalesTasks: true,
        salesTasks: null,
        loadingTasks: false
      };

    case types.LOAD_SALES_TASKS:
      return {
        ...state,
        loadingTasks: true,
        errorLoadingSalesTasks: false,
        salesTasks: null
      };

    default:
      return state;
  }
};

export default reducer;
