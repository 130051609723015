import React, { Component } from "react";
import { connect } from "react-redux";

import CompleteSalesNewsComponent from "./component/CompleteSalesNewsComponent";
import LargeScreenLoader from "../../common/Loaders/PulseLoader/pulse-loader";

class CompleteSalesNewsConatiner extends Component {
  state = {
    isLoading: true
  };
  componentDidMount() {
    if (this.props.history.location.state !== undefined) {
      this.setState({
        isLoading: false
      });
    } else {
      this.props.history.push("/sales-news");
    }
  }

  render() {
    const completeSalesNewsComponentProps = { ...this.props };
    return (
      <>
        {this.state.isLoading ? (
          <LargeScreenLoader />
        ) : (
          <CompleteSalesNewsComponent {...completeSalesNewsComponentProps} />
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps)(CompleteSalesNewsConatiner);
