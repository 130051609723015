import Autosuggest from "react-autosuggest";
import React from "react";
import styles from "./autocomplete.module.scss";

class AutoComplete extends React.Component {
  constructor() {
    super();

    this.state = {
      value: "",
      suggestions: [],
      inputChanged: false,
      companyChanged: false,
      loadingCompanyList: false
    };
  }

  componentDidMount() {
    this.setState({
      suggestions: this.props.suggestions ? this.props.suggestions : []
    });
  }

  componentDidUpdate = async (prevProps, prevState) => {
    if (
      this.props.suggestions !== prevProps.suggestions &&
      this.props.autoCompleteName === "company"
    ) {
      this.setState({
        suggestions: this.props.suggestions ? this.props.suggestions : [],
        loadingCompanyList: this.props.loadingCompanyList
      });
    }

    // ===========================bregs enhancement
    if (
      this.props.suggestions !== prevProps.suggestions &&
      this.props.autoCompleteName === "contactCompanyName"
    ) {
      this.setState({
        suggestions: this.props.suggestions ? this.props.suggestions : [],
        loadingCompanyList: this.props.loadingCompanyList
      });
    }

    // ===========================bregs enhancement
    if (
      this.props.suggestions !== prevProps.suggestions &&
      this.props.autoCompleteName === "personCompany"
    ) {
      this.setState({
        suggestions: this.props.suggestions ? this.props.suggestions : [],
        loadingCompanyList: this.props.loadingCompanyList
      });
    }
    // ===============================bregs enhancement

    if (
      (this.props.resetPerson &&
        this.props.resetPerson != prevProps.resetPerson) ||
      (this.props.resetState &&
        this.props.resetState != prevProps.resetState) ||
      (this.props.resetCity && this.props.resetCity != prevProps.resetCity) ||
      (this.props.resetLeadID &&
        this.props.resetLeadID != prevProps.resetLeadID)
    ) {
      await this.setState({
        value: ""
      });
    }

    if (
      this.props.value &&
      (this.props.value != prevProps.value ||
        this.props.suggestions != prevProps.suggestions)
    ) {
      var selectedRecord = this.props.suggestions
        ? this.props.suggestions.filter(item => {
          return item.id == this.props.value;
        })
        : [];

      if (this.props.autoCompleteName == "company") {
        if (this.props.newlyAddedCompany) {
          this.setState({
            value:
              typeof selectedRecord !== "undefined" &&
                selectedRecord[0] &&
                selectedRecord[0].company_name
                ? selectedRecord[0].company_name
                : this.state.value
          });
        } else {
          this.setState({
            value:
              typeof selectedRecord !== "undefined" &&
                selectedRecord[0] &&
                selectedRecord[0].company_name &&
                !this.state.inputChanged
                ? selectedRecord[0].company_name
                : this.state.value
          });
        }
      } else if (this.props.autoCompleteName == "personCompany" || this.props.autoCompleteName === "contactCompanyName") {
        this.setState({
          value:
            typeof selectedRecord !== "undefined" &&
              selectedRecord[0] &&
              selectedRecord[0].company_name
              ? selectedRecord[0].company_name
              : this.state.value
        });
      } else if (this.props.autoCompleteName == "leadId") {
        this.setState({
          value:
            typeof selectedRecord !== "undefined" &&
              selectedRecord[0] &&
              selectedRecord[0].lead_title &&
              !this.state.inputChanged
              ? selectedRecord[0].lead_title
              : this.state.value
        });
      } else if (
        this.props.autoCompleteName === "person" ||
        this.props.autoCompleteName === "personCountry" ||
        this.props.autoCompleteName === "personState" ||
        this.props.autoCompleteName === "personCity" ||
        this.props.autoCompleteName === "leadSource" ||
        this.props.autoCompleteName === "companyCountry" ||
        this.props.autoCompleteName === "companyState" ||
        this.props.autoCompleteName === "companyCity" ||
        this.props.autoCompleteName === "activity_person_id" ||
        this.props.autoCompleteName === "activity_type_id" ||
        this.props.autoCompleteName === "leadCategory"
      ) {
        if (
          this.props.autoCompleteName === "companyState" ||
          this.props.autoCompleteName === "personState"
        ) {
          this.setState({
            value:
              typeof selectedRecord !== "undefined" &&
                selectedRecord[0] &&
                selectedRecord[0].name
                ? selectedRecord[0].name
                : this.state.value
          });
        } else {
          this.setState({
            value:
              typeof selectedRecord !== "undefined" &&
                selectedRecord[0] &&
                selectedRecord[0].name &&
                !this.state.inputChanged
                ? selectedRecord[0].name
                : this.state.value
          });
        }

        // if (this.props.suggestions !== prevProps.suggestions && this.props.autoCompleteName === "contactCompanyName") {
        //   console.log(this.props.value);

        //   this.setState({
        //     value: typeof selectedRecord !== "undefined" &&
        //       selectedRecord[0] &&
        //       selectedRecord[0].name &&
        //       !this.state.inputChanged
        //       ? selectedRecord[0].name
        //       : this.state.value
        //   });
        // }

        // if(this.props.autoCompleteName === "person" && typeof selectedRecord !== "undefined" && selectedRecord[0] &&
        //   selectedRecord[0].name && (this.state.value !== selectedRecord[0].name)){
        //   this.setState({
        //     value: selectedRecord[0].name
        //   });
        // }
      }
    }
  };

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    if (
      this.props.autoCompleteName &&
      (this.props.autoCompleteName === "company" ||
        this.props.autoCompleteName === "personCompany" ||
        this.props.autoCompleteName === "contactCompanyName")
    ) {
      return inputLength === 0
        ? this.props.suggestions
        : this.props.suggestions
          ? this.props.suggestions.filter(
            record =>
              record.company_name.toLowerCase().slice(0, inputLength) ===
              inputValue

            // record.company_name.toLowerCase().includes(inputValue)
          )
          : [];
    } else if (
      this.props.autoCompleteName &&
      this.props.autoCompleteName === "leadId"
    ) {
      return inputLength === 0
        ? this.props.suggestions
        : this.props.suggestions
          ? this.props.suggestions.filter(
            record =>
              record.lead_title.toLowerCase().slice(0, inputLength) ===
              inputValue
          )
          : [];
    } else {
      return inputLength === 0
        ? this.props.suggestions
        : this.props.suggestions
          ? this.props.suggestions.filter(
            record =>
              record.name.toLowerCase().slice(0, inputLength) === inputValue
          )
          : [];
    }
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue = suggestion =>
    this.props.autoCompleteName &&
      (this.props.autoCompleteName === "company" ||
        this.props.autoCompleteName === "personCompany" ||
        this.props.autoCompleteName === "contactCompanyName")
      ? suggestion.company_name
      : this.props.autoCompleteName === "leadId"
        ? suggestion.lead_title
        : suggestion.name;

  // Use your imagination to render suggestions.
  renderSuggestion = suggestion => {
    return (
      <div>
        {this.props.autoCompleteName &&
          (this.props.autoCompleteName === "company" ||
            this.props.autoCompleteName === "personCompany" ||
            this.props.autoCompleteName === "contactCompanyName")
          ? suggestion.company_name
          : this.props.autoCompleteName === "leadId"
            ? suggestion.lead_title
            : suggestion.name}
      </div>
    );
  };

  shouldRenderSuggestions = value => {
    return true;
  };

  onChange = async (event, { newValue }) => {
    switch (this.props.autoCompleteName) {
      case "company":
        this.props.onChange(event, newValue, "company");
        break;
      case "person":
        this.props.onChange(event, newValue, "person");
        break;
      case "companyCountry":
        this.props.onChange(event, newValue, "companyCountry");
        break;
      case "companyState":
        this.props.onChange(event, newValue, "companyState");
        break;
      case "companyCity":
        this.props.onChange(event, newValue, "companyCity");
        break;
      case "personCompany":
        this.props.onChange(event, newValue, "personCompany");
        break;
      case "contactCompanyName":
        this.props.onChange(event, newValue, "contactCompanyName");
        break;
      case "personCountry":
        this.props.onChange(event, newValue, "personCountry");
        break;
      case "personState":
        this.props.onChange(event, newValue, "personState");
        break;
      case "personCity":
        this.props.onChange(event, newValue, "personCity");
        break;
      case "leadSource":
        this.props.onChange(event, newValue, "leadSource");
        break;

      case "activity_type_id":
        this.props.onChange(event, newValue, "activity_type_id");
        break;

      case "leadId":
        this.props.onChange(event, newValue, "leadId");
        break;

      case "activity_person_id":
        this.props.onChange(event, newValue, "activity_person_id");
        break;

      case "leadCategory":
        this.props.onChange(event, newValue, "leadCategory");
        break;

      default:
        break;
    }
    this.setState({
      value: newValue,
      inputChanged: true
    });
  };

  onBlur = e => {
    if (this.props.onBlur !== undefined) {
      this.props.onBlur(e);
    }
  };

  onSuggestionSelected = (event, { suggestion }) => {
    switch (this.props.autoCompleteName) {
      case "company":
        this.props.getPersonList({ name: "company_id", value: suggestion.id });
        break;
      case "person":
        this.props.getPersonPhone({
          name: "contact_id",
          value: suggestion.id,
          contact_name: suggestion.name,
          contact_person_phone: suggestion.phone_number
        });
        break;
      case "leadSource":
        this.props.setLeadSource({
          name: "lead_source_id",
          value: suggestion.id
        });
        break;

      case "leadCategory":
        this.props.setCategorySource({
          name: "category_id",
          value: suggestion.id
        });
        break;
      case "personCompany":
        this.props.handlePersonFieldsChange({
          name: "personCompany",
          value: suggestion.id
        });
        break;

      case "contactCompanyName":
        this.props.handleCompanyFieldsChange({
          name: "companyName",
          value: suggestion
        });
        break;
      case "personCountry":
        this.props.handlePersonFieldsChange({
          name: "personCountry",
          value: suggestion.id
        });
        break;
      case "personState":
        this.props.handlePersonFieldsChange({
          name: "personState",
          value: suggestion.id
        });
        break;
      case "personCity":
        this.props.handlePersonFieldsChange({
          name: "personCity",
          value: suggestion.id
        });
        break;
      case "companyCountry":
        this.props.handleCompanyFieldsChange({
          name: "companyCountry",
          value: suggestion.id
        });
        break;
      case "companyState":
        this.props.handleCompanyFieldsChange({
          name: "companyState",
          value: suggestion.id
        });
        break;
      case "companyCity":
        this.props.handleCompanyFieldsChange({
          name: "companyCity",
          value: suggestion.id
        });
        break;
      case "activity_person_id":
        this.props.change({ name: "activity_person_id", value: suggestion.id });
        break;
      case "activity_type_id":
        this.props.change({ name: "activity_type_id", value: suggestion.id });
        break;
      case "leadId":
        this.props.change({ name: "leadId", value: suggestion.id });
        break;
    }
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = async ({ value }) => {
    await this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: this.props.suggestions
    });
  };

  render() {
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    let inputProps = {
      placeholder: this.props.placeholder,
      value,
      onChange: this.onChange,
      onBlur: this.onBlur,
      disabled: false,
      maxLength: "50",
      autoComplete: "off"
    };

    if (this.props.disabled) {
      inputProps.disabled = true;
    }

    return (
      <Autosuggest
        theme={styles}
        suggestions={suggestions ? suggestions : []}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={this.onSuggestionSelected}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
      />
    );
  }
}

export default AutoComplete;
