import React, { Component } from "react";
import AddTasksComponent from "./component/AddTasksComponent";
import { connect } from "react-redux";
import * as actions from "./redux/actions";

class AddTasksContainer extends Component {
  render() {
    const addTasksComponent = { ...this.props };
    return <AddTasksComponent {...addTasksComponent} />;
  }
}

const mapStateToProps = state => {
  return {
    addSalesTask: state.addTask.addSalesTask,
    updateSalesTask: state.addTask.updateSalesTask,
    errorMessage: state.addTask.errorMessage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addTask: requestBody => dispatch(actions.addTask(requestBody)),
    editTask: requestBody => dispatch(actions.editTask(requestBody)),
    resetTask: () => dispatch(actions.resetTask()),
    resetUpdateTask: () => dispatch(actions.resetUpdateTask())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTasksContainer);
