import React from "react";
import moment from "moment";
import Moment from "react-moment";
import CryptoJS from "crypto-js";
import { openSnackbar } from "../common/Snackbar/snackbar";
import _ from 'lodash'

export function handleTechnicalError () {
  openSnackbar("Technical error occured, Please try after sometime.", 'snack-error');
}

export function showSnackbarMessage(message, type) {
  openSnackbar(message, type);
};

export const getTimeDifference = (item, lead_status_id) => {
  if (item && item.lead_status_logs && item.lead_status_logs.length > 0) {
    let curerntStatusLog = item.lead_status_logs.filter(log => {
      return log.lead_status_id == lead_status_id;
    });
    let nextStatusLog = null;
    if(lead_status_id != 6){
      let nextStatusId = lead_status_id;
      while((nextStatusLog == null || nextStatusLog.length == 0) && nextStatusId <= 6){
        if(nextStatusId !== lead_status_id){
          nextStatusLog = item.lead_status_logs.filter(log => {
            return log.lead_status_id == nextStatusId;
          });
        }
        ++nextStatusId;
      }
    }    
    //difference between 2 status
    if (
      curerntStatusLog &&
      curerntStatusLog[0] &&
      nextStatusLog &&
      nextStatusLog[0]
    ) {
      let nextDate = new Date(nextStatusLog[0].created_at);
      let currentDate = moment(curerntStatusLog[0].created_at);
      if (nextDate && currentDate) {
        return (
          <Moment from={nextDate} ago>
            {currentDate}
          </Moment>
        );
      }
    } else if (
      curerntStatusLog &&
      curerntStatusLog[0] &&
      (nextStatusLog === null || nextStatusLog.length === 0)
    ) {
      //difference of last status from now
      let currentDate = new Date(curerntStatusLog[0].created_at);
      if (currentDate) {
        let nowDate = new Date();
        return (
          <Moment from={nowDate} ago>
            {currentDate}
          </Moment>
        );
      }
    } else if (
      curerntStatusLog.length === 0 &&
      nextStatusLog &&
      nextStatusLog[0]
    ) {
      return " 0 min ";
    }
  }
  return " 0 min ";
};

export const getConvertedCurrency = currency => {
  return Math.abs(Number(currency)) >= 1.0e9
    ? (Math.abs(Number(currency)) / 1.0e9).toFixed(2) + " B"
    : Math.abs(Number(currency)) >= 1.0e6
    ? (Math.abs(Number(currency)) / 1.0e6).toFixed(2) + " M"
    : // : Math.abs(Number(currency)) >= 1.0e+3 ?
      //     Math.abs(Number(currency)) / 1.0e+3 + ' Thousand'
      Math.abs(Number(currency)).toFixed(2);
};

export const setStorageItem = (itemKey, itemValue) => {
  // return localStorage.setItem(itemKey, window.btoa(itemValue));
  return localStorage.setItem(itemKey, CryptoJS.AES.encrypt(JSON.stringify(itemValue), process.env.REACT_APP_CRYPTO_KEY_LOCAL_STORAGE).toString());
}

export const getStorageItem = (item) => {
  if (localStorage.getItem(item)) {
    const ciphertext = localStorage.getItem(item);
    
    const bytes = CryptoJS.AES.decrypt(ciphertext.toString(), process.env.REACT_APP_CRYPTO_KEY_LOCAL_STORAGE);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    // return window.atob(localStorage.getItem(item));
    return decryptedData;
  }
  return localStorage.getItem(item);
}

export const isAdminUser = () => {
  const currentUser = JSON.parse(getStorageItem("user"));
  return currentUser && currentUser.license_type.actual_name === 'ADMINISTRATOR';
}

export const isParentOrgAdmin = () => {
  const currentUser = JSON.parse(getStorageItem("user"));
  return currentUser && currentUser.is_parent && isAdminUser();
};

export const fileNameFromUrl = (url) => {
  if (url !== "") {
    const strArray = url.split('/');
    return strArray[strArray.length - 1];
  }
};
export function FindString(collection , search , keys = []) {
  const result = _.filter(collection, function (item) {
      if (keys.length === 0) {
          keys = Object.keys(item);
      }
      for (const key of keys) {
          if(typeof item[key] === "string") {
            if(item[key].toLowerCase().indexOf(search.toLowerCase()) > -1)
                return true;
          }else{
            if(item[key]==null) continue;
              if(item[key].toString().toLowerCase().indexOf(search.toLowerCase()) > -1)
              return true;
          }
      }
      return false;
    });

  return result;
}

export const setUser = (organization_id, is_parent) => {
  const currentUser = JSON.parse(getStorageItem("user"));
  const newUser = { ...currentUser, organization_id, is_parent };
  console.log(newUser, "New User");
  setStorageItem('user', JSON.stringify(newUser));
}


export function FindColumnInDateRange(collection , from , to , columnName) {
  function dateCheck(check) {

      var fDate,lDate,cDate;
      fDate = Date.parse(from);
      lDate = Date.parse(to);
      cDate = Date.parse(check);
  
      if((cDate <= lDate && cDate >= fDate)) {
          return true;
      }
      return false;
  }
  
  const result = _.filter(collection, function (item) {
      return dateCheck(item[columnName])
  });

  return result;
}

export default {
  getTimeDifference,
  getConvertedCurrency
};
