import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import CheckIcon from "@material-ui/icons/Check";
import "./module.scss";

function getSteps() {
  return ["Configure CRM Details", "Configure Data Objects Mapping", "Configure Status Mapping", "Assign Sales Manager of Super Office", "Data Synchronization Scheduler"];
}

function Circle({ completed, active, icon }) {
  const color = active || completed ? "#18C080" : "gray";
  const border = `3px solid ${color}`;

  const getBackgrounColor = () => {
    if (completed) return "#18C080";
    if (active) return "#D7F4EB";
    return "transparent";
  };

  const size = 36;

  return (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: size / 2,
        border,
        marginTop: -5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: getBackgrounColor(),
      }}
    >
      {completed ? (
        <CheckIcon style={{color: 'white'}} />
      ) : (
        <span style={{ color }} className="circle_text">
          {icon}
        </span>
      )}
    </div>
  );
}

export default function PlayGround() {
  const [activeStep, setActiveStep] = React.useState(3);
  const steps = getSteps();

  return (
    <div style={{ padding: 80 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label) => (
          <Step color="red" key={label}>
            <StepLabel StepIconComponent={Circle}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
