import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./ConfigureDataObjsBrregComponent.module.scss";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import FieldsMappingCRMContainer from "../../FieldsMappingCRM/FieldsMappingCRMContainer";
import axios from "../../../common/api-configCRM";
import { openSnackbar } from "../../../common/Snackbar/snackbar";
import SmallLoader from "../../../common/Loaders/CircularLoader/circular-loader";
import OopsError from "../../../components/ErrorMessages/OopsError/OopsError";
import WizardError from "../../../components/ErrorMessages/WizardError/WizardError";
import NoRecordsFound from "../../../components/ErrorMessages/NoRecordsFound/NoRecordsFound";
import { withRouter } from "react-router-dom";
import cssStyles from "./c.css";
import StatusMappingCRMContainer from '../../StatusMappingCRM/StatusMappingCRMContainer';
import { Hidden } from "@material-ui/core";
import ReactDOM from 'react-dom';

const stylesMaterialUI = theme => ({
  button: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    marginLeft: 0,
    marginRight: 0
  }
});

class ConfigureDataObjsBrregComponent extends Component {
  state = {
    CRMClicked: false,
    whichCRM: "",
    goBack: false,
    viewMapping: false,
    viewStatusMapping: false,
    /* idcrm_master: 1,
    crm_name: "SalesForce",
    idcrm_integration: "", */
    loadingObjectMapping: true,
    errorLoadingObjectMapping: false,
    selectedObjectMapping: [],
    loadingEntitiesDetails: true,
    errorLoadingEntitiesDetails: false,
    objectMapping: [],
    origObjectMapping: [],
    statusMapping: [],
    errorLoadingStatusMappingDetails: false,
    loadingStatusMappingDetails: true,
    errorInWizard: false,
    groupbased_objectMapping: [],
    isCRM_leadx_sync: true,
    isLeadx_CRM_sync: false
  };

  componentDidMount() {
    //console.log("props in did mount data objs", this.props);
    /* console.log("integrationDetails in step2", this.props.history.location.state.integrationDetails);
    this.setState({ integrationDetails: this.props.history.location.state.integrationDetails }); */

    /* if (this.props.history.location.state !== undefined) {
      console.log("idcrm_integration in step2", this.props.history.location.state.idcrm_integration);
      this.setState({
        idcrm_integration: this.props.history.location.state.idcrm_integration,
        crm_name: this.props.history.location.state.crm_name,
        idcrm_master: this.props.history.location.state.idcrm_master,
        isActive: this.props.history.location.state.isActive,
        configured: this.props.history.location.state.configured
      }, () => { */
    if (this.props.idcrm_master !== undefined &&
      this.props.idcrm_master !== null &&
      this.props.idcrm_master !== "" &&
      this.props.idcrm_integration !== undefined &&
      this.props.idcrm_integration !== null &&
      this.props.idcrm_integration !== "") {
      /* if (this.state.idcrm_integration !== undefined &&
        this.state.idcrm_integration !== "") { */
      axios.get(`/entities/get/${this.props.idcrm_integration}`)
        .then(response => {
          //console.log("response entities", response);
          if (response !== undefined &&
            response.data !== undefined &&
            response.data.status !== undefined &&
            response.data.status === 0) {
            if (response.data.data !== undefined) {
              if (response.data.data.length > 0) {
                this.setState({
                  origObjectMapping: response.data.data
                }, () => {
                  //let s = JSON.stringify(this.state.objectMapping);
                  //console.log("original response", response.data.data);
                  //create different array
                  let group_objMappingArray = [];
                  let group_nameArray = [];
                  if (this.state.origObjectMapping !== undefined &&
                    this.state.origObjectMapping.length > 0) {
                    this.state.origObjectMapping.forEach((item, index) => {
                      let i = group_nameArray.findIndex(x => x.group_name == item.group_name);
                      if (i <= -1) {
                        group_nameArray.push({ id: index, group_name: item.group_name });
                      }
                    });
                    //console.log("group_name_array", group_nameArray);
                    if (group_nameArray !== undefined &&
                      group_nameArray.length > 0) {
                      group_nameArray.forEach((groupItem) => {
                        let objMapping = [];
                        let selected_Id = "";
                        let copyOrigObjectMapping = [...this.state.origObjectMapping];
                        objMapping = copyOrigObjectMapping.filter((item) => {
                          //console.log("i", item);
                          return item.group_name === groupItem.group_name
                        });
                        //console.log("group name item", objMapping);
                        if (objMapping !== undefined) {
                          let copyObjMapping = [...objMapping];
                          const selectedObj = copyObjMapping.filter((objMap) => {
                            //console.log("gggg", objMap);
                            return objMap.leadxtocrm !== 0 && objMap.crmtoleadx !== 0
                          });
                          //console.log("selectedObj", selectedObj);
                          if (selectedObj !== undefined &&
                            selectedObj.length > 0) {
                            selected_Id = selectedObj[0].idcrm_entity_master;
                          } else {
                            selected_Id = objMapping[0].idcrm_entity_master;
                          }
                          //console.log("selected id", selected_Id);
                          group_objMappingArray.push({
                            selected_idcrm_entity_master: selected_Id,
                            group_name: groupItem.group_name,
                            objectMapping: objMapping
                          });
                          //console.log("group array", group_objMappingArray);
                        }
                      })
                    }
                  }
                  //console.log("grp array final", group_objMappingArray);
                  if (group_objMappingArray !== undefined &&
                    group_objMappingArray.length > 0) {
                    this.setState({
                      groupbased_objectMapping: group_objMappingArray,
                      errorLoadingEntitiesDetails: false,
                      loadingEntitiesDetails: false
                    }, () => {
                      //console.log("FinalFinal", this.state.groupbased_objectMapping);
                    });
                  }

                });
              }
              /* this.setState({
                errorLoadingEntitiesDetails: false,
                loadingEntitiesDetails: false
              }); */
              //console.log("FinalFinal", this.state.groupbased_objectMapping);
              //console.log("entitiesDetails ", this.state.objectMapping);
              //console.log("entitiesDetails ", this.state.loadingEntitiesDetails);
            } else {
              openSnackbar("Entities Data Not Found.", "snack-error");
              this.setState({
                loadingEntitiesDetails: false,
                errorLoadingEntitiesDetails: true,
              });
            }
          } else {
            openSnackbar("Exception Getting Entities Data", "snack-error");
            this.setState({
              loadingEntitiesDetails: false,
              errorLoadingEntitiesDetails: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
      /* } else {
        openSnackbar("Something Went Wrong", "snack-error");
      } */
      /* }); */

      axios.get(`/crm/statusmapping/get/${this.props.idcrm_master}`)
        .then(response => {
          //console.log("response status mapping", response);
          if (response !== undefined &&
            response.data !== undefined &&
            response.data.status !== undefined &&
            response.data.status === 0) {
            if (response.data.data !== undefined) {
              if (response.data.data.length > 0) {
                this.setState({
                  statusMapping: response.data.data
                });
              }
              this.setState({
                errorLoadingStatusMappingDetails: false,
                loadingStatusMappingDetails: false
              });
              //console.log("statusMapDetails ", this.state.statusMapping);
              //console.log("statusMapDetails ", this.state.loadingStatusMappingDetails);
            } else {
              openSnackbar("Status Mapping Data Not Found.", "snack-error");
              this.setState({
                errorLoadingStatusMappingDetails: false,
                loadingStatusMappingDetails: true,
              });
            }
          } else {
            openSnackbar("Exception Getting Status Mapping Information", "snack-error");
            this.setState({
              errorLoadingStatusMappingDetails: false,
              loadingStatusMappingDetails: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      /* openSnackbar("Error occured. Try Again", "snack-error"); */
      this.setState({
        errorInWizard: true
      });
    }
  }

  handleSaveNProceedClicked = (e) => {
    //console.log("configuration for CRM ", e);
    this.setState({ CRMClicked: true, whichCRM: e });
    if (this.checkValidity() === true) {
      let params = null;
      /* if (this.state.integrationsDetails !== undefined) {
        if (this.state.integrationsDetails.idcrm_integration !== "") { */
      /* params format -
       data ={
        "integration_id" : "1",
        "data_rows" : [
        {
        "fk_integration_id":"1", 
        "fk_entity":"1", 
        "leadxtocrm":"1", 
        "crmtoleadx":"0"
        },
        {
        "fk_integration_id":"1", 
        "fk_entity":"2", 
        "leadxtocrm":"1", 
        "crmtoleadx":"1"
        }]
        } */
      let data_rows = [];
      if (this.state.objectMapping !== undefined && this.state.objectMapping.length > 0) {
        this.state.objectMapping.map(objMap => {
          //console.log("objMap ", objMap);
          let obj = {
            fk_integration_id: this.props.idcrm_integration,
            fk_entity: objMap.idcrm_entity_master,
            leadxtocrm: objMap.leadxtocrm,
            crmtoleadx: objMap.crmtoleadx
          }
          data_rows.push(obj);
        });
        //console.log("data_rows", data_rows);
      };
      params = {
        integration_id: this.props.idcrm_integration,
        data_rows: data_rows
      }
      //console.log("params", params);
      axios
        .post('/integration_direction/add', { data: JSON.stringify(params) })
        .then(response => {
          //console.log("response in savenproceed", response);
          if (response !== undefined && response.data.status !== undefined &&
            response.data.status === 0) {
            openSnackbar("Saved!", "snack-success");
            // check if ok to proceed even if there is error saving data
            this.props.history.push({
              pathname: '/integrations/assignsalesteamCRM',
              state: {
                /*  idcrm_master: this.state.idcrm_master,
                 idcrm_integration: this.state.idcrm_integration,
                 isActive: this.state.isActive,
                 configured: this.state.configured
                 */ //integrationDetails: this.state.integrationDetails
              }
            });
          } else {
            openSnackbar("Something Went Wrong!", "snack-error");
          }
        })
        .catch((error) => {
          console.log(error);
          openSnackbar("Exception Saving Integration Direction Data", "snack-error");
        })

      /*  // check if ok to proceed even if there is error saving data
       this.props.history.push({
         pathname: '/integrations/assignsalesteamCRM',
         state: {
           idcrm_master: this.state.idcrm_master,
           idcrm_integration: this.state.idcrm_integration
           //integrationDetails: this.state.integrationDetails
         }
       }); */
    } else {
      openSnackbar("Please Select Mapping", "snack-error");
    }
  }

  handleGoBackClicked = () => {
    //console.log("go back clicked");
    this.setState({ goBack: true });
    this.props.history.goBack();
  }

  handleViewMappingClicked = (e) => {
    //console.log("Mapping for ", e);
    const objectMapsForEntity = [...this.state.origObjectMapping];
    const objectMap = objectMapsForEntity.filter(objMap => {
      if (objMap.idcrm_entity_master === e) {
        return true;
      }
    });
    //console.log("obj maps for selected e", objectMap);
    if (objectMap !== undefined && objectMap.length > 0) {
      this.setState({
        selectedObjectMapping: objectMap[0],
        viewMapping: true
      });
      /* if (objectMap[0].crmtoleadx === 1 || objectMap[0].leadxtocrm === 1) {
        this.setState({
          selectedObjectMapping: objectMap[0],
          viewMapping: true
        });
      } else {
        this.setState({
          selectedObjectMapping: [],
          viewMapping: false
        });
      } */
    }
  }

  handleCloseViewMapping = value => {
    //console.log("close dialog", value);

    this.setState({
      viewMapping: false,
      // updateActivity: null,
      // updateTask: null
    });
    // this.removeClasses();
  };

  handleMappingCheckboxClick = (objMap, objMapCheckbox, whichCheckbox, selectedIdCRMMaster, i) => {
    //const objectMapArray = objectMapArr;
    const selectedId = selectedIdCRMMaster;
    const ref = i;
    // console.log("selectedId handleMappingCheckbox", selectedId);
    // console.log("objMapCheckbox", objMapCheckbox);
    // console.log("whichCheckbox", whichCheckbox);
    let checkedValue = objMapCheckbox;
    if (objMapCheckbox === 0) {
      checkedValue = 1;
    } else if (objMapCheckbox === 1) {
      checkedValue = 0;
    }
    // console.log("checked checked", objMapCheckbox);
    // console.log(objMap);
    // console.log("selectedId handleMappingCheckbox", selectedId);
    for (let index = 0; index < this.state.groupbased_objectMapping.length; index++) {
      const objArray = this.state.groupbased_objectMapping[index];
      // console.log("objArray", objArray.selected_idcrm_entity_master);
      // console.log("objArray1", selectedId);
      if (objArray.selected_idcrm_entity_master == selectedId) {
        for (let index1 = 0; index1 < objArray.objectMapping.length; index1++) {
          const obj = objArray.objectMapping[index1];
          // console.log("obj", obj);
          // console.log("obj", objMap.idcrm_entity_master);
          if (obj.idcrm_entity_master === objMap.idcrm_entity_master &&
            obj.idcrm_entity_master == selectedId) {
            if (whichCheckbox === "leadxtocrm") {
              // console.log("leadxtocrm");
              obj.leadxtocrm = checkedValue;
            } else {
              // console.log("crmtoleadx");
              obj.crmtoleadx = checkedValue;
            }

            // console.log("changed objmap", obj);
          } else {
            if (objMap.idcrm_entity_master !== selectedId) {
              openSnackbar("Option Not Selected", "snack-error");
              obj.crmtoleadx = 0;
              obj.leadxtocrm = 0;
              // console.log("ref", ref);
              // console.log("refs", this.refs);
              // console.log("refref", this.refs[ref]);
              ReactDOM.findDOMNode(this.refs[ref]).checked = false; // !this.refs[ref].checked;
            }
          }
        }
      }
    }
    //console.log("handlemapping checkbox", this.state.groupbased_objectMapping);
  }

  handleSaveClicked = () => {
    if (this.checkValidity() === true) {
      let params = null;
      /*  if (this.state.integrationsDetails !== undefined) {
         if (this.state.integrationsDetails.idcrm_integration !== "") { */
      let data_rows = [];
      //console.log("object mapping in save", this.state.objectMapping);
      if (this.state.objectMapping !== undefined && this.state.objectMapping.length > 0) {
        for (let index = 0; index < this.state.objectMapping.length; index++) {
          const objMap = this.state.objectMapping[index];
          let obj = {
            fk_integration_id: this.props.idcrm_integration,
            fk_entity: objMap.idcrm_entity_master,
            leadxtocrm: objMap.leadxtocrm,
            crmtoleadx: objMap.crmtoleadx
          }
          data_rows.push(obj);
        }
        //console.log("data_rows", data_rows);
      };
      params = {
        integration_id: this.props.idcrm_integration,
        data_rows: data_rows
      }
      //console.log("params", params);
      axios
        .post('/integration_direction/add', { data: JSON.stringify(params) })
        .then(response => {
          //console.log("response in savenproceed", response);
          if (response !== undefined && response.data.status !== undefined &&
            response.data.status === 0) {
            openSnackbar("Saved!", "snack-success");
          } else {
            openSnackbar("Something Went Wrong!", "snack-error");
          }
        })
        .catch((error) => {
          console.log(error);
          openSnackbar("Unable to save Integration Direction Information", "snack-error");
        })
    } else {
      openSnackbar("Please Select Mapping", "snack-error");
    }
    /* }
    } else {
     console.log("Something went wrong");
     openSnackbar("Something Went Wrong", "snack-error");
   } */
  }

  checkValidity() {
    //console.log("maps", this.state.groupbased_objectMapping);
    if (this.state.groupbased_objectMapping !== undefined &&
      this.state.groupbased_objectMapping.length > 0) {
      //console.log("in1");
      //generate final array
      let finalObjectMappingArray = [];
      for (let index = 0; index < this.state.groupbased_objectMapping.length; index++) {
        const element = this.state.groupbased_objectMapping[index];
        //console.log("objMap", element.objectMapping);
        for (let index = 0; index < element.objectMapping.length; index++) {
          const elementObj = element.objectMapping[index];
          // console.log("selected", element.selected_idcrm_entity_master);
          // console.log("selected1", elementObj.idcrm_entity_master);
          if (element.selected_idcrm_entity_master === elementObj.idcrm_entity_master) {
            // console.log("inside");
            finalObjectMappingArray.push(elementObj);
          } else {
            // console.log("element in else", elementObj);
            elementObj.leadxtocrm = 0;
            elementObj.crmtoleadx = 0;
            // console.log("element in else", elementObj);
            finalObjectMappingArray.push(elementObj);
            // console.log("final arrayrrrrr", finalObjectMappingArray);
          }
        }
      }
      // console.log("final final", finalObjectMappingArray);
      this.state.objectMapping = finalObjectMappingArray;
      // console.log("final final", this.state.objectMapping);
      /*  this.setState({ objectMapping: finalObjectMappingArray }, () => {console.log("objectmapping final", this.state.objectMapping)}); */

      //generate final array
      let result = finalObjectMappingArray.find((objMap) => objMap.leadxtocrm === 1 || objMap.crmtoleadx === 1);
      // console.log("result result objmap", result);
      if (result === undefined) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  handleObjectSelected = (event, objMap, selectedIdCRMMaster, i) => {
    // const objectArray = objectArr;
    const selectedId = selectedIdCRMMaster;
    const ref = i;
    // console.log("objMap", objMap);
    // console.log("id selected", selectedId);
    const value = event.target.value;
    // console.log("value", value);
    const salesTeamAssignment = [
      ...this.state.groupbased_objectMapping
    ];
    salesTeamAssignment.map(obj => {
      if (obj.selected_idcrm_entity_master === selectedId) {
        const numValue = parseInt(value);
        if (numValue !== undefined && numValue > 0) {
          obj.selected_idcrm_entity_master = numValue;
          obj.objectMapping.map(obj => {
            //make other mapping value false
            if (obj.idcrm_entity_master !== value) {
              obj.leadxtocrm = 0;
              obj.crmtoleadx = 0;

              //console.log("1", "refleadx_" + selectedId);
              //console.log("1", "refcrm_" + selectedId);
              //console.log(this.refs[ref]);
              if (this.refs !== undefined && this.refs !== null &&
                this.refs["refleadx_" + selectedId] !== undefined && this.refs["refleadx_" + selectedId] !== null &&
                this.refs["refcrm_" + selectedId] !== undefined && this.refs["refcrm_" + selectedId] !== null) {
                ReactDOM.findDOMNode(this.refs["refleadx_" + selectedId]).checked = false;
                ReactDOM.findDOMNode(this.refs["refcrm_" + selectedId]).checked = false;
              }
            }
          })
        }
      }
    });
    //console.log("item from sleecsdfsdfsfds", salesTeamAssignment);
    if (salesTeamAssignment !== undefined &&
      salesTeamAssignment.length > 0) {
      this.setState({ groupbased_objectMapping: salesTeamAssignment }, () => {
        //console.log("group changedcyeeeyyeye", this.state.groupbased_objectMapping);
      });
    }
    //console.log("item from sleecsdfsdfsfds", this.state.groupbased_objectMapping);
  }

  handleViewStatusMappingClicked = () => {
    // console.log("status clicked");
    // console.log("Mapping for id", this.props.idcrm_master);
    if (this.state.statusMapping !== undefined &&
      this.state.statusMapping.length > 0) {
      this.setState({ viewStatusMapping: true });
    }
  }

  handleCloseStatusViewMapping = value => {
    // console.log("close dialog", value);
    this.setState({
      viewStatusMapping: false,
    });
  };

  handleChatterCheckboxClick = (objMap) => {
    // console.log("chkbox clicked", objMap);
  }

  handleDoneNReadyClicked = (e) => {
    //Save
    if (this.checkValidity() === true) {
      let params = null;
      /*  if (this.state.integrationsDetails !== undefined) {
         if (this.state.integrationsDetails.idcrm_integration !== "") { */
      let data_rows = [];
      // console.log("object mapping in save", this.state.objectMapping);
      if (this.state.objectMapping !== undefined && this.state.objectMapping.length > 0) {
        for (let index = 0; index < this.state.objectMapping.length; index++) {
          const objMap = this.state.objectMapping[index];
          let obj = {
            fk_integration_id: this.props.idcrm_integration,
            fk_entity: objMap.idcrm_entity_master,
            leadxtocrm: objMap.leadxtocrm,
            crmtoleadx: objMap.crmtoleadx
          }
          data_rows.push(obj);
        }
        // console.log("data_rows", data_rows);
      };
      params = {
        integration_id: this.props.idcrm_integration,
        data_rows: data_rows
      }
      // console.log("params", params);
      axios
        .post('/integration_direction/add', { data: JSON.stringify(params) })
        .then(response => {
          // console.log("response in savenproceed", response);
          if (response !== undefined && response.data.status !== undefined &&
            response.data.status === 0) {
            openSnackbar("Brreg Configured Successfully", "snack-success");
            this.props.history.push({
              pathname: '/integrations'
            });
          } else {
            openSnackbar("Something Went Wrong!", "snack-error");
          }
        })
        .catch((error) => {
          console.log(error);
          openSnackbar("Unable to save Integration Direction Information", "snack-error");
        })
    } else {
      openSnackbar("Please Select Mapping", "snack-error");
    }
    //Save
  }

  render() {
    const style = {
      border: 'none !important',
      marginBottom: '0',
      marginLeft: '0',
      marginRight: '0',
      borderStyle: 'hidden',
      padding: '0'
    }
    //console.log("group array", this.state.groupbased_objectMapping);
    return (

      <React.Fragment>
        <div className={styles.main_wrapper}>
          <div className="row">
            <div className="col-12">

              <div className={"breadcrumbs row " + styles.centerAlign}>
                <div className="col-md-6 col-12">
                  <h2 className="heading">Integrations</h2>
                  <div className="breadcrumbs_container">
                    <Breadcrumbs separator="›" arial-label="Breadcrumb">
                      {/* <Link to="/sales-news">Home</Link>
                      <Link to="/integrations">Integrations</Link>
                      <Link to="/integrations/configureCRM">Configure CRM</Link> */}
                      {/* <Link to="/integrations/authenticateCRM">Authenticate</Link> */}
                      <span>Home</span>
                      <span>Integrations</span>
                      {/* <span>Configure Brreg</span> */}
                      <span>Configure Data Object Mappings</span>
                    </Breadcrumbs>
                  </div>
                </div>
              </div>
              <div className="row container-body">
                <div className={`col-xs-12 col-md-12 col-lg-12 col-12`}>

                  <div className={styles.configureDataObjsBrreg_container}>
                    {this.state.errorInWizard ? <div><WizardError/></div> :
                      <div className={styles.configureDataObjsBrreg_container_header}>
                        <h1 className={styles.configureDataObjsBrreg_container_heading}>
                          Step 2 - Configure Data Objects Mapping
                      </h1>
                      </div>
                    }
                    <div className={styles.configureDataObjsBrreg_container_footer} >
                      {/* start */}
                      {this.state.errorLoadingEntitiesDetails ?
                        (<div><OopsError /></div>) :
                        this.state.loadingEntitiesDetails ? (<SmallLoader />) :
                          this.state.groupbased_objectMapping.length === 0 ?
                            (<div><NoRecordsFound /></div>) :
                            (<div>
                              <div className="row">
                                <div className="col-12">
                                  <div className="bg-white ml-4 mr-4">
                                    <table className="table table-striped- table-bordered mt-4 mb-4">
                                      <thead>
                                        <tr>
                                          {/*  <td>
                                            <> */}
                                          <th style={{ width: '20%', textAlign: 'center' }}>LeadX Data Objects</th>
                                          {/* <th style={{ width: '30%', textAlign: 'center' }}>Mapping</th> */}
                                          <th style={{ width: '20%', textAlign: 'center' }}>{this.props.crm_name} Data Objects</th>
                                          <th style={{ width: '20%', textAlign: 'center' }} />
                                          {/* </>
                                          </td>*/}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.groupbased_objectMapping === undefined ?
                                          <div><OopsError /></div>
                                          :
                                          (this.state.groupbased_objectMapping.map((objectMapArray, index) =>
                                            (objectMapArray !== undefined ?
                                              (<tr key={index}>
                                                {objectMapArray.objectMapping !== undefined &&
                                                  objectMapArray.objectMapping.length > 1 ?

                                                  (
                                                    <td colSpan="4" style={{ padding: '0' }}>
                                                      <table className="table table-striped-" style={style}>
                                                        {objectMapArray.objectMapping.map((objMap, index) =>
                                                          (<tbody key={index}>
                                                            <tr style={{ 'border': 'none !important', 'borderStyle': 'hidden' }}>
                                                              <td className="borderless" style={{ width: '20%' }}>
                                                                <label>
                                                                  <input ref={'refradio_' + objMap.idcrm_entity_master} name="objects_radio" type="radio" value={objMap.idcrm_entity_master} onChange={(event) => this.handleObjectSelected(event, objMap, objectMapArray.selected_idcrm_entity_master, 'refradio_' + objMap.idcrm_entity_master)} checked={objectMapArray.selected_idcrm_entity_master == objMap.idcrm_entity_master} />
                                                                  &nbsp;&nbsp;&nbsp;
                                                                  <strong>
                                                                    {objMap.leadx_entity}
                                                                  </strong>
                                                                </label>
                                                              </td>
                                                              {/* <td style={{ width: '30%', textAlign: 'center' }}>
                                                                <label className="switch">
                                                                  <input ref={'refleadx_' + objMap.idcrm_entity_master} type="checkbox" defaultChecked={objMap.leadxtocrm == true} onChange={() => this.handleMappingCheckboxClick(objMap, objMap.leadxtocrm, "leadxtocrm", objectMapArray.selected_idcrm_entity_master, 'refleadx_' + objMap.idcrm_entity_master)} /><span className="slider round" />
                                                                </label>&nbsp;&nbsp;<strong>LeadX to Brreg</strong>
                                                                <br />
                                                               
                                                                <label className="switch">
                                                                  <input ref={'refcrm_' + objMap.idcrm_entity_master} type="checkbox" defaultChecked={objMap.crmtoleadx == true} onChange={() => this.handleMappingCheckboxClick(objMap, objMap.crmtoleadx, "crmtoleadx", objectMapArray.selected_idcrm_entity_master, 'refcrm_' + objMap.idcrm_entity_master)} /><span className="slider round" />
                                                                </label>&nbsp;&nbsp;<strong>Brreg to LeadX</strong>
                                                              </td> */}
                                                              <td style={{ width: '20%', textAlign: 'center'  }}>
                                                                <strong>
                                                                  {objMap.crm_entity}
                                                                </strong>
                                                              </td>
                                                              <td style={{ width: '20%', textAlign: 'center' }}>
                                                                <button onClick={() => this.handleViewMappingClicked(objMap.idcrm_entity_master)} className="btn btn-outline-secondary btn-sm" type="button" style={{ padding: '5px 20px' }}>View Mapping</button>
                                                              </td>
                                                            </tr>
                                                            {/* {objMap.leadx_entity.toLowerCase() == "lead" ?
                                                              <tr style={{ backgroundColor: '#f7f7f7', textAlign: 'center' }}>
                                                                <td colSpan="4">
                                                                  <label>
                                                                    <input type="checkbox" defaultChecked />
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;Map to Chatter
                                                                  </label>
                                                                </td>
                                                              </tr> :
                                                              <></>
                                                            } */}

                                                          </tbody>)
                                                        )}
                                                      </table>
                                                    </td>)

                                                  :

                                                  (objectMapArray.objectMapping.map(objMap =>
                                                    <>
                                                      <td>
                                                        <strong>
                                                          {objMap.leadx_entity}
                                                        </strong>
                                                      </td>
                                                      {/* <td style={{ width: '30%', textAlign: 'center' }}>
                                                        <label className="switch">
                                                          <input type="checkbox" defaultChecked={objMap.leadxtocrm} onChange={() => this.handleMappingCheckboxClick(objMap, objMap.leadxtocrm, "leadxtocrm", objectMapArray.selected_idcrm_entity_master)} /><span className="slider round" />
                                                        </label>&nbsp;&nbsp;<strong>LeadX to Brreg</strong>
                                                        <br />
                                                        
                                                        <label className="switch">
                                                          <input type="checkbox" defaultChecked={objMap.crmtoleadx} onChange={() => this.handleMappingCheckboxClick(objMap, objMap.crmtoleadx, "crmtoleadx", objectMapArray.selected_idcrm_entity_master)} /><span className="slider round" />
                                                        </label>&nbsp;&nbsp;<strong>Brreg to LeadX</strong>
                                                      </td> */}
                                                      <td>
                                                        <strong>
                                                          {objMap.crm_entity}
                                                        </strong>
                                                      </td>
                                                      <td style={{ width: '20%', textAlign: 'center' }}>
                                                        <button onClick={() => this.handleViewMappingClicked(objMap.idcrm_entity_master)} className="btn btn-outline-secondary btn-sm" type="button" style={{ padding: '5px 20px' }}>View Mapping</button>
                                                      </td>
                                                    </>
                                                  ))

                                                }
                                              </tr>)
                                              :
                                              <></>)
                                          )
                                          )
                                        }
                                      </tbody>
                                    </table>
                                    <hr className="jss201" />
                                    <div className="mt-3 mb-5">
                                      <div style={{ textAlign: 'right' }}>
                                        <button onClick={this.handleGoBackClicked} className={styles.profileContainer_update_btn__MXNDH} type="button" style={{ backgroundColor: '#e0e0e0', padding: '10px 20px', color: 'rgba(0, 0, 0, 0.87)' }}>Go Back</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                        {/* <button onClick={() => this.handleSaveClicked("step2")} type="button" className={styles.profileContainer_update_btn__MXNDH} style={{ padding: '10px 20px' }}>Save</button>&nbsp;&nbsp;&nbsp;&nbsp; */}
                                        {this.props.idcrm_master == "2" ?
                                          <button onClick={() => this.handleDoneNReadyClicked("step4")} type="button" className={styles.profileContainer_update_btn__MXNDH} style={{ padding: '10px 20px', backgroundColor: '#19d98c' }}>Done and Ready to Go!</button>
                                          :
                                          <button onClick={() => this.handleSaveNProceedClicked("step2")} type="button" className={styles.profileContainer_update_btn__MXNDH} style={{ padding: '10px 20px' }}>Save &amp; Proceed</button>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>)
                      }

                      {/* end */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Dialog
            className="width"
            aria-labelledby="customized-add-activities"
            open={this.state.viewMapping ? this.state.viewMapping : false}
            onClose={this.handleCloseViewMapping}
            maxWidth={"lg"}
          >
            <FieldsMappingCRMContainer
              close={this.handleCloseViewMapping}
              fieldMapping={this.state.selectedObjectMapping}
              isCRM_leadx_sync={this.state.isCRM_leadx_sync}
              isLeadx_CRM_sync={this.state.isLeadx_CRM_sync}
            // crm_name={this.state.objectMapping[0].crm_name}
            /* updateActivity={this.state.updateActivity}
            lead_id={
              this.state.updateActivity === null
                ? null
                : this.state.updateActivity.lead_id 
            }*/
            />
          </Dialog>
          <Dialog
            className="width"
            aria-labelledby="customized-add-activities"
            open={this.state.viewStatusMapping ? this.state.viewStatusMapping : false}
            onClose={this.handleCloseStatusViewMapping}
            maxWidth={"lg"}
          >
            <StatusMappingCRMContainer
              close={this.handleCloseStatusViewMapping}
              fieldMapping={this.state.statusMapping}
            />
          </Dialog>
        </div>
        {/*  } */}
      </React.Fragment >
    );
  }
}

export default withStyles(stylesMaterialUI)(withRouter(ConfigureDataObjsBrregComponent));