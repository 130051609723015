import API from './api'
import React, { useEffect, useState, useRef } from 'react';
import LargeScreenLoader from "../../common/Loaders/PulseLoader/pulse-loader";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link, Redirect } from "react-router-dom";
import styles from './user_profile.module.scss';
import styled from 'styled-components';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import grey from '@material-ui/core/colors/grey';
import Switch from "react-switch";
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import Input from "@material-ui/core/Input";
import { showSnackbarMessage } from '../../common/common-functions';
import moment from 'moment';
import { commonValidation_regex } from "../../common/common-types.js";
import imageCompression from 'browser-image-compression';
import { openSnackbar } from "../../common/Snackbar/snackbar";
import CropperDialog from "../../common/CropperDialog/CropperDialog";
import InviteUser from '../../common/InviteUser'; 

const PrimaryButton = styled(Button)`
  font-size: 0.75em;
  text-transform: none;
  white-space: nowrap;
  text-align: center;
  background-color: #3a4860;
  padding-left: 2em;
  padding-right: 2em;
  color: white;
  height: 4.25em;
  padding: 0 0.5em;
  padding-left: 4em;
  padding-right: 4em;
  &:hover {
    background-color: #19D98B;
  }
`;

const SecondaryButton = styled(Button)`
  font-size: 0.75em;
  text-transform: none;
  white-space: nowrap;
  text-align: center;
  padding-left: 2em;
  padding-right: 2em;
  color: #3a4860;
  height: 4.25em;
  padding: 0 0.5em;
  padding-left: 4em;
  padding-right: 4em;
  font-weight: bold;
`;

const CustomCard = styled.div`
  padding: 0.8em;
  padding-top: 0.875em;
  border: 0.75px solid #D3D3D3;
  border-radius: 5px;
`;

const initialOptions = [{ value: 'Mr', label: 'Mr.' }, { value: 'Mrs', label: 'Mrs.' }, { value: 'Miss', label: 'Miss.' }];
const genderOptions = [{ value: 'male', label: 'Male' }, { value: 'female', label: 'Female' }];

export default function UserProfile(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [organizationName, setOrganizationName] = useState('vElement');
  const [roleOptions, setRoleOptions] = useState([]);
  const [licenseOptions, setLicenseOptions] = useState([]);
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [profile, setProfile] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const inputFile = useRef(null);
  const userId = props.match.params.id;
  //following are used for profile pic update, reference taken from profileContainer
  const [profilePicValidation, setProfilePicValidation] = useState(true);
  const [uri, seturi] = useState('');
  const [src, setImageSrc] = useState('');
  const [cropperDialog, setCropperDialog] = useState(false);
  const [file, setFileForImage] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    const result = await Promise.all([
      API.getRoles(),
      API.getLicenseTypes(),
      API.getCountries(),
      API.getUserProfile(props.match.params.id),
      API.getDepartments(),
      API.getMyAccount()
    ]);
    setIsLoading(false);
    setRoleOptions(result[0].map(r => ({ value: r.id, label: r.display_name })));
    setLicenseOptions(result[1].map(r => ({ value: r.id, label: r.display_name })));
    setCountriesOptions(result[2].map(c => ({ value: c.id, label: c.iso_code + " +" + c.dialing_code })));
    setProfile(result[3]);
    setDepartmentOptions(result[4].map(r => ({ value: r.id, label: r.name })));
    setOrganizationName(result[5]);
    setIsLoading(false);
  };

  //Generating MIME file type
  const remoteCallback = async (blob) => {
    let header = "";
    var fr = new FileReader();
    fr.onload = e => {
      var arr = new Uint8Array(e.target.result).subarray(0, 4);
      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      mimeType(header, blob);
    };
    fr.readAsArrayBuffer(blob);
  };

  const uploadProfile = (e) => {
    seturi(e.target.value);
    let file = e.target.files[0];
    console.log(file);
    e.target.value = "";
    remoteCallback(file);
  };

  //Checking MIME file type
  const mimeType = (headerString, blob) => {
    console.log(headerString);
    if (headerString.startsWith("ffd8ff")) {
      headerString = headerString.slice(0, 6);
      console.log(headerString);
    }
    setProfilePicValidation(true);
    switch (headerString) {
      case "89504e47":
      case "ffd8ff":
        checkUploadedFile(blob);
        break;
      default:
        // setProfilePicValidation(false);
        checkUploadedFile(blob);
        break;
    }
  };

  const checkUploadedFile = (file) => {
    console.log(file.type, profilePicValidation);
    if (
      profilePicValidation === true &&
      (file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg")
    ) {
      if (file.size < 10485760) {
        if (file.size > 2097152) {
          const options = {
            maxSizeMB: 2,
            maxWidthOrHeight: 1920
          }
          imageCompression(file, options)
            .then(compressFile => {
              settingImage(compressFile)
            })
            .catch(error => {
              seturi('');
              openSnackbar("Image size too large", "snack-error");
            })
        } else {
          settingImage(file);
        }
      } else {
        seturi('');
        openSnackbar("Image size should not more than 10 mb.", "snack-error");
      }
    } else {
      seturi('');
      openSnackbar("Invalid file format", "snack-error");
    }
  };

  const settingImage = async (file) => {
    const base64String = await getBase64(file);
    console.log(base64String);
    setImageSrc(base64String);
    setCropperDialog(true);
    setFileForImage(file);
  };

  const getBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const handleCloseCropDialog = () => {
    setCropperDialog(false);
    setImageSrc('');
    setFileForImage({});
    setProfilePicValidation(false);
  };

  // crop image button clicked
  const cropImage = async (val, cropper) => {
    if (val) {
      let params = {
        id: userId,
        user_avatar_old: profile.user_avtar,
        fileName: file.name,
        fileExtension: file.type,
        fileBody: cropper
          .getCroppedCanvas()
          .toDataURL(file.type),
        parentFolder: "users"
      };
      console.log(params);
      setCropperDialog(false);
      setIsLoading(true);
      const userProfilePicUrl = await API.updateProfileImage(params);
      console.log(userProfilePicUrl, "userProfilePicUrl");
      setProfile({
        ...profile,
        user_avatar: userProfilePicUrl
      });
      setFileForImage({});
      setImageSrc("");
      setProfilePicValidation(false);
      seturi("");
      setIsLoading(false);
      showSnackbarMessage('Profile picture updated successfully.', 'snack-success');
    }
    else {
      setFileForImage({});
      setImageSrc("");
      setCropperDialog(false);
      setProfilePicValidation(false);
      seturi("")
      return;
    }
  };

  const handleDropDownOnChange = (key) => (change) => {
    setProfile({
      ...profile,
      [key]: change.value
    });
  };

  const handleInputOnChange = (key) => (e) => {
    setProfile({
      ...profile,
      [key]: e.target.value
    });
  };

  const handleIsActiveOnChange = (is_active) => {
    setProfile({
      ...profile,
      is_active
    });
  };

  const isValidForm = () => {
    try {
      if (!profile.first_name) {
        throw new Error('First Name is required field.');
      }
      if (!commonValidation_regex.test(profile.first_name.trim())) {
        throw new Error('Please enter valid first name');
      }
      if (profile.middle_name && !commonValidation_regex.test(profile.middle_name && profile.middle_name.trim())) {
        throw new Error('Please enter valid middle name');
      }
      if (!profile.last_name) {
        throw new Error('Last Name is required field.');
      }
      if (!commonValidation_regex.test(profile.last_name.trim())) {
        throw new Error('Please enter valid last name');
      }
      if (!profile.country_code) {
        throw new Error('Country Code is required field.');
      }
      if (!profile.contact) {
        throw new Error('Contact is required field.');
      }
      if (!profile.gender) {
        throw new Error('Gender is required field.');
      }
      if (!profile.initials) {
        throw new Error('Prefix is required field.');
      }

      const phoneRegex = /^[0-9]{8,}$/;
      if (!phoneRegex.test(profile.contact.trim())) {
        throw new Error('Phone number should be minimum 8 digit long.');
      }
    } catch (e) {
      showSnackbarMessage(e.message, 'snack-error');
      return false;
    }

    return true;
  }

  const handleUpdateOnClick = async () => {
    if (isValidForm()) {
      setIsUpdating(true);
      const result = await API.updateProfile(profile);
      showSnackbarMessage(result.message, result.status ? 'snack-success' : 'snack-error');
      setIsUpdating(false);
    }
  }

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const onChangeFile = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    uploadProfile(event);
  };

  const renderPage = () => {
    if (!profile) return;

    return (<>
      <div className="main-wrapper">
        <div className="breadcrumbs">
          <div className="row justify-content-between">
            <div className="col-md-5">
              <h3 className={styles.title_Administration}>
                User Management
                </h3>
              <div className="breadcrumbs_container">
                <Breadcrumbs separator="›" arial-label="Breadcrumb">
                  <Link to="/sales-news">Home</Link>
                  <Link to="/administration">Administration</Link>
                  <Link to="/administration/user_management">User Management</Link>
                  <span>User Profile</span>
                </Breadcrumbs>
              </div>
            </div>
            <div className="col-md text-right">
              <div className="pt-3">
                <label className="font-weight-bold pointer">
                  {organizationName}
                </label>
                <span className="mx-2"></span>
              |
              <span className="mx-2"></span>
                <InviteUser />
                <span className="mx-2"></span>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row pb-3">
          <div className="col-xs-12 col-sm-12 col-lg-4">
            <div className={styles.containerCustom}>
              <img src={profile.user_avatar} className={styles.image+" img img-responsive"}
                style={{ height: "165px", width: "165px", borderRadius: '50%' }} />
              <div className={styles.middle}>
              <div className={styles.rounded} onClick={onButtonClick}>
                <CameraAltOutlinedIcon fontSize="small" style={{ color: grey[50], alignSelf: 'center' }}>
                </CameraAltOutlinedIcon>
                <input type="file" id='file' ref={inputFile} accept="image/*"
                  onChange={onChangeFile} style={{ display: 'none' }} />
              </div>
              </div>
            </div>
            <div className={styles.title_right_text}>
              Email ID
          </div>
            <div className={styles.card_view}>
              <CustomCard>
                {profile.email}
              </CustomCard>
            </div>

            {profile.AccountUserLicenses.length > 0 && <> <div className={styles.title_text}>
              License Info
            </div>
              <div className={styles.card_view}>
                <CustomCard>
                  <div className="row">
                    <div className="col-md-12 col-lg-12 col-12">
                      <div className={styles.title_text}>Organization Name</div>
                      <div>{profile.AccountUserLicenses[0].account.name}</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-lg-12 col-12">
                      <div className={styles.title_text}>Type</div>
                      <div>{licenseOptions.find(l => l.value === profile.AccountUserLicenses[0].license_type_id).label}</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-lg-12 col-12">
                      <div className={styles.title_text}>Status</div>
                      <div>{profile.AccountUserLicenses[0].active_license ? (
                        <span className={styles.status_active}>
                          Active
                        </span>
                      ) : (
                          <span className={styles.status_inactive}>
                            Inactive
                          </span>
                        )}</div>
                    </div>
                  </div>
                  <div className="row">
                    {profile.AccountUserLicenses[0].start_date && <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className={styles.title_text}>Start Date</div>
                      <div>{moment(profile.AccountUserLicenses[0].start_date, 'YYYY-MM-DD').format('MMM D, YYYY')}</div>
                    </div>}
                    {profile.AccountUserLicenses[0].end_date && <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className={styles.title_text}>End Date</div>
                      <div>{moment(profile.AccountUserLicenses[0].end_date, 'YYYY-MM-DD').format('MMM D, YYYY')}</div>
                    </div>}
                  </div>
                </CustomCard>
              </div></>}
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-8">
            <div className={styles.title_text} style={{ justifyContent: 'right' }}>
              <div className="row">
                <div className="col-md-9">
                  Organizational Information
              </div>
                <div className="col-md-3" style={{ textAlign: 'right' }}>
                  <Switch
                    onChange={handleIsActiveOnChange}
                    checked={profile.is_active}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onColor={"#8BDFBE"}
                    height={20}
                    width={40}
                    className={styles.toggle_button}
                  />
                </div>
              </div>
            </div>
            <div className={styles.card_view}>
              <CustomCard>
                <div className="row">
                  <div className="col-xs-6 col-sm-6 col-md-3">
                    <div className={styles.title_text}>
                      Title
                    </div>
                    <div style={{ marginTop: '1em' }}>
                      <Select
                        options={roleOptions}
                        placeholder="Select Title"
                        value={roleOptions.find(r => r.value === profile.role_id)}
                        onChange={handleDropDownOnChange('role_id')}
                      />
                    </div>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-3">
                    <div className={styles.title_text}>
                      Department
                    </div>
                    <div style={{ marginTop: '1em' }}>
                      <Select
                        options={departmentOptions}
                        placeholder="Select Department"
                        value={departmentOptions.find(d => d.value === profile.department_id)}
                        onChange={handleDropDownOnChange('department_id')}
                      />
                    </div>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-3">
                    <div className={styles.title_text}>
                      Organization
                    </div>
                    <div style={{ marginTop: '1em' }}>
                      <Input value={profile.account.name} inputProps={{ style: { height: '1.6em', paddingLeft: '0.75em', borderRadius: 3, border: '0.75px solid #D3D3D3', } }} disabled fullWidth={true} disableUnderline={true} />
                    </div>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-3">
                    <div className={styles.title_text}>
                      Role
                    </div>
                    <div style={{ marginTop: '1em' }}>
                      <Select
                        options={licenseOptions}
                        placeholder="Select Role"
                        isDisabled={true}
                        value={licenseOptions.find(l => l.value === profile.license_type_id)}
                        onChange={handleDropDownOnChange('license_type_id')}
                      />
                    </div>
                  </div>
                </div>
              </CustomCard>
            </div>
            <div className={styles.title_text} style={{ justifyContent: 'right', marginTop: '2em' }}>
              <div className="row">
                <div className="col-md-9">
                  Primary Contact
                </div>
              </div>
            </div>
            <div className={styles.card_view}>
              <CustomCard>
                <div className="row">
                  <div className="col-xs-6 col-sm-6 col-md-3">
                    <div className={styles.title_text}>
                      Prefix
                    </div>
                    <div style={{ marginTop: '1em' }}>
                      <Select
                        options={initialOptions}
                        placeholder="Select Prefix"
                        value={initialOptions.find(i => i.value === profile.initials)}
                        onChange={handleDropDownOnChange('initials')}
                      />
                    </div>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-3">
                    <div className={styles.title_text}>
                      First Name
                    </div>
                    <div style={{ marginTop: '1em' }}>
                      <Input onChange={handleInputOnChange('first_name')} value={profile.first_name} placeholder="First Name" inputProps={{ style: { height: '1.6em', paddingLeft: '0.75em', borderRadius: 3, border: '0.75px solid #D3D3D3', backgroundColor: '#FFF' } }} fullWidth={true} disableUnderline={true} />
                    </div>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-3">
                    <div className={styles.title_text}>
                      Middle Name
                    </div>
                    <div style={{ marginTop: '1em' }}>
                      <Input onChange={handleInputOnChange('middle_name')} value={profile.middle_name} placeholder="Middle Name" inputProps={{ style: { height: '1.6em', paddingLeft: '0.75em', borderRadius: 3, border: '0.75px solid #D3D3D3', backgroundColor: '#FFF' } }} fullWidth={true} disableUnderline={true} />
                    </div>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-3">
                    <div className={styles.title_text}>
                      Last Name
                    </div>
                    <div style={{ marginTop: '1em' }}>
                      <Input onChange={handleInputOnChange('last_name')} value={profile.last_name} placeholder="Last Name" inputProps={{ style: { height: '1.6em', paddingLeft: '0.75em', borderRadius: 3, border: '0.75px solid #D3D3D3', backgroundColor: '#FFF' } }} fullWidth={true} disableUnderline={true} />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-6 col-sm-6 col-md-6 col-lg-3">
                    <div className={styles.title_text}>
                      Gender
                    </div>
                    <div style={{ marginTop: '1em' }}>
                      <Select
                        options={genderOptions}
                        placeholder="Select Gender"
                        value={genderOptions.find(g => g.value === profile.gender)}
                        onChange={handleDropDownOnChange('gender')}
                      />
                    </div>
                  </div>
                  <div className="form-group col-xs-6 col-sm-6 col-md-6">
                    <div className={styles.title_text}>
                      Phone No.
                    </div>
                    <div style={{ marginTop: '1em' }} className="row no-gutters">
                      <div className="col-xs-2 col-sm-2 col-md-4 col-lg-4">
                        <Select
                          options={countriesOptions}
                          placeholder="Code"
                          value={countriesOptions.find(c => c.value === profile.country_code)}
                          onChange={handleDropDownOnChange('country_code')}
                        />
                      </div>
                      <div className="col-xs col-sm col-md-8 col-lg-8">
                        <Input onChange={handleInputOnChange('contact')} value={profile.contact} placeholder="Contact" inputProps={{ style: { height: '1.6em', paddingLeft: '0.75em', borderRadius: 3, border: '0.75px solid #D3D3D3', borderLeft: 'none', backgroundColor: '#FFF' } }} fullWidth={true} disableUnderline={true} />
                      </div>
                    </div>
                  </div>
                </div>

              </CustomCard>
              <div style={{ marginTop: '2em', textAlign: 'right' }}>
                <SecondaryButton onClick={() => props.history.goBack()}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton disabled={isUpdating} onClick={handleUpdateOnClick}>
                  Update
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <CropperDialog
              cropperDialog={cropperDialog}
              handleCloseCropDialog={handleCloseCropDialog}
              cropImage={cropImage}
              src={src}
              ratio={true}
            />
          </div>
        </div>
      </div>
    </>
    );
  }


  return (<> {isLoading ? <LargeScreenLoader /> : renderPage()} </>)
}
