import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import moment from "moment";
import { Link } from "react-router-dom";
import axios from "../../../common/api-configCRM";
import { withRouter } from "react-router-dom";
import { openSnackbar } from "../../../common/Snackbar/snackbar";
import SmallLoader from "../../../common/Loaders/CircularLoader/circular-loader";
import OopsError from "../../../components/ErrorMessages/OopsError/OopsError";
import WizardError from "../../../components/ErrorMessages/WizardError/WizardError";
import NoRecordsFound from "../../../components/ErrorMessages/NoRecordsFound/NoRecordsFound";
import TextField from '@material-ui/core/TextField';
import TimePicker from "rc-time-picker";
import DatePicker from "react-date-picker";
// import "rc-time-picker/assets/index.css";
import Dialog from "@material-ui/core/Dialog";
import styles from "./SyncDataSchedulerCRMComponent.module.scss";
import DoneNReadyCRMContainer from "../../DoneNReadyCRM/DoneNReadyCRMContainer";

const stylesMaterialUI = theme => ({
  button: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    marginLeft: 0,
    marginRight: 0
  }
});

class SyncDataSchedulerCRMComponent extends Component {
  state = {
    CRMClicked: false,
    whichCRM: "",
    isDone: false,
    goBack: false,
    date: [moment(), moment()],
    isOpenCRM_LeadX: false,
    isOpenLeadX_CRM: false,
    /* idcrm_master: 1,
    idcrm_integration: 1, */
    masterSchedules: [],
    loadingIntegrationDetails: true,
    errorLoadingIntegrationDetails: false,
    errorInWizard: false,
    integrationsDetails: {
      "fk_leadxtocrm_scheduleid": 0,
      "fk_crmtoleadx_scheduleid": 0,
      "leadxtocrm_scheduled_time": 0,
      "crmtoleadx_scheduled_time": 0,
      "leadx_to_crm_lastsyncdate": moment().format("YYYY-MM-DD"),
      "crm_to_leadx_lastsyncdate": moment().format("YYYY-MM-DD")
    }
  };

  componentDidMount() {
    //console.log("props step4", this.props);
    /* if (this.props.history.location.state !== undefined) {
      console.log("idcrm_integration in step4", this.props.history.location.state.idcrm_integration);
      this.setState({
        idcrm_integration: this.props.history.location.state.idcrm_integration,
        idcrm_master: this.props.history.location.state.idcrm_master,
        isActive: this.props.history.location.state.isActive,
        configured: this.props.history.location.state.configured
      });
    } */
    if (this.props.idcrm_integration !== undefined && 
      this.props.idcrm_integration !== null && 
      this.props.idcrm_integration !== "" &&
      this.props.idcrm_master !== undefined && 
      this.props.idcrm_master !== null &&
      this.props.idcrm_master !== "" && 
      this.props.isActive !== undefined && 
      this.props.isActive !== null &&
      this.props.isActive !== "" && 
      this.props.configured !== undefined && 
      this.props.configured !== null &&
      this.props.configured !== "") {
      //master schedules
      console.log("integration details in Sync Schedule", this.props);
      var integrationDetails = this.props.CRMConfigureDetails.integrationDetails;
      
      axios.get(`/schedules/get`).then(response => {
        // console.log("Master Schedules Resp", response);
        if (response !== undefined &&
          response.data !== undefined &&
          response.data.status !== undefined &&
          response.data.status === 0) {
          if (response.data.data !== undefined) {
            //console.log("resp data", response.data.data);
            this.setState({ masterSchedules: response.data.data });
          }
        }
      });
      console.log("integration details in Sync parameters",integrationDetails.account_id, integrationDetails.crm_org_id);
      axios.get(`integration/getHS/${this.props.idcrm_master}/${integrationDetails.crm_org_id}/${integrationDetails.account_id}`).then(response => {
        //console.log("Integration Resp", response);
        if (response !== undefined &&
          response.data !== undefined &&
          response.data.status !== undefined &&
          response.data.status === 0) {
          if (response.data.data !== undefined) {
            ////CAUTION: Currently integration details have multiple records
            // it should have only 1
            // TODO Rekha remove [0] once only one record is received
            if (response.data.data.length > 0) {
              if (response.data.data[0].crm_to_leadx_lastsyncdate !== null &&
                response.data.data[0].leadx_to_crm_lastsyncdate !== null &&
                response.data.data[0].crm_to_leadx_lastsyncdate !== "" &&
                response.data.data[0].leadx_to_crm_lastsyncdate !== "" &&
                response.data.data[0].crmtoleadx_scheduled_time !== null &&
                response.data.data[0].leadxtocrm_scheduled_time !== null) {
                this.setState({
                  integrationsDetails: response.data.data[0]
                }, () => {
                  //console.log("intdetRRRR", this.state.integrationsDetails);
                });
              }
            }
            this.setState({
              errorLoadingIntegrationDetails: false,
              loadingIntegrationDetails: false
            });
            // console.log("integrationsDetails ", this.state.integrationsDetails);
            // console.log("integrationsDetails ", this.state.loadingIntegrationDetails);
          } else {
            openSnackbar("Integration Information Not Found.", "snack-error");
            this.setState({
              loadingIntegrationDetails: false,
              errorLoadingIntegrationDetails: true,
            });
          }
        } else {
          openSnackbar("Unable to Get Integration Information", "snack-error");
          this.setState({
            loadingIntegrationDetails: false,
            errorLoadingIntegrationDetails: true,
          });
        }
      }).catch((error) => {
        openSnackbar("Exception Getting Integration Information", "snack-error");
        this.setState({
          loadingIntegrationDetails: false,
          errorLoadingIntegrationDetails: true,
        });
        console.log(error);
      });
    } else {
      this.setState({ errorInWizard: true });
    }
  }

  handleDoneNReadyClicked = (e) => {
    if (!this.checkValidity() === true) {
      // console.log("Final Step", e);
      //alert("Do You Want To Proceed?");
      // console.log("int det in done", this.state.integrationsDetails);
      let params = null;
      if (this.state.integrationsDetails !== undefined) {
        if (this.state.integrationsDetails.idcrm_integration !== "") {
          params = {
            idcrm_integration: this.props.idcrm_integration,
            fk_leadxtocrm_scheduleid: this.state.integrationsDetails.fk_leadxtocrm_scheduleid,
            fk_crmtoleadx_scheduleid: this.state.integrationsDetails.fk_crmtoleadx_scheduleid,
            leadxtocrm_scheduled_time: this.state.integrationsDetails.leadxtocrm_scheduled_time,
            crmtoleadx_scheduled_time: this.state.integrationsDetails.crmtoleadx_scheduled_time,
            leadx_to_crm_lastsyncdate: this.state.integrationsDetails.leadx_to_crm_lastsyncdate,
            crm_to_leadx_lastsyncdate: this.state.integrationsDetails.crm_to_leadx_lastsyncdate
          }
          // console.log("params in done", params);
          axios
            .put('/integration/updateschedule', { data: JSON.stringify(params) })
            .then(response => {
              // console.log("response in savenproceed", response);
              if (response !== undefined && response.data.status !== undefined &&
                response.data.status === 0) {
                //openSnackbar("Congratulations!!! CRM Integration Done Successfully", "snack-success");
                this.setState({ isDone: true });
                /*  this.props.history.push({
                   pathname: '/integrations'
                 }); */
              } else {
                openSnackbar("Something Went Wrong!", "snack-error");
              }
            })
            .catch((error) => {
              console.log(error);
              openSnackbar("Unable to Update Integration Schedule Information", "snack-error");
            });
        }
      }
    } else {
      openSnackbar("Please Select Valid Options", "snack-error");
    }
  }

  handleGoBackClicked = () => {
    // console.log("go back clicked");
    this.setState({ goBack: true });
    this.props.history.goBack();
  }

  handleScheduleChanged = (e, which) => {
    // console.log("tttt", e.target.value);
    // console.log("ttsdfd", which);
    const value = e.target.value;
    if (which === "leadxtocrm") {
      this.setState(prevState => ({
        integrationsDetails: {                   // object that we want to update
          ...prevState.integrationsDetails,    // keep all other key-value pairs
          fk_leadxtocrm_scheduleid: value       // update the value of specific key
        }
      }));
      //this.state.integrationsDetails.fk_leadxtocrm_scheduleid = e.target.value;
    } else {
      this.setState(prevState => ({
        integrationsDetails: {                   // object that we want to update
          ...prevState.integrationsDetails,    // keep all other key-value pairs
          fk_crmtoleadx_scheduleid: value       // update the value of specific key
        }
      }));
      //this.state.integrationsDetails.fk_crmtoleadx_scheduleid = e.target.value;
    }
    /* let intDetails = { ...this.state.integrationDetails };
    if (which === "leadxtocrm") {
      intDetails.fk_leadxtocrm_scheduleid = e.target.value;
    } else {
      intDetails.fk_crmtoleadx_scheduleid = e.target.value;
    }

    // console.log("new array", intDetails);
    this.setState({ integrationsDetails: intDetails }); */
  }

  handleScheduleTimeChange = (e, which) => {
    // console.log("tttt", e.target.value);
    const value = e.target.value;
    if (which === "leadxtocrm") {
      this.setState(prevState => ({
        integrationsDetails: {                   // object that we want to update
          ...prevState.integrationsDetails,    // keep all other key-value pairs
          leadxtocrm_scheduled_time: value       // update the value of specific key
        }
      }));
      //this.state.integrationsDetails.leadxtocrm_scheduled_time = e.target.value;
    } else {
      this.setState(prevState => ({
        integrationsDetails: {                   // object that we want to update
          ...prevState.integrationsDetails,    // keep all other key-value pairs
          crmtoleadx_scheduled_time: value       // update the value of specific key
        }
      }));
      //this.state.integrationsDetails.crmtoleadx_scheduled_time = e.target.value;
    }
    /* let intDetails = { ...this.state.integrationDetails };
    if (which === "leadxtocrm") {
      intDetails.leadxtocrm_scheduled_time = e.target.value;
    } else {
      intDetails.crmtoleadx_scheduled_time = e.target.value;
    }

    console.log("new array", intDetails);
    this.setState({ integrationsDetails: intDetails }); */
  }

  handleScheduleDateChange = (e, which) => {
    //console.log("tttt", e.target.value);
    const value = e.target.value;
    if (which === "leadxtocrm") {
      this.setState(prevState => ({
        integrationsDetails: {                   // object that we want to update
          ...prevState.integrationsDetails,    // keep all other key-value pairs
          leadx_to_crm_lastsyncdate: value       // update the value of specific key
        }
      }));
      //this.state.integrationsDetails.leadx_to_crm_lastsyncdate = e.target.value;
    } else {
      this.setState(prevState => ({
        integrationsDetails: {                   // object that we want to update
          ...prevState.integrationsDetails,    // keep all other key-value pairs
          crm_to_leadx_lastsyncdate: value       // update the value of specific key
        }
      }));
      //this.state.integrationsDetails.crm_to_leadx_lastsyncdate = e.target.value;
    }
    /* let intDetails = { ...this.state.integrationDetails };
    if (which === "leadxtocrm") {
      intDetails.leadx_to_crm_lastsyncdate = e.target.value;
    } else {
      intDetails.crm_to_leadx_lastsyncdate = e.target.value;
    }

    console.log("new array", intDetails);
    this.setState({ integrationsDetails: intDetails }); */
  }

  handleSaveClicked = () => {
    if (!this.checkValidity() === true) {
      let params = null;
      if (this.state.integrationsDetails !== undefined) {
        if (this.state.integrationsDetails.idcrm_integration !== "") {
          /* params = {
            idcrm_integration: this.state.idcrm_integration,
            fk_leadxtocrm_scheduleid: this.state.integrationsDetails.fk_leadxtocrm_scheduleid,
            fk_crmtoleadx_scheduleid: this.state.integrationsDetails.fk_crmtoleadx_scheduleid,
            leadxtocrm_scheduled_time: this.state.integrationsDetails.leadxtocrm_scheduled_time,
            crmtoleadx_scheduled_time: this.state.integrationsDetails.crmtoleadx_scheduled_time,
            leadx_to_crm_lastsyncdate: this.state.integrationsDetails.leadx_to_crm_lastsyncdate,
            crm_to_leadx_lastsyncdate: this.state.integrationsDetails.crm_to_leadx_lastsyncdate
          } */
          params = {
            idcrm_integration: this.props.idcrm_integration,
            fk_leadxtocrm_scheduleid: this.state.integrationsDetails.fk_leadxtocrm_scheduleid,
            fk_crmtoleadx_scheduleid: this.state.integrationsDetails.fk_leadxtocrm_scheduleid,
            leadxtocrm_scheduled_time: this.state.integrationsDetails.leadxtocrm_scheduled_time,
            crmtoleadx_scheduled_time: this.state.integrationsDetails.leadxtocrm_scheduled_time,
            leadx_to_crm_lastsyncdate: this.state.integrationsDetails.leadx_to_crm_lastsyncdate,
            crm_to_leadx_lastsyncdate: this.state.integrationsDetails.leadx_to_crm_lastsyncdate
          }
          //console.log("params in save", params);
          axios
            .put('/integration/updateschedule', { data: JSON.stringify(params) })
            .then(response => {
              //console.log("response in savenproceed", response);
              if (response !== undefined && response.data.status !== undefined &&
                response.data.status === 0) {
                openSnackbar("Saved!", "snack-success");
              } else {
                openSnackbar("Something Went Wrong!", "snack-error");
              }
            })
            .catch((error) => {
              console.log(error);
              openSnackbar("Unable to Update Integration Schedule Information", "snack-error");
            })
        }
      }
    } else {
      openSnackbar("Please Select Valid Options", "snack-error");
    }
  }

  toggleCalendarLeadX_CRM = () => {
    if (this.state.isOpenLeadX_CRM) {
      this.setState({
        flag: "calendar",
        isOpenLeadX_CRM: false
      });
    } else {
      this.setState({
        flag: "calendar",
        isOpenLeadX_CRM: true
      });
    }
  };

  toggleCalendarCRM_LeadX = () => {
    if (this.state.isOpenCRM_LeadX) {
      this.setState({
        flag: "calendar",
        isOpenCRM_LeadX: false
      });
    } else {
      this.setState({
        flag: "calendar",
        isOpenCRM_LeadX: true
      });
    }
  };

  handleDateChangeCRM_LeadX = date => {
    //console.log("date", date);
    this.setState(prevState => ({
      integrationsDetails: {                   // object that we want to update
        ...prevState.integrationsDetails,    // keep all other key-value pairs
        crm_to_leadx_lastsyncdate: moment(date).format("YYYY-MM-DD")       // update the value of specific key
      }
    }));
  }

  handleDateChangeLeadX_CRM = date => {
    //console.log("date", date);
    this.setState(prevState => ({
      integrationsDetails: {                   // object that we want to update
        ...prevState.integrationsDetails,    // keep all other key-value pairs
        leadx_to_crm_lastsyncdate: moment(date).format("YYYY-MM-DD")       // update the value of specific key
      }
    }));
  }

  dateChangeHandler = e => { };

  handleTimeChangeLeadX_CRM = newTime => {
    //this.setState({ activity_time: newTime && newTime.format("HH:mm:ss") });
    this.setState(prevState => ({
      integrationsDetails: {                   // object that we want to update
        ...prevState.integrationsDetails,    // keep all other key-value pairs
        leadxtocrm_scheduled_time: newTime && newTime.format("HH:mm:ss")      // update the value of specific key
      }
    }));
  };

  handleTimeChangeCRM_LeadX = newTime => {
    //this.setState({ activity_time: newTime && newTime.format("HH:mm:ss") });
    this.setState(prevState => ({
      integrationsDetails: {                   // object that we want to update
        ...prevState.integrationsDetails,    // keep all other key-value pairs
        crmtoleadx_scheduled_time: newTime && newTime.format("HH:mm:ss")       // update the value of specific key
      }
    }));
  };

  checkValidity() {
    if (this.state.integrationsDetails.fk_leadxtocrm_scheduleid === 0 /* ||
      this.state.integrationsDetails.fk_crmtoleadx_scheduleid === 0 */) {
      return true;
    } else {
      return false;
    }
  }

  handleCloseDoneNReady = value => {
    //console.log("close dialog", value);
    this.setState({
      isDone: false,
    });
    this.props.history.push({
      pathname: '/integrations'
    });
  };

  /* handleActivateDeactivate = () => {
    console.log("handleActivateDeactivate clicked", this.state.isActive);

    if (this.state.idcrm_master !== undefined &&
      this.state.isActive !== undefined) {
      const params = {
        idcrm_master: this.state.idcrm_master,
        isActive: !this.state.isActive
      };
      console.log("params", params);
      axios.put('/integration/changestatus', { data: JSON.stringify(params) })
        .then(response => {
          console.log("response in savenproceed", response);
          if (response !== undefined &&
            response.data.status !== undefined &&
            response.data.status === 0) {
            this.setState({ isActive: !this.state.isActive });
          } else {
            openSnackbar("Unable to Update Status", "snack-error");
          }
        })
        .catch((error) => {
          console.log(error);
          openSnackbar("Technical Error updating status", "snack-error");
        })
    }
  } */

  render() {
    /* if (this.state.goBack) {
      return <Redirect to='/integrations/assignsalesteamCRM' />
    } */
    const timepickerstyle = { 'text-align': 'center' };
    return (
      <React.Fragment>
        <div className={styles.main_wrapper}>
          <div className="row">
            <div className="col-12">

              <div className={"breadcrumbs row " + styles.centerAlign}>
                <div className="col-md-6 col-12">
                  <h2 className="heading">Integrations</h2>
                  <div className="breadcrumbs_container">
                    <Breadcrumbs separator="›" arial-label="Breadcrumb">
                      {/* <Link to="/sales-news">Home</Link>
                      <Link to="/integrations">Integrations</Link>
                      <Link to="/integrations">Configure CRM</Link> */}
                      {/* <Link to="/integrations/authenticateCRM">Authenticate</Link> */}
                      {/* <Link to="/integrations/configuredataobjsmapCRM">Configure Data Object Mappings</Link>
                      <Link to="/integrations/assignsalesteamCRM">Assign Sales Team</Link> */}
                      <span>Home</span>
                      <span>Integrations</span>
                      <span>Configure CRM</span>
                      <span>Configure Data Object Mappings</span>
                      <span>Assign Sales Team</span>
                      <span>Data Syncronization Scheduler</span>
                    </Breadcrumbs>
                  </div>
                </div>
              </div>
              <div className="row container-body">
                <div className={`col-xs-12 col-md-12 col-lg-12 col-12`}>
                  <div className={styles.syncDataSchedulerCRM_container}>

                    <div className={styles.syncDataSchedulerCRM_container_header}>
                      <h1 className={styles.syncDataSchedulerCRM_container_heading}>
                        Step 5 of 5 - Data Synchronization Scheduler
                      </h1>
                    </div>

                    <div className={styles.syncDataSchedulerCRM_container_footer} >
                      {/* start */}
                      {this.state.errorInWizard ?
                        (<div><WizardError /></div>) :
                        this.state.errorLoadingIntegrationDetails ?
                          (<div><OopsError /></div>) :
                          this.state.loadingIntegrationDetails ? (<SmallLoader />) :
                            this.state.integrationsDetails.length === 0 ?
                              (<div><NoRecordsFound /></div>) :
                              // ({ configureView }) 
                              (
                                <div>
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="bg-white ml-4 mr-4">
                                        <div className="mt-5 mb-4">
                                          <div className="row">
                                            <div className="col-sm-2 mb-3" />

                                            <div className="col-sm-8">

                                              <table className="table table-bordered mt-4 mb-4" >
                                                {/* <thead>
                                          <tr>
                                            <th style={{ width: '50%' }}>From LeadX to SalesForce</th>
                                            <th style={{ width: '50%' }}>From SalesForce to LeadX</th>
                                          </tr>
                                        </thead> */}
                                                <tbody>
                                                  <tr>
                                                    <td>
                                                      <br />
                                                      <strong>Synchronize Data - </strong>
                                                      <br /><br />
                                                      {this.state.masterSchedules.map(ms =>
                                                        <p key={ms.idschedule}>
                                                          <input type="radio" defaultValue={ms.idschedule} defaultChecked={this.state.integrationsDetails.fk_leadxtocrm_scheduleid === ms.idschedule} name="leadxtocrm" onChange={(event) => this.handleScheduleChanged(event, "leadxtocrm")} />&nbsp;&nbsp;&nbsp;&nbsp;{ms.schedule_desc}
                                                          <br />
                                                        </p>)}
                                                      <br />
                                                      <label className="col-form-label" htmlFor="time">
                                                        <strong>Start Time</strong><span style={{ color: '#c00' }} className={styles.required_field}>*</span>
                                                      </label>
                                                      <div style={{ width: '100%', textAlign: 'center' }}>
                                                        <TimePicker
                                                          showSecond={true}
                                                          value={moment(this.state.integrationsDetails.leadxtocrm_scheduled_time, "HH:mm:ss")}
                                                          onChange={this.handleTimeChangeLeadX_CRM}
                                                          className="add_cursor_pointer"
                                                          placement="bottomLeft"
                                                          clearIcon={null}
                                                        />
                                                      </div>

                                                      {/* className={`add_cursor_pointer ${styles.rc_input}`} */}
                                                      {/* <label className="col-form-label">Start Time (UTC +0)</label>
                                              <input onChange={(event) => this.handleScheduleTimeChange(event, "leadxtocrm")} type="text" defaultValue={this.state.integrationsDetails.leadxtocrm_scheduled_time} className="form-control" placeholder="HH:MM:SS" style={{ width: 150 }} /> */}
                                                      <br />
                                                      <div className={styles.form_group}>
                                                        {/* <div className="col-12 col-md-6 col-lg-4"> */}
                                                        <label className={styles.form_title}>
                                                          <strong>Start Date</strong><span style={{ color: '#c00' }} className={styles.required_field}>*</span>
                                                        </label>
                                                        <div className={styles.datepicker}>
                                                          <input
                                                            value={this.state.integrationsDetails.leadx_to_crm_lastsyncdate} style={{ width: '100%' }}
                                                            className="form-control add_cursor_pointer"
                                                            onClick={this.toggleCalendarLeadX_CRM}
                                                            onChange={this.dateChangeHandler}
                                                          />
                                                          <DatePicker
                                                            value={
                                                              new Date(
                                                                moment(this.state.integrationsDetails.leadx_to_crm_lastsyncdate).year(),
                                                                moment(this.state.integrationsDetails.leadx_to_crm_lastsyncdate).month(),
                                                                moment(this.state.integrationsDetails.leadx_to_crm_lastsyncdate).date()
                                                              )
                                                            }
                                                            clearIcon={null}
                                                            calendarIcon={null}
                                                            isOpen={this.state.isOpenLeadX_CRM}
                                                            onCalendarClose={this.toggleCalendarLeadX_CRM}
                                                            onChange={this.handleDateChangeLeadX_CRM}
                                                          /* minDate={new Date()}
                                                          maxDate={new Date(moment().add(20, 'years'))} */
                                                          />
                                                        </div>
                                                      </div>
                                                      {/* <label className="col-form-label">Start Date</label>
                                              <input type="text" defaultValue={this.state.integrationsDetails.leadx_to_crm_lastsyncdate} onChange={(event) => this.handleScheduleDateChange(event, "leadxtocrm")} className="form-control" placeholder="YYYY-MM-DD" style={{ width: 150 }} /> */}
                                                    </td>
                                                    {/* <td>
                                              <br />
                                              <strong>Sync Data -</strong>
                                              <br /><br />
                                              {this.state.masterSchedules.map(ms =>
                                                <p key={ms.idschedule}>
                                                  <input type="radio" defaultValue={ms.idschedule} defaultChecked={this.state.integrationsDetails.fk_crmtoleadx_scheduleid === ms.idschedule} name="crmtoleadx" onChange={(event) => this.handleScheduleChanged(event, "crmtoleadx")} />&nbsp;&nbsp;&nbsp;&nbsp;{ms.schedule_desc}
                                                  <br />
                                                </p>)}
                                              <br /> 
                                              
                                              <label className="col-form-label" htmlFor="time">
                                                <strong>Start Time</strong><span style={{ color: '#c00' }} className={styles.required_field}>*</span>
                                              </label>
                                              <div style={{ width: '200px' }}>
                                                <TimePicker
                                                  style={{ width: 100 }}
                                                  showSecond={true}
                                                  value={moment(this.state.integrationsDetails.crmtoleadx_scheduled_time, "HH:mm:ss")}
                                                  onChange={this.handleTimeChangeCRM_LeadX}
                                                  className="add_cursor_pointer"
                                                  placement="bottomLeft"
                                                  clearIcon={null}
                                                />
                                              </div>
                                              <br />

                                              <div className={styles.form_group}>
                                               
                                                <label className={styles.form_title}>
                                                  <strong>Start Date</strong><span style={{ color: '#c00' }} className={styles.required_field}>*</span>
                                                </label>
                                                <div className={styles.datepicker}>
                                                  <input
                                                    value={this.state.integrationsDetails.crm_to_leadx_lastsyncdate}
                                                    style={{ width: 200 }}
                                                    className="form-control add_cursor_pointer"
                                                    onClick={this.toggleCalendarCRM_LeadX}
                                                    onChange={this.dateChangeHandler}
                                                  />
                                                  <DatePicker
                                                    value={
                                                      new Date(
                                                        moment(this.state.integrationsDetails.crm_to_leadx_lastsyncdate).year(),
                                                        moment(this.state.integrationsDetails.crm_to_leadx_lastsyncdate).month(),
                                                        moment(this.state.integrationsDetails.crm_to_leadx_lastsyncdate).date()
                                                      )
                                                    }
                                                    clearIcon={null}
                                                    calendarIcon={null}
                                                    isOpen={this.state.isOpenCRM_LeadX}
                                                    onCalendarClose={this.toggleCalendarCRM_LeadX}
                                                    onChange={this.handleDateChangeCRM_LeadX}
                                                  />
                                                </div>
                                              </div>
                                            </td>*/}
                                                  </tr>
                                                </tbody>
                                              </table>

                                            </div>
                                            <div className="col-sm-2 mb-3" />
                                          </div>
                                        </div>
                                        <hr className="jss201" />
                                        <div className="mt-3 mb-5">
                                          <div style={{ textAlign: 'right' }}>
                                            <button onClick={this.handleGoBackClicked} className={styles.profileContainer_update_btn__MXNDH} type="button" style={{ backgroundColor: '#e0e0e0', padding: '10px 20px', color: 'rgba(0, 0, 0, 0.87)' }}>Go Back</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                          <button onClick={() => this.handleSaveClicked("step4")} type="button" className={styles.profileContainer_update_btn__MXNDH} style={{ padding: '10px 20px' }}>Save</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                          <button onClick={() => this.handleDoneNReadyClicked("step4")} type="button" className={styles.profileContainer_update_btn__MXNDH} style={{ padding: '10px 20px', backgroundColor: '#19d98c' }}>Done and Ready to Go!</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>)
                      }
                      {/* end */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Dialog
            className="width"
            aria-labelledby="customized-add-activities"
            open={this.state.isDone ? this.state.isDone : false}
            onClose={this.handleCloseDoneNReady}
            maxWidth={"lg"}
          >
            <DoneNReadyCRMContainer
              close={this.handleCloseDoneNReady}
              fieldMapping={this.state.idcrm_master}
              isActive={this.state.isActive}
              activeDeactive={this.handleActivateDeactivate}
            />
          </Dialog>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(stylesMaterialUI)(withRouter(SyncDataSchedulerCRMComponent));