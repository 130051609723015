import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "./redux/actions";
import { loadMoreSalesFeeds } from "../aside-right/redux/actions";
import SalesNews from "./component/sales-news";
import SalesNewsContainerStyles from "./SalesNewsContainer.module.css";
import AsideRight from "../aside-right/component/aside-right";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Grid, Button } from "@material-ui/core";
import InDraft from "./InDraft/InDraft";
import PublishNews from "./PublishNews/PublishNews";
import OopsError from "../../components/ErrorMessages/OopsError/OopsError";
import { getStorageItem } from "../../common/common-functions";
import {
  salesNewsRequestBody,
  salesFeedRequestBody
} from "../../common/apiParameters";
//import MultiSelect from "../../common/MultiSelect/multiSelect";
import MultiSelectSalesNews from "../../common/MultiSelect/multiSelectSalesNews"
import LargeScreenLoader from "../../common/Loaders/PulseLoader/pulse-loader";
import _ from 'lodash'
class SalesNewsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // currentUser: JSON.parse(localStorage.getItem('user')),
      currentUser: JSON.parse(getStorageItem("user")),
      activeIndex: 0,
      countFeed: 1,
      org_list: this.props.organizationList
        ? this.props.organizationList.filter(org =>
          this.props.selectedOrganizationList.includes(org.id)
        )
        : [],
      orgError: "",
      orgIdParams: [],
      loadingSalesNews: false
    };
  }

  componentWillUnmount() {
    this.props.resetActiveIndex();
  }

  changeTab = activeIndex => {
    this.props.initializeActiveTab(activeIndex);
  };

  handleScrollFeeds = e => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;

    const right = e.target.scrollWidth - e.target.scrollLeft <= e.target.clientWidth;
    if (window.innerWidth > 767 && bottom) {
      this.loadMoreDataSalesFeed();
    } else if (window.innerWidth < 767 && right) {
      this.loadMoreDataSalesFeed();
    }
  };

  loadMoreDataSalesFeed = () => {
    // const currentUser = JSON.parse(localStorage.getItem('user'));
    const currentUser = JSON.parse(getStorageItem("user"));

    if (
      this.props.salesFeeds &&
      this.props.totalSalesFeed > this.props.salesFeeds.length

    ) {
      const requestBody = salesFeedRequestBody;
      requestBody.organizationArray = this.props.selectedOrganizationList;

      requestBody.paginate = {
        page: this.state.countFeed.toString(),
        limit: "10"
      }
      this.props.loadMoreSalesFeeds(requestBody);
      this.setState(preState => {
        return { countFeed: preState.countFeed + 1 };
      });
    }
  };

  setOrganization = event => {
    this.handleUserInput(event);
  };
  //manage news

  handleUserInput(e) {
    if (e && e.name) {
      const name = e.name;
      const value = e.value;
      this.setState({ [name]: value }, () => {
        this.validateField(name, value);
      });
    }
  }

  validateField(fieldName, value) {
    switch (fieldName) {
      case "org_list":
        if (typeof value === "number") {
          this.setState({ orgError: "" });
        }

        break;

      default:
        break;
    }
  }

  callApi = (fn, requestBody) => () => {
    console.log("Executing now")
    this.setState({loadingSalesNews: true});
    fn(requestBody, (err, result) => {
      this.setState({loadingSalesNews: false});
    });
  }

  componentDidUpdate = (prevProps, prevState) => {

    if (this.props.selectedOrganizationList !== prevProps.selectedOrganizationList && this.props.selectedOrganizationList.length !== 0) {

      this.setState({
        countFeed: 1
      })
    }

    if (this.props.organizationList &&
      this.props.organizationList.length !== 0 &&
      prevProps.organizationList !== this.props.organizationList
    ) {
      let defaultOrganization = this.props.organizationList
        ? this.props.organizationList.filter(org => {
          return org.id === this.state.currentUser.organization_id;
        })
        : [];

      this.setState({ org_list: defaultOrganization });
    }
    console.log(prevState.org_list, this.state.org_list, 'LISTING');
    //prevState.org_list.length !== 0 &&
    if (prevState.org_list !== this.state.org_list) {
      const orgIdParams = this.state.org_list.map(org => {
        return org.id;
      });
      console.log(orgIdParams, 'orgID');
      this.props.setSelectedOrganization(orgIdParams);
      this.setState(
        {
          orgIdParams: orgIdParams

        },
        () => {
          const requestBody = salesNewsRequestBody;

          requestBody.organizationArray = this.state.orgIdParams; //append selected organisation to request body.
          requestBody.paginate = {
            page: "0",
            limit: "10"
          }

          console.log("Going for Call.....");
          const fn = _.debounce(this.callApi(this.props.initializeSalesNews, requestBody),0);
          fn();
        }
      );

      if (this.state.org_list.length === 0) {
        let defaultOrganization = this.props.organizationList
          ? this.props.organizationList.filter(org => {
            return org.id === this.state.currentUser.organization_id;
          })
          : [];

        this.setState({ org_list: defaultOrganization });
      }
    }
  };

  componentDidMount() {

    if (this.props.organizationList.length !== 0) {
      if (this.props.selectedOrganizationList.length !== 0) {
        const orgIdParams = this.props.selectedOrganizationList;
        this.props.setSelectedOrganization(orgIdParams);
        const requestBody = salesNewsRequestBody;
        requestBody.organizationArray = this.props.selectedOrganizationList;
        requestBody.paginate = {
          page: "0",
          limit: "10"
        }
        this.props.initializeSalesNews(requestBody);
      } else {
        let defaultOrganization = this.props.organizationList
          ? this.props.organizationList.filter(org => {
            return org.id === this.state.currentUser.organization_id;
          })
          : [];
        this.setState({ org_list: defaultOrganization });

        const orgIdParams = this.state.org_list.map(org => {
          return org.id;
        });
        this.props.setSelectedOrganization(orgIdParams);
        this.setState(
          {
            orgIdParams: orgIdParams
          },
          () => {
            const requestBody = salesNewsRequestBody;

            requestBody.organizationArray = this.state.orgIdParams; //append selected organisation to request body.
            requestBody.paginate = {
              page: "0",
              limit: "10"
            }
            this.props.initializeSalesNews(requestBody);
          }
        );
      }
    }

    this.props.resetActiveIndex();
  }

  canSeeOrgDropDown = () => {
    // Only Manager of Child Org can see and for Parent org Manager, Professional & Administrator can see 

    const { organizationList } = this.props;
    const currentUser = JSON.parse(getStorageItem("user"));

    if (!currentUser) return false;

    const licenseName = currentUser.license_type.actual_name;
    const hasOrgOptions = organizationList && organizationList.length > 1;

    return (
      hasOrgOptions &&
      ['MANAGER', 'ADMINISTRATOR'].includes(licenseName)
    );
  }

  canSeePublishOptions = () => {
    const currentUser = JSON.parse(getStorageItem("user"));
    if (!currentUser) return false;
    const licenseName = currentUser.license_type.actual_name;

    return ['MANAGER', 'ADMINISTRATOR'].includes(licenseName)
  }

  render() {
    const salesNewsProps = {
      ...this.props
    };

    const currentUser = JSON.parse(getStorageItem("user"));

    return (
      <>
        {this.props.errorLoadingSalesNews ? (
          <div>
            <OopsError />
          </div>
        ) : (
            <div>
              <Grid container>
                <Grid
                  container
                  className={SalesNewsContainerStyles.salesContanerGrid}
                  item
                  md={8}
                  lg={8}
                  xs={12}
                  sm={12}
                >
                  <Grid
                    className={SalesNewsContainerStyles.salesNewsHeading}
                    item
                    md={12}
                    lg={12}
                    xs={12}
                    sm={12}
                  >
                    <h2 className={SalesNewsContainerStyles.salesNewsTitile}>
                      SALES NEWS
                  </h2>
                    {
                      this.canSeeOrgDropDown() && (
                        <div
                          className={SalesNewsContainerStyles.salesNewsMultiSelect + " " + SalesNewsContainerStyles.salesNewsMultiSelect_1}
                        >
                          <MultiSelectSalesNews
                            isMulti={true}
                            selectName="salesNews"
                            organizationList={this.props.organizationList}
                            setOrganization={this.setOrganization}
                            value={this.state.org_list}
                            closeMenuOnSelect={false}
                            currentUser={currentUser}

                          />
                        </div>
                      )}
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    className={SalesNewsContainerStyles.breadcrumbsAlign}
                    item
                    md={12}
                    lg={12}
                    xs={12}
                    sm={12}
                  >
                    <Grid
                      className={SalesNewsContainerStyles.breadcrumbsWrap + " "}
                      item
                      md={5}
                      lg={5}
                      xs={12}
                      sm={4}
                    >
                      <Breadcrumbs
                        className={SalesNewsContainerStyles.breadcrumbsAlignItem}
                        separator="›"
                        arial-label="Breadcrumb"
                      >
                        <span>Home</span>
                        <span>Sales news</span>
                      </Breadcrumbs>
                    </Grid>

                    { this.canSeePublishOptions() && (
                        <Grid
                          container
                          justify="flex-end"
                          alignItems="flex-end"
                          spacing={8}
                          className={SalesNewsContainerStyles.containerBtn}
                          item
                          md={7}
                          lg={7}
                          xs={12}
                          sm={8}
                        >
                          <b
                            className={
                              this.props.activeIndex === 0
                                ? `${SalesNewsContainerStyles.btnText} ${SalesNewsContainerStyles.activeBtn}`
                                : `${SalesNewsContainerStyles.btnText}`
                            }
                            onClick={() => this.changeTab(0)}
                          >
                            All{" ( " + this.props.totalRecords + " )"}
                          </b>
                          {this.props.selectedOrganizationList.includes(this.state.currentUser.organization_id) ? <>
                            <span className={SalesNewsContainerStyles.separator}>
                              |
                        </span>

                            <b
                              className={
                                this.props.activeIndex === 1
                                  ? `${SalesNewsContainerStyles.btnText} ${SalesNewsContainerStyles.activeBtn}`
                                  : `${SalesNewsContainerStyles.btnText}`
                              }
                              onClick={() => this.changeTab(1)}
                            >
                              Published
                          {" ( " + this.props.totalPublishedNews + " )"}
                            </b>

                            <span className={SalesNewsContainerStyles.separator}>
                              |
                        </span>
                            <b
                              className={
                                this.props.activeIndex === 2
                                  ? `${SalesNewsContainerStyles.btnText} ${SalesNewsContainerStyles.activeBtn}`
                                  : `${SalesNewsContainerStyles.btnText}`
                              }
                              onClick={() => this.changeTab(2)}
                            >
                              Draft
                          {" ( " + this.props.inDraftNewsTotalRecords + " )"}
                            </b> </> : <div> </div>}
                        </Grid>
                      )}
                  </Grid>
                  {(this.state.loadingSalesNews && this.props.salesNews) && <LargeScreenLoader />}
                  <Grid
                    className={SalesNewsContainerStyles.salesNewsMobile}
                    item
                    md={12}
                    lg={12}
                    xs={12}
                    sm={12}
                  >
                    {this.props.activeIndex === 0 && (
                      <SalesNews
                        salesNewsRequestBodySelectedOrg={salesNewsRequestBody}
                        {...salesNewsProps}
                        currentUser={currentUser}
                      />
                    )}
                    {this.props.activeIndex === 1 && (
                      <PublishNews
                        {...salesNewsProps}
                        currentUser={this.state.currentUser}
                      />
                    )}
                    {this.props.activeIndex === 2 && (
                      <InDraft
                        {...salesNewsProps}
                        currentUser={this.state.currentUser}
                      />
                    )}
                  </Grid>
                </Grid>

                <Grid
                  className={SalesNewsContainerStyles.rightContentWrapper}
                  onScroll={this.handleScrollFeeds}
                  item
                  md={4}
                  lg={4}
                  xs={12}
                  sm={12}
                >
                  <div className={SalesNewsContainerStyles.rightContentInner}>
                    <AsideRight
                      {...this.props}
                      salesFeedRequestBody={salesFeedRequestBody}
                      currentUser={currentUser}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    salesNews: state.salesNews.salesNews,
    loadingSalesNews: state.salesNews.loadingSalesNews,
    errorLoadingSalesNews: state.salesNews.errorLoadingSalesNews,
    totalRecords: state.salesNews.totalRecords,
    totalSalesFeed: state.salesFeeds.totalSalesFeed,
    salesFeeds: state.salesFeeds.salesFeeds,
    callStatus: state.salesNews.callStatus,
    snackbarStatus: state.salesNews.snackbarStatus,
    inDraftSalesNews: state.salesNews.inDraftSalesNews,
    inDraftNewsTotalRecords: state.salesNews.inDraftNewsTotalRecords,
    callFlag: state.salesNews.callFlag,
    totalPublishedNews: state.salesNews.totalPublishedNews,
    publishSalesNewsdata: state.salesNews.publishSalesNewsdata,
    activeIndex: state.salesNews.activeIndex,
    organizationList: state.asideLeft.organizationList,
    organizationListNews: state.asideLeft.organizationListNews,
    selectedOrganizationList: state.salesNews.selectedOrganizationList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    initializeInDraftSalesNews: requestBody =>
      dispatch(actions.initializeInDraftSalesNews(requestBody)),
    deleteSalesNews: requestBody =>
      dispatch(actions.deleteSalesNews(requestBody)),
    initializeSalesNews: (requestBody, cb) =>
      dispatch(actions.initializeSalesNews(requestBody, cb)),
    loadMoreSalesNews: (requestBody, cb) =>
      dispatch(actions.loadMoreSalesNews(requestBody, cb)),
    loadMoreSalesFeeds: requestBody =>
      dispatch(loadMoreSalesFeeds(requestBody)),
    updatePublishNews: requestBody =>
      dispatch(actions.updatePublishNews(requestBody)),
    publishSalesNews: requestBody =>
      dispatch(actions.publishSalesNews(requestBody)),
    loadMoreInDraftSalesNews: (requestBody, cb) =>
      dispatch(actions.loadMoreInDraftSalesNews(requestBody, cb)),
    initializePublishNews: requestBody =>
      dispatch(actions.initializePublishNews(requestBody)),
    loadMorePublishedNews: (requestBody, cb) =>
      dispatch(actions.loadMorePublishedNews(requestBody, cb)),
    initializeActiveTab: requestBody =>
      dispatch(actions.initializeActiveTab(requestBody)),
    resetActiveIndex: () => dispatch(actions.resetActiveIndex()),
    setSelectedOrganization: orgIdParams =>
      dispatch(actions.setSelectedOrganization(orgIdParams))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesNewsContainer);
