import React, { useEffect } from "react";
import Api from "./Api";
import { setUser } from "../../common/common-functions";

export default function LoadingNewOrg({
  match: {
    params: { org, account_id, is_parent },
  },
}) {
  useEffect(() => {
    refreshAccount();
  }, []);

  const refreshAccount = async () => {
    console.log(account_id, org);
    await Api.refreshAccountsToSwitch(account_id);
    console.log(is_parent);
    setUser(+account_id, is_parent === 'true');
    setTimeout(() => {
      window.location.pathname = '/sales-news';
    }, 200);
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <img
        src={require("../../../assets/LoadingAccount.svg")}
        alt="Loading Account"
      />
      <div style={{ marginTop: 30, fontWeight: "bold" }}>
        Please wait... Loading {org}
      </div>
    </div>
  );
}
