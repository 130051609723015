import * as types from "./types";

const initialState = {
  idcrm_master: "",
  isActive: 0,
  configured: 0,
  crm_name: "",
  errorLoadingCRMDetails: false,
  idcrm_integration: ""
};

const reducer = (state = initialState, action) => {
  // console.log("action in reducer", action);
  // console.log("action  data in reducer", action.payload);
  // console.log(state);
  switch (action.type) {
    case types.SET_CONFIGURED:
      return {
        ...state,
        configured: action.payload.configured
      };
    case types.SET_IDCRM_MASTER:
      //console.log("in case", action.payload.idcrm_master);
      return {
        ...state,
        idcrm_master: action.payload.idcrm_master
      };
    case types.SET_CRM_NAME:
      return {
        ...state,
        crm_name: action.payload.crm_name
      };
    case types.SET_ISACTIVE:
      return {
        ...state,
        isActive: action.payload.isActive
      };
    case types.SETIDCRM_INTEGRATION:
      //console.log("in case", action);
      return {
        ...state,
        idcrm_integration: action.payload.idcrm_integration
      }
    default:
      return state;
  }

};

export default reducer;
