import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import { 
  DialogActions, 
  Button, 
  List, 
  ListItem, 
  ListItemAvatar, 
  ListItemText 
} from '@material-ui/core';
import {DialogContent, DialogTitle} from './Components';
import styles from "./style.module.scss";
import warningIcon from "../../../assets/images/bi/warning.png";
import Dialog from "@material-ui/core/Dialog";

export default function DeleteLeadConfirmation({open, onClose, deleting, onDeleteClick }) {
  return (
    <Dialog open={open}>
      <DialogTitle onClose={onClose} className={styles.dialogTitle}>
        {/* <span onClick={this.handleCloseDialog}></span> */}
      </DialogTitle>
      <DialogContent>
        <List className={styles.listContent}>
          <ListItem>
            <p className={styles.listTitle}>
              You are about to delete a lead and it will impact
                        </p>
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <img src={warningIcon} />
            </ListItemAvatar>
            <ListItemText className={styles.listText}>Reward points</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <img src={warningIcon} />
            </ListItemAvatar>
            <ListItemText className={styles.listText}>KPIs such as Revenue, Handshake and Pulse Dashboards</ListItemText>
          </ListItem>
          <ListItem>
            <hr className={styles.listBottom}></hr>
          </ListItem>
          <ListItem>
            <p className={styles.confirmText}>
              Are you sure you want to delete the lead?
                        </p>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions className={styles.dialogAct}>
        <Button onClick={onClose} className={styles.cancleBtn} color="primary">
          Cancel
                    </Button>
        {deleting ? (
          <CircularProgress
            style={{
              color: "#19D98C",
            }}
          />
        ) : (<Button onClick={onDeleteClick} className={styles.deleteBtn} variant="contained">
          Delete
        </Button>)}
      </DialogActions>
    </Dialog>
  )
}