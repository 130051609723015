import axios from "../../common/api-config";

export default {
  getOrganizationDetails() {
    return axios.post('/account/getOrganizationDetails', {}).then(response => {
      console.log(response.data, '/account/getOrganizationDetails');
      return response;
    });
  },
  updateOrganizationSettings(
    self_user_reg_config_enabled,
    self_user_reg_approval_required,
    default_license_id,
    default_role_id,
    default_department_id,
    cross_silo_feature,
    handover_feature,
    enable_publishing_news,
    enable_publishing_news_child,
    create_contact_for_lite_user,
    revenue_field_for_lite_user,
    show_org_in_registration
  ) {
    const body = {
      self_user_reg_config_enabled: self_user_reg_config_enabled ? 1 : 0,
      self_user_reg_approval_required: self_user_reg_approval_required ? 1 : 0,
      default_license_id,
      cross_silo_feature: cross_silo_feature ? 1 : 0,
      handover_feature: handover_feature ? 1 : 0,
      create_contact_for_lite_user: create_contact_for_lite_user ? 1 : 0,
      revenue_field_for_lite_user: revenue_field_for_lite_user ? 1 : 0,
      enable_publishing_news: enable_publishing_news ? 1 : 0,
      enable_publishing_news_child: enable_publishing_news_child ? 1 : 0,
      default_department_id,
      default_role_id,
      show_org_in_registration
    };
    return axios.post('/account/updateOrganizationSettings', body).then(response => response.data);
  },

  updateRegistrationUrl(account_id) {
    const url = '/account/updateRegistrationUrl';
    return axios.post(url, {account_id}).then(response => {
      return response.data.data;
    });
  },

  async getFeatureStatus() {
    const url = '/master/admin/features';
    const response = await axios.post(url, {});
    const result = response.data.data.data.rows;
    return result.reduce((prev, {name, disabled}) => ({ ...prev, [name]: disabled }), {});
  }
}