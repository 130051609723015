import React, { Component } from "react";
import * as actions from "./redux/actions";
import AsideLeft from "./component/aside-left";
import { connect } from "react-redux";
import { request } from "http";
import { openSnackbar } from '../../common/Snackbar/snackbar'
import { saveToDrafts, publishSalesNews, initializeSalesNews ,initializePublishNews, initializeInDraftSalesNews } from "../SalesNews/redux/actions";
import { initializeSalesFeeds } from "../aside-right/redux/actions";
import profileAction from '../Profile/redux/actions';
import {getStorageItem} from "../../common/common-functions";
import { getCategorySources } from "../newLead/redux/actions";

class AsideLeftContainer extends Component {

  constructor(props){
    super(props);
    this.state = {
      // user: JSON.parse(localStorage.getItem("user"))
      user: JSON.parse(getStorageItem("user"))
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.snackbarStatus !== this.props.snackbarStatus) {
      this.props.snackbarStatus && openSnackbar(this.props.snackbarStatus, this.props.callFlag ? "snack-success" : "snack-error")


      // const params = {
      //   arrayFilters: [
      //     {
      //       "is_active": 0,
      //       "is_deleted": 0,
      //       "created_by": this.state.user.user_id
      //     }
      //   ],
      //   selectFilters: [],
      //   sort: {
      //     field: "updated_at",
      //     sortOrder: "DESC"
      //   },
      //   paginate: {
      //     page: "0",
      //     limit: "10"
      //   },
      // }


      // const requestsales = {
      //   arrayFilters: [
      //     {
      //       is_active: 1,
      //       is_deleted: 0
      //     }
      //   ],
      //   selectFilters: ["id", "title", "cover_image", "news_body", "created_at", "updated_at"],
      //   sort: {
      //     field: "updated_at",
      //     sortOrder: "DESC"
      //   },
      //   paginate: {
      //     page: "0",
      //     limit: "10"
      //   },
      // };
      // this.props.initializeSalesNews(requestsales);

      // this.props.initializeInDraftSalesNews(params)

      // const requestBody = {
      //   arrayFilters: [
      //     {
      //       is_active: 1,
      //       is_deleted: 0,
      //       "created_by":this.state.user.user_id
      //     }
      //   ],
      //   selectFilters: ["id", "title", "cover_image", "news_body", "created_at", "updated_at"],
      //   sort: {
      //     field: "updated_at",
      //     sortOrder: "DESC"
      //   },
      //   paginate: {
      //     page: "0",
      //     limit: "10"
      //   },
      // };
      // this.props.initializePublishNews(requestBody);






    }
    // if(this.props.updatedUserDetailsFlag && prevProps.updatedUserDetailsFlag !== this.props.updatedUserDetailsFlag){
    //   // let params = {
    //   //   "user_id": this.state.user.user_id
    //   // }
    //   this.props.getUserDetails({});
    // }
  }

  render() {
    const logoutProps = this.props;
    console.log(this.props, "Hello");
    return (
      <div>
        <AsideLeft {...logoutProps} user={this.props.userDetails ? this.props.userDetails : this.state.user} companyList={this.props.companyList} />
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    snackbarStatus: state.salesNews.snackbarStatus,
    callFlag: state.salesNews.callFlag,
    user: state.asideLeft.user,
    companyList: state.asideLeft.companyList,
    countryList: state.asideLeft.countryList,
    updatedUserDetailsFlag: state.ProfileReducer.updatedUserDetailsFlag,
    userDetails: state.ProfileReducer.userDetails,
    points: state.asideLeft.points,
    organizationList: state.asideLeft.organizationList,
    organizationListNews: state.asideLeft.organizationListNews,
    selectedOrganizationList: state.salesNews.selectedOrganizationList,
    //CRM Integration
    is_parent_org: state.asideLeft.is_parent_org
    //CRM Integration
  };
};

const mapDispatchToProps = dispatch => {
  return {
    initializeSalesFeeds: requestBody => dispatch(initializeSalesFeeds(requestBody)),
    initializeSalesNews: requestBody => dispatch(initializeSalesNews(requestBody)),
    publishSalesNews: requestBody => dispatch(publishSalesNews(requestBody)),
    saveToDrafts: requestBody => dispatch(saveToDrafts(requestBody)),
    getCompanies: requestBody => dispatch(actions.getCompanies(requestBody)),
    getCountries: requestBody => dispatch(actions.getCountries(requestBody)),
    userLogout: () => dispatch(actions.userLogout()),
    getUserDetails: requestBody => dispatch(profileAction.getUserDetails(requestBody)),
    getPoints: () => dispatch(actions.getPoints()),
    initializeInDraftSalesNews: requestBody => dispatch(initializeInDraftSalesNews(requestBody)),
    initializePublishNews: requestBody => dispatch(initializePublishNews(requestBody)),
    getCategorySources: requestBody => dispatch(getCategorySources(requestBody)),
    getOrganizationList: requestBody => dispatch(actions.getOrganizationList(requestBody)),
    getOrganizationListNews: requestBody => dispatch(actions.getOrganizationListNews(requestBody)),
    //CRM Integration
    isParentOrg: requestBody => dispatch(actions.isParentOrg(requestBody))
    //CRM Integration
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AsideLeftContainer);