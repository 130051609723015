import React from "react";
import styles from "./NewContact.module.scss";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  addNewPerson,
  addNewCompany,
  getAllStates,
  getAllCities,
  resetStateCity,
  resetCity,
  updateCompany,
  updatePerson,
  resetAll,
} from "./redux/actions";
import { getContactList } from "../MyPipeline/redux/actions";
import { getPipelineLeadList } from "../MyPipeline/redux/actions";
import { getCompanies } from "../aside-left/redux/actions";
import { openSnackbar } from "../../common/Snackbar/snackbar";
import Person from './component/person';
import Company from './component/company';
import { contactListRequestBody } from "../../common/apiParameters";

const DialogTitle = withStyles(theme => ({
  root: {
    // width: '950px',
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding:"8px"
    //padding: theme.spacing.unit * 2
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function TabContainer(props) {
  return (
    <Typography component="div" className={props.className}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class NewCompany extends React.Component {
  state = {
    open: false,
    value: 0,
    newlyAddedCompany: null,
    newlyAddedPerson: null
  };

  static getDerivedStateFromProps(nextProps) {
    if (nextProps && nextProps.addNewCompanyError === true) {
      nextProps.hideDialog();
      nextProps.resetAll();
      openSnackbar("Failed to add company.", "snack-error");
    } else if (nextProps && nextProps.addNewCompanyError === false) {
      if (nextProps && nextProps.newlyAddedCompany) {
        nextProps.hideDialog(nextProps.newlyAddedCompany);
      } else {
        nextProps.hideDialog();
      }
      nextProps.resetAll();
      openSnackbar("Company added successfully.", "snack-success");
      // let params = {
      //   arrayFilters: [
      //     {
      //       is_deleted: 0
      //     }
      //   ],
      //   // selectFilters: ["id", "company_name"],
      //   selectFilters: [],
      //   sort: {
      //     field: "company_name",
      //     sortOrder: "ASC"
      //   },
      //   paginate: {
      //     page: "",
      //     limit: ""
      //   }
      // };
      // nextProps.getCompanies(params);
    }

    if (nextProps && nextProps.addNewPersonError === true) {
      nextProps.hideDialog();
      nextProps.resetAll();
      openSnackbar("Failed to add person.", "snack-error");
    } else if (nextProps && nextProps.addNewPersonError === false) {
      if (nextProps && nextProps.newlyAddedPerson) {
        nextProps.hideDialog(nextProps.newlyAddedPerson);
      } else {
        nextProps.hideDialog();
      }
      nextProps.resetAll();
      openSnackbar("Contact person added successfully.", "snack-success");
    }

    if (nextProps && nextProps.updateCompanyError === true) {
      nextProps.hideDialog();
      nextProps.resetAll();
      openSnackbar("Failed to update company!", "snack-error");
    } else if (nextProps && nextProps.updateCompanyError === false) {
      nextProps.hideDialog();
      nextProps.resetAll();
      openSnackbar("Company updated successfully.", "snack-success");
      // let params = {
      //   arrayFilters: [
      //     {
      //       is_deleted: 0
      //     }
      //   ],
      //   // selectFilters: ["id", "company_name"],
      //   selectFilters: [],
      //   sort: {
      //     field: "company_name",
      //     sortOrder: "ASC"
      //   },
      //   paginate: {
      //     page: "",
      //     limit: ""
      //   }
      // };
      // nextProps.getCompanies(params);
    }

    if (nextProps && nextProps.updatePersonError === true) {
      nextProps.hideDialog();
      nextProps.resetAll();
      openSnackbar("Failed to update person!", "snack-error");
    } else if (nextProps && nextProps.updatePersonError === false) {
      nextProps.hideDialog();
      nextProps.resetAll();
      openSnackbar("Person updated successfully.", "snack-success");
    }

    if (
      (nextProps.updateCompanyError !== null ||
        nextProps.updatePersonError !== null ||
        nextProps.addNewCompanyError !== null ||
        nextProps.addNewPersonError !== null) &&
      window.location.pathname == "/activities"
    ) {
      const companyListRequestBody = {
        arrayFilters: [
          {
            is_deleted: 0
          }
        ],
        selectFilters: [],
        sort: {
          field: "company_name",
          sortOrder: "ASC"
        },
        paginate: {
          page: "",
          limit: ""
        }
      };
      nextProps.getCompanies(companyListRequestBody);
    }

    if (
      (nextProps.updateCompanyError !== null ||
        nextProps.updatePersonError !== null ||
        nextProps.addNewCompanyError !== null ||
        nextProps.addNewPersonError !== null) &&
      window.location.pathname == "/my-pipeline"
    ) {
      nextProps.getContactList(contactListRequestBody);
    

      const companyListRequestBody = {
        arrayFilters: [
          {
            is_deleted: 0
          }
        ],
        selectFilters: [],
        sort: {
          field: "company_name",
          sortOrder: "ASC"
        },
        paginate: {
          page: "",
          limit: ""
        }
      };
      nextProps.getCompanies(companyListRequestBody);

      // Call get lead data
      if (nextProps.filterList) {
        const filterVals = {};
        if (nextProps.filterList.length !== 0) {
          filterVals.lead_current_status_id = nextProps.filterList;
        }
  
        let organizationArray = null;
        let current_user_selection = null;
        let licenseType = null;
  
        if (nextProps.orgSelected === null) {
          organizationArray = [nextProps.currentUser.organization_id];
        } else {
          organizationArray = [nextProps.orgSelected.id];
        }
  
        if (nextProps.userSelected === null) {
          current_user_selection = nextProps.currentUser.user_id;
        } else {
          current_user_selection = nextProps.userSelected.id;
        }
  
        if (nextProps.userSelected === null) {
          licenseType = nextProps.currentUser.license_type.actual_name;
        } else {
          licenseType = nextProps.userSelected.license_type.actual_name;
        }
  
        let params = {
          // user_id:
          //   nextProps.currentUser && nextProps.currentUser.user_id
          //     ? nextProps.currentUser.user_id
          //     : "",
          from: "my_pipeline",
          arrayFilters: [filterVals],
          selectFilters: [
            "id",
            "lead_title",
            "expected_closing_date",
            "lead_value",
            "is_confidential",
            "notes",
            "is_hand_over",
            "is_won",
            "is_bell_ringed",
            "project_location",
            "created_at",
            "linkedin_profile",
          ],
          sort: {
            field: "id",
            sortOrder: "DESC",
          },
          paginate: {
            page: "0",
            limit: "10",
          },
          organizationArray: organizationArray,
          user_id: current_user_selection,
          license_type: licenseType,
        };
        nextProps.getPipelineLeadList(params);
      }
    }
    if (
      (nextProps.updateCompanyError !== null ||
        nextProps.updatePersonError !== null ||
        nextProps.addNewCompanyError !== null ||
        nextProps.addNewPersonError !== null) &&
      (window.location.pathname == "/sales-news" || window.location.pathname == "/sales-community")
    ) {
      const companyListRequestBody = {
        arrayFilters: [
          {
            is_deleted: 0
          }
        ],
        selectFilters: [],
        sort: {
          field: "company_name",
          sortOrder: "ASC"
        },
        paginate: {
          page: "",
          limit: ""
        }
      };
      nextProps.getCompanies(companyListRequestBody);
    }
    return nextProps;
  }

  handleTabChange = (event, value) => {
    this.props.resetStateCity();
    const personFields = {
      personName: "",
      personCompany: 0,
      personPhoneNumber: "",
      personEmail: "",
      personAddress1: "",
      personAddress2: "",
      personCountry: 0,
      personState: 0,
      personCity: 0,
      personNote: ""
    };
    const companyFields = {
      companyName: "",
      companyCode: "",
      companyOfficeAddress1: "",
      companyOfficeAddress2: "",
      companyPostalAddress: "",
      companyCountry: 0,
      companyState: 0,
      companyCity: 0,
      companyWebSite: "",
      companyContactPersonName: "",
      companyContactPersonPhoneNumber: "",
      companyContactPersonEmail: "",
      companyNote: ""
    };
    this.setState({
      personFields: personFields,
      personErrors: {},
      companyErrors: {},
      companyFields: companyFields,
      value
    });
  };

  render() {
    const { value } = this.state;
    return (
      <div className="newContact-wrap">
        <TabContainer className={styles.tabsContainerPadding}>
          <DialogTitle
            id="customized-dialog-title"
            onClose={this.props.hideDialog}
          >
            <div className={styles.dialog_title}> { (this.props.currentCompany || this.props.currentPerson) ? "Update / Edit contact" : "New contact"}</div>
          </DialogTitle>

            <Tabs
              className={styles.tabsPadding}
              value={value}
              onChange={this.handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >

              <Tab label="Company"
                className={
                  value === 0 || (!this.props.addPersonFromLead && this.props.addCompanyFromLead) ? styles.tabsDesign : styles.tabNotselected
                }
                disabled={typeof this.props.currentPerson !== "undefined"} />
              <Tab
                className={
                  (value === 1 && !this.props.addPersonFromLead && !this.props.addCompanyFromLead) ||
                    (value === 1 && this.props.addPersonFromLead) ||
                    (value === 1 && !this.props.addCompanyFromLead)
                    ? styles.tabsDesign : styles.tabNotselected
                }
                label="Person"
                disabled={typeof this.props.currentCompany !== "undefined"}
              />

            </Tabs>
          
          {
            (value === 0 && !this.props.addPersonFromLead && !this.props.addCompanyFromLead) ||
            // (value === 1 && this.props.addPersonFromLead) ||
            (value === 0 && this.props.addCompanyFromLead) ?
            <Company {...this.props} />:
            <Person {...this.props} /> 
          }
            
        </TabContainer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    addNewPersonError: state.addNewContact.addNewPersonError,
    addNewCompanyError: state.addNewContact.addNewCompanyError,
    newlyAddedCompany: state.addNewContact.newlyAddedCompany,
    newlyAddedPerson: state.addNewContact.newlyAddedPerson,
    companyList: state.asideLeft.companyList,
    countryList: state.asideLeft.countryList,
    stateList: state.addNewContact.stateList,
    cityList: state.addNewContact.cityList,
    updateCompanyError: state.addNewContact.updateCompanyError,
    updatePersonError: state.addNewContact.updatePersonError,
    filterList: state.filterData.filterValue,
    orgSelected: state.mypipeline.mypipelineOrgSelected,
    userSelected: state.mypipeline.mypipelineUserSelected,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addNewPerson: requestBody => dispatch(addNewPerson(requestBody)),
    addNewCompany: requestBody => dispatch(addNewCompany(requestBody)),
    getAllStates: id => dispatch(getAllStates(id)),
    getAllCities: (countryId, stateId,isCity) => dispatch(getAllCities(countryId, stateId,isCity)),
    updateCompany: requestBody => dispatch(updateCompany(requestBody)),
    updatePerson: requestBody => dispatch(updatePerson(requestBody)),
    resetAll: () => dispatch(resetAll()),
    getContactList: requestBody => dispatch(getContactList(requestBody)),
    getPipelineLeadList: requestBody => dispatch(getPipelineLeadList(requestBody)),
    resetStateCity: () => dispatch(resetStateCity()),
    resetCity: () => dispatch(resetCity()),
    getCompanies: requestBody => dispatch(getCompanies(requestBody)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewCompany);
