import React, { useState, useEffect } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import styles from "./page.module.scss";
import styled from "styled-components";
import NoRecordFound from "../../components/ErrorMessages/NoRecordsFound/NoRecordsFound";
import SearchInput from "./CustomDropDown";
import Api from "./Api";
import LargeScreenLoader from "../../common/Loaders/PulseLoader/pulse-loader";
import CustomTable from "../PendingRequest/Table";
import TableRow from "@material-ui/core/TableRow";
import MDTableCell from "@material-ui/core/TableCell";
import Switch from "react-switch";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import Button from "@material-ui/core/Button";
import _ from "lodash";
import { showSnackbarMessage } from "../../common/common-functions";

const PrimaryButton = styled(Button)`
  font-size: 0.75em;
  text-transform: none;
  white-space: nowrap;
  text-align: center;
  background-color: ${(props) => (props.disabled ? "gray" : "#3a4860")};
  padding-left: 2em;
  padding-right: 2em;
  color: white !important;
  height: 4.25em;
  padding: 0 0.5em;
  padding-left: 4em;
  padding-right: 4em;
  &:hover {
    background-color: #19d98b;
  }
`;

const TableCell = styled(MDTableCell)`
  padding: 10px;
  text-align: center;
`;

const Span = styled.span`
  font-size: 0.875em !important;
`;

const SmallProfilePic = styled.img`
  width: 2.8125em;
  height: 2.8125em;
  border-radius: 1.5625em;
  margin-right: 0.9375em;
`;

const ItemComponent = ({ item, containerStyle }) => (
  <div style={{ ...containerStyle, display: "flex", alignItems: "center" }}>
    <div style={{ flex: 1 }}>
      <SmallProfilePic
        src={
          item.url || require("../../../assets/images/bi/employeeMissing.png")
        }
      />
    </div>
    <div style={{ flex: 10 }}>
      <label
        className={styles.cust_item}
      >{`${item.name}, ${item.role} - ${item.account}`}</label>
    </div>
  </div>
);

const headers = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    sort: false,
    textAlign: "center",
  },
  {
    id: "access",
    numeric: false,
    disablePadding: true,
    label: "Org. Access",
    textAlign: "center",
  },
  {
    id: "role",
    numeric: false,
    disablePadding: true,
    label: "Role",
    textAlign: "center",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Description",
    sort: false,
    textAlign: "center",
  },
];

let originalConfiguration = null;

export default function ManageAccess() {
  const [searchText, setSearchText] = useState("");
  const [selected, setSelected] = useState(null);
  const [userOptions, setUserOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedUserAccessInfo, setSelectedUserAccessInfo] = useState(null);

  useEffect(() => {
    fetchParentOrgUsers();
  }, []);

  useEffect(() => {
    if (selected) {
      fetchUserAccessDetails(selected.id);
    }
  }, [selected]);

  const fetchParentOrgUsers = async (user_id) => {
    setLoading(true);
    const userOptions = await Api.getParentOrgUsers(user_id);
    setUserOptions(userOptions);
    setLoading(false);
  };

  const fetchUserAccessDetails = async (userId) => {
    setLoading(true);
    setSelectedUserAccessInfo(null);
    const selectedUserAccessInfo = await Api.getOrgAccessOfAUser(userId);
    originalConfiguration = selectedUserAccessInfo.map((a) => ({ ...a }));
    setSelectedUserAccessInfo(selectedUserAccessInfo);
    setLoading(false);
  };

  const handleOnSave = async () => {
    const configuration = selectedUserAccessInfo.map((a) => ({
      account_id: a.id,
      access: a.access,
    }));
    
    setLoading(true);

    const response = await Api.saveConfiguration(selected.id, configuration);
    originalConfiguration = selectedUserAccessInfo.map((a) => ({ ...a }));

    if (response.data.message) {
      showSnackbarMessage(response.data.message, "snack-success");
    } else {
      showSnackbarMessage(response.errorMessage, "snack-error");
    }

    setLoading(false);
  };

  // const deboucedHandleOnSearch = _.debounce(setSearchText, 300);

  const renderUserSearchBar = () => {
    return (
      <SearchInput
        onSearch={setSearchText}
        placeholder="Search Users"
        value={searchText}
        options={userOptions}
        selected={selected}
        onSelect={setSelected}
        itemComponent={ItemComponent}
      />
    );
  };

  const toggle = (row) => (newChecked) => {
    setSelectedUserAccessInfo(
      selectedUserAccessInfo.map((record) => {
        if (record.id === row.id) {
          record.access = newChecked;
        }
        return record;
      })
    );
  };

  const handleOnCancel = () => {
    setSelectedUserAccessInfo(originalConfiguration.map((a) => ({ ...a })));
  };

  const renderRow = (row, i) => {
    const cardStyle = {
      backgroundColor: "#ffffff",
      boxShadow: "0 0.25em 0.5em 0 rgba(0, 0, 0, 0.2)",
    };
    const rowStyle = row.is_parent ? cardStyle : {};
    const nameStyle = row.is_parent ? { marginLeft: 6 } : {};
    return (
      <TableRow
        key={row.id}
        onClick={() => console.log(row)}
        style={{ height: 50, ...rowStyle }}
      >
        <TableCell style={{ textAlign: "left" }}>
          {row.is_parent && (
            <HomeOutlinedIcon style={{ color: "#62626E" }} />
          )}
          <Span style={nameStyle}>{row.name}</Span>
        </TableCell>
        <TableCell style={{ textAlign: "left" }}>
          <Span style={{ marginLeft: 15 }}>
            <Switch
              onChange={toggle(row)}
              checked={row.access}
              uncheckedIcon={false}
              checkedIcon={false}
              onColor={"#19C080"}
              height={20}
              width={40}
              disabled={row.is_parent}
              className={styles.toggle_button}
            />
          </Span>
        </TableCell>
        <TableCell style={{ textAlign: "left" }}>
          <Span>{selected.role}</Span>
        </TableCell>
        <TableCell style={{ textAlign: "left" }}>
          <Span>{row.description}</Span>
        </TableCell>
      </TableRow>
    );
  }

  const sortOrg = (orgs) => {
    const [firstOrg, ...restOrg] = orgs;
    return [firstOrg, ..._.sortBy(restOrg, ['name'])];
  }
  
  const renderTable = () => {
    return (
      <div className="row" style={{ width: "100%" }}>
        <div className="col-md-12">
          <CustomTable
            count={selectedUserAccessInfo.length}
            headers={headers}
            data={sortOrg(selectedUserAccessInfo)}
            initialOrder="asc"
            initialSortField="name"
            disableSort
            tablePaginationStyle={{}}
            renderRow={renderRow}
          />
          <div className="col-md-12" style={{ textAlign: "right" }}>
            <div className={styles.bottom_update_button}>
              <button
                type="button"
                class="btn btn-default"
                style={{ marginRight: 20 }}
                onClick={handleOnCancel}
              >
                <b>Cancel</b>
              </button>
              <PrimaryButton
                disabled={
                  _.differenceWith(
                    originalConfiguration,
                    selectedUserAccessInfo,
                    _.isEqual
                  ).length === 0
                }
                onClick={handleOnSave}
              >
                Save
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderPage = () => {
    return (
      <div className="main-wrapper">
        <div className="breadcrumbs">
          <div className="row justify-content-between">
            <div className="col-md-4">
              <h3 className={styles.page_title}>Manage Org. Access</h3>
            </div>
          </div>
          <div className="breadcrumbs_container">
            <Breadcrumbs separator="›" arial-label="Breadcrumb">
              <Link to="/sales-news">Home</Link>
              <Link to="/administration">Administration</Link>
              <Link to="/administration/user_management">User Management</Link>
              <span>Manage Org. Access</span>
            </Breadcrumbs>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-6">{renderUserSearchBar()}</div>
          </div>
          <hr />
        </div>
        <div className="container-body">
          <div className="row justify-content-center mb-4">
            <div
              className={`col-xs-12 col-md-12 col-lg-12 col-12 `}
              style={{
                fontFamily: "sans-serif !important",
                fontSize: "14px !important",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {selectedUserAccessInfo && selected ? renderTable() : <NoRecordFound />}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {loading && <LargeScreenLoader />}
      {renderPage()}
    </>
  );
}
