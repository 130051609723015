import React, { useState, useEffect } from "react";
import moment from "moment";
import styles from "../graphContainer.module.scss";

import SearchInput from "../../../components/SearchInput";
import API from "../API";
import util from "../util";
import CustomTable from "../../../pages/PendingRequest/Table";
import TableRow from "@material-ui/core/TableRow";
import MDTableCell from "@material-ui/core/TableCell";
import styled from "styled-components";
import tableBusinessmanPNG from "../../../../assets/images/bi/businessman.png";
import handshakeIconGrey from "../../../../assets/images/content-images/beforeHandshake.png";
import handshakeIconOne from "../../../../assets/images/content-images/afterHandshake.png";
import handshakeIconTwo from "../../../../assets/images/content-images/handshake.png";
import handoverAcceptedIcon from "../../../../assets/images/leads-icons/handoverAccepted.png";
import handoverNotAcceptedIcon from "../../../../assets/images/leads-icons/handoverNotAcceptedIcon.png";
import crossalesNotAcceptedIcon from "../../../../assets/images/leads-icons/crossalesNotAcceptedIcon.png";
import crossalesAcceptedIcon from "../../../../assets/images/leads-icons/crossalesAcceptedIcon.png";
import { getStorageItem } from "../../../common/common-functions";
import * as commonFunctions from "../../../common/common-functions";
import LeadDialog from "../../../components/LeadDialog/LeadDialog";
import LeadDetailPopup from "../../../pages/LeadDetailPopup/component/lead-detail-popup";
import Dialog from "@material-ui/core/Dialog";
import CompanyDetailPopup from "../../../pages/CompanyDetailPopup/component/companyDetailPopup";
import PersonDetailPopup from "../../../pages//PersonDetailPopup/component/personDetailPopup";
import LeadContainer from "../../newLead/LeadContainer";
import asideLeftStyles from "../../../pages/aside-left/component/aside-left.module.scss";
import LargeScreenLoader from "../../../common/Loaders/PulseLoader/pulse-loader";
import NoRecordFound from "../../../components/ErrorMessages/NoRecordsFound/NoRecordsFound";

const TableCell = styled(MDTableCell)`
  padding: 10px;
  text-align: center;
`;
const Span = styled.span`
  font-size: 0.875em !important;
`;

const headers = [
  {
    id: "lead_status.name",
    numeric: false,
    disablePadding: true,
    label: "Stage",
    sort: true,
    textAlign: "center",
  },
  {
    id: "leads",
    numeric: false,
    disablePadding: true,
    label: "Leads",
    textAlign: "center",
  },

  {
    id: "lead_title",
    numeric: false,
    disablePadding: true,
    label: "Lead Title",
    sort: true,
    textAlign: "center",
  },
  {
    id: "contact_company.company_name",
    numeric: false,
    disablePadding: true,
    label: "Client Name",
    sort: true,
    textAlign: "center",
  },
  {
    id: "createdBy.name",
    numeric: false,
    disablePadding: true,
    label: "Created By",
    sort: true,
    textAlign: "center",
  },
  {
    id: "assignedTo.name",
    numeric: false,
    disablePadding: true,
    label: "Assign To",
    sort: true,
    textAlign: "center",
  },

  {
    id: "created_at",
    numeric: false,
    disablePadding: true,
    label: "Created On",
    sort: true,
    textAlign: "center",
  },
];

const getDefaultDateRange = () => {
  return {
    from_date: moment().startOf("year"),
    to_date: moment(),
  };
};

const Leads = ({
  organizationArray,
  fromDate,
  toDate,
  getStatusLogs,
  statusLogs,
  selectOrg,
  ...rest
}) => {
  const [searchText, setSearchText] = useState("");
  const [leads, setLeads] = useState([]);
  const [currentSalesItem, setCurrentSalesItem] = useState(null);
  const [open, setOpen] = useState(false);
  const [openLeadModal, setOpenLeadModal] = useState(false);
  const [openCompanyDetail, setOpenCompanyDetail] = useState(false);
  const [openPersonDetail, setOpenPersonDetail] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const currentUser = JSON.parse(getStorageItem("user"));

  useEffect(() => {
    fetchLeads();
  }, [organizationArray, fromDate, toDate]);

  const fetchLeads = async () => {
    setShowLoader(true);
    const leads = await API.getLeadTable(
      util.getParams(organizationArray, fromDate, toDate)
    );
    setLeads(leads);
    setShowLoader(false);
  };

  const handleOnSearch = (searchString) => {
    setSearchText(searchString);
  };

  const clearGrid = () => {
    setSearchText("");
  };

  const filterString = (arr = [], string = "") => {
    const searchString = string.toLowerCase();

    const result = (arr || []).filter((e) => {
      return (
        e.lead_title.toLowerCase().search(searchString) !== -1 ||
        (e.contact_company &&
          e.contact_company.company_name.toLowerCase().search(searchString) !==
            -1) ||
        (e.assignedTo &&
          e.assignedTo.name.toLowerCase().search(searchString) !== -1) ||
        (e.lead_status &&
          e.lead_status.name.toLowerCase().search(searchString) !== -1) ||
        (e.createdBy &&
          e.createdBy.name.toLowerCase().search(searchString) !== -1)
      );
    });

    return result;
  };

  const sortedData = filterString(leads, searchText);

  const handleClickOpen = (item, index) => {
    setCurrentSalesItem(item);
    setOpen(true);

    //remove z-index of header and left menu bar , if dialog is open
    var headerElement = document.getElementsByClassName(asideLeftStyles.appBar);
    if (headerElement && headerElement[0]) {
      headerElement[0].classList.add("modal-zindex");
    }

    var leftNavElement = document.getElementsByClassName(asideLeftStyles.brand);
    if (leftNavElement && leftNavElement[0]) {
      leftNavElement[0].parentNode.classList.add("modal-zindex");
    }

    let params = {
      lead_id: item.id,
    };
    getStatusLogs(params);
  };

  const handleClose = () => {
    setOpenCompanyDetail(false);
    setOpenPersonDetail(false);
    setOpen(false);
    var headerElement = document.getElementsByClassName(asideLeftStyles.appBar);
    if (headerElement && headerElement[0]) {
      headerElement[0].classList.remove("modal-zindex");
    }

    var leftNavElement = document.getElementsByClassName(asideLeftStyles.brand);
    if (leftNavElement && leftNavElement[0]) {
      leftNavElement[0].parentNode.classList.remove("modal-zindex");
    }
  };

  const hideDialog = () => {
    setCurrentSalesItem(null);
    setOpen(false);
  };

  const hideModal = () => {
    setOpenLeadModal(false);

    var headerElement = document.getElementsByClassName(asideLeftStyles.appBar);
    headerElement[0].classList.remove("modal-zindex");

    var leftNavElement = document.getElementsByClassName(asideLeftStyles.brand);
    leftNavElement[0].parentNode.classList.remove("modal-zindex");
  };

  const handleWinCallBack = (currentSalesItem) => {
    var headerElement = document.getElementsByClassName(asideLeftStyles.appBar);
    headerElement[0].classList.add("modal-zindex");

    var leftNavElement = document.getElementsByClassName(asideLeftStyles.brand);
    leftNavElement[0].parentNode.classList.add("modal-zindex");

    setCurrentSalesItem(currentSalesItem);
    setOpenLeadModal(false);
    setOpen(true);
  };

  const openUpdateLeadPopup = async (statusLogs) => {
    setOpen(false);
    setOpenLeadModal(true);

    var headerElement = document.getElementsByClassName(asideLeftStyles.appBar);
    headerElement[0].classList.add("modal-zindex");

    var leftNavElement = document.getElementsByClassName(asideLeftStyles.brand);
    leftNavElement[0].parentNode.classList.add("modal-zindex");
  };

  const getImage = (row) => {
    if (row.type === "handover") {
      return row.is_accepted ? handoverAcceptedIcon : handoverNotAcceptedIcon;
    } else if (row.type === "cross-silo") {
      return row.is_accepted ? crossalesAcceptedIcon : crossalesNotAcceptedIcon;
    }
    return null;
  };

  const getHandShakeImage = (row) => {
    if (
      row.createdBy &&
      row.createdBy.license_type &&
      ["BASIC", "LITE"].includes(row.createdBy.license_type.actual_name)
    ) {
      switch (row.is_hand_over) {
        case 0:
          return handshakeIconGrey;
        case 1:
          return handshakeIconOne;
        case 2:
          return handshakeIconTwo;
        default:
          return null;
      }
    }
    return tableBusinessmanPNG;
  };

  const renderTable = () => {
    if (sortedData.length === 0) return <NoRecordFound />;

    return (
      sortedData.length > 0 && (
        <CustomTable
          count={sortedData.length}
          headers={headers}
          data={sortedData}
          initialOrder="desc"
          initialSortField="created_at"
          renderRow={(row, i) => {
            const handOverImage = getHandShakeImage(row);
            const handOverOrCrossSales = getImage(row);

            return (
              <TableRow key={row.id} onClick={() => console.log(row)}>
                <TableCell style={{ textAlign: "left" }}>
                  <Span>{row.lead_status.name}</Span>
                </TableCell>
                <TableCell style={{ textAlign: "left" }}>
                  {handOverImage && <img src={handOverImage} width="33" alt="Handshake" />}
                  {handOverOrCrossSales && <img
                    src={getImage(row)}
                    style={{ marginLeft: 4 }}
                    width="33"
                    alt="Handover and Cross Silo"
                  />}
                </TableCell>
                <TableCell
                  style={{ textAlign: "left", cursor: "pointer", maxWidth: 50 }}
                  onClick={() => handleClickOpen(row, i)}
                >
                  <Span className={styles.tableLink}>{row.lead_title}</Span>
                </TableCell>
                <TableCell style={{ textAlign: "left", maxWidth: 50 }}>
                  <Span>
                    {row.contact_company && row.contact_company.company_name}
                  </Span>
                </TableCell>
                <TableCell style={{ textAlign: "left", maxWidth: 50 }}>
                  <Span>{row.createdBy && row.createdBy.name}</Span>
                </TableCell>
                <TableCell style={{ textAlign: "left", maxWidth: 50 }}>
                  <Span>{row.assignedTo && row.assignedTo.name}</Span>
                </TableCell>
                <TableCell style={{ textAlign: "left" }}>
                  <Span>
                    {moment(new Date(row.created_at)).format("YYYY-MM-DD")}
                  </Span>
                </TableCell>
              </TableRow>
            );
          }}
        />
      )
    );
  };

  return (
    <div className="container-body">
      <div className="row justify-content-center mb-4">
        <div
          className={`col-xs-12 col-md-12 col-lg-12 col-12 `}
          style={{
            fontFamily: "sans-serif !important",
            fontSize: "14px !important",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <SearchInput
              onSearch={handleOnSearch}
              value={searchText}
              placeholder="Search"
              style={{ width: 300 }}
            />
            <a className={styles.ahref} onClick={clearGrid}>
              Clear
            </a>
          </div>
          <hr></hr>
          {showLoader ? <LargeScreenLoader /> : renderTable()}
        </div>

        {commonFunctions.isAdminUser() && currentSalesItem ? (
          <LeadDialog
            lead={currentSalesItem}
            showDialog={open}
            statusLogs={statusLogs}
            hideDialog={hideDialog}
            getPipelineLeadList={rest.getPipelineLeadList}
            onRefresh={fetchLeads}
            onClose={handleClose}
          />
        ) : (
          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth={"lg"}
            className="pipe-linemain-div"
          >
            <LeadDetailPopup
              {...rest}
              fromPulse
              currentUser={currentUser}
              currentSalesItem={currentSalesItem}
              unparkLead={(params) => rest.unparkLead(params)}
              resetUnparkLeadFlag={() => rest.resetUnparkLeadFlag()}
              openUpdateLeadPopup={() => openUpdateLeadPopup()}
              // ringTheBell={()=>this.ringTheBell()}
              onClose={() => handleClose()}
              getPipelineLeadList={rest.getPipelineLeadList}
              onRefresh={fetchLeads}
            />
          </Dialog>
        )}

        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openCompanyDetail}
          maxWidth={"lg"}
          className="companyDetailPopup"
        >
          <CompanyDetailPopup
            handleClose={() => handleClose()}
            updation={false}
            organizationName={
              rest.revenueData &&
              rest.revenueData.account &&
              rest.revenueData.account.name
            }
          />
        </Dialog>

        <Dialog
          className="width"
          aria-labelledby="customized-dialog-title"
          open={openLeadModal}
          onClose={hideModal}
          maxWidth={"lg"}
          className="leadFromPopup"
        >
          <LeadContainer
            hideModal={hideModal}
            handleWinCallBack={(lead) => handleWinCallBack(lead)}
            openLeadDetailCallBack={(lead) => handleWinCallBack(lead)}
            currentLead={currentSalesItem}
            openPersonPopup={(company_id, companyObj) =>
              this.openPersonPopup(company_id, companyObj)
            }
          />
        </Dialog>

        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openPersonDetail}
          maxWidth={"lg"}
          className="personDetailPopup"
        >
          <PersonDetailPopup
            handleClose={() => handleClose()}
            updation={false}
            organizationName={
              rest.revenueData &&
              rest.revenueData.account &&
              rest.revenueData.account.name
            }
          />
        </Dialog>
      </div>
    </div>
  );
};

export default Leads;
