import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import styles from "./page.module.scss";
import styled from "styled-components";
import LargeScreenLoader from "../../common/Loaders/PulseLoader/pulse-loader";
import SearchInput from "../../components/SearchInput";
import MySelect from "../../components/SingleSelect";
import CustomTable from "../PendingRequest/Table";
import TableRow from "@material-ui/core/TableRow";
import MDTableCell from "@material-ui/core/TableCell";
import Switch from "react-switch";
import Button from "@material-ui/core/Button";
import useRoleConfiguration from "./useRoleConfiguration";
import { showSnackbarMessage } from "../../common/common-functions";

const PrimaryButton = styled(Button)`
  font-size: 0.75em;
  text-transform: none;
  white-space: nowrap;
  text-align: center;
  background-color: ${(props) => (props.disabled ? "gray" : "#3a4860")};
  padding-left: 2em;
  padding-right: 2em;
  color: white !important;
  height: 4.25em;
  padding: 0 0.5em;
  padding-left: 4em;
  padding-right: 4em;
  &:hover {
    background-color: #19d98b;
  }
`;

const TableCell = styled(MDTableCell)`
  padding: 10px;
  text-align: center;
`;

const Span = styled.span`
  font-size: 0.875em !important;
`;

const headers = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Module and FeatureSet",
    sort: false,
    textAlign: "center",
  },
  {
    id: "active",
    numeric: false,
    disablePadding: true,
    label: "Permission",
    textAlign: "center",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Description",
    sort: false,
    textAlign: "center",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: true,
    label: "",
    sort: false,
    textAlign: "center",
  },
];

export default function RoleConfiguration() {
  const {
    state: {
      loading,
      searchText,
      licenseOptions,
      selectedLicense,
      updating,
      searchedModuleAndFeatures,
    },
    actions: {
      handleOnCancel,
      handleOnSave,
      handleOnToggleCheckFeature,
      handleOnToggleCheckModule,
      handleOnExpandClick,
      handleOnSearch,
      handleOnLicenseSelect,
    },
  } = useRoleConfiguration();

  const onSave = () =>
    handleOnSave((response) => {
      showSnackbarMessage(
        response.message,
        response.status ? "snack-success" : "snack-error"
      );
    });

  const renderExpandedModuleRow = (module) => (feature) => {
    const nameStyle = { marginLeft: 18 };
    return (
      <TableRow
        key={feature.id}
        onClick={() => console.log(module)}
        style={{ height: 50 }}
      >
        <TableCell style={{ textAlign: "left" }}>
          <Span style={nameStyle}>{feature.name}</Span>
        </TableCell>
        <TableCell style={{ textAlign: "left" }}>
          <Span style={{ marginLeft: 15 }}>
            <Switch
              onChange={handleOnToggleCheckFeature(module)(feature)}
              checked={feature.active}
              uncheckedIcon={false}
              checkedIcon={false}
              onColor={"#19C080"}
              height={20}
              width={40}
              disabled={!feature.can_modify}
              className={styles.toggle_button}
            />
          </Span>
        </TableCell>
        <TableCell style={{ textAlign: "left" }}>
          <Span>{feature.description}</Span>
        </TableCell>
        <TableCell style={{ textAlign: "left" }}></TableCell>
      </TableRow>
    );
  };

  const renderRow = (module, i) => {
    const nameStyle = { marginLeft: 10 };
    const cardStyle = {
      backgroundColor: "#ffffff",
      boxShadow: "0 0.25em 0.5em 0 rgba(0, 0, 0, 0.2)",
    };
    return (
      <>
        <TableRow
          key={module.id}
          onClick={() => console.log(module)}
          style={{ height: 50, ...cardStyle }}
        >
          <TableCell style={{ textAlign: "left" }}>
            <Span style={nameStyle}>{module.name}</Span>
          </TableCell>
          <TableCell style={{ textAlign: "left" }}>
            <Span style={{ marginLeft: 15 }}>
              <Switch
                onChange={handleOnToggleCheckModule(module)}
                checked={true}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor={"#19C080"}
                height={20}
                width={40}
                disabled={true}
                className={styles.toggle_button}
              />
            </Span>
          </TableCell>
          <TableCell style={{ textAlign: "left" }}>
            <Span>{module.description}</Span>
          </TableCell>
          <TableCell
            style={{ textAlign: "left" }}
            onClick={handleOnExpandClick(module)}
          >
            {module.features && module.features.length > 0 && (
              <Span>
                {module.open ? (
                  <img
                    style={{ width: 20 }}
                    src={require("../../../assets/chevron-up.svg")}
                    alt="open"
                  />
                ) : (
                  <img
                    style={{ width: 20 }}
                    src={require("../../../assets/chevron-down.svg")}
                    alt="closed"
                  />
                )}
              </Span>
            )}
          </TableCell>
        </TableRow>
        {module.open && module.features.map(renderExpandedModuleRow(module))}
      </>
    );
  };

  const renderTable = () => {
    return (
      <div className="row" style={{ width: "100%" }}>
        <div className="col-md-12">
          <CustomTable
            count={searchedModuleAndFeatures.length}
            headers={headers}
            data={searchedModuleAndFeatures}
            initialOrder="desc"
            initialSortField="name"
            disableSort
            tablePaginationStyle={{}}
            renderRow={renderRow}
          />
          <div className="col-md-12" style={{ textAlign: "right" }}>
            <div className={styles.bottom_update_button}>
              <button
                type="button"
                class="btn btn-default"
                style={{ marginRight: 20 }}
                onClick={handleOnCancel}
              >
                <b>Cancel</b>
              </button>
              <PrimaryButton onClick={onSave}>Save</PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderPage = () => {
    return (
      <div className="main-wrapper">
        <div className="breadcrumbs">
          <div className="row justify-content-between">
            <div className="col-md-4">
              <h3 className={styles.page_title}>Role Configuration</h3>
            </div>
          </div>
          <div className="breadcrumbs_container">
            <Breadcrumbs separator="›" arial-label="Breadcrumb">
              <Link to="/sales-news">Home</Link>
              <Link to="/administration">Administration</Link>
              <span>Role Configuration</span>
            </Breadcrumbs>
          </div>
          <hr />
          <div className="row">
            <div
              className="col-md-6"
              style={{ display: "flex", alignItems: "center" }}
            >
              <b>Manage Permissions</b>
            </div>
            <div className="col-md-6">
              <div className={"row " + styles.noHorizontalMargin}>
                <div
                  className={"col-md-6 " + styles.licenseTypeOptionContainer}
                >
                  <MySelect
                    disableSearch
                    key="financial_year"
                    options={licenseOptions}
                    selected={selectedLicense}
                    onSelect={handleOnLicenseSelect}
                    fullWidth
                  />
                </div>

                <div className={"col-md-6 " + styles.searchInputContainer}>
                  <SearchInput
                    onSearch={handleOnSearch}
                    value={searchText}
                    placeholder="Search by keyword"
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
        <div className="container-body">
          <div className="row justify-content-center mb-4">
            <div
              className={`col-xs-12 col-md-12 col-lg-12 col-12 `}
              style={{
                fontFamily: "sans-serif !important",
                fontSize: "14px !important",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {updating ? <LargeScreenLoader /> : renderTable()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {loading && <LargeScreenLoader />}
      {renderPage()}
    </>
  );
}