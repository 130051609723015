import React from 'react';
import { PopoverHeader, PopoverBody, Popover } from 'reactstrap';
import styles from './profile-popup.module.scss';
import profile_phone_icon from '../../../assets/images/content-images/profile_phone_icon.png';
import profile_email_icon from '../../../assets/images/content-images/profile_email_icon.png';
import { Link } from "react-router-dom";
import { imageType } from '../../common/common-types'
class ProfilePopup extends React.PureComponent{
    render(){
        return (
            <div className={styles.profile_popover}>
                <PopoverHeader>
                    <div className="row">
                        <div className="col-md-4">
                            <div className={styles.image_section}>
                               { imageType.test(this.props.user.user_avatar) ? 
                                <img src={
                                    this.props.user.user_avatar.slice(0, this.props.user.user_avatar.lastIndexOf('/')) + "/medium_" +
                                    this.props.user.user_avatar.substring(this.props.user.user_avatar.lastIndexOf("/") + 1)
                                } className={styles.image}/> : <img src={require("../../../assets/userProfile.jpeg")} className={styles.image}/> }
                            </div>
                            <div className={styles.points_section}> 
                                <span>{this.props.points} points</span>
                            </div>
                        </div>
                        <div className={styles.pr_30 + " col-md-8 pl-0"}>
                            <div className={styles.name_section}>
                                <div className={styles.user_name}>{this.props.user.name}</div>
                                <div className={styles.role_label}>{this.props.user.role.display_name}<span style={{fontWeight: 'bold'}}>{this.props.user.department ? " ("+this.props.user.department.name+")": "" }</span></div>
                            </div>
                            <div className={styles.email_section}>
                                <img src={profile_email_icon} className={styles.email_icon}
                                />
                                <span className={styles.profileEmailId}>{this.props.user.email}</span>
                            </div>
                            <div className={styles.contact_section}>
                            <img src={profile_phone_icon} className={styles.phone_icon}
                                />
                                {this.props.user ? (this.props.user.dialing_code ? "+" + this.props.user.dialing_code + " " 
                                    : 
                                        (this.props.user.countryCode ? "+" +this.props.user.countryCode.dialing_code + " " : "")) 
                                    + this.props.user.contact : ""}
                            </div>
                        </div>
                    </div>
                </PopoverHeader>
                <PopoverBody>
                    <div className={styles.view_profile}><Link to="/profile" onClick={()=> this.props.toggle()}> View profile </Link> </div>
                </PopoverBody>
            </div>
        )
    }
}

export default ProfilePopup;