import React, { Component } from "react";
import styles from "./styles.module.scss";
import Select, { components } from "react-select";

export default class MultiSelect extends Component {
  Option = props => {
    const {
      getStyles,
      innerProps: { ref, ...restInnerProps }
    } = props;  
    const renderSwitch = selectName => {
      switch (selectName) {
        case "salesNews":
          return (
            <div>
              <components.Option {...props}>
                <div className={styles.checkboxContainer}>
                  <div className={styles.container}>
                    <input
                      type="checkbox"
                      checked={props.isSelected}
                      onChange={() => null}
                    />
                    <div className={styles.checkmark} />
                  </div>
                  <label>{props.children}</label>
                </div>
              </components.Option>
            </div>
          );

        case "my-pipeline-organizations":
          return props.value == this.props.currentUser.organization_id ? (
            <b style={{}}>{`${props.children} `}</b>
          ) : (
            <span style={{}}>{`${props.children} `}</span>
          );

        case "my-pipeline-users":
          return <span style={{}}>{`${props.children} `}</span>;

        default:
          break;
      }
    };

    return (
      <div {...restInnerProps} ref={ref} style={getStyles("option", props)}>
        <div style={{ display: "flex" }}>
          {renderSwitch(this.props.selectName)}
        </div>
      </div>
    );
  };

  onChangeHandler = e => {
    e.preventDefault();
  };

  handleChange = selectedOption => {
    switch (this.props.selectName) {
      case "salesNews":
        this.props.setOrganization({
          name: "org_list",
          value: selectedOption
        });
        break;

      case "my-pipeline-organizations":
        this.props.setOrganization(selectedOption);
        break;

      case "my-pipeline-users":
        this.props.setUser(selectedOption);
        break;

      default:
        break;
    }
  };

  customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      textOverflow: "ellipsis",
      maxWidth: "90%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      //display: "initial",
      borderColor: "black"
    }),

    menuList: (provided, state) => ({
      ...provided,
      paddingTop: "0px",
      paddingBottom: "0px"
      // height: this.props.selectName === "my-pipeline-users" ? "450px" : ""
    }),

    option: (provided, state) => ({
      borderBottom:
        this.props.selectName === "my-pipeline-users"
          ? "1px solid lightgrey"
          : "none",
      color: state.isSelected ? "black" : "black",
      padding:
        this.props.selectName === "my-pipeline-users"
          ? "10px"
          : this.props.selectName === "my-pipeline-organizations"
          ? "5px 15px"
          : "0px 5px",
      cursor: "pointer"
    }),
    menu: (provided, state) => ({
      ...provided,
      padding: this.props.selectName === "salesNews" ? 12 : ""
    }),

    indicatorSeparator: state => ({
      display: "none"
    }),

    control: (base, state) => ({
      ...base,
      "&:hover": { borderColor: "lightgray" },
      border: "1px solid lightgray",
      boxShadow: "none"
    })
  };

  multiValueContainer = ({ selectProps, data }) => {
    const label = data.name;
    const allSelected = selectProps.value;
    const index = allSelected.findIndex(selected => selected.name === label);
    const isLastSelected = index === allSelected.length - 1;
    const labelSuffix = isLastSelected ? ` ` : ",";
    const val = `${label}${labelSuffix}`;
    return val;
  };

  optionLabel = option => {
    if (this.props.selectName === "my-pipeline-users") {
      if (this.props.currentUser.user_id === option.id) {
        return option.name_without_initials + " (Me)";
      } else {
        return option.name_without_initials;
      }
    } else if (
      this.props.selectName === "my-pipeline-organizations" &&
      this.props.currentUser.organization_id === option.id
    ) {
      return this.props.value.name;
    } else {
      return option.name;
    }
  };

  render() {
    const { optionGroups } = this.props;
    const {
      CustomOption,
      multiValueContainer,
      customStyles,
      ValueContainer
    } = this;

    return (
      <Select
        value={this.props.value}
        closeMenuOnSelect={false}
        backspaceRemovesValue={false}
        isClearable={false}
        placeholder={
          this.props.placeholder
            ? this.props.placeholder
            : "Select Organization"
        }
        options={this.props.organizationList}
        getOptionLabel={option => this.optionLabel(option)}
        getOptionValue={option => `${option.id}`}
        isMulti={this.props.isMulti}
        components={{
          MultiValueContainer: multiValueContainer,
          Option: this.Option
        }}
        //defaultMenuIsOpen={this.props.defaultMenuIsOpen}
        menuIsOpen={this.props.defaultMenuIsOpen}
        closeMenuOnSelect={this.props.closeMenuOnSelect}
        hideSelectedOptions={false}
        styles={customStyles}
        isSearchable={true}
        onChange={this.handleChange}
        onBlur={this.props.onBlur}
        ref={this.props.innerRef}
      />
    );
  }
}
