import * as types from "./types";

export const setStatusMapping = (fieldMapping) => {
  //console.log("action", fieldMapping);
  return {
    type: types.SETSTATUS_MAPPING,
    payload: { fieldMapping: fieldMapping }
  };
};

export const setLeadLeadMappingSelected = (flag) => {
  //console.log("action", flag);
  return {
    type: types.ISLEADLEADMAPPING_SELECTED,
    payload: { leadLeadMappingSelected: flag }
  };
};

export default {
  setStatusMapping,
  setLeadLeadMappingSelected
};
