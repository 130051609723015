import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import styles from "../graphContainer.module.scss";
import OrgOptions from "../OrgOption";
import { openSnackbar } from "../../../common/Snackbar/snackbar";
import DateRangePicker from "../../../components/DateRangePicker/GraphDatePicker";
import handOver1 from "../../../../assets/images/bi/handover1.png";
import handOver2 from "../../../../assets/images/bi/handOver2.png";
import util from "../util";
import API from "../API";
import {
  getStorageItem,
  FindString,
  FindColumnInDateRange,
} from "../../../common/common-functions";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import HandOverGenratedChart from "../../Graph/HandOverGraphs/handOverGenrated";
import LargeScreenLoader from "../../../common/Loaders/PulseLoader/pulse-loader";
import LegendContainer, { Tile } from "../LegendContainer";
import NoDataBarChart from "../NoDataBarChart";

const HandOver = ({
  organizationArray,
  oldOrganizationList,
  parent_account,
  fromDate,
  toDate,
  ...rest
}) => {
  const [selection, setSelection] = useState(0);
  const [handoverDate, setHandoverDate] = useState([
    moment().startOf("year").toDate(),
    new Date(),
  ]);
  const [handoverGenDashboard, setHandoverGenDashboard] = useState(null);
  const [handoverAcceptedDashboard, setHandoverAcceptedDashboard] = useState(
    null
  );
  const [showLoader, setShowLoader] = useState(false);
  const [isOpenDropdownContent, setDropdownContent] = useState(false);
  const handoverAcceptedRef = useRef(null);
  const handoverGeneratedRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, [organizationArray, fromDate, toDate]);

  const fetchData = async () => {
    setShowLoader(true);
    const params = {
      ...util.getParams(organizationArray, fromDate, toDate),
      parent_account_id: parent_account.id,
    };
    const result = await Promise.all([
      API.getHandOverGeneratedByParents(params),
      API.getHandOverAcceptedByParents(params),
    ]);

    setHandoverGenDashboard(result[0]);
    setHandoverAcceptedDashboard(result[1]);
    setShowLoader(false);
  };

  let HandoverGeneratedData = [];
  let handoverGenData = 0;

  if (
    handoverGenDashboard &&
    handoverGenDashboard.HandoverGeneratedChartValue != null
  ) {
    HandoverGeneratedData = handoverGenDashboard.HandoverGeneratedChartValue.labels.map(
      (x, i) => {
        return {
          xVal: x,
          yVal:
            handoverGenDashboard.HandoverGeneratedChartValue.datasets[0].data[
              i
            ],
        };
      }
    );
    let DataForGenerated =
      handoverGenDashboard.HandoverGeneratedChartValue.datasets[0].data;

    if (DataForGenerated.length != 0) {
      handoverGenData = DataForGenerated.reduce((total, num) => {
        return total + parseInt(num);
      }, 0);
    }
  }
  let HandoverAcceptedData = [];
  let handoverAccepted = 0;
  if (
    handoverAcceptedDashboard &&
    handoverAcceptedDashboard.HandoverAcceptedChartValue != null
  ) {
    HandoverAcceptedData = handoverAcceptedDashboard.HandoverAcceptedChartValue.labels.map(
      (x, i) => {
        return {
          xVal: x,
          yVal:
            handoverAcceptedDashboard.HandoverAcceptedChartValue.datasets[0]
              .data[i],
        };
      }
    );
    var DataForAccepted =
      handoverAcceptedDashboard.HandoverAcceptedChartValue.datasets[0].data;
    if (DataForAccepted.length != 0) {
      handoverAccepted = DataForAccepted.reduce((total, num) => {
        return total + parseInt(num);
      }, 0);
    }
  }

  const renderHandoverGeneratedByParentChart = () => {
    const HandoverChartOptions = {
      id: "handoverGenrated",
      chartStyle: "horizontal", // horizontal/vertical
      height: 0,
      width: 0,
      margin: {
        top: 20,
        right: 100,
        bottom: 40,
        left: 100,
      },
      hideXaxis: false,
      hideYaxis: false,
      hideCenterLine: false,
      parseTime: "%b-%y",
      hideLegend: false,
      barWidth: 10,
      insideBarWidth: 10,
      multiColorBar: true,
      colors: [
        "#58C6D2",
        "#0B7985",
        "#7CEAF6",
        "#58C6D2",
        "#0B7985",
        "#7CEAF6",
        "#58C6D2",
        "#0B7985",
        "#7CEAF6",
      ],
      hideTooltip: false,
      responsive: true,
      ticksSize: 10,
      xAxisRotate: false,
      yAxisText: true,
      xAxisText: true,
      yAxisTextString: "",
      xAxisTextString: "",
      yAxisDivided: true,
      valueOnTop: true,
      valueOnTopDecimal: false,
    };

    const width =
      handoverGeneratedRef &&
      handoverGeneratedRef.current &&
      handoverGeneratedRef.current.offsetWidth;

    const noData = HandoverGeneratedData && HandoverGeneratedData.length === 0;

    return (
      <div className={"col-md-6 " + styles.chartPadding}>
        <Card className={styles.card}>
          <CardHeader
            className={styles.activities_container_header}
            action={<div></div>}
            avatar={
              <div>
                <p className={styles.crossSalesChartHeader}>
                  Handover Generated by {parent_account.name}
                </p>
                <p className={styles.crossSalesChartSubHeader}>
                  Count of source Org
                </p>
              </div>
            }
          />
          {!noData ? (
            <>
              <CardContent>
                <div className="row">
                  <div className="col" ref={handoverGeneratedRef}>
                    {width && (
                      <HandOverGenratedChart
                        height={250}
                        width={width}
                        data={
                          HandoverGeneratedData ? HandoverGeneratedData : []
                        }
                        options={HandoverChartOptions}
                      />
                    )}
                  </div>
                </div>
              </CardContent>
              <div className="row">
                <div class="col-5">
                  <table className={styles.graphTable}>
                    <tr className={styles.header}>
                      <td></td>
                      {handoverGenDashboard &&
                        handoverGenDashboard.HandoverGeneratedChartValue.labels.map(
                          (x) => {
                            return <td>{x}</td>;
                          }
                        )}
                    </tr>
                    <tr className={styles.tbody}>
                      <td></td>
                      {handoverGenDashboard &&
                        handoverGenDashboard.HandoverGeneratedChartValue.labels.map(
                          (x, i) => {
                            return (
                              <td>
                                {(handoverGenDashboard &&
                                  handoverGenDashboard
                                    .HandoverGeneratedChartValue.datasets[0]
                                    .data[i]) ||
                                  0}
                              </td>
                            );
                          }
                        )}
                    </tr>
                  </table>
                </div>
                <div className="col-7" style={{ paddingTop: 13 }}>
                  <LegendContainer>
                    {HandoverGeneratedData.map((d, i) => (
                      <Tile
                        text={d.xVal}
                        color={HandoverChartOptions.colors[i]}
                        marginRight={
                          i === HandoverGeneratedData.length - 1 ? 0 : 4
                        }
                      />
                    ))}
                  </LegendContainer>
                </div>
              </div>
            </>
          ) : (
            <NoDataBarChart />
          )}
        </Card>
      </div>
    );
  };

  const renderHanderoverAcceptedByParentChart = () => {
    const HandacceptedChartOptions = {
      id: "handoverAccepted",
      chartStyle: "horizontal", // horizontal/vertical
      height: 0,
      width: 0,
      margin: {
        top: 20,
        right: 100,
        bottom: 40,
        left: 100,
      },
      hideXaxis: false,
      hideYaxis: false,
      hideCenterLine: false,
      parseTime: "%b-%y",
      hideLegend: false,
      barWidth: 10,
      insideBarWidth: 10,
      multiColorBar: true,
      colors: [
        "#58C6D2",
        "#0B7985",
        "#7CEAF6",
        "#58C6D2",
        "#0B7985",
        "#7CEAF6",
        "#58C6D2",
        "#0B7985",
        "#7CEAF6",
      ],
      hideTooltip: false,
      responsive: true,
      ticksSize: 10,
      xAxisRotate: false,
      yAxisText: true,
      xAxisText: true,
      yAxisTextString: "",
      xAxisTextString: "",
      yAxisDivided: true,
      valueOnTop: true,
      valueOnTopDecimal: false,
    };

    const width =
      handoverAcceptedRef &&
      handoverAcceptedRef.current &&
      handoverAcceptedRef.current.offsetWidth;

    console.log(
      HandoverAcceptedData,
      `Handover Accepted by ${parent_account.name}`
    );
    const noData = HandoverAcceptedData && HandoverAcceptedData.length === 0;

    return (
      <div className={"col-md-6 " + styles.chartPadding}>
        <Card className={styles.card}>
          <CardHeader
            className={styles.activities_container_header}
            action={<div></div>}
            avatar={
              <div>
                <p className={styles.crossSalesChartHeader}>
                  Handover Accepted by {parent_account.name}
                </p>
                <p className={styles.crossSalesChartSubHeader}>
                  Count of Destination Org
                </p>
              </div>
            }
          />
          {!noData ? (
            <>
              <CardContent>
                <div className="row">
                  <div className="col" ref={handoverAcceptedRef}>
                    <HandOverGenratedChart
                      height={250}
                      width={width}
                      data={HandoverAcceptedData ? HandoverAcceptedData : []}
                      options={HandacceptedChartOptions}
                    />
                  </div>
                </div>
              </CardContent>
              <div className="row">
                <div class="col-5">
                  <table className={styles.graphTable}>
                    <tr className={styles.header}>
                      <td></td>
                      {handoverAcceptedDashboard &&
                        handoverAcceptedDashboard.HandoverAcceptedChartValue.labels.map(
                          (x) => {
                            return <td>{x}</td>;
                          }
                        )}
                    </tr>
                    <tr className={styles.tbody}>
                      <td></td>
                      {handoverAcceptedDashboard &&
                        handoverAcceptedDashboard.HandoverAcceptedChartValue.labels.map(
                          (x, i) => {
                            return (
                              <td>
                                {(handoverAcceptedDashboard &&
                                  handoverAcceptedDashboard
                                    .HandoverAcceptedChartValue.datasets[0]
                                    .data[i]) ||
                                  0}
                              </td>
                            );
                          }
                        )}
                    </tr>
                  </table>
                </div>
                <div className="col-7" style={{ paddingTop: 13 }}>
                  <LegendContainer>
                    {HandoverAcceptedData.map((d, i) => (
                      <Tile
                        text={d.xVal}
                        color={HandacceptedChartOptions.colors[i]}
                        marginRight={
                          i === HandoverGeneratedData.length - 1 ? 0 : 4
                        }
                      />
                    ))}
                  </LegendContainer>
                </div>
              </div>
            </>
          ) : (
            <NoDataBarChart />
          )}
        </Card>
      </div>
    );
  };

  return (
    <>
      <div className="container-body">
        <div className="row">
          <div className="col-md-6 border-right mb-4">
            <div className="row">
              <div className={"col-sm-6 " + styles.topSectionLeft}>
                <img
                  className={styles.icon_img}
                  src={handOver2}
                  alt="avrageVelocity"
                />
              </div>
              <div className={"col-sm-6 " + styles.topSection}>
                <div className={styles.first_content_style}>
                  <div className={styles.content_values1}>
                    {handoverGenData}
                  </div>
                  <p className={styles.title_Pulselite}>
                    Generated by {parent_account.name}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="row mb-4">
              <div className={"col-sm-6 " + styles.topSectionLeft}>
                <img
                  className={styles.icon_img}
                  src={handOver1}
                  alt="LeadIcon"
                />
              </div>
              <div className={"col-sm-6 " + styles.topSection}>
                <div className={styles.first_content_style}>
                  <div className={styles.content_values1}>
                    {handoverAccepted}
                  </div>
                  <p className={styles.title_Pulselite}>
                    Accepted by {parent_account.name}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-body">
        <div className="row justify-content-center mb-4">
          {renderHandoverGeneratedByParentChart()}
          {renderHanderoverAcceptedByParentChart()}
        </div>
      </div>

      {showLoader && <LargeScreenLoader />}
    </>
  );
};

export default HandOver;
