import React from "react";
import AutoComplete from "../../../common/AutoComplete/autocomplete";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import styles from "./ActivityTaskNote.module.scss";
import moment from "moment";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import axiosInstance from "../../../common/api-config";
import DatePicker from "react-date-picker";
import IconButton from "@material-ui/core/IconButton";
import { FormErrors } from "../../../common/formErrors/formErrors";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { openSnackbar } from "../../../common/Snackbar/snackbar";
import { connect } from "react-redux";
import CircularProgress from "../../../common/Loaders/CircularLoader/circular-loader";
import { commonValidation_regex, commonValidationWithLineBreak_regex} from "../../../common/common-types";


const DialogTitle = withStyles(theme => ({
  root: {
    width: "100%",
    margin: 0,
    padding: theme.spacing.unit
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    width: "100%",
    margin: 0,
    padding: "0px 30px 25px 30px"
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    width: "100%",
    borderTop: `0`,
    margin: 0
  }
}))(MuiDialogActions);

class ActivityForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activity_type_id: 0,
      company_id: props.company_id ? props.company_id : 0,
      activity_person_id: props.person_id ? props.person_id : 0,
      //activity_person_id: 0,
      activity_date: moment().format("YYYY-MM-DD"),
      activity_time: "00:00:00",
      //lead_id: props.lead_id ? props.lead_id : null,
      notes: "",
      personList: [],
      addActivityErrors: {
        activity_type_id: "",
        company_id: "",
        activity_person_id: "",
        notes: ""
      },
      isOpen: false,
      submitButton: false
    };
  }

  componentDidMount() {
    if (this.props.company_id && this.props.person_id) {
      this.fetchPersonList(this.props.company_id);
    }
    if (this.props.item) {
      const { item } = this.props;
      this.setState({
        activity_type_id: item.activity_type ? item.activity_type.id : 0,
        company_id: item.contact_company ? item.contact_company.id : 0,
        activity_person_id: item.person_id ? item.person_id : 0,
        activity_date: item.activity_date
          ? moment(item.activity_date).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
        activity_time: item.activity_time ? item.activity_time : "00:00:00",
        notes: item.notes ? item.notes : ""
      });
      this.fetchPersonList(item.contact_company.id);
    }
  }

  handleUserInput = e => {
    if (e && e.target) {
      const name = e.target.name;
      const value = e.target.value;
      this.setState({ [name]: value }, () => {
        this.validateField(name, value);
      });
    } else if (e && e.name) {
      const name = e.name;
      const value = e.value;
      this.setState({ [name]: value }, () => {
        this.validateField(name, value);
      });
      if (name === "company_id") {
        this.setState({ activity_person_id: 0, lead_id: null });
      }
    }
  };

  fetchPersonList = company_id => {
    let params = {
      arrayFilters: [
        {
          is_deleted: 0,
          company_id: company_id
        }
      ],
      selectFilters: [
        "id",
        "initials",
        "first_name",
        "last_name",
        "phone_number"
      ],
      sort: {
        field: "id",
        sortOrder: "DESC"
      },
      paginate: {
        page: "",
        limit: ""
      }
    };
    axiosInstance
      .post("/contact/person/get", params)
      .then(response => {
        if (response && response.data && response.data.statusCode === 200) {
          this.setState({
            personList: response.data.data.rows
          });
        } else {
          this.setState({
            personList: []
          });
          this.checkPersonValidation();
        }
      })
      .catch(error => {
        this.setState({
          personList: []
        });
      });
  };

  getPersonList = event => {
    this.handleUserInput(event);
    let company_id = 0;
    if (event && event.target) {
      company_id = event.target.value;
    } else if (event && event.value) {
      company_id = event.value;
    }
    this.setState({ company_id });
    this.getLeadList(company_id);
    let params = {
      arrayFilters: [
        {
          is_deleted: 0,
          company_id: company_id
        }
      ],
      selectFilters: [
        "id",
        "initials",
        "first_name",
        "last_name",
        "phone_number"
      ],
      sort: {
        field: "id",
        sortOrder: "DESC"
      },
      paginate: {
        page: "",
        limit: ""
      }
    };
    axiosInstance
      .post("/contact/person/get", params)
      .then(response => {
        if (response && response.data && response.data.statusCode === 200) {
          this.setState({ personList: response.data.data.rows });
        } else {
          this.setState({ personList: [] });
        }
        this.checkPersonValidation();
      })
      .catch(error => {
        this.setState({ personList: [] });
      });
  };

  checkValidCompany = () => {
    if (
      !this.state.company_id ||
      this.state.company_id === 0 ||
      this.state.company_id === ""
    ) {
      let addActivityErrors = { ...this.state.addActivityErrors };
      addActivityErrors["company_id"] =
        "Please select company from suggestions";
      this.setState({
        addActivityErrors: addActivityErrors
      });
    }
  };

  checkActivityType = async (event, newValue, autocompleteName) => {
    let addActivityErrors = { ...this.state.addActivityErrors };
    let activeTypeId = this.state.activity_type_id;
    if (newValue && newValue != "") {
      let selectedType = this.props.activityTypes.filter(type => {
        return type.name == newValue;
      });
      if (!selectedType || selectedType.length == 0) {
        addActivityErrors["activity_type_id"] =
          "Please select activity type from suggestions";
      }
      activeTypeId = 0;
    } else if (newValue.length === 0) {
      activeTypeId = 0;
      addActivityErrors["activity_type_id"] =
        "Please select activity type from suggestions";
    }
    await this.setState({
      addActivityErrors: addActivityErrors,
      activity_type_id: activeTypeId
    });
  };

  checkValidPerson = () => {
    let addActivityErrors = { ...this.state.addActivityErrors };
    if (this.state.personList.length === 0) {
      addActivityErrors["activity_person_id"] =
        "No persons added for this company";
    } else if (
      !this.state.contact_id ||
      this.state.contact_id === 0 ||
      this.state.contact_id === ""
    ) {
      addActivityErrors["activity_person_id"] =
        "Please select person from suggestions";
    } else {
      addActivityErrors["activity_person_id"] = "";
    }
    this.setState({
      addActivityErrors: addActivityErrors
    });
  };

  checkPersonValidation = () => {
    let addActivityErrors = { ...this.state.addActivityErrors };
    if (this.state.personList.length == 0) {
      addActivityErrors["activity_person_id"] =
        "No persons added for this company";
    } else {
      addActivityErrors["activity_person_id"] = "";
    }
    this.setState({
      addActivityErrors: addActivityErrors
    });
  };

  handleTimeChange = newTime => {
    this.setState({ activity_time: newTime && newTime.format("HH:mm:ss") });
  };

  handleSaveClick = async () => {
    if (
      this.state.company_id !== 0 &&
      this.state.activity_type_id !== 0 &&
      this.state.notes.length < 500
    ) {
      if (this.props.item) {
        const requestBody = {
          id: this.props.item.id,
          lead_id: this.props.lead_id,
          activity_type_id: this.state.activity_type_id,
          activity_date: this.state.activity_date,
          activity_time: this.state.activity_time,
          person_id: this.state.activity_person_id,
          company_id: this.state.company_id,
          notes: this.state.notes.trim()
          //user_id: JSON.parse(localStorage.getItem("user")).user_id
        };
        this.setState({ submitButton: true });
        axiosInstance
          .put("/salesActivity/update", requestBody)
          .then(response => {
            if (response && response.data && response.data.statusCode === 200) {
              openSnackbar("Activity updated successfully.", "snack-success");
              this.props.close("save_activity");
            } else {
              openSnackbar(response.data.errorMessage, "snack-error");
              this.props.close("");
            }
          })
          .catch(error => {
            openSnackbar(error.errorMessage, "snack-error");
            this.props.close("");
          });
      } else {
        const requestBody = {
          lead_id: this.props.lead_id,
          activity_type_id: this.state.activity_type_id,
          activity_date: this.state.activity_date,
          activity_time: this.state.activity_time,
          person_id: this.state.activity_person_id,
          company_id: this.state.company_id,
          notes: this.state.notes.trim()
          //user_id: JSON.parse(localStorage.getItem("user")).user_id
        };
        this.setState({ submitButton: true });
        axiosInstance
          .post("/salesActivity/add", requestBody)
          .then(response => {
            if (response && response.data && response.data.statusCode === 200) {
              openSnackbar("Activity added successfully.", "snack-success");
              this.props.close("save_activity");
            } else {
              openSnackbar(response.data.errorMessage, "snack-error");
              this.props.close("");
            }
          })
          .catch(error => {
            openSnackbar(error.errorMessage, "snack-error");
            this.props.close("");
          });
      }
    } else {
      this.validateAllFields();
    }
  };

  validateAllFields = () => {
    let addActivityErrors = { ...this.state.addActivityErrors };
    if (this.state.company_id === 0) {
      addActivityErrors.company_id = "This field is required";
    }

    // if (this.state.activity_person_id === 0) {
    //   addActivityErrors.activity_person_id = "This field is required";
    // }

    if (this.state.activity_type_id === 0) {
      addActivityErrors.activity_type_id = "This field is required";
    }

    if (this.state.notes.length > 500) {
      addActivityErrors.notes = "Note cannot be more than 500 characters";
    }

    this.setState({ addActivityErrors: addActivityErrors });
  };

  validateField = (fieldName, value) => {
    let addActivityErrors = { ...this.state.addActivityErrors };
    switch (fieldName) {
      case "activity_type_id":
        if (value === 0) {
          addActivityErrors["activity_type_id"] = "This field is required";
        } else {
          addActivityErrors["activity_type_id"] = "";
        }
        break;
      case "company_id":
        if (value === 0) {
          addActivityErrors["company_id"] = "This field is required";
        } else {
          addActivityErrors["company_id"] = "";
        }
        break;
      case "activity_person_id":
        if (value === 0) {
          addActivityErrors["activity_person_id"] = "This field is required";
        } else {
          addActivityErrors["activity_person_id"] = "";
        }
        break;

      case "notes":
        if (value.trim() === "") {
            addActivityErrors["notes"] = "";
        }
        else if (
            !value.match(commonValidationWithLineBreak_regex)
        ) {
          addActivityErrors["notes"] = "Please enter a valid note";
        }
        else if (value.trim().length > 1024) {
          addActivityErrors["notes"] =
            "Note cannot be more than 500 characters";
        } else {
          addActivityErrors["notes"] = "";
        }
        break;
      default:
        break;
    }
    this.setState({
      addActivityErrors: addActivityErrors
    });
  };

  toggleCalendar = () => {
    if (this.state.isOpen) {
      this.setState({
        flag: "calendar",
        isOpen: false
      });
    } else {
      this.setState({
        flag: "calendar",
        isOpen: true
      });
    }
  };

  handleDateChange = date =>
    this.setState({ activity_date: moment(date).format("YYYY-MM-DD") });

  dateChangeHandler = e => {};

  render() {
    const { activityTypes, companyList } = this.props;
    const { personList, leadListing } = this.state;
    return (
      <div className={styles.add_activity}>
        <DialogTitle id="customized-dialog-title" onClose={this.props.close} />
        <DialogContent className={styles.add_activity_dialog_content + " add_activity_mobile_left_right"}>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4">
              <div className={styles.form_group}>
                <label className={styles.form_title} htmlFor="sales-activity">
                  Sales activity<span className={styles.required_field}>*</span>
                </label>
                <div>
                  <AutoComplete
                    suggestions={activityTypes}
                    autoCompleteName="activity_type_id"
                    placeholder="Select sales activity"
                    value={this.state.activity_type_id}
                    name="activity_type_id"
                    id="activity_type_id"
                    change={this.handleUserInput}
                    onChange={(event, newValue, autocompleteName) =>
                      this.checkActivityType(event, newValue, autocompleteName)
                    }
                    disabled={this.props.activityTypes && !(this.props.activityTypes.length > 0)}
                  />
                </div>
                <FormErrors
                  formErrors={this.state.addActivityErrors}
                  field="activity_type_id"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className={styles.form_group}>
                <label className={styles.form_title} htmlFor="company_id">
                  Company<span className={styles.required_field}>*</span>
                </label>
                <div>
                  {/* <AutoComplete
                    suggestions={companyList}
                    autoCompleteName="company"
                    placeholder="Select company"
                    getPersonList={this.getPersonList}
                    value={this.state.company_id}
                    name="company_id"
                    id="company_id"
                    disabled={this.props.company_id}
                    //onBlur={() => this.checkValidCompany()}
                  /> */}
                  <input
                    className={styles.inputStyle}
                    value={this.props.company_name}
                    disabled={true}
                  />
                </div>
                <FormErrors
                  formErrors={this.state.addActivityErrors}
                  field="company_id"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className={styles.form_group}>
                <label
                  className={styles.form_title}
                  htmlFor="activity_person_id"
                >
                  Contact person
                </label>
                <div>
                  <AutoComplete
                    suggestions={personList}
                    autoCompleteName="activity_person_id"
                    placeholder="Select contact person"
                    value={this.state.activity_person_id}
                    name="activity_person_id"
                    id="activity_person_id"
                    change={this.handleUserInput}
                    disabled={true}
                    //onBlur={() => this.checkValidPerson()}
                  />
                  {/* <input
                    className={styles.inputStyle}
                    value={this.props.person_name}
                    disabled={this.props.company_id}
                  /> */}
                </div>
                <FormErrors
                  formErrors={this.state.addActivityErrors}
                  field="activity_person_id"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <label className={styles.form_title}>
                Date<span className={styles.required_field}>*</span>
              </label>
              <div className={styles.datepicker}>
                <input
                  value={this.state.activity_date}
                  className={styles.inputStyle + " add_cursor_pointer"}
                  onClick={this.toggleCalendar}
                  onChange={this.dateChangeHandler}
                />
                <DatePicker
                  value={
                    new Date(
                      moment(this.state.activity_date).year(),
                      moment(this.state.activity_date).month(),
                      moment(this.state.activity_date).date()
                    )
                  }
                  clearIcon={null}
                  calendarIcon={null}
                  isOpen={this.state.isOpen}
                  onCalendarClose={this.toggleCalendar}
                  onChange={this.handleDateChange}
                  minDate={new Date()}
                  maxDate={new Date(moment().add(20, 'years'))}
                />
              </div>
              <i className="date-picker-icon" />
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className={styles.form_group}>
                <label className={styles.form_title} htmlFor="time">
                  Time<span className={styles.required_field}>*</span>
                </label>
                <TimePicker
                  style={{ width: 100 }}
                  showSecond={true}
                  value={moment(this.state.activity_time, "HH:mm:ss")}
                  onChange={this.handleTimeChange}
                  className="add_cursor_pointer"
                  placement="bottomLeft"
                  clearIcon={null}
                />
                <i className="time-picker-icon" />
              </div>
            </div>
            <div className="col-12">
              <div className={styles.form_group}>
                <label className={styles.form_title} htmlFor="notes">
                  Notes
                </label>
                <div className="notesHint">{(1024 - this.state.notes.length) +" characters left"}</div>
                <textarea
                  className={styles.textArea}
                  id="notes"
                  name="notes"
                  value={this.state.notes}
                  onChange={this.handleUserInput}
                  maxLength={1024}
                />
              </div>
              <FormErrors
                  formErrors={this.state.addActivityErrors}
                  field="notes"
                />
            </div>
          </div>
          <DialogActions>
            {this.state.submitButton ? (
              <div className={styles.submit_loader}>
                <CircularProgress
                  style={{
                    color: "#19D98C"
                  }}
                />
              </div>
            ) : (
              <Button
                onClick={this.handleSaveClick}
                className={styles.saveButton}
                color="primary"
              >
                Save
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    companyList: state.asideLeft.companyList
  };
};

export default connect(mapStateToProps)(ActivityForm);
