import axiosInstance from "../../../common/api-config";

export const getNotesListing = req => {
  return dispatch => {
    dispatch({ type: "LOADING_NOTES" });
    axiosInstance
      .post("/notesLog/get", req)
      .then(response => {
        if (response && response.data && response.data.statusCode === 200) {
          dispatch({
            type: "GET_NOTES_LIST_SUCCESS",
            data: response.data.data.rows
          });
        } else {
          dispatch({
            type: "GET_NOTES_LIST_FAIL",
            errorMessage: response.data.errorMessage
          });
        }
      })
      .catch(error => {
        dispatch({
          type: "GET_NOTES_LIST_FAIL",
          errorMessage: error.errorMessage
        });
      });
  };
};

export const resetNotesListing = () => {
  return dispatch => {
    dispatch({ type: "RESET_NOTES_LISTING" });
  };
};

export default {
  getNotesListing,
  resetNotesListing
};
