import React, { useState } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MDTableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import styled from 'styled-components';
import style from './user_managment.module.scss';
// import Label from '@material-ui/core/Label';
import _ from 'lodash';

const TableCell = styled(MDTableCell)`
  padding-left: 0em;
  border-bottom-width: 0.125em;
  border-bottom-color: #C9C8D0;
  text-align: center;
  font-weight: 650;
  color: #62616E;
  margin-right: 0.9375em !important;
  margin-left: 0.9375em;
  padding-bottom : 1em;  
`;

function EnhancedTableHead(props) {
  const { classes, headers, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={style.custom_table} style={{ borderSpacing: '0.9375em 3.125em' }}>
      <TableRow>
        {headers.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id && headCell.sort ? order : false}
            css={{ marginLeft: 10 }}
          >
            <TableSortLabel
              active={headCell.sort && headCell.id === orderBy}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={headCell.sort && createSortHandler(headCell.id)}
            >
              {headCell.label}
              {headCell.sort && orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? '' : ''}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function CustomTable(props) {
  const { 
    classes, 
    headers, 
    data, 
    renderRow, 
    page, 
    order, 
    orderBy, 
    count, 
    rowsPerPage, 
    onRowsPerPageChange, 
    onPageChange, 
    onOrderChange 
  } = props;

  const handleOnRequestSort = (event, property) => {
    onOrderChange(property);
  };

  const handleChangePage = (event, newPage) => onPageChange(newPage);

  const handleChangeRowsPerPage = (event) => {
    onRowsPerPageChange(parseInt(event.target.value, 10));
    onPageChange(0);
  }

  console.log(order, orderBy, 'Orders');

  return (
    <>
      <Table style={{ borderSpacing: 15 }}>
        <EnhancedTableHead
          classes={classes}
          headers={headers}
          onRequestSort={handleOnRequestSort}
          order={order}
          orderBy={orderBy}
        />
        <TableBody>
          {data && data.map((row, i) => renderRow(row, i))}
        </TableBody>
      </Table>
      <TablePagination
        style={{ paddingBottom: 150 }}
        rowsPerPageOptions={[10, 20]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        classes={{
          selectIcon: classes.selectIcon
        }}
      />
    </>
  )
}

const styles = (theme) => ({
  selectIcon: {
    paddingLeft: "5px"
  }
});

export default withStyles(styles)(CustomTable);